import React, { Component, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import TSelectNativeMultiple from 'component/shared/TSelectNativeMultiple/TSelectNativeMultiple';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import TInputDateBase from 'component/shared/TInputDateBase/TInputDateBase';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import { Grid, Card, CardContent } from '@material-ui/core';
import CalendarMonth from 'component/shared/CalendarMonth';

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    minHeight: 700,
  },
}));

export default function ViewItemAgenda({ handleMenuItem }) {
  const classes = useStyles();

  const {
    especialidade,
    setEspecialidade,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    intervaloAtendimento,
    setIntervaloAtendimento,
    dataSheduleItem,
  } = useConfiguracaoAgenda();

  console.log(dataSheduleItem);

  return (
    <div className={classes.background}>
      <Card>
        <CardContent>
          <h1>Visualização Agenda</h1>
          <Grid container spacing={5}>
            <Grid item md={4}>
              <TInputBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Nome do profissional"
                name="nome_profissional"
                placeholder="Nome do profissional"
                //value={ duracaoAtendimento }
                //onChange={ e => setDuracaoAtendimento(e.target.value) }
              />
            </Grid>
            <Grid item md={4}>
              <TSelectNative
                label="Especialidade"
                name="especialidade"
                // error
                // helperText="Mensagem de apoio"
                required
                options={[{ value: 1, label: 'Valor 1' }]}
                value={especialidade}
                onChange={(e) => setEspecialidade(e.target.value)}
              />
            </Grid>
            <Grid item md={4}>
              <TSelectNative
                label="Ocupação"
                name="ocupacao"
                // error
                // helperText="Mensagem de apoio"
                required
                options={[{ value: 1, label: 'Valor 1' }]}
                // value={ especialidade }
                // onChange={ e => setEspecialidade(e.target.value) }
              />
            </Grid>
            <Grid item md={6}>
              <TSelectNative
                label="Conselho"
                name="conselho"
                // error
                // helperText="Mensagem de apoio"
                required
                options={[{ value: 1, label: 'Valor 1' }]}
                // value={ especialidade }
                // onChange={ e => setEspecialidade(e.target.value) }
              />
            </Grid>
            <Grid item md={6}>
              <TInputBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Número de registro"
                name="numero_registro"
                placeholder="Número de registro"
                //value={ duracaoAtendimento }
                //onChange={ e => setDuracaoAtendimento(e.target.value) }
              />
            </Grid>
            <Grid item md={6}>
              <TInputDateBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Data Inicial"
                name="data_inicial"
                placeholder="Data Inicial"
                value={dataInicial}
                onChange={(e) => setDataInicial(e)}
              />
            </Grid>
            <Grid item md={6}>
              <TInputDateBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Data Final"
                name="data_final"
                placeholder="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e)}
              />
            </Grid>
            <Grid item md={3}>
              <TInputBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Faixa etária maior ou igual a"
                name="faixa_etaria_maior"
                placeholder="Faixa etária"
                //value={ duracaoAtendimento }
                //onChange={ e => setDuracaoAtendimento(e.target.value) }
              />
            </Grid>
            <Grid item md={3}>
              &nbsp;
              <TSelectNative
                label=" "
                name="faixa_etaria_maior_selecionar"
                // error
                // helperText="Mensagem de apoio"
                options={[]}
                // value={ especialidade }
                // onChange={ e => setEspecialidade(e.target.value) }
              />
            </Grid>
            <Grid item md={3}>
              <TInputBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Faixa etária menor ou igual a"
                name="faixa_etaria_menor"
                placeholder="Faixa etária"
                //value={ duracaoAtendimento }
                //onChange={ e => setDuracaoAtendimento(e.target.value) }
              />
            </Grid>
            <Grid item md={3}>
              &nbsp;
              <TSelectNative
                label=""
                name="faixa_etaria_menor_selecionar"
                // error
                // helperText="Mensagem de apoio"
                options={[]}
                // value={ especialidade }
                // onChange={ e => setEspecialidade(e.target.value) }
              />
            </Grid>
            <Grid item md={6}>
              <TSelectNative
                label="Sexo"
                name="sexo"
                // error
                // helperText="Mensagem de apoio"
                options={[
                  {
                    value: 'masculino',
                    label: 'Masculino',
                  },
                  {
                    value: 'feminino',
                    label: 'Feminino',
                  },
                ]}
                // value={ especialidade }
                // onChange={ e => setEspecialidade(e.target.value) }
              />
            </Grid>
            <Grid item md={12}>
              <TSelectNativeMultiple
                label="Formulários"
                name="formularios"
                placeholder="Formulários"
                // error
                // helperText="Mensagem de apoio"
                required
                options={[
                  { value: 1, label: 'Anamnese' },
                  { value: 2, label: 'Covid 19' },
                ]}
                value={formularios}
                onChange={(e, newValue) => setFormularios(newValue)}
              />
            </Grid>
            <Grid item md={6}>
              <TInputTimeBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Duração do atendimento"
                name="duracao_atendimento"
                placeholder="Duração do atendimento"
                value={duracaoAtendimento}
                onChange={(e) => setDuracaoAtendimento(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TInputTimeBase
                // error
                // helperText="Mensagem de apoio"
                required
                label="Intervalo entre os atendimentos"
                name="intervalo_atendimento"
                placeholder="Intervalo entre os atendimentos"
                value={intervaloAtendimento}
                onChange={(e) => setIntervaloAtendimento(e.target.value)}
              />
            </Grid>

            <Grid item md={12}>
              <CalendarMonth handleMenuItem={handleMenuItem} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
