import React from 'react';
import VisualizadorClinico from './VisualizadorClinico';

const index = (props) => {
  const { url, open } = props;

  return (
    <>
      <VisualizadorClinico
        url={url}
        open={open}
        setModal={(_) => props.setModal()}
      />
    </>
  );
};

export default index;
