import axios from 'axios';
import env from 'react-dotenv';

/**
 * @param {Object.<string, string>} params
 * @returns {Array.<{key: string, value: string}>}
 * @description Função que retorna os parâmetros da url em chave e valor
 */
function getParams(params) {
  if (typeof params === 'object') {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

/**
 * @param {string} token
 * @param {object} params
 * @param {string?} params.user_id
 * @param {string?} params.start_date_min "yyyy-mm-dd hh:MM:ss"
 * @param {string?} params.start_date_max "yyyy-mm-dd hh:MM:ss"
 * @param {string?} params.cpf
 * @param {string?} params.cns
 * @param {string?} params.search "Pesquisar por id, nome, cns ou cpf"
 * @description Filtra as agendas de um profissional.
 **/
export async function filterProfessionalSchedule(token, params) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.

  const uri = new URL(`${api_url}/schedule/filter`);

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  uri.searchParams.append('token', token);

  const response = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
    },
  });
  return response.data;
}

/**
 * @param {string} token
 * @description Lista todas as agendas.
 */
export async function listAllSchedule(token) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.

  var uri = new URL(`${api_url}/schedule/list`);

  uri.searchParams.append('token', token);

  const { data } = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
    },
  });
  return data;
}

/**
 * @param {string} token Token do usuário
 * @param {string} patientId ID do paciente
 * @description Retorna os atendimentos agendados futuros do paciente.
 */
export async function getNextSchedulesPatient(token, patientId) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.

  var uri = new URL(`${api_url}/schedule/next-schedule-patient/${patientId}`);

  uri.searchParams.append('token', token);

  const { data } = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
    },
  });
  return data;
}

export default {
  listAllSchedule,
  getNextSchedulesPatient,
};
