import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  IconButton,
  Backdrop,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DInput from '../shared/DInput';
import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactQuill from 'react-quill';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-quill/dist/quill.snow.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@mui/icons-material/Save';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import InfoIcon from '@material-ui/icons/Info';
import CardContent from '@material-ui/core/CardContent';
import 'react-toastify/dist/ReactToastify.css';
import { saveTemplateEmail } from './api/EmailConfig';

const useStyles = makeStyles((theme) => ({
  paciente__topo: {
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.snow,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  icon: {
    marginLeft: -7,
    cursor: 'pointer',
    height: 18.77,
  },
  bg: {
    backgroundColor: theme.palette.background.snow,
  },
  gHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 24,
    fontSize: 16,
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  gMessage: {
    fontSize: 14,
    color: theme.palette.neutral.midgrey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 320px)',
  },
  titleTop: {
    marginTop: 40,
    marginBottom: 24,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
  },
  containerPopDicionario: {
    maxWidth: '350px',
  },
  btnTitle: {
    maxHeight: '50px',
    marginTop: '40px',
  },
  dicionarioContentTitle: {
    padding: '10px',
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  dicionarioContentSubTitle: {
    marginTop: '5px',
    marginBottom: '15px',
    padding: '10px',
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  dicionarioAlignIcon: {
    flexDirection: 'column',
    display: 'flex',
    paddingRight: '5px',
  },
  dicionarioCard: {
    padding: '10px',
    paddingBottom: '10px !important',
  },
  listDicionario: {
    marginBottom: '20px',
  },
  title: {
    margin: 0,
    padding: 0,
    color: theme.palette.primary.light,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '14px',
  },
  box: {
    background: theme.palette.background.cloud,
    borderRadius: 8,
    marginBottom: 32,
  },
  addQuestion: {
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '4px 18px',
    letterSpacing: 0,
  },
  btSave: {
    color: '#fff',
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
    marginLeft: 8,
  },
  btBack: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  dialogContent: {
    padding: 16,
    width: 296,
    color: theme.palette.neutral.black,
  },
}));

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export default function EmailCreateConfig({
  emailParam,
  callbackCloseEmailEditor,
}) {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState(emailParam);
  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [valueFooter, setValueFooter] = useState('');
  const [valueSignature, setValueSignature] = useState('');
  const [valueBody, setValueBody] = useState('');
  const [valueTitle, setValueTitle] = useState('');

  useEffect(() => {
    setVariables(Object.keys(email.variables));
    setValueFooter(email.footer);
    setValueSignature(email.signature);
    setValueBody(email.body);
    setValueTitle(email.title);
  }, [email]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseEditor = () => {
    setOpenDialog(false);
    callbackCloseEmailEditor();
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  const handleConfirmChanges = () => {
    var checkValidate = true;

    if (!valueTitle || stripHtmlTags(valueTitle).trim() === '') {
      toast.error('O campo Título não pode estar vazio!');
      checkValidate = false;
    }

    if (!valueBody || stripHtmlTags(valueBody).trim() === '') {
      toast.error('O campo Corpo da mensagem não pode estar vazio!');
      checkValidate = false;
    }

    if (!valueFooter || stripHtmlTags(valueFooter).trim() === '') {
      toast.error('O campo Rodapé não pode estar vazio!');
      checkValidate = false;
    }

    if (!valueSignature || stripHtmlTags(valueSignature).trim() === '') {
      toast.error('O campo Assinatura não pode estar vazio!');
      checkValidate = false;
    }

    if (!checkValidate) {
      return;
    }

    setOpenDialog(false);
    setLoading(true);
    const emailBody = {
      ...email,
      title: valueTitle,
      body: `<div id="body">${valueBody}</div>`,
      footer: `<div id="footer">${valueFooter}</div>`,
      sign: `<div id="sign">${valueSignature}</div>`,
    };

    saveTemplateEmail(token, emailBody)
      .then((response) => {
        if (typeof response.status_code == 'undefined') {
          if (response.status) {
            toast.success('E-mail salvo com sucesso!');
          } else {
            toast.error('Erro ao salvar o e-mail!');
          }
        }

        setLoading(false);
        callbackCloseEmailEditor();
      })
      .catch((error) => {
        toast.error('Erro ao salvar o e-mail!');
        setLoading(false);
        callbackCloseEmailEditor();
      });
  };

  const handleDicionarioVariaveis = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchor(null);
  };

  const renderEmailDialog = () => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleClose}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            style={{ color: '#949494', position: 'absolute', top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent classaName={classes.dialogContent}>
            <Typography
              variant="h5"
              style={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '24px',
                marginBottom: 8,
              }}
            >
              Salvar alterações
            </Typography>
            <Typography
              style={{ fontSize: 14, fontWeight: 400, marginBottom: 28 }}
            >
              Ao sair sem salvar você perderá
              <br />
              todas as alterações deste arquivo
            </Typography>
            <Grid container>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  variant="outlined"
                  className={classes.btBack}
                  size="sm"
                  onClick={handleCloseEditor}
                >
                  Sair mesmo assim
                </Button>
                <Button
                  variant="contained"
                  className={classes.btSave}
                  size="sm"
                  onClick={handleConfirmChanges}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Backdrop style={{ zIndex: 3000, color: 'white' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item className={classes.paciente__topo}>
        <Grid container alignItems="center">
          <Grid item>
            <ArrowBackIosIcon
              classes={{ root: classes.icon }}
              onClick={handleClickOpenDialog}
              fontSize="large"
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h1">
              {email?.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Container>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleTop} variant="h3">
            Editar Mensagem
          </Typography>
          <Button
            className={classes.btnTitle}
            onClick={(e) => handleDicionarioVariaveis(e)}
          >
            <MenuBookIcon />
            &nbsp;Dicionário de variáveis
          </Button>
        </Grid>

        <Popover
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={handleCloseAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Container className={classes.containerPopDicionario}>
            <Grid
              item
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                marginTop: '10px',
              }}
            >
              <CloseIcon
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={(e) => setAnchor(null)}
              />
            </Grid>
            <Grid container className={classes.dicionarioContentTitle}>
              <Typography component="h2" style={{ width: '100%' }}>
                {' '}
                Dicionário de Variáveis{' '}
              </Typography>
            </Grid>
            <Grid container className={classes.dicionarioContentSubTitle}>
              <Typography variant="label" component="label">
                {' '}
                Entenda como utilizar variáveis para a personalização de suas
                mensagens.{' '}
              </Typography>
            </Grid>
            {variables.map((variable) => {
              return (
                <>
                  <Card className={classes.listDicionario} variant="outlined">
                    <CardContent className={classes.dicionarioCard}>
                      <Grid container justifyContent="space-between">
                        <Grid
                          item
                          md={1}
                          className={classes.dicionarioAlignIcon}
                          justifyContent="center"
                        >
                          <InfoIcon />
                        </Grid>
                        <Grid item md={10}>
                          <Typography variant="h6" component="h6">
                            {variable}
                          </Typography>
                          <Typography
                            className={classes.pos}
                            color="textSecondary"
                          >
                            {email.variables[variable]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              );
            })}
          </Container>
        </Popover>

        <Grid className={classes.bg}>
          <Box className={classes.box} style={{ padding: 16 }}>
            <Typography className={classes.title} variant="h4">
              Título
            </Typography>
            <DInput
              id="title"
              type="text"
              fullWidth={true}
              inputProps={{ maxLength: 254 }}
              value={valueTitle}
              onChange={(e) => {
                setValueTitle(e.target.value);
              }}
            />
          </Box>

          <Grid item>
            <Typography className={classes.title} variant="h4" component="h2">
              Corpo da mensagem
            </Typography>
          </Grid>

          <Box className={classes.box} style={{ height: 400 }}>
            <ReactQuill
              theme="snow"
              value={valueBody}
              style={{ height: 360, border: 0 }}
              modules={modules}
              formats={formats}
              onKeyDown={(event) => {
                if (
                  event.target.value >= 1000 &&
                  event.key !== 'Backspace' &&
                  event.key !== 'Home' &&
                  event.key !== 'End' &&
                  event.key !== 'Shift' &&
                  event.key !== 'ArrowLeft' &&
                  event.key !== 'ArrowRight' &&
                  event.key !== 'Delete'
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(value, delta, name, events) => {
                if (value != '') {
                  setValueBody(value);
                }
              }}
            />
          </Box>

          <Grid item>
            <Typography className={classes.title} variant="h4" component="h2">
              Assinatura
            </Typography>
          </Grid>

          <Box className={classes.box} style={{ height: 300 }}>
            <ReactQuill
              theme="snow"
              value={valueSignature}
              style={{ height: 260, border: 0 }}
              modules={modules}
              formats={formats}
              onKeyDown={(event) => {
                if (
                  event.target.value >= 1000 &&
                  event.key !== 'Backspace' &&
                  event.key !== 'Home' &&
                  event.key !== 'End' &&
                  event.key !== 'Shift' &&
                  event.key !== 'ArrowLeft' &&
                  event.key !== 'ArrowRight' &&
                  event.key !== 'Delete'
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(value, delta, name, events) => {
                if (value != '') {
                  setValueSignature(value);
                }
              }}
            />
          </Box>

          <Grid item>
            <Typography className={classes.title} variant="h4" component="h2">
              Rodapé
            </Typography>
          </Grid>

          <Box className={classes.box} style={{ height: 200 }}>
            <ReactQuill
              theme="snow"
              value={valueFooter}
              style={{ height: 160, border: 0 }}
              modules={modules}
              formats={formats}
              onKeyDown={(event) => {
                if (
                  event.target.value >= 1000 &&
                  event.key !== 'Backspace' &&
                  event.key !== 'Home' &&
                  event.key !== 'End' &&
                  event.key !== 'Shift' &&
                  event.key !== 'ArrowLeft' &&
                  event.key !== 'ArrowRight' &&
                  event.key !== 'Delete'
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(value, delta, name, events) => {
                if (value != '') {
                  setValueFooter(value);
                }
              }}
            />
          </Box>

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              className={classes.addQuestion}
              size="sm"
              startIcon={
                email?.id ? (
                  <SaveIcon style={{ fontSize: 20 }} />
                ) : (
                  <CheckIcon style={{ fontSize: 20 }} />
                )
              }
              onClick={handleConfirmChanges}
            >
              <span style={{ marginTop: 2, display: 'block' }}>
                {email?.id ? 'Salvar alterações' : 'Salvar e adicionar'}
              </span>
            </Button>
          </Box>
        </Grid>
      </Container>

      {renderEmailDialog()}
    </>
  );
}
