import { React, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DButton from '../../../../shared/DButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { getAll } from '../../../../../api/animal';
import CreateAnimal from '../Add/';
import EditAnimal from '../Edit/';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
  tbl_header: {
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 21,
    color: '#4F4F4F',
  },
  buttonAdd: {
    minWidth: 110,
    height: 33,
    marginBottom: 30,
    borderRadius: 100,
    border: `1px solid ${theme.palette.background.cloud} !important`,
    fontSize: 14,
    padding: '6px !important',
    fontWeight: '400 !important',
    float: 'right',
  },
  pagination: {
    margin: '24px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiPagination-ul': {
      color: '#272727',
      background: 'transparent',
      fontWeight: 400,

      '& > li': {
        '& button': {
          fontSize: 16,
        },
      },
      '& > li:first-child': {
        '& button': {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        },
      },
      '& > li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        },
      },
      '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        background: '#fff',
        fontWeight: 500,
      },
    },
  },
}));

function ListAnimals(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  const [animals, setAnimals] = useState([]);
  const [screen, setScreen] = useState(1);
  const [animalId, setAnimalId] = useState(null);
  const [updateFromChild, setUpdateFromChild] = useState(false);

  const StyledTableRowHeader = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.neutral.snow,
    },
  }))(TableRow);

  const StyledTitleTableCell = withStyles((theme) => ({
    body: {
      textAlign: 'left',
      color: theme.palette.neutral.black,
    },
  }))(TableCell);

  useEffect(() => {
    console.log('nice123');
    if (props.idPaciente && screen == 1) {
      getAll(props.idPaciente, localStorage.getItem('token')).then((res) => {
        setAnimals(res?.animals);
      });
    }
  }, [props.idPaciente, screen]);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
      color: theme.palette.neutral.black,
      padding: '6px 12px',
    },
    body: {
      fontSize: 14,
      padding: '6px 12px',
      whiteSpace: 'nowrap',
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleScreenFromChild = (data) => {
    setScreen(data);
  };

  return (
    (screen == 3 && (
      <>
        <EditAnimal
          sendScreenToParent={handleScreenFromChild}
          patientId={props.idPaciente}
          animalId={animalId}
        ></EditAnimal>
      </>
    )) ||
    (screen == 2 && (
      <>
        <CreateAnimal
          sendScreenToParent={handleScreenFromChild}
          patientId={props.idPaciente}
        ></CreateAnimal>
      </>
    )) ||
    (screen == 1 && (
      <>
        <div style={{ height: '100%' }}>
          <Grid item md={12}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <DButton
                className={classes.buttonAdd}
                onClick={(e) => {
                  setScreen(2);
                }}
                type="button"
                variant="primary"
                size="none"
              >
                Adicionar
              </DButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="animais table">
              <TableHead>
                <StyledTableRowHeader>
                  <StyledTitleTableCell>Nome</StyledTitleTableCell>
                  <StyledTitleTableCell>Espécie/Raça</StyledTitleTableCell>
                  <StyledTitleTableCell>Nascimento/Idade</StyledTitleTableCell>
                  <StyledTitleTableCell>&nbsp;</StyledTitleTableCell>
                </StyledTableRowHeader>
              </TableHead>
              <TableBody>
                {Array.isArray(animals) &&
                  animals.map((row, index) => (
                    <StyledTableRow key={`animals-${index}`}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>
                        {row.specie}/{row.breed}
                      </StyledTableCell>
                      <StyledTableCell>{row.age}</StyledTableCell>
                      <StyledTableCell>
                        <a
                          href="#"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            textDecoration: 'none',
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setAnimalId(row.id);
                            setScreen(3);
                          }}
                        >
                          <OpenInNewIcon />
                          <p id="demo">&nbsp;Abrir</p>
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    ))
  );
}

export default ListAnimals;
