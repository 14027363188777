import { TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';

const EBFieldDefaultConductClinical = ({
  id,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);

  const [conductClinical, setConductClinical] = useState();

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <div>
          <Typography
            variant="body1"
            className="text-bold text-primary"
            style={{ fontSize: '1em' }}
          >
            Conduta clínica
          </Typography>
          <div className="textarea-box mb-3">
            <TextField
              id={id}
              minRows="5"
              multiline
              size="medium"
              variant="outlined"
              name="conductClinical"
              label={answer ? '' : 'Conduta clínica'}
              defaultValue={answer ? answer[0] : ''}
              error={erro[0] ? erro[0].value : ''}
              helperText={erro[0] ? erro[0].value : ''}
              value={conductClinical}
              inputProps={{ maxLength: 500 }}
              InputProps={{
                readOnly: readOnly,
              }}
              onChange={(e) => {
                if (erro !== null && erro.length > 0) {
                  delete erro[0].value;
                }
                setConductClinical(e.target.value);
              }}
              onBlur={(e) => {
                !readOnly
                  ? handleInputSubmit({ [id]: conductClinical })
                  : console.log('effect onBlur');
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EBFieldDefaultConductClinical;
