/**
 * Mapeia os documentos de pacientes retornando sempre um objeto, evitando quebrar a tela com novas atualizações.
 * @param {Array<object>} patient_document
 * @param {Object} find_method  - Objeto com o id do documento ou o valor description.
 * @param {string | number} find_method.document_type_id
 * @param {string} find_method.description
 * @returns object
 */
export function getPatientDocuments(
  patient_document,
  { document_type_id = null, description = null },
) {
  var fields = {};

  if (patient_document) {
    if (document_type_id) {
      var document = patient_document.find(
        (document) => document.document_type_id == document_type_id,
      );

      if (
        document &&
        Array.isArray(document.patient_document_fields) &&
        document.patient_document_fields.length > 0
      ) {
        fields = document.patient_document_fields[0];

        return fields;
      }

      return fields;
    } else if (description) {
      var document = patient_document.find(
        (document) => document.document_type.description == description,
      );

      if (
        document &&
        Array.isArray(document.patient_document_fields) &&
        document.patient_document_fields.length > 0
      ) {
        fields = document.patient_document_fields[0];

        return fields;
      }

      return fields;
    } else {
      return fields;
    }
  }

  return fields;
}

export const months = [
  {
    id: 1,
    value: 'Janeiro',
  },
  {
    id: 2,
    value: 'Fevereiro',
  },
  {
    id: 3,
    value: 'Março',
  },
  {
    id: 4,
    value: 'Abril',
  },
  {
    id: 5,
    value: 'Maio',
  },
  {
    id: 6,
    value: 'Junho',
  },
  {
    id: 7,
    value: 'Julho',
  },
  {
    id: 8,
    value: 'Agosto',
  },
  {
    id: 9,
    value: 'Setembro',
  },
  {
    id: 10,
    value: 'Outubro',
  },
  {
    id: 11,
    value: 'Novembro',
  },
  {
    id: 12,
    value: 'Dezembro',
  },
];

export const weeks = [
  {
    id: 1,
    value: 'Domingo',
    abrev: 'Dom',
  },
  {
    id: 2,
    value: 'Segunda-feira',
    abrev: 'Seg',
  },
  {
    id: 3,
    value: 'Terça-feira',
    abrev: 'Ter',
  },
  {
    id: 4,
    value: 'Quarta-feira',
    abrev: 'Qua',
  },
  {
    id: 5,
    value: 'Quinta-feira',
    abrev: 'Qui',
  },
  {
    id: 6,
    value: 'Sexta-feira',
    abrev: 'Sex',
  },
  {
    id: 7,
    value: 'Sábado',
    abrev: 'Sab',
  },
];

/**
 * @param {number} time Tempo em segundos
 * @returns "00:00"
 */
export const secondsToMinutes = (time) =>
  Math.floor(time / 60) +
  ':' +
  String(
    Math.floor(time % 60) < 10
      ? '0' + Math.floor(time % 60)
      : Math.floor(time % 60),
  );

/**
 * @param {number} time Tempo em segundos
 * @returns "00:00:00"
 */
export const secondsToHours = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var s = seconds - hours * 3600 - minutes * 60;

  hours = hours < 10 ? '0' + hours : hours;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  s = s < 10 ? '0' + s : s;

  return hours + ':' + minutes + ':' + s;
};
