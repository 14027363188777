import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import './styles.scss';

const EBComorbidities = ({
  id,
  label,
  name,
  title,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [options, setOptions] = useState(null);
  const [statusSend, setStatusSend] = useState(false);

  useEffect(() => {
    let optionsState = {};

    additional_data.options.map(function (option, index) {
      optionsState = {
        ...optionsState,
        [option.value]: { checked: false, obs: '' },
      };
    });
    if (answer && Array.isArray(answer) && answer.length > 0) {
      Object.entries(answer[0]).map(function (res) {
        optionsState[res[0]] = res[1];
      });
    }
    setOptions(optionsState);
  }, []);

  const handleCheckboxChange = (e) => {
    const old_options = {
      ...options,
      [e.target.value]: {
        ...options[e.target.value],
        checked: e.target.checked,
      },
    };
    setOptions(old_options);
    setStatusSend(true);
  };

  const handleInputChange = (e, id) => {
    const answerInput = {
      ...options,
      [id]: { ...options[id], obs: e.target.value },
    };
    setOptions(answerInput);
    setStatusSend(true);
  };

  useEffect(() => {
    if (options != null) {
      handleInputSubmit({ [id]: options });
    }
  }, [options]);

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      {field_category_id === 3 && (
        <div>
          <div className="mb-3">
            <div className="box-antecedents">
              <Typography
                variant="body1"
                className="text-bold mb-2"
                style={{ fontSize: '0.8em' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${label ? label : 'Comorbidades'}`,
                  }}
                />
              </Typography>
              {additional_data.options.length > 0 && options != null
                ? additional_data.options.map((option, index) => (
                    <>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            id={index}
                            checked={options[option.value].checked}
                            color="primary"
                            disabled={readOnly}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            required={is_required}
                            onChange={handleCheckboxChange}
                            value={option.value}
                            name={option.label}
                          />
                        }
                        label={option.label}
                      />
                      {options[option.value] !== undefined &&
                      options[option.value].checked ? (
                        <TextField
                          id={option.value}
                          label={label ? label : ''}
                          placeholder={placeholder ? placeholder : ''}
                          defaultValue={options[option.value].obs}
                          error={erro[0] ? erro[0].value : ''}
                          value={
                            options[option.value] !== undefined
                              ? options[option.value].obs
                              : ''
                          }
                          onChange={(e) => {
                            if (erro !== null && erro.length > 0) {
                              delete erro[0].value;
                            }
                            handleInputChange(e, option.value);
                          }}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                          variant="outlined"
                          //   onBlur={ e => {
                          //     !readOnly ? handleInputSubmit({[id]: options}) : console.log('effect onBlur')
                          // } }
                        />
                      ) : (
                        ''
                      )}
                    </>
                  ))
                : 'Não existe dados cadastrados'}
              <br />
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {erro[0] ? erro[0].value : ''}
              </span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBComorbidities;
