import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  InputBase,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { password, resendcode } from './../../../../api/recoverpassword';
import useError from './../../../../hooks/useError';
import { required } from '../../../../helpers/validacoes';
import { DefaultButton } from './../../../shared/ButtonDefault/index.jsx';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 60,
    borderBottom: 'solid #4F4F4F 1px',
  },
  inputFocused: {
    borderBottom: `solid ${theme.palette.primary.main} 2px`,
  },
  textLink: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
}));

function FormRecoverPasswordCode(props) {
  const classes = useStyles();

  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [loading, setLoading] = useState(false);

  const [error, validarFormulario] = useError({
    code1: required,
    code2: required,
    code3: required,
    code4: required,
    code5: required,
  });

  const nextField = (field) => {
    try {
      document.querySelector(`input[name="${field}"]`).focus();
    } catch (err) {
      console.log(`campo ${field} não encontrado`);
    }
  };

  const handleGetTokenPassword = () => {
    setLoading(true);
    password(
      code1,
      code2,
      code3,
      code4,
      code5,
      localStorage.getItem('token_recoverpassword'),
    ).then((res) => {
      if (res.status) {
        toast.success('Solicitação concluida com sucesso.');
        localStorage.setItem('token_recoverpassword', res.token);
        props.onCode();
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    });
  };

  const handleResend = (type_action) => {
    setLoading(true);

    resendcode(localStorage.getItem('token_recoverpassword'), type_action).then(
      (res) => {
        if (res.status) {
          toast.success('Codigo reenviado com sucesso.');
          localStorage.setItem('token_recoverpassword', res.token);
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      },
    );
  };

  return (
    <>
      <form
        onSubmit={(form) => {
          form.preventDefault();

          if (validarFormulario({ code1, code2, code3, code4, code5 })) {
            handleGetTokenPassword();
          }
        }}
      >
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="body1" className="text-regular text-mt">
                  Por favor, informe o código que enviamos para
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={`text-medium text-mt ${classes.textLink}`}
                  style={{ textDecoration: 'underline' }}
                >
                  {localStorage.getItem('phone_recoverid')
                    ? localStorage.getItem('phone_recoverid')
                    : localStorage.getItem('email_recoverid')}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item>
                    <InputBase
                      autoFocus
                      name="input1"
                      onFocus={(e) => e.target.select()}
                      inputProps={{ maxLength: 1 }}
                      disabled={loading}
                      value={code1}
                      onChange={(e) => {
                        setCode1(e.target.value);
                        nextField('input2');
                      }}
                      classes={{
                        root: classes.input,
                        focused: classes.inputFocused,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <InputBase
                      name="input2"
                      onFocus={(e) => e.target.select()}
                      value={code2}
                      inputProps={{ maxLength: 1 }}
                      disabled={loading}
                      onChange={(e) => {
                        setCode2(e.target.value);
                        nextField('input3');
                      }}
                      classes={{
                        root: classes.input,
                        focused: classes.inputFocused,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <InputBase
                      name="input3"
                      onFocus={(e) => e.target.select()}
                      value={code3}
                      inputProps={{ maxLength: 1 }}
                      disabled={loading}
                      onChange={(e) => {
                        setCode3(e.target.value);
                        nextField('input4');
                      }}
                      classes={{
                        root: classes.input,
                        focused: classes.inputFocused,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <InputBase
                      name="input4"
                      onFocus={(e) => e.target.select()}
                      value={code4}
                      inputProps={{ maxLength: 1 }}
                      disabled={loading}
                      onChange={(e) => {
                        setCode4(e.target.value);
                        nextField('input5');
                      }}
                      classes={{
                        root: classes.input,
                        focused: classes.inputFocused,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <InputBase
                      name="input5"
                      onFocus={(e) => e.target.select()}
                      value={code5}
                      inputProps={{ maxLength: 1 }}
                      disabled={loading}
                      onChange={(e) => {
                        setCode5(e.target.value);
                      }}
                      classes={{
                        root: classes.input,
                        focused: classes.inputFocused,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  disabled={loading}
                  onClick={(e) => handleResend('email')}
                  className={`text-medium text-mt ${classes.textLink}`}
                  style={{ textDecoration: 'underline' }}
                >
                  Reenviar código
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  disabled={loading}
                  onClick={(e) => handleResend('sms')}
                  className={`text-medium text-mt ${classes.textLink}`}
                  style={{ textDecoration: 'underline' }}
                >
                  Enviar código por SMS
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item>
                    <DefaultButton
                      disabled={loading}
                      onClick={(e) => props.onBack()}
                      type="button"
                      variant="outlined"
                      color="primary"
                    >
                      Voltar
                    </DefaultButton>
                  </Grid>
                  <Grid item>
                    <DefaultButton
                      disabled={loading}
                      type="submit"
                      variant="text"
                      color="primary"
                    >
                      {loading ? <CircularProgress size={15} /> : 'Avançar'}
                    </DefaultButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

FormRecoverPasswordCode.defaultProps = {
  onCode: function () {
    console.log('onCode');
  },
  onBack: function () {
    console.log('onBack');
  },
};

export default FormRecoverPasswordCode;
