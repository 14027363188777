import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DButton from '../../shared/DButton';
import DInput from '../../shared/DInput';
import { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import './styles.css';

// Import Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  paciente__topo: {
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.snow,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  icon: {
    marginLeft: -7,
    cursor: 'pointer',
    height: 18.77,
  },
  bg: {
    backgroundColor: theme.palette.background.snow,
  },
  gHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 24,
    fontSize: 16,
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  gMessage: {
    fontSize: 14,
    color: theme.palette.neutral.midgrey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 320px)',
  },
  titleTop: {
    marginTop: 40,
    marginBottom: 24,
    color: theme.palette.primary.light,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
  },
  title: {
    margin: '0 0 8px 0',
    padding: 0,
    color: theme.palette.primary.light,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
  },
  box: {
    background: theme.palette.background.cloud,
    borderRadius: 8,
    marginBottom: 32,
  },
  addQuestion: {
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '4px 18px',
    letterSpacing: 0,
  },
  btSave: {
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
    marginLeft: 8,
  },
  btBack: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
}));

function QuestionCreate(props) {
  const classes = useStyles();

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }, { font: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const [title, setTitle] = useState(
    props.isEditAnswer ? props.question.title : '',
  );
  const [value, setValue] = useState(
    props.isEditAnswer ? props.question.description : '',
  );
  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmChanges = () => {
    if (value.length > 0 && title.length > 0) {
      if (props.isEditAnswer) {
        props.onSave(title, value, props.category, props.question);
      } else {
        props.onSave(title, value, props.category, null);
      }
    } else {
      toast.error('Preencha todos os campos!');
    }
  };

  return (
    <>
      <Grid item className={classes.paciente__topo}>
        <Grid container alignItems="center">
          <Grid item>
            <ArrowBackIosIcon
              classes={{ root: classes.icon }}
              onClick={handleClickOpen}
              color="primary"
              fontSize="large"
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h1">
              {props.isEditAnswer
                ? 'FAQ - Alterar pergunta'
                : 'FAQ - Cadastrar pergunta'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Container>
        <Grid className={classes.bg}>
          <Typography className={classes.titleTop} variant="h3">
            {props.isEditAnswer ? 'Alterar pergunta' : 'Cadastrar pergunta'}
          </Typography>

          <Box className={classes.box} style={{ padding: 16 }}>
            <Typography className={classes.title} variant="h4">
              Título
            </Typography>
            <DInput
              id="title"
              label="Digite aqui a sua pergunta..."
              placeholder="Como alterar minha senha de acesso?"
              type="text"
              fullWidth={true}
              inputProps={{ maxLength: 254 }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Box>

          <Box className={classes.box} style={{ height: 600 }}>
            <ReactQuill
              theme="snow"
              value={value}
              style={{ height: 560, border: 0 }}
              modules={modules}
              formats={formats}
              onKeyDown={(event) => {
                if (
                  text.length >= 1000 &&
                  event.key !== 'Backspace' &&
                  event.key !== 'Home' &&
                  event.key !== 'End' &&
                  event.key !== 'Shift' &&
                  event.key !== 'ArrowLeft' &&
                  event.key !== 'ArrowRight' &&
                  event.key !== 'Delete'
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(value, delta, name, events) => {
                if (events.getText().trim() == '') {
                  setValue('');
                } else {
                  setValue(value);
                  setText(events.getText().trim());
                }
              }}
            />
          </Box>

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              className={classes.addQuestion}
              style={props.isEditAnswer ? { backgroundColor: '#168183' } : {}}
              size="sm"
              startIcon={
                props.isEditAnswer ? (
                  <SaveIcon style={{ fontSize: 20 }} />
                ) : (
                  <CheckIcon style={{ fontSize: 20 }} />
                )
              }
              onClick={handleConfirmChanges}
            >
              <span style={{ marginTop: 2, display: 'block' }}>
                {props.isEditAnswer
                  ? 'Salvar alterações'
                  : 'Salvar e adicionar'}
              </span>
            </Button>
          </Box>
        </Grid>
      </Container>

      <Dialog open={open} onClose={handleClose}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          style={{ color: '#949494', position: 'absolute', top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ padding: 16, width: 296, color: '#272727' }}>
          <Typography
            variant="h5"
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '24px',
              marginBottom: 8,
            }}
          >
            Salvar alterações
          </Typography>
          <Typography
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 28 }}
          >
            Ao sair sem salvar você perderá
            <br />
            todas as alterações deste arquivo
          </Typography>
          <Grid container>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                variant="outlined"
                className={classes.btBack}
                size="sm"
                onClick={(e) => props.onClose(true)}
              >
                Sair mesmo assim
              </Button>
              <Button
                variant="contained"
                className={classes.btSave}
                size="sm"
                onClick={handleConfirmChanges}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default QuestionCreate;
