import axios from 'axios';
import env from 'react-dotenv';

export async function getPregnancies(token, patientId) {
  const res = await axios.get(
    `${env.API_URL}/group-form/group-form-patient/${patientId}/formated`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    },
  );
  return res.data;
}

export default {
  getPregnancies,
};
