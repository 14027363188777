import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormHelperText,
  Modal,
  InputLabel,
  FormControl,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  useForkRef,
  useTheme,
} from '@material-ui/core';
import { Clear, ExpandMore, Error } from '@material-ui/icons';
import DButton from '../DButton';
import { Bio } from './../../../api/biomedidas';
import FieldType from './components/FieldType';
import DDateInput from './../DDateInput';
import useError from './../../../hooks/useError';

import { required } from './../../../helpers/validacoes';
import NovaBiomedidaHelper from './NovaBiomedidaHelper';

import { toast } from 'react-toastify';

import { useStyles, getModalStyle } from './theme';

function NovaBiomedida(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);

  const [biomedida, setBiomedida] = useState(
    props.biomedida ? props.biomedida.biomeasure_exam_id : null,
  );
  const [dataMedicao, setDataMedicao] = useState(
    props.biomedida ? props.biomedida.date_measurement : null,
  );
  const [tipo, setTipo] = useState(
    props.biomedida ? parseInt(props.biomedida.type) : 1,
  );

  const [biomedidas, setBiomedidas] = useState([]);
  const [fieldsBiomedida, setFieldsBiomedida] = useState([]);
  const [values, setValues] = useState([]);
  const [errorValues, setErrorValues] = useState([]);

  const [error, validarFormulario, setError] = useError({
    biomedida: required,
    dataMedicao: required,
  });

  useEffect(() => {
    Bio.get(localStorage.getItem('token')).then((res) => {
      if (res.data.status && props.profile)
        setBiomedidas(
          res.data.details.biomeasures.filter(
            (biomeasures) =>
              biomeasures.biomeasure_exam_profile &&
              biomeasures.biomeasure_exam_profile.find(
                (profile) => profile.profile_id == props.profile,
              ),
          ),
        );

      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    setValues([]);

    if (biomedida && biomedida !== '' && loaded) {
      const fields = biomedidas.find((bio) => bio.id === biomedida);

      if (fields && fields.biomeasure_exam_field.length > 0) {
        NovaBiomedidaHelper.setBiomedida(props.biomedida).setFields(
          setFieldsBiomedida,
          fields,
        );
        setValues(NovaBiomedidaHelper.getValuesDefault(fields));
      } else {
        setFieldsBiomedida([]);
      }
    } else {
      setFieldsBiomedida([]);
    }
  }, [biomedida, loaded, props.biomedida]);

  const handleSubmit = () => {
    const body = {
      health_attendance_id: props.healthAttendanceId,
      biomeasure_exam_id: biomedida,
      date_measurement: dataMedicao,
      type: tipo,
      biomeasure_exam_health_attendance_value: values.map((value) => {
        return {
          biomeasure_exam_field_id: value.id,
          value: Array.isArray(value.value)
            ? value.value.join(';')
            : value.value,
        };
      }),
    };

    if (props.biomedida) {
      Bio.updateToHealthAttendanceById(
        localStorage.getItem('token'),
        body,
        props.biomedida.id,
      ).then((response) => {
        if (response.data.status) {
          toast.success('Biomedida atualizada com sucesso.');
          props.onSaved(true);
        }
      });
    } else {
      Bio.createToHealthAttendance(localStorage.getItem('token'), body).then(
        (response) => {
          if (response.data.status) {
            toast.success('Biomedida cadastrada com sucesso.');
            props.onSaved(true);
          }
        },
      );
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.headermodal}>
            <h2 className="simple-modal-title text-mt text-regular">
              Adicione uma biomedida ou exame
            </h2>
            <Clear
              style={{ color: '#949494' }}
              onClick={(e) => props.onClose(true)}
            />
          </div>
          <form
            onSubmit={(form) => {
              form.preventDefault();

              let formulario_valido = true;
              if (!validarFormulario({ biomedida, dataMedicao }))
                formulario_valido = false;

              if (!NovaBiomedidaHelper.validateFields(setErrorValues, values))
                formulario_valido = false;

              if (formulario_valido) {
                handleSubmit();
              }
            }}
          >
            <Grid
              container
              spacing={1}
              justifyContent="center"
              classes={{ root: classes.modalContent }}
            >
              <Grid item md={11}>
                <FormControl
                  className={`${classes.formControl} ${error.biomedida ? 'form-control-error' : ''}`}
                >
                  <Select
                    native
                    fullWidth
                    value={biomedida}
                    error={error.biomedida ? true : false}
                    onChange={(e) => {
                      delete error.biomedida;
                      setBiomedida(
                        e.target.value !== '' ? parseInt(e.target.value) : null,
                      );
                    }}
                    IconComponent={() =>
                      error.biomedida ? (
                        <Error />
                      ) : (
                        <ExpandMore
                          style={{ color: theme.palette.primary.light }}
                        />
                      )
                    }
                  >
                    <option aria-label="None" value="">
                      Selecione uma opção
                    </option>
                    {biomedidas.map((biomedida, index) => (
                      <option
                        key={`tipo-biomedida-option-${index}-${biomedida.id}`}
                        value={biomedida.id}
                      >
                        {biomedida.name}
                      </option>
                    ))}
                  </Select>
                  {error.biomedida && (
                    <FormHelperText>{error.biomedida}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={11}>
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item md={7}>
                    <DDateInput
                      id="data_medicao"
                      labelColor="black"
                      error={error.dataMedicao ? true : false}
                      helperText={error.dataMedicao}
                      fullWidth
                      label="Data da medição"
                      value={dataMedicao}
                      onChange={(arg1, arg2, e) => {
                        delete error.dataMedicao;
                        setDataMedicao(arg1);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="flex-end"
                      classes={{ root: classes.radioField }}
                    >
                      <Grid item>
                        <FormControl className={`Radio-root`}>
                          <RadioGroup
                            row
                            aria-label="position"
                            onChange={(e) => {
                              setTipo(parseInt(e.target.value));
                            }}
                            name="tipo"
                            value={tipo}
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio color="primary" />}
                              label="Exame"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio color="primary" />}
                              label="Autogerado"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {biomedida && fieldsBiomedida.length > 0 && (
                <Grid item md={11}>
                  <Grid
                    container
                    alignItems="flex-start"
                    classes={{ root: classes.fields }}
                  >
                    {biomedida &&
                      fieldsBiomedida.map((field, index) => {
                        return (
                          <FieldType
                            key={`field-biomedida-${index}`}
                            error={errorValues[index]}
                            campo={field}
                            value={
                              values &&
                              values.find((val) => val.id === field.id)
                                ? values.find((val) => val.id === field.id)
                                    .value
                                : ''
                            }
                            onChange={(value) => {
                              let new_values = [...values];
                              new_values[index].value = value;
                              setValues(new_values);
                            }}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className={`${classes.footermodal} tema-light`}>
              <DButton size="sm" type="submit" variant="primary">
                Salvar
              </DButton>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

NovaBiomedida.defaultProps = {
  open: false,
  healthAttendanceId: null,
  biomedida: null,
  profile: null,
  onSaved: function () {
    console.log('onSaved');
  },
  onClose: function () {
    console.log('onClose');
  },
};

export default NovaBiomedida;
