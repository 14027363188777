import React from 'react';

export const ChatContext = React.createContext({});

export const ChatProvider = (props) => {
  const [sid, setSid] = React.useState(null);
  const [serviceSid, setServiceSid] = React.useState(null);
  const [conversationId, setConversationId] = React.useState(null);
  const [chatAttendance, setChatAttendance] = React.useState(false);

  return (
    <ChatContext.Provider
      value={{
        conversationId,
        setConversationId,
        sid,
        setSid,
        serviceSid,
        setServiceSid,
        chatAttendance,
        setChatAttendance,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

export const useChat = () => React.useContext(ChatContext);
