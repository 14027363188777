import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './index.scss';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import VisualizarExame from '../../../../../VisualizadorExames';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../../../context/NewHealthAttendance';

export default function ModalPDF(props) {
  const [open, setOpen] = React.useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const { healthAttendance } = useNewHealthAttendance();
  const [fileSize, setFileSize] = useState('');
  const handleClose = () => props.setModal();

  useEffect(() => {
    if (downloadFile) {
      setDownloadFile(false);
    }
  }, [downloadFile]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className="estilo_modal_PDF">
          <div className="flex_modal_PDF">
            <div className="fonte_titulo_modal_PDF">Criar PDF</div>
            <div className="flex_end_modal_PDF">
              <Button onClick={handleClose}>
                <CloseIcon className="icone_modal_PDF" />
              </Button>
            </div>
          </div>
          <div className="tamanho_preview_PDF mt20_modal_PDF">
            <div className="corpo_pdf">
              <div className="">
                <div className="">
                  <VisualizarExame
                    getIframe
                    idAtendimento={healthAttendance.id}
                    height={500}
                    downloadFile={downloadFile}
                    fileSize={(e) => setFileSize(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="legenda_tamanho_pdf">Tamanho: {fileSize}</div>
          <div className="alinha_botao_modal_PDF mt50_modal_PDF">
            <Button
              className="botao_baixar_PDF"
              onClick={(e) => {
                setDownloadFile(true);
              }}
            >
              <DownloadIcon />
              Baixar Arquivo
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
