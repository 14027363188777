// LoginStyles.js
import styled from 'styled-components';
import { Grid, Container } from '@material-ui/core';

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerLogin = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  border-bottom: 8px solid #006fb5;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  border-bottom: 8px solid #006fb5;
`;

export const styles = (theme) => ({
  showDuoDoctorLogo: {
    display:
      theme.palette.logo_dev_enable === 1
        ? 'flex !important'
        : 'none !important',
  },
  showMedicalGroup: {
    display:
      theme.palette.hidden_login_medical_group === 1 ? 'none !important' : '',
  },
});
