import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, makeStyles } from '@material-ui/core';
import './index.scss';
import { getPregnancies } from './../../../../api/groupForm';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import ModalAcompanhamento from '../../../shared/NewTabs/VisualizadorClinNewTab/Components/ModalAcompanhamento';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaGestacao: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

export default function CustomizedTables() {
  const { patient } = useNewHealthAttendance();

  const [pregnancy, setPregnancy] = React.useState(null);
  const [pregnancies, setPregnancies] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (patient) {
      getPregnancies(localStorage.getItem('token'), patient.id).then((res) => {
        if (res.status) setPregnancies(res.groupForm);
      });
    }
  }, [patient]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Data</StyledTableCell>
              <StyledTableCell align="left">Semanas</StyledTableCell>
              <StyledTableCell align="left">Unidades</StyledTableCell>
              <StyledTableCell align="left">Data do parto</StyledTableCell>
              <StyledTableCell align="center">Resultado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pregnancies.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.data_acolhimento}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.idade_gestacional_semanas}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.maternidade_referencia}
                </StyledTableCell>
                <StyledTableCell align="left">-</StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    onClick={(e) => setPregnancy(row)}
                    className={classes.iconResultadoTabelaGestacao}
                    startIcon={<LaunchIcon />}
                  >
                    {' '}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pregnancy && (
        <ModalAcompanhamento
          pregnancy={pregnancy}
          open={true}
          onClose={(e) => setPregnancy(null)}
        />
      )}
    </>
  );
}
