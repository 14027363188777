import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      marginTop: 15,
      marginBottom: 15,
    },
    radioField: {
      height: 48,
    },
    fields: {
      marginTop: 15,
      marginBottom: 15,
      border: '1px solid #949494',
      borderRadius: 4,
      padding: 16,
    },
    paper: {
      position: 'absolute',
      width: 691,
      backgroundColor: theme.palette.background.snow,
      border: '1px solid #949494',
      borderRadius: 8,
    },
    headermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3px 15px',
      borderBottom: 'solid 1px #C7C7C7',
    },
    footermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '3px 15px',
      borderTop: 'solid 1px #C7C7C7',
    },
    formControl: {
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export { useStyles, getModalStyle };
