import axios from 'axios';
import env from 'react-dotenv';

function getAllUser(token, page) {
  return axios
    .post(
      `${env.API_URL}/user/list?page=${page}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMedicalUnitesById(token, profileId) {
  return axios
    .post(
      `${env.API_URL}/user/profiles`,
      {
        profile_id: profileId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .then((data) => {
      //console.log(data);
      if (data.hasOwnProperty('status') && data.status == true) {
        return data;
      }
    });
}

export function getMedicalUnites(token) {
  return axios
    .post(
      `${env.API_URL}/medicalunit`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getProfileId(token) {
  return axios
    .post(
      `${env.API_URL}/user/profiles`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .then((data) => {
      if (data.status == true) {
        if (Array.isArray(data.profiles)) {
          return data.profiles;
        }
      } else {
        throw data;
      }
    });
}

export function getPermissionSelect(token, profileId = undefined) {
  var body = {};

  if (profileId)
    body = {
      profile_id: profileId,
    };

  return axios.post(`${env.API_URL}/user/profiles`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': `${token}`,
    },
  });
}

export async function filterConference(body) {
  const response = await axios.post(
    `${env.API_URL}/conference/filter-conference`,
    body,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${localStorage.getItem('token')}`,
      },
    },
  );

  if (response instanceof Error) {
    throw response;
  }

  return response.data;
}

export async function filterVoip(body) {
  const response = await axios.post(`${env.API_URL}/call/filter-voip`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': `${localStorage.getItem('token')}`,
    },
  });

  return response.data;
}

export function getVoipToken(token) {
  return axios
    .post(
      `${env.API_URL}/call/calling`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getVoipCallToken(token) {
  return axios
    .post(
      `${env.API_URL}/call/token`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function createVoipConference(token, phoneNumber, roomName) {
  return axios
    .post(
      `${env.API_URL}/call/conference/create`,
      {
        phone_number: phoneNumber,
        nameRoom: roomName,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

/**
 * @param {*} token
 * @param {*} id
 * @param {object} data
 * @param {string?} data.to_type
 * @param {string?} data.to
 * @param {string} data.duration
 * @param {string?} data.health_attendance_id
 */
export function updateCall(token, id, data) {
  return axios
    .put(`${env.API_URL}/call/${id}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function addParticipantVoipConference(
  token,
  {
    phoneNumber,
    nameParticipant,
    nameRoom,
    health_attendance_id,
    to_type = 'patient',
  },
) {
  return axios
    .post(
      `${env.API_URL}/call/conference/add-participant`,
      {
        phone_number: phoneNumber,
        nameParticipant,
        nameRoom,
        health_attendance_id,
        to_type,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function updateUserAvatar(token, file) {
  var data = new FormData();
  data.append('file', file);

  return axios
    .post(`${env.API_URL}/user/avatar?token=${token}`, data, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function removeUserAvatar(token) {
  return axios
    .delete(`${env.API_URL}/user/destroy-avatar`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function updateUser(token, userId, dados) {
  return axios
    .put(`${env.API_URL}/user/${userId}`, dados, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function updateCustomUser(token, userId, dados) {
  return axios
    .patch(`${env.API_URL}/user/${userId}/update-user`, dados, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createUserMedicalUnit(token, dados, id) {
  return axios
    .post(`${env.API_URL}/user/${id}/profiles/add`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function updateUserProfile(token, dados, id) {
  return axios
    .put(`${env.API_URL}/user/${id}/profiles/update`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteUserMedicalUnit(token, dados, id) {
  return axios
    .post(`${env.API_URL}/user/${id}/profiles/delete`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteUser(token, id) {
  return getUserInfoById(token, id).then((data) => {
    if (data.status) {
      const { user_invite } = data.user;

      if (user_invite) {
        return axios
          .delete(`${env.API_URL}/user/${user_invite.id}/invite`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-domain': env.X_DOMAIN,
              'x-auth-token': `${token}`,
            },
          })
          .then((res) => res.data)
          .catch((error) => error.response);
      } else {
        throw new Error('Usuário sem convite');
      }
    } else {
      throw new Error(data);
    }
  });
}

function blockUser(token, id) {
  return axios
    .put(
      `${env.API_URL}/user/${id}/blocked`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function unblockUser(token, id) {
  return axios
    .put(
      `${env.API_URL}/user/${id}/unblocked`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

/**
 * @param {*} token
 * @param {object} params
 * @param {*?} params.patient_id
 * @param {*?} params.health_attendance_id
 * @param {*?} params.search
 * @param {*?} params.type
 */
export function searchPrescriptions(token, params) {
  var uri = new URL(`${env.API_URL}/prescription/filter`);

  var params = getParams(params); // Separa os parâmetros a serem filtrados

  if (params && Array.isArray(params)) {
    // Monta o filtro para pesquisa de prescrições
    params.forEach((param) => {
      uri.searchParams.append(param.key, param.value);
    });
  }

  return axios
    .get(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export function getPrescriptions(token, healthAttendanceId) {
  return axios
    .get(
      `${env.API_URL}/prescription/${healthAttendanceId}/listByhealthAttendanceId`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((err) => console.error(err));
}

export function getPrescriptionsByPatientId(token, patientId, page) {
  var url = '';

  if (page) {
    url = `${env.API_URL}/prescription/${patientId}/listByPatientId?page=${page}`;
  } else {
    url = `${env.API_URL}/prescription/${patientId}/listByPatientId`;
  }

  return axios
    .get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function resendInvite(token, id) {
  return axios
    .get(`${env.API_URL}/user/${id}/resend-invite`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function initializePrescription(token, healthAttendanceId, cid10 = []) {
  return axios
    .post(
      `${env.API_URL}/prescription/initiate`,
      {
        healthAttendanceId: healthAttendanceId,
        cid10,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 404 || res.status === 400) throw new Error(res.data);

      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export function finishPrescription(token, prescriptionId, data) {
  return axios
    .post(`${env.API_URL}/prescription/${prescriptionId}/finish`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => {
      if (res.status == 404) throw new Error(res.data);

      return res.data;
    });
}

export function finishPrescriptionDocument(token, prescriptionId, document) {
  return axios
    .post(
      `${env.API_URL}/prescription/${prescriptionId}/finishPrescriptionDocument`,
      document,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function requestUpdatePassword(token) {
  return axios
    .get(`${env.API_URL}/user/request-update-password`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function updatePassword(token, code, password) {
  return axios
    .patch(
      `${env.API_URL}/user/update-password`,
      {
        code,
        password,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function verifyResetPasswordCode(token, code) {
  return axios
    .post(
      `${env.API_URL}/user/verify-request-code`,
      { code },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getPossibleUserProfiles(token) {
  return axios
    .post(
      `${env.API_URL}/profile/list`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .then((data) => {
      if (data.status && data.status == true) {
        return data;
      } else {
        throw data.status;
      }
    })
    .catch((err) => console.error(err));
}

export function showAvatar(token) {
  return axios
    .get(`${env.API_URL}/user/show-avatar`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function uploadAvatar(token, image) {
  return axios
    .post(
      `${env.API_URL}/user/avatar`,
      {
        file: image,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function fetchCurrentUserDetails(token) {
  return axios
    .get(`${env.API_URL}/me`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMeLogin(token) {
  return axios
    .get(`${env.API_URL}/me-login`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getUserInfoById(token, id) {
  return axios
    .get(`${env.API_URL}/user/${id}/show`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMeByInvite(token) {
  return axios
    .get(`${env.API_URL}/user/verify-invite?token=${token}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createUser(token, dados) {
  var documents = [
    {
      type: 'CPF',
      fields: [
        {
          id: 1,
          value: dados.cpf,
        },
      ],
    },
    {
      type: 'CNS',
      fields: [
        {
          id: 9,
          value: dados.cns,
        },
      ],
    },
  ];

  if (dados.nacionalidade == 1) {
    documents.push({
      type: 'RG',
      fields: [
        {
          id: 2,
          value: dados.identidadeNumero.replace(/[^0-9]/gm, ''),
        },
        {
          id: 3,
          value: new Date(dados.identidadeEmissao).toLocaleDateString(),
        },
        {
          id: 4,
          value: dados.identidadeOrgaoEmissor,
        },
        {
          id: 60,
          value: dados.identidadeUF,
        },
      ],
    });
  }

  if (dados.nacionalidade == 2) {
    documents.push({
      type: 'NATURALIZED',
      fields: [
        {
          id: 15,
          value: new Date(dados.dataNaturalizacao).toLocaleDateString(),
        },
        {
          id: 16,
          value: dados.paisNascimento,
        },
      ],
    });
  }

  if (dados.nacionalidade == 3) {
    documents.push({
      type: 'PASSPORT',
      fields: [
        {
          id: 5,
          value: dados.passaporteNumero,
        },
        {
          id: 6,
          value: new Date(dados.passaporteDataEmissao).toLocaleDateString(),
        },
        {
          id: 7,
          value: dados.passaportePaisEmissor,
        },
        {
          id: 8,
          value: new Date(dados.passaporteDataValidade).toLocaleDateString(),
        },
      ],
    });
  }

  if (dados.conselhos) {
    documents.push(...dados.conselhos);
  }

  var nacionalidade;

  switch (parseInt(dados.nacionalidade)) {
    case 1:
      nacionalidade = 'brasileiro';
      break;
    case 2:
      nacionalidade = 'naturalizado';
      break;
    case 3:
      nacionalidade = 'estrangeiro';
      break;
    default:
      nacionalidade = null;
      break;
  }

  if (dados.especialidade && Array.isArray(dados.especialidade)) {
    let _temp = dados.especialidade.map((x) => {
      return {
        medical_speciality_id: x.id,
      };
    });

    dados.especialidade = _temp;
  }

  var new_dados = {
    user: {
      presentation: dados.apresentacao,
      name: dados.nome,
      social_name: dados.nomeSocial,
      birth_date: new Date(dados.dataNascimento).toLocaleDateString(),
      email: dados.email,
      nationality: nacionalidade,
      password: dados.senha,
      mother_name: dados.nomeMae,
      username: dados.id,
    },
    demographic_data: {
      state_birth: dados.estadoNascimento,
      city_birth: dados.municipioNascimento,
      sex: dados.sexo,
      genre: dados.genero,
      breed: dados.raca,
      etnia: dados.etnia,
      sexual_orientation_id: dados.orientacaoSexual,
    },
    contact: dados.telefones.map(function (telefone) {
      return {
        type: 'telefone',
        value: telefone.telefone,
        observation: telefone.tipoTelefone,
      };
    }),
    address: {
      address_name: 'Residêncial',
      zip_code: dados.cep.replace(/[^0-9]/gm, ''),
      street: dados.endereco,
      number: dados.numero,
      district: dados.bairro,
      city_id: dados.municipio,
      address_type_id: dados.logradouro,
      complement: dados.complemento,
    },
    document: documents,
    speciality: dados.especialidade,
  };

  return axios
    .post(
      `${env.API_URL}/user/store-by-invite?token=${token}`,
      JSON.stringify(new_dados),
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getProfissionalByFilter(
  token,
  active,
  cpf,
  cns,
  name,
  profile,
  medical_unit,
) {
  return axios
    .get(
      `${env.API_URL}/user/list/filter?active=${active}&cpf=${cpf}&cns=${cns}&name=${name}&profile_id=${profile}&medical_unit_id=${medical_unit}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getParams(params) {
  if (typeof params === 'object') {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

function listUserByMedicalUnit(token, medical_unit) {
  return axios
    .get(`${env.API_URL}/user/list/filter?medical_unit_id=${medical_unit}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getUsers(token) {
  return axios
    .get(`${env.API_URL}/user/list/filter/not-paginated`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data);
}

function listUserByMedicalUnitNotPaginated(token, medical_unit) {
  return axios
    .get(
      `${env.API_URL}/user/list/filter/not-paginated?medical_unit_id=${medical_unit}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function listChatUserByMedicalSpeciality(
  token,
  medicalUnitId,
  medicalSpecialityId,
) {
  return axios
    .get(
      `${env.API_URL}/user/list-medical-speciality/filter?medical_unit_id=${medicalUnitId}&medical_speciality_ids=${medicalSpecialityId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function filterProfessional(token, paramsFilter) {
  var uri = new URL(`${env.API_URL}/user/list/filter`);

  if (paramsFilter && paramsFilter.id) {
    return axios
      .get(`${env.API_URL}/healthAttendance/${paramsFilter.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      })
      .then((res) => res.data)
      .catch((error) => error.response);
  }

  var params = getParams(paramsFilter); // Separa os parâmetros a serem filtrados

  if (params && Array.isArray(params)) {
    // Monta o filtro para pesquisa de profissionais
    params.forEach((param) => {
      uri.searchParams.append(param.key, param.value);
    });
  }

  return axios
    .get(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getUserAvatar(token) {
  return axios
    .get(`${env.API_URL}/user/show-avatar`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUserStatus(token) {
  return axios
    .get(`${env.API_URL}/user/total-status`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMenusByProfileAndUnity(token, medical_unit_id, profile_id) {
  return axios
    .post(
      `${env.API_URL}/acl?profile_id=${profile_id}&medical_unit_id=${medical_unit_id}&token=${token}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function listUserByMedicalSpeciality(
  token,
  medicalUnitId,
  medicalSpecialityId,
) {
  return axios
    .get(
      `${env.API_URL}/user/list-medical-speciality-by-medical-unit/filter?requester_medical_unit_id=${medicalUnitId}&medical_speciality_ids=${medicalSpecialityId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': `${token}`,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function listMedicalUnitOnlineUsers(token, medicalUnitId) {
  return axios
    .get(`${env.API_URL}/user/list-logged?medical_unit_id=${medicalUnitId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getDefaultPermission(token, perfilId) {
  let params = perfilId ? `profileId=${perfilId}` : ``;
  return axios
    .get(`${env.API_URL}/user/default-permission?${params}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': `${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllUser,
  fetchCurrentUserDetails,
  getMeLogin,
  getMeByInvite,
  createUser,
  createUserMedicalUnit,
  deleteUserMedicalUnit,
  blockUser,
  unblockUser,
  deleteUser,
  resendInvite,
  getMedicalUnitesById,
  getPossibleUserProfiles,
  getProfissionalByFilter,
  getAllUserStatus,
  getMenusByProfileAndUnity,
  listUserByMedicalSpeciality,
  listUserByMedicalUnit,
  listUserByMedicalUnitNotPaginated,
  listChatUserByMedicalSpeciality,
  listMedicalUnitOnlineUsers,
  getDefaultPermission,
};
