import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Logo from '../../../assets/img/LogoTelemed.png';
import { Grid } from '@material-ui/core';
import {
  Person,
  CreditCard,
  CalendarToday as Calendar,
} from '@material-ui/icons';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 225,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMenuDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: 72,
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  active: {
    background: 'rgba(255, 255, 255, 0.16) !important',
    borderBottomColor: '#FFF !important',
  },
}));

export default function Navbar(props) {
  const classes = useStyles();

  const logoPlataforma = () => {
    try {
      const json = localStorage.getItem('theme');

      if (JSON.parse(json)) {
        return JSON.parse(json).logoPlataforma;
      } else {
        return Logo;
      }
    } catch (e) {
      return Logo;
    }
  };

  return (
    <div className={classes.grow} id="navbar-visualizador">
      <AppBar position="static" classes={{ root: 'bg-white' }}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            <img src={logoPlataforma()} alt="Product" width={160} />
          </Typography>
          <div className={classes.grow} />
          <Grid
            className={`${classes.sectionMenuDesktop} nav-items`}
            alignItems="center"
          >
            <Typography className="text-primary">
              Resultado de exames anteriores
            </Typography>

            <Typography className="text-primary">
              <Person className="text-primary" /> &nbsp;&nbsp;
              {props.data && props.data.nome_paciente}
            </Typography>
            <Typography className="text-primary">
              <CreditCard className="text-primary" /> &nbsp;&nbsp; CNS:{' '}
              {props.data && props.data.cns}
            </Typography>
            <Typography className="text-primary">
              <Calendar className="text-primary" /> &nbsp;&nbsp; Data:{' '}
              {props.data && props.data.data}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
