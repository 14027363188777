import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { Clear } from '@material-ui/icons';
import DButton from '../DButton';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      textAlign: 'center',
      width: 304,
      // top: "10%",
      // left: "50%",
      // marginLeft: "-152px",
      backgroundColor: theme.palette.background.snow,
      border: `1px solid ${theme.palette.neutral.midgrey}`,
      // boxShadow: theme.shadows[5],
      borderRadius: 15,
    },
    headermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 15px',
      borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    },
    footermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '10px 15px',
      borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    },
    clearClass: {
      color: theme.palette.neutral.midgrey,
      cursor: 'pointer',
    },
  }),
);

const useStylesFullWidth = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
      maxWidth: 770,
      // top: "15px",
      // left: "50%",
      // marginLeft: "-385px",
      backgroundColor: theme.palette.background.snow,
      border: `1px solid ${theme.palette.neutral.midgrey}`,
      // boxShadow: theme.shadows[5],
      borderRadius: 15,
    },
    headermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3px 15px',
      borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    },
    footermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '3px 15px',
      borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    },
  }),
);

export default function ActionModal(props) {
  const classes = useStyles();
  const classesFull = useStylesFullWidth();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div
      style={modalStyle}
      className={props.fullWidth ? classesFull.paper : classes.paper}
    >
      <div
        className={
          props.fullWidth ? classesFull.headermodal : classes.headermodal
        }
      >
        {props.title ? (
          <h2 className="simple-modal-title text-mt text-regular">
            {props.title}
          </h2>
        ) : (
          <h2>&nbsp;</h2>
        )}
        <Clear
          className={classes.clearClass}
          onClick={(e) => props.onClose(false)}
        />
      </div>
      <div style={{ padding: '15px' }}>{props.children}</div>
      <ActionModal />
      <div
        className={`${props.fullWidth ? classesFull.footermodal : classes.footermodal} tema-light`}
      >
        {props.loadingButton == true ? (
          <DButton onClick={(e) => props.onClose(true)} variant="primary">
            <CircularProgress size={20} color="inherit" />
          </DButton>
        ) : (
          <DButton onClick={(e) => props.onClose(true)} variant="primary">
            {props.labelButton ? props.labelButton : 'Fechar'}
          </DButton>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
