import React from 'react';
import { withStyles } from '@material-ui/core';
import { Component } from 'react';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Modal,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BiomedidasCreate from './component/Biomedidas/BiomedidasCreate';
import { Bio } from './../../api/biomedidas';
import { ThemeContext } from '../../context/ThemeContext';

import BiomedidasDestroy from './component/Biomedidas/BiomedidasDestroy';

const stylesv = (theme) => ({
  primary: {
    color: `${theme.palette.primary.light} !important`,
  },
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: `${theme.palette.primary.light} !important`,
    borderColor: `${theme.palette.primary.light} !important`,
    textTransform: 'none',
  },
  iconPrimary: {
    color: `${theme.palette.primary.light} !important`,
  },
  iconError: {
    color: `${theme.palette.support.error} !important`,
  },
});

class Biomedidas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isList: true,
      isCreate: false,
      loadingMedicalUnits: true,
      biomedida: null,
      biomedidas: [],
      Symptoms: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      open: false,
      modalStyle: this.getModalStyle(),
      error: [],
    };
  }

  componentDidMount() {
    const { theme, setTheme } = this.context;

    if (theme.additionalData.create)
      this.setState({
        ...this.state,
        isList: false,
        isCreate: true,
        biomedida: theme.additionalData.biomedida,
      });

    Bio.get(localStorage.getItem('token')).then((res) => {
      if (res.data.status)
        this.setState({
          ...this.state,
          biomedidas: res.data.details.biomeasures,
        });
    });
  }

  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      width: '626px',
    };
  }

  confirmDelete(biomedida) {
    this.setState({ ...this.state, open: true, biomedida });
  }

  create() {
    const { theme, setTheme } = this.context;
    setTheme({
      ...theme,
      sidebar: false,
      additionalData: {
        create: true,
        biomedida: null,
      },
    });
  }

  edit(biomedida) {
    const { theme, setTheme } = this.context;
    setTheme({
      ...theme,
      sidebar: false,
      additionalData: {
        create: true,
        biomedida: biomedida,
      },
    });
  }

  back() {
    const { theme, setTheme } = this.context;
    setTheme({
      ...theme,
      sidebar: true,
      additionalData: {
        create: false,
        biomedida: null,
      },
    });
  }

  render() {
    const { classes } = this.props;
    const styles = {
      titleTop: { marginTop: 15, marginBottom: 24 },
      buttonToolbar: { marginTop: 24, marginBottom: 24 },
      flex: { display: 'flex' },
      listItem: {
        padding: '12px 0',
        margin: 0,
        borderTop: '1px solid #c7c7c7',
      },
      firstListItem: {
        padding: '12px 0',
        margin: 0,
      },
      paper: {
        position: 'absolute',
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        backgroundColor: 'white',
        border: '1px solid #949494',
        borderRadius: 15,
      },
      headermodal: {
        height: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '3px 15px',
        borderBottom: 'solid 1px #C7C7C7',
      },
      footermodal: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 16,
        borderTop: 'solid 1px #C7C7C7',
        marginTop: 8,
      },
    };

    const StyledTip = withStyles({
      tooltip: {
        backgroundColor: '#272727',
        fontWeight: 400,
        fontSize: 14,
        padding: 8,
      },
      arrow: {
        color: '#272727',
      },
    })(Tooltip);

    return (
      <Container className="tema-light">
        {this.state.isList ? (
          <>
            <Typography
              style={styles.titleTop}
              variant="h3"
              className="text-primary"
            >
              Atendimento
            </Typography>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md={9}>
                    <Typography variant="h4">Biomedidas e exames</Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={styles.flex}
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="outlined"
                      size="sm"
                      startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                      className={classes.addTagButton}
                      onClick={(e) => this.create()}
                    >
                      Adicionar medição
                    </Button>
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 24 }}>
                  <Grid item md={12}>
                    <List>
                      {this.state.biomedidas.length > 0
                        ? this.state.biomedidas.map((row, index) => (
                            <ListItem
                              style={
                                index == 0
                                  ? styles.firstListItem
                                  : styles.listItem
                              }
                            >
                              <ListItemText primary={row.name} />
                              <ListItemSecondaryAction>
                                <StyledTip
                                  title="Excluir"
                                  placement="right"
                                  arrow
                                >
                                  <IconButton
                                    size="sm"
                                    onClick={(e) => {
                                      this.confirmDelete(row);
                                    }}
                                  >
                                    {row.isLoading ? (
                                      <CircularProgress
                                        size={15}
                                        color="inherit"
                                      />
                                    ) : (
                                      <DeleteIcon
                                        style={{ fontSize: 20 }}
                                        className={classes.iconError}
                                      />
                                    )}
                                  </IconButton>
                                </StyledTip>

                                <StyledTip
                                  title="Editar"
                                  placement="right"
                                  arrow
                                >
                                  <IconButton
                                    size="sm"
                                    onClick={(e) => {
                                      this.edit(row);
                                    }}
                                  >
                                    <EditIcon
                                      style={{ fontSize: 20 }}
                                      className={classes.iconPrimary}
                                    />
                                  </IconButton>
                                </StyledTip>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))
                        : 'Não existem biomedidas e exames cadastrados'}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        ) : (
          ''
        )}

        {this.state.isCreate ? (
          <BiomedidasCreate
            biomedidas={this.state.biomedidas}
            biomedida={this.state.biomedida}
            listar={(e) => this.back()}
          />
        ) : (
          ''
        )}

        {this.state.open && (
          <BiomedidasDestroy
            biomedida={this.state.biomedida}
            open={this.state.open}
            onRemoved={(e) => {
              Bio.get(localStorage.getItem('token')).then((res) => {
                if (res.data.status)
                  this.setState({
                    ...this.state,
                    open: false,
                    biomedidas: res.data.details.biomeasures,
                    biomedida: null,
                  });
                else
                  this.setState({
                    ...this.state,
                    open: false,
                    biomedidas: [],
                    biomedida: null,
                  });
              });
            }}
            onClose={(close) =>
              this.setState({ ...this.state, open: false, biomedida: null })
            }
          />
        )}
      </Container>
    );
  }
}

Biomedidas.contextType = ThemeContext;

export default withStyles(stylesv)(Biomedidas);
