import { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  healthAttendanceList,
  getHealthAttendanceByPage,
  getHealthAttendanceByPageFilter,
  getHealthAttendanceByPageFilterParams,
} from '../../../../../api/health_attendances';
import { AppContext } from '../../../../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '50%',
    minWidth: 320,
  },
}));

function AtendimentoFilter(props) {
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  const debouncedSearch = useDebounce(search, 1000);

  const {
    AttendanceFilter,
    setAttendanceFilter,
    setAttendancesList,
    lockAdministrativeUnitMenu,
    updateAcl,
    totais,
    setTotais,
  } = useContext(AppContext);

  useEffect(() => {
    if (debouncedSearch || debouncedSearch == '') {
      setLoadingSearch(true);

      setAttendanceFilter({ ...AttendanceFilter, search: debouncedSearch });

      getHealthAttendanceByPageFilter(
        localStorage.getItem('token'),
        1,
        {
          ...AttendanceFilter,
          search: debouncedSearch,
          service_character: '1,2,3,4',
        },
        { service_character: '1,2,3,4' },
      )
        .then((res) => {
          if (res.status) {
            props.setAttendances(res.healthAttendances.patient);
            props.setPagination(res.healthAttendances.paginate);
            setAttendancesList(res.healthAttendances.patient);
            setTotais({
              ...totais,
              status: {
                ...totais.status,
                todos: res.healthAttendances.totals[0],
                assumido: res.healthAttendances.totals[2],
                aberto: res.healthAttendances.totals[1],
                finalizado: res.healthAttendances.totals[3],
              },
              quadro: {
                ...totais.quadro,
                todos: res.healthAttendances.totals[5],
                leve: res.healthAttendances.totals[6],
                moderado: res.healthAttendances.totals[7],
                grave: res.healthAttendances.totals[8],
                nao_indicativo: res.healthAttendances.totals[9],
              },
            });
          }
          setLoadingSearch(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingSearch(false);
        });
    }
  }, [debouncedSearch]);

  const classes = useStyles();

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
      autoComplete="off"
    >
      <OutlinedInput
        id="outlined"
        type="text"
        placeholder="Buscar por nome, protocolo ou cpf"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          props.clearFilteredData();
        }}
        endAdornment={
          loadingSearch ? (
            <CircularProgress size={20} className={classes.loading} />
          ) : (
            <SearchIcon />
          )
          // <InputAdornment position="end">
          //     <IconButton
          //         aria-label="Search"
          //         onClick={e => null}
          //         edge="end">
          //     </IconButton>
          // </InputAdornment>
        }
        labelWidth={0}
      />
    </FormControl>
  );
}

export default AtendimentoFilter;
