import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import orgaoEmissorCode from '../../../../../../../../assets/dados/orgaoEmissor.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { useStyles } from './styles';

interface BrazilianIdentityProps {
  states: { id: number; value: string; description: string }[];
}

export const BrazilianIdentity = ({ states }: BrazilianIdentityProps) => {
  const classes = useStyles();
  const { dadosIdentidadeBrasileiro, dadosIdentidadeGeral } = usePatient();

  const orgaoEmissorMap = useMemo(
    () =>
      orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Número de identidade:</strong>{' '}
        {dadosIdentidadeBrasileiro.identidadeNumero
          ? dadosIdentidadeBrasileiro.identidadeNumero
          : 'Não informado'}
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Data de emissão:</strong>{' '}
        {dadosIdentidadeBrasileiro.identidadeDataEmissao
          ? dadosIdentidadeBrasileiro.identidadeDataEmissao
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Órgão emissor:</strong>{' '}
        {orgaoEmissorMap.find(
          (oe) => oe.value === dadosIdentidadeBrasileiro.identidadeOrgaoEmissor,
        )
          ? orgaoEmissorMap.find(
              (oe) =>
                oe.value === dadosIdentidadeBrasileiro.identidadeOrgaoEmissor,
            )?.value
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>UF:</strong>{' '}
        {states?.find(
          (state) => state.id === dadosIdentidadeBrasileiro.identidadeUF,
        )
          ? states?.find(
              (state) => state.id === dadosIdentidadeBrasileiro.identidadeUF,
            )?.description
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Nome da mãe:</strong>{' '}
        {dadosIdentidadeGeral.maeDesconhecida
          ? 'Mãe desconhecida'
          : dadosIdentidadeGeral.nomeMae
            ? dadosIdentidadeGeral.nomeMae
            : 'Não informado'}
      </Grid>

      <Grid item xs={4} className={classes.field_padding}>
        <strong>Nome do pai:</strong>{' '}
        {dadosIdentidadeGeral.paiDesconhecido
          ? 'Pai desconhecido'
          : dadosIdentidadeGeral.nomePai
            ? dadosIdentidadeGeral.nomePai
            : 'Não informado'}
      </Grid>
    </Grid>
  );
};
