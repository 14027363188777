import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import DAutoComplete from '../../../../../../shared/DAutoComplete';
import DInput from '../../../../../../shared/DInput';
import DRadio from '../../../../../../shared/DRadio';

const EBSelect = ({
  id,
  label,
  name,
  placeholder,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [selected, setSelected] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      additional_data.options.map(function (item) {
        return { id: item.value, value: item.label };
      }),
    );

    if (answer.length > 0) {
      setSelected(answer[0]);
    }
  }, []);

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: '1em', display: 'flex' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${additional_data.construct.showTitle ? label : ''}`,
            }}
          />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
            {is_required === 1 ? '*' : ''}
          </span>
        </Typography>
        <Select
          fullWidth={true}
          disabled={readOnly}
          label={
            additional_data.construct.showTitle
              ? additional_data.construct.showTitle
              : ''
          }
          labelId="select-operator-label"
          id="select-operator"
          value={selected}
          error={erro[0] ? erro[0].value : ''}
          helperText={erro[0] ? erro[0].value : ''}
          required={is_required === 1 ? true : false}
          onChange={(event) => {
            if (erro !== null && erro.length > 0) {
              delete erro[0].value;
            }
            setSelected(event.target.value);
          }}
          onBlur={(e) => {
            !readOnly
              ? handleInputSubmit({ [id]: selected })
              : console.log('effect onBlur');
          }}
        >
          <MenuItem value="" disabled>
            {' '}
            {placeholder ? placeholder : ''}{' '}
          </MenuItem>
          {additional_data.options.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
        <br />
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBSelect;
