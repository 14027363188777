import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useStyles } from './theme';
import clsx from 'clsx';

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.rootRadio}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIconRadio)} />
      }
      icon={<span className={classes.iconRadio} />}
      {...props}
    />
  );
}

export function Assumir(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ marginTop: 50, marginBottom: 48 }}
    >
      <Grid item>
        <ErrorOutlineIcon
          fontSize="small"
          color="primary"
          classes={{
            colorPrimary: classes.iconColorConfirm,
            fontSizeSmall: classes.iconConfirm,
          }}
        />
      </Grid>
      <Grid item style={{ marginTop: 18 }}>
        <Typography
          classes={{ root: classes.textColor }}
          variant="body1"
          className="text-bold text-el"
        >
          Atenção
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: 24 }}>
        <Typography
          classes={{ root: classes.textColor }}
          variant="body1"
          align="center"
          className="text-regular text-mt"
        >
          Você foi convidado a participar da interconsulta para o<br />
          atendimento {props.protocolo}.<br />
          Deseja iniciá-la agora?
        </Typography>
      </Grid>
    </Grid>
  );
}

export function Abdicar(props) {
  const classes = useStyles();

  let options = [
    { label: 'Paciente não encontrado', value: 'Paciente não encontrado' },
    { label: 'Paciente', value: 'Paciente' },
    { label: 'Desistiu do atendimento', value: 'Desistiu do atendimento' },
    { label: 'Outro', value: 'Outro' },
  ];

  const [value, setValue] = useState(null);
  const [outro, setOutro] = useState('');

  useEffect(() => {
    props.onChange(value, outro);
  }, [value, outro]);

  return (
    <Grid container style={{ padding: 16 }}>
      <Grid item md={12}>
        <FormControl classes={{ root: classes.fullWidth }} component="fieldset">
          <FormLabel
            classes={{
              root: classes.textColorGreen,
              focused: classes.colorGreen,
            }}
            component="legend"
          >
            Motivo
          </FormLabel>
          <RadioGroup
            aria-label="motivo"
            name="motivo"
            style={{ padding: 10 }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={`motivo-radio-options-${index}`}
                classes={{
                  root:
                    value === 'Outro' && option.value === value
                      ? classes.hidden
                      : classes.show,
                  label: classes.radioLabel,
                }}
                value={option.value}
                control={<StyledRadio />}
                label={option.label}
              />
            ))}
            {value === 'Outro' && (
              <Grid container alignItems="flex-end">
                <Grid item>
                  <FormControlLabel
                    classes={{ root: classes.radioOutros }}
                    value="Outro"
                    control={<StyledRadio checked />}
                  />
                </Grid>
                <Grid item md={10} className="text-field-green">
                  <TextField
                    fullWidth
                    focused
                    label="Outro"
                    placeholder="Outro"
                    value={outro}
                    onChange={(e) => setOutro(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
