import { Divider } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';

const EBHr = ({ id, label, placeholder, value }) => {
  return (
    <section>
      <div>
        <Divider
          variant="fullWidth"
          style={{ marginTop: 30, marginBottom: 20 }}
        />
      </div>
    </section>
  );
};

export default EBHr;
