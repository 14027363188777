import IconDoctor from './../../../assets/img-icon/DoctorIcon.svg';
import IconCog from './../../../assets/img-icon/CogIcon.svg';
import ListCheck from './../../../assets/img-icon/listCheckIcon.svg';
import IdentidadeVisual from './../../../assets/img-icon/IdentidadeVisualIcon.svg';
import Tree from './../../../assets/img-icon/TreeIcon.svg';
import List from './../../../assets/img-icon/ListIcon.svg';

function DoctorIcon(props) {
  return <img src={IconDoctor} {...props} />;
}

function CogIcon(props) {
  return <img src={IconCog} {...props} />;
}

function CogGrayIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color ? props.color : '#949494'}
        d="M13.8274 8.7685C13.8588 8.51752 13.8823 8.26654 13.8823 7.99988C13.8823 7.73321 13.8588 7.48223 13.8274 7.23125L15.4823 5.93713C15.6314 5.81948 15.6706 5.60772 15.5765 5.43517L14.0078 2.72144C13.9137 2.5489 13.702 2.48615 13.5294 2.5489L11.5765 3.33321C11.1686 3.01948 10.7294 2.76066 10.251 2.56458L9.95293 0.48615C9.9294 0.297915 9.7647 0.156738 9.56862 0.156738H6.43136C6.23528 0.156738 6.07058 0.297915 6.04705 0.48615L5.74901 2.56458C5.27058 2.76066 4.83136 3.02733 4.42352 3.33321L2.47058 2.5489C2.29019 2.47831 2.08626 2.5489 1.99215 2.72144L0.423518 5.43517C0.321558 5.60772 0.368616 5.81948 0.517636 5.93713L2.17254 7.23125C2.14117 7.48223 2.11764 7.74105 2.11764 7.99988C2.11764 8.2587 2.14117 8.51752 2.17254 8.7685L0.517636 10.0626C0.368616 10.1803 0.329401 10.392 0.423518 10.5646L1.99215 13.2783C2.08626 13.4509 2.29803 13.5136 2.47058 13.4509L4.42352 12.6665C4.83136 12.9803 5.27058 13.2391 5.74901 13.4352L6.04705 15.5136C6.07058 15.7018 6.23528 15.843 6.43136 15.843H9.56862C9.7647 15.843 9.9294 15.7018 9.95293 15.5136L10.251 13.4352C10.7294 13.2391 11.1686 12.9724 11.5765 12.6665L13.5294 13.4509C13.7098 13.5214 13.9137 13.4509 14.0078 13.2783L15.5765 10.5646C15.6706 10.392 15.6314 10.1803 15.4823 10.0626L13.8274 8.7685ZM7.99999 10.745C6.48626 10.745 5.25489 9.5136 5.25489 7.99988C5.25489 6.48615 6.48626 5.25478 7.99999 5.25478C9.51372 5.25478 10.7451 6.48615 10.7451 7.99988C10.7451 9.5136 9.51372 10.745 7.99999 10.745Z"
      />
    </svg>
  );
}

function PersonPinIcon(props) {
  // return (<img src={PersonPin} { ...props } />)
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 2H5C3.89 2 3 2.9 3 4V18C3 19.1 3.9 20 5 20H9L11.29 22.29C11.68 22.68 12.31 22.68 12.7 22.29L15 20H19C20.1 20 21 19.1 21 18V4C21 2.9 20.1 2 19 2ZM12 5.3C13.49 5.3 14.7 6.51 14.7 8C14.7 9.49 13.49 10.7 12 10.7C10.51 10.7 9.3 9.49 9.3 8C9.3 6.51 10.51 5.3 12 5.3ZM18 16H6V15.1C6 13.1 10 12 12 12C14 12 18 13.1 18 15.1V16Z" />
    </svg>
  );
}

function ListCheckIcon(props) {
  return <img src={ListCheck} {...props} />;
}

function IdentidadeVisualIcon(props) {
  return <img src={IdentidadeVisual} {...props} />;
}

function TreeIcon(props) {
  return <img src={Tree} {...props} />;
}

function ListIcon(props) {
  return <img src={List} {...props} />;
}

function DownloadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
      </g>
    </svg>
  );
}

export function UploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M5,20h14v-2H5V20z M5,10h4v6h6v-6h4l-7-7L5,10z" />
      </g>
    </svg>
  );
}

export function LogoutIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
      </g>
    </svg>
  );
}

export function TrashIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
        stroke="#FF6B57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
        stroke="#FF6B57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8499 9.14001L18.1999 19.21C18.0899 20.78 17.9999 22 15.2099 22H8.7899C5.9999 22 5.9099 20.78 5.7999 19.21L5.1499 9.14001"
        stroke="#FF6B57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3301 16.5H13.6601"
        stroke="#FF6B57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 12.5H14.5"
        stroke="#FF6B57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12H16"
        stroke="#6893F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V8"
        stroke="#6893F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#6893F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export {
  DoctorIcon,
  CogIcon,
  PersonPinIcon,
  ListCheckIcon,
  IdentidadeVisualIcon,
  TreeIcon,
  ListIcon,
  CogGrayIcon,
  DownloadIcon,
};
