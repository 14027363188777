import axios from 'axios';
import env from 'react-dotenv';
import { getFormatDate } from '../../helpers/formatacao';

export async function listNotification(token, uid) {
  let url = new URL(env.API_URL).origin;
  const uri = new URL(`${url}/notification/list/${uid}`);

  uri.searchParams.append('token', token);

  const response = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
    },
  });
  return response.data;
}

export async function readNotification(token, id, user_id, notification_id) {
  let url = new URL(env.API_URL).origin;
  const response = await axios.get(
    `${url}/notification/read/${id}?token=${token}`,
    {
      id,
      notification_id,
      user_id,
      read: 1,
      read_at: getFormatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );

  return response.data;
}
