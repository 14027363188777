import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import configuration from 'api/configuration';
import { useConfiguracaoAgendaGeral } from 'context/ConfiguracaoAgendaGeralContext';
import { useEffect, useState } from 'react';
import EnvioLembretes from './EnvioLembretes';
import GerenciamentoAtendimento from './GerenciamentoAtendimento';
import ParametrosGerais from './ParametrosGerais';

const useStyles = makeStyles((theme) => ({
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
}));

function ConfiguracaoGeral() {
  const classes = useStyles();
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(false);

  const {
    setAgendarAplicativo,
    setEncaixe,
    setRetorno,
    setDiasAgendamento,
    setPeriodoRetorno,
    handleLembrete,
  } = useConfiguracaoAgendaGeral();

  useEffect(() => {
    setLoading(true);

    configuration.get(token).then((res) => {
      if (res.status) {
        setAgendarAplicativo(Boolean(res.configuration.schedule_app));
        setEncaixe(Boolean(res.configuration.allow_fit));
        setRetorno(Boolean(res.configuration.allow_return_schedule));
        setDiasAgendamento(String(res.configuration.schedule_days));
        setPeriodoRetorno(String(res.configuration.range_max_return));
      }

      setLoading(false);
    });
  }, []);

  return (
    <Card>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: 'white' }} />
      </Backdrop>
      <CardContent>
        <Typography
          variant="h3"
          component="h1"
          classes={{ root: classes.titulo }}
        >
          Parâmetros gerais
        </Typography>
        <ParametrosGerais />
        <GerenciamentoAtendimento />
        <EnvioLembretes />
      </CardContent>
    </Card>
  );
}

export default ConfiguracaoGeral;
