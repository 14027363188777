import env from 'react-dotenv';
import axios from 'axios';

function getAllMedicalSpecialties(token) {
  return axios
    .get(`${env.API_URL}/medicalSpeciality`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMedicalSpecialtyById(token, id) {
  return axios
    .get(`${env.API_URL}/medicalSpeciality/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createMedicalSpecialty(token, dados) {
  return axios
    .post(`${env.API_URL}/medicalSpeciality`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateMedicalSpecialty(token, dados, id) {
  return axios
    .put(`${env.API_URL}/medicalSpeciality/${id}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateStatusMedicalSpecialty(token, specialtyId, status) {
  // status: {active: 1} || {active: 0}

  return axios
    .patch(
      `${env.API_URL}/medicalSpeciality/${specialtyId}`,
      JSON.stringify(status),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteMedicalSpecialty(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMedicalSpecialtyPermission(token, id) {
  return axios
    .get(`${env.API_URL}/medicalSpeciality/${id}/list-permission`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalSpecialtyPermissionCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalSpeciality/${id}/call-enabled`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalSpecialtyPermissionCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}/call-enabled`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalSpecialtyPermissionSequentialCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalSpeciality/${id}/sequential-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalSpecialtyPermissionSequentialCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}/sequential-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalSpecialtyPermissionGeneralGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalSpeciality/${id}/call-in-general-group`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalSpecialtyPermissionGeneralGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}/call-in-general-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalSpecialtyPermissionTotalGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalSpeciality/${id}/call-in-total-group`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalSpecialtyPermissionTotalGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}/call-in-total-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalSpecialtyPermissionDirectCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalSpeciality/${id}/direct-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalSpecialtyPermissionDirectCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalSpeciality/${id}/direct-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllMedicalSpecialties,
  getMedicalSpecialtyById,
  createMedicalSpecialty,
  updateMedicalSpecialty,
  deleteMedicalSpecialty,
  updateStatusMedicalSpecialty,
  getMedicalSpecialtyPermission,
  enableMedicalSpecialtyPermissionCall,
  disableMedicalSpecialtyPermissionCall,
  enableMedicalSpecialtyPermissionSequentialCall,
  disableMedicalSpecialtyPermissionSequentialCall,
  enableMedicalSpecialtyPermissionGeneralGroup,
  disableMedicalSpecialtyPermissionGeneralGroup,
  enableMedicalSpecialtyPermissionTotalGroup,
  disableMedicalSpecialtyPermissionTotalGroup,
  enableMedicalSpecialtyPermissionDirectCall,
  disableMedicalSpecialtyPermissionDirectCall,
};
