import { Button, useTheme, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  AssignmentInd,
  BarChart,
  CalendarTodayRounded,
  ChatBubble,
  Help,
  LocalHospital,
  Person,
  Settings,
} from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { executeLogout } from '../../../../../api/auth';
import { getUserAvatar, getUserInfoById } from '../../../../../api/user';
import { AppContext } from '../../../../../context/AppContext';
import { useMenus } from '../../../../../context/MenuContext';
import { LogoutIcon } from '../../../Icon/Icon';
import Logo from './../../../../../assets/img/LogoEsaude.png';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 225,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    color: theme.palette.primary.light,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMenuDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: 72,
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  active: {
    background: 'rgba(255, 255, 255, 0.16) !important',
    borderBottomColor: '#FFF !important',
  },
  normal: {},
  button_label: {
    textTransform: 'none',
    fontWeight: 400,
  },
  userBox: {
    background: '#d4f5fa !important',
    color: 'black',
    width: 48,
    height: 48,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    height: '100%',
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
    border: 'solid 2px transparent',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.16);',
      boxShadow: 'none',
      borderBottomColor: '#FFF',
    },
  },
}))(Button);

const iconsMap = {
  people_alt_icon: PeopleAltIcon,
  doctor_icon: AssignmentInd,
  bar_chart: BarChart,
  calendar_today_rounded: CalendarTodayRounded,
  local_hospital: LocalHospital,
  cog_icon: Settings,
  chat_bubble: ChatBubble,
};

export const UserIcon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  color: ${(props) => props.themeColor};
`;

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    openNotifications,
    setOpenNotifications,
    UnreadNotifications,
    avatar,
    setAvatar,
    currentMedicalUnit,
  } = useContext(AppContext);
  const { menus, setAtendimentoIndex } = useMenus();
  const [redirect, setRedirect] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorRelatorioEl, setAnchorRelatorioEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [menus, setMenus] = useState([]);
  const [user, setUser] = useState(null);

  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const addDefaultSrc = (ev) => {
    ev.target.src = Logo;
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    executeLogout(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        // history.push("/login")
        window.location = '/login';
      } else {
        // history.push("/login")
        window.location = '/login';
      }
    });
  };

  const handleRelatorioMenuOpen = (event) => {
    setAnchorRelatorioEl(event.currentTarget);
  };

  const handleRelatorioMenuClose = () => {
    setAnchorRelatorioEl(null);
  };

  const getInitialsName = (value) => {
    if (!value) return;
    value = value.split(' ');

    if (value.length > 0) {
      return value.length > 1
        ? value[0].charAt(0).toUpperCase() +
            value[value.length - 1].charAt(0).toUpperCase()
        : value[0].charAt(0).toUpperCase();
    } else {
      return value;
    }
  };

  useEffect(() => {
    getUserAvatar(localStorage.getItem('token'))
      .then((data) => {
        if (data.status) {
          setAvatar(data.avatar);
        }
      })
      .catch((err) => console.error(err));

    getUserInfoById(
      localStorage.getItem('token'),
      localStorage.getItem('uid'),
    ).then((data) => {
      if (data.status) {
        setUser(data.user);
      }
    });
  }, []);

  const handleGoToProfile = () => {
    history.push('/meu-perfil');
    setAnchorEl(null);
  };

  const handleGoToFaq = () => history.push('/faq-user');

  const handleGoToHome = () => {
    if (localStorage.getItem('route_default')) {
      history.push(localStorage.getItem('route_default'));
    } else {
      history.push('/meu-perfil');
    }
  };

  const handleGoToHistoricoAcoes = () => {
    setAnchorRelatorioEl(null);
    history.push('/historico-acoes');
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleGoToProfile}>
        <Person style={{ color: theme.palette.primary.light }} />
        &nbsp;&nbsp; Meu perfil
      </MenuItem>
      <MenuItem
        onClick={() =>
          window.open('https://duo-app-poc-moodle.azurewebsites.net/', '_blank')
        }
      >
        <Help style={{ color: theme.palette.primary.light }} />
        &nbsp;&nbsp; Aprendizado
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <LogoutIcon fill={`${theme.palette.support.error}`} />
        &nbsp;&nbsp; Sair
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <Help />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 11 new notifications"
          color="inherit"
          onClick={(e) => {
            setOpenNotifications(!openNotifications);
          }}
        >
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      {/* <UserInfo menus={setMenus} /> */}
      {/* <SelectPermission onMenus={ setMenus } /> */}
      <AppBar
        style={{
          backgroundColor: theme.palette.background.snow,
          boxShadow: 'none',
        }}
        position="static"
      >
        <Toolbar>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={() => handleGoToHome()}
          >
            <img
              onError={addDefaultSrc}
              src={theme.palette.logoPlataforma || Logo}
              alt="Telemed 24h"
              width={160}
            />
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionMenuDesktop}>
            {Array.isArray(menus) &&
              menus.map((item, index) => {
                const Icon = iconsMap[item.icon];

                if (currentMedicalUnit) {
                  if (
                    currentMedicalUnit.medical_unit_type == 'Administrativa'
                  ) {
                    if (item.name.includes(['Atendimentos', 'Solicitações'])) {
                      return null;
                    }
                  } else if (
                    currentMedicalUnit.medical_unit_type == 'Assistencial'
                  ) {
                    if (currentMedicalUnit.funcao == 'Executante') {
                      if (item.name.includes(['Solicitações'])) {
                        return null;
                      }
                    } else if (currentMedicalUnit.funcao == 'Solicitante') {
                      if (item.name.includes(['Atendimentos'])) {
                        return null;
                      }
                    }
                  }
                }

                if (item.name == 'Relatórios') {
                  return (
                    <>
                      <ColorButton
                        key={index}
                        aria-controls="submenu-relatorios"
                        aria-haspopup="true"
                        classes={
                          props.active === item.slug
                            ? {
                                root: classes.active,
                                label: classes.button_label,
                              }
                            : { label: classes.button_label }
                        }
                        variant="contained"
                        color="primary"
                        onClick={handleRelatorioMenuOpen}
                        startIcon={<Icon />}
                      >
                        {item.name}
                      </ColorButton>

                      <Menu
                        id="submenu-relatorios"
                        anchorEl={anchorRelatorioEl}
                        keepMounted
                        open={Boolean(anchorRelatorioEl)}
                        onClose={handleRelatorioMenuClose}
                        className="mt-4"
                      >
                        {/* <MenuItem onClick={() => history.push(item.slug) }>Relatórios Gerais</MenuItem> */}
                        <MenuItem onClick={handleGoToHistoricoAcoes}>
                          Histórico de Ações
                        </MenuItem>
                        <MenuItem onClick={() => history.push('power-bi')}>
                          Relatórios Gerais
                        </MenuItem>
                      </Menu>
                    </>
                  );
                }

                return (
                  <ColorButton
                    onClick={() => {
                      if (item.name == 'Atendimentos') {
                        setAtendimentoIndex(true);
                      }
                      history.push(item.slug);
                    }}
                    key={index}
                    classes={
                      props.active === item.slug
                        ? { root: classes.active, label: classes.button_label }
                        : { label: classes.button_label }
                    }
                    variant="contained"
                    color="primary"
                    startIcon={<Icon />}
                  >
                    {item.name}
                  </ColorButton>
                );
              })}
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={handleGoToFaq}
            >
              <Badge badgeContent={0} color="secondary">
                <Help style={{ fontSize: 26 }} />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={(e) => {
                setOpenNotifications(!openNotifications);
              }}
            >
              <Badge badgeContent={UnreadNotifications} color="secondary">
                <NotificationsIcon style={{ fontSize: 26 }} />
              </Badge>
            </IconButton>
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle style={{ fontSize: 48 }} />
            </IconButton> */}
            {avatar ? (
              <UserIcon
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                themeColor={theme.palette.primary.light}
                src={avatar}
              />
            ) : (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className={classes.userBox}
              >
                {user &&
                  (user.social_name
                    ? getInitialsName(user.social_name)
                    : getInitialsName(user.full_name))}
              </IconButton>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
