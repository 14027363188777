import React, { Component, useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Popover from '@material-ui/core/Popover';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';
import DoctorIcon from '../../../../assets/img-icon/DoctorIconBlue.svg';
import MonitorIcon from '../../../../assets/img-icon/monitorIcon.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import BlockIcon from '@material-ui/icons/Block';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';

import DButton from '../../../shared/DButton';
import Person from '@material-ui/icons/Person';
import { getPatientById } from '../../../../api/patient';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getPatientDocuments } from '../../../../helpers/utils';
import { useTheme } from '@material-ui/styles';
import PatientInfo from '../PatientInfo';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useMenus } from './../../../../context/MenuContext';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getAgeByDate } from '../../../../helpers/formatacao';
import {
  LocalPhone,
  LocalPhoneOutlined,
  PhoneInTalk,
} from '@material-ui/icons';
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  item__card: {
    display: 'flex',
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
    boxShadow:
      '0px 1px 1px rgba(49, 34, 67, 0.1), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.1)',
  },
  item__card_onduty: {
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__card_rest: {
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__card_blocked: {
    display: 'flex',
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    backgroundColor: `${theme.palette.background.cloud}`,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__chip: {
    backgroundColor: theme.palette.primary.light,
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: '0 16px',
    marginRight: 12,
    height: 26,
  },
  item__chip_success: {
    backgroundColor: theme.palette.primary.light,
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: '0 16px',
    marginRight: 12,
    height: 26,
  },
  item__chip_cancelled: {
    backgroundColor: theme.palette.support.error,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: '0 16px',
    marginRight: 12,
    height: 26,
  },
  item__chip_onduty: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: '0 16px',
    marginRight: 12,
    height: 26,
  },
  item__chip_blocked: {
    backgroundColor: theme.palette.neutral.darkgray,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: '0 16px',
    marginRight: 12,
    height: 26,
  },
  dialog: {
    padding: 24,
    width: 320,
  },
  dialog__success: {
    backgroundColor: theme.palette.background.cloud,
    padding: 24,
  },
  close_button: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  success: {
    color: theme.palette.primary.main,
  },
  cancel: {
    color: theme.palette.support.error,
  },
  active: {
    color: theme.palette.primary.main,
  },
  consultas_popover: {
    listStyle: 'none',
    padding: 0,
    margin: '0 16px',
  },
  consultas_popoverItem: {
    listStyle: 'none',
    margin: 0,
    padding: '12px 0',
    fontSize: 12,
    color: theme.palette.neutral.black,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avisoAtraso: {
    fontSize: 18,
    color: theme.palette.primary.light,
    margin: 0,
  },
  primaryMargin: {
    fontSize: 18,
    color: theme.palette.primary.light,
    margin: 0,
  },
  bckContent: {
    borderRadius: 2,
    width: 4,
    backgroundColor: theme.palette.primary.main,
    height: '50px',
  },
  bckContentCanceled: {
    borderRadius: 2,
    width: 4,
    backgroundColor: theme.palette.support.error,
    height: '50px',
  },
  colorPrimary: {
    color: theme.palette.primary.light,
  },
  btnOpenClass: {
    textTransform: 'none',
    color: '#67738B',
    fontWeight: 500,
    height: 36,
    marginRight: 8,
  },
  card: {
    background: '#F4F4F4',
  },
  cardTitle: {
    marginBottom: 12,
  },
  cardBody: {
    padding: 15,
    paddingBottom: '15px !important',
  },
  container: {
    display: 'none',
  },
  containerOpen: {
    display: 'block',
  },
  mainDivTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  },
  tagInfo: {
    backgroundColor: '##F4F6F8',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#67738B',
    fontSize: '12px',
  },
  fontInfo: {
    color: '#67738B',
    fontSize: '12px',
  },
  startIconCustom: {
    margin: 0,
  },
  tagInfoHealthAttendance: {
    backgroundColor: '#EDF6FD',
    borderRadius: '30px',
    padding: '11px 5px',
    color: '#006FB5',
    fontSize: '12px',
    marginRight: 8,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'baseline',
  },
  statusHealthAttendanceOn: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.check,
    marginRight: 5,
  },
  statusHealthAttendanceOff: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.grey,
    marginRight: 5,
  },
}));

const StyledButtonAction = withStyles((theme) => ({
  root: {
    textTransform: 'unset',
    fontSize: 14,
    color: theme.palette.primary.light,
    fontWeight: '400 !important',
  },
}))(Button);

function AgendaItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { setAtendimentoIndex } = useMenus();
  const [openPatientData, setOpenPatientData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);

  const [patientInfo, setPatientInfo] = useState('');
  const [cpf, setCpf] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [delayopen, setDelayOpen] = React.useState(false);
  const handleDelayOpen = () => {
    setDelayOpen(true);
  };
  const handleDelayClose = () => {
    setDelayOpen(false);
  };
  const [delayopensuccess, setDelayOpenSuccess] = React.useState(false);
  const handleDelayOpenSuccess = () => {
    setDelayOpen(false);
    setDelayOpenSuccess(true);
  };
  const handleDelayCloseSuccess = () => {
    setDelayOpenSuccess(false);
  };

  const [cancelopen, setCancelOpen] = React.useState(false);
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const [cancelopensuccess, setCancelOpenSuccess] = React.useState(false);
  const handleCancelOpenSuccess = () => {
    setCancelOpen(false);
    setCancelOpenSuccess(true);
  };
  const handleCancelCloseSuccess = () => {
    setCancelOpenSuccess(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const getPatientName = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _patientData = data.schedule_participants.find(
          (x) => x.patient_id,
        );

        return _patientData?.patient?.full_name;
      }
    }
  };

  const getHealthAttendanceProtocol = (data) => {
    if (data && data.schedule_health_attendance) {
      if (Array.isArray(data.schedule_health_attendance)) {
        const _patientData = data.schedule_health_attendance.find(
          (x) => x.health_attendance_id,
        );

        return _patientData.health_attendance_id;
      }
    }
  };

  const getTypeOfConsultation = (data) => {
    if (data && data.anamnese_form) {
      return data.anamnese_form.type_service;
    }
  };

  const getProfessionalName = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _professionalData = data.schedule_participants.find(
          (x) => x.document_type === 'cns' && x.patient === null,
        );

        if (_professionalData) {
          return _professionalData.user.full_name
            ? _professionalData.user.full_name
            : _professionalData.user.social_name;
        }
      }
    }
  };

  const getPatientIdAndPatientInfo = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _patientData = data.schedule_participants.find(
          (x) => x.patient_id,
        );

        if (_patientData && _patientData.patient_id) {
          getPatientById(localStorage.getItem('token'), _patientData.patient_id)
            .then((data) => {
              if (data.status) {
                setPatientInfo(data.details.patient);

                // console.log(data.details.patient);
                var { patient_document } = data.details.patient;

                setCpf(
                  getPatientDocuments(patient_document, { description: 'CPF' })
                    .value,
                );
              }
            })
            .catch(console.error);
        }
      }
    }
  };

  const getScheduleHour = (hour) => {
    if (hour) {
      var d = new Date(hour);

      return format(d, 'HH:mm', { locale: ptBR });
    } else {
      return null;
    }
  };

  const getPatientUnity = (data) => {
    if (data) {
      if (Array.isArray(data.medical_unit) && data.medical_unit.length > 0) {
        return data.medical_unit[0].company_name;
      }
    }
  };

  const getHealthAttendanceStatus = (data) => {
    if (
      data &&
      data.schedule_health_attendance &&
      Array.isArray(data.schedule_health_attendance) &&
      data.schedule_health_attendance[0].health_attendance
    ) {
      switch (
        data.schedule_health_attendance[0].health_attendance
          .health_attendance_status_id
      ) {
        case 2:
          return 'Assumido';
        case 3:
          return 'Finalizado';
        case 4:
          return 'Cancelado';
        default:
          return 'Aguardando atendimento';
      }
    }
  };

  const renderTime = (data) =>
    data.data_time ? data.data_time.slice(11, -3) : '';

  useEffect(() => {
    getPatientIdAndPatientInfo(props.data);
    setAtendimentoIndex(true); // para garantir que faça a busca no AtendimentoList.jsx
  }, []);

  if (props.data && props.data.id > 0) {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <img src={MonitorIcon} style={{ height: 32, width: 32 }} />
          <h3 style={{ margin: '20px 0 16px' }}>
            {getPatientName(props.data)}{' '}
            {props.status ? ` - ${props.status}` : null}
          </h3>
          <ul style={{ margin: 0, padding: '0 20px', fontSize: 14 }}>
            <li>
              <span className={classes.active}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.active}>Protocolo:</span>{' '}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.active}>Unidade:</span>{' '}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.active}>Profissional:</span>{' '}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.active}>Grupo de paciente:</span>{' '}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(', ')
                : null}
            </li>
            <li>
              <span className={classes.active}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
            </li>
            {/* <li>
              <span className={classes.active}>Motivo do cancelamento:</span>{" "}
            </li> */}
          </ul>
          <DButton
            variant="primary"
            icon={<PersonIcon fontSize="small" />}
            size="sm"
            onClick={(e) => {
              if (
                props.data?.schedule_health_attendance?.health_attendance_id
              ) {
                localStorage.setItem(
                  'health_attendance_interconsultation',
                  props.data.schedule_health_attendance.health_attendance_id,
                );

                history.push(
                  `/atendimento-imediato/${props.data.schedule_health_attendance.health_attendance_id}?schedule=true`,
                );
              } else {
                toast.error('Erro ao selecionar atendimento. #1');
              }
            }}
            style={{ margin: '48px auto 0', width: 200 }}
          >
            Atender
          </DButton>
        </Dialog>

        <Dialog
          onClose={handleDelayClose}
          open={delayopen}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleDelayClose} />
          </IconButton>
          <h3 className={classes.avisoAtraso}>Aviso de atraso</h3>
          <Typography style={{ margin: '16px 0' }}>
            Atraso na consulta:
            <br />
            <strong>17/02/2021 das 09:00 às 09:10</strong>
            <br />
            <br />O atraso vai passar de 30 minutos? Se sim recomendamos pedir o
            cancelamento da consulta.
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleDelayClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton
              variant="primary"
              size="sm"
              onClick={handleDelayOpenSuccess}
            >
              Confirmar
            </DButton>
          </div>
        </Dialog>
        <Dialog
          onClose={handleDelayCloseSuccess}
          open={delayopensuccess}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleDelayCloseSuccess} />
          </IconButton>
          <h3 style={{ fontSize: 18, color: '#2a814e', margin: 0 }}>
            Aviso de atraso enviado
          </h3>
          <Typography style={{ margin: '16px 0' }}>
            Informaremos o paciente, mas pedimos que inicie o atendimento o
            quanto antes.
          </Typography>
        </Dialog>

        <Dialog
          onClose={handleCancelClose}
          open={cancelopen}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleCancelClose} />
          </IconButton>
          <h3 className={classes.primaryMargin}>Pedido de cancelamento</h3>
          <Typography style={{ margin: '16px 0' }}>
            Cancelar a consulta:
            <br />
            <strong>17/02/2021 das 09:00 às 09:10</strong>
            <br />
            <br />
            Houve algum imprevisto? Cancele a consulta apenas em casos urgentes.
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleCancelClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton
              variant="primary"
              size="sm"
              onClick={handleCancelOpenSuccess}
            >
              Confirmar
            </DButton>
          </div>
        </Dialog>
        <Dialog
          onClose={handleCancelCloseSuccess}
          open={cancelopensuccess}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleCancelCloseSuccess} />
          </IconButton>
          <h3 style={{ fontSize: 18, color: '#2a814e', margin: 0 }}>
            Pedido enviado à administração
          </h3>
          <Typography style={{ margin: '16px 0' }}>
            Assim que aprovado, enviaremos uma notificação para te avisar.
          </Typography>
        </Dialog>

        <Dialog
          onClose={(e) => setOpenPatientData(false)}
          open={openPatientData}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={(e) => setOpenPatientData(false)} />
          </IconButton>
          <PatientInfo patientInfo={patientInfo} />
        </Dialog>

        <Popover
          open={openPop}
          anchorEl={anchorEl}
          onClose={handlePopClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ul className={classes.consultas_popover}>
            <li
              className={classes.consultas_popoverItem}
              onClick={handleClickOpen}
            >
              <DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} />{' '}
              Visualizar
            </li>
            {/* <li className={ classes.consultas_popoverItem } onClick={ e => setOpenPatientData(true) }>
              <DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} />&nbsp;Informações do paciente
            </li> */}
            {/* <li
              className={classes.consultas_popoverItem}
              onClick={handleDelayOpen}
            >
              <InfoIcon style={{ fontSize: 16, marginRight: 8 }} /> Aviso de
              atraso
            </li>
            <li
              className={classes.consultas_popoverItem}
              onClick={handleCancelOpen}
              style={{ color: "#d13d2e" }}
            >
              <CancelIcon style={{ fontSize: 16, marginRight: 8 }} /> Pedir
              cancelamento
            </li> */}
          </ul>
        </Popover>

        {/* new card */}
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardBody }}>
            <Grid container alignItems="center">
              <Grid item>
                <div
                  style={{
                    paddingRight: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {getScheduleHour(props.data.start)}
                  </Typography>
                  <div
                    style={{
                      height: 12,
                      width: 1,
                      backgroundColor: '#c7c7c7',
                      marginBottom: 4,
                    }}
                  ></div>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 300 }}
                    className={classes.colorPrimary}
                  >
                    {getScheduleHour(props.data.end)}
                  </Typography>
                </div>
              </Grid>
              <div className={classes.bckContent}></div>

              <Grid item>
                {patientInfo &&
                  Array.isArray(patientInfo.patient_has_group) &&
                  patientInfo.patient_has_group.map((item, key) => {
                    return (
                      <Chip
                        key={key}
                        label={item.group.name}
                        className={classes.item__chip}
                      />
                    );
                  })}
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: '#272727',
                    fontWeight: 300,
                    fontSize: 16,
                    paddingLeft: 16,
                  }}
                >
                  {getPatientName(props.data)}
                  <div style={{ lineHeight: '12px' }}>
                    <span style={{ fontSize: 11 }}>
                      {renderTime(props.data)}{' '}
                      {props.status ? ` - ${props.status}` : null}
                    </span>
                  </div>
                  <div className={classes.statusIcon}>
                    <div
                      className={
                        props.data.patient_online
                          ? classes.statusHealthAttendanceOn
                          : classes.statusHealthAttendanceOff
                      }
                    ></div>
                    <p style={{ margin: '4px 0 4px 0', fontSize: 14 }}>
                      {props.data.patient_online
                        ? 'Online - Em andamento'
                        : 'Offline'}
                    </p>
                  </div>
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: 'auto' }}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={
                      props.data.health_attendance_type == '-' ||
                      props.data.health_attendance_type == null
                        ? classes.container
                        : classes.tagInfoHealthAttendance
                    }
                  >
                    {props.data.health_attendance_type
                      ? props.data.health_attendance_type
                      : ''}
                  </div>
                  <Button
                    {...props}
                    className={classes.btnOpenClass}
                    variant="outlined"
                    startIcon={
                      <LocalPhoneOutlined
                        fontSize="small"
                        className={classes.startIconCustom}
                      />
                    }
                    size="md"
                    onClick={() => {
                      if (
                        props.data?.schedule_health_attendance
                          ?.health_attendance_id
                      ) {
                        localStorage.setItem(
                          'health_attendance_interconsultation',
                          props.data.schedule_health_attendance
                            .health_attendance_id,
                        );

                        history.push(
                          `/atendimento-imediato/${props.data.schedule_health_attendance.health_attendance_id}?schedule=true`,
                        );
                      } else {
                        toast.error('Erro ao selecionar atendimento. #2');
                      }
                    }}
                  ></Button>
                  <Button
                    variant="outlined"
                    className={classes.btnOpenClass}
                    onClick={handlePopOpen}
                  >
                    <MoreVertIcon />
                  </Button>
                </div>
              </Grid>
              <Grid item>
                {openCard ? (
                  <StyledButtonAction onClick={(e) => setOpenCard(false)}>
                    <KeyboardArrowDownIcon color="primary" />
                  </StyledButtonAction>
                ) : (
                  <StyledButtonAction onClick={(e) => setOpenCard(true)}>
                    <KeyboardArrowUpIcon color="primary" />
                  </StyledButtonAction>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              classes={{
                root: openCard ? classes.containerOpen : classes.container,
              }}
            >
              <Grid
                item
                md={12}
                style={{ display: 'flex', marginTop: 30 }}
                justifyContent="space-between"
              >
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Nome da Mãe</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo?.mother_name || 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Sexo biológico</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo
                        ? patientInfo.biological_sex
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Gênero</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo.genre &&
                      Array.isArray(patientInfo.genre) &&
                      typeof patientInfo.genre[0] !== 'undefined'
                        ? patientInfo.genre[0].description
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Raça</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo.breed &&
                      Array.isArray(patientInfo.breed) &&
                      typeof patientInfo.breed[0] !== 'undefined'
                        ? patientInfo.breed[0].description
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Idade</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo &&
                        getAgeByDate(patientInfo.birth_date) + ' anos'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  } else if (props.data && props.data.status === 2) {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <AssignmentIcon style={{ color: '#168183', height: 36, width: 34 }} />
          <h3 style={{ margin: '20px 0 16px' }}>
            {getPatientName(props.data)}
          </h3>
          <ul style={{ margin: 0, padding: '0 20px', fontSize: 14 }}>
            <li>
              <span className={classes.success}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.success}>Protocolo:</span>{' '}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.success}>Unidade:</span>{' '}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.success}>Profissional:</span>{' '}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.success}>Grupo de paciente:</span>{' '}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(', ')
                : null}
            </li>
            <li>
              <span className={classes.success}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
              <br />
              <br />
            </li>
            {/* <li>
              Paciente relatou fortes dores abdominais com sangramento periódico
              <br />
              <br />
            </li>
            <li>
              <span className={classes.success}>Diagnóstico:</span> trabalho de
              parto com risco
            </li>
            <li>
              <span className={classes.success}>Prescrição:</span> 24h de
              observação em completo repouso
            </li> */}
          </ul>
        </Dialog>

        <div
          className={classes.item__card}
          onClick={handleClickOpen}
          style={{ cursor: 'pointer' }}
        >
          <div
            style={{
              paddingRight: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: '#168183' }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: '#c7c7c7',
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, color: '#4f4f4f' }}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 2,
              width: 4,
              backgroundColor: '#168183',
              height: '100%',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 16,
              flexDirection: 'column',
            }}
          >
            <div>
              <Chip
                icon={
                  <AssignmentIcon style={{ color: '#168183', height: 16 }} />
                }
                label="Ver Prontuário"
                className={classes.item__chip_success}
              />
            </div>
            <Typography
              style={{
                color: '#272727',
                fontWeight: 300,
                marginTop: 12,
                fontSize: 16,
              }}
            >
              {getPatientName(props.data)} &nbsp;&nbsp;{' '}
              <strong style={{ fontWeight: 400 }}>Protocolo:</strong>{' '}
              {getHealthAttendanceProtocol(props.data)}
            </Typography>
          </div>
        </div>
      </>
    );
  } else if (props.data && props.data.status === 4) {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <CancelIcon style={{ color: '#E23F5D', height: 36, width: 34 }} />
          <h3 style={{ margin: '20px 0 16px' }}>
            {getPatientName(props.data)}
          </h3>
          <ul style={{ margin: 0, padding: '0 20px', fontSize: 14 }}>
            <li>
              <span className={classes.cancel}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.cancel}>Protocolo:</span>{' '}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.cancel}>Unidade:</span>{' '}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.cancel}>Profissional:</span>{' '}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.cancel}>Grupo de paciente:</span>{' '}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(', ')
                : null}
            </li>
            <li>
              <span className={classes.cancel}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
            </li>
            {/* <li>
              <span className={classes.cancel}>Motivo do cancelamento:</span>{" "}
              paciente não pôde comparecer à consulta
            </li> */}
          </ul>
        </Dialog>
        {/* new card */}
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardBody }}>
            <Grid container alignItems="center">
              <Grid item>
                <div
                  style={{
                    paddingRight: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color: theme.palette.support.error,
                    }}
                  >
                    {getScheduleHour(props.data.start)}
                  </Typography>
                  <div
                    style={{
                      height: 12,
                      width: 1,
                      backgroundColor: '#c7c7c7',
                      marginBottom: 4,
                    }}
                  ></div>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 300,
                      color: theme.palette.support.error,
                    }}
                  >
                    {getScheduleHour(props.data.end)}
                  </Typography>
                </div>
              </Grid>
              <div className={classes.bckContentCanceled}></div>

              <Grid item>
                {patientInfo &&
                  Array.isArray(patientInfo.patient_has_group) &&
                  patientInfo.patient_has_group.map((item, key) => {
                    return (
                      <Chip
                        key={key}
                        label={item.group.name}
                        className={classes.item__chip}
                      />
                    );
                  })}
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 300,
                    fontSize: 16,
                    paddingLeft: 16,
                  }}
                >
                  {getPatientName(props.data)}&nbsp;&nbsp;
                  <div className={classes.statusIcon}>
                    <div
                      className={
                        props.data.patient_online
                          ? classes.statusHealthAttendanceOn
                          : classes.statusHealthAttendanceOff
                      }
                    ></div>
                    <p style={{ margin: '4px 0 4px 0', fontSize: 14 }}>
                      {props.data.patient_online
                        ? 'Online - Em andamento'
                        : 'Offline'}
                    </p>
                  </div>
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: 'auto' }}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={
                      props.data.health_attendance_type == '-' ||
                      props.data.health_attendance_type == null
                        ? classes.container
                        : classes.tagInfoHealthAttendance
                    }
                  >
                    {props.data.health_attendance_type
                      ? props.data.health_attendance_type
                      : ''}
                  </div>
                  <Button
                    variant="outlined"
                    className={classes.btnOpenClass}
                    onClick={handleClickOpen}
                  >
                    <MoreVertIcon />
                  </Button>
                </div>
              </Grid>
              <Grid item>
                {openCard ? (
                  <StyledButtonAction onClick={(e) => setOpenCard(false)}>
                    <KeyboardArrowDownIcon color="primary" />
                  </StyledButtonAction>
                ) : (
                  <StyledButtonAction onClick={(e) => setOpenCard(true)}>
                    <KeyboardArrowUpIcon color="primary" />
                  </StyledButtonAction>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              classes={{
                root: openCard ? classes.containerOpen : classes.container,
              }}
            >
              <Grid
                item
                md={12}
                style={{ display: 'flex', marginTop: 30 }}
                justifyContent="space-between"
              >
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Nome da Mãe</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo && patientInfo.mother_name == ''
                        ? 'Não informado'
                        : patientInfo.mother_name}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Sexo biológico</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo
                        ? patientInfo.biological_sex
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Gênero</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo.genre &&
                      Array.isArray(patientInfo.genre) &&
                      typeof patientInfo.genre[0] !== 'undefined'
                        ? patientInfo.genre[0].description
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Raça</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo.breed &&
                      Array.isArray(patientInfo.breed) &&
                      typeof patientInfo.breed[0] !== 'undefined'
                        ? patientInfo.breed[0].description
                        : 'Não informado'}
                    </div>
                  </div>
                </Grid>
                <Grid item md={2} justifyContent="center">
                  <div className={classes.fontInfo}>Idade</div>
                  <div className={classes.mainDivTag}>
                    <div className={classes.tagInfo}>
                      {patientInfo &&
                        getAgeByDate(patientInfo.birth_date) + ' anos'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  } else if (props.data && props.data.status === 3) {
    return (
      <div className={classes.item__card} style={{ opacity: 0.5 }}>
        <div
          style={{
            paddingRight: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: '#1616B5' }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: '#c7c7c7',
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: '#4f4f4f' }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div className={classes.bckContent}></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 16,
            flexDirection: 'column',
          }}
        >
          <div>
            {/*
            <Chip
              icon={<img src={MonitorIcon} style={{ height: 12 }} />}
              label="Monitoramento"
              className={classes.item__chip}
            />
            <Chip
              icon={
                <PregnantWomanIcon
                  fontSize="small"
                  style={{ color: "#1616b5" }}
                />
              }
              label="Gravidez"
              className={classes.item__chip}
            /> */}
          </div>
          <Typography
            style={{
              color: '#272727',
              fontWeight: 300,
              marginTop: 12,
              fontSize: 16,
            }}
          >
            {getPatientName(props.data)} &nbsp;&nbsp;{' '}
            <strong style={{ fontWeight: 400 }}>Protocolo:</strong>{' '}
            {getHealthAttendanceProtocol(props.data)}
          </Typography>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton
            style={{ color: '#1616b5', marginTop: -6, marginRight: -12 }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
    );
  } else if (props.status == 'onduty') {
    return (
      <div className={classes.item__card_onduty}>
        <div
          style={{
            paddingRight: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: '#1616B5' }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: '#c7c7c7',
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: '#4f4f4f' }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div className={classes.bckContent}></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 16,
            flexDirection: 'column',
          }}
        >
          <div>
            <Chip
              icon={<AccessTimeIcon style={{ color: '#fff', height: 16 }} />}
              label="Plantão"
              className={classes.item__chip_onduty}
            />
          </div>
        </div>
      </div>
    );
  } else if (props.status == 'rest') {
    return (
      <div className={classes.item__card_rest}>
        <div
          style={{
            paddingRight: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: '#1616B5' }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: '#c7c7c7',
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: '#4f4f4f' }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div className={classes.bckContent}></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 16,
            flexDirection: 'column',
          }}
        >
          <div>
            <Chip
              icon={<FreeBreakfastIcon style={{ color: '#fff', height: 16 }} />}
              label="Período de descanso"
              className={classes.item__chip_onduty}
            />
          </div>
        </div>
      </div>
    );
  } else if (props.status == 'blocked') {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <h3 className={classes.primaryMargin}>Pedido de desbloqueio</h3>
          <Typography style={{ margin: '16px 0' }}>
            Os horários ficarão disponíveis para os pacientes novamente
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton variant="primary" size="sm" onClick={handleClose}>
              Confirmar
            </DButton>
          </div>
        </Dialog>

        <div className={classes.item__card_blocked}>
          <div
            style={{
              paddingRight: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: '#4f4f4f' }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: '#c7c7c7',
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, color: '#4f4f4f' }}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 2,
              width: 4,
              backgroundColor: '#4f4f4f',
              height: '100%',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 16,
              flexDirection: 'column',
            }}
          >
            <div>
              <Chip
                icon={<BlockIcon style={{ color: '#fff', height: 16 }} />}
                label="Horario bloqueado"
                className={classes.item__chip_blocked}
              />
              <Button
                style={{ textTransform: 'none', color: '#1616b5' }}
                onClick={handleClickOpen}
              >
                Pedir desbloqueio
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default AgendaItem;
