import env from 'react-dotenv';
import axios from 'axios';

function createRoom(token, dados) {
  return axios
    .post(`${env.API_URL}/conference/createRoom`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createConference(token, dados) {
  return axios
    .post(`${env.API_URL}/conference`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getConferenceByAttendenceId(token, id) {
  return axios
    .get(`${env.API_URL}/conference/health-attendance/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getConferenceCallByAttendenceId(token, id) {
  return axios
    .get(`${env.API_URL}/call/recordings/${id}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getConferenceByRoom(token, roomId) {
  return axios
    .get(`${env.API_URL}/conference/recordings/${roomId}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function joinRoom(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conferenceParticipant/joinRoom`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function joinRoomPatient(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conferenceParticipant/joinRoomPatient`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function joinRoomUser(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conferenceParticipant/joinRoomUser`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function conferenceAddParticipant(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conferenceParticipant?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  createRoom,
  createConference,
  getConferenceByAttendenceId,
  joinRoom,
  conferenceAddParticipant,
  joinRoomPatient,
  getConferenceByRoom,
  getConferenceCallByAttendenceId,
  joinRoomUser,
};
