import axios from 'axios';
import env from 'react-dotenv';

export function getCIAP(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/diagnostic/ciap`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getCIPE(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/diagnostic/cipe`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getCID10(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/diagnostic/cid10`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getSIGTAP(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/diagnostic/sigtap`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}
