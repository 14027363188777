import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { getPatientById } from 'api/patient';
import {
  getHasScheduleTime,
  getScheduleTimesById,
  putScheduleTime,
  getScheduleById,
} from 'api/schedule/agenda-medico';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalCancel from './Modais/ModalCancel';

const useStyles = makeStyles((theme) => ({
  scheduleItem: {
    padding: 32,
  },
  card: {
    background: theme.palette.background.snow,
    border: `2px solid ${theme.palette.neutral.midlightgray} !important`,
    marginBottom: 28.8,
  },
  cardBody: {
    padding: 15,
    paddingBottom: '15px !important',
  },
  bckContent: {
    borderRadius: 2,
    width: 1,
    backgroundColor: theme.palette.neutral.darkgray,
    opacity: 0.3,
    height: '50px',
  },
  btnReagendar: {
    textTransform: 'none !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important',
    height: 36,
    marginRight: '10px !important',
  },
  btnCancelar: {
    textTransform: 'none !important',
    color: `${theme.palette.support.error} !important`,
    fontWeight: '400 !important',
    height: 36,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'baseline',
  },
  statusHealthAttendanceOn: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.check,
    marginRight: 5,
  },
  statusHealthAttendanceOff: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.error,
    marginRight: 5,
  },
  titleSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 28,
  },
  toolbar_top: {
    zIndex: 3000,
    color: 'white',
    textAlign: 'center',
  },
}));

export default function ScheduleItem(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  const {
    setNomePaciente,
    setDuracaoAtendimento,
    setDataInicial,
    setDataScheduleItem,
    horariosAgendados,
    setHorariosAgendados,
    setPatientDocuments,
    dadosAgendamento,
    setDadosAgendamento,
  } = useConfiguracaoAgenda();

  const handleOpen = (horarioAgend, indexHorarioAgend) => {
    setSelectedTime({ index: indexHorarioAgend, horarioAgend: horarioAgend });
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTime(null);
    setOpen(false);
  };

  useEffect(() => {
    if (horariosAgendados == null) {
      props.handleMenuItem('lista_agendas');
    }
  }, [horariosAgendados]);

  const handleReagendarCreate = async (horarioAgend, indexHorarioAgend) => {
    setLoadingDelete(true);

    try {
      const schedule = await getScheduleById(horarioAgend.schedule_id);
      const scheduleTimes = await getScheduleTimesById(horarioAgend.schedule_id)
        .then((res) => (res.status ? res.scheduleTimes : []))
        .catch((err) => {
          toast.error('Erro ao buscar horários agendados');
          console.log(err);
        });

      const scheduleTime = scheduleTimes.find(
        (scheduleTimes) => scheduleTimes.id == horarioAgend.id,
      );

      if (!scheduleTime) throw new Error({ message: 'Horário não encontrado' });

      setNomePaciente(
        horarioAgend.patient.social_name != ''
          ? horarioAgend.patient.social_name
          : horarioAgend.patient.full_name,
      );

      const [year, month, day] = horarioAgend.data_time
        .split(' ')[0]
        .split('-');

      setDuracaoAtendimento(renderHour(horarioAgend.data_time));
      setDataInicial(new Date(`${year}-${month}-${day}T00:00:00`));
      setDataScheduleItem(scheduleTime);

      setDadosAgendamento({
        ...schedule,
        horarioAgendado: scheduleTime,
        fromView: false,
      });

      await getPatientById(
        localStorage.getItem('token'),
        horarioAgend.patient.id,
      ).then((response) => {
        if (response.status) {
          setPatientDocuments(response.details.patient.patient_document);
        } else {
          throw new Error({
            message:
              'Ocorreu um erro ao selecionar o agendamento. Por favor, tente novamente em alguns minutos.',
          });
        }
      });

      props.handleMenuItem('reagendar_create');
    } catch (err) {
      toast.error(
        err.message ? err.message : 'Erro ao buscar horários agendados',
      );
      console.log(err);
    }
  };

  const renderDate = (strDate) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format('DD/MM/YYYY');
  };

  const renderHour = (strHour) => {
    const hourObj = new Date(strHour);
    const momentObj = moment(hourObj);
    return momentObj.format('HH:mm');
  };

  function getEnderecoPaciente(idPacient) {
    setLoadingDelete(true);

    getPatientById(localStorage.getItem('token'), idPacient).then(
      (response) => {
        if (response.status) {
          setPatientDocuments(response.details.patient.patient_document);
          props.handleMenuItem('reagendar_create');
        } else {
          toast.error(
            'Ocorreu um erro ao selecionar o agendamento. Por favor, tente novamente em alguns minutos.',
          );
        }

        setLoadingDelete(false);
      },
    );
  }

  function cancelarAgendamento(horarioAgendObj, justificativa) {
    setLoadingDelete(true);

    var dadosObj = {
      status: 'cancelado',
      justification: justificativa,
    };

    putScheduleTime(horarioAgendObj.horarioAgend.id, dadosObj)
      .then(async (response) => {
        if (response.status !== false) {
          await checarHorariosAgendamento(horarioAgendObj.horarioAgend);
        } else {
          toast.error(
            'Ocorreu um erro ao excluir o agendamento. Por favor, tente novamente em alguns minutos.',
          );
          console.log(response.message);
        }

        setLoadingDelete(false);
      })
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao excluir o agendamento. Por favor, tente novamente em alguns minutos.',
        );
        console.log(error);

        setLoadingDelete(false);
      });
  }

  function checarHorariosAgendamento(horarioAgendObj) {
    getHasScheduleTime(horarioAgendObj.schedule_id).then((data) => {
      if (data.status === false) {
        setHorariosAgendados(data.scheduleDataExtract);

        handleClose();
        toast.success('Agendamento cancelado com sucesso');
      } else {
        props.handleMenuItem('lista_agendas');
      }
    });
  }

  function rowScheduleTime() {
    return (
      <>
        {horariosAgendados &&
          horariosAgendados.length &&
          horariosAgendados.map((item, index) => {
            if (!Array.isArray(item)) {
              item = [item];
            }

            if (item[0] && Array.isArray(item[0].schedule_participants)) {
              let patient = item[0].schedule_participants[0];

              if (patient) {
                item[0].patient = patient;
              }
            }

            const scheduleTimeDate = new Date(item[0]?.data_time);
            const currentDate = new Date();

            if (
              currentDate.getTime() <= scheduleTimeDate.getTime() ||
              item[0]?.status == 'agendado'
            ) {
              return (
                <Card classes={{ root: classes.card }}>
                  <CardContent classes={{ root: classes.cardBody }}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <div
                          style={{
                            paddingRight: 36,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: '#006FB5',
                            }}
                          >
                            {renderDate(item[0].data_time)}
                          </Typography>
                          <div
                            style={{
                              height: 12,
                              width: 1,
                              backgroundColor: '#c7c7c7',
                              marginBottom: 4,
                            }}
                          ></div>
                          <Typography
                            style={{ fontSize: 14, fontWeight: 300 }}
                            className={classes.colorPrimary}
                          >
                            {renderHour(item[0].data_time)}
                          </Typography>
                        </div>
                      </Grid>
                      <div className={classes.bckContent}></div>

                      <Grid item>
                        <Typography
                          style={{
                            color: '#272727',
                            fontWeight: 300,
                            fontSize: 16,
                            paddingLeft: 32,
                          }}
                        >
                          {item[0].patient?.social_name != ''
                            ? item[0].patient?.social_name
                            : item[0].patient?.full_name}
                          &nbsp;&nbsp;
                          <div className={classes.statusIcon}>
                            <div
                              className={
                                item[0].status == 'disponivel'
                                  ? classes.statusHealthAttendanceOn
                                  : classes.statusHealthAttendanceOff
                              }
                            ></div>
                            <p style={{ margin: '4px 0 4px 0', fontSize: 14 }}>
                              {item[0].status[0].toUpperCase() +
                                item[0].status.substring(1)}
                            </p>
                          </div>
                        </Typography>
                      </Grid>

                      {item[0].status == 'agendado' ? (
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <div style={{ display: 'flex' }}>
                            <Button
                              className={classes.btnReagendar}
                              startIcon={
                                <BorderColorOutlinedIcon
                                  fontSize="small"
                                  className={classes.startIconCustom}
                                />
                              }
                              size="sm"
                              onClick={() =>
                                handleReagendarCreate(item[0], index)
                              }
                            >
                              <span>Reagendar</span>
                            </Button>
                            <Button
                              className={classes.btnCancelar}
                              startIcon={
                                <CancelPresentationOutlinedIcon
                                  fontSize="small"
                                  className={classes.startIconCustom}
                                />
                              }
                              onClick={() => handleOpen(item[0], index)}
                            >
                              <span>Cancelar</span>
                            </Button>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              );
            }
          })}
      </>
    );
  }

  return (
    <div className={classes.scheduleItem}>
      <Backdrop className={classes.toolbar_top} open={loadingDelete}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalCancel
        open={open}
        horarioAgendado={selectedTime}
        onCancel={(scheduleTime, justification) =>
          cancelarAgendamento(scheduleTime, justification)
        }
        onClose={() => handleClose()}
      />

      <Grid container>
        <Grid item md={12} style={{ display: 'flex', alignItems: 'center' }}>
          <a
            href="#"
            className={classes.titleSection}
            onClick={(e) => {
              if (
                Array.isArray(horariosAgendados) &&
                horariosAgendados.length > 0
              ) {
                let _horarioAgendado = horariosAgendados[0];

                if (_horarioAgendado.fromView) {
                  return props.handleMenuItem(
                    `visualizar_agenda:${_horarioAgendado.schedule_id}`,
                  );
                }
              }

              props.handleMenuItem('lista_agendas');
            }}
          >
            <ArrowBackIosIcon color="primary" fontSize="large" />
            Agendamentos no período
          </a>
        </Grid>
        <Grid item md={12}>
          {rowScheduleTime()}
        </Grid>
      </Grid>
    </div>
  );
}
