import axios from 'axios';
import env from 'react-dotenv';

export function recoverpassword(dados) {
  return axios
    .post(
      `${env.API_URL}/recoverpassword`,
      {
        type_action: dados.typeAction,
        value: dados.typeAction === 'sms' ? dados.phone : dados.email,
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}

export function password(code1, code2, code3, code4, code5, token) {
  return axios
    .post(
      `${env.API_URL}/recoverpassword/password`,
      {
        code: [code1, code2, code3, code4, code5].join(''),
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-auth-token': token,
          'content-type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}

export function resendcode(token, type_action) {
  return axios
    .post(
      `${env.API_URL}/recoverpassword/resend`,
      {
        type_action,
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-auth-token': token,
          'content-type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}

export function change(token, password) {
  return axios
    .put(
      `${env.API_URL}/recoverpassword/change`,
      {
        password,
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-auth-token': token,
          'content-type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}
