import { Grid, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Input, Modal, TextField } from '@material-ui/core';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import moment from 'moment';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  baseCalendar: {
    flexDirection: 'column',
    display: 'flex',
  },
  gridCalendar: {
    flex: '1 1 0%',
    display: 'grid',
  },
  itensCalendar: {
    display: 'grid',
    gridTemplateRows: 'repeat(1, minmax(0,1fr))',
    gridTemplateColumns: 'repeat(7,minmax(0,1fr))',
    flex: '1 1 0%',
    columnGap: '10px',
    rowGap: '10px',
    padding: 32,
  },
  currentDay: {
    background: 'rgba(104, 147, 248, 0.2);',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'pointer',
    '&>span:last-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
    },
  },
  dayWeekAndIcon: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  estiloModalAtencao: {
    backgroundColor: theme.palette.neutral.white,
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    border: 'none',
    padding: '60px 100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconeModalAtencao: {
    color: theme.palette.neutral.black,
    fontSize: '14px',
  },
  titleModalAtencao: {
    fontSize: '20px',
    color: theme.palette.neutral.black,
    fontWeight: '700',
    marginTop: '20px',
  },
  fontDescricaoModalAtencao: {
    fontSize: '16px',
    color: theme.palette.neutral.darkgray,
    marginTop: '20px',
    width: '250px',
  },
  alinhaBotoesModalAtencao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    marginTop: '50px',
  },
  botaoConfirmar: {
    background: `${theme.palette.support.error} !important`,
    border: `1px solid ${theme.palette.support.error} !important`,
    color: `${theme.palette.neutral.white} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  botaoCancelar: {
    backgroundColor: 'transparent',
    // border: `1px solid ${theme.palette.support.error} !important`,
    color: `${theme.palette.neutral.black} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  justificativa: {
    marginTop: '24px',
    width: '100%',
    '&>span:first-child': {
      color: `${theme.palette.neutral.darkgray} !important`,
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  titleHours: {
    color: 'rgba(0, 111, 181, 0.5)',
    fontSize: 14,
  },
  titleHoursJourney: {
    color: 'rgba(0, 111, 181, 0.5)',
    fontSize: 14,
    fontWeight: '700',
  },
  colorButton: {
    color: 'rgba(0, 111, 181, 0.5)',
    fontSize: 14,
  },
  startIconCustom: {
    margin: '0 !important',
    justifyContent: 'flex-end !important',
    paddingRight: '0 !important',
  },
}));

export default function DaysAndHours({
  defaultData,
  haveError,
  setIsPartialEdit,
}) {
  const classes = useStyles();
  const { setDays } = useConfiguracaoAgenda();
  const [dayWeekSelected, setDayWeekSelected] = useState();
  const [day, setDay] = useState([]);
  const [open, setOpen] = useState();
  const [openEdit, setOpenEdit] = useState();
  const [openModalEditOrDelete, setOpenModalEditOrDelete] = useState(false);
  const [hrInitial, setHrInitial] = useState();
  const [hrFinal, setHrFinal] = useState();
  const [hrInitialEdit, setHrInitialEdit] = useState();
  const [hrFinalEdit, setHrFinalEdit] = useState();
  const [dataJourney, setDataJourney] = useState();

  const dayWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

  useEffect(() => {
    if (defaultData) {
      setDay(defaultData);
      setDays(defaultData);
    }
  }, [defaultData]);

  useEffect(() => {
    let result;
    let hrInitial;
    let hrFinal;
    if (day) {
      let totalHrsForDay = day.map((item, index) => {
        hrInitial = moment(item.hrInitial, 'hh:mm');
        hrFinal = moment(item.hrFinal, 'hh:mm');
        return (result = {
          day: item.day,
          totalHr: hrFinal.diff(hrInitial, 'hours'),
        });
      });
      const totalJourneyForDay = totalHrsForDay.reduce((acc, current) => {
        if (!acc[current.day]) {
          acc[current.day] = { ...current };
          return acc;
        }
        acc[current.day].totalHr = acc[current.day].totalHr + current.totalHr;
        return acc;
      }, {});

      // Extraia a lista
      const newList = Object.values(totalJourneyForDay);
      setDataJourney(newList);
    }
  }, [day]);

  function verificaJornada(dia) {
    let result;
    let hrInitial;
    let hrFinal;
    if (dia) {
      let totalHrsForDay = dia.map((item, index) => {
        hrInitial = moment(item.hrInitial, 'hh:mm');
        hrFinal = moment(item.hrFinal, 'hh:mm');
        return (result = {
          day: item.day,
          totalHr: hrFinal.diff(hrInitial, 'hours'),
        });
      });
      const totalJourneyForDay = totalHrsForDay.reduce((acc, current) => {
        if (!acc[current.day]) {
          acc[current.day] = { ...current };
          return acc;
        }
        acc[current.day].totalHr = acc[current.day].totalHr + current.totalHr;
        return acc;
      }, {});

      // Extraia a lista
      const newList = Object.values(totalJourneyForDay);

      return newList;
    }

    return [];
  }

  function handleHourDay() {
    let total_jornadas = day.filter((x) => x.day == dayWeekSelected).length;

    if (total_jornadas == 2) {
      setOpen(false);
      setOpenEdit(false);
      return toast.error('Você pode cadastrar até 2 jornadas por dia');
    }

    const _setDay = [
      ...day,
      {
        day: dayWeekSelected,
        hrInitial: hrInitial,
        hrFinal: hrFinal,
      },
    ];

    const maisQue24H = verificaJornada(_setDay).filter((item) => {
      if (item.day === dayWeekSelected) {
        if (item.totalHr > 24) {
          return true;
        }
      }
    });

    if (maisQue24H.length > 0) {
      toast.error('A jornada não pode ter mais que 24 horas');
      return;
    }

    setDay(_setDay);
    setDays(_setDay);
    setOpen(false);
    setOpenEdit(false);
    setDayWeekSelected(null);
    setIsPartialEdit(false);
    setHrInitial('');
    setHrFinal('');
  }

  function handleEditDay() {
    let _tempDay = day;
    _tempDay[dayWeekSelected.index] = {
      day: dayWeekSelected.day,
      hrInitial: hrInitialEdit,
      hrFinal: hrFinalEdit,
    };

    const _setDay = _tempDay;

    const maisQue24H = verificaJornada(_setDay).filter((item, i) => {
      if (dayWeekSelected && i == dayWeekSelected.index) {
        if (item.totalHr > 24) {
          return true;
        }
      }
    });

    if (maisQue24H.length > 0) {
      toast.error('A jornada não pode ter mais que 24 horas');
      return;
    }

    setDay(_setDay);
    setDays(_setDay);
    setIsPartialEdit(false);

    let result;
    let hrInitial;
    let hrFinal;
    if (_setDay) {
      let totalHrsForDay = _setDay.map((item, index) => {
        hrInitial = moment(item.hrInitial, 'hh:mm');
        hrFinal = moment(item.hrFinal, 'hh:mm');
        return (result = {
          day: item.day,
          totalHr: hrFinal.diff(hrInitial, 'hours'),
        });
      });
      const totalJourneyForDay = totalHrsForDay.reduce((acc, current) => {
        if (!acc[current.day]) {
          acc[current.day] = { ...current };
          return acc;
        }
        acc[current.day].totalHr = acc[current.day].totalHr + current.totalHr;
        return acc;
      }, {});

      // Extraia a lista
      const newList = Object.values(totalJourneyForDay);
      setDataJourney(newList);
    }

    setOpen(false);
    setOpenEdit(false);

    setDayWeekSelected(null);
  }

  const checkHoursBisMinorThanA = (b, a, cb) => {
    if (a == '') {
      cb(3);
    } else if (b == '') {
      cb(4);
    } else {
      let d = new Date();

      const [hourA, minuteA] = a.split(':');
      const [hourB, minuteB] = b.split(':');

      let [ha, hb] = [
        new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          parseInt(hourA),
          parseInt(minuteA),
          0,
          0,
        ),
        new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          parseInt(hourB),
          parseInt(minuteB),
          0,
          0,
        ),
      ];

      if (hb < ha || hb == ha) {
        cb(2);
      } else {
        cb(1);
      }
    }
  };

  const renderModalAddItemDay = () => {
    return (
      <>
        <Modal
          open={open}
          onClose={(e) => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx className={classes.estiloModalAtencao}>
            <div className={classes.iconeModalAtencao}>
              <AccessTimeIcon style={{ fontSize: 56 }} />
            </div>
            <div className={classes.titleModalAtencao} id="modal-modal-title">
              Adicionar horário
            </div>
            <div className={classes.fontDescricaoModalAtencao}>
              <TextField
                id="time"
                label="Horário de Entrada"
                type="time"
                fullWidth
                defaultValue=""
                className={classes.textField}
                onChange={(e) => {
                  setHrInitial(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
            <div className={classes.fontDescricaoModalAtencao}>
              <TextField
                id="time"
                label="Horário de Saída"
                type="time"
                fullWidth
                defaultValue=""
                className={classes.textField}
                onChange={(e) => {
                  setHrFinal(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
            <div className={classes.alinhaBotoesModalAtencao}>
              <Button
                className={classes.botaoCancelar}
                onClick={(e) => {
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                className={classes.botaoConfirmar}
                onClick={(e) =>
                  checkHoursBisMinorThanA(
                    hrFinal || '',
                    hrInitial || '',
                    (checkHours) => {
                      if (checkHours == 3) {
                        return toast.error('Informe um horário de entrada.');
                      } else if (checkHours == 4) {
                        return toast.error('Informe um horário de saída.');
                      } else if (checkHours == 2) {
                        return toast.error(
                          'Horário de saída não pode ser menor ou igual que o de entrada.',
                        );
                      } else if ((hrFinal || '') === (hrInitial || '')) {
                        return toast.error(
                          'Horário de entrada não pode ser igual ao de saída.',
                        );
                      }

                      handleHourDay();
                    },
                  )
                }
              >
                Confirmar
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  function removeDay(dayweek) {
    setHrInitial('');
    setHrFinal('');
    setHrInitialEdit('');
    setHrFinalEdit('');

    setDays((prevDays) => {
      let _tempDays = prevDays;

      delete _tempDays[dayweek.index];

      return _tempDays.filter((x) => x);
    });
    setDay((prevDays) => {
      let _tempDays = prevDays;

      delete _tempDays[dayweek.index];

      return _tempDays.filter((x) => x);
    });
    setIsPartialEdit(false);
  }

  const renderModalEditItemDay = (props) => {
    return (
      <>
        <Modal
          open={openEdit}
          onClose={(e) => setOpenEdit(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx className={classes.estiloModalAtencao}>
            <div className={classes.iconeModalAtencao}>
              <AccessTimeIcon style={{ fontSize: 56 }} />
            </div>
            <div className={classes.titleModalAtencao} id="modal-modal-title">
              Editar horário
            </div>
            <div className={classes.fontDescricaoModalAtencao}>
              <TextField
                id="time"
                label="Horário de Entrada"
                type="time"
                fullWidth
                defaultValue={hrInitialEdit}
                className={classes.textField}
                onChange={(e) => {
                  setHrInitialEdit(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
            <div className={classes.fontDescricaoModalAtencao}>
              <TextField
                id="time"
                label="Horário de Saída"
                type="time"
                fullWidth
                defaultValue={hrFinalEdit}
                className={classes.textField}
                onChange={(e) => {
                  setHrFinalEdit(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
            <div className={classes.alinhaBotoesModalAtencao}>
              <Button
                className={classes.botaoCancelar}
                onClick={(e) => setOpenEdit(false)}
              >
                Cancelar
              </Button>
              <Button
                className={classes.botaoConfirmar}
                onClick={(e) =>
                  checkHoursBisMinorThanA(
                    hrFinalEdit || '',
                    hrInitialEdit || '',
                    (checkHours) => {
                      if (checkHours == 3) {
                        return toast.error('Informe um horário de entrada.');
                      } else if (checkHours == 4) {
                        return toast.error('Informe um horário de saída.');
                      } else if (checkHours == 2) {
                        return toast.error(
                          'Horário de saída não pode ser menor que o de entrada.',
                        );
                      } else if (
                        (hrFinalEdit || '') === (hrInitialEdit || '')
                      ) {
                        return toast.error(
                          'Horário de começo não pode ser igual ao horário de entrada.',
                        );
                      }

                      handleEditDay(dayWeekSelected);
                    },
                  )
                }
              >
                Confirmar
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderModalEditOrDelete = (props) => {
    return (
      <>
        <Modal
          open={openModalEditOrDelete}
          onClose={(e) => setOpenModalEditOrDelete(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx className={classes.estiloModalAtencao}>
            <div className={classes.iconeModalAtencao}>
              <AccessTimeIcon style={{ fontSize: 56 }} />
            </div>
            <div className={classes.titleModalAtencao} id="modal-modal-title">
              Deseja editar ou remover o horário ?
            </div>
            <div className={classes.alinhaBotoesModalAtencao}>
              <Button
                className={classes.botaoCancelar}
                onClick={(e) => {
                  setOpenEdit(true);
                  setOpenModalEditOrDelete(false);
                }}
              >
                Editar
              </Button>
              <Button
                className={classes.botaoConfirmar}
                onClick={(e) => {
                  removeDay(dayWeekSelected);
                  setOpenModalEditOrDelete(false);
                }}
              >
                Remover
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  function DayWeek(props) {
    return (
      <>
        <div
          className={classes.currentDay}
          onClick={(e) => {
            setOpen(true);
            setDayWeekSelected(props.day);

            let total_jornadas = day.filter((x) => x.day == props.day).length;

            if (total_jornadas == 2) {
              setOpen(false);
              setOpenEdit(false);
              return toast.error('Você pode cadastrar até 2 jornadas por dia');
            }
          }}
        >
          <div className={`${classes.dayWeekAndIcon} iconDay`}>
            <span>{props.day}</span>
            <Button
              className={classes.startIconCustom}
              startIcon={
                <AddCircleOutlineIcon
                  fontSize="small"
                  className={classes.colorButton}
                />
              }
              onClick={(e) => {
                setOpen(true);
                setDayWeekSelected(props.day);

                let total_jornadas = day.filter(
                  (x) => x.day == props.day,
                ).length;

                if (total_jornadas == 2) {
                  setOpen(false);
                  setOpenEdit(false);
                  return toast.error(
                    'Você pode cadastrar até 2 jornadas por dia',
                  );
                }
              }}
            ></Button>
          </div>
          <div>
            {day.map((evt, idx) => (
              <>
                {evt.day == props.day && (
                  <div key={idx}>
                    <span className={classes.titleHours}>
                      {evt.hrInitial} - {evt.hrFinal}
                    </span>
                    <Button
                      className={classes.startIconCustom}
                      startIcon={
                        <BorderColorOutlinedIcon
                          fontSize="small"
                          className={classes.colorButton}
                        />
                      }
                      onClick={(e) => {
                        setHrInitialEdit(evt.hrInitial);
                        setHrFinalEdit(evt.hrFinal);

                        setDayWeekSelected({ day: evt.day, index: idx });
                        setOpenModalEditOrDelete(true);
                        e.stopPropagation();
                      }}
                    ></Button>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }

  function DaysJourney(props) {
    return (
      <>
        <div className={classes.currentDay}>
          <div className={`${classes.dayWeekAndIcon} iconDay`}>
            <span>{'Jornada do dia'}</span>
          </div>
          <div>
            {dataJourney &&
              dataJourney.length > 0 &&
              dataJourney.map((evt, idx) => (
                <>
                  {evt.day == props.day && (
                    <div key={idx}>
                      <span className={classes.titleHoursJourney}>
                        {evt.totalHr} {'h'}
                      </span>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classes.baseCalendar}>
      <div className={classes.gridCalendar}>
        <div className={classes.itensCalendar}>
          {dayWeek.map((day, i) => (
            <>
              <DayWeek day={day} key={i} haveError={haveError} />
            </>
          ))}
          {dayWeek.map((day, i) => (
            <>
              <DaysJourney day={day} key={i} />
            </>
          ))}

          {renderModalAddItemDay()}
          {renderModalEditItemDay()}
          {renderModalEditOrDelete()}
        </div>
      </div>
    </div>
  );
}

DaysAndHours.defaultProps = {
  setIsPartialEdit: () => null,
};
