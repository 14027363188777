import { useState } from 'react';
import { Grid } from '@material-ui/core';
import DPaperAction from './../../../shared/DPaperAction';
import FormRecoverID from './../../components/FormRecoverID';
import FormRecoverPassword from './../../components/FormRecoverPassword';
import FormRecover from './../../components/FormRecover';
import FormRecoverPasswordChange from './../../components/FormRecoverPasswordChange';
import FormRecoverIDCode from './../../components/FormRecoverIDCode';
import FormRecoverPasswordCode from './../../components/FormRecoverPasswordCode';

function FormRecoverContent(props) {
  const [recover, setRecover] = useState(null);

  const acoes = [
    <FormRecoverID
      onBack={(e) => setRecover(null)}
      onCode={(e) => setRecover(2)}
    />,
    <FormRecover />,
    <FormRecoverIDCode
      onBack={(e) => setRecover(2)}
      onCode={(e) => props.onCode()}
    />,
    <FormRecoverPassword
      onBack={(e) => setRecover(null)}
      onCode={(e) => setRecover(4)}
    />,
    <FormRecoverPasswordCode
      onBack={(e) => setRecover(3)}
      onCode={(e) => setRecover(5)}
    />,
    <FormRecoverPasswordChange
      onBack={(e) => setRecover(4)}
      onCode={(e) => props.onCode()}
    />,
  ];

  return (
    <>
      {recover === null ? (
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DPaperAction
                  title="Esqueci o meu ID"
                  onAction={(e) => setRecover(0)}
                />
              </Grid>
              <Grid item>
                <DPaperAction
                  title="Esqueci minha senha"
                  onAction={(e) => setRecover(3)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>{acoes[recover]}</>
      )}
    </>
  );
}

FormRecoverContent.defaultProps = {
  onCode: function () {
    console.log('onCode 1');
  },
};

export default FormRecoverContent;
