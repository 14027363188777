import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolBarContent: {
    width: '100%',
    height: 58,
  },
  boxToolbarContent: {
    borderRadius: 32.5,
    height: 58,
    bottom: 24,
    left: 'calc(50% - 226.5px)',
  },
  boxInnerContent: {
    height: '100%',
  },
  boxPipe: {
    width: 1,
    height: 40,
    display: 'block',
    background: 'rgba(148, 148, 148, 1)',
  },
  iconSmall: {
    fontSize: 16,
  },
  boxTitleContent: {
    height: '100%',
  },
  textWhite: {
    color: 'white !important',
  },
});

export { useStyles };
