import env from 'react-dotenv';
import axios from 'axios';

function getAllSymptoms(token) {
  return axios
    .get(`${env.API_URL}/symptom/list`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createSymptom(token, dados) {
  return axios
    .post(`${env.API_URL}/symptom`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateSymptom(token, dados) {
  return axios
    .put(`${env.API_URL}/symptom/${dados.id}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteSymptom(token, id) {
  return axios
    .delete(`${env.API_URL}/symptom/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getAllSymptoms, createSymptom, updateSymptom, deleteSymptom };
