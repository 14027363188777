import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DButton from '../../../../shared/DButton';
import DDateInput from '../../../../shared/DDateInput';
import DInput from '../../../../shared/DInput';
import DMultAutoComplete from '../../../../shared/DMultAutoComplete';
import DDropDownSimple from '../../../../shared/DDropDownSimple';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAnimal, updateAnimal } from '../../../../../api/animal';

const useStyles = makeStyles((theme) => ({
  paciente__nome: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.neutral.white,
    fontWeight: 600,
  },
  paciente__topo: {
    position: 'absolute',
    left: 0,
    top: 98,
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.snow,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
    zIndex: 5,
  },
  input: {
    marginRight: 30,
  },
  labelBlack: {
    color: theme.palette.neutral.black,
  },
  buttonModal: {
    minWidth: 'unset !important',
    padding: '6px 18px !important',
    marginTop: '30px',
  },
  buttonModalCancelar: {
    minWidth: 'unset !important',
    padding: '6px 18px !important',
    marginTop: '30px',
    marginRight: '10px',
  },
}));

export default function EditAnimal({
  sendScreenToParent,
  patientId,
  animalId,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [specie, setSpecie] = useState('');
  const [breed, setBreed] = useState('');
  const [sex, setSex] = useState('');
  const [age, setAge] = useState('');
  const [coat, setCoat] = useState('');
  const [weight, setWeight] = useState('');
  const [chip_number, setChip_number] = useState('');
  const [pedigree_number, setPedigree_number] = useState('');
  const [temperament, setTemperament] = useState('');

  const sexos = [
    { id: 1, value: 'Fêmea' },
    { id: 2, value: 'Macho' },
  ];

  useEffect(() => {
    setLoading(true);

    getAnimal(animalId, localStorage.getItem('token')).then((res) => {
      if (res?.status) {
        setName(res.animal.name);
        setSpecie(res.animal.specie);
        setBreed(res.animal.breed);
        setSex(res.animal.sex);
        setAge(res.animal.age);
        setCoat(res.animal.coat);
        setWeight(res.animal.weight);
        setAge(res.animal.age);
        setChip_number(res.animal.chip_number);
        setPedigree_number(res.animal.pedigree_number);
        setTemperament(res.animal.temperament);
      }
      setLoading(false);
    });
  }, []);

  function getDataForm() {
    var dados = {
      name,
      specie,
      breed,
      sex,
      age,
      coat,
      weight,
      chip_number,
      pedigree_number,
      temperament,
      patient_id: patientId,
    };

    return dados;
  }

  function sendUpdate(dataPost) {
    setLoading(true);

    updateAnimal(animalId, localStorage.getItem('token'), dataPost).then(
      (res) => {
        if (res?.status) {
          toast.success('Animal atualizado com sucesso!');
          sendScreenToParent(1);
        } else {
          toast.error('Erro, não foi possível atualizar o animal.');
        }

        setLoading(false);
      },
    );
  }

  return (
    <>
      <Grid container className={`tema-light ${classes.root}`} spacing={3}>
        <Grid item md={12} style={{ marginTop: '1em' }}>
          <Typography
            className="text-primary text-bold"
            variant="h5"
            gutterBottom
          >
            Editar Animal
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex"
          alignItems="flex"
          item
          md={12}
          style={{ paddingTop: '0' }}
        >
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="name"
              label="Nome"
              placeholder="Nome"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="specie"
              label="Espécie"
              placeholder="Espécie"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setSpecie(e.target.value);
              }}
              value={specie}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="breed"
              label="Raça"
              placeholder="Raça"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setBreed(e.target.value);
              }}
              value={breed}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DDropDownSimple
              primaryColorSelectIcon
              id="sex"
              label="Sexo"
              placeholder="Sexo"
              fullWidth
              labelColor="black"
              labelClass={classes.labelBlack}
              value={sex}
              options={sexos}
              onChange={(event) => {
                setSex(event);
              }}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="age"
              label="Idade/Nascimento"
              placeholder="Idade"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setAge(e.target.value);
              }}
              value={age}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="weight"
              label="Peso"
              placeholder="Peso"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setWeight(e.target.value);
              }}
              value={weight}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="coat"
              label="Pelagem"
              placeholder="Pelagem"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setCoat(e.target.value);
              }}
              value={coat}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="chip_number"
              label="Número Chip"
              placeholder="Número Chip"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setChip_number(e.target.value);
              }}
              value={chip_number}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={3}>
            <DInput
              id="pedigree_number"
              label="Número Pedigree"
              placeholder="Número Pedigree"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setPedigree_number(e.target.value);
              }}
              value={pedigree_number}
            />
          </Grid>
          <Grid className={`${classes.input}`} item md={10}>
            <DInput
              id="temperament"
              label="Temperamento"
              placeholder="Temperamento"
              labelColor="black"
              type="text"
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                setTemperament(e.target.value);
              }}
              value={temperament}
            />
          </Grid>
          <Grid item>
            <DButton
              disabled={loading}
              className={`${classes.buttonModalCancelar}`}
              type="button"
              variant="secondary"
              size="sm"
              onClick={(e) => sendScreenToParent(1)}
            >
              Cancelar
            </DButton>
            <DButton
              multiClass={classes.buttonModal}
              variant="primary"
              size="sm"
              onClick={(e) => {
                var dados = getDataForm();
                sendUpdate(dados);
              }}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                <> {'Salvar'} </>
              )}
            </DButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
