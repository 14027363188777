import { createContext, useContext, useState } from 'react';

export const ConfiguracaoAgendaGeralContext = createContext();

export const ConfiguracaoAgendaGeralProvider = ({ children }) => {
  const [agendarAplicativo, setAgendarAplicativo] = useState(false);
  const [encaixe, setEncaixe] = useState(false);
  const [retorno, setRetorno] = useState(false);
  const [diasAgendamento, _setDiasAgendamento] = useState(0);
  const [periodoRetorno, _setPeriodoRetorno] = useState(0);
  const [lembretes, setLembretes] = useState([]);

  return (
    <ConfiguracaoAgendaGeralContext.Provider
      value={{
        agendarAplicativo,
        setAgendarAplicativo,
        encaixe,
        setEncaixe,
        retorno,
        setRetorno,
        diasAgendamento,
        _setDiasAgendamento,
        periodoRetorno,
        _setPeriodoRetorno,
        lembretes,
        setLembretes,
      }}
    >
      {children}
    </ConfiguracaoAgendaGeralContext.Provider>
  );
};

const getNumber = (value) => value.toString().replace(/[^0-9]/, '');

const getHoras = (maxHour = 42) => {
  const horas = new Array(maxHour).fill(0).map((_, index) => index + 1);

  return horas.map((hora) => ({
    value: hora,
    label: hora > 1 ? `${hora} Horas` : `${hora} hora`,
  }));
};

const getMinutos = (maxMinutos = 59) => {
  const minutos = new Array(maxMinutos).fill(0).map((_, index) => index + 1);

  return minutos.map((minutos) => ({
    value: minutos,
    label: minutos > 1 ? `${minutos} Minutos` : `${minutos} minuto`,
  }));
};

export const useConfiguracaoAgendaGeral = () => {
  const {
    agendarAplicativo,
    setAgendarAplicativo,
    encaixe,
    setEncaixe,
    retorno,
    setRetorno,
    diasAgendamento,
    _setDiasAgendamento,
    periodoRetorno,
    _setPeriodoRetorno,
    lembretes,
    setLembretes,
  } = useContext(ConfiguracaoAgendaGeralContext);

  const setDiasAgendamento = (value) => _setDiasAgendamento(getNumber(value));
  const setPeriodoRetorno = (value) => _setPeriodoRetorno(getNumber(value));

  const handleLembrete = (dias, minutos, mensagem, index) => {
    let new_lembretes = [...lembretes];

    new_lembretes[index] = {
      dias,
      minutos,
      mensagem,
    };

    setLembretes(new_lembretes);
  };

  const addLembrete = () => {
    let new_lembretes = [...lembretes];
    new_lembretes.push({ dias: 0, minutos: 0, mensagem: '' });
    setLembretes(new_lembretes);
  };

  const removeLembrete = (index) => {
    let new_lembretes = [...lembretes];
    new_lembretes.splice(index, 1);
    setLembretes(new_lembretes);
  };

  return {
    agendarAplicativo,
    setAgendarAplicativo,
    encaixe,
    setEncaixe,
    retorno,
    setRetorno,
    diasAgendamento,
    setDiasAgendamento,
    periodoRetorno,
    setPeriodoRetorno,
    lembretes,
    getHoras,
    getMinutos,
    handleLembrete,
    addLembrete,
    removeLembrete,
  };
};
