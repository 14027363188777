import { React, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import FieldType from './previewComponents/FieldType';
import ViewFieldType from './previewComponents/ViewFieldType';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
    marginBottom: 24,
    position: 'relative',
  },
  card_title: {
    color: theme.palette.neutral.black,
    fontSize: 16,
    margin: '0 0 16px',
  },

  card_subtitle: {
    color: theme.palette.neutral.black,
    fontSize: 14,
    margin: '0 0 16px',
  },
  card_history: {
    position: 'absolute',
    top: 2,
    right: 8,
    color: theme.palette.primary.light,
  },
  card_outline: {
    padding: 16,
    marginBottom: 24,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 4,
    fontSize: 18,
  },
}));

function PreviewCampo(props) {
  const classes = useStyles();

  const [values, setValues] = useState(
    props.campos.map((campo) => {
      return '';
    }),
  );

  return (
    <>
      <h6 className={classes.card_subtitle}>Modo edição</h6>
      <div className={classes.card_outline}>
        <Grid container spacing={1} alignItems="flex-end">
          {props.campos.map((campo, index) => (
            <FieldType
              key={`field-type-${index}`}
              campo={campo}
              onChange={(value) => {
                let new_values = [...values];
                new_values[index] = value;
                setValues(new_values);
              }}
            />
          ))}
        </Grid>
      </div>
      <h6 className={classes.card_subtitle}>Modo leitura</h6>
      <Card className={classes.card}>
        <IconButton className={classes.card_history}>
          <HistoryIcon style={{ fontSize: 22 }} />
        </IconButton>
        <ViewFieldType campos={props.campos} values={values} />
      </Card>
    </>
  );
}

PreviewCampo.defaultProps = {
  campos: [],
};

export default PreviewCampo;
