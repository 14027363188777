import React from 'react';

export const AnamneseFormFilterContext = React.createContext({});

export const AnamneseFormFilterProvider = (props) => {
  const [filter, setFilter] = React.useState({
    line_care: '',
    service_character: '',
    interconsultation: '',
    time: 0,
    active: 1,
  });

  const resetFilter = () => {
    console.log('resetFilter');
    setFilter({
      line_care: '',
      service_character: '',
      interconsultation: '',
      time: 0,
      active: 1,
    });
  };

  return (
    <AnamneseFormFilterContext.Provider
      value={{ filter, setFilter, resetFilter }}
    >
      {props.children}
    </AnamneseFormFilterContext.Provider>
  );
};

export const useAnamneseFormFilter = () =>
  React.useContext(AnamneseFormFilterContext);
