import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Close, Person, Save } from '@material-ui/icons';
import { Clear } from '@material-ui/icons';
import { Box } from '@mui/system';
import DInput from '../../../../shared/DInput';
import DAutoComplete from '../../../../shared/DAutoComplete';
import DRadio from '../../../../shared/DRadio';
import { getAllUnidades } from '../../../../../api/unidades';
import DButton from '../../../../shared/DButton';
import useError from '../../../../../hooks/useError';
import { required } from '../../../../../helpers/validacoes';
import construtor from '../../../../../api/construtor';
import CBO from '../../../../../api/cbo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DMultAutoComplete from '../../../../shared/DMultAutoComplete';
import LineCares from '../../../../../api/lineCare';
import { getAllMedicalSpecialties } from '../../../../../api/medicalSpecialty';
import { getSIGTAP } from '../../../../../api/diagnosticHypothesis';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 590,
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.snow,
    border: '1px solid #949494',
    borderRadius: 15,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headermodal: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 15px',
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '3px 15px',
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  medicoescard: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4',
    padding: '10px',
    borderRadius: '6px',
    boxShadow: '2px 4px 1px -2px rgba(0, 0, 0, 0.27)',
  },
  medicoescards: {
    width: 540,
  },
  content: {
    width: 545,
    height: 450,
    paddingTop: 16,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
  },
  dialog: {
    borderRadius: 8,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingTop: 16,
    paddingBottom: 16,
  },
  displayFaixaEtaria: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function FormsEditModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [name, setName] = useState('');
  const [typeAttendance, setTypeAttendance] = useState();
  const [consultationForm, setConsultationForm] = useState();
  const [medicalUnits, setMedicalUnits] = useState([]);
  const [medicalUnit, setMedicalUnit] = useState([]);
  const [medicalUnitsMap, setMedicalUnitsMap] = useState([]);
  const [cbo, setCBO] = useState(0);
  const [cbos, setCBOs] = useState([]); //list de CBOS

  const [specialty, setSpecialty] = useState(false); //especialidade selecionado
  const [specialties, setSpecialties] = useState([]); //list de especialidades
  const [specialtiesMap, setSpecialtiesMap] = useState([]);

  const [ageGroupInit, setAgeGroupInit] = useState(null);
  const [ageGroupEnd, setAgeGroupEnd] = useState(null);
  const [sex, setSex] = useState(null);
  const [sexes, setSexes] = useState([
    { id: 1, description: 'Ambos' },
    { id: 2, description: 'Masculino' },
    { id: 3, description: 'Feminino' },
  ]); //list de especialidades

  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const [lineOfCares, setLineOfCares] = useState([]);
  const [lineOfCaresMap, setLineOfCaresMap] = useState([]);
  const [lineOfCare, setLineOfCare] = useState([]);
  const [lineOfCareCheck, setLineOfCareCheck] = useState();

  const [status, setStatus] = useState();

  const [procedure, setProcedure] = useState(null);
  const [procedures, setProcedures] = useState([]);

  const [loadingSearch, setLoadingSearch] = useState(false);

  const notify = {
    form: {
      loading: () => toast.warning('Restaurando informações'),
      updated: () => toast.success('Formulário atualizado com sucesso!'),
      error: () => toast.error('Erro ao atualizar o formulário!'),
    },
  };

  useEffect(function () {
    getAllUnidades(localStorage.getItem('token')).then((res) => {
      if (!res.error && res.length > 0) {
        setMedicalUnits(
          res.filter((item) => item.medical_unit_type === 'Assistencial'),
        );
      }
    });

    CBO.get(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setCBOs(res.cbo);
      }
    });

    LineCares.listLineCare(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setLineOfCares(
          res.lineCare.data.map((x) => {
            return { id: x.id, value: x.name, active: x.active };
          }),
        );
      }
    });

    getAllMedicalSpecialties(localStorage.getItem('token')).then((res) => {
      if (res && res.length) {
        setSpecialties(res);
      }
    });
    setLoadingSearch(true);
    getSIGTAP(localStorage.getItem('token'))
      .then((res) => {
        if (res.status) {
          setLoadingSearch(false);
          setProcedures(
            res.sigtap.map((item) => {
              return {
                id: item.id,
                code: item.cd_procedimento_sus,
                value: `(${item.cd_procedimento_sus}) ${item.ds_procedimento}`,
              };
            }),
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(
    function () {
      if (props.open) notify.form.loading();

      setLoadingInfo(true);
      //if do formid
      construtor
        .show(localStorage.getItem('token'), props.formId)
        .then((res) => {
          if (res.status) {
            setName(res.anamnese_form.name);
            setCBO(res.anamnese_form.cbo_id);
            setLineOfCareCheck(res.anamnese_form.line_care);
            setLineOfCare(
              res.anamnese_form.anamnese_form_line_care
                ? res.anamnese_form.anamnese_form_line_care.map((res) => {
                    return {
                      id: res.line_care_id,
                      value: res.line_care
                        ? res.line_care
                        : 'Linha de cuidado removida ou não encontrada',
                    };
                  })
                : [],
            );
            setMedicalUnit(res.anamnese_form.anamnese_form_medical_units);
            setTypeAttendance(res.anamnese_form.type_service);
            setConsultationForm(res.anamnese_form.consultation_form);
            setSex(res.anamnese_form.sex);
            setAgeGroupInit(res.anamnese_form.age_group_init);
            setAgeGroupEnd(res.anamnese_form.age_group_end);

            setLineOfCaresMap(
              res.anamnese_form.anamnese_form_line_care.map((item) => {
                return { id: item.line_care.id, value: item.line_care.name };
              }),
            );

            setMedicalUnitsMap(
              res.anamnese_form.anamnese_form_medical_units.map((item) => {
                return { id: item.id, value: item.company_name };
              }),
            );

            setSpecialty(
              res.anamnese_form.anamnese_form_medical_speciality.length > 0
                ? res.anamnese_form.anamnese_form_medical_speciality[0]
                    .medical_speciality_id
                : '',
            );

            setSpecialtiesMap(
              res.anamnese_form.anamnese_form_medical_speciality.map((item) => {
                return {
                  id: item.medical_speciality.id,
                  value: item.medical_speciality.description,
                };
              }),
            );

            setProcedure(res.anamnese_form.procedure_id);

            setLoadingInfo(false);
            setStatus(res.anamnese_form.active);
          }
        });
    },
    [props.formId],
  );

  function getAllForms() {
    construtor.getList(localStorage.getItem('token')).then((res) => {
      if (res.status) props.forms(res.anamnese_forms.data);
    });
  }

  function closeModal() {
    setName('');
    setCBO('');
    setLineOfCareCheck('');
    setMedicalUnit([]);
    setTypeAttendance('');
    setConsultationForm('');

    props.onClose(true);
  }

  const [error, validarFormulario, setErrors] = useError({
    name: required,
    cbo: required,
    lineOfCare: required,
    lineOfCareCheck: required,
    typeAttendance: required,
    consultationForm: required,
  });

  function getData() {
    let medicalUnitArray = [];
    let lineOfCareArray = [];

    const medicalUnitsMap = medicalUnit.map((item) => {
      medicalUnitArray.push(item.id);
    });

    const lineOfCaresMap = lineOfCare.map((item) => {
      lineOfCareArray.push(item.id);
    });

    var dados = {
      name,
      cbo,
      specialty,
      medicalUnitArray,
      lineOfCare,
      lineOfCareCheck,
      lineOfCareArray,
      typeAttendance,
      consultationForm,
      procedure,
      status,
      sex,
      ageGroupInit,
      ageGroupEnd,
    };

    return dados;
  }

  function updateForm(dados) {
    var dadosPost = {
      name: dados.name,
      cbo_id: parseInt(dados.cbo),
      medical_speciality_id: dados.specialty,
      type_service: dados.typeAttendance,
      line_care: parseInt(dados.lineOfCareCheck),
      array_medical_unit: dados.medicalUnitArray,
      array_line_care: dados.lineOfCareArray ? dados.lineOfCareArray : [],
      consultation_form: parseInt(dados.consultationForm),
      procedure_id: dados.procedure,
      incomplete_data: 0,
      sex: dados.sex,
      age_group_init: dados.ageGroupInit,
      age_group_end: dados.ageGroupEnd,
    };

    var dadosPatch = {
      active: dados.status,
    };

    setLoading(true);
    construtor
      .put(localStorage.getItem('token'), props.formId, dadosPost)
      .then((res) => {
        if (res.status) {
          notify.form.updated();

          getAllForms();

          props.onUpdated(true);

          setLoading(false);
        } else if (res.details.name) {
          toast.warning('Já existe um formulário com este nome.');
          setLoading(false);
          return;
        } else {
          notify.form.error();
        }

        if (status == '0' || status == '1') {
          construtor
            .statusUpdate(
              localStorage.getItem('token'),
              props.formId,
              dadosPatch,
            )
            .then((res) => {
              if (res.status) {
                notify.form.updated();

                getAllForms();

                props.onUpdated(true);

                setLoading(false);
              } else {
                notify.form.error();
              }
            });
        }
      });
  }

  function changeTypeAttendance(newValue) {
    if (typeAttendance == 'agendado' && newValue != 'agendado') {
      toast.warn(
        'Ao trocar um caráter de atendimento do agendado para outra opção, lembre-se de verificar se existem agendas vinculadas ao formulário, pois esta mudança pode acarretar dificuldade no agendamento.',
      );
    }
    setTypeAttendance(newValue);
  }

  const cbosMap = cbos.map((item) => {
    return {
      id: item.id,
      code: item.code,
      value: `(${item.code}) ${item.description}`,
    };
  });

  const body = (
    <form
      autoComplete="off"
      onSubmit={(form) => {
        form.preventDefault();

        var dados = getData();

        if (lineOfCareCheck == 0) {
          dados.lineOfCare = [1];
        }

        if (validarFormulario({ ...dados })) {
          updateForm(dados);
        } else {
          console.log(error);
        }
      }}
    >
      <div>
        <>
          <Grid container justifyContent="center" className="tema-light">
            {loadingSearch ? (
              <>
                <CircularProgress size={15} color="inherit" /> Carregando...
              </>
            ) : (
              <>
                <Box style={{ marginTop: 5, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Nome</strong>
                      <DInput
                        id="nome-form"
                        label="Nome"
                        labelColor="black"
                        placeholder="Nome"
                        inputProps={{ maxLength: 100 }}
                        type="text"
                        error={error.name}
                        helperText={error.name}
                        fullWidth={true}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Ocupação que utilizará o formulário</strong>
                      {(cbo || cbo == null || cbo == '') &&
                      cbosMap.length > 0 ? (
                        <DAutoComplete
                          id="cbo-form"
                          label="Selecione o CBO"
                          placeholder=""
                          labelColor="black"
                          error={error.cbo}
                          helperText={error.cbo}
                          fullWidth={true}
                          defaultValue={
                            cbosMap.filter((res) => res.id === cbo)[0]
                          }
                          options={cbosMap}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.cbo;
                            setCBO(newValue ? newValue.id : '');
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Procedimento</strong>
                      {(procedure || procedure == null || procedure == '') &&
                      procedures.length > 0 ? (
                        <DAutoComplete
                          id="cbo-form"
                          label="Selecione o Procedimento"
                          placeholder=""
                          labelColor="black"
                          disabled={procedures.length == 0 ? 'true' : null}
                          error={error.procedure}
                          helperText={error.procedure}
                          fullWidth={true}
                          options={procedures}
                          defaultValue={
                            procedures.filter((res) => res.id === procedure)[0]
                          }
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.procedure;
                            setProcedure(newValue ? newValue.id : '');
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Unidades que terão acesso</strong>
                      {medicalUnits.length > 0 ? (
                        <DMultAutoComplete
                          placeholder="Unidades"
                          labelColor="black"
                          label="Selecione as unidades"
                          // error={ error.medicalUnit }
                          // helperText={ error.medicalUnit }
                          defaultValue={medicalUnitsMap}
                          options={medicalUnits.map(function (item) {
                            return { id: item.id, value: item.company_name };
                          })}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            // delete error.medicalUnit;
                            setMedicalUnit(newValue);
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Especialidade</strong>
                      {(specialty || specialty === '') &&
                        specialties.length > 0 && (
                          <DAutoComplete
                            id="specialty-form"
                            label="Selecione a especialidade"
                            placeholder=""
                            labelColor="black"
                            error={error.specialty}
                            helperText={error.specialty}
                            fullWidth={true}
                            value={
                              specialty &&
                              specialties.filter((res) => res.id === 1)
                                ? specialties.filter(
                                    (res) => res.id === specialty,
                                  )[0]
                                : ''
                            }
                            options={specialties}
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              delete error.specialty;
                              setSpecialty(newValue ? newValue.id : '');
                            }}
                          />
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Sexo</strong>
                      {(sex || sex === '' || sex == null) &&
                        sexes.length > 0 && (
                          <DAutoComplete
                            id="sex-form"
                            label="Selecione o sexo"
                            placeholder=""
                            labelColor="black"
                            error={error.sex}
                            helperText={error.sex}
                            fullWidth={true}
                            value={
                              sex && sexes.filter((res) => res.id === 1)
                                ? sexes.filter((res) => res.id === sex)[0]
                                : 1
                            }
                            options={sexes}
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              delete error.sex;
                              setSex(newValue ? newValue.id : '');
                            }}
                          />
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Faixa Etária (anos)</strong>
                      <Box className={classes.displayFaixaEtaria}>
                        <Grid item md={6}>
                          <Input
                            id="ageGroupInit"
                            label="Idade Inicial"
                            placeholder="Idade Inicial"
                            onChange={(e) => {
                              const val = /^[0-9]*$/.test(e.target.value);
                              if (val) {
                                setAgeGroupInit(e.target.value);
                              }
                            }}
                            value={ageGroupInit}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Input
                            id="age_group_end"
                            label="Idade Final"
                            placeholder="Idade Final"
                            onChange={(e) => {
                              const val = /^[0-9]*$/.test(e.target.value);
                              if (val) {
                                setAgeGroupEnd(e.target.value);
                              }
                            }}
                            value={ageGroupEnd}
                          />
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Linha de cuidado</strong>
                      <DRadio
                        inline
                        name="line-of-care-form"
                        error={error.lineOfCareCheck}
                        helperText={error.lineOfCareCheck}
                        onChange={(e) => setLineOfCareCheck(e.target.value)}
                        value={lineOfCareCheck}
                        options={[
                          { value: '0', label: 'Não' },
                          { value: '1', label: 'Sim' },
                        ]}
                      />
                      {lineOfCareCheck === '1' ? (
                        <Box>
                          {lineOfCares.length > 0 ? (
                            <DMultAutoComplete
                              placeholder="Linhas de cuidado"
                              label="Linhas de cuidado"
                              labelColor="black"
                              error={error.lineOfCare}
                              helperText={error.lineOfCare}
                              // defaultValue={ lineOfCares && lineOfCares.length ? lineOfCares.filter(res => lineOfCaresMap.map(item => res.id == item.id)) : []}
                              defaultValue={lineOfCaresMap}
                              options={lineOfCares.map(function (item) {
                                return { id: item.id, value: item.value };
                              })}
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) =>
                                value && option.id === value.id
                              }
                              onChange={(e, newValue) => {
                                setLineOfCare(newValue);
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Modelo de formulário</strong>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: -21,
                        }}
                      >
                        <h5 style={{ marginRight: 15 }}>
                          Caráter de atendimento:
                        </h5>
                        <DRadio
                          inline
                          name="type-attendance-form"
                          error={error.typeAttendance}
                          helperText={error.typeAttendance}
                          onChange={(e) => changeTypeAttendance(e.target.value)}
                          value={typeAttendance}
                          options={[
                            { value: 'imediato', label: 'Imediato' },
                            { value: 'monitoramento', label: 'Monitoramento' },
                            { value: 'orientacao', label: 'Orientação' },
                            { value: 'triagem', label: 'Triagem' },
                            { value: 'agendado', label: 'Agendado' },
                          ]}
                        />
                      </Box>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ marginRight: 15 }}>
                          Formulário de interconsulta?
                        </h5>
                        <DRadio
                          inline
                          name="form-interconsult"
                          error={error.consultationForm}
                          helperText={error.consultationForm}
                          onChange={(e) => setConsultationForm(e.target.value)}
                          value={consultationForm}
                          options={[
                            { value: '0', label: 'Não' },
                            { value: '1', label: 'Sim' },
                          ]}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Box className={classes.medicoescards}>
                    <Box className={classes.medicoescard}>
                      <strong>Status</strong>
                      <DRadio
                        inline
                        name="status-form"
                        error={error.status}
                        helperText={error.status}
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                        options={[
                          { value: '0', label: 'Inativo' },
                          { value: '1', label: 'Ativo' },
                        ]}
                      />
                    </Box>
                  </Box>
                </Box>
                <Grid item md={11} sm={6}>
                  <Box style={{ marginBottom: 15, float: 'right' }}>
                    <DButton
                      type="submit"
                      size="sm"
                      variant="primary"
                      icon={<Save fontSize="small" />}
                    >
                      {loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        'Salvar'
                      )}
                    </DButton>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </div>
    </form>
  );

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialog }}
        aria-labelledby="form-construct-edit"
        scroll="body"
        // onClose={(e) => props.onClose(true)}
        onClose={(e) => closeModal()}
        open={props.open}
      >
        {loadingInfo ? (
          <>
            <DialogTitle
              id="form-construct-edit"
              classes={{ root: classes.title }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h2"
                    className="text-mt text-regular text-primary"
                  >
                    Editar Formulário
                  </Typography>
                </Grid>
                <Grid item>
                  <Close onClick={(e) => closeModal()} />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: 400 }}
              >
                <Grid item className="text-center">
                  <CircularProgress
                    className="text-success"
                    style={{ fontSize: 64 }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle
              id="medical-unity-create-title"
              classes={{ root: classes.title }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h2"
                    className="text-mt text-regular text-primary"
                  >
                    Editar Formulário
                  </Typography>
                </Grid>
                <Grid item>
                  <Close onClick={(e) => closeModal()} />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              {body}
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
}
