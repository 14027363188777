import { styled } from '@mui/material/styles';
import {
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
  FormControl,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  formControlRoot: {
    width: '100%',
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
  },
  formControlErrorRoot: {
    width: '100%',
    border: 'solid #FF6B57 1px',
    borderRadius: 8,
  },
  selectRoot: {
    height: 53,
    padding: '12px 16px',
    boxSizing: 'border-box',
    '&:disabled': {
      backgroundColor: '#F5F5F5',
    },
  },
  select: {
    fontSize: 14,
  },
  selectError: {
    border: 'solid #FF6B57 1px',
    fontSize: 14,
    borderRadius: 8,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
}));

function TSelectNative({
  label,
  name,
  options,
  value,
  onChange,
  error,
  helperText,
  required,
  id,
  disabled,
  onBlur,
}) {
  const classes = useStyles();

  return (
    <>
      <InputLabel
        error={error}
        classes={{ root: classes.label, error: classes.errorLabel }}
      >
        {label} {required ? '*' : ''}
      </InputLabel>
      <FormControl
        classes={{
          root: error ? classes.formControlErrorRoot : classes.formControlRoot,
        }}
      >
        <NativeSelect
          error={error}
          classes={{
            select: classes.select,
            root: classes.selectRoot,
          }}
          disableUnderline
          fullWidth
          id={id}
          disabled={disabled}
          onBlur={onBlur}
          IconComponent={(e) => disabled ?? <ExpandMoreIcon />}
          name={name}
          value={value}
          onChange={onChange}
        >
          <option value="">Selecione</option>
          {options.map((option, index) => (
            <option key={`native-select-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {helperText && (
        <p
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
        >
          {helperText}
        </p>
      )}
      {error && (
        <p className={`${classes.helperText} ${classes.helperTextError}`}>
          {error}
        </p>
      )}
    </>
  );
}

TSelectNative.defaultProps = {
  label: 'Select Native',
  name: 'select_native',
  options: [],
  onChange: (e) => {
    console.log('onChange');
  },
  onBlur: (e) => {
    console.log('onBlur');
  },
};

export default TSelectNative;
