import env from 'react-dotenv';
import axios from 'axios';

function getAllContacts(token) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  return axios
    .get(`${api_url}/faq/contact/all?token=${token}`, config)
    .then((res) => res.data);
}

function getContactById(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  return axios
    .get(`${api_url}/faq/contact/${id}?token=${token}`, config)
    .then((res) => res.data);
}

function createContact(token, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return fetch(`${api_url}/faq/contact?token=${token}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': token,
      'x-domain': env.X_DOMAIN,
    },
    method: 'post',
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function updateContact(token, dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return fetch(`${api_url}/faq/contact/${id}?token=${token}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': token,
      'x-domain': env.X_DOMAIN,
    },
    method: 'put',
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteContact(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return fetch(`${api_url}/faq/contact/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': token,
      'x-domain': env.X_DOMAIN,
    },
    method: 'delete',
  }).then((res) => res.json());
}

export {
  getAllContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
};
