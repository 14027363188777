import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Checkbox, Input, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 215,
      marginTop: 51,
    },
  },
};

export default function DDropdown(props) {
  const classes = useStyles();

  const handleChange = (event) => props.onChange(event.target.value);

  return (
    <div>
      <FormControl className={classes.formControl}>
        {!props.notLabel && (
          <InputLabel id={`select-label-${props.id}`}>{props.label}</InputLabel>
        )}
        <Select
          labelId={`select-label-${props.id}`}
          id={`${props.id}`}
          value={props.value}
          multiple
          error={props.error}
          renderValue={(selected) => {
            console.log(selected);

            var _selected = selected.filter((x) => typeof x === 'number');

            var _isAllSelected = selected.find((x) => typeof x === 'text');

            var _allObj = selected.filter((x) => typeof x === 'object');

            if (selected.find((x) => x === 'todos')) {
              return `${_allObj.length} selecionados`;
            } else {
              return `${_selected.length} selecionados`;
            }

            if (selected.length > 1) {
              return props.value.indexOf('todos') > -1
                ? `${selected.length - 1} selecionados`
                : `${selected.length} selecionados`;
            } else {
              for (var cont = 0; cont < props.options.length; cont++) {
                if (props.options[cont].id === selected[0])
                  return props.options[cont].value;
              }
            }
          }}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
        >
          <MenuItem key={`${props.id}-todos`} value={'todos'}>
            <Checkbox
              checked={props.value && props.value.indexOf('todos') > -1}
            />
            <ListItemText primary="Todos" />
          </MenuItem>

          {props.options.length &&
            props.options.map(function (item, key) {
              return (
                <MenuItem
                  key={`${props.id}-option-item-${key}`}
                  value={item.id}
                >
                  <Checkbox
                    checked={
                      (props.value && props.value.indexOf('todos') > -1) ||
                      props.value.indexOf(item.id) > -1
                    }
                  />
                  <ListItemText primary={item.value} />
                </MenuItem>
              );
            })}

          <MenuItem key={`${props.id}-reset`} value={'reset'}>
            <ListItemText
              className="text-right text-est text-regular text-link"
              primary="Resetar seleção"
            />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
