import { useContext, useEffect, useRef, useState } from 'react';
import { DeleteForever } from '@material-ui/icons';
import DInput from './../../../../component/shared/DInput';
import { mascaraNumero } from './../../../../helpers/formatacao';
import DDropDownSimple from './../../../../component/shared/DDropDownSimple';
import {
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';

function Telefone(props) {
  const [tipo, setTipo] = useState(null);
  const [telefone, setTelefone] = useState(null);

  useEffect(() => {
    setTipo(props.tipo);
    setTelefone(mascaraNumero(props.telefone));
  }, [props.tipo, props.telefone]);

  function mascaraNumero(value) {
    try {
      var numero = value.substr(0, 15);

      numero = numero.replace(/\D/g, '');
      numero = numero.replace(/^(\d{2})(\d)/g, '($1) $2');
      numero = numero.replace(/(\d)(\d{4})$/, '$1-$2');

      return numero;
    } catch (err) {
      console.error(err);
      return value;
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <DDropDownSimple
          label="Tipos de telefone"
          placeholder="Tipos de telefone"
          fullWidth
          error={props.error && !tipo}
          helperText={props.error && !tipo ? props.helperText : ''}
          value={tipo}
          options={props.tiposTelefone}
          onChange={(value) => {
            props.onUpdate({ tipo: value, telefone });
          }}
        />
      </Grid>
      <Grid item md={6}>
        <DInput
          label="Telefone"
          placeholder="Telefone"
          type="text"
          error={props.error}
          helperText={props.error ? props.helperText : ''}
          fullWidth
          value={telefone}
          onChange={(e) => {
            let telefone_not_mascara = e.target.value
              .toString()
              .replace(/[^0-9]/g, '');
            props.onUpdate({
              tipo,
              telefone: mascaraNumero(telefone_not_mascara),
            });
          }}
          icon={
            props.index !== 0 ? (
              <DeleteForever
                style={{ color: 'red', cursor: 'pointer' }}
                fontSize="small"
                onClick={(e) => {
                  props.onRemove(true);
                }}
              />
            ) : null
          }
          positionIcon="end"
        />
      </Grid>
    </Grid>
  );
}

Telefone.defaultProps = {
  index: 0,
  tiposTelefone: [
    {
      id: 'Comercial',
      value: 'Comercial',
    },
    {
      id: 'Pessoal',
      value: 'Pessoal',
    },
    {
      id: 'Residencial',
      value: 'Residencial',
    },
  ],
  tipo: null,
  telefone: null,
  error: undefined,
};

export default Telefone;
