import React, { useState, useEffect } from 'react';
import env from 'react-dotenv';
import '../../../index.scss';
import StarIcon from '@mui/icons-material/Star';
import {
  getConferenceByRoom,
  getConferenceCallByAttendenceId,
} from './../../../../../../api/videocall';
import { useNewHealthAttendance } from './../../../../../../context/NewHealthAttendance';
import { toast } from 'react-toastify';
import CustomModalLigacao from './../CustomModalLigacao';
import { Button, Grid, useTheme } from '@material-ui/core';
import FoneCall from './../../../../../../assets/img/fone-call.png';
import PlayCall from './../../../../../../assets/img/play_arrow.png';
import { FilterBarContainer } from '../styles';
import { getHistoryByPatient } from '../../../../../../api/patient';
import {
  filterConference,
  filterVoip,
  getUserInfoById,
} from '../../../../../../api/user';
import { getFormatDate } from '../../../../../../helpers/formatacao';
import { ExpandMore, SyncRounded } from '@material-ui/icons';

export default function LigacoesHistorico(props) {
  const theme = useTheme();
  const { healthAttendance, patient } = useNewHealthAttendance();
  const [videos, setVideos] = useState([]);
  const [videolCall, setVideoCall] = useState(null);
  const [calls, setCalls] = useState([]);
  const [call, setCall] = useState(null);

  useEffect(() => {
    console.log('calls', calls);
  }, [calls]);

  useEffect(() => {
    if (healthAttendance) {
      let token = localStorage.getItem('token');

      getConferenceByRoom(token, healthAttendance.id).then((res) =>
        res.status
          ? setVideos(res.recordings[0])
          : toast.warn('Nenhuma gravação encontrada.'),
      );

      getConferenceCallByAttendenceId(token, healthAttendance.id).then((res) =>
        res.status
          ? setCalls(res.recordings)
          : toast.warn('Nenhuma gravação de ligação encontrada.'),
      );
    }
  }, [healthAttendance]);

  const handleVideo = (video) => {
    let token = localStorage.getItem('token');

    setVideoCall(
      `${env.API_URL}/conference/handle-recording/${video.sid}?token=${token}`,
    );
  };

  const handleCall = (video) => {
    let token = localStorage.getItem('token');

    setCall(`${env.API_URL}/call/handle-recording/${video.sid}?token=${token}`);
  };

  const filterBy = (type) => {
    console.log(healthAttendance);

    var userId = [];
    var healthAttendanceId = [healthAttendance?.id];
    var medical_speciality_id = [];
    var initialDate = '';
    var finalDate = '';
    var health_attendance_type_id = [];

    const postFilterData = {
      user_id: userId,
      health_attendance_id: healthAttendanceId,
      medical_speciality_id: medical_speciality_id,
      dataInicial: initialDate,
      dataFinal: finalDate,
      health_attendance_type_id: health_attendance_type_id,
    };

    switch (type) {
      case 'period':
        postFilterData.dataInicial =
          getFormatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00';
        postFilterData.dataFinal = getFormatDate(
          new Date(new Date().setHours(23, 59, 59, 999)),
          'yyyy-MM-dd hh:mm:ss',
        );

        Promise.all([
          filterConference(postFilterData),
          filterVoip(postFilterData),
        ])
          .then((res) => {
            console.log('resPromAll', res);
            var recordings = [];

            res.forEach((item) => {
              if (item.recordings) {
                recordings = [...recordings, ...item.recordings];
              }
            });
            setCalls(recordings);
          })
          .catch((err) => {
            console.log('filterError', err);
            if (err.response?.data) {
              toast.error(
                err.response?.data?.message || 'Erro ao buscar conferências',
              );
            }
          });
        break;
      case 'protocol':
        Promise.all([
          filterConference(postFilterData),
          filterVoip(postFilterData),
        ])
          .then((res) => {
            console.log('resPromAll', res);
            var recordings = [];

            res.forEach((item) => {
              if (item.recordings) {
                recordings = [...recordings, ...item.recordings];
              }
            });
            setCalls(recordings);
          })
          .catch((err) => {
            console.log('filterError', err);

            if (err.response?.data) {
              toast.error(
                err.response?.data?.message || 'Erro ao buscar conferências',
              );
            }
          });

        break;
      case 'attendanceType':
        postFilterData.health_attendance_type_id = [
          healthAttendance?.health_attendance_type_id,
        ];

        Promise.all([
          filterConference(postFilterData),
          filterVoip(postFilterData),
        ])
          .then((res) => {
            console.log('resPromAll', res);
            var recordings = [];

            res.forEach((item) => {
              if (item.recordings) {
                recordings = [...recordings, ...item.recordings];
              }
            });
            setCalls(recordings);
          })
          .catch((err) => {
            console.log('filterError', err);
            if (err.response?.data) {
              toast.error(
                err.response?.data?.message || 'Erro ao buscar conferências',
              );
            }
          });
        break;
      case 'professional':
        postFilterData.user_id = [healthAttendance?.user_requesting?.id];

        Promise.all([
          filterConference(postFilterData),
          filterVoip(postFilterData),
        ])
          .then((res) => {
            console.log('resPromAll', res);
            var recordings = [];

            res.forEach((item) => {
              if (item.recordings) {
                recordings = [...recordings, ...item.recordings];
              }
            });
            setCalls(recordings);
          })
          .catch((err) => {
            console.log('filterError', err);
            if (err.response?.data) {
              toast.error(
                err.response?.data?.message || 'Erro ao buscar conferências',
              );
            }
          });
        break;
      case 'speciality':
        getUserInfoById(
          localStorage.getItem('token'),
          healthAttendance?.user_requesting?.id,
        ).then((resUser) => {
          if (resUser.status) {
            postFilterData.medical_speciality_id = resUser.speciality.map(
              (spec) => spec.medical_speciality_id,
            );

            Promise.all([
              filterConference(postFilterData),
              filterVoip(postFilterData),
            ])
              .then((res) => {
                console.log('resPromAll', res);
                var recordings = [];

                res.map((item) => {
                  if (item.recordings) {
                    recordings = [...recordings, ...item.recordings];
                  }
                });
                setCalls(recordings);
              })
              .catch((err) => {
                console.log('filterError', err);
                if (err.response?.data) {
                  toast.error(
                    err.response?.data?.message ||
                      'Erro ao buscar conferências',
                  );
                }
              });
          }
        });
        break;

      default:
        // reset
        getHistoryByPatient(localStorage.getItem('token'), patient.id).then(
          (res) => {
            if (res.status) {
              console.log(res);
              // setHistories(res.histories);
            }
          },
        );
        break;
    }
  };

  return (
    <>
      <div className="fundo_new_tab">
        <FilterBarContainer
          primaryColor={theme.palette.primary.main}
          className="shadow-01"
        >
          <Button
            onClick={() => {
              filterBy('period');
            }}
          >
            <span>Período</span>

            <ExpandMore />
          </Button>
          <Button
            onClick={() => {
              filterBy('protocol');
            }}
          >
            <span>Protocolo</span>

            <ExpandMore />
          </Button>
          <Button
            onClick={() => {
              filterBy('attendanceType');
            }}
          >
            <span>Tipo da consulta</span>

            <ExpandMore />
          </Button>
          <Button
            onClick={() => {
              filterBy('professional');
            }}
          >
            <span>Profissional</span>

            <ExpandMore />
          </Button>
          <Button
            onClick={() => {
              filterBy('speciality');
            }}
          >
            <span>Especialidade</span>

            <ExpandMore />
          </Button>

          <button
            className="filter_reset"
            onClick={() => {
              filterBy('reset');
            }}
          >
            <SyncRounded />
            Redefinir
          </button>
        </FilterBarContainer>
        <div className="main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Ligações</div>
        </div>
        <div className="fonte_subtitulo_new_tabs text-primary mt_35_new_tab">
          Últimas ligações
        </div>
        <div className="grid_cards_ligacoes mt_35_new_tab">
          {videos.map((video) => (
            <div className="flex_column_card_ligacoes">
              <div className="card_ligacoes">
                <div
                  className="thumb_ligacoes"
                  onClick={(e) => handleVideo(video)}
                >
                  <img src={PlayCall} />
                </div>
                <div className="card_text_ligacoes">
                  <div className="titulo_card_ligacoes">
                    Teleconsulta - {video.date_formated}
                  </div>
                  <div className="duracao_card_ligacoes mt10_new_tabs">
                    Duração:{' '}
                    {`${video.duration < 60 ? video.duration : Math.round(video.duration / 60)} ${video.duration < 60 ? 's' : 'm'}`}
                  </div>
                  {/* <div className='info_card_ligacoes mt10_new_tabs'>
									<div className='star_icon_card'>
										<StarIcon style={{ fontSize: '18px' }} />
									</div>
									<div className='nota_card_ligacoes'>4.9</div>
									<div className='numero_comentarios_card_ligacoes'>(3 comentários)</div>
								</div> */}
                </div>
              </div>
            </div>
          ))}

          {typeof calls != 'undefined' &&
            calls.map((call) => (
              <div className="flex_column_card_ligacoes">
                <div className="card_ligacoes">
                  <div
                    className="thumb_ligacoes"
                    onClick={(e) => handleCall(call)}
                  >
                    <img src={FoneCall} />
                  </div>
                  <div className="card_text_ligacoes">
                    <div className="titulo_card_ligacoes">
                      Teleconsulta - {call.dateFormated}
                    </div>
                    <div className="duracao_card_ligacoes mt10_new_tabs">
                      Duração:{' '}
                      {`${call.duration < 60 ? call.duration : Math.round(call.duration / 60)} ${call.duration < 60 ? 's' : 'm'}`}
                    </div>
                    {/* <div className='info_card_ligacoes mt10_new_tabs'>
									<div className='star_icon_card'>
										<StarIcon style={{ fontSize: '18px' }} />
									</div>
									<div className='nota_card_ligacoes'>4.9</div>
									<div className='numero_comentarios_card_ligacoes'>(3 comentários)</div>
								</div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {videolCall && (
        <CustomModalLigacao open={true} onClose={(e) => setVideoCall(null)}>
          <video width="100%" height="100%" autoPlay controls>
            <source src={videolCall} type="video/mp4" />
            Carregando...
          </video>
        </CustomModalLigacao>
      )}

      {call && (
        <CustomModalLigacao open={true} onClose={(e) => setCall(null)}>
          <Grid
            container
            style={{ height: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <audio autoPlay controls>
                <source src={call} type="audio/ogg" />
                Carregando...
              </audio>
            </Grid>
          </Grid>
        </CustomModalLigacao>
      )}
    </>
  );
}
