import React, { useState } from 'react';
import '../index.scss';
import AnamneseNewTab from '../../NewTabs/AnamneseNewTab';
import HistoricoNewTab from '../../NewTabs/HistoricoNewTab';
import SolicitanteNewTab from '../../NewTabs/SolicitanteNewTab';
import PacienteNewTab from '../../NewTabs/PacienteNewTab';
import AlergiasNewTab from '../../NewTabs/AlergiasNewTab';
import DadosEnviadosNewTab from '../../NewTabs/DadosEnviadosNewTab';
import AntecedentesNewTab from '../../NewTabs/AntecedentesNewTab';
import GruposNewTab from '../../NewTabs/GruposNewTab';
import UnidadeNewTab from '../../NewTabs/UnidadeNewTab';
import DadosPrincipaisNewTab from '../../NewTabs/DadosPrincipaisNewTab';

export default function SubmenuNewCardsPaciente(props) {
  const [tab, setTab] = useState(0);
  const tabList = [
    <DadosPrincipaisNewTab />,
    <AlergiasNewTab />,
    <GruposNewTab />,
    <UnidadeNewTab />,
  ];
  const checkActive = (index) => {
    if (tab === index) {
      return 'item_selected_submenu_new_cards background-color';
    }
  };
  const submenuOptions = ['Dados principais', 'Alergias', 'Grupos', 'Unidade'];
  return (
    <div className="main_flex_submenu_new_cards">
      <div className="background_submenu_new_cards">
        {submenuOptions.map((submenuOption, key) => (
          <div
            className={'flex_1_submenu_new_cards ' + checkActive(key)}
            onClick={() => setTab(key)}
          >
            {submenuOption}
          </div>
        ))}
      </div>
      <div className="mt40-submenu-new-cards">{tabList[tab]}</div>
    </div>
  );
}
