import React, { useCallback, useState } from 'react';
import { useContext, useEffect } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Paper from '@material-ui/core/Paper';
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import { SideBarFilterProfessionalContext } from '../../../../context/useFilterProfessional';
import { AppContext } from '../../../../context/AppContext';
import { useFilterProfessional } from '../../../../context/useFilterProfessional';
import ProfissionalViewModal from '../ProfissionalViewModal/ProfissionalViewModal';
import { getAllUserStatus } from '../../../../api/user';
import StatusProfissional from './StatusProfissional';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chip: {
    margin: 4,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  selected: {
    backgroundColor: `${theme.palette.background.snow} !important`,
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: theme.palette.primary.light,
    fontWeight: '500 !important',
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
    '&>li:last-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    textAlign: 'left',
  },
}))(TableCell);

const StyledTitleTableCell = withStyles((theme) => ({
  body: {
    textAlign: 'left',
    color: theme.palette.neutral.black,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.snow,
    },
  },
}))(TableRow);

const StyledTableRowHeader = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutral.snow,
    // borderBottom: `solid 2px ${theme.palette.neutral.black} !important`,
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    textTransform: 'unset',
    fontSize: 14,
    padding: 0,
    minWidth: 'unset',
  },
}))(Button);

const StyledButtonAction = withStyles((theme) => ({
  root: {
    textTransform: 'unset',
    fontSize: 14,
    color: theme.palette.primary.light,
  },
}))(Button);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledTitle = withStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.primary.light,
  },
}))((props) => (
  <>
    {props.order === 'asc' ? <KeyboardArrowUpIcon color="primary" /> : ''}
    {props.order === 'desc' ? <KeyboardArrowDownIcon color="primary" /> : ''}
  </>
));

const StyledOutlinedInput = withStyles((theme) => ({
  root: {
    background: theme.palette.background.snow,
    minWidth: 400,
    marginBottom: 30,
  },
}))(OutlinedInput);

function sortByProfessionalName(profissionais, order) {
  var array = [...profissionais];

  if (order.name === 'asc') {
    array = array.reverse(function (a, b) {
      return a.name - b.name;
    });
  } else if (order.name === 'desc') {
    array = array.reverse(function (a, b) {
      return a.name - b.name;
    });
  }
  return array;
}

export default function ProfissionalGrid(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    profissionais,
    setProfissionais,
    loadingProfissionais,
    setLoadingProfissionais,
    profissionaisPaginate,
  } = useContext(SideBarFilterProfessionalContext);
  const { newHandleProgfiles, setProfileInfo, userInfo, setUserInfo, userId } =
    useContext(AppContext);
  const { setTotaisStatus } = useFilterProfessional();

  const [openProfissionalViewModal, setOpenProfissionalViewModal] =
    useState(false);
  const [order, setOrder] = useState({ name: null });
  const [orderName, setOrderName] = useState(null);
  const [idProfissional, setIdProfissional] = useState(null);
  const [nomeProfissional, setNomeProfissional] = useState(null);
  const [statusProfissional, setStatusProfissional] = useState(null);
  const [aceiteTermoProfissional, setAceiteTermoProfissional] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setIdProfissional(null);
  }, [profissionais]);

  function handleOpenProfissionalViewModal(user) {
    setOpenProfissionalViewModal(true);
    setIdProfissional(user.id);
    setNomeProfissional(user.name);
    setStatusProfissional(user.status);
    setAceiteTermoProfissional(user.accepted_term);
  }

  function handleCloseProfissionalViewModal() {
    let oldUserInfo = userInfo;

    if (userId == idProfissional) {
      setProfileInfo([]);
      newHandleProgfiles();
      setUserInfo(oldUserInfo);
    }

    props.onPage(page);

    setOpenProfissionalViewModal(false);
    setIdProfissional(null);
    setNomeProfissional(null);
    setStatusProfissional(null);
    setAceiteTermoProfissional(null);

    getAllUserStatus(localStorage.getItem('token')).then((res) => {
      setTotaisStatus(res.total);
    });
  }

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: 'white' }}
        open={loadingProfissionais && loadingProfissionais}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="profissionais table">
          <TableHead>
            <StyledTableRowHeader>
              <StyledTitleTableCell>Status</StyledTitleTableCell>
              <StyledTitleTableCell>
                <StyledButton
                  onClick={(event) => setOrderName(event.currentTarget)}
                >
                  Nome
                  <StyledTitle order={order.name} />
                </StyledButton>
                <StyledMenu
                  id="order-nome-profissional"
                  anchorEl={orderName}
                  keepMounted
                  open={Boolean(orderName)}
                  onClose={(event) => setOrderName(null)}
                >
                  <MenuItem
                    onClick={(event) => {
                      setOrderName(null);
                      setOrder({ ...order, name: 'asc' });
                    }}
                  >
                    {'A > Z'}
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      setOrderName(null);
                      setOrder({ ...order, name: 'desc' });
                    }}
                  >
                    {'Z > A'}
                  </MenuItem>
                </StyledMenu>
              </StyledTitleTableCell>
              <StyledTitleTableCell>Conselho</StyledTitleTableCell>
              <StyledTitleTableCell>CPF</StyledTitleTableCell>
              <StyledTitleTableCell>Perfis que possui</StyledTitleTableCell>
              <StyledTitleTableCell>Unidades</StyledTitleTableCell>
              <StyledTitleTableCell>&nbsp;</StyledTitleTableCell>
            </StyledTableRowHeader>
          </TableHead>
          <TableBody>
            {Array.isArray(profissionais) &&
              profissionais.map((row, index) => (
                <StyledTableRow key={`profissionais-${index}`}>
                  <StyledTableCell component="th" scope="row">
                    {row.status ? (
                      <StatusProfissional status={row.status} />
                    ) : (
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.name ? row.name : '-'}</StyledTableCell>
                  <StyledTableCell>
                    {row.conselho && row.conselho.length == 0
                      ? '-'
                      : row.conselho && Object.values(row.conselho).length > 1
                        ? Object.values(row.conselho).map((conselho, index) => {
                            index == 0 ? (
                              <>{conselho}</>
                            ) : (
                              <>{', ' + conselho}</>
                            );
                          })
                        : row.conselho &&
                          Object.values(row.conselho).map(
                            (conselho) => conselho,
                          )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row && Array.isArray(row.cpf)
                      ? row.cpf[0]
                      : row.cpf != null
                        ? row.cpf
                        : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row && Array.isArray(row.perfis)
                      ? row.perfis
                          .map((u) => u.description)
                          .filter(
                            (un, ix) =>
                              row.perfis
                                .map((ru) => ru.description)
                                .indexOf(un) == ix,
                          )
                          .map((labelStr, index) => {
                            if (!labelStr) return;
                            return (
                              <Chip
                                key={`chip-${index}`}
                                size="small"
                                label={labelStr}
                                classes={{ root: classes.chip }}
                              />
                            );
                          })
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row && Array.isArray(row.units) && row.units.length
                      ? row.units.map((unidade, index) => {
                          return (
                            <Chip
                              key={`chip-${index}`}
                              size="small"
                              label={unidade.medical_unit}
                              classes={{ root: classes.chip }}
                            />
                          );
                        })
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    <a
                      href="#"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        color: theme.palette.primary.light,
                        textDecoration: 'none',
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        handleOpenProfissionalViewModal(row);
                      }}
                    >
                      <OpenInNewIcon />
                      <p id="demo">&nbsp;Abrir</p>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {profissionaisPaginate && profissionaisPaginate.total > 0 && (
        <Pagination
          count={profissionaisPaginate ? profissionaisPaginate.last_page : 1}
          page={profissionaisPaginate ? profissionaisPaginate.current_page : 1}
          classes={{ root: classes.pagination, ul: classes.ul }}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              classes={{
                selected: classes.selected,
                page: classes.page,
              }}
            />
          )}
          onChange={(event, page) => {
            setPage(page);
            props.onPage(page);
          }}
        />
      )}
      {idProfissional && (
        <ProfissionalViewModal
          open={openProfissionalViewModal}
          onClose={handleCloseProfissionalViewModal}
          idProfissional={idProfissional}
          nomeProfissional={nomeProfissional}
          statusProfissional={statusProfissional}
          aceiteTermoProfissional={aceiteTermoProfissional}
        />
      )}
    </>
  );
}
