import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import Box from '@material-ui/core/Box';
import CloseIcon from '@mui/icons-material/Close';
import AddCircle from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Backdrop,
  IconButton,
  Typography,
  Container,
  Card,
  CircularProgress,
  CardContent,
  Button,
  InputLabel,
  Select,
  Grid,
  MenuItem,
} from '@material-ui/core';
import {
  getHistoricoVersaoById,
  getListTerm,
  getTermActive,
  saveTerm,
  termData,
} from './api/termosUso';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 15,
    color: theme.palette.primary.light,
  },
  titleHistorico: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 15,
    color: theme.palette.primary.light,
  },
  hrHistorico: {
    width: '100%',
  },
  box: {
    background: theme.palette.background.cloud,
    borderRadius: 8,
    marginBottom: 32,
  },
  inputTitleLabel: {
    fontSize: 18,
  },
  inputStyle: {
    width: '100%',
  },
  dialogContent: {
    padding: 16,
    width: 296,
    color: theme.palette.neutral.black,
  },
  btnAcaoModal: {
    whiteSpace: 'nowrap',
    margin: '8px',
  },
  addTagButton: {
    borderRadius: 20,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
    padding: '5px 18px',
    margin: 2,
  },
  headerCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelSelecionarVersao: {
    width: '100%',
  },
  selectVersao: {
    width: '50%',
  },
  contentVersaoHistorico: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 20,
  },
  btSave: {
    color: '#fff',
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
    marginLeft: 8,
  },
  btBack: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  closeIconModal: {
    color: '#949494',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  viewHtml: {
    width: '100%',
    minWidth: '500px',
    height: 430,
    border: 1,
  },
}));

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export default function TermosUso() {
  const token = localStorage.getItem('token');
  const classes = useStyles();
  const [oldTermoVersao, setOldTermoVersao] = useState(null);

  const [oldTermVersion, setOldTermVersion] = useState([]);
  const [oldIdTermVersion, setOldIdTermVersion] = useState(null);
  const [oldDescTermVersion, setOldDescTermVersion] = useState(null);

  const [currentTermo, setCurrentTermo] = useState(null);
  const [openDialogVersoes, setOpenDialogVersoes] = useState(false);
  const [openDialogSalvar, setOpenDialogSalvar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(function () {
    getTermActive(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setCurrentTermo(res.term);
      }
    });
  }, []);

  useEffect(
    function () {
      if (openDialogVersoes) {
        getListTerm(localStorage.getItem('token')).then((res) => {
          if (res.status) {
            setOldTermVersion(res.term);
          }
        });
      }
    },
    [openDialogVersoes],
  );

  const getHistoricoVersao = (e) => {
    const id = e.target.value;
    setOldIdTermVersion(id);
    let descriptionTerm =
      oldTermVersion && oldTermVersion.find((term) => term.id == id);
    setOldDescTermVersion(descriptionTerm);
  };

  const handleCloseVersoes = () => {
    setOpenDialogVersoes(false);
  };

  const handleCloseSalvar = () => {
    setOpenDialogSalvar(false);
  };

  const handleConfirmChanges = () => {
    setOpenDialogSalvar(false);
    if (
      currentTermo.terms.length > 0 &&
      currentTermo.title.length > 0 &&
      currentTermo.terms != '<p><br></p>'
    ) {
      saveTerm(localStorage.getItem('token'), currentTermo)
        .then((data) => {
          if (data.status) {
            toast.success('Termo de uso salvo com sucesso');
            setCurrentTermo(data.term);
          } else {
            toast.error('Erro ao salvar termo de uso');
            console.error(data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error('Preencha todos os campos!');
    }
  };

  const renderModalSalvarTermoUso = () => {
    return (
      <>
        <Dialog open={openDialogSalvar} onClose={handleCloseSalvar}>
          <Typography className={classes.titleHistorico}>
            Termo de Uso e Política de Privacidade da Plataforma{' '}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={classes.closeIconModal}
            onClick={handleCloseSalvar}
          >
            <CloseIcon />
          </IconButton>
          <hr className={classes.hrHistorico} />
          <DialogContent classaName={classes.dialogContent}>
            <Grid item className={classes.contentVersaoHistorico}>
              <ReactQuill
                readOnly={true}
                theme="snow"
                value={currentTermo ? currentTermo.terms : ''}
                className={classes.viewHtml}
                modules={{ toolbar: false }}
                formats={{}}
              />
            </Grid>
            <Grid container>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  variant="primary"
                  className={classes.btBack}
                  size="sm"
                  onClick={handleCloseSalvar}
                >
                  Sair mesmo assim
                </Button>
                <Button
                  variant="primary"
                  className={classes.btSave}
                  size="sm"
                  onClick={handleConfirmChanges}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const renderModalVersoesTermoUso = () => {
    return (
      <>
        <Dialog open={openDialogVersoes} onClose={handleCloseVersoes}>
          <Typography className={classes.titleHistorico}>
            Histórico de Versões
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={classes.closeIconModal}
            onClick={handleCloseVersoes}
          >
            <CloseIcon />
          </IconButton>
          <hr className={classes.hrHistorico} />
          <DialogContent classaName={classes.dialogContent}>
            <Grid container>
              <InputLabel className={classes.labelSelecionarVersao}>
                Selecione uma versão
              </InputLabel>
              <Select
                // value={profiles}
                className={classes.selectVersao}
                onChange={getHistoricoVersao}
              >
                {oldTermVersion.map((item) => (
                  <MenuItem value={item.id}>{item.version}</MenuItem>
                ))}
              </Select>

              <Grid item className={classes.contentVersaoHistorico}>
                <ReactQuill
                  readOnly={true}
                  theme="snow"
                  value={oldDescTermVersion ? oldDescTermVersion.terms : ''}
                  className={classes.viewHtml}
                  modules={{ toolbar: false }}
                  formats={{}}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: 'white', textAlign: 'center' }}
        open={showLoader}
      >
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando configurações de tema...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <Container style={{ marginTop: 20 }}>
        <Typography variant="h3" className={classes.title}>
          Termos de Uso
        </Typography>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Grid item className={classes.headerCardContent}>
                  <Typography
                    className={classes.title}
                    variant="h4"
                    component="h2"
                  >
                    Editar termos de uso
                  </Typography>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="sm"
                      startIcon={<AddCircle style={{ fontSize: 16 }} />}
                      className={classes.addTagButton}
                      onClick={() => {
                        setOpenDialogSalvar(true);
                      }}
                    >
                      Visualizar e salvar nova versão
                    </Button>

                    <Button
                      variant="outlined"
                      size="sm"
                      startIcon={<AddCircle style={{ fontSize: 16 }} />}
                      className={classes.addTagButton}
                      onClick={() => setOpenDialogVersoes(true)}
                    >
                      Histórico de versões
                    </Button>
                  </Grid>
                </Grid>

                <Box className={classes.box} style={{ padding: 16 }}>
                  <Typography className={classes.inputTitleLabel} variant="h4">
                    Título
                  </Typography>
                  <Input
                    type="text"
                    id="user-term-title"
                    className={classes.inputStyle}
                    maxLength="30"
                    value={currentTermo?.title}
                    onChange={(e) => {
                      if (e.target.value.length <= 30)
                        setCurrentTermo({
                          ...currentTermo,
                          title: e.target.value,
                        });
                    }}
                  />
                </Box>

                <Box className={classes.box} style={{ height: 400 }}>
                  <ReactQuill
                    theme="snow"
                    id="user-term-content"
                    value={currentTermo ? currentTermo.terms : ''}
                    style={{ height: 360, border: 0 }}
                    modules={modules}
                    formats={formats}
                    onKeyDown={(event) => {
                      if (
                        currentTermo?.terms.length >= 1000 &&
                        event.key !== 'Backspace' &&
                        event.key !== 'Home' &&
                        event.key !== 'End' &&
                        event.key !== 'Shift' &&
                        event.key !== 'ArrowLeft' &&
                        event.key !== 'ArrowRight' &&
                        event.key !== 'Delete'
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(value, delta, name, events) => {
                      if (value != '') {
                        setCurrentTermo((y) => {
                          return { ...y, terms: value };
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      {renderModalSalvarTermoUso()}
      {renderModalVersoesTermoUso()}
    </>
  );
}
