import React from 'react';
import env from 'react-dotenv';
import ErrorPage from '../ErrorPage/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, prod: env.NODE_ENV === 'production' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (errorInfo && errorInfo.componentStack) {
      console.log(error && error.toString());
      console.log(new Error(errorInfo.componentStack));
    }

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError && this.state.prod) {
      // Se tem erro e está em `produção`.
      return <ErrorPage voltar={(e) => this.setState({ hasError: false })} />;
      // return <ErrorUI voltar={e => this.setState({hasError: false})} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
