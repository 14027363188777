//External library
import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

//Material UI
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

//assets
import { CheckCircleOutline } from '@material-ui/icons';

//components
import SimpleModal from '../shared/Modal';
import FormCadastro from './components/FormCadastro';
import StepLine from './components/Step';
import TopBar from './components/TopBar';

//API
import { userDominios } from '../../api/dominios';
import { createUser, getMeByInvite } from '../../api/user';

class AutoCadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirecting: false,
      step: 0,
      invite: null,
      domains: null,
      isRegistering: false,
      isRegistered: false,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    getMeByInvite(params.get('token')).then((res) => {
      if (res.status) this.setState({ ...this.state, invite: res.details });
      else this.setState({ ...this.state, isRedirecting: true });
    });

    userDominios().then((res) => {
      this.setState({ ...this.state, domains: res });
    });
  }

  handleErrors = (res, dados, setErrors) => {
    let errors = {};

    if (res.error === 'E-mail já cadastrado') {
      errors.email = 'E-mail já cadastrado';
    }

    if (res.details) {
      Object.entries(res.details).forEach(([key, value]) => {
        Object.assign(errors, { [key]: value.join(',') });
      });

      this.handleUsernameError(res);
      this.handleDocumentError(res, errors);
      this.handleContactError(res, errors);
    }

    setErrors({ ...dados.error, ...errors });
    this.setState({ step: 0, isRegistering: false });
  };

  handleUsernameError = (response) => {
    if (
      response.details &&
      response.details['user.username'] &&
      Array.isArray(response.details['user.username'])
    ) {
      if (
        response.details['user.username'][0] ===
        'USER.USERNAME já cadastrado no sistema.'
      ) {
        toast.error('ID já utilizado no sistema.');
      }
    }
  };

  handleDocumentError = (response, errors) => {
    if (
      response.details &&
      response.details.document &&
      Array.isArray(response.details.document)
    ) {
      if (
        response.details.document[0] === 'CPF inválido ou CPF já utilizado.'
      ) {
        errors.cpf = 'CPF inválido ou CPF já utilizado.';
      }

      response.details.document.forEach((message) => {
        toast.error(message);
      });
    }
  };

  handleContactError = (response, errors) => {
    if (
      response.details &&
      response.details.contact &&
      Array.isArray(response.details.contact)
    ) {
      errors.telefones = 'Telefone já cadastrado no sistema.';

      response.details.contact.forEach((message) => {
        toast.error(message);
      });
    }
  };

  userRegister(dados, setErrors) {
    const params = new URLSearchParams(this.props.location.search);
    this.setState({ ...this.state, isRegistering: true });

    createUser(params.get('token'), dados)
      .then((res) => {
        if (res.status) {
          this.setState({
            ...this.state,
            isRegistering: false,
            isRegistered: true,
          });
        } else {
          console.log('false');
          this.handleErrors(res, dados, setErrors);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ ...this.state, isRegistering: false });
      })
      .finally(() => {
        this.setState({ isRegistering: false });
      });
  }

  render() {
    return (
      <div className="bg-white h-100">
        {this.state.isRedirecting && <Redirect to="/" />}
        <TopBar />
        <ToastContainer />
        <StepLine step={this.state.step} />
        {this.state.domains && this.state.invite ? (
          <FormCadastro
            voltar={(e) =>
              this.setState({ ...this.state, isRedirecting: true })
            }
            loading={this.state.isRegistering}
            enviarCadastro={(dados, setErr) => this.userRegister(dados, setErr)}
            step={this.state.step}
            onStep={(step) => this.setState({ ...this.state, step })}
            dominios={this.state.domains}
            invite={this.state.invite}
          />
        ) : (
          <Backdrop
            style={{ zIndex: 3000, color: 'white' }}
            open={!this.state.domains}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <SimpleModal
          open={this.state.isRegistered}
          onClose={(e) => this.setState({ ...this.state, isRedirecting: true })}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
          >
            <Grid item className="text-center">
              <CheckCircleOutline
                className="text-success"
                style={{ fontSize: 64 }}
              />
              <Typography className="text-mt text-regular">
                Cadastro efetuado com sucesso
              </Typography>
            </Grid>
          </Grid>
        </SimpleModal>
      </div>
    );
  }
}

export default withRouter(AutoCadastro);
