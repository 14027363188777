import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 53,
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    fontSize: 14,
    padding: '12px 16px 12px 16px',
  },
  rootDisabled: {
    borderColor: '#F5F6F6',
    background: '#F5F6F6',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
}));

function TSearchBase({
  label,
  name,
  value,
  placeholder,
  onChange,
  error,
  helperText,
}) {
  const classes = useStyles();

  return (
    <>
      <InputLabel
        error={error}
        classes={{
          root: classes.label,
          error: classes.errorLabel,
        }}
      >
        {label}
      </InputLabel>
      <FormControl
        //   className={clsx(classes.textField)}
        variant="outlined"
        size="small"
        autoComplete="off"
      >
        <OutlinedInput
          id="outlined"
          type="text"
          error={error}
          name={name}
          placeholder={placeholder ?? label}
          labelWidth={0}
          value={value}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              // listarProfissional();
            }
          }}
          classes={{
            root: classes.root,
            disabled: classes.rootDisabled,
            error: classes.error,
          }}
          onChange={(e) => {
            //   setFilterValue(
            //     e.target.value
            //       .toString()
            //       .replace(
            //         /[`´¨~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/]/gi,
            //         ""
            //       )
            //   );
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton onClick={(e) => 'listarProfissional()'} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {helperText && (
        <p
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
        >
          {helperText}
        </p>
      )}
    </>
  );
}

TSearchBase.defaultProps = {
  label: 'Search Base',
  name: 'search_base',
  value: '',
  placeholder: '',
  error: false,
  helperText: '',
  onChange: (e) => {
    console.log('onChange');
  },
};

export default TSearchBase;
