import 'date-fns';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import {
  Backdrop,
  CircularProgress,
  Typography,
  Grid,
} from '@material-ui/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import SearchList from '../../../shared/SearchList';
import SelectRangeDate from '../../../shared/SelectRangeDate';
import CustomPopover from './CustomPopover';
import DInput from '../../../shared/DInput';
import { fetchCurrentUserDetails } from '../../../../api/user';
import { getUnidade } from '../../../../api/unidades';
import { AppContext } from 'context/AppContext';

const status = [
  {
    id: 'ativo',
    label: 'Ativo',
  },
  {
    id: 'inativo',
    label: 'Inativo',
  },
];

const useStyles = makeStyles((theme) => ({
  btnFilter: {
    margin: '0 auto',
    marginTop: '15px',
    borderRadius: '20px',
    width: '120px',
    display: 'block',
  },

  menuFilter: {
    margin: '10px',
    fontSize: '14px',
    color: theme.palette.neutral.midgrey,
  },

  menuRedefinir: {
    color: `${theme.palette.primary.light} !important`,
  },

  labelFilter: {
    verticalAlign: 'middle',
  },

  btnCriarAgenda: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.white,
  },
}));

export default function Filter({ handleMenuItem, callbackFilter }) {
  const { userInfo } = useContext(AppContext);

  const classes = useStyles();
  const token = localStorage.getItem('token');
  const filtrosSelectedinit = {
    status: [
      {
        id: 'ativo',
        label: 'Ativo',
      },
    ],
    search: '',
    start_date_min: null,
    start_date_max: null,
  };

  const [medicalUnitType, setMedicalUnitType] = useState(null);
  const [anchorStatus, setAnchorStatus] = useState(null);
  const [anchorPeriodo, setAnchorPeriodo] = useState(null);
  const [filtrosSelecionados, setFiltrosSelecionados] =
    useState(filtrosSelectedinit);
  const [dataInicial, setDataInicial] = useState(null); //moment().subtract(10, "days").toDate());
  const [dataFinal, setDataFinal] = useState(null); //moment().toDate());

  const FormatDate = (strDate, format) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format(format);
  };

  const handlePeriodoClick = (event) => {
    setAnchorPeriodo(event.currentTarget);
  };

  const handlePeriodoClose = (event) => {
    setAnchorPeriodo(null);
  };

  const handleStatusClick = (event) => {
    setAnchorStatus(event.currentTarget);
  };

  const handleStatusClose = (event) => {
    setAnchorStatus(null);
  };

  const setStatusFilter = (statuses) => {
    const st = status.filter((u) => statuses.map((a) => a.id).includes(u.id));
    setFiltrosSelecionados({ ...filtrosSelecionados, status: st });
  };

  const setDateFilter = (dates) => {
    setDataInicial(dates.dataInicial);
    setDataFinal(dates.dataFinal);
    setFiltrosSelecionados({
      ...filtrosSelecionados,
      start_date_min: dates.dataInicial
        ? FormatDate(dates.dataInicial, 'DD/MM/YYYY 00:00:00')
        : null,
      start_date_max: dates.dataFinal
        ? FormatDate(dates.dataFinal, 'DD/MM/YYYY 23:59:59')
        : null,
    });
  };

  useEffect(() => {
    if (userInfo.unidade) {
      getUnidade(localStorage.getItem('token'), userInfo.unidade).then(
        (res) => {
          setMedicalUnitType(res.details.medicalUnit.medical_unit_type);
        },
      );
    }
  }, [userInfo.unidade]);

  function buildCreateButton() {
    if (medicalUnitType === 'Assistencial') {
      return (
        <>
          <Tooltip title={`Comece clicando aqui`} placement="left">
            <Button
              aria-describedby="simple-popover-perfil"
              onClick={() => handleMenuItem('create_agenda')}
              className={[classes.menuFilter, classes.btnCriarAgenda]}
              type="button"
            >
              Criar Agenda
            </Button>
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title={`Unidade não assistencial`} placement="left">
            <Button
              disabled
              aria-describedby="simple-popover-perfil"
              onClick={() => handleMenuItem('create_agenda')}
              className={[classes.menuFilter, classes.btnCriarAgenda]}
              type="button"
            >
              Criar Agenda
            </Button>
          </Tooltip>
        </>
      );
    }
  }

  return (
    <>
      <Grid container>
        <Grid item md={1}>
          <h3 className={classes.labelFilter}>Agendas</h3>
        </Grid>
        <Grid item md={3}>
          <DInput
            notLabel
            id="search_text"
            label="Pesquisar: profissional, especialidade, ocupação e conselho"
            placeholder="Pesquisar: profissional, especialidade, ocupação e conselho"
            labelColor="black"
            type="text"
            positionIcon="end"
            fullWidth={true}
            onChange={(e) => {
              setFiltrosSelecionados({
                ...filtrosSelecionados,
                search: e.target.value,
              });
            }}
            value={filtrosSelecionados.searchText}
            onBlur={(e) => callbackFilter(filtrosSelecionados)}
          />
        </Grid>

        <Grid item md={3}>
          <Button
            aria-describedby="simple-popover-periodo"
            className={classes.menuFilter}
            onClick={handlePeriodoClick}
          >
            Selecione um período &nbsp; <ExpandMoreIcon />
          </Button>

          <CustomPopover
            id="simple-popover-periodo"
            anchor={anchorPeriodo}
            handleClose={handlePeriodoClose}
          >
            <SelectRangeDate
              dateStart={dataInicial}
              dateEnd={dataFinal}
              callbackFilterSelect={setDateFilter}
              callbackFilterClick={(e) => {
                setAnchorPeriodo(null);
                callbackFilter(filtrosSelecionados);
              }}
            />
          </CustomPopover>
        </Grid>

        <Grid item md={3}>
          <Button
            aria-describedby="simple-popover-status"
            className={classes.menuFilter}
            onClick={handleStatusClick}
            type="button"
          >
            Selecione o status &nbsp; <ExpandMoreIcon />
          </Button>

          <CustomPopover
            id="simple-popover-executor"
            anchor={anchorStatus}
            handleClose={handleStatusClose}
          >
            <SearchList
              src={status}
              searchAttr="label"
              keyAttr="id"
              callbackFilterSelect={setStatusFilter}
              callbackFilterClick={(e) => {
                setAnchorStatus(null);
                callbackFilter(filtrosSelecionados);
              }}
              selecionados={filtrosSelecionados.status}
            />
          </CustomPopover>
        </Grid>

        <Grid item md={2}>
          {buildCreateButton()}
        </Grid>
      </Grid>
    </>
  );
}
