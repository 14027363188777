import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import DInput from '../../../../../../shared/DInput';
import DRadio from '../../../../../../shared/DRadio';

const EBRadioButton = ({
  id,
  label,
  name,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [input, setInput] = useState({});
  const [options, setOptions] = useState({});

  const handleChange = (e) => {
    setOptions(e.target.value);
    handleInputSubmit({ [id]: e.target.value });
  };

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      {field_category_id === 2 && (
        <div>
          <div className="mb-3">
            <Typography
              variant="body1"
              className="text-bold text-primary"
              style={{ fontSize: '1em', display: 'flex' }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `${additional_data.construct.showTitle ? label : ''}`,
                }}
              />
              <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
                {is_required === 1 ? '*' : ''}
              </span>
            </Typography>
            {additional_data.options.length > 0 ? (
              <DRadio
                id={id}
                name={name}
                inline={
                  additional_data.construct.inline != null
                    ? additional_data.construct.inline
                    : ''
                }
                // readOnly={is_read_only === 1 ? true : false}
                disabled={readOnly}
                required={is_required === 1 ? true : false}
                onChange={handleChange}
                value={options.length > 0 ? options : answer[0]}
                options={additional_data.options}
              />
            ) : (
              'Não existe dados cadastrados'
            )}
            <br />

            <span
              style={{ fontSize: 12, color: '#D13D2E' }}
              className="text-regular"
            >
              {erro[0] ? erro[0].value : ''}
            </span>

            <br />

            <span style={{ fontSize: 12 }} className="text-light">
              {helper_text ? helper_text : ''}
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBRadioButton;
