import React, { useState, useEffect } from 'react';
import './index.scss';
import { Button } from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';
import { Typography } from '@material-ui/core';

export default function DroagAndDropLogotipo({ logoTipo }) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const renderSelectImage = () => {
    if (selectedLogo) {
      return (
        <>
          <img
            style={{ display: 'block', margin: '0 auto' }}
            src={selectedLogo}
            alt=""
          />
        </>
      );
    } else {
      return (
        <>
          {/* <div className='fonte_subtitledropdown mt_20_dropdown'>
            Arraste seu arquivo aqui, ou <Button className='botao_dropdown'>procure-o</Button>
          </div> */}
        </>
      );
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setSelectedLogo(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setSelectedLogo(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const renderLogoTipo = () => {
    if (logoTipo) {
      return (
        <>
          <div className="fundo_dropdown">
            <div className="flex_title_dropdown">
              <div className="fonte_titledropdown mt_35_dropdown">
                Logotipo da plataforma
              </div>
            </div>
            <div className="flex_title_dropdown">
              <div className="fonte_subtitledropdown mt_35_dropdown">
                Adicione a sua marca na platafoma
              </div>
            </div>

            <div className="mt_35_dropdown">
              <div className="border_dropdown">
                <div>
                  <img
                    style={{ display: 'block', margin: '0 auto' }}
                    src={selectedLogo || logoTipo}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <Typography
              for="logo_config"
              color="primary"
              className="lbl-logo-config"
              component="label"
            >
              Selecione seu arquivo aqui
            </Typography>
            <input
              onChange={onSelectFile}
              type="file"
              id="logo_config"
              accept="image/png, image/jpeg, image/svg"
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="fundo_dropdown">
            <div className="flex_title_dropdown">
              <div className="fonte_titledropdown mt_35_dropdown">
                Logotipo da plataforma
              </div>
            </div>
            <div className="flex_title_dropdown">
              <div className="fonte_subtitledropdown mt_35_dropdown">
                Adicione a sua marca na platafoma
              </div>
            </div>

            <div className="mt_35_dropdown">
              <div className="border_dropdown">
                <div>
                  <UploadIcon className="icon_dropdown" />
                </div>
                {renderSelectImage()}
                <Typography
                  for="logo_config"
                  color="primary"
                  className="lbl-logo-config"
                  component="label"
                >
                  Selecione seu arquivo aqui
                </Typography>
                <input
                  onChange={onSelectFile}
                  type="file"
                  id="logo_config"
                  accept="image/png, image/jpeg, image/svg"
                />
                <div className="fonte_info_dropdown mt_10_dropdown">
                  Formatos suportados: .SGV, .PNG ou JPEG
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <>{renderLogoTipo()}</>;
}
