import { Divider } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';

const EBSpace = ({ id, label, placeholder, value }) => {
  return (
    <section>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <span style={{ visibility: 'hidden' }}>space</span>
      </div>
    </section>
  );
};

export default EBSpace;
