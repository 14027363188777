import {
  CallReceived,
  Mic,
  PeopleAlt,
  PhoneEnabled,
  PhoneDisabled,
  PhoneInTalk,
  MicOff,
  Phone,
  VideoCall,
  Person,
  PersonAdd,
  Chat,
  OpenInBrowser,
  Videocam,
  VideocamOff,
} from '@material-ui/icons';
import DButton from './../../DButton';
import { SvgIcon } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useState } from 'react';
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles } from './../theme';

const CircleButtom = withStyles((theme) => ({
  root: {
    width: 32,
    minWidth: 32,
    height: 32,
    padding: 0,
    textTransform: 'capitalize',
    color: theme.palette.primary.light,
    border: 'solid #d8ebea 1px',
    borderRadius: 100,
    backgroundColor: '#d8ebea',
    '&:hover': {
      backgroundColor: '#d8ebea',
    },
  },
}))(Button);

const CircleBlueButtom = withStyles((theme) => ({
  root: {
    width: 32,
    minWidth: 32,
    height: 32,
    padding: 0,
    textTransform: 'capitalize',
    color: theme.palette.primary.light,
    border: 'solid #d8ebea 1px',
    borderRadius: 100,
    backgroundColor: 'rgba(74, 74, 233, 0.08)',
    '&:hover': {
      backgroundColor: '#d8ebea',
    },
  },
}))(Button);

const RedButtom = withStyles((theme) => ({
  root: {
    width: 110,
    height: 33,
    textTransform: 'capitalize',
    color: '#FFF',
    border: `1px solid ${theme.palette.support.error} !important`,
    borderRadius: 100,
    backgroundColor: theme.palette.support.error,
    '&:hover': {
      backgroundColor: theme.palette.support.error,
    },
  },
}))(Button);

export const MuteCall = (props) => {
  return (
    <CircleButtom {...props}>
      <Mic fontSize="small" />
    </CircleButtom>
  );
};

export const UnmuteCall = (props) => {
  return (
    <CircleButtom {...props}>
      <MicOff fontSize="small" />
    </CircleButtom>
  );
};

export const AddUserCall = (props) => {
  return (
    <CircleBlueButtom {...props}>
      <PersonAdd fontSize="small" />
    </CircleBlueButtom>
  );
};

export const ChatButtom = (props) => {
  return (
    <CircleBlueButtom {...props}>
      <Chat fontSize="small" />
    </CircleBlueButtom>
  );
};

export const OpenInBrowserButtom = (props) => {
  return (
    <CircleButtom {...props}>
      <OpenInBrowser fontSize="small" />
    </CircleButtom>
  );
};

export const VideoButtom = (props) => {
  return (
    <CircleButtom {...props}>
      <Videocam fontSize="small" />
    </CircleButtom>
  );
};

export const VideoOffButtom = (props) => {
  return (
    <CircleButtom {...props}>
      <VideocamOff fontSize="small" />
    </CircleButtom>
  );
};

export const DesligarButtom = (props) => {
  const classes = useStyles();

  return (
    <RedButtom {...props}>
      <PhoneDisabled
        classes={{ fontSizeSmall: classes.iconSmall }}
        fontSize="small"
      />
      &nbsp;Desligar
    </RedButtom>
  );
};
