import { Grid, Typography } from '@material-ui/core';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { useStyles } from './styles';

export const Cns = () => {
  const classes = useStyles();

  const { patient, dadosCns } = usePatient();

  let cont = 2;

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
          <h5 className={classes.dados_titulo}>Cartão Nacional de Saúde</h5>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className="text-regular text-st"
            style={{ marginBottom: 10 }}
          >
            <strong>CNS Principal:</strong>{' '}
            {dadosCns &&
              Array.isArray(dadosCns) &&
              dadosCns.find((x) => x.default) &&
              dadosCns.find((x) => x.default).value}
          </Typography>

          <>
            {dadosCns &&
              dadosCns
                .filter((x) => x.default !== true)
                .map((res, index) => {
                  return (
                    <Typography
                      variant="body1"
                      style={{ marginBottom: 10 }}
                      className="text-regular text-st"
                      key={`cns-secundario-${index}`}
                    >
                      <strong>CNS {cont++}:</strong> {res.value}
                    </Typography>
                  );
                })}
          </>
        </Grid>
      </Grid>
    </>
  );
};
