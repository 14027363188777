/* eslint-disable no-use-before-define */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './assets/sass/app.scss';
import Agenda from './component/Agenda';
import AgendaMedico from './component/AgendaMedico';
import AtendimentoImediatoContent from './component/AtendimentoImediato/AtendimentoImediatoContent';
import AtendimentoSideBar from './component/AtendimentoImediato/AtendimentoSideBar';
import Configuracao from './component/Configuracao';
import FaqUser from './component/FaqUser/FaqUser';
import HealthAttendanceType from './component/HealthAttendanceType';
import MeuPerfil from './component/MeuPerfil';
import PacienteContent from './component/Paciente/PacienteContent';
import ProfissionalContent from './component/Profissional/ProfissionalContent.jsx';
import LayoutAdmin from './component/shared/LayoutAdmin';
import LayoutAdminContext from './component/shared/LayoutAdminContext';

import { Sidebar as SidebarConfiguracao } from './component/Configuracao/component/Sidebar';
import SideBarPacientes from './component/Paciente/components/SideBar';
import SideBar from './component/Profissional/components/SideBar';

import ConfiguracaoAgenda from 'component/ConfiguracaoAgenda/ConfiguracaoAgenda';
import ModalCallInterconsultation from 'component/shared/ModalCallInterconsultation';
import { NewHealthAttendanceProvider } from 'context/NewHealthAttendance';
import { toast, ToastContainer } from 'react-toastify';
import Anamnese from './component/AtendimentoImediato/PacienteDetails/components/Anamnese';
import CreatePaciente from './component/Paciente/components/CreatePaciente';
import PowerBI from './component/PowerBI';
import HistoricoAcoes from './component/Relatorios/HistoricoAcoes';
import FloatingButton from './component/shared/FloatingButton';
import InviteInterconsultation from './component/shared/InviteInterconsultation';
import ModalCall from './component/shared/ModalCall';
import ModalChat from './component/shared/ModalChat';
import ModalIncomingCall from './component/shared/ModalIncomingCall';
import NotificationCentral from './component/shared/Notifications/Panel';
import NotifyPopUp from './component/shared/Notifications/PopUp/NotifyPopUp';
import SolicitacoesContent from './component/Solicitacoes/SolicitacoesContent';
import TelaEmBranco from './component/TelaEmBranco';
import { AppContext } from './context/AppContext';
import AtendimentoContextProvider from './context/AtendimentoContext';
import {
  PatientContext,
  PatientProvider as PatientCtxProvider,
  usePatient,
} from './context/PatientContext';
import { PatientFilterProvider } from './context/PatientFilterContext';
import { ThemeProvider } from './context/ThemeContext';
import { SideBarFilterProfessionalProvider } from './context/useFilterProfessional';
import { PatientProvider } from './context/usePatient';

function InnerApp() {
  const [configuracao, setConfiguracao] = useState('forms');
  const [patientFilter, setPatientFilter] = useState([]);
  const { openNotifications, setOpenNotifications } = useContext(AppContext);
  const [notSidebarAtendimento, setNotSidebarAtendimento] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [callOpen, setCallOpen] = useState(false);
  const [incomingCallOpen, setIncomingCallOpen] = useState(false);
  const [dadosCall, setDadosCall] = useState(null);
  const [dadosChat, setDadosChat] = useState(null);
  const [callStarted, setCallStarted] = useState(false);
  const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);

  useEffect(() => {
    isAuthenticated();

    axios.interceptors.response.use(
      (response) => {
        if (response?.data?.status_code && response?.data?.status_code === 401)
          toast.error(
            'Funcionalidade não permitida: ' +
              extractPermissionDescription(response.data.message),
          );

        return response;
      },
      (error) => {
        return error;
      },
    );
  }, []);

  function extractPermissionDescription(message) {
    let extract = message.substring(message.indexOf('#') + 1);
    return extract;
  }

  function isAuthenticated() {
    const token = localStorage.getItem('token');

    if (token === null) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <NotifyPopUp />
      <NotificationCentral
        open={openNotifications}
        back={(e) => setOpenNotifications(false)}
      />
      <ToastContainer />
      <FloatingButton
        setOpenModal={(e) => {
          setChatOpen(true);
        }}
      />
      <InviteInterconsultation />
      <ModalChat
        open={chatOpen}
        callStarted={callStarted}
        setCloseModal={(e) => {
          setChatOpen(false);
        }}
        openCall={(status, dadosCall, dadosChat) => {
          setDadosCall(dadosCall);
          setDadosChat(dadosChat);
          setChatOpen(false);
          setCallOpen(true);
          setCallStarted(true);
        }}
        incomingCall={(status, dadosCall, dadosChat) => {
          setDadosCall(dadosCall);
          setDadosChat(dadosChat);
          setChatOpen(false);
          setIncomingCallOpen(true);
        }}
      />
      <ModalCall
        open={callOpen}
        dadosChat={dadosChat}
        dadosCall={dadosCall}
        setOpenChat={(e) => {
          setCallOpen(false);
          setCallStarted(false);
          setChatOpen(true);
        }}
        setCloseModal={(e) => {
          setCallOpen(false);
          setCallStarted(false);
        }}
      />

      <NewHealthAttendanceProvider>
        <ModalCallInterconsultation />
      </NewHealthAttendanceProvider>

      <ModalIncomingCall
        open={incomingCallOpen}
        dadosChat={dadosChat}
        dadosCall={dadosCall}
        openCall={(status) => {
          setIncomingCallOpen(false);
          setCallOpen(true);
          setCallStarted(true);
        }}
        setCloseModal={(e) => {
          setIncomingCallOpen(false);
        }}
      />
      <Switch>
        <Route
          path="/configuracao-agenda"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <ConfiguracaoAgenda />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/atendimento-imediato"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin
                notSidebar={notSidebarAtendimento}
                sidebar={<AtendimentoSideBar />}
                onSideBar={(e) => console.log('onSidebar')}
              >
                <PatientCtxProvider>
                  <PatientProvider>
                    <AtendimentoContextProvider>
                      <AtendimentoImediatoContent
                        onSideBar={(e) => setNotSidebarAtendimento(!e)}
                      />
                    </AtendimentoContextProvider>
                  </PatientProvider>
                </PatientCtxProvider>
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/construtor"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin
                sidebar={<AtendimentoSideBar />}
                onSideBar={(e) => console.log('onSidebar')}
              >
                <AtendimentoContextProvider>
                  <Anamnese />
                </AtendimentoContextProvider>
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/meu-perfil"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <MeuPerfil />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/healthattendancetype"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <HealthAttendanceType />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/profissional"
          render={() =>
            isAuthenticated() ? (
              <SideBarFilterProfessionalProvider>
                <LayoutAdmin
                  onSideBar={(e) => console.log('onSidebar')}
                  active="profissionais"
                  sidebar={<SideBar />}
                >
                  <ProfissionalContent />
                </LayoutAdmin>
              </SideBarFilterProfessionalProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/pacientes"
          render={() =>
            isAuthenticated() ? (
              <PatientCtxProvider>
                <PatientFilterProvider>
                  <LayoutAdmin
                    onSideBar={(e) => setSideBarOpen(e)}
                    active="pacientes"
                    sidebar={
                      <SideBarPacientes
                        sideBarOpen={sideBarOpen}
                        filteredData={(data) => setPatientFilter(data)}
                      />
                    }
                  >
                    <PacienteContent
                      filteredData={{
                        details: patientFilter,
                        time: new Date(),
                      }}
                    />
                  </LayoutAdmin>
                </PatientFilterProvider>
              </PatientCtxProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/pacientes/detalhes/:id"
          render={() =>
            isAuthenticated() ? (
              <PatientCtxProvider>
                <PatientFilterProvider>
                  <LayoutAdmin
                    onSideBar={(e) => setSideBarOpen(e)}
                    active="pacientes"
                    sidebar={
                      <SideBarPacientes
                        sideBarOpen={sideBarOpen}
                        filteredData={(data) => setPatientFilter(data)}
                      />
                    }
                  >
                    <PacienteContent
                      filteredData={{
                        details: patientFilter,
                        time: new Date(),
                      }}
                    />
                  </LayoutAdmin>
                </PatientFilterProvider>
              </PatientCtxProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/pacientes/cadastro"
          render={() =>
            isAuthenticated() ? (
              <PatientCtxProvider>
                <PatientFilterProvider>
                  <LayoutAdmin active="pacientes" notSidebar>
                    <CreatePaciente listar={() => null} />
                  </LayoutAdmin>
                </PatientFilterProvider>
              </PatientCtxProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/configuracao"
          render={() =>
            isAuthenticated() ? (
              <ThemeProvider>
                <LayoutAdminContext
                  active="configuracoes"
                  sidebarOpen={true}
                  sidebar={
                    <SidebarConfiguracao
                      onChange={(newActive) => setConfiguracao(newActive)}
                      active={configuracao}
                    />
                  }
                >
                  <Configuracao active={configuracao} />
                </LayoutAdminContext>
              </ThemeProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/solicitacao"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin active="solicitacao" notSidebar>
                <PatientCtxProvider>
                  <PatientProvider>
                    <SolicitacoesContent />
                  </PatientProvider>
                </PatientCtxProvider>
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/agenda"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <Agenda />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/agenda-medico"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <AgendaMedico />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/faq-user"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <FaqUser />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/tela"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <TelaEmBranco />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/historico-acoes"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <HistoricoAcoes />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          path="/power-bi"
          render={() =>
            isAuthenticated() ? (
              <LayoutAdmin notSidebar>
                <PowerBI />
              </LayoutAdmin>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      </Switch>
    </>
  );
}

export default InnerApp;
