import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import DButton from './../../../../../shared/DButton';
import CardRemove from './CardRemove';
import { useContionals } from '../../../../../../context/ConditionalsContext';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 8,
  },
  cardBlue: {
    background: theme.palette.primary.light,
    marginBottom: 8,
  },
  cardContent: {
    paddingBottom: '5px !important',
  },
  contentRemove: {
    width: 267,
    height: 65,
    padding: '0px 24px',
  },
  titleRemove: {
    borderBottom: 'none',
  },
  buttonBack: {
    minWidth: 74,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
  },
  buttonConfirm: {
    minWidth: 145,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
  },
  textTitle: {
    cursor: 'pointer',
  },
  textTitleDisabled: {
    cursor: 'pointer',
    color: theme.palette.neutral.midgrey,
  },
  textTitleWhite: {
    cursor: 'pointer',
    color: 'white',
  },
  hrDisabled: {
    border: `1px solid ${theme.palette.neutral.midgrey} !important`,
  },
}));

function CardView(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = props.conditionalId === props.conditional.id ? 'blue' : 'white';
  const ctrDisabled =
    props.conditionalId === props.conditional.id ? false : true;
  const { pendingSave } = useContionals();

  useEffect(function () {});

  return (
    <>
      <Card
        classes={{ root: theme === 'blue' ? classes.cardBlue : classes.card }}
      >
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={7}>
              <Typography
                classes={{
                  root:
                    theme === 'white'
                      ? props.conditionalId && ctrDisabled
                        ? classes.textTitleDisabled
                        : classes.textTitle
                      : classes.textTitleWhite,
                }}
                variant="body1"
                className="text-regular text-st"
                onClick={(e) => {
                  if (!pendingSave) props.onCondition(true);
                  else toast.warn('Salve a condicional antes de prosseguir.');
                }}
              >
                {props.conditional.name}
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <DButton
                    disabled={
                      (props.conditionalId && ctrDisabled) ||
                      props.loading ||
                      theme === 'blue'
                    }
                    onClick={(e) => setOpen(true)}
                    className={`${theme === 'white' ? '' : 'btn-white'}`}
                    size="sm"
                    type="button"
                    variant="ghost"
                    icon={<DeleteIcon size="sm" />}
                  >
                    Excluir
                  </DButton>
                </Grid>
                <Grid item>
                  <DButton
                    disabled={
                      (props.conditionalId && ctrDisabled) || theme === 'blue'
                    }
                    onClick={(e) => props.onEdit(true)}
                    className={`${theme === 'white' ? '' : 'btn-white'}`}
                    size="sm"
                    type="button"
                    variant="ghost"
                    icon={<CreateIcon size="sm" />}
                  >
                    Editar
                  </DButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <hr
                style={{
                  backgroundColor: 'rgba(199, 199, 199, 1)',
                  border: 0,
                  height: '1px',
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <CardRemove
        open={open}
        conditionalsId={props.conditional.id}
        onDeleted={(e) => props.onDeleted(true)}
        onClose={(e) => setOpen(false)}
      />
    </>
  );
}

export default CardView;
