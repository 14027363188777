import { Grid } from '@material-ui/core';
import SimpleModal from '../../shared/Modal';

function ExamModal(props) {
  return (
    <SimpleModal fullPage open={props.open} onClose={(e) => props.onClose()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          item
          className="text-center"
          style={{ height: '100%', width: '100%' }}
        >
          <iframe width="100%" height="100%" noborder src={props.src}></iframe>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}

ExamModal.defaultProps = {
  open: false,
  src: 'https://dash.weni.ai',
  onClose: function () {
    console.log('onClose');
  },
};

export default ExamModal;
