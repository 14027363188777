import env from 'react-dotenv';
import axios from 'axios';

function newInvite(token, dados) {
  return axios
    .post(`${env.API_URL}/user/invite`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function testUploadInvite(token, file) {
  var body = new FormData();
  body.append('file', file);

  return axios
    .post(`${env.API_URL}/user/test-upload-invite`, body, {
      headers: {
        'x-domain': env.X_DOMAIN,
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function uploadInvite(token, file) {
  var body = new FormData();
  body.append('file', file);

  return axios
    .post(`${env.API_URL}/user/upload-invite`, body, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        'x-domain': env.X_DOMAIN,
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { newInvite, testUploadInvite, uploadInvite };
