import ReactDOM from 'react-dom';
import App from './App';
import ErrorHandling from './component/ErrorHandling';
import AppContext from './context/AppContext';
import ThemeContext from './context/useThemeContext';
import './index.css';

ReactDOM.render(
  <ThemeContext>
    <ErrorHandling>
      <AppContext>
        <App />
      </AppContext>
    </ErrorHandling>
  </ThemeContext>,
  document.getElementById('root'),
);
