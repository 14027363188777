import { useState, useEffect } from 'react';
import { fetchCurrentUserDetails } from '../../../../api/user';
import { Grid, Divider } from '@material-ui/core';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { allergysByPatient } from './../../../../api/healthAttendance';

function AlergiasView(props) {
  const { healthAttendance } = useNewHealthAttendance();

  const [foodAllergy, setFoodAllergy] = useState([]);
  const [medicineAllergy, setMedicineAllergy] = useState([]);

  useEffect(() => {
    fetchCurrentUserDetails(localStorage.getItem('token')).then((res) => {
      if (res.id && res.id === healthAttendance.user_id) {
        var _foodAllergy = healthAttendance.anamnese_pre_form
          .filter((pre_form) => pre_form.anamnese_pre_food_allergy.length > 0)
          .map((pre_form) =>
            pre_form.anamnese_pre_food_allergy.map(
              (food_allergy) => food_allergy.name,
            ),
          );
        setFoodAllergy(
          _foodAllergy.length > 0 ? _foodAllergy[0] : _foodAllergy,
        );
        var _mediciaAllergy = healthAttendance.anamnese_pre_form
          .filter(
            (pre_form) => pre_form.anamnese_pre_medicine_allergy.length > 0,
          )
          .map((pre_form) =>
            pre_form.anamnese_pre_medicine_allergy.map(
              (medicine_allergy) => medicine_allergy.name,
            ),
          );
        setMedicineAllergy(
          _mediciaAllergy.length > 0 ? _mediciaAllergy[0] : _mediciaAllergy,
        );
      } else {
        allergysByPatient(
          localStorage.getItem('token'),
          healthAttendance.patient_id,
        ).then((res) => {
          if (res.status) {
            setFoodAllergy(res.foods);
            setMedicineAllergy(res.medicines);
          }
        });
      }
    });
  }, []);

  return (
    <div className="mt_35_new_tab">
      <div className="main_div_tag_paciente_tab">
        <Grid item md={4}>
          <div className="fonte_paciente_tab">Alimentar</div>
          <div className="main_div_tag_paciente_tab mt10_new_tabs">
            {foodAllergy.map((food_allergy) => (
              <div className="tag_paciente_tab">{food_allergy}</div>
            ))}
            {foodAllergy.length === 0 && (
              <div className="tag_paciente_tab">Nenhuma</div>
            )}
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem className="divide_new_tab" />
        <Grid item md={4} className="alinha_centro">
          <div className="fonte_paciente_tab">Medicamentosa</div>
          <div className="main_div_tag_paciente_tab mt10_new_tabs justifica_centro">
            {medicineAllergy.map((medicine_allergy) => (
              <div className="tag_paciente_tab">{medicine_allergy}</div>
            ))}
            {medicineAllergy.length === 0 && (
              <div className="tag_paciente_tab">Nenhuma</div>
            )}
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem className="divide_new_tab" />
        <Grid item md={4} className="alinha_centro">
          <div className="fonte_paciente_tab">Outras</div>
          <div className="main_div_tag_paciente_tab mt10_new_tabs justifica_centro">
            <div className="tag_paciente_tab">Nenhuma</div>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default AlergiasView;
