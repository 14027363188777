import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import {
  mascaraNumero,
  mascaraTelefone,
} from '../../../../../../../helpers/formatacao';
import DAutoComplete from '../../../../../../shared/DAutoComplete';
import DInput from '../../../../../../shared/DInput';

const useStyles = makeStyles({
  gridDate: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  divDate: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    margin: 0,
    '&:first-child': {
      marginRight: '30px',
    },
  },
});

const EBPhone = ({
  id,
  label,
  name,
  placeholder,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleChange, handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();

  const [typePhone, setTypePhone] = useState('');
  const [numberPhone, setNumberPhone] = useState('');
  const [data, setData] = useState('');
  const [optionsTypePhone, setOptionsTypePhone] = useState('');

  useEffect(() => {
    setOptionsTypePhone(
      additional_data.options.map(function (item) {
        return { id: item.value, value: item.label };
      }),
    );
    const data = {
      value: {
        typePhone: typePhone,
        numberPhone: numberPhone,
      },
    };
    if (data.value.typePhone !== undefined) {
      setData(data.value);
      // handleInputSubmit({[id]: data.value})
    }
  }, [numberPhone, typePhone]);

  useEffect(() => {
    if (answer.length > 0) {
      setNumberPhone(answer[0].numberPhone);
    }
  }, []);

  const idTypePhone = answer.length ? answer[0].typePhone : '';

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: '1em', display: 'flex' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${additional_data.construct.showTitle ? label : ''}`,
            }}
          />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
            {is_required === 1 ? '*' : ''}
          </span>
        </Typography>
        <Box className={classes.gridDate}>
          <Box className={classes.divDate}>
            {additional_data.options.length > 0 && optionsTypePhone ? (
              <DAutoComplete
                id="select-form"
                label="Tipo de Telefone"
                name="typePhone"
                placeholder="Tipo de Telefone"
                labelColor="black"
                error={erro[0] ? erro[0].value : ''}
                helperText={erro[0] ? erro[0].value : ''}
                // readOnly={is_read_only === 1 ? true : false}
                required={is_required === 1 ? true : false}
                fullWidth={true}
                disabled={readOnly}
                defaultValue={
                  optionsTypePhone.filter((res) => res.id == idTypePhone)[0]
                }
                options={additional_data.options.map(function (item) {
                  return { id: item.value, value: item.label };
                })}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) =>
                  value && option.id === value.id
                }
                onChange={(event, newValue) => {
                  if (erro !== null && erro.length > 0) {
                    delete erro[0].value;
                  }
                  setTypePhone(newValue ? newValue.id : '');
                }}
              />
            ) : (
              'Não existe dados cadastrados'
            )}
          </Box>
          <Box className={classes.divDate}>
            <DInput
              id={id}
              label="Telefone"
              name="numberPhone"
              placeholder="Telefone"
              value={numberPhone}
              readOnly={readOnly}
              required={is_required === 1 ? true : false}
              error={erro[0] ? erro[0].value : ''}
              helperText={erro[0] ? erro[0].value : ''}
              type="text"
              fullWidth={true}
              positionIcon="end"
              inputProps={{ maxLength: 16 }}
              onChange={(e) => {
                if (erro !== null && erro.length > 0) {
                  delete erro[0].value;
                }

                if (e.target.value || e.target.value == '') {
                  let new_telefone = mascaraTelefone(e.target.value);
                  setNumberPhone(new_telefone);
                }
              }}
              onBlur={(e) => {
                if (data !== null) {
                  !readOnly
                    ? handleInputSubmit({ [id]: data })
                    : console.log('effect onBlur');
                }
              }}
            />
          </Box>
        </Box>
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBPhone;
