import { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import {
  SideBarFilterProfessionalContext,
  SideBarFilterProfessionalProvider,
} from '../../../../context/useFilterProfessional';
import { withStyles } from '@material-ui/styles';
import {
  filterProfessional,
  getProfissionalByFilter,
} from '../../../../api/user';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

const StyledOutlinedInput = withStyles((theme) => ({
  root: {
    background: theme.palette.background.snow,
    minWidth: 400,
    width: '100%',
  },
}))(OutlinedInput);

function ProfissionalFilter(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [search, setSearch] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCPF] = useState('');
  const [cns, setCNS] = useState('');

  const {
    profissionais,
    setProfissionais,
    profissionaisListForFilter,
    getProfessionalsByFilter,
    loadingProfissionais,
    setLoadingProfissionais,
    all,
    waiting,
    released,
    blocked,
  } = useContext(SideBarFilterProfessionalContext);

  const handleKeypress = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      handleSubmit();
    }
  };

  const handleSetData = (data) => {
    if (data.status) {
      if (Array.isArray(data.users) && data.users.length > 0) {
        setProfissionais(data.users);
        props.setPagination(data.paginate);
        setLoadingProfissionais(false);
      } else {
        setProfissionais([]);
        setLoadingProfissionais(false);
      }
    } else {
      setProfissionais([]);
      setLoadingProfissionais(false);
      toast.error(
        data.message || 'Erro ao buscar profissionais, tente novamente.',
      );
    }
  };

  const handleSubmit = () => {
    const regCPF = /[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/;
    const resultCPF = search.match(regCPF);

    const regCNS = /[0-9]{15}/g;
    const resultCNS = search.match(regCNS);

    if (resultCNS) {
      props.setActiveFilter({
        cns: search,
        cpf: '',
        name: '',
        active: [all || waiting, released, blocked],
      });
    } else if (resultCPF) {
      props.setActiveFilter({
        cpf: search,
        cns: '',
        name: '',
        active: [all || waiting, released, blocked],
      });
    } else {
      props.setActiveFilter({
        name: search,
        cpf: '',
        cns: '',
        active: [all || waiting, released, blocked],
      });
    }
  };

  const validationSearch = (search) => {
    // const regName = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{1,19}\b/gi;
    // const resultName = search.match(regName);

    const regCNS = /[0-9]{15}/g;
    const resultCNS = search.match(regCNS);

    const regCPF = /[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/;
    const resultCPF = search.match(regCPF);

    if (resultCPF) setCPF(search);
    else if (resultCNS) setCNS(search);
    else setName(search);
  };

  useEffect(() => {
    if (search || search === '') {
      validationSearch(search);

      if (search === '') {
        props.setActiveFilter(null);
      }
    }
  }, [search]);

  return (
    <>
      <FormControl variant="outlined" size="small">
        <StyledOutlinedInput
          id="filter-profissionais"
          type="text"
          placeholder="Buscar por nome, e-mail ou CPF"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyPress={handleKeypress}
          onBlur={handleSubmit}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start">
                <SearchIcon
                  style={{ color: theme.palette.primary.light }}
                  classes={{ root: classes.icon }}
                />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={0}
        />
      </FormControl>
    </>
  );
}

export default ProfissionalFilter;
