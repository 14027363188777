import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DSwitch from '../../../../../shared/DSwitch';

const useStyles = makeStyles({
  card_subtitle: {
    color: '#272727',
    fontSize: 14,
    margin: '0 0 16px',
  },
  edit_input: {
    backgroundColor: '#fff',
    width: '56px !important',
    marginRight: 8,
    '& input': {
      padding: 12,
    },
  },
});

function VerdadeiroFalsoComponent(props) {
  const [value, setValue] = useState(false);

  return (
    <Grid item>
      <Grid container justifyContent="center">
        <Grid item>
          <DSwitch
            checked={value}
            onChange={(e) => {
              let new_value = !value;
              setValue(new_value);
              props.onChange([new_value ? 'verdadeiro' : 'falso']);
            }}
            name="verdadeiro_falso"
          />
        </Grid>
        {props.showName && (
          <Grid item>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              {props.name}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

VerdadeiroFalsoComponent.defaultProps = {
  showName: false,
  name: null,
  onChange: function (value) {
    console.log(value);
  },
};

export default VerdadeiroFalsoComponent;
