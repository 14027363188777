import { useState, useContext } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { makeStyles } from '@material-ui/core/styles';
import { DownloadIcon } from '../../../../shared/Icon/Icon';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  sideBar: {
    minWidth: '20%',
    maxWidth: 228,
    marginLeft: '-20%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarOpen: {
    minWidth: '20%',
    maxWidth: 228,
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarButtonOpen: {
    left: '20%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  content: {
    minWidth: '80%',
    maxWidth: '80%',
    '@media(minWidth: 768)': {
      minWidth: '100%',
    },
  },
  contentFull: {
    minWidth: '100%',
  },
  footer: {
    width: '98%',
    background: 'transparent',
    padding: '15px 1%',
    position: 'fixed',
    bottom: 0,
  },

  downloadIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '10px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    fill: 'white',
  },
}));

function Header(props) {
  const classes = useStyles();

  const [sidebar, setSidebar] = useState(
    props.sidebarOpen ? props.sidebarOpen : false,
  );

  return (
    <>
      <Navbar active={props.active} open={(e) => setSidebar(true)} />
      <Container
        maxWidth={false}
        style={{ height: 'calc(100% - 72px)' }}
        disableGutters={true}
      >
        <Grid
          container
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <Grid
            item
            classes={{ root: sidebar ? classes.content : classes.contentFull }}
          >
            {props.children}
          </Grid>
        </Grid>
      </Container>
      <footer className={classes.footer}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              className="text-regular text-st text-link"
            >
              {props.enableDownload ? (
                <DownloadIcon
                  className={classes.downloadIcon}
                  onClick={(e) => props.download()}
                />
              ) : (
                ''
              )}
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default Header;
