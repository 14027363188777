import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  withStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Close } from '@material-ui/icons';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import DInput from '../../../shared/DInput';

import Popover from '@material-ui/core/Popover';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import SaveIcon from '@material-ui/icons/Save';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';

import {
  getPermissionsFromRoleGroupId,
  getProfileById,
  getProfilePermissionsById
} from '../../../../api/profiles';
import { IIsEdit } from '../interfaces/state.interface';
import { useStyles } from './styles';

interface IProps {
  open: boolean;
  permission: any[];
  documents: any;
  editData: IIsEdit;
  counterPermission: any[];
  registryDuplicaded: boolean;
  loading: boolean;
  token: string | null;
  error: boolean;
  created: boolean;
  editProfile: (data: any) => void;
  createProfile: (data: any) => void;
  onConfirmClose: () => void;
  onClose: () => void;
  setErrors: (setErrors: any) => void;
}

const SelectAllCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.action.selected,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default function SimpleDialog(props: IProps) {
  const classes = useStyles();
  const { onClose, open } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [permissionsRole, setPermissionsRole] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [totalPermissionsProfile, setTotalPermissionsProfile] = useState([]);
  const [showTotal, setShowTotal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [documentsName, setDocumentsName] = useState<string[]>([]);
  const [perfilName, setPerfilName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [nameRequired, setNameRequired] = useState(false);
  const [docRequired, setDocRequired] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [, setCtrlSubmit] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [role, setRole] = useState<{ id: number; name: string } | null>(null);
  const [expandMore, setExpandMore] = useState(false);
  const [expanded, setExpanded] = useState<boolean | string>(false);

  const getProfileByIdCallback = useCallback((editDataId) => {
    getProfileById(props.token, editDataId).then(
      (res) => {

        setSelectedDocuments([]);
        setDocumentsName([]);

        if (res.status) {
          setEditData(res);
          setPerfilName(res.profile.description);

          res.profileDocument.forEach((item: any) => {
            setSelectedDocuments((prev) => [
              ...prev,
              item.document_type.id,
            ]);
            setDocumentsName((prev) => [
              ...prev,
              item.document_type.description,
            ]);
          });
        }
      },
    );
  }, []);

  const getProfilePermissionsByIdCallback = useCallback((editDataId) => {
    getProfilePermissionsById(
      props.token,
      editDataId,
    ).then((res) => {
      if (res.profile[0].profile_permissions.length) {
        const permissionIds = res.profile[0].profile_permissions.map(
          (permission: any) => permission.permission_id,
        );
        setSelectedPermissions(permissionIds);
        setTotalPermissionsProfile(res.profilePermissionCounter);
        setIsLoading(false);
      }
      if (res.profile[0].profile_permissions.length === 0) {
        setIsLoading(false);
      }
    });
  }, [])

  useEffect(() => {
    if (props.editData && props.editData.status) {
      setShowTotal(true);

      setDuplicate(props.editData.duplicate);

      Promise.all([
        getProfileByIdCallback(props.editData.id),
        getProfilePermissionsByIdCallback(props.editData.id),
      ])

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    return () => {
      setEditData({}); // Cleanup // limpa após o componente ser desmontado
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData, props.counterPermission]);



  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setExpandMore(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setExpandMore(false);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  function validarCampos() {
    let nameRequired = false;
    let docRequired = false;
    let perrmissionsRequired = false;

    setIsLoading(true);

    if (
      perfilName.trim() === '' ||
      (duplicate && editData.profile.description === perfilName)
    ) {
      setIsLoading(false);
      nameRequired = true;
      setNameRequired(nameRequired);
    }

    if (selectedPermissions.length === 0) {
      perrmissionsRequired = true;
      toast.error('Selecione uma permissão.');
    }

    if (!nameRequired && !docRequired && !perrmissionsRequired) {
      if (props.editData.status) {
        props.editProfile([perfilName, selectedPermissions, selectedDocuments]);
      } else {
        props.createProfile([
          perfilName,
          selectedPermissions,
          selectedDocuments,
        ]);
      }
    }
  }

  function showSelected(roleId: number = 0, totalPermissions: number) {
    if(!expanded) return

    return totalPermissionsProfile[roleId] ? (
      <>
        <Box display="inline" className={classes.textHelperSelected}>
          ({totalPermissionsProfile[roleId]} de {totalPermissions} selecionados)
        </Box>
      </>
    ) : (
      ''
    );
  }

  function closeModal() {
    if (
      perfilName.length > 0 ||
      documentsName.length > 0 ||
      selectedPermissions.length > 0
    )
      props.onConfirmClose();
    else onClose();
  }

  const handleChangeRoleGroup = (panel: any, roleGroupId: number) => (_: any, isExpanded: boolean) => {

    setPermissionsRole([]);
    setExpanded(isExpanded ? panel : false);

    if (isExpanded) {
      setLoadingPermissions(true);
      
      getPermissionsFromRoleGroupId(
        props.token,
        roleGroupId,
      ).then((res) => {
        if (res.status) {
          setPermissionsRole(res.permissions);
          setRole(res.role);
          setLoadingPermissions(false);
        } else {
          setPermissionsRole([]);
          setRole(null);
          setLoadingPermissions(false);
        }
      });
    } else {
      setShowTotal(true);
    }
  };

  return isLoading ? (
    <>
      <Dialog
        aria-labelledby="profile-create-title"
        scroll="body"
        onClose={() => closeModal()}
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          id="profile-create-title"
          classes={{ root: classes.title }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="h2"
                className="text-mt text-regular text-primary"
              >
                {props.editData.status
                  ? duplicate
                    ? 'Duplicar'
                    : 'Editar'
                  : 'Criar'}{' '}
                perfil
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={() => {
                  closeModal();
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: 400 }}
          >
            <Grid item className="text-center">
              <CircularProgress
                className="text-success"
                style={{ fontSize: 64 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      <Dialog
        aria-labelledby="profile-create-title"
        scroll="body"
        onClose={() => closeModal()}
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          id="profile-create-title"
          classes={{ root: classes.title }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="h2"
                className="text-mt text-regular text-primary"
              >
                {props.editData.status
                  ? duplicate
                    ? 'Duplicar'
                    : 'Editar'
                  : 'Criar'}{' '}
                perfil
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={() => {
                  closeModal();
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <Grid item md={8} style={{ paddingRight: 16 }}>
              <DInput
                id="profileName"
                label="Nome do perfil"
                placeholder="Nome do perfil"
                error={nameRequired || props.registryDuplicaded}
                helperText={
                  nameRequired
                    ? !duplicate
                      ? 'Campo obrigatório'
                      : 'Utilize um nome diferente'
                    : props.registryDuplicaded
                      ? 'O nome já esta cadastrado no sistema'
                      : ''
                }
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (nameRequired) {
                    setNameRequired(false);
                  }

                  setPerfilName(e.target.value);
                }}
                value={perfilName}
              />
            </Grid>
            <Grid
              item
              md={4}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <DInput
                id="documents"
                label="Documentação que será exigida"
                readOnly={true}
                error={docRequired}
                helperText={docRequired ? 'Campo obrigatório' : ''}
                type="text"
                fullWidth={true}
                positionIcon="end"
                icon={expandMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                value={documentsName.length ? documentsName.join(', ') : ''}
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <ul className={classes.doc_popover}>
                  <>
                    {props.documents && props.documents.length ? (
                      <>
                        {props.documents.map(function (item: any) {
                          if (item.id >= 7) {
                            return (
                              <li className={classes.doc_popoverItem}>
                                <Checkbox
                                  defaultChecked={
                                    selectedDocuments.find(
                                      (doc) => doc === item.id,
                                    )
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    setShowTotal(false);
                                    if (docRequired) {
                                      setDocRequired(false);
                                    }
                                    var docExists = selectedDocuments.find(
                                      (doc) => doc === item.id,
                                    );

                                    if (docExists) {
                                      var index =
                                        selectedDocuments.indexOf(docExists);

                                      const spliceSelectedDocuments = [
                                        ...selectedDocuments,
                                      ];
                                      const spliceDocumentsName = [
                                        ...documentsName,
                                      ];

                                      spliceSelectedDocuments.splice(index, 1);
                                      spliceDocumentsName.splice(index, 1);

                                      setSelectedDocuments(
                                        spliceSelectedDocuments,
                                      );
                                      setDocumentsName(spliceDocumentsName);
                                    } else {
                                      setSelectedDocuments((prev) => [
                                        ...prev,
                                        item.id,
                                      ]);
                                      setDocumentsName((prev) => [
                                        ...prev,
                                        item.description,
                                      ]);
                                    }
                                  }}
                                />{' '}
                                {item.description}
                              </li>
                            );
                          }

                          return null
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                </ul>
              </Popover>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 32 }}>
            <Typography>
              Selecione as funcionalidades que o perfil terá acesso
            </Typography>

            {props.permission.map(function (permissionGroup: any, index: number) {
              return (
                <Accordion
                  key={index}
                  className={classes.accordion}
                  expanded={expanded === 'panel' + permissionGroup.id}
                  onChange={handleChangeRoleGroup(
                    'panel' + permissionGroup.id,
                    permissionGroup.id,
                  )}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.accordion_heading}>
                      {permissionGroup.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item md={12}>
                        {loadingPermissions ? (
                          <>
                            <Grid item md={12} style={{ textAlign: 'center' }}>
                              <CircularProgress
                                size={15}
                                color="inherit"
                                style={{ marginTop: 32 }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Accordion className={classes.subaccordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography
                                className={classes.subaccordion_heading}
                              >
                                {role && role.name}{' '}
                                {showTotal
                                  ? showSelected(
                                    role?.id,
                                    permissionsRole.length,
                                  )
                                  : ''}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item md={12}>
                                  <ul className={classes.doc_popover}>
                                    <li className={classes.doc_popoverItem}>
                                      <SelectAllCheckbox
                                        onClick={(e: ChangeEvent<HTMLInputElement>) => {
                                          setShowTotal(false);

                                          if (e.target.checked) {
                                            permissionsRole.forEach(
                                              function (rolePermission: { id: number; name: string; }) {
                                                const permissionExists =
                                                  selectedPermissions.find(
                                                    (doc) =>
                                                      doc === rolePermission.id,
                                                  );

                                                if (!permissionExists) {
                                                  setSelectedPermissions(
                                                    (prev) => [
                                                      ...prev,
                                                      rolePermission.id,
                                                    ],
                                                  );
                                                }
                                              },
                                            );
                                          } else {
                                            const splicePermissions = [
                                              ...selectedPermissions,
                                            ];

                                            permissionsRole.forEach(
                                              function (rolePermission: { id: number; name: string; }) {
                                                const permissionExists =
                                                  selectedPermissions.find(
                                                    (doc) =>
                                                      doc === rolePermission.id,
                                                  );

                                                if (permissionExists) {
                                                  const index =
                                                    splicePermissions.indexOf(
                                                      permissionExists,
                                                    );
                                                  splicePermissions.splice(
                                                    index,
                                                    1,
                                                  );
                                                  setSelectedPermissions(
                                                    splicePermissions,
                                                  );
                                                }
                                              },
                                            );

                                            setSelectedPermissions(
                                              splicePermissions,
                                            );
                                          }
                                        }}
                                      />
                                      Selecionar tudo
                                    </li>
                                  </ul>
                                </Grid>
                                {permissionsRole.map(function (rolePermission: { id: number; name: string; }) {
                                  return (
                                    <Grid key={rolePermission.id} item md={12}>
                                      <ul className={classes.doc_popover}>
                                        <li className={classes.doc_popoverItem}>
                                          <Checkbox
                                            defaultChecked={
                                              selectedPermissions.find(
                                                (doc) =>
                                                  doc === rolePermission.id,
                                              )
                                                ? true
                                                : false
                                            }
                                            checked={
                                              selectedPermissions.find(
                                                (doc) =>
                                                  doc === rolePermission.id,
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              setShowTotal(false);
                                              const index = selectedPermissions.indexOf(rolePermission.id);
                                              if (index === -1) {
                                                setSelectedPermissions(
                                                  (prev) => [...prev, rolePermission.id],
                                                );
                                              } else {
                                                setSelectedPermissions(
                                                  (prev) =>
                                                    prev.filter(
                                                      (permission, i) =>
                                                        i !== index,
                                                    ),
                                                );
                                              }
                                            }}
                                          />{' '}
                                          {`${rolePermission.name}`}
                                        </li>
                                      </ul>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.footer }}>
          <Grid
            container
            spacing={1}
            className="tema-light"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                className={classes.backButton}
                onClick={() => {
                  closeModal();
                }}
              >
                Voltar
              </Button>
              <Button
                size="small"
                startIcon={<SaveIcon style={{ fontSize: 20 }} />}
                className={classes.saveButton}
                onClick={() => {
                  if (!props.loading) {
                    validarCampos();
                    setCtrlSubmit(true);
                  }
                }}
              >
                {props.loading ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  'Salvar'
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
