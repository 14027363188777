//External library
import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import env from 'react-dotenv';
import ReCAPTCHA from 'react-google-recaptcha';

//API
import {
  authId as auth,
  recoverPassword,
  resendCode,
  verificarCodigo,
} from '../../api/auth';
import {
  getDefaultPermission,
  fetchCurrentUserDetails,
  getMeLogin,
} from '../../api/user';

//Assets
import LogoDuosystemSvg from '../../assets/img/LogoDuosystem.svg';
import MedicineSvg from '../../assets/img/undraw_medicine_b1ol 1.svg';

//Components
import FooterLogin from '../shared/FooterLogin';
import FormLogin from './components/FormLogin';
import FormTwoFactor from './components/FormTwoFactor';
import LogoTheme from './components/LogoTheme';
import MdTermUse from './components/MdTermUse';
import FormRecoverContent from './components/FormRecoverContent';

//Styles
import { Content, ContainerLogin, Wrapper, styles } from './style';
import './style.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: 0,
      loggingIn: false,
      resending: false,
      loginError: false,
      sendingCode: false,
      codeError: false,
      requestingNewPassword: false,
      newPasswordRequestSent: false,
      loginAttempts: 0,
      termsOfUse: false,
      openTermsOfUseModal: false,
      data: '',
      signedTerms: false,
      isChecked: false,
      defaultRoute: '/meu-perfil',
    };
  }

  clearStorageExcept(keysToKeep) {
    const itemsToKeep = keysToKeep.map((key) => {
      return { key: key, value: localStorage.getItem(key) };
    });

    const validItemsToKeep = itemsToKeep.filter((item) => item.value !== null);

    localStorage.clear();

    validItemsToKeep.forEach((item) => {
      localStorage.setItem(item.key, item.value);
    });
  }

  componentDidMount() {
    this.clearStorageExcept(['theme', 'user_dominios']);
  }

  _setUserDetailsInLocalStorage(res) {
    localStorage.setItem('old_token', localStorage.getItem('token'));
    localStorage.setItem('token', res.token);
    localStorage.setItem('uid', res.details.uid);
    localStorage.setItem('scope', res.details.scope);
    localStorage.setItem('exp', res.details.exp);
    localStorage.setItem('email', res.details.email);
  }

  executeLogin(dataRequest) {
    this.setState({ ...this.state, loggingIn: true });

    auth(dataRequest)
      .then((res) => {
        if (res.status === false && res.error) {
          toast.error(
            res.error.message
              ? res.error.message
              : 'Erro ao tentar efetuar login.',
          );

          this.setState({
            ...this.state,
            loggingIn: false,
            loginAttempts: res.details !== undefined ? res.details.attempts : 0,
            loginError: res.error,
          });

          this.setState({ ...this.state, loggingIn: false });

          return;
        }

        if (res.token) {
          this._setUserDetailsInLocalStorage(res);
          if (
            res.details.scope === 'authenticated' ||
            res.details.scope === 'acl'
          ) {
            getMeLogin(localStorage.getItem('token')).then((res) => {
              if (!res.term_use_verified) {
                this.setState({
                  ...this.state,
                  openTermsOfUseModal: true,
                  data: res,
                  signedTerms: false,
                });
              } else {
                getDefaultPermission(localStorage.getItem('token')).then(
                  (res) => {
                    if (res.status) {
                      localStorage.setItem('route_default', res.route_default);
                      this.setState({
                        ...this.state,
                        loggingIn: false,
                        actions: 4,
                        defaultRoute: res.route_default,
                      });
                    } else {
                      this.setState({
                        ...this.state,
                        loggingIn: false,
                        actions: 4,
                      });
                    }
                  },
                );
              }
            });
          } else this.setState({ ...this.state, loggingIn: false, actions: 1 });
        }
      })
      .catch((err) => {
        toast.error('Erro ao tentar efetuar login.');
        this.setState({ ...this.state, loggingIn: false });

        console.log(err);
      });
  }

  resendCode(type) {
    this.setState({ ...this.state, resending: true });
    resendCode(type).then((res) => {
      this.setState({
        ...this.state,
        resending: false,
        codeError: false,
        loginError: null,
      });
    });
  }

  verifyCode(dataRequest) {
    this.setState({ ...this.state, sendingCode: true });
    verificarCodigo(dataRequest).then((res) => {
      if (res.error !== undefined) {
        this.setState({ ...this.state, sendingCode: false, codeError: true });
      } else {
        this._setUserDetailsInLocalStorage(res);

        fetchCurrentUserDetails(localStorage.getItem('token'))
          .then((res) => {
            if (res.term_use_verified)
              this.setState({
                ...this.state,
                sendingCode: false,
                codeError: true,
                actions: 3,
              });
            else
              this.setState({
                ...this.state,
                openTermsOfUseModal: true,
                data: res,
                signedTerms: false,
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  recoverPassword(dataRequest) {
    recoverPassword(dataRequest).then((res) => {
      if (!res.error) {
        this.setState({ ...this.state, requestingNewPassword: true });
        this.setState({
          ...this.state,
          requestingNewPassword: false,
          newPasswordRequestSent: true,
        });
      } else {
        toast.error(
          res.error.message ? res.error.message : 'Usuário não encontrado.',
        );
      }
    });
  }

  voltar() {
    this.setState({
      ...this.state,
      actions: 0,
      loginAttempts: 0,
      loginError: false,
      newPasswordRequestSent: false,
    });
  }

  getAction(props) {
    const actionComponent = [
      <FormLogin
        redefinir={(e) => this.setState({ ...this.state, actions: 2 })}
        loading={this.state.loggingIn}
        tentativas={this.state.loginAttempts}
        error={this.state.loginError}
        login={(dataRequest) => {
          props.recaptchaRef.current.execute();
          props.recaptchaRef.current.reset();
          this.executeLogin(dataRequest);
        }}
      />,
      <>
        {!this.state.openTermsOfUseModal ? (
          <>
            <FormTwoFactor
              loading={this.state.sendingCode}
              error={this.state.codeError}
              resending={this.state.resending}
              reenviar={(dataRequest) => {
                props.recaptchaRef.current.execute();
                props.recaptchaRef.current.reset();
                this.resendCode(dataRequest);
              }}
              verificar={(dataRequest) => {
                props.recaptchaRef.current.execute();
                props.recaptchaRef.current.reset();
                this.verifyCode(dataRequest);
              }}
              voltar={this.voltar.bind(this)}
            />
          </>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: 300 }}
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        )}
      </>,
      <FormRecoverContent
        onCode={(e) => {
          this.setState({
            ...this.state,
            actions: 0,
            loginError: false,
            codeError: false,
          });
        }}
      />,
      <Redirect to="/profissional" />,
      <Redirect to={this.state.defaultRoute} />,
    ];

    return actionComponent[this.state.actions];
  }

  render() {
    const { classes } = this.props;
    this.recaptchaRef = React.createRef();

    return (
      <Wrapper className="bg-white container-login">
        <ToastContainer />
        <ContainerLogin className="h-100">
          <Content item md={6} xs={12}>
            <div style={{ marginBottom: '73px' }}>
              <LogoTheme />
            </div>

            <img
              className={classes.showMedicalGroup}
              alt="médicos"
              src={MedicineSvg}
              onClick={(e) => this.setState({ ...this.state, actions: 0 })}
            />

            <Container
              className={classes.showDuoDoctorLogo}
              style={{
                flexDirection: 'row',
                marginTop: 107,
                width: '70%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img alt="Desenvolvido por DuoSystem" src={LogoDuosystemSvg} />
            </Container>
          </Content>
          <Grid item xs={12} md={6}>
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {this.getAction({ recaptchaRef: this.recaptchaRef })}
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                sitekey={env.SITE_KEY}
              />
            </Grid>
          </Grid>
        </ContainerLogin>
        <MdTermUse
          open={this.state.openTermsOfUseModal}
          onClose={(e) => {
            if (localStorage.getItem('old_token')) {
              localStorage.setItem('token', localStorage.getItem('old_token'));
            }
            this.setState({
              ...this.state,
              openTermsOfUseModal: false,
              sendingCode: false,
              actions: 0,
              loggingIn: false,
            });
          }}
          value={this.state.data}
          signedTerms={(e) => {
            getDefaultPermission(localStorage.getItem('token')).then((res) => {
              if (res.status) {
                localStorage.setItem('route_default', res.route_default);
                this.setState({
                  ...this.state,
                  loggingIn: false,
                  actions: 4,
                  defaultRoute: res.route_default,
                  signedTerms: true,
                  openTermsOfUseModal: false,
                  sendingCode: false,
                });
              } else {
                this.setState({
                  ...this.state,
                  loggingIn: false,
                  actions: 4,
                  signedTerms: true,
                  openTermsOfUseModal: false,
                  sendingCode: false,
                });
              }
            });
          }}
        ></MdTermUse>
        <FooterLogin
          modal={this.state.termsOfUse}
          onOpenModal={(e) =>
            this.setState({ ...this.state, termsOfUse: true })
          }
          onCloseModal={(e) =>
            this.setState({ ...this.state, termsOfUse: false })
          }
        />
      </Wrapper>
    );
  }
}

export default withStyles(styles)(Login);
