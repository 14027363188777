import React from 'react';
import '../index.scss';
import { Button, Grid } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@mui/icons-material/Print';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReactQuill from 'react-quill';
import DDropzone from '../../DDropzone';
import DButton from '../../DButton';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import HistoryIcon from '@mui/icons-material/History';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import TabelaVacinas from '../../NovasTabelas/TabelaVacinas';
import TabelaResultadoExames from '../../NovasTabelas/TabelaResultadoExames';
import TabelaExamesLaboratoriais from '../../NovasTabelas/TabelaExamesLaboratoriais';
import TabelaExamesEnviados from '../../NovasTabelas/TabelaExamesEnviados';
import TabelaGestacao from '../../NovasTabelas/TabelaGestacao';
import ModalVacinacao from './Components/ModalVacinacao';
import ModalBiomedida from './Components/ModalBiomedida';
import ModalLaudo from './Components/ModalLaudo';
import ModalAcompanhamento from './Components/ModalAcompanhamento';
import ModalResultado from './Components/ModalResultado';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import Biomedidas from './../../../shared/Biomedidas/Biomedidas';
import NovaBiomedida from './../../../shared/NovaBiomedida/NovaBiomedida';
import { fetchCurrentUserDetails } from './../../../../api/user';
import HeaderCards from './Components/HeaderCards/HeaderCards';

export default function VisualizadorClinNewTab(props) {
  const { healthAttendance, setHealthAttendance, patient } =
    useNewHealthAttendance();

  const [openModal, setOpenModal] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [updateListBiomedidas, setUpdateListBiomedidas] = React.useState(false);
  const [updateBiomedida, setUpdateBiomedida] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    fetchCurrentUserDetails(localStorage.getItem('token')).then((response) => {
      if (response.token_details && response.token_details.acl)
        setProfile(response.token_details.acl.profile_id);
    });
  }, []);
  console.log(healthAttendance.patient.biological_sex);
  return (
    <>
      {healthAttendance && (
        <HeaderCards healthAttendance={healthAttendance.id} />
      )}
      <div className="fundo_new_tab" style={{ marginTop: 16 }}>
        <div className="main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Biomedidas e exames</div>
          <div className="flex_end_new_tab">
            {/* <Button className="botao_editar_new_tab" startIcon={<PrintIcon />}>
              Imprimir
            </Button> */}
            <Button
              disabled={!profile}
              onClick={(e) => setOpenModal(true)}
              className="botao_editar_new_tab  text-primary"
              startIcon={<AddCircleIcon />}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="mt_35_new_tab main_flex_box_bio_exames">
          {openModal && healthAttendance && (
            <NovaBiomedida
              profile={profile}
              biomedida={updateBiomedida}
              healthAttendanceId={healthAttendance.id}
              open={openModal}
              onSaved={(e) => {
                setUpdateBiomedida(null);
                setUpdateListBiomedidas(true);
                setOpenModal(false);
              }}
              onClose={(e) => {
                setOpenModal(false);
              }}
            />
          )}
          {healthAttendance && (
            <Biomedidas
              updateList={updateListBiomedidas}
              onUpdated={(e) => setUpdateListBiomedidas(false)}
              healthAttendanceId={healthAttendance.id}
              patientId={patient ? patient.id : null}
              onEdit={(biomedida) => {
                setUpdateBiomedida(biomedida);
                setOpenModal(true);
              }}
            />
          )}
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Vacinas</div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaVacinas />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">
            Resultados de exames de imagens anteriores
          </div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaResultadoExames />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Exames laboratoriais</div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaExamesLaboratoriais />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">
            Exames enviados pelo paciente
          </div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaExamesEnviados />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        {healthAttendance.patient.biological_sex === 'Feminino' ? (
          <>
            <div className="mt_35_new_tab main_flex_new_tab">
              <div className="fonte_new_tab text-primary">
                Acompanhamento de gestação
              </div>
            </div>
            <div className="mt_35_new_tab">
              <TabelaGestacao />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
