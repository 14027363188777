import { Component } from 'react';
import { Redirect } from 'react-router';

import SolicitacoesList from './components/SolicitacoesList/SolicitacoesList';
import { Header } from './components/SolicitacoesList/components/Header';

import SolicitacoesCreate from './components/SolicitacoesCreate/SolicitacoesCreate';
import { HeaderCreate } from './components/SolicitacoesCreate/components/HeaderCreate';

import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { userDominios } from '../../api/dominios';
import { getAllUnidades } from '../../api/unidades';
import { fetchCurrentUserDetails } from '../../api/user';
import { PatientContext } from '../../context/usePatient';
import SolicitacoesPatient from './components/SolicitacoesPatient/SolicitacoesPatient';
import { HeaderPatient } from './components/SolicitacoesPatient/components/HeaderPatient';

import { AnamneseFormFilterProvider } from './../../context/AnamneseFormFilterContext';

import ContextWrapper from 'component/shared/CalendarMonth/context/ContextWrapper';
import { ConfiguracaoAgendaProvider } from 'context/ConfiguracaoAgendaContext';
import { PreHealthAttendanceProvider } from 'context/PreHealthAttendanceContext';
import { withRouter } from 'react-router';
import Agendamento from './components/Agendamento/Agendamento';

class Solicitacoes extends Component {
  static contextType = PatientContext;

  constructor(props) {
    super(props);

    this.state = {
      isList: true,
      isCadastro: false,
      isDetail: false,
      isAgendamento: false,
      login: true,
      checkCalendar: false,
      unidadesRef: [],
      unidadesVinculadas: [],
      userDominios: {},
      historyAttendance: false, // True: em andamento, False: Histórico
      componentLoaded: false,
      loadingEvent: false,
      pid: true,
      reload: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { idPatient, setIdPatient } = this.context;

    this.handleRedirectProps(this.props);

    fetchCurrentUserDetails(localStorage.getItem('token'))
      .then((res) =>
        !res.id ? this.setState({ ...this.state, login: false }) : '',
      )
      .catch(console.error);

    const promise1 = await userDominios().then((res) => {
      this.setState({ ...this.state, userDominios: res });
    });

    const promise2 = await getAllUnidades(localStorage.getItem('token')).then(
      (res) => {
        var unidadesRef = [];
        var unidadesVinculadas = [];

        res.length &&
          res.map((item) => {
            if (item.below_mother_medical_unit == 'no') {
              unidadesRef.push(item);
              this.setState({ ...this.state, unidadesRef: unidadesRef });
            } else {
              unidadesVinculadas.push(item);
              this.setState({
                ...this.state,
                unidadesVinculadas: unidadesVinculadas,
              });
            }
          });
      },
    );

    Promise.all([promise1, promise2])
      .catch(console.log)
      .finally(() => {
        this.setState({
          ...this.state,
          isLoading: false,
          componentLoaded: true,
        });
      });

    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('pid');
    if (pid && !idPatient) {
      setIdPatient(pid);
      this.setState({
        ...this.state,
        isList: false,
        isCadastro: false,
        isDetail: true,
      });
    } else {
      this.setState({ ...this.state, pid: false });
    }
  }

  handleRedirectProps(props) {
    if (props.location && props.location.state) {
      this.setState({ ...this.state, ...props.location.state });
    }
  }

  render() {
    return (
      <>
        {!this.state.login ? <Redirect to="/login" /> : ''}

        {this.state.reload ? <Redirect to="/solicitacoes" /> : ''}

        {this.state.isLoading ? (
          <Backdrop
            style={{ zIndex: 3000, color: 'white', textAlign: 'center' }}
            open={this.state.isLoading}
          >
            <Grid container>
              <Grid item md={12}>
                <CircularProgress color="inherit" />
              </Grid>
              <Grid item md={12}>
                <Typography variant="body1" className="text-regular text-st">
                  Carregando...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
        ) : null}

        <AnamneseFormFilterProvider>
          <PreHealthAttendanceProvider>
            {this.state.loadingEvent && (
              <Backdrop style={{ zIndex: 3000, color: '#FFF' }} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            {this.state.isList && !this.state.pid ? (
              <>
                {!this.state.componentLoaded && (
                  <Backdrop style={{ zIndex: 3000, color: '#FFF' }} open={true}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}

                <Header
                  cadastrar={(e) =>
                    this.setState({
                      ...this.state,
                      isList: false,
                      isCadastro: true,
                      isDetail: false,
                    })
                  }
                  openCalendar={(value) => {
                    this.setState({ ...this.state, checkCalendar: value });
                  }}
                  checkedCalendar={this.state.checkCalendar}
                  historyAttendance={this.state.historyAttendance}
                  setHistoryAttendance={(x) =>
                    this.setState({ ...this.state, historyAttendance: x })
                  }
                />

                <SolicitacoesList
                  checkedCalendar={this.state.checkCalendar}
                  historyAttendance={this.state.historyAttendance}
                  setHistoryAttendance={(x) =>
                    this.setState({ ...this.state, historyAttendance: x })
                  }
                  unidadesRef={this.state.unidadesRef}
                  unidadesVinculadas={this.state.unidadesVinculadas}
                  userDominios={this.state.userDominios}
                />
              </>
            ) : (
              ''
            )}

            {this.state.isCadastro ? (
              <>
                <HeaderCreate
                  listar={(e) => {
                    this.setState({
                      ...this.state,
                      isList: true,
                      isCadastro: false,
                      isDetail: false,
                      pid: false,
                    });
                  }}
                />
                <SolicitacoesCreate
                  verdetalhe={(e) =>
                    this.setState({
                      ...this.state,
                      isList: false,
                      isCadastro: false,
                      isDetail: true,
                    })
                  }
                />
              </>
            ) : (
              ''
            )}

            {this.state.isDetail ? (
              <>
                <HeaderPatient
                  cadastrar={(e) =>
                    this.setState({
                      ...this.state,
                      isList: false,
                      isCadastro: true,
                      isDetail: false,
                      isAgendamento: false,
                    })
                  }
                />
                <SolicitacoesPatient
                  onHealthAttendance={(e) => {
                    this.setState({
                      ...this.state,
                      isDetail: false,
                      isList: true,
                      isCadastro: false,
                      checkCalendar: false,
                      historyAttendance: false,
                      pid: false,
                    });
                  }}
                  agendar={(e, data, anamnesePreFormFiles) => {
                    var dataAnamneseForm = data;
                    var patientFile = anamnesePreFormFiles;

                    this.setState({
                      ...this.state,
                      isList: false,
                      isCadastro: false,
                      isDetail: false,
                      isAgendamento: true,
                      dataAnamneseForm: dataAnamneseForm,
                      patientFile: patientFile,
                    });
                  }}
                />
              </>
            ) : (
              ''
            )}

            {this.state.isAgendamento && this.state.dataAnamneseForm ? (
              <>
                <ConfiguracaoAgendaProvider>
                  <ContextWrapper>
                    <Agendamento
                      onClose={(e) =>
                        this.setState({
                          ...this.state,
                          isList: false,
                          isCadastro: false,
                          isDetail: true,
                          isAgendamento: false,
                        })
                      }
                      receiveDataAnamnesePreForm={this.state.dataAnamneseForm}
                      receivePatientFile={this.state.patientFile}
                      handleMenuItem={(e) =>
                        this.setState({
                          ...this.state,
                          isDetail: false,
                          isList: true,
                          isCadastro: false,
                          isAgendamento: false,
                          checkCalendar: false,
                          historyAttendance: false,
                          pid: false,
                        })
                      }
                    />
                  </ContextWrapper>
                </ConfiguracaoAgendaProvider>
              </>
            ) : (
              ''
            )}
          </PreHealthAttendanceProvider>
        </AnamneseFormFilterProvider>
      </>
    );
  }
}

export default withRouter(Solicitacoes);
