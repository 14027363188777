import axios from 'axios';
import env from 'react-dotenv';

export function getCitiesByStateId(state_id) {
  return axios.get(`${env.API_URL}/city?state_id=${state_id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
    },
  });
}
