import { Grid, Divider } from '@material-ui/core';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import PatientLineCare from './PatientLineCare/PatientLineCare';

function LineCareEdit(props) {
  const { healthAttendance, patient } = useNewHealthAttendance();

  return (
    <>
      <Grid item md={12}>
        {/* <div className="fonte_paciente_tab">Linha de Cuidado</div> */}
        {patient && (
          <PatientLineCare
            saveClose
            onClose={(e) => {
              props.close(true);
            }}
            paciente={patient}
          />
        )}
      </Grid>
    </>
  );
}

export default LineCareEdit;
