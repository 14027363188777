import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { OutlinedInput } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DButton from '../../shared/DButton';
import LineCares from '../../../api/lineCare';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import assumedIcon from '../../../assets/img-icon/assumedIcon.svg';
import finishedIcon from '../../../assets/img-icon/finishedIcon.svg';
import inactiveIcon from '../../../assets/img-icon/inactiveIcon.svg';
import openIcon from '../../../assets/img-icon/openIcon.svg';
import filterIcon from '../../../assets/img-icon/filterIcon.svg';
import { AppContext } from '../../../context/AppContext';
import { AtendimentoContext } from '../../../context/AtendimentoContext';
import { useEffect } from 'react';
import { mascaraData } from '../../../helpers/formatacao';
import { checarData } from '../../../helpers/validacoes';
import { toast } from 'react-toastify';
import { Alert, AlertTitle } from '@material-ui/lab';

const BlackTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    minWidth: 220,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    margin: '0 0 40px',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#4a4ae9',
    margin: '0 0 11px',
  },
  formControl: {
    width: '100%',
    minWidth: 220,
    height: 'calc(100vh - 310px)',
    overflow: 'auto',
    fontSize: 14,
  },
  group: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    '& strong': {
      fontWeight: 600,
      marginLeft: 4,
    },
  },
  inputRoot: {
    padding: 8,
    width: '45%',
    background: '#fff',
  },
  inputRoot2: {
    padding: 8,
    width: '100%',
    background: '#fff',
  },
  inputInput: {
    padding: 0,
  },
  footer: {
    height: 80,
    borderTop: '1px solid #c7c7c7',
    // position: "absolute",
    flexWrap: 'nowrap',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function AtendimentoSideBar() {
  const classes = useStyles();
  const [periodoDe, setPeriodoDe] = useState('');
  const [periodoAte, setPeriodoAte] = useState('');
  const [validate, setValidate] = useState(null);
  const {
    AttendanceFilter,
    setAttendanceFilter,
    attendancesList,
    totais,
    applyFilter,
    setApplyFilter,
  } = useContext(AppContext);
  const [attendancesCount, setAttendancesCount] = useState({
    all: 0,
    all_board: 0,
    assumed: 0,
    open: 0,
    finished: 0,
    low: 0,
    moderate: 0,
    serious: 0,
    not_indicative: 0,
  });

  const [state, setState] = useState({
    todos: true,
    assumido: false,
    aberto: false,
    finalizado: false,
    linecare: null,
  });

  const [lineCares, setLineCares] = useState([]);

  const handleChange = (event) => {
    setAttendanceFilter((prevState) => {
      return {
        ...prevState,
        status: {
          ...prevState.status,
          [event.target.name]: event.target.checked,
        },
      };
    });

    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setAttendanceFilter({
      status: {
        todos: false,
        assumido: false,
        aberto: false,
        finalizado: false,
      },
      quadro: {
        todos: false,
        leve: false,
        moderado: false,
        grave: false,
        nao_indicativo: false,
      },
      linhaDeCuidado: {},
      periodo: {
        from: null,
        to: null,
      },
      search: '',
    });
    // console.log('useEffect');
  }, []);

  const handleChangeStatus = (event) => {
    setApplyFilter(false);
    setAttendanceFilter((prevState) => {
      if (event.target.name == 'todos') {
        if (prevState.status.todos) {
          return {
            ...prevState,
            status: {
              assumido: false,
              aberto: false,
              finalizado: false,
              todos: false,
            },
          };
        }

        return {
          ...prevState,
          status: {
            todos: true,
            assumido: true,
            aberto: true,
            finalizado: true,
          },
        };
      }

      return {
        ...prevState,
        status: {
          ...prevState.status,
          todos: false,
          [event.target.name]: event.target.checked,
        },
        quadro: {
          ...prevState.quadro,
          todos: false,
        },
      };
    });

    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeQuadro = (event) => {
    setApplyFilter(false);
    setAttendanceFilter((prevState) => {
      if (event.target.name == 'todos') {
        if (prevState.quadro.todos) {
          return {
            ...prevState,
            status: {
              assumido: false,
              aberto: false,
              finalizado: false,
              todos: false,
            },
            quadro: {
              todos: false,
              leve: false,
              moderado: false,
              grave: false,
              nao_indicativo: false,
            },
          };
        }

        return {
          ...prevState,
          quadro: {
            todos: true,
            leve: true,
            moderado: true,
            grave: true,
            nao_indicativo: true,
          },
        };
      }

      return {
        ...prevState,
        quadro: {
          ...prevState.quadro,
          todos: false,
          [event.target.name]: event.target.checked,
        },
      };
    });

    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeLinecare = (event, lineCareId) => {
    setApplyFilter(false);
    setAttendanceFilter((prevState) => {
      return {
        ...prevState,
        linhaDeCuidado: {
          ...prevState.linhaDeCuidado,
          todos: false,
          [lineCareId]: event.target.checked,
        },
      };
    });
  };

  function validaData(date) {
    const [day1, month1, year1] = date.split('/');

    if (!checarData(day1, month1, year1)) {
      setValidate(['invalid_date']);
    } else {
      setValidate(validate.filter((item) => item !== 'invalid_date'));
    }
  }

  const aplicarFiltroData = () => {
    const [day1, month1, year1] = periodoDe.split('/');
    const [day2, month2, year2] = periodoAte.split('/');

    if (!checarData(day1, month1, year1)) {
      setValidate(['invalid_date']);
      return false;
    }

    if (!checarData(day2, month2, year2)) {
      setValidate(['invalid_date']);
      return false;
    }

    if (periodoAte && periodoDe) {
      const date1 = new Date(year1, month1 - 1, day1);
      const date2 = new Date(year2, month2 - 1, day2);

      if (date1 > date2) {
        setValidate(['major_end_date']);
        setPeriodoAte('');
        return false;
      } else {
        setValidate(validate.filter((item) => item !== 'major_end_date'));
      }
    }

    setAttendanceFilter((prevState) => {
      return {
        ...prevState,
        periodo: {
          from: periodoDe,
          to: periodoAte,
        },
      };
    });
    setApplyFilter(true);
  };

  useEffect(() => {
    LineCares.getAllLineCare()
      .then((data) => {
        if (data && data.status) {
          setLineCares(data.lineCare);
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (Array.isArray(attendancesList) && attendancesList.length > 0) {
      var _attendances = attendancesList.map((x) => {
        return {
          ...x,
          rating: x.rating_health_attendance[0] || null,
        };
      });

      const _abertos = _attendances.filter(
        (x) => x.health_attendance_status_id == 1,
      ).length;
      const _assumidos = _attendances.filter(
        (x) => x.health_attendance_status_id == 2,
      ).length;
      const _finalizados =
        _attendances.filter((x) => x.health_attendance_status_id == 3).length +
        _attendances.filter((x) => x.health_attendance_status_id == 4).length;

      const _leve = _attendances.filter(
        (x) => x.rating && x.rating.rating_health.id == 2,
      ).length;
      const _moderado = _attendances.filter(
        (x) => x.rating && x.rating.rating_health.id == 3,
      ).length;
      const _grave = _attendances.filter(
        (x) => x.rating && x.rating.rating_health.id == 1,
      ).length;
      const _nao_indicativo = _attendances.filter(
        (x) => x.rating && x.rating.rating_health.id == 4,
      ).length;

      setAttendancesCount({
        ...attendancesCount,
        all_board: _leve + _moderado + _grave + _nao_indicativo,
        all: attendancesList.length,
        assumed: _assumidos,
        open: _abertos,
        finished: _finalizados,
        low: _leve,
        moderate: _moderado,
        serious: _grave,
        not_indicative: _nao_indicativo,
      });
    }
  }, [attendancesList]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{ background: '#F4F4F4', paddingTop: 15, marginTop: '-15px' }}
    >
      <Grid item md={10}>
        <div className={classes.root}>
          <h4 className={classes.title}>Filtro</h4>
          <div className={classes.formControl}>
            <FormControl component="fieldset" autoComplete="off">
              <FormLabel component="legend" className={classes.subtitle}>
                Status
              </FormLabel>
              <FormGroup>
                <Grid container>
                  <Grid item sm={2}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.status.todos
                      }
                      name="todos"
                      onChange={handleChangeStatus}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.status.assumido
                      }
                      name="assumido"
                      onChange={handleChangeStatus}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.status.aberto
                      }
                      name="aberto"
                      onChange={handleChangeStatus}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.status.finalizado
                      }
                      name="finalizado"
                      onChange={handleChangeStatus}
                    />
                  </Grid>
                  <Grid item sm={10}>
                    <ul className={classes.group}>
                      <li className={classes.item}>
                        Todos ({totais.status.todos})
                      </li>
                      <li className={classes.item}>
                        <img src={assumedIcon} style={{ marginRight: 8 }} />{' '}
                        Assumido <strong>({totais.status.assumido})</strong>
                      </li>
                      <li className={classes.item}>
                        <img src={openIcon} style={{ marginRight: 8 }} />
                        Aberto <strong>({totais.status.aberto})</strong>
                      </li>
                      <li className={classes.item}>
                        <img src={finishedIcon} style={{ marginRight: 8 }} />
                        Finalizado <strong>({totais.status.finalizado})</strong>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" autoComplete="off">
              <FormLabel
                component="legend"
                className={classes.subtitle}
                style={{ marginTop: 24 }}
              >
                Quadro
              </FormLabel>
              <FormGroup>
                <Grid container>
                  <Grid item sm={2}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.quadro.todos
                      }
                      name="todos"
                      onChange={handleChangeQuadro}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={AttendanceFilter && AttendanceFilter.quadro.leve}
                      name="leve"
                      onChange={handleChangeQuadro}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.quadro.moderado
                      }
                      name="moderado"
                      onChange={handleChangeQuadro}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter && AttendanceFilter.quadro.grave
                      }
                      name="grave"
                      onChange={handleChangeQuadro}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={
                        AttendanceFilter &&
                        AttendanceFilter.quadro.nao_indicativo
                      }
                      name="nao_indicativo"
                      onChange={handleChangeQuadro}
                    />
                  </Grid>
                  <Grid item sm={10}>
                    <ul className={classes.group}>
                      <li className={classes.item}>
                        Todos <strong>({totais.quadro.todos})</strong>
                      </li>
                      <li className={classes.item}>
                        <FiberManualRecordIcon
                          style={{ marginRight: 8, fill: '#ADE7D2' }}
                        />{' '}
                        Leve <strong>({totais.quadro.leve})</strong>
                      </li>
                      <li className={classes.item}>
                        <FiberManualRecordIcon
                          style={{ marginRight: 8, fill: '#F6D876' }}
                        />{' '}
                        Moderado <strong>({totais.quadro.moderado})</strong>
                      </li>
                      <li className={classes.item}>
                        <FiberManualRecordIcon
                          style={{ marginRight: 8, fill: '#FC5D32' }}
                        />{' '}
                        Grave <strong>({totais.quadro.grave})</strong>
                      </li>
                      <li className={classes.item}>
                        <FiberManualRecordIcon
                          style={{ marginRight: 8, fill: '#000' }}
                        />{' '}
                        Não indicativo{' '}
                        <strong>({totais.quadro.nao_indicativo})</strong>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" autoComplete="off">
              <FormLabel
                component="legend"
                className={classes.subtitle}
                style={{ marginTop: 24 }}
              >
                Linha de Cuidado
              </FormLabel>
              <FormGroup>
                <Grid container>
                  <Grid item sm={2}>
                    {lineCares &&
                      lineCares.map((linecare, index) => {
                        return (
                          <FormControlLabel
                            control={<Checkbox />}
                            checked={
                              (AttendanceFilter &&
                                AttendanceFilter.linhaDeCuidado[linecare.id]) ||
                              false
                            }
                            name={linecare.id}
                            onChange={(e) =>
                              handleChangeLinecare(e, linecare.id)
                            }
                            key={index}
                          />
                        );
                      })}
                  </Grid>
                  <Grid item sm={10}>
                    <ul className={classes.group}>
                      {lineCares &&
                        lineCares.map((linecare, index) => {
                          return (
                            <li className={classes.item} key={index}>
                              {linecare.active ? (
                                <Typography
                                  variant="body1"
                                  className="text-regular text-st"
                                >
                                  {linecare.name}
                                </Typography>
                              ) : (
                                <>
                                  <img
                                    src={inactiveIcon}
                                    style={{ marginRight: 8 }}
                                  />
                                  <BlackTooltip
                                    arrow
                                    title="Inativo"
                                    placement="right"
                                  >
                                    <Typography
                                      variant="body1"
                                      className="text-regular text-st text-disabled"
                                    >
                                      {linecare.name}
                                    </Typography>
                                  </BlackTooltip>
                                </>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
            <Grid container>
              <Grid item md={12}>
                <FormLabel
                  component="legend"
                  className={classes.subtitle}
                  style={{ marginTop: 24, fontSize: 14 }}
                >
                  Período de solicitação
                </FormLabel>
              </Grid>
              <Grid item md={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={5}>
                    <OutlinedInput
                      id="outlined1"
                      type="text"
                      placeholder={format(new Date(), 'dd/MM/yyyy')}
                      labelWidth={0}
                      classes={{
                        root: classes.inputRoot2,
                        input: classes.inputInput,
                      }}
                      value={periodoDe}
                      onBlur={(e) => validaData(e.target.value)}
                      onChange={(e) =>
                        setPeriodoDe(mascaraData(e.target.value))
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Typography
                          variant="body1"
                          className="text-regular text-st"
                        >
                          à
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={5}>
                    <FormControl component="fieldset" autoComplete="off">
                      <OutlinedInput
                        id="outlined2"
                        type="text"
                        value={periodoAte}
                        placeholder={format(new Date(), 'dd/MM/yyyy')}
                        onBlur={(e) => validaData(e.target.value)}
                        onChange={(e) =>
                          setPeriodoAte(mascaraData(e.target.value))
                        }
                        labelWidth={0}
                        classes={{
                          root: classes.inputRoot2,
                          input: classes.inputInput,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    {validate && validate.includes('invalid_date') && (
                      <Alert severity="error">Data inválida</Alert>
                    )}
                    {validate && validate.includes('major_end_date') && (
                      <Alert severity="error">
                        Data inicial superior a final
                      </Alert>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            <DButton
              onClick={aplicarFiltroData}
              variant="primary"
              icon={<img src={filterIcon} style={{ marginRight: 8 }} />}
            >
              Aplicar Filtro
            </DButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
