import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Clear, GetApp } from '@material-ui/icons';
import DButton from '../../../shared/DButton';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DRadio from '../../../shared/DRadio';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DInput from '../../../shared/DInput';
import DDropdown from '../../../shared/DDropdown';
import { listAllPerfis, listAllUnidades } from '../../../../api/dominios';
import { getAllUnidades } from '../../../../api/unidades';
import ReportIcon from '@material-ui/icons/Report';
import useError from '../../../../hooks/useError';
import {
  required,
  validaCPF,
  requiredTwoName,
  requiredUnidades,
} from '../../../../helpers/validacoes';
import DDropzone from '../../../shared/DDropzone';
import { Alert, AlertTitle } from '@material-ui/lab';
import { mascaraCPF, mascaraRG } from '../../../../helpers/formatacao';
import modelocsv from './../../../../assets/modelos/example-upload-invites.csv';
import { getModelInvite } from './../../../../api/dominios';
import { toast } from 'react-toastify';
import DSelectMutiple from './../../../shared/DSelectMutiple';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Bio } from './../../../../api/biomedidas';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: '498px',
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,
      maxHeight: '100%',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.snow,
      border: `1px solid ${theme.palette.neutral.midgrey}`,
      borderRadius: 4,
    },
    fontColor: {
      color: '#000 !important',
      '& .MuiFormControlLabel-label': {
        color: '#000 !important',
      },
      '& .MuiFormControl-root label': {
        color: '#000 !important',
      },
    },
    headermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '3px 15px',
      borderBottom: 'solid 1px transparent',
    },
    footermodal: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 16,
      borderTop: 'solid 1px transparent',
      marginTop: 8,
    },
    fontBottom: {
      '&>span ': {
        fontSize: '12px',
      },
    },
    iconRemovePermission: {
      color: `${theme.palette.support.error} !important`,
      cursor: 'pointer',
    },
  }),
);

function BiomedidasDestroy(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [loading, setLoading] = useState(false);

  const remover = () => {
    setLoading(true);

    Bio.destroy(localStorage.getItem('token'), props.biomedida.id)
      .then((res) => {
        setLoading(false);

        if (res.status) {
          toast.success(`${props.biomedida.name} foi removido`);
          props.onRemoved(true);
        } else {
          props.onRemoved(false);
          if (res.error.message) toast.error(res.error.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open ? props.open : false}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={`${classes.paper}`}>
          {/* <div className={ classes.headermodal }>
                            <Clear style={{color:"#949494"}} onClick={ e => {
                                props.onClose(true)
                            } } />
                        </div> */}
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ marginTop: 53, marginBottom: 48 }}
          >
            <Grid md={6}>
              <ErrorOutlineIcon
                className="text-error"
                fontSize="large"
                classes={{
                  fontSizeLarge: 'icon-large',
                }}
              />
            </Grid>
            <Grid md={10}>
              <Typography variant="h2" className="text-center">
                Confirmar excluir
              </Typography>
              <Typography
                variant="body1"
                className="text-mt text-center text-regular"
                style={{ marginTop: 24 }}
              >
                Confirmar a exclusão dessa medição? Isto apagará todas as suas
                configurações.
              </Typography>
            </Grid>
          </Grid>
          <div className={`${classes.footermodal} tema-light`}>
            <Grid container justifyContent="center">
              <Grid item md={10}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <DButton
                      variant="ghost"
                      onClick={(e) => props.onClose(true)}
                    >
                      Voltar
                    </DButton>
                  </Grid>
                  <Grid item>
                    <DButton
                      disabled={loading}
                      variant="primary"
                      className="btn-error"
                      onClick={remover}
                    >
                      {loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        'Confirmar Excluir'
                      )}
                    </DButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}

BiomedidasDestroy.defaultProps = {
  biomedida: null,
  open: false,
  onClose: function () {
    console.log('onClose');
  },
  onRemoved: function () {
    console.log('onRemoved');
  },
};

export default BiomedidasDestroy;
