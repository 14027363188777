import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './index.scss';
import { Divider } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';

import { toast } from 'react-toastify';
import { useNewHealthAttendance } from '../../../context/NewHealthAttendance';

export default function ModalChamadaDeVoz(props) {
  const [selectedValue, setSelectedValue] = useState(null);
  const { setInCallCtx, setClickOffButton } = useNewHealthAttendance();

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className="estilo_modal_chamada_voz">
          <div className="flex_modal_chamada_voz">
            <div className="title_modal_chamada_voz" id="modal-modal-title">
              Chamada de voz
            </div>
            <div className="flex_end_modal_chamada_voz">
              <Button onClick={(e) => props.onClose(true)}>
                <CloseIcon className="icone_modal_chamada_voz" />
              </Button>
            </div>
          </div>
          <div className="mt20_modal_chamada_voz">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          {props.value &&
            props.value.map((item, index) => {
              return (
                <div className="mt30_modal_chamada_voz alinha_radio_chamada_voz">
                  <Radio
                    checked={selectedValue && selectedValue.id === item.id}
                    onChange={(e) => handleChange(item)}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': item.phone }}
                  />
                  <div className="direcao_texto_chamada_voz label_chamada_voz">
                    <div>
                      <b>Telefone:</b> {item.phone}
                    </div>
                    <div className="mt10_modal_chamada_voz">
                      <b>Tipo de telefone:</b> {item.type}
                    </div>
                    <div className="mt10_modal_chamada_voz">
                      <b>Apelido:</b> {item.name}
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="mt20_modal_chamada_voz">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="alinha_botoes_modal_chamada_voz mt20_modal_chamada_voz">
            <Button
              className="botao_cancelar_chamada_voz"
              onClick={(e) => props.onClose(true)}
            >
              Cancelar
            </Button>
            <Button
              className="botao_chamar_chamada_voz"
              onClick={(e) => {
                selectedValue
                  ? props.onSubmit(selectedValue)
                  : toast.warning(
                      'É necessário escolher um número de contato.',
                    );
                setInCallCtx(false);
                setClickOffButton(false);
              }}
            >
              Chamar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
