import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  card_subtitle: {
    color: '#272727',
    fontSize: 14,
    margin: '0 0 16px',
  },
  edit_input: {
    backgroundColor: '#fff',
    width: '56px !important',
    marginRight: 8,
    '& input': {
      padding: 12,
    },
  },
});

function NumberComponent(props) {
  const classes = useStyles();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value !== '') {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Grid item>
      {props.showName && (
        <h6 className={classes.card_subtitle}>{props.name}</h6>
      )}
      <Grid container>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={value}
            error={props.error}
            inputProps={{ maxLength: 4 }}
            onChange={(e) => {
              let new_value = e.target.value
                ? e.target.value.replace(/[^0-9]/, '')
                : '';
              setValue(new_value);
              props.onChange(new_value);
            }}
            variant="outlined"
            classes={{ root: classes.edit_input }}
          />{' '}
          {props.showUnit ? props.unit : ''}
        </Grid>
      </Grid>
    </Grid>
  );
}

NumberComponent.defaultProps = {
  showName: false,
  name: null,
  showUnit: false,
  unit: null,
  error: false,
  onChange: function (value) {
    console.log(value);
  },
};

export default NumberComponent;
