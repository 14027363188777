import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../../assets/img/Telemed24h_LOGO_BCO 1.svg';
import { getList as getListTheme, palette } from '../../../../api/temas';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 160,
  },
}));

function TopBar(props) {
  const classes = useStyles();

  const [image, setImage] = useState(null);

  useEffect(() => {
    getListTheme()
      .then((res) => {
        if (res.status && res.configuration) {
          setImage(res.configuration.file);
        } else {
          setImage(logo);
          return logo;
        }
      })
      .catch((err) => {
        console.error(err);
        setImage(logo);
        return logo;
      });
  }, []);
  return (
    <div className={classes.root}>
      {image && <img className={classes.logo} src={image} alt="Logo" />}
    </div>
  );
}

export default TopBar;
