import env from 'react-dotenv';
import axios from 'axios';

class groupForm {
  token;
  uri;
  name = 'groupForm';

  constructor() {
    localStorage.getItem('token')
      ? (this.token = localStorage.getItem('token'))
      : (this.token = null);
    this.uri = `${env.API_URL}/group-form`;
  }

  async getAll() {
    try {
      const response = await axios.get(
        `${this.uri}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async getById(id) {
    try {
      if (!id) throw new Error(`${this.name} ~ getById: ID não informado.`);

      const response = await axios.get(
        `${this.uri}/${id}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async getByPatientId(patientId) {
    try {
      if (!patientId)
        throw new Error(
          `${this.name} ~ getByPatientId: ID do Paciente não informado.`,
        );

      const response = await axios.get(
        `${this.uri}/group-form-patient/${patientId}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async getInputsByPatientIdAndNameInput(patientId, inputName) {
    try {
      if (!patientId)
        throw new Error(
          `${this.name} ~ getByPatientId: ID do Paciente não informado.`,
        );

      const response = await axios.get(
        `${this.uri}/group-form-patient/${patientId}/input-by-name/?inputName=${inputName}&token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async getInputsByPatientId(patientId) {
    try {
      if (!patientId)
        throw new Error(
          `${this.name} ~ getByPatientId: ID do Paciente não informado.`,
        );

      const response = await axios.get(
        `${this.uri}/group-form-patient/${patientId}/inputs/4?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async getAnswerByPatientId(patientId) {
    try {
      if (!patientId)
        throw new Error(
          `${this.name} ~ getByPatientId: ID do Paciente não informado.`,
        );

      const response = await axios.get(
        `${this.uri}/group-form-patient/1/answer/${patientId}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async createAnswer(data) {
    /* Expected data:
            [
                {
                    "group_form_input_id": 15,
                    "patient_id": 9,
                    "group_form_id": 4,
                    "value": "Valor informado"
                },
                {
                    "group_form_input_id": 16,
                    "patient_id": 9,
                    "group_form_id": 4,
                    "value": "Valor 2"
                }
            ]
        */

    try {
      const answers = data.map((answer) => {
        if (!answer.group_form_input_id)
          throw new Error(
            `${this.name} ~ createAnswer: ID do group_form_input não informado.`,
          );
        if (!answer.patient_id)
          throw new Error(
            `${this.name} ~ createAnswer: ID do Paciente não informado.`,
          );
        if (!answer.group_form_id)
          throw new Error(
            `${this.name} ~ createAnswer: ID do group_form não informado.`,
          );
        if (answer.value != '')
          throw new Error(`${this.name} ~ createAnswer: Value não informado.`);

        return {
          group_form_input_id: answer.group_form_input_id,
          patient_id: answer.patient_id,
          group_form_id: answer.group_form_id,
          value: answer.value,
        };
      });

      const response = await axios.post(
        `${this.uri}/group-form-answer?token=${this.token ? this.token : localStorage.getItem('token')}`,
        { answers },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async createOrUpdateForm(data) {
    /* 
            {
                "group_form_input_id": number,
                "patient_id": number,
                "group_form_id": number,
                "value": number,
                "group_form_patient_id": number
            }
        */

    try {
      const response = await axios.patch(
        `${this.uri}/group-form-answer?token=${this.token ? this.token : localStorage.getItem('token')}`,
        data,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async updateGroupFormById(group_id, data) {
    try {
      if (!data.name)
        throw new Error(
          `${this.name} ~ updateGroupFormById: name não informado.`,
        );
      if (!data.group_id)
        throw new Error(
          `${this.name} ~ updateGroupFormById: group_id não informado.`,
        );

      const response = await axios.put(
        `${this.uri}/group-form-answer/${group_id}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async deleteGroupFormById(group_id) {
    try {
      if (!group_id)
        throw new Error(
          `${this.name} ~ deleteGroupFormById: group_id não informado.`,
        );

      const response = await axios.delete(
        `${this.uri}/${group_id}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async updateFormPatient(group_id, status) {
    try {
      const response = await axios.put(
        `${this.uri}/group-form-patient/${group_id}?token=${this.token ? this.token : localStorage.getItem('token')}`,
        { status },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }

  async createNewFormPatient(group_form_id, patient_id, status) {
    try {
      const response = await axios.post(
        `${this.uri}/group-form-patient?token=${this.token ? this.token : localStorage.getItem('token')}`,
        {
          group_form_id,
          patient_id,
          status,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(err);
      throw err; // "Repassa o erro para a função que chamou."
    }
  }
}

export default new groupForm();
