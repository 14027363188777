import { useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import DDropDownSimple from '../../../../shared/DDropDownSimple/DDropDownSimple';
import DInput from '../../../../shared/DInput';
import DAutoComplete from '../../../../shared/DAutoComplete';
import { useEffect } from 'react';

function FormCadastroConselhos(props) {
  const { states, cbos, error } = props;

  const [conselho, setConselho] = useState(props.conselho.conselho);
  const [numero, setNumero] = useState(props.conselho.numero);
  const [uf, setUf] = useState(props.conselho.uf);
  const [descricao, setDescricao] = useState(props.conselho.descricao);

  const handleValidate = () => {
    return true;
  };

  const onChangeComponent = () => {
    if (handleValidate()) {
      props.onChange({ conselho, numero, uf, descricao });
    }
  };

  useEffect(
    function () {
      onChangeComponent();
    },
    [uf, descricao],
  );

  return (
    <Card className="bg-light-gray mb-3">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <DInput
              label="Conselho de classe"
              placeholder="Conselho de classe"
              type="text"
              disabled
              positionIcon="end"
              // error={ error.conselhos }
              // helperText={ error.conselhos }
              fullWidth
              value={conselho}
            />
          </Grid>
          <Grid item md={3}>
            <DInput
              error={
                (error &&
                  numero === '' &&
                  (descricao.substring(0, 2) == '22' || descricao == '')) ||
                (error && (numero === '0000000000' || numero === '00000000'))
              }
              helperText={
                error &&
                numero === '' &&
                (descricao.substring(0, 2) == '22' || descricao == '')
                  ? 'Campo obrigatório'
                  : '' ||
                    (error && (numero === '0000000000' || numero === '00000000')
                      ? 'Campo inválido'
                      : '')
              }
              label="Código do conselho"
              placeholder="Código do conselho"
              type="text"
              positionIcon="end"
              inputProps={{ maxLength: 10 }}
              fullWidth
              onChange={(e) => {
                var _value = String(e.target.value).replace(/\D/g, '');

                setNumero(_value);
              }}
              onBlur={(e) => onChangeComponent(e.target.value)}
              value={numero}
            />
          </Grid>
          <Grid item md={3}>
            <DDropDownSimple
              error={error && uf === ''}
              helperText={error && uf === '' ? 'Campo obrigatório' : ''}
              label="UF"
              placeholder="UF Conselho"
              fullWidth
              value={uf}
              options={states}
              onChange={setUf}
            />
          </Grid>
          <Grid item md={12}>
            <DAutoComplete
              error={error && descricao === ''}
              helperText={error && descricao === '' ? 'Campo obrigatório' : ''}
              label="Código e descrição CBO"
              placeholder="Código e descrição CBO"
              fullWidth
              defaultValue={cbos.find((res) => res.code == descricao)}
              options={cbos}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.code === value.code
              }
              onChange={(event, newValue) => {
                setDescricao(newValue ? newValue.code : '');
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FormCadastroConselhos;
