import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import DInput from '../shared/DInput';

import {
  getAllSymptoms,
  createSymptom,
  updateSymptom,
  deleteSymptom,
} from '../../api/symptom';
import { nameRegex } from '../../helpers/formatacao';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@material-ui/icons';
import DButton from '../shared/DButton';

const styles = (theme) => ({
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
  },
  disabledTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    textTransform: 'none',
  },
  deleteButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
  },
  cancelarButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
    marginRight: '43px',
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
});

class Sintomas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMedicalUnits: true,
      Symptoms: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      isEdit: false,
      oldSymtom: '',
      error: [],
      delete: false,
      rowSelected: '',
      indexSelected: '',
    };
  }

  componentDidMount() {
    this.listSymptoms();
  }

  listSymptoms() {
    getAllSymptoms(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.symptoms.data.map((row) => {
          var new_state = this.state.Symptoms;
          new_state.push({
            id: row.id,
            description: row.description,
            isEdit: false,
            isLoading: false,
          });
          this.setState({ ...this.state, Symptoms: new_state });
        });
      } else {
        this.setState({ ...this.state, Symptoms: [] });
      }
    });
  }

  saveSymptom(Symptom, index) {
    if (Symptom.description.trim() == '') {
      var new_state = this.state.error;
      new_state.push({ index: index, erro: 'Campo obrigatório' });
      this.setState({ ...this.state, error: new_state });
    } else {
      var new_state = this.state.Symptoms;
      new_state[index].isLoading = !Symptom.isLoading;
      this.setState({ ...this.state, Symptoms: new_state });

      if (Symptom.id) {
        updateSymptom(localStorage.getItem('token'), Symptom)
          .then((res) => {
            if (res.status) {
              new_state[index].isLoading = !Symptom.isLoading;
              new_state[index].isEdit = !Symptom.isEdit;
              this.setState({
                ...this.state,
                Symptoms: new_state,
                isEdit: false,
                oldSymtom: '',
              });

              toast.success('Registro atualizado com sucesso!');
            } else {
              if (res.status == false) {
                if (res.message == 'já cadastrado no sistema') {
                  var new_error = this.state.error;
                  new_error.push({
                    index: index,
                    erro: `${Symptom.description} já cadastrado no sistema`,
                  });
                  this.setState({ ...this.state, error: new_error });
                  toast.error(
                    `${Symptom.description} já cadastrado no sistema`,
                  );
                } else {
                  if (res?.status_code != 401)
                    toast.error(`Não foi possível atualizar o sintoma.`);
                }
              } else {
                toast.error('Erro: Não foi possível atualizar o registro.');
                var new_error = this.state.error;
                new_error.push({
                  index: index,
                  erro: 'Este campo não aceita números ou caracteres especiais.',
                });
                this.setState({ ...this.state, error: new_error });
              }

              new_state[index].isLoading = !Symptom.isLoading;
              this.setState({ ...this.state, Symptoms: new_state });
            }
          })
          .catch((err) => {
            toast.error('Erro: Não foi possível atualizar o registro.');
            this.controlaMensagemErro(Symptom, index, new_state);
          });
      } else {
        createSymptom(localStorage.getItem('token'), Symptom)
          .then((res) => {
            if (res.status) {
              new_state[index].id = res.symptom.id;
              new_state[index].isLoading = !Symptom.isLoading;
              new_state[index].isEdit = !Symptom.isEdit;
              this.setState({ ...this.state, Symptoms: new_state });

              toast.success('Registro inserido com sucesso!');
            } else {
              if (res.status == false) {
                if (res.message == 'já cadastrado no sistema') {
                  var new_error = this.state.error;
                  new_error.push({
                    index: index,
                    erro: `${Symptom.description} já cadastrado no sistema`,
                  });
                  this.setState({ ...this.state, error: new_error });
                  toast.error(
                    `${Symptom.description} já cadastrado no sistema`,
                  );
                } else {
                  if (res?.status_code != 401)
                    toast.error(`Não foi possível criar o sintoma.`);
                }
              } else {
                var new_error = this.state.error;
                new_error.push({
                  index: index,
                  erro: 'Este campo não aceita números ou caracteres especiais.',
                });
                this.setState({ ...this.state, error: new_error });
              }

              new_state[index].isLoading = !Symptom.isLoading;
              this.setState({ ...this.state, Symptoms: new_state });
            }
          })
          .catch((err) => {
            toast.error('Erro: Não foi possível inserir o registro.');
            this.controlaMensagemErro(Symptom, index, new_state);
          });
      }
    }
  }

  controlaMensagemErro(Linecare, index, new_state) {
    new_state[index].isLoading = !Linecare.isLoading;
    this.setState({ ...this.state, Linecares: new_state });
  }

  confirmDelete(Symptom, index) {
    var new_state = this.state.Symptoms;
    new_state[index].isLoading = !Symptom.isLoading;
    this.setState({ ...this.state, Symptoms: new_state });

    deleteSymptom(localStorage.getItem('token'), Symptom.id).then((res) => {
      if (res.status) {
        new_state.splice(index, 1);
        this.setState({ ...this.state, Symptoms: new_state });
        toast.success('Registro removido com sucesso!');
      } else {
        new_state[index].isLoading = !Symptom.isLoading;
        this.setState({ ...this.state, Symptoms: new_state });
        toast.error('Erro: não foi possível remover o registro.');
      }
    });
  }

  modalConfirmDelete() {
    const styles = {
      contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px',
      },
      titleRemove: {
        borderBottom: 'none',
      },
      buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
      buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
    };
    return (
      <Dialog
        aria-labelledby="conditional-remove-title"
        scroll="body"
        onClose={(e) => {
          this.setState({ ...this.state, delete: false });
        }}
        open={true}
      >
        <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={10}>
              <Typography variant="body1" className="text-regular text-st">
                Se seguir com essa ação todos os dados serão perdidos, deseja
                confirmar essa ação?
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={(e) => {
                  this.setState({ ...this.state, delete: false });
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={styles.contentRemove}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonBack}
                variant="secondary"
                size="none"
                className="btn-error"
                onClick={(e) => this.setState({ ...this.state, delete: false })}
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonConfirm}
                variant="primary"
                size="none"
                className="btn-error"
                onClick={(e) => {
                  this.confirmDelete(
                    this.state.rowSelected,
                    this.state.indexSelected,
                  );
                  this.setState({ ...this.state, delete: false });
                }}
              >
                Confirmar Exclusão
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
      buttonToolbar: { marginTop: 24, marginBottom: 24 },
      flex: { display: 'flex' },

      listItem: {
        padding: '16px 0',
        margin: 0,
        borderTop: '1px solid #c7c7c7',
      },
      firstListItem: {
        padding: '16px 0',
        margin: 0,
      },

      item_text: {
        fontSize: 14,
        margin: 0,
        padding: 0,
      },
    };

    const { classes } = this.props;

    return (
      <>
        <Container className="tema-light">
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Atendimento
          </Typography>
          <Card style={{ background: '#f9f9f9' }}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container>
                <Grid item md={9}>
                  <Typography variant="h4">Sintomas</Typography>
                </Grid>
                <Grid item md={3} style={styles.flex} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    size="sm"
                    startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                    className={
                      this.state.Symptoms.find((x) => x.isEdit)
                        ? classes.disabledTagButton
                        : classes.addTagButton
                    }
                    disabled={this.state.Symptoms.find((x) => x.isEdit)}
                    onClick={(e) => {
                      // var new_symptom = this.state.Symptoms;
                      // new_symptom.unshift({id: null, description: '', isEdit: true, isLoading: false})

                      // console.log(new_symptom, this.state.Symptoms)

                      // if(this.state.Symptoms.find(x => x.isEdit)){
                      //     return false;
                      // } else {
                      //     // console.log(new_symptom)
                      //     // this.setState({ ...this.state, Symptoms: new_symptom })
                      // }
                      // var new_symptom = {id: null, description: '', isEdit: true, isLoading: false}

                      if (this.state.Symptoms.find((x) => x.isEdit)) return; // Já há um edit a ser preenchido

                      var new_symptom = this.state.Symptoms;
                      new_symptom.unshift({
                        id: null,
                        description: '',
                        isEdit: true,
                        isLoading: false,
                      });

                      this.setState({
                        ...this.state,
                        Symptoms: new_symptom,
                        error: [],
                      });
                    }}
                  >
                    Adicionar sintoma
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    {this.state.Symptoms.length
                      ? this.state.Symptoms.map((row, index) => (
                          <>
                            {row.isEdit ? (
                              <ListItem
                                style={
                                  index == 0
                                    ? styles.firstListItem
                                    : styles.listItem
                                }
                              >
                                <Grid item md={6}>
                                  <DInput
                                    id="name"
                                    inputProps={{ maxLength: 50 }}
                                    label="Nome do sintoma"
                                    placeholder="Digite o nome do novo sintoma"
                                    value={row.description}
                                    defaultValue={row.description}
                                    error={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? this.state.error.find(
                                            (error) => error.index == index,
                                          ).erro
                                        : ''
                                    }
                                    type="text"
                                    fullWidth={true}
                                    positionIcon="end"
                                    onChange={(e) => {
                                      if (
                                        nameRegex(e.target.value) ||
                                        e.target.value == ''
                                      ) {
                                        var new_value = this.state.Symptoms;
                                        new_value[index].description =
                                          e.target.value;
                                        this.setState({
                                          ...this.state,
                                          Symptoms: new_value,
                                          error: [],
                                        });
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <ListItemSecondaryAction style={{ right: 0 }}>
                                    <Button
                                      size="sm"
                                      className={classes.cancelarButton}
                                      onClick={(e) => {
                                        var new_symptom = this.state.Symptoms;

                                        if (!this.state.isEdit)
                                          new_symptom.splice(index, 1);
                                        else {
                                          new_symptom[index].isEdit =
                                            !row.isEdit;
                                          new_symptom[index].description =
                                            this.state.oldSymtom;
                                        }

                                        this.setState({
                                          ...this.state,
                                          isEdit: false,
                                          Symptoms: new_symptom,
                                          error: [],
                                        });
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <SaveIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.saveButton}
                                      onClick={(e) => {
                                        if (!row.isLoading) {
                                          this.saveSymptom(row, index);
                                        }
                                      }}
                                    >
                                      {row.isLoading ? (
                                        <CircularProgress
                                          size={15}
                                          color="inherit"
                                        />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </ListItemSecondaryAction>
                                </Grid>
                              </ListItem>
                            ) : (
                              <ListItem
                                style={
                                  index == 0
                                    ? styles.firstListItem
                                    : styles.listItem
                                }
                              >
                                <ListItemText
                                  primary={row.description}
                                  disableTypography={true}
                                  style={styles.item_text}
                                />
                                <ListItemSecondaryAction style={{ right: 0 }}>
                                  <IconButton
                                    size="sm"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        // this.confirmDelete(row, index)
                                        this.setState({
                                          ...this.state,
                                          delete: true,
                                          rowSelected: row,
                                          indexSelected: index,
                                        });
                                      }
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: 20 }} />
                                  </IconButton>

                                  <IconButton
                                    size="sm"
                                    className={classes.editButton}
                                    onClick={(e) => {
                                      var new_state = this.state.Symptoms;
                                      new_state[index].isEdit = !row.isEdit;
                                      this.setState({
                                        ...this.state,
                                        isEdit: true,
                                        Symptoms: new_state,
                                        oldSymtom: row.description,
                                      });
                                    }}
                                  >
                                    <EditIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </>
                        ))
                      : 'Não existem sintomas cadastrados'}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        {this.state.delete ? this.modalConfirmDelete() : ''}
      </>
    );
  }
}

export default withStyles(styles)(Sintomas);
