import clsx from 'clsx';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

function HealthAttendanceTypeFilter(props) {
  const classes = useStyles();

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
      autoComplete="off"
    >
      {/*<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>*/}
      <OutlinedInput
        id="outlined-adornment-password"
        type="text"
        placeholder="Buscar por Tipo de Atendimento"
        // value={e => console.log('')}
        // onChange={e => console.log('')}
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={e => console.log('')}
              // onMouseDown={e => console.log('')}
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        labelWidth={0}
      />
    </FormControl>
  );
}

export default HealthAttendanceTypeFilter;
