import axios from 'axios';
import env from 'react-dotenv';

function getPatientWallet(token, cpf, type) {
  let cpfOnlyNumbers = cpf.replace(/\D/g, '');

  return axios
    .get(
      `${env.API_URL}/patientWallet/patient/list?cpf=${cpfOnlyNumbers}&type=${type}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function showPatientWalletItem(token, patientWalletItemId) {
  return axios
    .get(`${env.API_URL}/patientWalletItem/show/${patientWalletItemId}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getPatientWallet, showPatientWalletItem };
