import DInput from '../../../shared/DInput';
import {
  Visibility,
  VisibilityOff,
  CheckCircleOutline,
} from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';
import DButton from '../../../shared/DButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from 'react';
import useError from '../../../../hooks/useError';
import { required } from '../../../../helpers/validacoes';
import SimpleModal from './../../../shared/Modal';
import PasswordInstrucions from './components/PasswordInstrucions';

import './style.scss';

function FormRecover(props) {
  const [error, validarFormulario, setErrors] = useError({
    confirm_senha: required,
    senha: required,
  });

  const [senha, setSenha] = useState('');
  const [confirm_senha, setConfirmSenha] = useState('');
  const [visibility1, setVisibility1] = useState(false);
  const [visibility2, setVisibility2] = useState(false);
  const [checkMinCaractere, setChekMinCaractere] = useState(false);
  const [checkCaixa, setCheckCaixa] = useState(false);
  const [checkNumero, setCheckNumero] = useState(false);
  const [checkCaractere, setCheckCaractere] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [senhasIguais, setSenhasIguais] = useState(true);

  function checkSenhasIguais(_senha, _confirmSenha) {
    if (_senha != '' && _confirmSenha != '') {
      if (_senha !== _confirmSenha) {
        setSenhasIguais(false);
        setErrors({ ...error, confirm_senha: 'Senhas diferentes.' });
      } else {
        setSenhasIguais(true);
        delete error.confirm_senha;
      }
    } else {
      setSenhasIguais(true);
    }
  }

  function validaSenhaForte(value) {
    if (value.length >= 8) setChekMinCaractere(true);
    else setChekMinCaractere(false);

    if (value.toString().replace(/[^0-9]/g, '') !== '') setCheckNumero(true);
    else setCheckNumero(false);

    if (value.toString().replace(/[0-9a-zA-Z]/g, '') !== '')
      setCheckCaractere(true);
    else setCheckCaractere(false);

    if (
      value.toString().replace(/[^a-z]/g, '') !== '' &&
      value.toString().replace(/[^A-Z]/g, '') !== ''
    )
      setCheckCaixa(true);
    else setCheckCaixa(false);
  }

  return (
    <>
      <form
        className="form-login"
        onSubmit={(form) => {
          form.preventDefault();
          if (validarFormulario({ senha, confirm_senha })) {
            props.refresh({ senha, confirm_senha });
          }
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <DInput
              fullWidth={true}
              label="Senha"
              name="senha"
              type={visibility1 ? 'text' : 'password'}
              positionIcon="end"
              icon={
                visibility1 ? (
                  <Visibility onClick={(e) => setVisibility1(false)} />
                ) : (
                  <VisibilityOff onClick={(e) => setVisibility1(true)} />
                )
              }
              error={!!error.senha}
              helperText={error.senha ? error.senha : ''}
              onChange={(e) => {
                validaSenhaForte(e.target.value);
                setSenha(e.target.value);

                if (confirm_senha != '')
                  checkSenhasIguais(e.target.value, confirm_senha);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <PasswordInstrucions
              checkMinCaractere={checkMinCaractere}
              checkCaixa={checkCaixa}
              checkNumero={checkNumero}
              checkCaractere={checkCaractere}
              senhasIguais={senhasIguais}
            />
          </Grid>
          <Grid item xs={12}>
            <DInput
              fullWidth={true}
              label="Confirmar nova senha"
              name="confirm_senha"
              type={visibility2 ? 'text' : 'password'}
              positionIcon="end"
              icon={
                visibility2 ? (
                  <Visibility onClick={(e) => setVisibility2(false)} />
                ) : (
                  <VisibilityOff onClick={(e) => setVisibility2(true)} />
                )
              }
              error={!!error.confirm_senha}
              helperText={error.confirm_senha ? error.confirm_senha : ''}
              onChange={(e) => {
                if (
                  checkCaractere &&
                  checkCaixa &&
                  checkNumero &&
                  checkMinCaractere &&
                  e.target.value === senha
                )
                  setFormValid(true);
                else setFormValid(false);

                setConfirmSenha(e.target.value);
                checkSenhasIguais(senha, e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="btn-toolbar">
          <Grid item xs={6}>
            <DButton variant="secondary" onClick={(e) => props.voltar(true)}>
              Voltar
            </DButton>
          </Grid>
          <Grid item xs={6}>
            <DButton variant="primary" disabled={!formValid} type="submit">
              {props.loading ? (
                <CircularProgress color="inherit" />
              ) : (
                'Confirmar'
              )}
            </DButton>
          </Grid>
        </Grid>
      </form>
      <SimpleModal open={props.modal} onClose={(e) => props.onCloseModal(true)}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 200 }}
        >
          <Grid item className="text-center">
            <CheckCircleOutline
              className="text-success"
              style={{ fontSize: 64 }}
            />
            <Typography className="text-mt text-regular">
              Senha alterada com sucesso
            </Typography>
          </Grid>
        </Grid>
      </SimpleModal>
    </>
  );
}

export default FormRecover;
