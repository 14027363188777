import { useState } from 'react';
import { Typography, Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    fontSize: 18,
    fontWeight: 500,
    color: `${theme.palette.primary.light} !important`,
    alignItems: 'center',
    margin: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.neutral.black,
    margin: '0 0 16px',
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  item: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral.midgrey} !important`,
  },
  boxOpen: {
    transition: '0.5s',
    width: 400,
    height: '100vh',
    background: theme.palette.background.cloud,
    position: 'fixed',
    top: 100,
    right: 0,
    zIndex: 2015,
    overflow: 'auto',
    filter:
      'drop-shadow(0px 4px 5px rgba(49, 34, 67, 0.12)) drop-shadow(0px 1px 10px rgba(49, 34, 67, 0.1)) drop-shadow(0px 2px 4px rgba(49, 34, 67, 0.18))',
  },
  boxClose: {
    transition: '0.5s',
    width: 400,
    height: '100vh',
    background: theme.palette.background.cloud,
    position: 'fixed',
    top: '100px !important',
    right: -405,
    zIndex: 2015,
    overflow: 'auto',
    filter:
      'drop-shadow(0px 4px 5px rgba(49, 34, 67, 0.12)) drop-shadow(0px 1px 10px rgba(49, 34, 67, 0.1)) drop-shadow(0px 2px 4px rgba(49, 34, 67, 0.18))',
  },
  switch: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

function Rightbar(props) {
  const classes = useStyles();

  return (
    <div className={props.show ? classes.boxOpen : classes.boxClose}>
      <h4 className={classes.title}>
        <IconButton>
          <ChevronLeftIcon
            fontSize="large"
            className="text-primary"
            onClick={props.hide}
          />
        </IconButton>
        Especialidades
      </h4>
      <div style={{ padding: 24, paddingTop: 28 }}>
        <Card style={{ padding: 16, height: 'calc(100vh - 145px)' }}>
          <h3 className={classes.subtitle}>Inativas</h3>
          <ul className={classes.list}>
            {props.specialties.length &&
              props.specialties.map((specialty, index) => {
                return (
                  <>
                    {!specialty.active ? (
                      <li className={classes.item}>
                        {specialty.description}
                        <Switch
                          defaultChecked={specialty.active}
                          size="small"
                          color="primary"
                          classes={
                            specialty.active ? { checked: classes.switch } : ''
                          }
                          onChange={(e) => {
                            // setTimeout(() => {
                            props.enableOrDisableSpecialtie(specialty);
                            // }, 1000);
                          }}
                        />
                      </li>
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
          </ul>
        </Card>
      </div>
    </div>
  );
}

export default Rightbar;
