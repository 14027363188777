import { Grid } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { mascaraCPF } from '../../../../../../../../helpers/formatacao';
import DDateInput from '../../../../../../../shared/DDateInput';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

interface IMainData {
  errors: {
    nacionalidade?: string;
    cpf?: string;
    email?: string;
    nome?: string;
    anoEstimadoDeNascimento?: string;
    dataNascimento?: string;
    sexo?: string;
    seIdentificaSexo?: string;
    justificativaDadoIncompleto?: string;
    orientacaoSexual?: string;
    genero?: string;
    nomeSocial?: string;
    pronomes?: string;
  };
  nacionalidades: {
    id: number;
    value: string;
  }[];
  sexoMap: { id: string; value: string }[];
  justificativaDadosMap: { id: string; value: string }[];
  orientacoesSexuaisMap: { id: number; value: string }[];
  generosMap: { id: number; value: string }[];
  pronomeMap: { id: number; value: string }[];
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
}

export const MainData = ({
  errors,
  nacionalidades,
  setErrors,
  removeError,
  generosMap,
  justificativaDadosMap,
  orientacoesSexuaisMap,
  pronomeMap,
  sexoMap,
}: IMainData) => {
  const classes = useStyles();
  const { dadosPrincipais, setDadosPrincipais } = usePatient();
  return (
    <>
      <Grid item xs={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <div className="input-info">
              <DDropDownSimple
                primaryColorSelectIcon
                id="nacionalidade"
                label="Nacionalidade"
                placeholder="Nacionalidade"
                fullWidth
                error={errors.nacionalidade as String}
                helperText={errors.nacionalidade}
                value={dadosPrincipais.nacionalidade}
                options={nacionalidades}
                onChange={(id: string) => {
                  removeError('nacionalidade');
                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      nacionalidade: id,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DInput
              id="cpf"
              label="CPF"
              placeholder="CPF"
              type="text"
              error={errors.cpf}
              helperText={errors.cpf}
              fullWidth={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                removeError('cpf');
                setDadosPrincipais((prevState) => {
                  return {
                    ...prevState,
                    cpf: mascaraCPF(e.target.value),
                  };
                });
              }}
              value={dadosPrincipais.cpf}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DInput
              id="email"
              placeholder="E-mail"
              label="E-mail"
              type="text"
              error={errors.email}
              helperText={errors.email}
              fullWidth={true}
              inputProps={{ maxLength: 254 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                removeError('email');
                setDadosPrincipais((prevState) => {
                  return {
                    ...prevState,
                    email: e.target.value,
                  };
                });
              }}
              value={dadosPrincipais.email}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DInput
              id="nome"
              label="Nome"
              placeholder="Nome completo"
              inputProps={{ maxLength: 100 }}
              type="text"
              error={errors.nome}
              helperText={errors.nome}
              fullWidth={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                removeError('nome');
                const _value = e.target.value.replace(
                  /[^a-zA-Zà-úÀ-Ú"' ]/g,
                  '',
                );

                setDadosPrincipais((prevState) => {
                  return {
                    ...prevState,
                    nomeCompleto: _value,
                  };
                });
              }}
              value={dadosPrincipais.nomeCompleto}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        {dadosPrincipais.dadosIncompletos ? (
          <Grid container spacing={3} className={classes.alignItemsEnd}>
            <Grid item md={12}>
              <DInput
                id="Ano estimado de nascimento"
                error={errors.anoEstimadoDeNascimento}
                helperText={errors.anoEstimadoDeNascimento}
                label="Ano estimado de nascimento"
                fullWidth
                value={dadosPrincipais.anoEstimadoDeNascimento}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  removeError('anoEstimadoDeNascimento');
                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      anoEstimadoDeNascimento: e.target.value
                        .replace(/[^0-9]/g, '')
                        .slice(0, 4),
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} className={classes.alignItemsEnd}>
            <Grid item md={12}>
              <DDateInput
                id="dataNascimento"
                label="Data de Nascimento"
                error={errors.dataNascimento}
                helperText={errors.dataNascimento}
                fullWidth
                maxDate={new Date()}
                maxDateMessage="A data de nascimento não pode ser maior que a data atual"
                value={dadosPrincipais.inputDataNascimento}
                onError={(errorMessage: string) => {
                  setErrors({ dataNascimento: errorMessage });
                }}
                onChange={(arg1, arg2) => {
                  removeError('dataNascimento');
                  if (
                    arg2.isValid &&
                    arg2.day &&
                    arg2.month &&
                    arg2.year &&
                    String(arg2.year).length === 4
                  ) {
                    setDadosPrincipais((prevState) => {
                      return {
                        ...prevState,
                        dataNascimento: arg1,
                        inputDataNascimento: arg1,
                      };
                    });

                    return;
                  }

                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      inputDataNascimento: arg1,
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <div className="input-info">
              <DDropDownSimple
                primaryColorSelectIcon
                id="sexo"
                placeholder="Sexo biológico"
                label="Sexo biológico"
                fullWidth
                error={errors.sexo}
                helperText={errors.sexo}
                value={dadosPrincipais.sexo}
                options={sexoMap}
                onChange={(id: string) => {
                  removeError('sexo');
                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      sexo: id,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <div className="input-info">
              <DDropDownSimple
                primaryColorSelectIcon
                id="seIdentificaSexo"
                placeholder="Se identifica com sexo biológico"
                label="Se identifica com sexo biológico"
                fullWidth
                error={errors.seIdentificaSexo}
                helperText={errors.seIdentificaSexo}
                value={dadosPrincipais.seIdentificaSexo}
                options={[
                  {
                    id: 1,
                    value: 'Sim',
                  },
                  {
                    id: 0,
                    value: 'Não',
                  },
                ]}
                onChange={(value) => {
                  removeError('seIdentificaSexo');
                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      seIdentificaSexo: value ? 1 : 0,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {dadosPrincipais.dadosIncompletos && (
        <Grid item md={4} className={classes.field_padding}>
          <Grid container spacing={3} className={classes.alignItemsEnd}>
            <Grid item md={12} style={{ paddingTop: 0 }}>
              <div className="input-info">
                <DDropDownSimple
                  primaryColorSelectIcon
                  id="justificativaDadoIncompleto"
                  placeholder="Justificativa dados incompletos"
                  label="Justificativa dados incompletos"
                  fullWidth
                  error={errors.justificativaDadoIncompleto}
                  helperText={errors.justificativaDadoIncompleto}
                  value={dadosPrincipais.justificativaDadoIncompleto}
                  options={justificativaDadosMap}
                  onChange={(value) => {
                    removeError('justificativaDadoIncompleto');
                    setDadosPrincipais((prevState) => {
                      return {
                        ...prevState,
                        justificativaDadoIncompleto: value,
                      };
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item md={4} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <div className="input-info">
              <DDropDownSimple
                primaryColorSelectIcon
                id="orientacaoSexual"
                label="Orientação sexual"
                placeholder="Orientação sexual"
                fullWidth
                required={!!dadosPrincipais.dadosIncompletos}
                error={errors.orientacaoSexual}
                helperText={errors.orientacaoSexual}
                value={dadosPrincipais.orientacaoSexual}
                options={orientacoesSexuaisMap}
                onChange={(value) => {
                  removeError('orientacaoSexual');
                  setDadosPrincipais((prevState) => {
                    return {
                      ...prevState,
                      orientacaoSexual: value,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {dadosPrincipais.seIdentificaSexo === 0 && (
        <>
          <Grid item md={4} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <div className="input-info">
                  <DDropDownSimple
                    id="genero"
                    primaryColorSelectIcon
                    placeholder="Gênero"
                    label="Gênero"
                    fullWidth
                    error={errors.genero}
                    helperText={errors.genero}
                    value={dadosPrincipais.genero}
                    options={generosMap}
                    onChange={(id) => {
                      removeError('genero');
                      setDadosPrincipais((prevState) => {
                        return {
                          ...prevState,
                          genero: id,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <DInput
                  id="nomeSocial"
                  placeholder="Nome social"
                  label="Nome social"
                  style={{ marginTop: 5, fontSize: '14px' }}
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  error={errors.nomeSocial}
                  helperText={errors.nomeSocial}
                  fullWidth={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const _value = e.target.value.replace(
                      /[^a-zA-Zà-úÀ-Ú"' ]/g,
                      '',
                    );

                    setDadosPrincipais((prevState) => {
                      return {
                        ...prevState,
                        nomeSocial: _value,
                      };
                    });
                  }}
                  value={dadosPrincipais.nomeSocial}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <div className="input-info">
                  <DDropDownSimple
                    id="pronomes"
                    primaryColorSelectIcon
                    placeholder="Pronomes"
                    label="Pronomes"
                    fullWidth
                    error={errors.pronomes}
                    helperText={errors.pronomes}
                    value={dadosPrincipais.pronomes}
                    options={pronomeMap}
                    onChange={(id) => {
                      removeError('pronomes');
                      setDadosPrincipais((prevState) => {
                        return {
                          ...prevState,
                          pronomes: id,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
