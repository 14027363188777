import React, { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import {
  getHealthAttendanceObservation,
  createHealthAttendanceObservation,
  updateHealthAttendanceObservation,
} from '../../../../api/healthAttendance';

const SimpleInput = styled.input`
  width: ${(props) =>
    props.fullWidth ? '100%' : props.width ? props.width : ''};
  height: ${(props) => (props.height ? props.height : '30px')};
  border: ${(props) => (props.border ? props.border : '0')};
  border-left: 1px solid #ccc;
  padding: ${(props) =>
    props.padding ? props.padding : props.noPadding ? '' : '5px'};
  outline: none;
  height: ${(props) => (props.height ? props.height : '50px')};
  background: ${(props) =>
    props.background ? props.background : 'transparent'};
`;

export default function Observation(props) {
  const [originalObservation, setOriginalObservation] = useState(null);

  const [observation, setObservation] = useState('');

  const [newObservation, setNewObservation] = useState(null);

  const observationDebounced = useDebounce(newObservation, 500);

  function handleSaveObservation(observation) {
    if (originalObservation && originalObservation.id) {
      updateHealthAttendanceObservation(
        localStorage.getItem('token'),
        observation,
        originalObservation.id,
        props.attendance.id,
        localStorage.getItem('uid'),
      )
        .then((data) => {
          if (data.status) toast.success('Observação salva com sucesso');
          else toast.error('Erro ao salvar observação');
          console.error(data);
        })
        .catch((err) => console.error(err));
    } else {
      createHealthAttendanceObservation(
        localStorage.getItem('token'),
        observation,
        localStorage.getItem('uid'),
        props.attendance.id,
      )
        .then((data) => {
          if (data.status) toast.success('Observação salva com sucesso');
          else toast.error('Erro ao salvar observação');
          console.error(data);
        })
        .catch((err) => console.error(err));
    }
  }

  useEffect(() => {
    if (!props.attendance) return;

    if (observationDebounced || observationDebounced === '') {
      newObservation && handleSaveObservation(observationDebounced);
    }
  }, [observationDebounced]);

  useEffect(() => {
    if (!props.attendance) return;
    getHealthAttendanceObservation(
      localStorage.getItem('token'),
      props.attendance.id,
    )
      .then((data) => {
        if (data.status) {
          setOriginalObservation(data.healthAttendanceObservation[0]);
          setObservation(data.healthAttendanceObservation[0].observation);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      setObservation('');
      setNewObservation(null);
      setOriginalObservation(null);
    };
  }, []);

  if (!props.attendance) return '';

  return (
    <SimpleInput
      type="text"
      placeholder="Digite uma observação"
      fullWidth
      border="0"
      padding="0px 5px 0px"
      onChange={(e) => setNewObservation(e.target.value)}
      value={
        newObservation || newObservation === '' ? newObservation : observation
      }
    />
  );
}
