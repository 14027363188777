// Componente utilizado nas seguintes telas:
// - Tela de edição de paciente
// - Tela de atendimento

import { useEffect, useMemo, useState } from 'react';

import { Button, Checkbox, Grid, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { getPatientDocuments } from '../../../../../../helpers/utils';

import { toast } from 'react-toastify';

import { getOnlyNumbers } from '../../../../../../helpers/formatacao';
import {
  parseNumber,
  required,
  requiredOptionalTwoName,
  requiredTwoName,
  validaCEP,
  validaCPF,
  validaEmail,
  validaLoteCNSCustom,
  validaPIS,
  validaRG,
  validaTel,
} from '../../../../../../helpers/validacoes';
import sexoCode from './../../../../../../assets/dados/sexo.json';

import {
  getPatientById,
  updatePatientFull,
} from '../../../../../../api/patient';

import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { InfoOutlined } from '@mui/icons-material';
import { FormControlLabel } from '@mui/material';
import { format } from 'date-fns';
import styled from 'styled-components';
import useNewError from '../../../../../../hooks/useNewError';
import { getRelationShipDegree } from './../../../../../../api/dominios';
import { usePatient } from './../../../../../../context/PatientContext';
import { EditDetails } from './components/EditDetails';
import { ViewDetails } from './components/ViewDetails';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  dados_container: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingBottom: 24,
    marginBottom: 24,
    fontSize: 14,
  },
  dados_titulo: {
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    margin: 0,
  },
  dados_editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  dados_saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  dados_chip: {
    background: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    height: 26,
    marginBottom: 16,
    marginRight: 8,
  },
  dados_card: {
    background: theme.palette.background.snow,
    boxShadow:
      '0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)',
    borderRadius: 4,
    marginBottom: 16,
    padding: 20,
  },
  field_padding: {
    paddingTop: '10px!important',
    display: 'flex',
  },
  alignItemsEnd: {
    alignItems: 'end',
  },
}));

const nacionalidades = [
  { id: 1, value: 'Brasileiro' },
  { id: 2, value: 'Naturalizado' },
  { id: 3, value: 'Estrangeiro' },
];

function DetailsMain(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [grauDeRelacionamentoList, setGrauDeRelacionamentoList] = useState([]);

  const [paciente, setPaciente] = useState(null);

  const {
    setPatient,
    setDadosPrincipais,
    dadosPrincipais,
    dadosIdentidadeGeral,
    setDadosIdentidadeGeral,
    dadosIdentidadeBrasileiro,
    setDadosIdentidadeBrasileiro,
    dadosIdentidadeNaturalizado,
    setDadosIdentidadeNaturalizado,
    dadosIdentidadeEstrangeiro,
    setDadosIdentidadeEstrangeiro,
    dadosDemograficos,
    setDadosDemograficos,
    dadosCns,
    dadosGravidez,
    setDadosGravidez,
    setDadosCns,
    dadosComplementares,
    setDadosComplementares,
    setDadosTelefones,
    dadosUnidades,
    setDadosUnidades,
    dadosEndereco,
    setDadosEndereco,
  } = usePatient();

  useEffect(() => {
    getRelationShipDegree(localStorage.getItem('token')).then((res) => {
      if (
        res.status &&
        Array.isArray(res.relationship_degrees) &&
        res.relationship_degrees.length > 0
      ) {
        setGrauDeRelacionamentoList(
          res.relationship_degrees.map((relationship_degrees) => {
            return {
              id: relationship_degrees.id,
              value: relationship_degrees.description,
            };
          }),
        );
      }
    });
  }, []);

  useEffect(() => {
    delete error.cns;
  }, [dadosCns]);

  useEffect(() => {
    if (props.paciente) {
      setPaciente(props.paciente.patient);
    }
  }, [props.paciente]);

  const [loadingPatient, setLoadingPatient] = useState(true);

  // Telefones
  const [telefones, setTelefones] = useState(
    props.paciente.patient.patient_contact.length
      ? props.paciente.patient.patient_contact
      : [],
  );
  const [qtdTelefones, setQtdTelefones] = useState([1]);

  // Unidades
  const [unidadeRef, setUnidadeRef] = useState(
    props.paciente.patient.medical_unit.length
      ? props.paciente.patient.medical_unit[0].id
      : '',
  );
  const [unidadeVinculada, setUnidadeVinculada] = useState(
    props.paciente.patient.patient_has_medical_unit.length > 0
      ? props.paciente.patient.patient_has_medical_unit.map((res) => {
          return {
            id: res.medical_unit_id,
            value: res.medical_unit.bussines_name
              ? res.medical_unit.bussines_name
              : res.medical_unit.company_name,
          };
        })
      : [],
  );

  // Switches
  const [editarFull, setEditarFull] = useState(false);

  useEffect(() => {
    if (props.paciente && props.paciente.patient) {
      getPatientById(localStorage.getItem('token'), props.paciente.patient.id)
        .then((data) => {
          if (data.status) {
            setPatient(data.details.patient);

            var _nationalityParse;

            // get nationality;
            if (isNaN(data.details.patient.nationality)) {
              // Parse nationality "Brasileiro" || "1"
              if (
                nacionalidades.find(
                  (x) => x.value === data.details.patient.nationality,
                )
              ) {
                _nationalityParse = nacionalidades.find(
                  (x) => x.value === data.details.patient.nationality,
                ).id;
              }
            } else {
              _nationalityParse = data.details.patient.nationality;
            }

            // get cpf;
            var _cpfDocument = getPatientDocuments(
              data.details.patient.patient_document,
              {
                description: 'CPF',
              },
            ).value;

            // get birth date;
            var _birthDate = data.details.patient.birth_date;

            var regDate = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);

            if (_birthDate && typeof _birthDate === 'string') {
              _birthDate = _birthDate.split(' ')[0]; // splitting yyyy-mm-dd 00:00:00;

              _birthDate = _birthDate.replace(regDate, '$3/$2/$1'); // replacing yyyy-mm-dd to dd/mm/yyyy;
            }

            // get biological_sex;
            var _biological_sex = data.details.patient.biological_sex;

            if (_biological_sex) {
              if (sexoMap.find((x) => x.value === _biological_sex)) {
                _biological_sex = sexoMap.find(
                  (x) => x.value === _biological_sex,
                ).id;
              }
            }

            // get nr;
            var _NR;

            if (_cpfDocument) {
              _NR = _cpfDocument;
            } else if (
              data.details.patient.cns_principal ||
              (Array.isArray(data.details.patient.cns_secundario) &&
                data.details.patient.cns_secundario.length > 0)
            ) {
              _NR =
                data.details.patient.cns_principal ||
                data.details.patient.cns_secundario[0].value;
            }

            let _dadosPrincipais = {
              nacionalidade: parseInt(_nationalityParse),
              cpf: _cpfDocument,
              dataNascimento: _birthDate,
              inputDataNascimento: _birthDate,
              nomeCompleto: data.details.patient.full_name,
              nomeMae: data.details.patient.mother_name,
              nomePai: data.details.patient.father_name,
              paiDesconhecido: data.details.patient.father_know,
              maeDesconhecida: data.details.patient.mother_know,
              sexo: _biological_sex,
              orientacaoSexual: data.details.patient.sexual_orientation_id,
              NR: _NR,
              genero: data.details.patient.genre_id,
              nomeSocial: data.details.patient.social_name,
              email: data.details.patient.email,
              seIdentificaSexo: data.details.patient.biological_sex_identifies,
              dadosIncompletos: !!data.details.patient.incomplete_data,
              pronomes: data.details.patient.pronoun_id,
              anoEstimadoDeNascimento: data.details.patient.birth_year,
              justificativaDadoIncompleto:
                data.details.patient.incomplete_data_reason,
              paisNascimento: data.details.patient.country_birth,
              estadoNascimento: data.details.patient.state_birth,
            };

            setDadosPrincipais(_dadosPrincipais);

            // ----------------------------------------------------------------

            let _dadosIdentidadeGeral = {
              nomeMae: data.details.patient.mother_name,
              maeDesconhecida:
                data.details.patient.mother_know == 1 ? false : true,
              nomePai: data.details.patient.father_name,
              paiDesconhecido:
                data.details.patient.father_know == 1 ? false : true,
            };

            setDadosIdentidadeGeral(_dadosIdentidadeGeral);

            // ----------------------------------------------------------------

            var _dadosIdentidadeBrasileiro = {};

            if (data.details.patient.rg) {
              let _rgValue = data.details.patient.rg.value;

              _dadosIdentidadeBrasileiro = {
                identidadeNumero: _rgValue.numero.value,
                identidadeDataEmissao: _rgValue.data_emissao.value.view,
                identidadeOrgaoEmissor: _rgValue.orgao_emissor.value,
                identidadeUF: _rgValue.uf.value.id,
              };
            }

            setDadosIdentidadeBrasileiro(_dadosIdentidadeBrasileiro);

            // ----------------------------------------------------------------

            var _dadosIdentidadeNaturalizado = {};

            if (data.details.patient.rne) {
              let _rneValue = data.details.patient.rne.value;

              _dadosIdentidadeNaturalizado = {
                identidadeNumero: _rneValue.numero.value,
                identidadeDataEmissao: _rneValue.data_emissao.value.view,
                identidadeOrgaoEmissor: _rneValue.orgao_emissor.value,
                identidadeUF: _rneValue.uf.value.id,
              };
            }

            if (_dadosIdentidadeNaturalizado) {
              setDadosIdentidadeNaturalizado(_dadosIdentidadeNaturalizado);
            }

            // ----------------------------------------------------------------

            var _dadosIdentidadeEstrangeiro = {};

            if (data.details.patient.passport) {
              let _passportValue = data.details.patient.passport.value;

              _dadosIdentidadeEstrangeiro = {
                numeroPassaporte: _passportValue.numero.value,
                dataValidade: _passportValue.data_validade.value.view,
                dataEmissaoPassaporte: _passportValue.data_emissao.value.view,
                paisPassaporte: _passportValue.pais_emissor.value.id,
              };
            }

            if (_dadosIdentidadeEstrangeiro) {
              setDadosIdentidadeEstrangeiro(_dadosIdentidadeEstrangeiro);
            }

            // ----------------------------------------------------------------

            var _cnsDocument = [];

            if (data.details.patient.cns_principal)
              _cnsDocument.push({
                value: data.details.patient.cns_principal,
                default: true,
              });

            if (
              data.details.patient.cns_secundario &&
              Array.isArray(data.details.patient.cns_secundario)
            ) {
              data.details.patient.cns_secundario.map((res) =>
                _cnsDocument.push({ value: res.value, default: false }),
              );
            }

            setDadosCns(_cnsDocument);

            // ----------------------------------------------------------------

            var _dadosDemograficos = {
              raca: data.details.patient.breed_id,
              etnia: data.details.patient.etnia_id,
              municipioNascimento: data.details.patient.city_birth,
              estadoNascimento: data.details.patient.state_birth,
            };

            setDadosDemograficos(_dadosDemograficos);

            // ----------------------------------------------------------------

            if (
              Array.isArray(data.details.patient.patient_pregnancy) &&
              data.details.patient.patient_pregnancy.length > 0
            ) {
              let _pregnancy = data.details.patient.patient_pregnancy[0];

              try {
                _pregnancy.last_mestruation_date =
                  _pregnancy.last_mestruation_date
                    ? format(
                        new Date(
                          _pregnancy.last_mestruation_date + 'T00:00:00',
                        ),
                        'dd/MM/yyyy',
                      )
                    : null;
              } catch (err) {
                console.log(err);
                _pregnancy.last_mestruation_date = null;
              }

              try {
                _pregnancy.last_abortion_date = _pregnancy.last_abortion_date
                  ? format(
                      new Date(_pregnancy.last_abortion_date + 'T00:00:00'),
                      'dd/MM/yyyy',
                    )
                  : null;
              } catch (err) {
                console.log(err);
                _pregnancy.last_abortion_date = null;
              }

              setDadosGravidez({
                qtdVezesGravidez: _pregnancy.quantity,
                filhosVivos: _pregnancy.born_alive,
                dtUltimaMenstruacao: _pregnancy.last_mestruation_date,
                teveAborto: _pregnancy.abortion === 0 ? false : true,
                qtdVezesAborto: _pregnancy.quantity_abortion,
                dtUltimoAborto: _pregnancy.last_abortion_date,
                dtUltimaMenstruacaoValida: true,
              });
            }

            // ----------------------------------------------------------------

            var _pisDoc = getPatientDocuments(
              data.details.patient.patient_document,
              {
                description: 'PIS',
              },
            );

            var _isDeficient = false;

            if (
              Array.isArray(data.details.patient.patient_deficiencies) &&
              data.details.patient.patient_deficiencies.length > 0
            ) {
              _isDeficient = true;
            }

            setDadosComplementares({
              numeroPis: _pisDoc.value,
              seriePis: _pisDoc.serie,
              ufPis: _pisDoc.uf,
              temDeficiencia: _isDeficient,
              deficiencia: data.details.patient.patient_deficiencies,
              populacaoEspec: data.details.patient.specific_population_id,
              grauDeRelacionamento: data.details.patient.relationship_degree_id,
              responsavelLegalDocumento:
                data.details.patient.legal_responsible_document,
              responsavelLegalDocumentoEmissao:
                data.details.patient.legal_responsible_document_date,
              responsavelLegal: data.details.patient.legal_responsible,
            });

            // ----------------------------------------------------------------

            var _contacts = data.details.patient.patient_contact || [];

            setDadosTelefones(_contacts);

            // -------------------------------------------------

            var _address = data.details.patient.patient_address[0];

            var _endereco = {
              cep: _address.zip_code,
              endereco: _address.address_name,
              numero: _address.number,
              complemento: _address.complement,
              bairro: _address.district,
              logradouro: _address.address_type_id,
              estado:
                _address.city && _address.city[0]
                  ? _address.city[0].state_id
                  : null,
              municipio: _address.city_id,
              idMunicipio: _address.city_id,
            };

            setDadosEndereco(_endereco);

            // -------------------------------------------------

            setDadosUnidades(data.details.patient.patient_has_medical_unit);

            if (props.paciente) {
              props.paciente.patient = data.details.patient;

              setLoadingPatient(false);
            }
          }

          setLoadingPatient(false);
        })
        .catch((err) => {
          console.log(err);

          setLoadingPatient(false);
        });
    }
  }, [props]);

  const unidadeRefs = useMemo(
    () =>
      props.unidadesRef.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [],
  );

  const unidadesVinculadas = useMemo(
    () =>
      props.unidadesVinculadas.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [],
  );

  const sexoMap = useMemo(
    () =>
      sexoCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  function formatDate(date) {
    if (date && date != '' && date != 'null') {
      var day = date.substring(0, 2);
      var month = date.substring(3, 5);
      var year = date.substring(6);

      return [year, month, day].join('-');
    } else {
      return '';
    }
  }

  const validateDate = {
    dataEmissaoIdentidade: () => {
      if (dadosIdentidadeBrasileiro.identidadeDataEmissao) {
        var [day, month, year] =
          dadosIdentidadeBrasileiro.identidadeDataEmissao.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dadosPrincipais.dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (_identidadeEmissao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataUltimaMenstruacao: () => {
      if (dadosGravidez.dtUltimaMenstruacaoValida) {
        var [day, month, year] = dadosGravidez.dtUltimaMenstruacao.split('/');

        var _dtUltimaMenstruacao = new Date(`${year}-${month}-${day}`);

        if (_dtUltimaMenstruacao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Data inválida.';
      }
    },
    dataUltimoAborto: () => {
      if (dadosGravidez.dtUltimoAborto || dadosGravidez.dtUltimoAborto != '') {
        var [day, month, year] = dadosGravidez.dtUltimoAborto.split('/');

        var _dtUltimoAborto = new Date(`${year}-${month}-${day}`);

        if (_dtUltimoAborto > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return undefined;
      }
    },
  };

  function getValidators() {
    var validators = {};

    if (
      !dadosPrincipais ||
      !dadosIdentidadeGeral ||
      !dadosIdentidadeBrasileiro ||
      !dadosIdentidadeNaturalizado ||
      !dadosIdentidadeEstrangeiro ||
      !dadosEndereco ||
      !dadosComplementares
    )
      return;

    if (!dadosPrincipais.dadosIncompletos) {
      // Dados pessoais
      validators.email = validaEmail;
      validators.nome = requiredTwoName;
      validators.nacionalidade = required;
      validators.seIdentificaSexo = (value) =>
        value !== null ? undefined : 'Campo obrigatório';

      if (dadosPrincipais.nacionalidade == 1) {
        validators.cpf = validaCPF;
      }

      console.log('se identifica com o sexo', dadosPrincipais.seIdentificaSexo);

      if (dadosPrincipais.seIdentificaSexo == 0) {
        validators.genero = required;
        validators.pronomes = required;
        validators.nomeSocial = requiredOptionalTwoName;
      }

      if (dadosPrincipais.nacionalidade == 1) {
        validators.identidadeNumero = validaRG;
        validators.identidadeEmissao = validateDate.dataEmissaoIdentidade;
        validators.identidadeOrgaoEmissor = required;
        validators.identidadeUF = required;
      }

      if (dadosPrincipais.nacionalidade == 2) {
        validators.identidadeNaturalNumero = required;
        validators.identidadeNaturalEmissao = required;
        validators.identidadeNaturalOrgaoEmissor = required;
        validators.identidadeNaturalUF = required;
      }

      if (dadosPrincipais.nacionalidade == 3) {
        validators.paisNascimento = required;
        validators.numeroPassaporte = required;
        validators.paisEmissorPassaporte = required;
        validators.dataEmissaoPassaporte = required;
        validators.dataValidadePassaporte = required;
      }

      if (dadosIdentidadeGeral.maeDesconhecida != true) {
        validators.nomeMae = requiredTwoName;
      }

      if (dadosIdentidadeGeral.paiDesconhecido != true) {
        validators.nomePai = requiredTwoName;
      }

      // Carteira Nacional de saúde (CNS)
      //validators.cns = validaLoteCNS; // condicionado a outro componente
      validators.cns = validaLoteCNSCustom;

      // Dados demográficos
      validators.raca = required;
      validators.sexo = required;
      validators.orientacaoSexual = required;

      if (dadosDemograficos.raca == 5) {
        validators.etnia = required;
      }

      if (dadosPrincipais.sexo == 'Feminino' && dadosPrincipais.idade >= 15) {
        validators.qtdVezesGravidez = required;
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;
      }

      if (dadosGravidez.qtdVezesGravidez > 0)
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;

      if (dadosGravidez.teveAborto) {
        validators.qtdVezesAborto = required;
        validators.dtUltimoAborto = validateDate.dataUltimoAborto;
      }

      if (dadosGravidez.filhosVivos || dadosGravidez.qtdVezesAborto) {
        var msgErro;

        if (dadosGravidez.filhosVivos > dadosGravidez.qtdVezesGravidez) {
          // msgErro = "Quantidade de filhos vivos não pode ser superior que a de gravidez";
          // mensagem de erro desativada temporariamente para atender questões de filhos gemeos
        } else if (
          dadosGravidez.qtdVezesAborto > dadosGravidez.qtdVezesGravidez
        ) {
          msgErro =
            'Quantidade de abortos não pode ser superior que a de gravidez';
        } else if (
          dadosGravidez.teveAborto &&
          parseNumber(dadosGravidez.filhosVivos) +
            parseNumber(dadosGravidez.qtdVezesAborto) >
            dadosGravidez.qtdVezesGravidez
        ) {
          msgErro =
            'Quantidade de filhos vivos e de abortos não condizem com a quantidade de gravidez';
        }

        function validaQtdFilhos() {
          return msgErro;
        }

        validators.qtdVezesGravidez = validaQtdFilhos;
      }

      validators.numeroPis = validaPIS;

      // Telefones
      validators.telefones = validaTel;

      if (dadosPrincipais.nacionalidade != 3) {
        // Endereço
        validators.cep = validaCEP;
        validators.numero = required;
        validators.logradouro = required;
        validators.endereco = required;
        validators.bairro = required;
        validators.municipio = (valor) => {
          if (valor == '' || valor === undefined || !valor) {
            return 'Campo obrigatório';
          }
        };
        validators.estado = required;
      }
    } else {
      // Dados incompletos

      // Dados pessoais
      validators.sexo = required;
      validators.justificativaDadoIncompleto = required;
      validators.anoEstimadoDeNascimento = (valor) => {
        if (valor === '' || valor === undefined || !valor) {
          return 'Campo obrigatório';
        }

        if (Number(valor) < 1900 || Number(valor) > new Date().getFullYear()) {
          return Number(valor) < 1900
            ? 'Data não pode ser menor que 1900.'
            : 'Data não pode ser maior que a data atual.';
        }

        return valor.length < 1 ? 'Campo obrigatório' : undefined;
      };

      // Endereço

      if (
        dadosPrincipais.nacionalidade == 2 ||
        dadosPrincipais.nacionalidade == 3
      ) {
        validators.paisNascimento = required;

        //console.log("Pais Nascimento", dadosPrincipais);
      } else {
        validators.cep = validaCEP;
        validators.numero = required;
      }
    }

    if (dadosComplementares.temDeficiencia == true) {
      validators.deficiencia = required;
    } else {
      validators.deficiencia = false;
    }

    if (
      (dadosComplementares.responsavelLegal !== null &&
        dadosComplementares.responsavelLegal) ||
      (dadosComplementares.responsavelLegalDocumento !== null &&
        dadosComplementares.responsavelLegalDocumento) ||
      (dadosComplementares.responsavelLegalDocumentoEmissao !== null &&
        dadosComplementares.responsavelLegalDocumentoEmissao)
    ) {
      validators.responsavelLegalDocumento = required;
      validators.responsavelLegalDocumentoEmissao = required;
      validators.responsavelLegal = required;
    } else {
      validators.responsavelLegalDocumento = false;
      validators.responsavelLegalDocumentoEmissao = false;
      validators.responsavelLegal = false;
    }

    return validators;
  }
  // const [error, validarFormulario, setErrors] = useError(getValidators());

  const { error, validaFormulario, setErrors, removeError } =
    useNewError(getValidators());

  function getDados() {
    // Identidade - Estrangeiro

    if (loadingPatient) return;

    var dados = {};

    // Dados principais;
    dados.cpf = dadosPrincipais.cpf;
    dados.nome = dadosPrincipais.nomeCompleto;
    dados.nomeSocial = dadosPrincipais.nomeSocial;
    dados.email = dadosPrincipais.email;
    dados.dataNascimento = dadosPrincipais.dataNascimento;
    dados.nacionalidade = dadosPrincipais.nacionalidade;
    dados.sexo = dadosPrincipais.sexo;
    dados.orientacaoSexual = dadosPrincipais.orientacaoSexual;
    dados.seIdentificaSexo = dadosPrincipais.seIdentificaSexo;
    dados.genero = dadosPrincipais.genero;
    dados.pronomes = dadosPrincipais.pronomes;
    dados.justificativaDadoIncompleto =
      dadosPrincipais.justificativaDadoIncompleto;
    dados.anoEstimadoDeNascimento = dadosPrincipais.anoEstimadoDeNascimento;
    dados.dadosIncompletos = dadosPrincipais.dadosIncompletos;

    // Identidade Geral;
    dados.nomeMae = dadosIdentidadeGeral.nomeMae;
    dados.maeDesconhecida = dadosIdentidadeGeral.maeDesconhecida;
    dados.nomePai = dadosIdentidadeGeral.nomePai;
    dados.paiDesconhecido = dadosIdentidadeGeral.paiDesconhecido;
    dados.paisNascimento = dadosIdentidadeGeral.paisNascimento;

    // Identidade Brasileiro
    dados.identidadeNumero = dadosIdentidadeBrasileiro.identidadeNumero;
    dados.identidadeEmissao = dadosIdentidadeBrasileiro.identidadeDataEmissao;
    dados.identidadeOrgaoEmissor =
      dadosIdentidadeBrasileiro.identidadeOrgaoEmissor;
    dados.identidadeUF = dadosIdentidadeBrasileiro.identidadeUF;

    // Identidade Naturalizado;
    dados.identidadeNaturalNumero =
      dadosIdentidadeNaturalizado.identidadeNumero;
    dados.identidadeNaturalEmissao =
      dadosIdentidadeNaturalizado.identidadeDataEmissao;
    dados.identidadeNaturalOrgaoEmissor =
      dadosIdentidadeNaturalizado.identidadeOrgaoEmissor;
    dados.identidadeNaturalUF = dadosIdentidadeNaturalizado.identidadeUF;
    dados.naturalizadoPaisNascimento =
      dadosIdentidadeNaturalizado.paisNascimentoNaturalizado;
    dados.paisNascimento = dadosPrincipais.paisNascimento;

    // Identidade Estrangeiro;
    dados.numeroPassaporte = dadosIdentidadeEstrangeiro.numeroPassaporte;
    dados.dataEmissaoPassaporte =
      dadosIdentidadeEstrangeiro.dataEmissaoPassaporte;
    dados.paisEmissorPassaporte = dadosIdentidadeEstrangeiro.paisPassaporte;
    dados.passaporteDataValidade =
      dadosIdentidadeEstrangeiro.dataValidadePassaporte;
    dados.paisNascimento = dadosPrincipais.paisNascimento;
    dados.dataValidadePassaporte = dadosIdentidadeEstrangeiro.dataValidade;

    // Carteira Nacional de saúde (CNS)
    dados.cns = dadosCns;

    // Dados demográficos
    dados.estadoNascimento = dadosDemograficos.estadoNascimento;
    dados.municipioNascimento = dadosDemograficos.municipioNascimento;
    dados.raca = dadosDemograficos.raca;
    dados.etnia = dadosDemograficos.etnia;

    // Dados de gravidez
    dados.qtdVezesGravidez = dadosGravidez.qtdVezesGravidez;
    dados.filhosVivos = dadosGravidez.filhosVivos;
    dados.dtUltimaMenstruacao = dadosGravidez.dtUltimaMenstruacao;
    dados.teveAborto = dadosGravidez.teveAborto;
    dados.qtdVezesAborto = dadosGravidez.qtdVezesAborto;
    dados.dtUltimoAborto = dadosGravidez.dtUltimoAborto;

    // Dados complementares
    dados.numeroPis = dadosComplementares.numeroPis;
    dados.seriePis = dadosComplementares.seriePis;
    dados.ufPis = dadosComplementares.ufPis;
    dados.temDeficiencia = dadosComplementares.temDeficiencia;
    dados.deficiencia = dadosComplementares.deficiencia;
    dados.populacaoEspec = dadosComplementares.populacaoEspec;
    dados.grauDeRelacionamento = dadosComplementares.grauDeRelacionamento;
    dados.responsavelLegalDocumento =
      dadosComplementares.responsavelLegalDocumento;
    dados.responsavelLegalDocumentoEmissao =
      dadosComplementares.responsavelLegalDocumentoEmissao;
    dados.responsavelLegal = dadosComplementares.responsavelLegal;

    // Telefones
    dados.telefones = telefones;

    // Endereço
    dados.cep = dadosEndereco.cep;
    dados.endereco = dadosEndereco.endereco;
    dados.numero = dadosEndereco.numero;
    dados.complemento = dadosEndereco.complemento;
    dados.bairro = dadosEndereco.bairro;
    dados.logradouro = dadosEndereco.logradouro;
    dados.estado = dadosEndereco.estado;
    dados.municipio = dadosEndereco.municipio;
    dados.idMunicipio = dadosEndereco.idMunicipio;

    if (dadosPrincipais.nacionalidade == 1) {
      dados.passaporteNumero = 'null';
      dados.passaportePaisEmissor = 'null';
      dados.passaporteDataEmissao = 'null';
      dados.passaporteDataValidade = 'null';
      dados.passaportePaisNascimento = 'null';
      dados.naturalizadoRne = 'null';
      dados.naturalizadoDataEmissao = 'null';
      dados.naturalizadoOrgaoEmissor = 'null';
      dados.naturalizadoUF = 'null';
      dados.naturalizadoPaisNascimento = 'null';
    }

    if (dadosPrincipais.nacionalidade == 2) {
      dados.identidadeNumero = 'null';
      dados.identidadeEmissao = 'null';
      dados.identidadeOrgaoEmissor = 'null';
      dados.identidadeUF = 'null';
      dados.passaporteNumero = 'null';
      dados.passaportePaisEmissor = 'null';
      dados.passaporteDataEmissao = 'null';
      dados.passaporteDataValidade = 'null';
      dados.passaportePaisNascimento = 'null';
    }

    if (dadosPrincipais.nacionalidade == 3) {
      dados.identidadeNumero = 'null';
      dados.identidadeEmissao = 'null';
      dados.identidadeOrgaoEmissor = 'null';
      dados.identidadeUF = 'null';
      dados.numeroPis = 'null';
      dados.seriePis = 'null';
      dados.ufPis = 'null';
      dados.naturalizadoRne = 'null';
      dados.naturalizadoDataEmissao = 'null';
      dados.naturalizadoOrgaoEmissor = 'null';
      dados.naturalizadoUF = 'null';
      dados.naturalizadoPaisNascimento = 'null';
    }

    if (dadosIdentidadeGeral.maeDesconhecida) {
      dados.nomeMae = 'null';
    }

    if (dadosIdentidadeGeral.paiDesconhecido) {
      dados.nomePai = 'null';
    }

    return dados;
  }

  function getDadosEnvio() {
    var patient = {};
    var patientAddress = {};
    var patientPregnancy = {};

    var patientDeficiencies = [];
    var patientContact = [];
    var patientDocument = [];
    var patientHasMedicalUnit = [];

    // patient
    patient.email = dadosPrincipais.email;
    patient.full_name = dadosPrincipais.nomeCompleto;
    patient.nationality = dadosPrincipais.nacionalidade;
    patient.birth_date = formatDate(dadosPrincipais.dataNascimento);
    patient.birth_year = dadosPrincipais.anoEstimadoDeNascimento;
    patient.biological_sex = dadosPrincipais.sexo;
    patient.pronoun_id = dadosPrincipais.pronomes || null;
    patient.genre_id = dadosPrincipais.genero;
    patient.social_name = dadosPrincipais.nomeSocial;
    patient.biological_sex_identifies = dadosPrincipais.seIdentificaSexo;
    patient.etnia_id =
      dadosDemograficos.raca === 5 ? dadosDemograficos.etnia : null; // Raça -> 5 === Indígena;;
    patient.sexual_orientation_id = dadosPrincipais.orientacaoSexual;
    patient.breed_id = dadosDemograficos.raca;
    patient.specific_population_id = dadosComplementares.populacaoEspec
      ? dadosComplementares.populacaoEspec.id
      : null;
    patient.relationship_degree_id = dadosComplementares.grauDeRelacionamento;
    patient.legal_responsible = dadosComplementares.responsavelLegal;
    patient.legal_responsible_document =
      dadosComplementares.responsavelLegalDocumento;
    patient.legal_responsible_document_date =
      dadosComplementares.responsavelLegalDocumentoEmissao;
    patient.medical_unit_id =
      Array.isArray(dadosUnidades.unidadeRef) && dadosUnidades.unidadeRef.length
        ? dadosUnidades.unidadeRef
        : unidadeRef != ''
          ? unidadeRef
          : null;
    patient.profession = null;
    patient.marital_status = null;

    if (dadosPrincipais.nacionalidade === 1) {
      patient.country_birth = 45;
      patient.state_birth = dadosDemograficos.estadoNascimento;
      patient.city_birth = dadosDemograficos.municipioNascimento;
    }

    if (dadosPrincipais.nacionalidade === 2) {
      patient.country_birth =
        dadosIdentidadeNaturalizado.paisNascimentoNaturalizado;
      patient.state_birth = null;
      patient.city_birth = null;
    }

    if (dadosPrincipais.nacionalidade === 3) {
      patient.country_birth =
        dadosIdentidadeEstrangeiro.passaportePaisNascimento;
      patient.state_birth = null;
      patient.city_birth = null;
    }

    patient.father_name = dadosIdentidadeGeral.nomePai;
    patient.mother_name = dadosIdentidadeGeral.nomeMae;
    patient.mother_know = !dadosIdentidadeGeral.maeDesconhecida;
    patient.father_know = !dadosIdentidadeGeral.paiDesconhecido;

    patient.incomplete_data = dadosPrincipais.dadosIncompletos;
    patient.incomplete_data_reason =
      dadosPrincipais.justificativaDadoIncompleto;

    if (dadosPrincipais.sexo === 'Feminino') {
      patientPregnancy.quantity = dadosGravidez.qtdVezesGravidez || 0;
      patientPregnancy.born_alive = dadosGravidez.filhosVivos || 0;
      patientPregnancy.last_mestruation_date =
        dadosGravidez.dtUltimaMenstruacao;
      patientPregnancy.abortion = dadosGravidez.teveAborto;
      patientPregnancy.quantity_abortion = dadosGravidez.qtdVezesAborto;
      patientPregnancy.last_abortion_date = dadosGravidez.dtUltimoAborto;
    } else {
      patientPregnancy = null;
    }

    // patientAddress
    patientAddress.zip_code = getOnlyNumbers(dadosEndereco.cep);
    patientAddress.address_name = dadosEndereco.endereco;
    patientAddress.street = dadosEndereco.endereco;
    patientAddress.number = dadosEndereco.numero;
    patientAddress.complement = dadosEndereco.complemento;
    patientAddress.district = dadosEndereco.bairro;
    patientAddress.country_id = 45; // ID 45 === Brasil. // Se tem endereço é do brasil.
    patientAddress.address_type_id = dadosEndereco.logradouro;
    patientAddress.city_id = dadosEndereco.idMunicipio;

    // patientDeficiencies
    if (
      dadosComplementares.deficiencia &&
      Array.isArray(dadosComplementares.deficiencia)
    ) {
      dadosComplementares.deficiencia.forEach((def) => {
        patientDeficiencies.push({
          value: def.value,
          patient_deficiencies_type_id: 1,
        });
      });
    }

    // patientContact
    if (telefones && Array.isArray(telefones)) {
      telefones.forEach((tel) => {
        patientContact.push({ ...tel });
      });
    }

    // patientDocument
    if (dadosCns && Array.isArray(dadosCns)) {
      dadosCns.forEach((c) => {
        if (c.value != '') {
          patientDocument.push({
            type: 'CNS',
            fields: [
              {
                // Valor do cns.
                id: 9,
                value: c.value,
              },
              {
                // Indica se o CNS é principal;
                id: 61,
                value: c.default,
              },
            ],
          });
        }
      });
    }

    if (dadosPrincipais.cpf) {
      var _cpfDocument = {
        type: 'CPF',
        fields: [
          {
            id: 1,
            value: dadosPrincipais.cpf,
          },
        ],
      };

      patientDocument.push(_cpfDocument);
    }

    if (dadosComplementares.numeroPis) {
      var _pisDocument = {
        type: 'PIS',
        fields: [
          {
            id: 57,
            value: dadosComplementares.numeroPis,
          },
          {
            id: 58,
            value: '',
          },
          {
            id: 59,
            value: '',
          },
        ],
      };

      patientDocument.push(_pisDocument);
    }

    if (dadosPrincipais.nacionalidade === 2) {
      var _rneDocument = {
        type: 'RNE',
        fields: [
          {
            id: 62,
            value: dadosIdentidadeNaturalizado.identidadeNumero,
          },
          {
            id: 63,
            value: formatDate(
              dadosIdentidadeNaturalizado.identidadeDataEmissao,
            ),
          },
          {
            id: 64,
            value: dadosIdentidadeNaturalizado.identidadeOrgaoEmissor,
          },
          {
            id: 65,
            value: dadosIdentidadeNaturalizado.identidadeUF,
          },
        ],
      };

      patientDocument.push(_rneDocument);
    }

    if (dadosPrincipais.nacionalidade === 1) {
      var _rgDocument = {
        type: 'RG',
        fields: [
          {
            id: 2,
            value: dadosIdentidadeBrasileiro.identidadeNumero,
          },
          {
            id: 3,
            value: formatDate(dadosIdentidadeBrasileiro.identidadeDataEmissao),
          },
          {
            id: 4,
            value: dadosIdentidadeBrasileiro.identidadeOrgaoEmissor,
          },
          {
            id: 60,
            value: dadosIdentidadeBrasileiro.identidadeUF,
          },
        ],
      };

      patientDocument.push(_rgDocument);
    }

    if (dadosPrincipais.nacionalidade === 3) {
      // Paciente estrangeiro;
      patientDocument.push({
        type: 'PASSPORT',
        fields: [
          {
            //Numero
            id: 5,
            value: dadosIdentidadeEstrangeiro.numeroPassaporte,
          },
          {
            //Data Emissao
            id: 6,
            value: formatDate(dadosIdentidadeEstrangeiro.dataEmissaoPassaporte),
          },
          {
            //Pais Emissor
            id: 7,
            value: dadosIdentidadeEstrangeiro.paisEmissorPassaporte,
          },
          {
            //Data Validade
            id: 8,
            value: formatDate(dadosIdentidadeEstrangeiro.dataValidade),
          },
        ],
      });
    }

    // PatientHasGroup
    // if (groups && Array.isArray(groups)) {
    //   groups.forEach((group) => {
    //     patientHasGroup.push({ group_id: group.id || null });
    //   });
    // }

    // PatientHasMedicalUnit
    if (
      Array.isArray(dadosUnidades.unidadeVinculada) &&
      dadosUnidades.unidadeVinculada.length > 0
    ) {
      dadosUnidades.unidadeVinculada.forEach((unidade) => {
        patientHasMedicalUnit.push({ medical_unit_id: unidade.id });
      });
    } else {
      unidadeVinculada.forEach((unidade) => {
        patientHasMedicalUnit.push({ medical_unit_id: unidade.id });
      });
    }

    // PatientLineCare
    // patientLineCare = paciente.patient_line_care;

    return {
      patient,
      patientAddress,
      patientPregnancy,
      patientDeficiencies,
      patientContact,
      patientDocument,
      // patientHasGroup,
      patientHasMedicalUnit,
      // patientLineCare,
    };
  }

  async function updatePatientData() {
    const dados = getDados();

    try {
      if (validaFormulario({ ...dados })) {
        var payloadEnvio = getDadosEnvio();

        try {
          updatePatientFull(
            localStorage.getItem('token'),
            payloadEnvio,
            paciente.id,
          ).then(function (data) {
            console.log('response');
            console.log(data);

            setEditarFull(false);

            if (data.status) {
              setEditarFull(false);
              toast.success('Paciente atualizado com sucesso.');
            } else {
              if (typeof data.details.patientDocument !== 'undefined') {
                toast.error(
                  'Erro ao atualizar paciente.' +
                    data.details.patientDocument[0],
                );
              } else {
                throw new Error(data);
              }

              setEditarFull(true);
            }
          });
        } catch (err) {
          console.log('Erro ao atualizar paciente', err);
          toast.error('Erro ao atualizar paciente');
        }
      } else {
        toast.warn('Verifique os campos obrigatórios.');
        console.log('error');
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function removeFormat(value) {
    return value.replace(/[\s.-]*/gim, '');
  }

  function removeAccents(text) {
    const accentsMap = new Map([
      ['A', 'Á|À|Ã|Â|Ä'],
      ['a', 'á|à|ã|â|ä'],
      ['E', 'É|È|Ê|Ë'],
      ['e', 'é|è|ê|ë'],
      ['I', 'Í|Ì|Î|Ï'],
      ['i', 'í|ì|î|ï'],
      ['O', 'Ó|Ò|Ô|Õ|Ö'],
      ['o', 'ó|ò|ô|õ|ö'],
      ['U', 'Ú|Ù|Û|Ü'],
      ['u', 'ú|ù|û|ü'],
      ['C', 'Ç'],
      ['c', 'ç'],
      ['N', 'Ñ'],
      ['n', 'ñ'],
    ]);

    const reducer = (acc, [key]) =>
      acc.replace(new RegExp(accentsMap.get(key), 'g'), key);

    return [...accentsMap].reduce(reducer, text);
  }

  function getAgeByDate(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      //
      age--;
    }
    return age;
  }

  useEffect(() => {
    try {
      if (!dadosPrincipais.dataNascimento) return;

      let _dateValue;

      if (
        dadosPrincipais.dataNascimento &&
        typeof dadosPrincipais.dataNascimento === 'string'
      ) {
        _dateValue = dadosPrincipais.dataNascimento
          .split('/')
          .reverse()
          .join('-');

        var _dateReg = new RegExp(/(\d{4})-(\d{2})-(\d{2})/g);

        if (!_dateReg.test(_dateValue)) {
          // não da match em xxxx-xx-xx
          return;
        }
      }

      setDadosPrincipais((prevState) => {
        return {
          ...prevState,
          idade: getAgeByDate(_dateValue),
        };
      });
    } catch (e) {
      console.log(e);
    }
  }, [dadosPrincipais.dataNascimento]);

  const DadosIncompletosButton = styled.button`
    background-color: ${(props) => props.bgColor};
    color: #fff;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    width: 139px;
    height: 31px;
    border: none;

    font-size: 10px;
    font-weight: 500;
    text-align: center;

    min-width: max-content;

    display: flex;
    align-items: center;
  `;

  if (
    loadingPatient ||
    paciente === null ||
    !dadosPrincipais ||
    !dadosIdentidadeBrasileiro
  )
    return 'Carregando paciente...';

  return (
    <div id="details-man">
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            {dadosPrincipais.dadosIncompletos && editarFull === false && (
              <DadosIncompletosButton bgColor={theme.palette.primary.light}>
                <InfoOutlined
                  style={{ width: 13, height: 13, marginRight: 10 }}
                />
                <span>Dados incompletos</span>
              </DadosIncompletosButton>
            )}

            {editarFull && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={dadosPrincipais.dadosIncompletos}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onClick={(e) => {
                      setDadosPrincipais((prevState) => {
                        return {
                          ...prevState,
                          dadosIncompletos: e.target.checked,
                        };
                      });
                    }}
                    icon={<CircleUnchecked />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                label="Dados incompletos"
              />
            )}
          </Grid>
          <Grid
            item
            md={6}
            style={{ display: 'flex' }}
            justifyContent="flex-end"
          >
            <Button
              variant="text"
              className={
                editarFull ? classes.dados_saveButton : classes.dados_editButton
              }
              onClick={(e) => {
                // Save
                if (editarFull) {
                  updatePatientData();
                } else {
                  setEditarFull(true);
                }
              }}
            >
              {editarFull ? (
                <>
                  <SaveIcon fontSize="small" />
                  <Box ml={1}>Salvar</Box>
                </>
              ) : (
                <>
                  <EditIcon fontSize="small" />
                  <Box ml={1}>Editar</Box>
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>

      {!editarFull ? (
        <ViewDetails
          grauDeRelacionamentoList={grauDeRelacionamentoList}
          patientData={paciente}
          phones={telefones}
          unidadesRefs={unidadeRefs}
        />
      ) : (
        <EditDetails
          grauDeRelacionamentoList={grauDeRelacionamentoList}
          phones={telefones}
          setPhones={setTelefones}
          unidadeRefs={unidadeRefs}
          unidadesVinculadas={unidadesVinculadas}
          setErrors={setErrors}
          removeError={removeError}
          error={error}
          setUnidadeRef={setUnidadeRef}
          setUnidadeVinculada={setUnidadeVinculada}
          unidadeRef={unidadeRef}
          unidadeVinculada={unidadeVinculada}
        />
      )}
    </div>
  );
}

export default DetailsMain;
