import React from 'react';
import Construtor from './../api/construtor';

export const ConditionalsContext = React.createContext({});

export const ConditionalsProvider = (props) => {
  const [conditionals, setConditionals] = React.useState(undefined);
  const [fields, setFields] = React.useState([]);
  const [forms, setForms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pendingSave, setPendingSave] = React.useState(false);

  return (
    <ConditionalsContext.Provider
      value={{
        conditionals,
        setConditionals,
        fields,
        setFields,
        forms,
        setForms,
        loading,
        setLoading,
        pendingSave,
        setPendingSave,
      }}
    >
      {props.children}
    </ConditionalsContext.Provider>
  );
};

export const useContionals = () => React.useContext(ConditionalsContext);
