import { createContext, useContext, useState } from 'react';

export const ConfiguracaoAgendaContext = createContext();

export const ConfiguracaoAgendaProvider = ({ children }) => {
  const [segunda, _setSegunda] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [terca, _setTerca] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [quarta, _setQuarta] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [quinta, _setQuinta] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [sexta, _setSexta] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [sabado, _setSabado] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [domingo, _setDomingo] = useState({
    primeiraConsulta: 0,
    retorno: 0,
    encaixe: 0,
    totalAtendimento: 0,
  });

  const [especialidade, setEspecialidade] = useState([]);
  const [justificativa, setJustificativa] = useState(null);
  const [isExcluir, setIsExcluir] = useState(false);

  const [profissional, setProfissional] = useState(null);
  const [changeProfessional, setChangeProfessional] = useState(false);
  const [anamneseFormId, setAnamneseFormId] = useState(null);
  const [formError, setFormError] = useState(true);
  const [dadosAgendamento, setDadosAgendamento] = useState(null);
  const [horariosAgenda, setHorariosAgenda] = useState([]);
  const [currentContent, setCurrentContent] = useState('lista_agendas');
  const [patientDocuments, setPatientDocuments] = useState(null);
  const [ocupacao, setOcupacao] = useState(null);
  const [conselho, setConselho] = useState(null);
  const [numeroRegistro, setNumeroRegistro] = useState('');
  const [faixaEtariaMaior, setFaixaEtariaMaior] = useState('');
  const [faixaEtariaMenor, setFaixaEtariaMenor] = useState('');
  const [faixaEtariaMaiorSelecionar, setFaixaEtariaMaiorSelecionar] =
    useState('');
  const [faixaEtariaMenorSelecionar, setFaixaEtariaMenorSelecionar] =
    useState('');
  const [sexo, setSexo] = useState('');

  const [formularios, setFormularios] = useState([]);
  const [nomePaciente, setNomePaciente] = useState('');
  const [duracaoAtendimento, setDuracaoAtendimento] = useState('00:00');
  const [intervaloAtendimento, setIntervaloAtendimento] = useState('00:00');
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const [dataSheduleItem, setDataScheduleItem] = useState(null);

  const [horariosAgendados, setHorariosAgendados] = useState(null);

  const [days, setDays] = useState([]);

  return (
    <ConfiguracaoAgendaContext.Provider
      value={{
        segunda,
        _setSegunda,
        terca,
        _setTerca,
        quarta,
        _setQuarta,
        quinta,
        _setQuinta,
        sexta,
        _setSexta,
        sabado,
        _setSabado,
        domingo,
        _setDomingo,
        especialidade,
        setEspecialidade,
        justificativa,
        setJustificativa,
        isExcluir,
        setIsExcluir,
        formularios,
        setFormularios,
        duracaoAtendimento,
        setDuracaoAtendimento,
        nomePaciente,
        setNomePaciente,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        dataSheduleItem,
        setDataScheduleItem,
        horariosAgendados,
        setHorariosAgendados,
        intervaloAtendimento,
        setIntervaloAtendimento,
        profissional,
        setProfissional,
        changeProfessional,
        setChangeProfessional,
        anamneseFormId,
        setAnamneseFormId,
        formError,
        setFormError,
        dadosAgendamento,
        setDadosAgendamento,
        horariosAgenda,
        setHorariosAgenda,
        currentContent,
        setCurrentContent,
        patientDocuments,
        setPatientDocuments,
        ocupacao,
        setOcupacao,
        conselho,
        setConselho,
        numeroRegistro,
        setNumeroRegistro,
        faixaEtariaMaior,
        setFaixaEtariaMaior,
        faixaEtariaMenor,
        setFaixaEtariaMenor,
        faixaEtariaMaiorSelecionar,
        setFaixaEtariaMaiorSelecionar,
        faixaEtariaMenorSelecionar,
        setFaixaEtariaMenorSelecionar,
        sexo,
        setSexo,
        days,
        setDays,
      }}
    >
      {children}
    </ConfiguracaoAgendaContext.Provider>
  );
};

export const useConfiguracaoAgenda = () => {
  const {
    segunda,
    _setSegunda,
    terca,
    _setTerca,
    quarta,
    _setQuarta,
    quinta,
    _setQuinta,
    sexta,
    _setSexta,
    sabado,
    _setSabado,
    domingo,
    _setDomingo,
    especialidade,
    setEspecialidade,
    justificativa,
    setJustificativa,
    isExcluir,
    setIsExcluir,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
    nomePaciente,
    setNomePaciente,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    intervaloAtendimento,
    setIntervaloAtendimento,
    dataSheduleItem,
    setDataScheduleItem,
    horariosAgendados,
    setHorariosAgendados,
    profissional,
    setProfissional,
    changeProfessional,
    setChangeProfessional,
    anamneseFormId,
    setAnamneseFormId,
    formError,
    setFormError,
    dadosAgendamento,
    setDadosAgendamento,
    horariosAgenda,
    setHorariosAgenda,
    currentContent,
    setCurrentContent,
    patientDocuments,
    setPatientDocuments,
    ocupacao,
    setOcupacao,
    numeroRegistro,
    setNumeroRegistro,
    faixaEtariaMaior,
    setFaixaEtariaMaior,
    faixaEtariaMenor,
    setFaixaEtariaMenor,
    faixaEtariaMaiorSelecionar,
    setFaixaEtariaMaiorSelecionar,
    faixaEtariaMenorSelecionar,
    setFaixaEtariaMenorSelecionar,
    sexo,
    setSexo,
    conselho,
    setConselho,
    days,
    setDays,
  } = useContext(ConfiguracaoAgendaContext);

  const somaSimples = (a, b) =>
    parseInt(a === undefined || a === null || a === '' ? 0 : a) +
    parseInt(b === undefined || b === null || b === '' ? 0 : b);

  const getNumero = (value) => value.toString().replace(/[^0-9]/, '');

  const _handleDia = (dados, setValue) =>
    setValue({
      ...dados,
      totalAtendimento: Number(dados.primeiraConsulta) + Number(dados.retorno),
    });

  const setSegunda = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setSegunda,
    );

  const setTerca = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setTerca,
    );

  const setQuarta = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setQuarta,
    );

  const setQuinta = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setQuinta,
    );

  const setSexta = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setSexta,
    );

  const setSabado = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setSabado,
    );

  const setDomingo = (
    primeiraConsulta = 0,
    retorno = 0,
    encaixe = 0,
    totalAtendimento = 0,
  ) =>
    _handleDia(
      { primeiraConsulta, retorno, encaixe, totalAtendimento },
      _setDomingo,
    );

  return {
    segunda,
    setSegunda,
    terca,
    setTerca,
    quarta,
    setQuarta,
    quinta,
    setQuinta,
    sexta,
    setSexta,
    sabado,
    setSabado,
    domingo,
    setDomingo,
    somaSimples,
    getNumero,
    especialidade,
    setEspecialidade,
    justificativa,
    setJustificativa,
    isExcluir,
    setIsExcluir,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
    nomePaciente,
    setNomePaciente,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    intervaloAtendimento,
    setIntervaloAtendimento,
    dataSheduleItem,
    setDataScheduleItem,
    horariosAgendados,
    setHorariosAgendados,
    profissional,
    setProfissional,
    changeProfessional,
    setChangeProfessional,
    anamneseFormId,
    setAnamneseFormId,
    formError,
    setFormError,
    dadosAgendamento,
    setDadosAgendamento,
    horariosAgenda,
    setHorariosAgenda,
    currentContent,
    setCurrentContent,
    patientDocuments,
    setPatientDocuments,
    ocupacao,
    setOcupacao,
    conselho,
    setConselho,
    numeroRegistro,
    setNumeroRegistro,
    faixaEtariaMaior,
    setFaixaEtariaMaior,
    faixaEtariaMenor,
    setFaixaEtariaMenor,
    faixaEtariaMaiorSelecionar,
    setFaixaEtariaMaiorSelecionar,
    faixaEtariaMenorSelecionar,
    setFaixaEtariaMenorSelecionar,
    sexo,
    setSexo,
    setDays,
    days,
  };
};
