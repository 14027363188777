import axios from 'axios';
import env from 'react-dotenv';
import { getBrowserData } from '../helpers/browser';

function checkAuth(token) {
  return axios
    .get(`${env.API_URL}/check-token?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function auth(dados) {
  var body = new FormData();
  body.append('email', dados.email);
  body.append('password', dados.senha);

  let strBrowserData = getBrowserData();

  body.append('browser', strBrowserData);
  body.append('password', dados.senha);

  return axios
    .post(`${env.API_URL}/auth`, body, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export async function checkIsValidUserId(userid) {
  try {
    const { data } = await axios.post(
      `${env.API_URL}/verify-username`,
      {
        username: userid,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  } catch (error) {
    console.log(error);

    throw error;
  }
}

export async function authId(dados) {
  try {
    const { data } = await axios.post(
      `${env.API_URL}/authid`,
      {
        username: dados.id,
        password: dados.senha,
        browser: getBrowserData(),
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

function resendCode(type) {
  var body = new FormData();
  body.append('sms', type === 'sms');

  return axios
    .post(
      `${env.API_URL}/two-factor-resend?token=${localStorage.getItem('token')}`,
      body,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function verificarCodigo(dados) {
  var body = new FormData();
  body.append('code', dados.codigo);

  let strBrowserData = getBrowserData();

  body.append('browser', strBrowserData);

  return axios
    .post(
      `${env.API_URL}/two-factor?token=${localStorage.getItem('token')}`,
      body,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function recoverPassword(dados) {
  var body = new FormData();
  body.append('email', dados.email);

  return axios
    .post(`${env.API_URL}/auth-recover`, body, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function refreshPassword(senha, token) {
  var body = new FormData();
  body.append('password', senha);

  return axios
    .post(`${env.API_URL}/auth-refresh?token=${token}`, body, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function executeLogout(token) {
  return axios
    .post(`${env.API_URL}/logout?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  auth,
  resendCode,
  verificarCodigo,
  recoverPassword,
  refreshPassword,
  checkAuth,
  executeLogout,
};
