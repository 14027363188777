//Import core
import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { filterProfessionalSchedule } from '../../../../api/schedule';
import useDebounce from '../../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

function AgendaFilter(props) {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const filterValueDebounced = useDebounce(filterValue, 500);

  async function listarPacientes() {
    var filter = {};

    filter.user_id = localStorage.getItem('uid');

    if (filterValueDebounced) {
      filter.search = filterValueDebounced;
    }

    if (props.selectedDate) {
      const date = new Date(props.selectedDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      filter.start_date_min = year + '-' + month + '-' + day;
      filter.start_date_max = year + '-' + month + '-' + day;
    }

    filterProfessionalSchedule(localStorage.getItem('token'), filter)
      .then((data) => {
        if (data.status) {
          console.log('data.schedule', data.schedule);
          props.setScheduleList(data.schedule, filterValueDebounced);
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    listarPacientes();
  }, [filterValueDebounced]);

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
      autoComplete="off"
    >
      <OutlinedInput
        id="outlined"
        type="text"
        placeholder="Buscar por nome, ID, CNS ou CPF"
        labelWidth={0}
        value={filterValue}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            listarPacientes();
          }
        }}
        onChange={(e) => {
          setFilterValue(
            e.target.value
              .toString()
              .replace(/[`´¨~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/]/gi, ''),
          );
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton onClick={(e) => listarPacientes()} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default AgendaFilter;
