import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import PersonIcon from '@material-ui/icons/Person';
import { Grid, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 17,
  },
  active: {
    '& $line': {
      backgroundColor: `1px solid ${theme.palette.background.cloud}`,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.background.cloud,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.background.cloud,
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    zIndex: 1,
    color: theme.palette.neutral.midgrey,
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.complement.main,
    borderColor: theme.palette.complement.main,
    color: theme.palette.neutral.white,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    color: theme.palette.neutral.white,
  },
}));

function StepLine(props) {
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PersonIcon />,
      2: <SettingsIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  function getSteps() {
    return ['Dados da unidade', 'Configurações'];
  }

  const steps = getSteps();

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <Stepper
          alternativeLabel
          activeStep={props.step}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  className="text-mt text-regular"
                  variant="body1"
                  gutterBottom
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}

export default StepLine;
