import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DAutoCompleteControlled from '../../../../../shared/DAutoCompleteControlled';
import DInput from '../../../../../shared/DInput';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
  },
  errorColor: {
    color: theme.palette.support.error,
  },
}));

function Sintomas(props) {
  const classes = useStyles();

  const [id, setId] = useState(null);
  const [day, setDay] = useState(null);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    setId(props.sintomaId);
    setDay(props.sintomaDay);
    if (props.sintomas.filter((res) => res.id === props.sintomaId)[0]) {
      setInputValue(
        props.sintomas.filter((res) => res.id === props.sintomaId)[0].value,
      );
    }
  }, [props.sintomaId, props.sintomaDay]);

  useEffect(() => {
    console.log(day);
    console.log(typeof day);
  }, [props.error]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <DAutoCompleteControlled
          id={`sintomas-${props.id}`}
          label="Sintomas"
          placeholder=""
          labelColor="black"
          error={props.error && parseInt(id) <= 0}
          helperText={props.error && parseInt(id) <= 0 ? props.error : ''}
          fullWidth
          value={props.sintomas.filter((res) => res.id === id)[0]}
          onChange={(e, newValue) => {
            props.onChange({ id: newValue ? newValue.id : '', days: day });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={props.sintomas}
          getOptionLabel={(option) => option.value}
          getOptionSelected={(option, value) => value && option.id === value.id}
        />
      </Grid>
      <Grid item md={6}>
        <Grid container alignItems="center">
          <Grid item md={8}>
            <DInput
              id={`days-${props.id}`}
              label="Há quantos dias?"
              labelColor="black"
              placeholder="Há quantos dias?"
              type="text"
              error={props.error && (day == null || day <= 0)}
              helperText={
                props.error && (day == null || day <= 0)
                  ? day == '0'
                    ? 'Campo inválido'
                    : props.error
                  : ''
              }
              value={day}
              inputProps={{ maxLength: 2 }}
              positionIcon="end"
              fullWidth={true}
              onChange={(e) => {
                props.onChange({
                  id,
                  days: e.target.value.replace(/[^0-9]/g, ''),
                });
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              justifyContent={props.index > 0 ? 'space-between' : 'center'}
              alignItems="center"
            >
              {props.index > 0 ? (
                <Grid item>
                  <DeleteForeverIcon
                    classes={{ root: classes.icon }}
                    className={classes.errorColor}
                    size={14}
                    onClick={(e) => props.onRemoveNewSymptomItem()}
                  />
                </Grid>
              ) : (
                ''
              )}
              {props.totalSintomas - 1 === props.index ? (
                <Grid item>
                  <AddCircleIcon
                    classes={{ root: classes.icon }}
                    color="primary"
                    size={14}
                    onClick={(e) => props.onAddNewSymptomItem()}
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Sintomas.defaultProps = {
  id: new Date().getTime(),
  index: 0,
  error: false,
  totalSintomas: 0,
  sintomas: [],
  sintomaId: null,
  sintomaDay: null,
};

export default Sintomas;
