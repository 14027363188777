import { Grid, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getPatientById,
  updateCNSDocument,
} from '../../../../../../../../api/patient';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { validaLoteCNSCustom } from '../../../../../../../../helpers/validacoes';
import useNewError from '../../../../../../../../hooks/useNewError';
import CNSCard from '../../../../../../../shared/CNSCard';
import DButton from '../../../../../../../shared/DButton';

const useStyles = makeStyles((theme) => ({
  dados_titulo: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    margin: 0,
  },
  dados_editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  dados_saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  marginDefault: {
    marginTop: 0,
    marginBottom: 25,
  },
}));

interface CnsProps {
  errors: { [key: string]: any };
}

export const Cns = ({ errors }: CnsProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [cns, setCNS] = useState<{ value: string; default: boolean }[]>();
  const { error, validaFormulario, setErrors, removeError } = useNewError({
    cns: validaLoteCNSCustom,
  });

  const { patient, setPatient, dadosCns, setDadosCns } = usePatient();

  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  useEffect(() => {
    let cnses = [];

    if (patient.cns_principal)
      cnses.push({ value: patient.cns_principal, default: true });

    if (patient.cns_secundario && patient.cns_secundario.length > 0) {
      patient.cns_secundario.map((res) =>
        cnses.push({ value: res.value, default: false }),
      );
    }

    setCNS(dadosCns);
  }, []);

  return (
    <form
      onSubmit={(form) => {
        form.preventDefault();
        if (validaFormulario({ cns: cns?.map((res) => res.value) })) {
          setLoading(true);

          setDadosCns(cns);

          let body = {
            cns: cns?.map((res) => {
              return {
                type: 'CNS',
                fields: [
                  {
                    id: 9,
                    value: res.value,
                  },
                  {
                    id: 61,
                    value: res.default,
                  },
                ],
              };
            }),
          };

          updateCNSDocument(
            localStorage.getItem('token'),
            body,
            patient.id,
          ).then((res) => {
            setLoading(false);

            if (res.status) {
              toast.success('CNS atualizado com sucesso.');
              getPatientById(localStorage.getItem('token'), patient.id).then(
                (res) => {
                  if (res.status) {
                    setPatient(res.details.patient);
                  }
                },
              );
            }
          });
        } else {
          console.log(error);
        }
      }}
    >
      <Grid container>
        <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
          <h5 className={classes.dados_titulo}>Cartão Nacional de Saúde</h5>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {cns &&
            cns.map((res, index) => {
              return (
                <CNSCard
                  key={`cns-card-${index}`}
                  notDelete={index === 0}
                  isDefault={res.default}
                  value={res.value}
                  error={error.cns && index in error.cns}
                  helperText={
                    error.cns && index in error.cns ? error.cns[index] : ''
                  }
                  onBlur={(e) => {
                    removeError('cns');
                    setDadosCns((prevState) => {
                      let newCns = [...prevState];

                      if (e.default) {
                        if (newCns.find((x) => x.default === true)) {
                          newCns.find((x) => x.default === true).default =
                            false;
                        }
                      }

                      newCns[index] = {
                        value: e.value,
                        default: e.default,
                      };

                      return [...newCns];
                    });
                  }}
                  onChange={(e) => {
                    removeError('cns');
                    setCNS((prevState) =>
                      prevState.map((c, i) =>
                        i === index
                          ? { value: e.value, default: e.default }
                          : c,
                      ),
                    );
                  }}
                  onDelete={(e) => {
                    setCNS((prevState) =>
                      prevState.filter((c, i) => i !== index),
                    );
                    setDadosCns((prevState) =>
                      prevState.filter((c, i) => i !== index),
                    );
                  }}
                />
              );
            })}
          <DButton
            className={classes.marginDefault}
            type="button"
            variant="primary"
            size="sm"
            icon={<AddCircleIcon />}
            onClick={(x) => {
              let oldCns = [...cns];

              oldCns.push({ value: '', default: oldCns.length <= 0 });

              setCNS(oldCns);
            }}
          >
            Adicionar novo CNS
          </DButton>
        </Grid>
      </Grid>
    </form>
  );
};
