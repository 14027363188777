import env from 'react-dotenv';
import axios from 'axios';

function getAllCategories(token) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/category/all?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getCategoryById(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/category/${id}?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createCategory(token, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .post(`${api_url}/faq/category?token=${token}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateCategory(token, dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .put(
      `${api_url}/faq/category/${id}?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-auth-token': token,
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteCategory(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .delete(`${api_url}/faq/category/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
};
