import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, makeStyles } from '@material-ui/core';
import './index.scss';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { useEffect } from 'react';
import { Vacinacao } from '../../../../api/above';
import {
  getPatientWallet,
  showPatientWalletItem,
} from '../../../../api/patientWallet';
import ModalVacinacao from '../../NewTabs/VisualizadorClinico/Components/ModalVacinacao';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaVacinas: {
    color: `${theme.palette.primary.light} !important`,
  },
}));
export default function CustomizedTables() {
  const [vacinas, setVacinas] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const { healthAttendance } = useNewHealthAttendance();
  const { patient } = useNewHealthAttendance();
  const classes = useStyles();

  useEffect(() => {
    if (healthAttendance) {
      Vacinacao.listVacinas(
        localStorage.getItem('token'),
        healthAttendance.id,
      ).then((data) => {
        if (data.status) {
          setVacinas(data.details);
        }
      });
    }
    getPatientWallet(
      localStorage.getItem('token'),
      patient.cpf,
      'vaccine',
    ).then((data) => {
      if (data.status) {
        setVacinas(data.patientWallet);
      }
    });
  }, [patient]);

  const handleOpenFile = (patientWalletItemId) => {
    showPatientWalletItem(localStorage.getItem('token'), patientWalletItemId)
      .then((data) => {
        if (!data.status && !data.temporalyUrlFile) return;

        let _filename = data.patientWalletItem.file.split('/').pop();
        let link = document.createElement('a');
        link.href = data.temporalyUrlFile;
        link.target = '_blank';
        link.download = _filename;
        link.click();
        link.remove();
      })
      .catch((err) => console.error(err));
  };

  function formatDate(date) {
    if (date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : null;
      var day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
      var month =
        date.getMonth() + 1 >= 10 ? date.getMonth() : '0' + date.getMonth();
      var year = String(date.getFullYear()).slice(2);

      return [day, month, year].join('/');
    } else {
      return '';
    }
  }

  return (
    <>
      {vacinas && vacinas.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Data</StyledTableCell>
                <StyledTableCell align="left">Vacina</StyledTableCell>
                <StyledTableCell align="left">Dose</StyledTableCell>
                <StyledTableCell align="left">Lote</StyledTableCell>
                <StyledTableCell align="center">Arquivo(s)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vacinas &&
                vacinas.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {item.date && formatDate(item.date)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.dosage}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.batch}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item &&
                          item.items.map((subitem, indexSubitem) => {
                            return (
                              <Button
                                className={classes.iconResultadoTabelaVacinas}
                                onClick={(e) => handleOpenFile(subitem.id)}
                                startIcon={<LaunchIcon />}
                              ></Button>
                            );
                          })}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ''
      )}
      {open ? (
        <ModalVacinacao open={open} setModal={(_) => setOpen(!open)} />
      ) : (
        ''
      )}
    </>
  );
}
