import { useState } from 'react';

function useError(validations) {
  const [error, setError] = useState({});

  function validaFormulario(dados) {
    let error = {};

    for (var validation in validations) {
      let isInvalid;
      try {
        isInvalid = validations[validation](dados[validation]);
      } catch (e) {
        console.log(`Erro ao validar campo ${validation}`);
        console.log(e);
      }
      if (isInvalid !== undefined)
        error = Object.assign({ [validation]: isInvalid }, error);
    }

    setError(error);

    return Object.entries(error).length < 1;
  }

  function setErrors(errors) {
    setError({ ...error, ...errors });
  }

  return [error, validaFormulario, setErrors];
}

export default useError;
