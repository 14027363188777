import { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import powerbi from './../../api/powerbi';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  centeredText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

function PowerBI(props) {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);

  const reportId = '128f159d-3447-40c1-99ed-423d1aae600a';
  const groupId = '02f01751-d07b-4441-accc-929b4821dd7c';

  useEffect(() => {
    powerbi.getAccesToken(localStorage.getItem('token')).then((res) => {
      setReady(true);
      if (res.data.status) {
        if (typeof res?.data?.token_content?.access_token != 'undefined') {
          setToken(res.data.token_content.access_token);
        }
      }
    });
  }, []);

  return (
    <>
      {token == null && ready && (
        <span className={classes.centeredText}>
          Não foi possível carregar as informações.
        </span>
      )}
      {token && ready && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
            accessToken: token,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                'loaded',
                function () {
                  console.log('Report loaded');
                },
              ],
              [
                'rendered',
                function () {
                  console.log('Report rendered');
                },
              ],
              [
                'error',
                function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={'Ember-container'}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      )}
    </>
  );
}

export default PowerBI;
