// Esse componente está sendo utilizado por outra tela ./solicitacoes
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  withStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircleOutline, Close } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useContext, useEffect, useState } from 'react';
import { createPatient } from '../../../../api/patient';
import { getCep } from '../../../../helpers/apis';
import {
  mascaraCEP,
  mascaraCPF,
  mascaraNumero,
  mascaraPIS,
  mascaraRG,
} from '../../../../helpers/formatacao';
import {
  required,
  requiredOptionalTwoName,
  requiredTwoName,
  validaCEP,
  validaCPF,
  validaEmail,
  validaLoteCNS,
  validaPIS,
  validaRG,
} from '../../../../helpers/validacoes';
import useError from '../../../../hooks/useError';
import DAutoComplete from '../../../shared/DAutoComplete';
import DButton from '../../../shared/DButton';
import DDateInput from '../../../shared/DDateInput';
import DDropDownSimple from '../../../shared/DDropDownSimple';
import DInput from '../../../shared/DInput';
import DMultAutoComplete from '../../../shared/DMultAutoComplete';
import SimpleModal from '../../../shared/Modal';
import deficienciasCode from './../../../../assets/dados/deficiencia.json';
import generoCode from './../../../../assets/dados/genero.json';
import justificativaDadosIncompletosCode from './../../../../assets/dados/justificativasDadosIncompletos.json';
import orgaoEmissorCode from './../../../../assets/dados/orgaoEmissor.json';
import orientacoesSexuaisCode from './../../../../assets/dados/orientacoesSexuais.json';
import populacaoEspecCode from './../../../../assets/dados/populacaoEspec.json';
import racasCode from './../../../../assets/dados/racas.json';
import sexoCode from './../../../../assets/dados/sexo.json';
import StepLine from './components/Step';
// import DateFnsUtils from "@date-io/date-fns";
import 'date-fns';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getCitys,
  getCountrys,
  getEtnias,
  getStates,
} from '../../../../api/dominios';
import { getUnidade } from '../../../../api/unidades';
import { AppContext } from '../../../../context/AppContext';
import { UnidadeCard } from './styles';

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
  },
  root: {
    width: 545,
    height: 450,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingTop: 16,
    paddingBottom: 16,
  },
  marginDefault: {
    marginTop: 0,
    marginBottom: 25,
  },
  linha: {
    border: `1px solid ${theme.palette.primary.light}`,
    marginTop: 0,
    marginBottom: 32,
  },
  icon: {
    color: theme.palette.neutral.midgrey,
  },
  hide: {
    display: 'none',
  },
  buttonModal: {
    minWidth: 'unset !important',
    height: '33px !important',
    padding: '6px 18px !important',
  },
  labelBlack: {
    color: theme.palette.neutral.white,
  },
  textPrimaryColor: {
    color: theme.palette.primary.light,
    wordBreak: 'break-word',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral.white,
    boxShadow:
      '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
    fontSize: 20,
    marginTop: 3,
  },
  arrow: {
    color: theme.palette.neutral.white,
  },
}))(Tooltip);

function validaTel(tel) {
  var errors = [];

  if (Array.isArray(tel)) {
    tel.forEach((item, index) => {
      var telObj = { index: null, phone: null, name: null };

      if (!item.phone || item.phone.length <= 0) {
        telObj.phone = 'Campo obrigatório';
      } else if (
        item.phone.length < 14 ||
        item.phone == '(00) 00000-0000' ||
        item.phone == '(11) 11111-1111' ||
        item.phone == '(22) 22222-2222' ||
        item.phone == '(33) 33333-3333' ||
        item.phone == '(44) 44444-4444' ||
        item.phone == '(55) 55555-5555' ||
        item.phone == '(66) 66666-6666' ||
        item.phone == '(77) 77777-7777' ||
        item.phone == '(88) 88888-8888' ||
        item.phone == '(99) 99999-9999'
      ) {
        telObj.phone = 'Telefone inválido';
      }

      if (!item.name || item.name.length <= 0) {
        telObj.name = 'Campo obrigatório';
      }

      telObj.index = index;
      errors.push(telObj);
    });

    if (errors.length) {
      if (errors.filter((x) => x.phone || x.name).length > 0) {
        return errors;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } else {
    return null;
  }
}

function PacienteCreate(props) {
  const classes = useStyles();
  const { userInfo } = useContext(AppContext);

  const { onClose, open } = props;

  const [activeMedicalUnit, setActvieMedicalUnit] = useState(null);

  // Dados pessoais
  const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [nomeSocial, setNomeSocial] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [dataNascimentoValida, setDataNascimentoValida] = useState(true);
  const [idade, setIdade] = useState(0);
  const [nacionalidade, setNacionalidade] = useState('');

  // Identidade
  const [identidadeNumero, setIdentidadeNumero] = useState('');
  const [identidadeEmissao, setIdentidadeEmissao] = useState('');
  const [identidadeEmissaoValida, setIdentidadeEmissaoValida] = useState(true);
  const [identidadeOrgaoEmissor, setIdentidadeOrgaoEmissor] = useState('');
  const [identidadeUF, setIdentidadeUF] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [maeDesconhecida, setMaeDesconhecida] = useState(false);

  // Identidade - Naturalizado
  const [dataNaturalizacao, setDataNaturalizacao] = useState('');
  const [dataNaturalizacaoValida, setDataNaturalizacaoValida] = useState(true);
  const [paisNascimento, setPaisNascimento] = useState('');

  // Identidade - Estrangeiro
  const [passaporteNumero, setPassaporteNumero] = useState('');
  const [passaportePaisEmissor, setPassaportePaisEmissor] = useState('');
  const [passaporteDataEmissao, setPassaporteDataEmissao] = useState('');
  const [passaporteDataEmissaoValida, setPassaporteDataEmissaoValida] =
    useState(true);
  const [passaporteDataValidade, setPassaporteDataValidade] = useState('');
  const [passaporteDataValidadeValida, setPassaporteDataValidadeValida] =
    useState(true);
  const [passaportePaisNascimento, setPassaportePaisNascimento] = useState('');

  // Carteira Nacional de saúde (CNS)
  const [cns, setCns] = useState(['']);
  const [cnsPrincipal, setCnsPrincipal] = useState(0);
  const [qtdCns, setQtdCns] = useState([1]);

  // Dados demográficos
  const [raca, setRaca] = useState('');
  const [etnia, setEtnia] = useState(null);
  const [sexo, setSexo] = useState('');
  const [genero, setGenero] = useState('');
  const [orientacaoSexual, setOrientacaoSexual] = useState('');

  // Gravidez
  const [qtdVezesGravidez, setQtdVezesGravidez] = useState('');
  const [filhosVivos, setFilhosVivos] = useState('');
  const [dtUltimaMenstruacao, setDtUltimaMenstruacao] = useState('');
  const [dtUltimaMenstruacaoValida, setDtUltimaMenstruacaoValida] =
    useState(true);
  const [teveAborto, setTeveAborto] = useState(false);
  const [qtdVezesAborto, setQtdVezesAborto] = useState('');
  const [dtUltimoAborto, setDtUltimoAborto] = useState('');
  const [dtUltimoAbortoValida, setDtUltimoAbortoValida] = useState(true);

  //Dados incompletos
  const [justificativaDadoIncompleto, setJustificativaDadoIncompleto] =
    useState('');
  const [anoEstimadoDeNascimento, setAnoEstimadoDeNascimento] = useState('');

  // Dados complementares
  const [numeroPis, setNumeroPis] = useState('');
  const [seriePis, setSeriePis] = useState('');
  const [ufPis, setUfPis] = useState('');
  const [temDeficiencia, setTemDeficiencia] = useState(false);
  const [deficiencia, setDeficiencia] = useState([]);
  const [populacaoEspec, setPopulacaoEspec] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [paiDesconhecido, setPaiDesconhecido] = useState(false);

  // Telefones
  const [telefones, setTelefones] = useState([{ id: 1, phone: '', name: '' }]);
  const [qtdTelefones, setQtdTelefones] = useState([1]);

  // Unidades
  const [unidadeRef, setUnidadeRef] = useState('');
  const [unidadeVinculada, setUnidadeVinculada] = useState([]);

  // Endereço
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [municipio, setMunicipio] = useState('');
  const [idMunicipio, setIdMunicipio] = useState('');

  // Passos do formulário
  const [step, setStep] = useState(0);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  // switches
  const [loading, setLoading] = useState(false);
  const [expandMore, setExpandMore] = useState(false);
  const [dadosIncompletos, setDadosIncompletos] = useState(false);
  const [cadastrado, setCadastrado] = useState(false);

  const [states, setStates] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [citys, setCitys] = useState([]);
  const [etnias, setEtnias] = useState([]);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [load4, setLoad4] = useState(false);
  const [load5, setLoad5] = useState(false);

  useEffect(function () {
    getStates(localStorage.getItem('token'))
      .then((res) => {
        if (res.status)
          setStates(
            res.states.map((item) => {
              return { id: item.id, value: item.code };
            }),
          );

        setLoad1(true);
      })
      .catch((err) => console.log(err));

    getCitys(localStorage.getItem('token'))
      .then((res) => {
        if (res.status)
          setCitys(
            res.cities.map((item) => {
              return { id: item.id, value: item.description };
            }),
          );

        setLoad2(true);
      })
      .catch((err) => console.log(err));

    getCountrys(localStorage.getItem('token'))
      .then((res) => {
        if (res.status)
          setCountrys(
            res.countrys.map((item) => {
              return { id: item.id, value: item.description };
            }),
          );

        setLoad3(true);
      })
      .catch((err) => console.log(err));

    getEtnias(localStorage.getItem('token'))
      .then((res) => {
        if (res.status)
          setEtnias(
            res.etnias.map((item) => {
              return { id: item.id, value: item.description };
            }),
          );

        setLoad4(true);
      })
      .catch((err) => console.log(err));

    if (userInfo && userInfo.unidade) {
      getUnidade(localStorage.getItem('token'), userInfo.unidade)
        .then((data) => {
          if (data.status) {
            setActvieMedicalUnit(data.details);
          }

          setLoad5(true);
        })
        .catch((err) => {
          console.error(err);
          setLoad5(true);
        });
    }
  }, []);

  // const states = props.userDominios.state && props.userDominios.state.map((item) => {
  //     return { id: item.id, value: item.code };
  // });

  // const countrys = props.userDominios.country && props.userDominios.country.map((item) => {
  //     return { id: item.id, value: item.description };
  // });

  // const citys = props.userDominios.city && props.userDominios.city.map((item) => {
  //     return { id: item.id, value: item.description }
  // })

  // const etnias = props.userDominios.etnia && props.userDominios.etnia.map((item) => {
  //     return { id: item.id, value: item.description };
  // });

  const unidadesRef = props.unidadesRef.map(function (item) {
    return {
      id: item.id,
      value: item.business_name ? item.business_name : item.company_name,
    };
  });

  const unidadesVinculadas = props.unidadesVinculadas.map(function (item) {
    return {
      id: item.id,
      value: item.business_name ? item.business_name : item.company_name,
    };
  });

  const racasMap = racasCode.map(function (item) {
    return { id: item.id, value: item.name };
  });

  const deficienciasMap = deficienciasCode.map(function (item) {
    return { id: item.name, value: item.name };
  });

  const populacaoEspecMap = populacaoEspecCode.map(function (item) {
    return { id: item.id, value: item.name };
  });

  const orientacoesSexuaisMap = orientacoesSexuaisCode.map(function (item) {
    return { id: item.id, value: item.name };
  });

  const generosMap = generoCode.map(function (item) {
    return { id: item.id, value: item.name };
  });

  const orgaoEmissorMap = orgaoEmissorCode.map(function (item) {
    return { id: item.name, value: item.name };
  });

  const justificativaDadosIncompletosMap =
    justificativaDadosIncompletosCode.map(function (item) {
      return { id: item.name, value: item.name };
    });

  const sexoMap = sexoCode.map(function (item) {
    return { id: item.name, value: item.name };
  });

  const nacionalidades = [
    { id: 1, value: 'Brasileiro' },
    { id: 2, value: 'Naturalizado' },
    { id: 3, value: 'Estrangeiro' },
  ];

  const validateDate = {
    dataNascimento: () => {
      if (dataNascimentoValida) {
        var [day, month, year] = dataNascimento.split('/');

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_dataNascimento > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataEmissaoIdentidade: () => {
      if (identidadeEmissaoValida) {
        var [day, month, year] = identidadeEmissao.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (_identidadeEmissao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataNaturalizacao: () => {
      if (dataNaturalizacaoValida && dataNaturalizacao !== '') {
        var [day, month, year] = dataNaturalizacao.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (_identidadeEmissao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataEmissaoPassaporte: () => {
      if (passaporteDataEmissaoValida) {
        var [day, month, year] = passaporteDataEmissao.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          if (passaporteDataEmissao === '') {
            return 'Campo obrigatório.';
          }

          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataValidadePassaporte: () => {
      if (passaporteDataValidadeValida) {
        var [day, month, year] = passaporteDataValidade.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          if (passaporteDataValidade === '') {
            return 'Campo obrigatório.';
          }

          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataUltimaMenstruacao: () => {
      if (dtUltimaMenstruacaoValida) {
        var [day, month, year] = dtUltimaMenstruacao.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (_identidadeEmissao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return 'Campo obrigatório.';
      }
    },
    dataUltimoAborto: () => {
      if (dtUltimoAborto || dtUltimoAborto != '') {
        var [day, month, year] = dtUltimoAborto.split('/');

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split('/');

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return 'Data não pode ser menor que a data de nascimento.';
        }

        if (_identidadeEmissao > new Date()) {
          return 'Data não pode ser maior que a data atual.';
        }

        if (day && month && year) {
          return undefined;
        } else {
          return 'Data inválida.';
        }
      } else {
        return undefined;
      }
    },
  };

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function novoTelefone() {
    delete error.telefones;

    var new_qtd_telefones = [...qtdTelefones, 1];

    setQtdTelefones(new_qtd_telefones);
    setTelefones([
      ...telefones,
      { id: new_qtd_telefones.length, phone: '', name: '' },
    ]);
  }

  function novoCns() {
    delete error.cns;

    var new_qtd_cns = [...qtdCns, 1];

    setQtdCns(new_qtd_cns);
    setCns([...cns, null]);
  }

  function getValidators() {
    var validators = {};

    if (!dadosIncompletos) {
      validators = {
        // Dados pessoais
        cpf: validaCPF,
        email: validaEmail,
        nome: requiredTwoName,
        nomeSocial: requiredOptionalTwoName,
        dataNascimento: validateDate.dataNascimento,
        nacionalidade: required,
      };

      if (step === 1) {
        // Telefones
        validators.telefones = validaTel;
      }

      if (step === 2) {
        // Endereço
        if (nacionalidade != 3) {
          validators.cep = validaCEP;
          validators.numero = required;
        }

        if (nacionalidade == 3) {
          validators.paisNascimento = required;
        }
      }

      if (step3 === true) {
        // Carteira Nacional de saúde (CNS)
        validators.cns = validaLoteCNS;
      }

      if (step4 === true) {
        // Dados demográficos
        validators.raca = required;
        validators.sexo = required;
        validators.genero = required;
        validators.orientacaoSexual = required;

        // Dados complementares
        if (!paiDesconhecido) {
          validators.nomePai = requiredTwoName;
        }
      }

      if (!maeDesconhecida) {
        validators.nomeMae = requiredTwoName;
      }

      if (nacionalidade == 1) {
        // Identidade

        validators.identidadeNumero = validaRG;
        validators.identidadeEmissao = validateDate.dataEmissaoIdentidade;
        validators.identidadeOrgaoEmissor = required;
        validators.identidadeUF = required;

        // Dados complementares
        validators.numeroPis = validaPIS;
      } else if (nacionalidade == 2) {
        // Identidade - Naturalizado
        validators.dataNaturalizacao = validateDate.dataNaturalizacao;
        validators.paisNascimento = required;
      } else if (nacionalidade == 3) {
        // Identidade - Estrangeiro
        validators.passaporteNumero = required;
        validators.passaportePaisEmissor = required;
        validators.passaporteDataEmissao = validateDate.dataEmissaoPassaporte;
        validators.passaporteDataValidade = validateDate.dataValidadePassaporte;
        // validators.passaportePaisNascimento = required;
      }

      if (raca == 5) {
        validators.etnia = required;
      }

      if (temDeficiencia) {
        validators.deficiencia = required;
      }

      if (sexo == 'Feminino' && idade >= 15) {
        validators.qtdVezesGravidez = required;
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;
      }

      if (qtdVezesGravidez > 0) {
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;
        validators.filhosVivos = required;

        if (teveAborto) {
          validators.qtdVezesAborto = required;
          validators.dtUltimoAborto = validateDate.dataUltimoAborto;
        }
      }

      if (filhosVivos || qtdVezesAborto) {
        var msgErro;

        if (filhosVivos > qtdVezesGravidez) {
          msgErro =
            'Quantidade de filhos vivos não pode ser superior que a de gravidez';
        } else if (teveAborto && qtdVezesAborto > qtdVezesGravidez) {
          msgErro =
            'Quantidade de abortos não pode ser superior que a de gravidez';
        } else if (
          teveAborto &&
          parseInt(filhosVivos) + parseInt(qtdVezesAborto) > qtdVezesGravidez
        ) {
          msgErro =
            'Quantidade de filhos vivos e de abortos não condizem com a quantidade de gravidez';
        }

        function validaQtdFilhos() {
          return msgErro;
        }

        validators.qtdVezesGravidez = validaQtdFilhos;
      }
    } else {
      // Dados incompletos

      // Dados pessoais
      validators.sexo = required;
      validators.justificativaDadoIncompleto = required;
      validators.anoEstimadoDeNascimento = (valor) => {
        if (valor == '' || valor === undefined || !valor) {
          return 'Campo obrigatório';
        }

        if (Number(valor) < 1900 || Number(valor) > new Date().getFullYear()) {
          return Number(valor) < 1900
            ? 'Data não pode ser menor que 1900.'
            : 'Data não pode ser maior que a data atual.';
        }

        return valor.length < 1 ? 'Campo obrigatório' : undefined;
      };

      if (step === 2) {
        // Endereço

        if (nacionalidade == 3) {
          validators.paisNascimento = required;
        } else {
          validators.cep = validaCEP;
          validators.numero = required;
        }
      }
    }

    return validators;
  }

  function getDados() {
    var dados = {
      // Dados pessoais
      cpf,
      email,
      nome,
      nomeSocial,
      dataNascimento,
      nacionalidade,

      // Identidade
      identidadeNumero,
      identidadeEmissao,
      identidadeOrgaoEmissor,
      identidadeUF,
      nomeMae,
      maeDesconhecida,

      // Identidade - Naturalizado
      dataNaturalizacao,
      paisNascimento,

      // Identidade - Estrangeiro
      passaporteNumero,
      passaportePaisEmissor,
      passaporteDataEmissao,
      passaporteDataValidade,
      passaportePaisNascimento,

      // Carteira Nacional de saúde (CNS)
      cns,
      cnsPrincipal,

      // Dados demográficos
      raca,
      etnia,
      sexo,
      genero,
      orientacaoSexual,

      // Gravidez
      qtdVezesGravidez,
      filhosVivos,
      dtUltimaMenstruacao,
      teveAborto,
      qtdVezesAborto,
      dtUltimoAborto,

      // Dados complementares
      numeroPis,
      seriePis,
      ufPis,
      temDeficiencia,
      deficiencia,
      populacaoEspec,
      nomePai,
      paiDesconhecido,

      // Telefones
      telefones,

      // Unidades
      unidadeRef,
      unidadeVinculada,

      // Endereço
      cep,
      endereco,
      numero,
      complemento,
      bairro,
      estado,
      municipio,
      idMunicipio,

      //Dados Incompletos
      justificativaDadoIncompleto,
      dadosIncompletos,
      anoEstimadoDeNascimento,
    };

    if (nacionalidade == 1) {
      dados.passaporteNumero = 'null';
      dados.passaportePaisEmissor = 'null';
      dados.passaporteDataEmissao = 'null';
      dados.passaporteDataValidade = 'null';
      dados.passaportePaisNascimento = 'null';
      dados.dataNaturalizacao = 'null';
      // dados.paisNascimento = 'null'
    }

    if (nacionalidade == 2) {
      dados.identidadeNumero = 'null';
      dados.identidadeEmissao = 'null';
      dados.identidadeOrgaoEmissor = 'null';
      dados.identidadeUF = 'null';
      dados.passaporteNumero = 'null';
      dados.passaportePaisEmissor = 'null';
      dados.passaporteDataEmissao = 'null';
      dados.passaporteDataValidade = 'null';
      dados.passaportePaisNascimento = 'null';
    }

    if (nacionalidade == 3) {
      dados.identidadeNumero = 'null';
      dados.identidadeEmissao = 'null';
      dados.identidadeOrgaoEmissor = 'null';
      dados.identidadeUF = 'null';
      dados.numeroPis = 'null';
      dados.seriePis = 'null';
      dados.ufPis = 'null';
      dados.dataNaturalizacao = 'null';
      // dados.paisNascimento = 'null'
    }

    if (maeDesconhecida) {
      dados.nomeMae = 'null';
    }

    if (paiDesconhecido) {
      dados.nomePai = 'null';
    }

    console.log(dados);

    return dados;
  }

  useEffect(() => {
    if (Object.keys(error).length) {
      if (Object.keys(error)[0] == 'nomePai') {
        setExpandMore(true);

        if (Object.keys(error).hasOwnProperty('scrollIntoView')) {
          setTimeout(function () {
            document.getElementById(Object.keys(error)[0]).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }, 1000);
        }
      } else {
        console.log(Object.keys(error)[0]);
        document.getElementById(Object.keys(error)[0]).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }, [error]);

  function onStep(step) {
    setStep(step);
  }

  function formatDate(date) {
    if (date && date != '') {
      var day = date.substring(0, 2);
      var month = date.substring(3, 5);
      var year = date.substring(6);

      console.log(day, month, year);

      return [year, month, day].join('-');
    } else {
      return '';
    }
  }

  function removeFormat(value) {
    return value.replace(/[\s.-]*/gim, '');
  }

  function criarPaciente(dados) {
    var documents = [];
    var deficiencies = [];
    var contacts = [];
    var units = [];
    var pregnancy = {};

    dados.cns.map((res) => {
      documents.push({
        type: 'CNS',
        fields: [
          {
            id: 9,
            value: res,
          },
          {
            id: 61,
            value: res === dados.cns[dados.cnsPrincipal],
          },
        ],
      });
    });

    if (dados.nacionalidade == 3) {
      documents.push({
        type: 'PASSPORT',
        fields: [
          {
            id: 5,
            value: dados.passaporteNumero,
          },
          {
            id: 6,
            value: formatDate(dados.passaporteDataEmissao),
          },
          {
            id: 7,
            value: dados.passaportePaisEmissor,
          },
          {
            id: 8,
            value: formatDate(dados.passaporteDataValidade),
          },
        ],
      });

      documents.push({
        type: 'CPF',
        fields: [
          {
            id: 1,
            value: dados.cpf,
          },
        ],
      });
    } else {
      documents.push({
        type: 'CPF',
        fields: [
          {
            id: 1,
            value: dados.cpf,
          },
        ],
      });

      if (dados.nacionalidade == 1) {
        documents.push(
          {
            type: 'RG',
            fields: [
              {
                id: 2,
                value: dados.identidadeNumero,
              },
              {
                id: 3,
                value: formatDate(dados.identidadeEmissao),
              },
              {
                id: 4,
                value: dados.identidadeOrgaoEmissor,
              },
              {
                id: 60,
                value: dados.identidadeUF,
              },
            ],
          },
          {
            type: 'PIS',
            fields: [
              {
                id: 57,
                value: dados.numeroPis,
              },
              {
                id: 58,
                value: dados.seriePis,
              },
              {
                id: 59,
                value: dados.ufPis,
              },
            ],
          },
        );
      }

      if (dados.nacionalidade == 2) {
        documents.push({
          type: 'NATURALIZED',
          fields: [
            {
              id: 15,
              value: formatDate(dados.dataNaturalizacao),
            },
            {
              id: 16,
              value: dados.paisNascimento,
            },
          ],
        });
      }
    }

    if (dados.sexo == 'Feminino') {
      pregnancy = {
        quantity: dados.qtdVezesGravidez,
        born_alive: dados.filhosVivos,
        last_mestruation_date: formatDate(dados.dtUltimaMenstruacao),
        abortion: dados.teveAborto,
        quantity_abortion: dados.qtdVezesAborto,
        last_abortion_date:
          formatDate(dados.dtUltimoAborto) ||
          new Date().toISOString().split('T')[0],
      };
    }

    if (dados.deficiencia.length) {
      dados.deficiencia.map((item) => {
        deficiencies.push({ value: item.value });
      });
    }

    dados.telefones.map((item) => {
      contacts.push({
        name: item.name,
        phone: item.phone,
        type: null,
        observation: null,
      });
    });

    dados.unidadeVinculada.map((item) => {
      units.push({ medical_unit_id: item.id });
    });

    var dadosPost = {
      patient: {
        email: dados.email,
        full_name: dados.nome,
        social_name: dados.nomeSocial,
        nationality: dados.nacionalidade,
        birth_date: formatDate(dados.dataNascimento),
        biological_sex: dados.sexo,
        profession: null,
        marital_status: null,
        etnia_id: dados.etnia,
        sexual_orientation_id: dados.orientacaoSexual,
        breed_id: dados.raca,
        genre_id: dados.genero,
        specific_population_id: dados.populacaoEspec
          ? dados.populacaoEspec
          : null,
        relationship_degree_id: null,
        medical_unit_id: dados.unidadeRef ? dados.unidadeRef : null,
        father_name: dados.paiDesconhecido ? null : dados.nomePai,
        mother_name: dados.maeDesconhecida ? null : dados.nomeMae,
        father_know: !dados.paiDesconhecido,
        mother_know: !dados.maeDesconhecida,
        incomplete_data: dadosIncompletos,
        incomplete_data_reason: justificativaDadoIncompleto
          ? justificativaDadoIncompleto
          : null,
        birth_year: dadosIncompletos ? anoEstimadoDeNascimento : null,
      },
      patientAddress: {
        zip_code: removeFormat(dados.cep),
        address_name: dados.endereco,
        street: dados.endereco,
        number: dados.numero,
        complement: dados.complemento,
        district: dados.bairro,
        city_id: dados.idMunicipio,
        country_id: dados.paisNascimento,
        address_type_id: 1,
      },
      patientPregnancy: pregnancy,
      patientDeficiencies: deficiencies,
      patientContact: contacts,
      patientDocument: documents,
      patientHasGroup: [],
      patientHasMedicalUnit: units,
    };

    if (dados.nacionalidade == 1) {
      dadosPost.patientAddress.country_id = 45;
    }

    if (dados.nacionalidade == 3) {
      dadosPost.patientAddress.country_id = paisNascimento;
    }

    setLoading(true);

    createPatient(localStorage.getItem('token'), dadosPost).then((res) => {
      if (res.status) {
        setCadastrado(true);
      } else {
        setLoading(false);

        if (res.details) {
          var errors = {};

          Object.entries(res.details).forEach(function (item, i) {
            toast.error(
              'Erro, não foi possível cadastrar o paciente: ' + item[1],
            );
          });
        } else {
          toast.error('Erro, não foi possível cadastrar o paciente.');
          console.log(res);
        }
      }
    });
  }

  function applyDrag(arr, dragResult) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
  }

  function removeAccents(text) {
    const accentsMap = new Map([
      ['A', 'Á|À|Ã|Â|Ä'],
      ['a', 'á|à|ã|â|ä'],
      ['E', 'É|È|Ê|Ë'],
      ['e', 'é|è|ê|ë'],
      ['I', 'Í|Ì|Î|Ï'],
      ['i', 'í|ì|î|ï'],
      ['O', 'Ó|Ò|Ô|Õ|Ö'],
      ['o', 'ó|ò|ô|õ|ö'],
      ['U', 'Ú|Ù|Û|Ü'],
      ['u', 'ú|ù|û|ü'],
      ['C', 'Ç'],
      ['c', 'ç'],
      ['N', 'Ñ'],
      ['n', 'ñ'],
    ]);

    const reducer = (acc, [key]) =>
      acc.replace(new RegExp(accentsMap.get(key), 'g'), key);

    return [...accentsMap].reduce(reducer, text);
  }

  useEffect(() => {
    if (step !== 0) {
      setStep(0);
      toast.info('Revise os campos obrigatórios.');
    }

    if (!dadosIncompletos) {
      setJustificativaDadoIncompleto('');
      setAnoEstimadoDeNascimento('');
    }
  }, [dadosIncompletos]);

  useEffect(() => {
    // Caso não seja estrangeiro, autopreenche endereço com endereço da unidade no caso de dados incompletos, do contrário os apaga.
    if (dadosIncompletos) {
      if (activeMedicalUnit && activeMedicalUnit.address) {
        const { number, zip_code } = activeMedicalUnit.address;

        if (nacionalidade == 3) {
          setCep('');
          setNumero('');
          setEndereco('');
          setMunicipio('');
          setEstado('');
          setBairro('');
        } else {
          setCep(mascaraCEP(zip_code));
          setNumero(number);
        }
      }
    }
  }, [nacionalidade, dadosIncompletos]);

  useEffect(() => {
    if (!cep || !cep.length) return;

    var new_cep = cep.replace(/[^0-9]/gm, '');

    if (new_cep.length == 8) {
      getCep(new_cep)
        .then((res) => {
          if (res.erro) {
            var new_erro = {};
            new_erro.cep = 'CEP inexistente';
            setErrors(new_erro);

            setBairro('');
            setEndereco('');
            setEstado('');
            setMunicipio('');
            setIdMunicipio('');
          } else {
            var municipioSelec = citys.filter(
              (city) =>
                city.value == removeAccents(res.localidade.toUpperCase()),
            )[0];

            if (municipioSelec.id) {
              setBairro(res.bairro);
              setEndereco(res.logradouro);
              setEstado(res.uf);
              setMunicipio(res.localidade);
              setIdMunicipio(municipioSelec.id);
            }
          }
        })
        .catch(console.error);
    }
  }, [cep]);

  return (
    <>
      <Dialog
        disableBackdropClick={true}
        aria-labelledby="medical-unity-create-title"
        scroll="body"
        onClose={(e) => onClose(true)}
        open={open}
      >
        <form autoComplete="off">
          <DialogTitle
            id="medical-unity-create-title"
            classes={{ root: classes.title }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  variant="h2"
                  className="text-mt text-regular text-primary"
                >
                  Cadastrar paciente
                </Typography>
              </Grid>
              <Grid item>
                <Close
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => props.onClose(true)}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          {load1 && load2 && load3 && load4 ? (
            <DialogContent classes={{ root: classes.content }}>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                className="tema-light"
              >
                <Grid item md={12}>
                  <StepLine step={step} />
                  <hr />
                </Grid>
              </Grid>

              <Grid
                container
                className={`tema-light ${classes.root} ${step !== 0 ? classes.hide : ''}`}
                spacing={3}
              >
                <Grid item md={6}>
                  <DInput
                    id="cpf"
                    label="CPF"
                    placeholder="CPF"
                    labelColor="black"
                    // required={true}
                    required={!dadosIncompletos}
                    type="text"
                    error={error.cpf}
                    helperText={error.cpf}
                    positionIcon="end"
                    fullWidth={true}
                    onChange={(e) => {
                      delete error.cpf;
                      setCPF(mascaraCPF(e.target.value));
                    }}
                    value={cpf}
                  />
                </Grid>
                <Grid item md={6}>
                  <DInput
                    id="email"
                    label="E-mail"
                    placeholder="E-mail"
                    labelColor="black"
                    // required={true}
                    inputProps={{ maxLength: 254 }}
                    required={!dadosIncompletos}
                    type="text"
                    error={error.email}
                    helperText={error.email}
                    positionIcon="end"
                    fullWidth
                    onChange={(e) => {
                      delete error.email;

                      if (e.target.value.length <= 254) {
                        setEmail(e.target.value);
                        setStep1(true);
                      }
                    }}
                    value={email}
                  />
                </Grid>
                <Grid item md={12} className={!step1 ? classes.hide : ''}>
                  <DInput
                    id="nome"
                    label="Nome completo"
                    placeholder="Nome completo"
                    labelColor="black"
                    // required={true}
                    required={!dadosIncompletos}
                    type="text"
                    inputProps={{ maxLength: 100 }}
                    positionIcon="end"
                    error={error.nome}
                    helperText={error.nome}
                    fullWidth
                    onChange={(e) => {
                      delete error.nome;
                      var _value = e.target.value.replace(
                        /[^a-zA-Zà-úÀ-Ú"' ]/g,
                        '',
                      );

                      setNome(_value);
                    }}
                    value={nome}
                  />
                </Grid>
                <Grid item md={12} className={!step1 ? classes.hide : ''}>
                  <DInput
                    id="nomeSocial"
                    label="Nome social (opcional)"
                    inputProps={{ maxLength: 100 }}
                    placeholder="Nome social"
                    labelColor="black"
                    type="text"
                    positionIcon="end"
                    fullWidth
                    error={error.nomeSocial}
                    helperText={error.nomeSocial}
                    onChange={(e) => {
                      var _value = e.target.value.replace(
                        /[^a-zA-Zà-úÀ-Ú"' ]/g,
                        '',
                      );

                      setNomeSocial(_value);
                    }}
                    value={nomeSocial}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  className={dadosIncompletos || !step1 ? classes.hide : ''}
                >
                  <DDateInput
                    id="dataNascimento"
                    error={error.dataNascimento}
                    positionIcon="end"
                    helperText={error.dataNascimento}
                    fullWidth
                    label={
                      <>
                        {
                          <span style={{ color: 'black' }}>
                            Data de nascimento
                          </span>
                        }{' '}
                        <span style={{ color: 'red' }}>*</span>{' '}
                      </>
                    }
                    maxDate={new Date()}
                    maxDateMessage="A data de nascimento não pode ser maior que a data atual"
                    value={dataNascimento}
                    onError={(errorMessage) => {
                      setErrors({ ...error, dataNascimento: errorMessage });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.dataNascimento;

                      var anoAtual = new Date().getFullYear();
                      var anoNasc = arg2.year;

                      setIdade(anoAtual - anoNasc);
                      setDataNascimento(arg1);
                      setDataNascimentoValida(arg2.isValid);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  className={!dadosIncompletos || !step1 ? classes.hide : ''}
                >
                  <DInput
                    id="anoEstimadoDeNascimento"
                    error={error.anoEstimadoDeNascimento}
                    helperText={error.anoEstimadoDeNascimento}
                    label={'Ano estimado de nascimento'}
                    labelColor="black"
                    // required={true}
                    inputProps={{ maxLength: 4 }}
                    required={dadosIncompletos}
                    type="text"
                    positionIcon="end"
                    fullWidth
                    onChange={(e) => {
                      delete error.anoEstimadoDeNascimento;

                      if (e.target.value.length === 4) {
                        if (Number(e.target.value) > new Date().getFullYear()) {
                          setErrors({
                            ...error,
                            anoEstimadoDeNascimento:
                              'O ano estimado de nascimento não pode ser maior que o ano atual',
                          });
                        }

                        if (Number(e.target.value) < 1900) {
                          setErrors({
                            ...error,
                            anoEstimadoDeNascimento:
                              'O ano estimado de nascimento não pode ser menor que 1900',
                          });
                        }
                      }

                      setAnoEstimadoDeNascimento(
                        e.target.value.replace(/[^0-9]/g, ''),
                      );
                    }}
                    value={anoEstimadoDeNascimento}
                  />
                </Grid>
                <Grid item md={6} className={!step1 ? classes.hide : ''}>
                  <DDropDownSimple
                    id="nacionalidade"
                    label="Nacionalidade"
                    placeholder="Nacionalidade"
                    fullWidth
                    labelColor="black"
                    error={error.nacionalidade}
                    helperText={error.nacionalidade}
                    labelClass={classes.labelBlack}
                    value={nacionalidade}
                    options={nacionalidades}
                    onChange={(event) => {
                      delete error.nacionalidade;
                      setStep2(true);
                      setNacionalidade(event);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  className={!dadosIncompletos ? classes.hide : ''}
                >
                  <hr className={classes.linha} />
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Justificar cadastro incompleto
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={!dadosIncompletos ? classes.hide : ''}
                >
                  <DAutoComplete
                    id="justificativaDadoIncompleto"
                    label="Motivo"
                    placeholder="Motivo"
                    labelColor="black"
                    // required={true}
                    required={dadosIncompletos}
                    fullWidth={true}
                    error={error.justificativaDadoIncompleto}
                    helperText={error.justificativaDadoIncompleto}
                    defaultValue={
                      justificativaDadosIncompletosMap.filter(
                        (oe) => oe.value == justificativaDadoIncompleto,
                      )[0]
                    }
                    options={justificativaDadosIncompletosMap}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      value && option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      delete error.justificativaDadoIncompleto;
                      setJustificativaDadoIncompleto(
                        newValue ? newValue.id : '',
                      );
                    }}
                  />
                </Grid>
                <Grid item md={12} className={!step2 ? classes.hide : ''}>
                  <hr className={classes.linha} />
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Identidade
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={
                    !dadosIncompletos &&
                    (nacionalidade != 1 ? classes.hide : '')
                  }
                >
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DInput
                        id="identidadeNumero"
                        label="Número de identidade"
                        placeholder="Número de identidade"
                        labelColor="black"
                        // required={true}
                        required={!dadosIncompletos}
                        type="text"
                        error={error.identidadeNumero}
                        positionIcon="end"
                        helperText={error.identidadeNumero}
                        fullWidth={true}
                        value={identidadeNumero}
                        onChange={(e) => {
                          delete error.identidadeNumero;
                          setIdentidadeNumero(mascaraRG(e.target.value));
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DDateInput
                        id="identidadeEmissao"
                        error={error.identidadeEmissao}
                        helperText={error.identidadeEmissao}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data de emissão
                              </span>
                            }{' '}
                            {!dadosIncompletos && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                        maxDate={new Date()}
                        maxDateMessage="A data de emissão não pode ser maior que a data atual"
                        value={identidadeEmissao}
                        onError={(errorMessage) => {
                          setErrors({
                            ...error,
                            identidadeEmissao: errorMessage,
                          });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.identidadeEmissao;

                          setIdentidadeEmissao(arg1);
                          setIdentidadeEmissaoValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                    <Grid item md={9}>
                      <DAutoComplete
                        id="identidadeOrgaoEmissor"
                        label="Órgão emissor"
                        placeholder="Órgão emissor"
                        labelColor="black"
                        // required={true}
                        required={!dadosIncompletos}
                        fullWidth={true}
                        error={error.identidadeOrgaoEmissor}
                        helperText={error.identidadeOrgaoEmissor}
                        defaultValue={
                          orgaoEmissorMap.filter(
                            (oe) => oe.value == identidadeOrgaoEmissor,
                          )[0]
                        }
                        options={orgaoEmissorMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.identidadeOrgaoEmissor;
                          setIdentidadeOrgaoEmissor(
                            newValue ? newValue.id : '',
                          );
                        }}
                      />
                    </Grid>
                    {states.length > 0 && (
                      <Grid item md={3}>
                        <DAutoComplete
                          id="identidadeUF"
                          label="UF"
                          placeholder="Estado"
                          labelColor="black"
                          // required={true}
                          required={!dadosIncompletos}
                          fullWidth={true}
                          error={error.identidadeUF}
                          helperText={error.identidadeUF}
                          defaultValue={
                            states &&
                            states.length &&
                            states.filter((oe) => oe.id == identidadeUF)[0]
                          }
                          options={states}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.identidadeUF;
                            setIdentidadeUF(newValue ? newValue.id : '');
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={nacionalidade != 2 ? classes.hide : ''}
                >
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DDateInput
                        id="dataNaturalizacao"
                        error={error.dataNaturalizacao}
                        helperText={error.dataNaturalizacao}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data de naturalização
                              </span>
                            }{' '}
                            {!dadosIncompletos && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data de naturalização não pode ser menor que a data de nascimento"
                        maxDate={new Date()}
                        maxDateMessage="A data de naturalização não pode ser maior que a data atual"
                        value={dataNaturalizacao}
                        onError={(errorMessage) => {
                          setErrors({
                            ...error,
                            dataNaturalizacao: errorMessage,
                          });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.dataNaturalizacao;

                          setDataNaturalizacao(arg1);
                          setDataNaturalizacaoValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="paisNascimento"
                        label="País de nascimento"
                        placeholder="País de nascimento"
                        labelColor="black"
                        // required={true}
                        required={!dadosIncompletos}
                        fullWidth
                        error={error.paisNascimento}
                        helperText={error.paisNascimento}
                        defaultValue={
                          countrys &&
                          countrys.length &&
                          countrys.filter((res) => res.id == paisNascimento)[0]
                        }
                        options={countrys}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.paisNascimento;
                          setPaisNascimento(newValue ? newValue.id : '');
                          setStep3(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={nacionalidade != 3 ? classes.hide : ''}
                >
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DInput
                        id="passaporteNumero"
                        label="Número do passaporte"
                        placeholder="Número do passaporte"
                        labelColor="black"
                        required={!dadosIncompletos}
                        // required={true}
                        type="text"
                        error={error.passaporteNumero}
                        helperText={error.passaporteNumero}
                        positionIcon="end"
                        fullWidth={true}
                        inputProps={{ maxLength: 9 }}
                        onChange={(e) => {
                          delete error.passaporteNumero;
                          setPassaporteNumero(
                            e.target.value.replace(/[^0-9a-zA-Z]/g, ''),
                          );
                        }}
                        value={passaporteNumero}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="passaportePaisEmissor"
                        label="País emissor do passaporte"
                        placeholder="País emissor do passaporte"
                        labelColor="black"
                        required={!dadosIncompletos}
                        // required={true}
                        fullWidth={true}
                        error={error.passaportePaisEmissor}
                        helperText={error.passaportePaisEmissor}
                        defaultValue={
                          countrys.length &&
                          countrys.filter(
                            (res) => res.id == passaportePaisEmissor,
                          )[0]
                        }
                        options={countrys}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.passaportePaisEmissor;
                          setPassaportePaisEmissor(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DDateInput
                        id="passaporteDataEmissao"
                        error={error.passaporteDataEmissao}
                        helperText={error.passaporteDataEmissao}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data de emissão do passaporte
                              </span>
                            }{' '}
                            {!dadosIncompletos && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                        maxDate={new Date()}
                        maxDateMessage="A data de emissão não pode ser maior que a data atual"
                        value={passaporteDataEmissao}
                        onError={(errorMessage) => {
                          setErrors({
                            ...error,
                            passaporteDataEmissao: errorMessage,
                          });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.passaporteDataEmissao;

                          setPassaporteDataEmissao(arg1);
                          setPassaporteDataEmissaoValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DDateInput
                        id="passaporteDataValidade"
                        error={error.passaporteDataValidade}
                        helperText={error.passaporteDataValidade}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data de validade do passaporte
                              </span>
                            }{' '}
                            {!dadosIncompletos && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data de validade não pode ser menor que a data de nascimento"
                        value={passaporteDataValidade}
                        onError={(errorMessage) => {
                          setErrors({
                            ...error,
                            passaporteDataValidade: errorMessage,
                          });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.passaporteDataValidade;

                          setPassaporteDataValidade(arg1);
                          setPassaporteDataValidadeValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={!nacionalidade ? classes.hide : ''}
                >
                  <DInput
                    id="nomeMae"
                    label="Nome da mãe"
                    placeholder="Nome da mãe"
                    labelColor="black"
                    type="text"
                    disabled={maeDesconhecida}
                    required={!dadosIncompletos && !maeDesconhecida}
                    inputProps={{ maxLength: 100 }}
                    error={error.nomeMae}
                    helperText={error.nomeMae}
                    positionIcon="end"
                    fullWidth={true}
                    onChange={(e) => {
                      if (e.target.value !== undefined) setStep3(true);

                      delete error.nomeMae;
                      setNomeMae(e.target.value);
                    }}
                    value={nomeMae}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={maeDesconhecida}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={(e) => {
                          setStep3(true);
                          setMaeDesconhecida(!maeDesconhecida);

                          if (!maeDesconhecida) {
                            delete error.nomeMae;
                            setNomeMae('');
                          }
                        }}
                      />
                    }
                    label="Mãe desconhecida"
                  />
                </Grid>
                <Grid item md={12} className={!step3 ? classes.hide : ''}>
                  <hr className={classes.linha} />
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Carteira Nacional de Saúde
                  </Typography>
                </Grid>
                <Grid item md={12} className={!step3 ? classes.hide : ''}>
                  {qtdCns.length
                    ? qtdCns.map(function (item, i) {
                        return (
                          <Card
                            key={`cns-component-${i}`}
                            className="bg-light-gray mb-3"
                          >
                            <CardContent
                              style={
                                qtdCns.length === 1
                                  ? {}
                                  : { padding: '15px 0px 0px 15px' }
                              }
                            >
                              <Grid container spacing={3}>
                                {qtdCns.length === 1 ? (
                                  <>
                                    <Grid item md={12}>
                                      <DInput
                                        id="cns"
                                        label="CNS"
                                        placeholder="CNS"
                                        labelColor="black"
                                        inputProps={{ maxLength: 15 }}
                                        // required={true}
                                        required={!dadosIncompletos}
                                        type="text"
                                        error={error.cns && error.cns[i]}
                                        helperText={error.cns && error.cns[i]}
                                        fullWidth={true}
                                        onChange={(e) => {
                                          error.cns && delete error.cns[i];

                                          if (e.target.value !== undefined) {
                                            setStep4(true);
                                          }

                                          var new_cns = [...cns];

                                          var cns_regex =
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              '',
                                            );

                                          new_cns[i] = cns_regex;
                                          setCns(new_cns);
                                        }}
                                        positionIcon="end"
                                        value={cns[i]}
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item md={5}>
                                      <DInput
                                        id="cns"
                                        label="CNS"
                                        placeholder="CNS"
                                        labelColor="black"
                                        // required={true}
                                        required={!dadosIncompletos}
                                        type="text"
                                        inputProps={{ maxLength: 15 }}
                                        error={error.cns && error.cns[i]}
                                        helperText={error.cns && error.cns[i]}
                                        fullWidth={true}
                                        onChange={(e) => {
                                          error.cns && delete error.cns[i];

                                          if (e.target.value !== undefined) {
                                            setStep4(true);
                                          }

                                          var new_cns = [...cns];

                                          var cns_regex =
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              '',
                                            );

                                          new_cns[i] = cns_regex;
                                          setCns(new_cns);
                                        }}
                                        positionIcon="end"
                                        value={cns[i]}
                                      />
                                    </Grid>
                                    <Grid item md={6}>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            value={i}
                                            name="cnsPrincipal"
                                            color="primary"
                                            checked={
                                              cnsPrincipal == i ? true : false
                                            }
                                            onChange={(e) => {
                                              setCnsPrincipal(i);
                                            }}
                                            positionIcon="end"
                                          />
                                        }
                                        label="Definir como principal"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={1}
                                      style={{
                                        margin: '15px 0px',
                                        padding: '0px',
                                      }}
                                    >
                                      <Button
                                        style={{
                                          height: '70%',
                                          width: '100%',
                                          padding: 0,
                                          justifyContent: 'unset',
                                        }}
                                        onClick={(e) => {
                                          delete error.cns;

                                          var new_cns = [...cns];
                                          var new_qtd_cns = [...qtdCns];

                                          new_cns.splice(i, 1);
                                          new_qtd_cns.splice(i, 1);

                                          setCns(new_cns);
                                          setQtdCns(new_qtd_cns);

                                          if (cnsPrincipal == i) {
                                            setCnsPrincipal(0);
                                          }
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          color="error"
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                          }}
                                          viewBox="0 -15 55 55"
                                        />
                                      </Button>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </CardContent>
                          </Card>
                        );
                      })
                    : ''}
                  <DButton
                    className={classes.marginDefault}
                    type="button"
                    variant="primary"
                    size="sm"
                    icon={<AddCircleIcon />}
                    onClick={novoCns}
                  >
                    Adicionar novo CNS
                  </DButton>
                </Grid>
                <Grid item md={12} className={!step4 ? classes.hide : ''}>
                  <hr className={classes.linha} />
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Dados demográficos
                  </Typography>
                </Grid>
                <Grid item md={12} className={!step4 ? classes.hide : ''}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="raca"
                        label="Raça"
                        placeholder="Raça"
                        labelColor="black"
                        // required={true}
                        required={!dadosIncompletos}
                        fullWidth
                        error={error.raca}
                        helperText={error.raca}
                        defaultValue={
                          racasMap.filter((res) => res.id == raca)[0]
                        }
                        options={racasMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.raca;
                          delete error.etnia;

                          setRaca(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="etnia"
                        label="Etnia"
                        placeholder="Etnia"
                        labelColor="black"
                        fullWidth
                        required={!dadosIncompletos && raca == 5}
                        disabled={raca != 5}
                        error={error.etnia}
                        helperText={error.etnia}
                        defaultValue={
                          etnias.length &&
                          etnias.filter((res) => res.id == etnia)[0]
                        }
                        options={etnias}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.etnia;
                          setEtnia(newValue ? newValue.id : null);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="sexo"
                        label="Sexo biológico"
                        placeholder="Sexo biológico"
                        labelColor="black"
                        fullWidth={true}
                        required={true}
                        // required={ !dadosIncompletos }
                        error={error.sexo}
                        helperText={error.sexo}
                        defaultValue={
                          sexoMap.filter((res) => res.id == sexo)[0]
                        }
                        options={sexoMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.sexo;
                          setSexo(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="genero"
                        label="Gênero"
                        placeholder="Gênero"
                        labelColor="black"
                        fullWidth={true}
                        // required={true}
                        required={!dadosIncompletos}
                        error={error.genero}
                        helperText={error.genero}
                        defaultValue={
                          generosMap.filter((res) => res.id == genero)[0]
                        }
                        options={generosMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.genero;
                          setGenero(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="orientacaoSexual"
                        label="Orientação sexual"
                        placeholder="Orientação sexual"
                        labelColor="black"
                        fullWidth={true}
                        // required={true}
                        required={!dadosIncompletos}
                        error={error.orientacaoSexual}
                        helperText={error.orientacaoSexual}
                        defaultValue={
                          orientacoesSexuaisMap.filter(
                            (res) => res.id == orientacaoSexual,
                          )[0]
                        }
                        options={orientacoesSexuaisMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.orientacaoSexual;
                          setOrientacaoSexual(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={sexo != 'Feminino' ? classes.hide : ''}
                >
                  <hr className={classes.linha} />
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Gravidez
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={sexo != 'Feminino' ? classes.hide : ''}
                >
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DInput
                        id="qtdVezesGravidez"
                        label="Quantas vezes já engravidou"
                        placeholder="Quantas vezes já engravidou"
                        labelColor="black"
                        type="text"
                        required={
                          !dadosIncompletos &&
                          (sexo == 'Feminino' && idade >= 15 ? true : false)
                        }
                        error={error.qtdVezesGravidez}
                        helperText={error.qtdVezesGravidez}
                        fullWidth
                        onChange={(e) => {
                          delete error.qtdVezesGravidez;

                          if (e.target.value.length <= 2) {
                            setQtdVezesGravidez(
                              e.target.value.replace(/[^0-9]/g, ''),
                            );
                          }
                        }}
                        positionIcon="end"
                        value={qtdVezesGravidez}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DInput
                        id="filhosVivos"
                        label="Filhos nascidos vivos"
                        placeholder="Filhos nascidos vivos"
                        labelColor="black"
                        type="text"
                        error={error.filhosVivos}
                        helperText={error.filhosVivos}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.filhosVivos;

                          if (e.target.value.length <= 2) {
                            setFilhosVivos(
                              e.target.value.replace(/[^0-9]/g, ''),
                            );
                          }
                        }}
                        positionIcon="end"
                        value={filhosVivos}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DDateInput
                        id="dtUltimaMenstruacao"
                        error={error.dtUltimaMenstruacao}
                        helperText={error.dtUltimaMenstruacao}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data da última menstruação
                              </span>
                            }
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data da ultima menstruação não pode ser menor que a data de nascimento"
                        maxDate={new Date()}
                        maxDateMessage="A data da ultima menstruação não pode ser maior que a data atual"
                        value={dtUltimaMenstruacao}
                        onError={(errorMessage) => {
                          setErrors({
                            ...error,
                            dtUltimaMenstruacao: errorMessage,
                          });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.dtUltimaMenstruacao;

                          setDtUltimaMenstruacao(arg1);
                          setDtUltimaMenstruacaoValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={teveAborto}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={(e) => {
                              setTeveAborto(!teveAborto);
                            }}
                          />
                        }
                        label="Já teve algum aborto"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className={!teveAborto ? classes.hide : ''}
                    >
                      <DInput
                        id="qtdVezesAborto"
                        label="Quantas vezes já abortou"
                        placeholder="Quantas vezes já abortou"
                        labelColor="black"
                        type="text"
                        required={!dadosIncompletos && teveAborto}
                        error={error.qtdVezesAborto}
                        helperText={error.qtdVezesAborto}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.qtdVezesAborto;

                          if (e.target.value.length <= 2) {
                            setQtdVezesAborto(
                              e.target.value.replace(/[^0-9]/g, ''),
                            );
                          }
                        }}
                        positionIcon="end"
                        value={qtdVezesAborto}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className={!teveAborto ? classes.hide : ''}
                    >
                      <DDateInput
                        id="dtUltimoAborto"
                        error={error.dtUltimoAborto}
                        helperText={error.dtUltimoAborto}
                        fullWidth
                        label={
                          <>
                            {
                              <span style={{ color: 'black' }}>
                                Data do último aborto
                              </span>
                            }
                          </>
                        }
                        minDate={
                          dataNascimento
                            ? String(dataNascimento)
                                .split('/')
                                .reverse()
                                .join('-')
                            : null
                        }
                        minDateMessage="A data do ultimo abordo não pode ser menor que a data de nascimento"
                        maxDate={new Date()}
                        maxDateMessage="A data do ultimo abordo não pode ser maior que a data atual"
                        value={dtUltimoAborto}
                        onError={(errorMessage) => {
                          setErrors({ ...error, dtUltimoAborto: errorMessage });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.dtUltimoAborto;

                          setDtUltimoAborto(arg1);
                          setDtUltimoAbortoValida(arg2.isValid);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} className={!step4 ? classes.hide : ''}>
                  <Grid item md={12}>
                    <hr className={classes.linha} />
                  </Grid>
                  <span
                    onClick={(e) => setExpandMore(!expandMore)}
                    className="text-mt text-regular text-link"
                    style={
                      !expandMore
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 30,
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }
                    }
                  >
                    {!expandMore
                      ? 'Mostrar dados complementares'
                      : 'Esconder dados complementares'}
                    &nbsp;&nbsp;&nbsp;
                    {!expandMore ? <ExpandMore /> : <ExpandLess />}
                  </span>
                </Grid>
                <Grid
                  item
                  md={12}
                  className={!expandMore ? classes.hide : ''}
                  style={!expandMore ? { marginTop: 32 } : {}}
                >
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DInput
                        id="numeroPis"
                        label="Número do NIT-PIS-PASEP"
                        placeholder="Número do NIT-PIS-PASEP"
                        labelColor="black"
                        type="text"
                        error={error.numeroPis}
                        helperText={error.numeroPis}
                        positionIcon="end"
                        fullWidth={true}
                        value={numeroPis}
                        onChange={(e) => {
                          delete error.numeroPis;
                          setNumeroPis(mascaraPIS(e.target.value));
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={temDeficiencia}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={(e) => {
                              delete error.deficiencia;
                              setTemDeficiencia(!temDeficiencia);
                            }}
                          />
                        }
                        label="Pessoa com deficiência"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <DMultAutoComplete
                        id="deficiencia"
                        label="Deficiências"
                        placeholder="Deficiências"
                        labelColor="black"
                        error={error.deficiencia}
                        helperText={error.deficiencia}
                        required={temDeficiencia}
                        options={deficienciasMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.deficiencia;
                          setDeficiencia(newValue);
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <DAutoComplete
                        id="populacaoEspec"
                        label="População específica"
                        placeholder="População específica"
                        labelColor="black"
                        fullWidth={true}
                        error={error.populacaoEspec}
                        helperText={error.populacaoEspec}
                        defaultValue={
                          populacaoEspecMap.filter(
                            (oe) => oe.id == populacaoEspec,
                          )[0]
                        }
                        options={populacaoEspecMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.populacaoEspec;
                          setPopulacaoEspec(newValue ? newValue.id : '');
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <DInput
                        id="nomePai"
                        label="Nome do pai"
                        placeholder="Nome do pai"
                        labelColor="black"
                        type="text"
                        disabled={paiDesconhecido}
                        inputProps={{ maxLength: 100 }}
                        required={!dadosIncompletos && !paiDesconhecido}
                        error={error.nomePai}
                        helperText={error.nomePai}
                        positionIcon="end"
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.nomePai;
                          setNomePai(e.target.value);
                        }}
                        value={nomePai}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={paiDesconhecido}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={(e) => {
                              setPaiDesconhecido(!paiDesconhecido);

                              if (!paiDesconhecido) {
                                delete error.nomePai;
                                setNomePai('');
                              }
                            }}
                          />
                        }
                        label="Pai desconhecido"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                className={`tema-light ${classes.root} ${step !== 1 ? classes.hide : ''}`}
                spacing={3}
              >
                <Grid item md={12}>
                  <Grid item md={12}>
                    <Typography
                      className="text-primary text-bold"
                      variant="h5"
                      gutterBottom
                    >
                      Telefones
                    </Typography>
                    <Typography variant="p" gutterBottom>
                      &nbsp;
                    </Typography>
                  </Grid>

                  {/* {
                            telefones && Array.isArray(telefones) && telefones.length ? (

                                    <Container dragHandleSelector=".column-drag-handle" onDrop={e => setTelefones(applyDrag(telefones, e))}>
                                        {
                                            telefones.map((item, i) => {
                                                return (
                                                        <Draggable key={item.id}>
                                                        <Card key={ `telefones-component-${i}` } className="bg-light-gray mb-3 draggable-item">
                                                        <CardContent style={telefones.length === 1 ? {} : { padding: '15px 0px 0px 0px' }}>
                                                        <Grid container spacing={3}>
                                                        { telefones.length === 1
                                                            ?
                                                            <>
                                                            <Grid item md={6}>
                                                            <DInput
                                                            id="telefones"
                                                            label="Telefone"
                                                            placeholder="Telefone"
                                                            labelColor='black'
                                                            // required={true}
                                                            required={!dadosIncompletos}
                                                            type="text"
                                                            error={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                                            fullWidth={true}
                                                            onChange={ e => {
                                                                delete error.telefones
                                                                var new_telefone = [...telefones];
                                                                new_telefone[i].phone = mascaraNumero(e.target.value);
                                                                setTelefones(new_telefone);
                                                            } }
                                                            positionIcon="end"
                                                            value={telefones[i].phone} />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                            <DInput
                                                            id={ `apelido-telefone-${i}` }
                                                            label="Apelido do telefone"
                                                            placeholder="Apelido do telefone"
                                                            labelColor='black'
                                                            // required={true}
                                                            required={!dadosIncompletos}
                                                            type="text"
                                                            error={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].name}
                                                            fullWidth={true}
                                                            onChange={ e => {
                                                                delete error.telefones
                                                                var new_telefone = [...telefones];
                                                                new_telefone[i].name = e.target.value;
                                                                setTelefones(new_telefone);
                                                            } }
                                                            positionIcon="end"
                                                            value={telefones[i] && telefones[i].name} />
                                                            </Grid>
                                                            </>
                                                            : 
                                                            <>
                                                            <Grid item md={1} style={{ margin: '15px 0px', padding: '0px' }} className="column-drag-handle">
                                                            <DragHandleIcon style={{ width: '100%', height: '100%' }} viewBox='-15 0 40 40' />
                                                            </Grid>
                                                            <Grid item md={5}>
                                                            <DInput
                                                            id="telefones"
                                                            label="Telefone"
                                                            placeholder="Telefone"
                                                            labelColor='black'
                                                            required={true}
                                                            type="text"
                                                            error={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                                            fullWidth={true}
                                                            onChange={ e => {
                                                                delete error.telefones
                                                                var new_telefone = [...telefones];
                                                                new_telefone[i].phone = mascaraNumero(e.target.value);
                                                                setTelefones(new_telefone);
                                                            } }
                                                            positionIcon="end"
                                                            value={telefones[i].phone} />
                                                            </Grid>
                                                            <Grid item md={5}>
                                                            <DInput
                                                            id={ `apelido-telefone-${i}` }
                                                            label="Apelido do telefone"
                                                            inputProps={{maxLength: 10}}
                                                            placeholder="Apelido do telefone"
                                                            labelColor='black'
                                                            required={true}
                                                            type="text"
                                                            error={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                                            fullWidth={true}
                                                            onChange={ e => {
                                                                delete error.telefones
                                                                var new_telefone = [...telefones];
                                                                new_telefone[i].name = e.target.value;
                                                                setTelefones(new_telefone);
                                                            } }
                                                            positionIcon="end"
                                                            value={telefones && telefones[i].name} />
                                                            </Grid>
                                                            <Grid item md={1} style={{ margin: '15px 0px', padding: '0px' }}>
                                                            <Button style={{ height: '70%', width: '100%', padding: 0, justifyContent: 'unset' }} onClick={ e => { 
                                                                delete error.telefones
                                                                
                                                                var new_telefone = [...telefones];
                                                                var new_qtd_telefones = [...qtdTelefones];
                                                                
                                                                new_telefone.splice(i, 1)
                                                                new_qtd_telefones.splice(i, 1)
                                                                
                                                                setTelefones(new_telefone);
                                                                setQtdTelefones(new_qtd_telefones);
                                                            } }>
                                                            <DeleteForeverIcon color='error'  style={{ height: '100%', width: '100%'}} viewBox='0 -15 55 55' />
                                                            </Button>
                                                            </Grid>
                                                            </>
                                                        }
                                                        </Grid>
                                                        </CardContent>
                                                        </Card>
                                                        </Draggable>
                                                )
                                            })
                                        }
                                    </Container>
                            ) : ""
                        } */}
                  {/* 
                        { qtdTelefones.length 
                            ?   
                            (
                                <Container dragHandleSelector=".column-drag-handle" onDrop={e => setTelefones(applyDrag(telefones, e))}>
                                {qtdTelefones.map(function(item, i){
                                    return (
                                        <Draggable key={item.id}>
                                        <Card key={ `telefones-component-${i}` } className="bg-light-gray mb-3 draggable-item">
                                        <CardContent style={qtdTelefones.length === 1 ? {} : { padding: '15px 0px 0px 0px' }}>
                                        <Grid container spacing={3}>
                                        { qtdTelefones.length === 1
                                            ?
                                            <>
                                            <Grid item md={6}>
                                            <DInput
                                            id="telefones"
                                            label="Telefone"
                                            placeholder="Telefone"
                                            labelColor='black'
                                            // required={true}
                                            required={!dadosIncompletos}
                                            type="text"
                                            error={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                            fullWidth={true}
                                            onChange={ e => {
                                                delete error.telefones
                                                var new_telefone = [...telefones];
                                                new_telefone[i].phone = mascaraNumero(e.target.value);
                                                setTelefones(new_telefone);
                                            } }
                                            positionIcon="end"
                                            value={telefones[i].phone} />
                                            </Grid>
                                            <Grid item md={6}>
                                            <DInput
                                            id={ `apelido-telefone-${i}` }
                                            label="Apelido do telefone"
                                            placeholder="Apelido do telefone"
                                            labelColor='black'
                                            // required={true}
                                            required={!dadosIncompletos}
                                            type="text"
                                            error={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].name}
                                            fullWidth={true}
                                            onChange={ e => {
                                                delete error.telefones
                                                var new_telefone = [...telefones];
                                                new_telefone[i].name = e.target.value;
                                                setTelefones(new_telefone);
                                            } }
                                            positionIcon="end"
                                            value={telefones[i] && telefones[i].name} />
                                            </Grid>
                                            </>
                                            : 
                                            <>
                                            <Grid item md={1} style={{ margin: '15px 0px', padding: '0px' }} className="column-drag-handle">
                                            <DragHandleIcon style={{ width: '100%', height: '100%' }} viewBox='-15 0 40 40' />
                                            </Grid>
                                            <Grid item md={5}>
                                            <DInput
                                            id="telefones"
                                            label="Telefone"
                                            placeholder="Telefone"
                                            labelColor='black'
                                            required={true}
                                            type="text"
                                            error={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].phone }
                                            fullWidth={true}
                                            onChange={ e => {
                                                delete error.telefones
                                                var new_telefone = [...telefones];
                                                new_telefone[i].phone = mascaraNumero(e.target.value);
                                                setTelefones(new_telefone);
                                            } }
                                            positionIcon="end"
                                            value={telefones[i].phone} />
                                            </Grid>
                                            <Grid item md={5}>
                                            <DInput
                                            id={ `apelido-telefone-${i}` }
                                            label="Apelido do telefone"
                                            inputProps={{maxLength: 10}}
                                            placeholder="Apelido do telefone"
                                            labelColor='black'
                                            required={true}
                                            type="text"
                                            error={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                            helperText={ error.telefones && error.telefones[i] && error.telefones[i].name }
                                            fullWidth={true}
                                            onChange={ e => {
                                                delete error.telefones
                                                var new_telefone = [...telefones];
                                                new_telefone[i].name = e.target.value;
                                                setTelefones(new_telefone);
                                            } }
                                            positionIcon="end"
                                            value={telefones && telefones[i].name} />
                                            </Grid>
                                            <Grid item md={1} style={{ margin: '15px 0px', padding: '0px' }}>
                                            <Button style={{ height: '70%', width: '100%', padding: 0, justifyContent: 'unset' }} onClick={ e => { 
                                                delete error.telefones
                                                
                                                var new_telefone = [...telefones];
                                                var new_qtd_telefones = [...qtdTelefones];
                                                
                                                new_telefone.splice(i, 1)
                                                new_qtd_telefones.splice(i, 1)
                                                
                                                setTelefones(new_telefone);
                                                setQtdTelefones(new_qtd_telefones);
                                            } }>
                                            <DeleteForeverIcon color='error'  style={{ height: '100%', width: '100%'}} viewBox='0 -15 55 55' />
                                            </Button>
                                            </Grid>
                                            </>
                                        }
                                        </Grid>
                                        </CardContent>
                                        </Card>
                                        </Draggable>
                                        )
                                    })}
                                    </Container>
                                    )
                                    : 
                                    '' 
                                } */}
                  <DButton
                    className={classes.marginDefault}
                    type="button"
                    variant="primary"
                    size="sm"
                    icon={<AddCircleIcon />}
                    onClick={novoTelefone}
                  >
                    Adicionar novo telefone
                  </DButton>
                  <Grid item md={12}>
                    <hr className={classes.linha} />
                    <Typography
                      className="text-primary text-bold"
                      variant="h5"
                      gutterBottom
                    >
                      Unidades
                    </Typography>
                  </Grid>
                  <Grid item md={12} className={classes.marginDefault}>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <DAutoComplete
                          id="unidadeRef"
                          label="Unidade de referência"
                          placeholder="Unidade de referência"
                          labelColor="black"
                          fullWidth={true}
                          error={error.unidadeRef}
                          helperText={error.unidadeRef}
                          defaultValue={
                            unidadesRef.filter((res) => res.id == unidadeRef)[0]
                          }
                          options={unidadesRef}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.unidadeRef;
                            setUnidadeRef(newValue ? newValue.id : '');
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <DMultAutoComplete
                          id="unidadeVinculada"
                          label="Unidades vinculadas ao paciente"
                          placeholder="Unidades vinculadas ao paciente"
                          labelColor="black"
                          error={error.unidadeVinculada}
                          helperText={error.unidadeVinculada}
                          defaultValue={
                            unidadesVinculadas.filter(
                              (res) => res.id == unidadeVinculada,
                            )[0]
                          }
                          options={unidadesVinculadas}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.unidadeVinculada;

                            setUnidadeVinculada(newValue);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                className={`tema-light ${classes.root} ${step !== 2 ? classes.hide : ''}`}
                spacing={3}
              >
                <Grid item md={12}>
                  <Grid item md={12}>
                    {activeMedicalUnit &&
                      activeMedicalUnit.medicalUnit &&
                      nacionalidade != 3 && (
                        <UnidadeCard>
                          <div>
                            <span>
                              {activeMedicalUnit.medicalUnit
                                .medical_unit_type || '-'}
                            </span>
                            <span>
                              <b>
                                {activeMedicalUnit.medicalUnit.business_name ||
                                  '-'}
                              </b>
                            </span>
                          </div>
                          <div>
                            <span>CNES</span>
                            <span>
                              {activeMedicalUnit.medicalUnit.cnes || '-'}
                            </span>
                          </div>
                          <div>
                            <span>Telefone</span>
                            <span>
                              {activeMedicalUnit.medicalUnit.phone_number
                                ? mascaraNumero(
                                    activeMedicalUnit.medicalUnit.phone_number,
                                  )
                                : '-'}
                            </span>
                          </div>
                        </UnidadeCard>
                      )}
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      className="text-primary text-bold"
                      variant="h5"
                      gutterBottom
                    >
                      Endereço{' '}
                      {nacionalidade === 3
                        ? 'residencial estrangeiro'
                        : activeMedicalUnit &&
                            dadosIncompletos &&
                            activeMedicalUnit.address
                          ? 'da unidade de saúde'
                          : ''}
                    </Typography>
                    <Typography variant="p" gutterBottom>
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid container spacing={3}>
                    {countrys.length > 0 && nacionalidade === 3 && (
                      <Grid item md={12}>
                        <DDropDownSimple
                          id="paisNascimento"
                          label="País de nascimento"
                          placeholder="País de nascimento"
                          fullWidth
                          labelColor="black"
                          required={!dadosIncompletos || nacionalidade == 3}
                          error={error.paisNascimento}
                          helperText={error.paisNascimento}
                          labelClass={classes.labelBlack}
                          value={paisNascimento}
                          options={countrys}
                          onChange={(event) => {
                            delete error.paisNascimento;
                            setPaisNascimento(event);
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item md={7}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item md={7}>
                          <DInput
                            id="cep"
                            label="CEP"
                            placeholder="CEP"
                            labelColor="black"
                            required={nacionalidade != 3}
                            type="text"
                            error={error.cep}
                            helperText={error.cep}
                            positionIcon="end"
                            fullWidth={true}
                            onChange={(e) => {
                              delete error.cep;
                              setCep(mascaraCEP(e.target.value));

                              if (e.target.value.length >= 10) {
                                getCep(e.target.value)
                                  .then((res) => {
                                    if (res.erro) {
                                      var new_erro = {};
                                      new_erro.cep = 'CEP inexistente';
                                      setErrors(new_erro);

                                      setBairro('');
                                      setEndereco('');
                                      setEstado('');
                                      setMunicipio('');
                                      setIdMunicipio('');
                                    } else {
                                      var municipioSelec = citys.filter(
                                        (city) =>
                                          city.value ==
                                          removeAccents(
                                            res.localidade.toUpperCase(),
                                          ),
                                      )[0];

                                      console.log(municipioSelec);

                                      if (municipioSelec.id) {
                                        setBairro(res.bairro);
                                        setEndereco(res.logradouro);
                                        setEstado(res.uf);
                                        setMunicipio(res.localidade);
                                        setIdMunicipio(municipioSelec.id);
                                      }
                                    }
                                  })
                                  .catch(console.error);
                              }
                            }}
                            value={cep}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <a
                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                            style={{ fontSize: 14 }}
                            target="_blank"
                            className="text-mt text-regular text-link"
                            rel="noreferrer"
                          >
                            Não sei o CEP
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <DInput
                        id="endereco"
                        label="Endereço"
                        placeholder="Endereço"
                        labelColor="black"
                        type="text"
                        disabled={true}
                        error={error.endereco}
                        helperText={error.endereco}
                        positionIcon="end"
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.endereco;
                          setEndereco(e.target.value);
                        }}
                        value={endereco}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <DInput
                        id="numero"
                        label="Número"
                        placeholder="Número"
                        labelColor="black"
                        required={nacionalidade != 3}
                        // required={!dadosIncompletos}
                        type="text"
                        error={error.numero}
                        positionIcon="end"
                        helperText={error.numero}
                        inputProps={{ maxLength: 8 }}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.numero;

                          setNumero(e.target.value);
                        }}
                        value={numero}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <DInput
                        id="Complemento"
                        label="Complemento (opcional)"
                        placeholder="Complemento"
                        labelColor="black"
                        type="text"
                        positionIcon="end"
                        fullWidth={true}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setComplemento(e.target.value);
                          }
                        }}
                        value={complemento}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <DInput
                        id="bairro"
                        label="Bairro"
                        placeholder="Bairro"
                        labelColor="black"
                        type="text"
                        disabled={true}
                        positionIcon="end"
                        error={error.bairro}
                        helperText={error.bairro}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.bairro;
                          setBairro(e.target.value);
                        }}
                        value={bairro}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <DInput
                        id="estado"
                        label="Estado"
                        placeholder="Estado"
                        labelColor="black"
                        type="text"
                        disabled={true}
                        error={error.estado}
                        helperText={error.estado}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.estado;
                          setEstado(e.target.value);
                        }}
                        value={estado}
                        positionIcon="end"
                        icon={<ExpandMore />}
                      />
                    </Grid>
                    <Grid item md={9}>
                      <DInput
                        id="municipio"
                        label="Municipio"
                        placeholder="Municipio"
                        labelColor="black"
                        type="text"
                        disabled={true}
                        error={error.municipio}
                        helperText={error.municipio}
                        fullWidth={true}
                        onChange={(e) => {
                          delete error.municipio;
                          setMunicipio(e.target.value);
                        }}
                        value={municipio}
                        positionIcon="end"
                        icon={<ExpandMore />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent classes={{ root: classes.content }}>
              <Grid
                container
                className={`${classes.root}`}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <CircularProgress size={15} color="inherit" />
                  <Typography variant="body1" className="text-mt text-regular">
                    Carregando formulário
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          )}
          <DialogActions classes={{ root: classes.footer }}>
            <Grid
              container
              spacing={1}
              className="tema-light"
              justifyContent="flex-start"
              style={{ paddingLeft: '15px' }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!(load1 && load2 && load3 && load4)}
                      defaultChecked={dadosIncompletos}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary radio' }}
                      onClick={(e) => {
                        setDadosIncompletos(!dadosIncompletos);

                        if (!dadosIncompletos) {
                          setStep2(true);
                          setStep3(true);
                          setStep4(true);

                          if (activeMedicalUnit && activeMedicalUnit.address) {
                            const { number, zip_code } =
                              activeMedicalUnit.address;

                            if (nacionalidade == 3) {
                              setCep('');
                              setNumero('');
                            } else {
                              if (cep === '') {
                                setCep(mascaraCEP(zip_code));
                              }

                              if (numero === '') {
                                setNumero(number);
                              }
                            }
                          }
                        }
                      }}
                    />
                  }
                  label="Dados incompletos"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className="tema-light"
              justifyContent="flex-end"
            >
              <Grid item>
                <DButton
                  disabled={loading}
                  className={`${step === 0 ? classes.hide : classes.buttonModal}`}
                  type="button"
                  variant="secondary"
                  size="sm"
                  onClick={(e) => setStep(step - 1)}
                >
                  Voltar
                </DButton>
              </Grid>
              <Grid item>
                <DButton
                  disabled={loading || !(load1 && load2 && load3 && load4)}
                  multiClass={
                    step === 2
                      ? `btn-success ${classes.buttonModal}`
                      : classes.buttonModal
                  }
                  variant="primary"
                  size="sm"
                  onClick={(e) => {
                    var dados = getDados();

                    if (validarFormulario({ ...dados })) {
                      if (step === 2) {
                        criarPaciente(dados);
                      } else {
                        onStep(step + 1);
                      }
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <> {step === 2 ? 'Finalizar cadastro' : 'Avançar'} </>
                  )}
                </DButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
        <SimpleModal open={cadastrado} onClose={(e) => props.onClose(true)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: 300, wordBreak: 'break-word' }}
          >
            <Grid item className="text-center">
              <CheckCircleOutline
                className="text-success"
                style={{ fontSize: 64 }}
              />
              <Typography
                className={['text-mt text-regular', classes.textPrimaryColor]}
              >
                Cadastro do paciente efetuado com sucesso
              </Typography>
              <Typography variant="p" gutterBottom>
                &nbsp;
              </Typography>
              <Typography
                className="text-regular"
                variant="body2"
                style={{ wordBreak: 'break-word' }}
              >
                {nome}
              </Typography>
            </Grid>
          </Grid>
        </SimpleModal>
      </Dialog>
    </>
  );
}

export default PacienteCreate;
