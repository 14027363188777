import React, { useState } from 'react';
import GlobalContext from './GlobalContext';

export default function ContextWrapper(props) {
  const [monthIndex, setMonthIndex] = useState(
    new Date().getDate() === 30 || new Date().getDate() === 31
      ? new Date().getMonth() + 1
      : new Date().getMonth(),
  );
  const [openScheduleOfTheDay, setOpenScheduleOfTheDay] = useState();
  const [dayBlock, setDayBlock] = useState([]);
  const [openModalBloqueio, setOpenModalBloqueio] = useState(false);
  const [openModalBloqueioDia, setOpenModalBloqueioDia] = useState(false);
  const [openModalDesbloqueio, setOpenModalDesbloqueio] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        openScheduleOfTheDay,
        setOpenScheduleOfTheDay,
        dayBlock,
        setDayBlock,
        openModalBloqueio,
        setOpenModalBloqueio,
        openModalBloqueioDia,
        setOpenModalBloqueioDia,
        setOpenModalDesbloqueio,
        openModalDesbloqueio,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
