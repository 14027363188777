import axios from 'axios';
import env from 'react-dotenv';

class Biomedidas {
  get(token) {
    return axios.get(`${env.API_URL}/biomeasure/filter?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    });
  }

  create(token, body) {
    return axios.post(`${env.API_URL}/biomeasure?token=${token}`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    });
  }

  update(token, id, body) {
    return axios.put(`${env.API_URL}/biomeasure/${id}?token=${token}`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    });
  }

  destroy(token, id) {
    return axios
      .delete(`${env.API_URL}/biomeasure/${id}/destroy?token=${token}`, {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      })
      .then((res) => res.data)
      .catch((error) => error.response);
  }

  createToHealthAttendance(token, body) {
    return axios.post(
      `${env.API_URL}/biomeasure/biomeasure-exam-health-attendance?token=${token}`,
      body,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );
  }

  getByHealthAttendance(token, health_attendance_id) {
    return axios.get(
      `${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${health_attendance_id}?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );
  }

  destroyByHealthAttendance(token, id) {
    return axios.delete(
      `${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${id}?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );
  }

  updateToHealthAttendanceById(token, body, id) {
    return axios.put(
      `${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${id}?token=${token}`,
      body,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );
  }

  getByPatient(token, patient_id) {
    return axios.get(
      `${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/patient/${patient_id}?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );
  }
}

let Bio = new Biomedidas();

export { Bio };
