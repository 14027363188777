import React from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import Alergias from '../assets/dados/alergias.json';

export const PreHealthAttendanceContext = createContext({});

export const PreHealthAttendanceProvider = (props) => {
  const [patientFile, setPatientFiles] = useState([]);

  const [comorbiditiesData, setComorbiditiesData] = useState([]);
  const [comorbiditiesFamilyData, setComorbiditiesFamilyData] = useState([]);
  const [comorbidities, setComorbidities] = useState([]);
  const [comorbidity, setComorbidity] = useState([]);
  const [comorbidityFamilyHistory, setComorbidityFamilyHistory] = useState([]);
  const [comorbidityFamily, setComorbidityFamily] = useState([]);
  const [comorbidityFamilyAF, setComorbidityFamilyAF] = useState([]);

  const [symptomsData, setSymptomsData] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsDays, setSymptomsDays] = useState([]);

  const [lineOfCare, setLineOfCare] = useState(null);
  const [lineOfCareCheck, setLineOfCareCheck] = useState(false);

  const [healthAttendanceType, setHealthAttendanceType] = useState();
  const [healthAttendanceId, setHealthAttendanceId] = useState();
  const [anamneseId, setAnamneseId] = useState('');

  const [continuousUseMedications, setContinuousUseMedications] = useState([]);
  const [jsonListMedicines, setListMedicine] = useState([]);

  const [foodAllergiesOptions, setFoodAllergiesOptions] = useState(Alergias);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [drugAllergies, setDrugAllergies] = useState([]);

  const [physycalActivity, setPhysycalActivity] = useState('');
  const [smoking, setSmoking] = useState('');
  const [smoker, setSmoker] = useState({
    field_1: {
      time: null,
      period: null,
    },
    field_2: {
      amount: null,
    },
  });
  const [exSmoker, setExSmoker] = useState({
    field_1: {
      time: null,
      period: null,
    },
    field_2: {
      time: null,
      period: null,
    },
  });
  const [smoking_amount, setSmokingAmount] = useState(null);
  const [smoking_period, setSmokingPeriod] = useState(null);
  const [smoking_period_without, setSmokingPeriodWithout] = useState(null);

  const [alcoholic, setAlcoholic] = useState('');
  const [alcoholic_type, setAlcoholicType] = useState(null);
  // const [alcoholic_period, setAlcoholicPeriod] = useState(null);
  const [alcoholicPeriod, setAlcoholicPeriod] = useState({
    field_1: {
      time: null,
      period: null,
    },
  });

  const [drugs, setDrugs] = useState('');
  const [drugsList, setDrugsList] = useState([
    {
      type: null,
      use: null,
      inputValueType: '',
      inputValueUse: '',
    },
  ]);

  const [symptomItems, setSymptomItems] = useState([{ id: 0, days: null }]);

  const [lineCares, setLineCares] = useState(null);

  const [anamneseForm, setAnamneseForm] = useState([]);
  const [healthAttendanceAnamneseForm, setHealthAttendanceAnamneseForm] =
    useState(null);
  const [group, setGroup] = useState(null);
  const [serviceCharacter, setServiceCharacter] = useState(null);
  const [interconsultation, setInterconsultation] = useState(false);

  const [drinkstate, setdrinkState] = useState({
    checkedA: false,
    checkedB: false,
  });

  const carater = [
    { id: 1, value: 'Imediato' },
    { id: 2, value: 'Monitoramento' },
    { id: 3, value: 'Orientação' },
    { id: 4, value: 'Triagem' },
    { id: 5, value: 'Agendado' },
  ];

  return (
    <PreHealthAttendanceContext.Provider
      value={{
        patientFile,
        setPatientFiles,
        comorbiditiesData,
        setComorbiditiesData,
        comorbiditiesFamilyData,
        setComorbiditiesFamilyData,
        comorbidities,
        setComorbidities,
        comorbidity,
        setComorbidity,
        comorbidityFamilyHistory,
        setComorbidityFamilyHistory,
        comorbidityFamily,
        setComorbidityFamily,
        comorbidityFamilyAF,
        setComorbidityFamilyAF,
        symptomsData,
        setSymptomsData,
        symptoms,
        setSymptoms,
        symptomsDays,
        setSymptomsDays,
        lineOfCare,
        setLineOfCare,
        lineOfCareCheck,
        setLineOfCareCheck,
        healthAttendanceType,
        setHealthAttendanceType,
        healthAttendanceId,
        setHealthAttendanceId,
        anamneseId,
        setAnamneseId,
        continuousUseMedications,
        setContinuousUseMedications,
        jsonListMedicines,
        setListMedicine,
        foodAllergiesOptions,
        setFoodAllergiesOptions,
        foodAllergies,
        setFoodAllergies,
        drugAllergies,
        setDrugAllergies,
        physycalActivity,
        setPhysycalActivity,
        smoking,
        setSmoking,
        smoker,
        setSmoker,
        exSmoker,
        setExSmoker,
        smoking_amount,
        setSmokingAmount,
        smoking_period,
        setSmokingPeriod,
        smoking_period_without,
        setSmokingPeriodWithout,
        alcoholic,
        setAlcoholic,
        alcoholic_type,
        setAlcoholicType,
        // alcoholic_period, setAlcoholicPeriod,
        alcoholicPeriod,
        setAlcoholicPeriod,
        drugs,
        setDrugs,
        drugsList,
        setDrugsList,
        symptomItems,
        setSymptomItems,
        lineCares,
        setLineCares,
        anamneseForm,
        setAnamneseForm,
        healthAttendanceAnamneseForm,
        setHealthAttendanceAnamneseForm,
        group,
        setGroup,
        serviceCharacter,
        setServiceCharacter,
        interconsultation,
        setInterconsultation,
        drinkstate,
        setdrinkState,
        carater,
      }}
    >
      {props.children}
    </PreHealthAttendanceContext.Provider>
  );
};

export function usePreHealthAttendance() {
  const {
    patientFile,
    setPatientFiles,
    comorbiditiesData,
    setComorbiditiesData,
    comorbiditiesFamilyData,
    setComorbiditiesFamilyData,
    comorbidities,
    setComorbidities,
    comorbidity,
    setComorbidity,
    comorbidityFamilyHistory,
    setComorbidityFamilyHistory,
    comorbidityFamily,
    setComorbidityFamily,
    comorbidityFamilyAF,
    setComorbidityFamilyAF,
    symptomsData,
    setSymptomsData,
    symptoms,
    setSymptoms,
    symptomsDays,
    setSymptomsDays,
    lineOfCare,
    setLineOfCare,
    lineOfCareCheck,
    setLineOfCareCheck,
    healthAttendanceType,
    setHealthAttendanceType,
    healthAttendanceId,
    setHealthAttendanceId,
    anamneseId,
    setAnamneseId,
    continuousUseMedications,
    setContinuousUseMedications,
    jsonListMedicines,
    setListMedicine,
    foodAllergiesOptions,
    setFoodAllergiesOptions,
    foodAllergies,
    setFoodAllergies,
    drugAllergies,
    setDrugAllergies,
    physycalActivity,
    setPhysycalActivity,
    smoking,
    setSmoking,
    smoker,
    setSmoker,
    exSmoker,
    setExSmoker,
    smoking_amount,
    setSmokingAmount,
    smoking_period,
    setSmokingPeriod,
    smoking_period_without,
    setSmokingPeriodWithout,
    alcoholic,
    setAlcoholic,
    alcoholic_type,
    setAlcoholicType,
    // alcoholic_period, setAlcoholicPeriod,
    alcoholicPeriod,
    setAlcoholicPeriod,
    drugs,
    setDrugs,
    drugsList,
    setDrugsList,
    symptomItems,
    setSymptomItems,
    lineCares,
    setLineCares,
    anamneseForm,
    setAnamneseForm,
    healthAttendanceAnamneseForm,
    setHealthAttendanceAnamneseForm,
    group,
    setGroup,
    serviceCharacter,
    setServiceCharacter,
    interconsultation,
    setInterconsultation,
    drinkstate,
    setdrinkState,
    carater,
  } = useContext(PreHealthAttendanceContext);

  return {
    patientFile,
    setPatientFiles,
    comorbiditiesData,
    setComorbiditiesData,
    comorbiditiesFamilyData,
    setComorbiditiesFamilyData,
    comorbidities,
    setComorbidities,
    comorbidity,
    setComorbidity,
    comorbidityFamilyHistory,
    setComorbidityFamilyHistory,
    comorbidityFamily,
    setComorbidityFamily,
    comorbidityFamilyAF,
    setComorbidityFamilyAF,
    symptomsData,
    setSymptomsData,
    symptoms,
    setSymptoms,
    symptomsDays,
    setSymptomsDays,
    lineOfCare,
    setLineOfCare,
    lineOfCareCheck,
    setLineOfCareCheck,
    healthAttendanceType,
    setHealthAttendanceType,
    healthAttendanceId,
    setHealthAttendanceId,
    anamneseId,
    setAnamneseId,
    continuousUseMedications,
    setContinuousUseMedications,
    jsonListMedicines,
    setListMedicine,
    foodAllergiesOptions,
    setFoodAllergiesOptions,
    foodAllergies,
    setFoodAllergies,
    drugAllergies,
    setDrugAllergies,
    physycalActivity,
    setPhysycalActivity,
    smoking,
    setSmoking,
    smoker,
    setSmoker,
    exSmoker,
    setExSmoker,
    smoking_amount,
    setSmokingAmount,
    smoking_period,
    setSmokingPeriod,
    smoking_period_without,
    setSmokingPeriodWithout,
    alcoholic,
    setAlcoholic,
    alcoholic_type,
    setAlcoholicType,
    // alcoholic_period, setAlcoholicPeriod,
    alcoholicPeriod,
    setAlcoholicPeriod,
    drugs,
    setDrugs,
    drugsList,
    setDrugsList,
    symptomItems,
    setSymptomItems,
    lineCares,
    setLineCares,
    anamneseForm,
    setAnamneseForm,
    healthAttendanceAnamneseForm,
    setHealthAttendanceAnamneseForm,
    group,
    setGroup,
    serviceCharacter,
    setServiceCharacter,
    interconsultation,
    setInterconsultation,
    drinkstate,
    setdrinkState,
    carater,
  };
}
