import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Bloqueio from '../../../../assets/img/Bloqueio.png';
import { required } from 'helpers/validacoes';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import Checkbox from '@material-ui/core/Checkbox';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    justifyContent: 'left',
  },
  backgroundModalExcluir: {
    minWidth: '450px',
    maxWidth: '500px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.neutral.white,
    margin: '100px auto',
    padding: '20px',
  },
  mainTitle: {
    textAlign: 'center',
    color: theme.palette.neutral.black,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  titleDelete: {
    textAlign: 'center',
    color: theme.palette.neutral.darkgray,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  columnGrid: {
    alignSelf: 'center',
    marginTop: 20,
    padding: '0px 5px',
  },

  btnExcluir: {
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    borderRadius: '10px',
    gap: '10px',
  },
  btnEditar: {
    background: theme.palette.primary.main,
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    gap: '10px',
    borderRadius: '10px',
    color: 'white',
    transition: 'all 200ms linear',

    '&:hover': {
      color: 'black',
    },
  },
  innerModal: {
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));

export default function BloqueioAgendaModal({ onBloquear, open, onClose }) {
  const [error, validarFormulario, setErrors] = useError(getValidators());

  const classes = useStyles();

  const [inicioBloqueio, setInicioBloqueio] = useState(null);
  const [fimBloqueio, setFimBloqueio] = useState(null);
  const [justificativa, setJustificativa] = useState('');
  const [isFullDay, setIsFullDay] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [enableOtherReason, setEnableOtherReason] = useState(false);

  const justificativasOptions = [
    { value: 1, label: 'Atestado' },
    { value: 2, label: 'Falta' },
    { value: 3, label: 'Feriado' },
    { value: 4, label: 'Férias' },
    { value: 5, label: 'Outra' },
  ];

  useEffect(() => {
    return () => {
      setInicioBloqueio(null);
      setFimBloqueio(null);
      setJustificativa('');
      setIsFullDay(false);
    };
  }, []);

  useEffect(() => {
    if (justificativa == 5) setEnableOtherReason(true);
    else setEnableOtherReason(false);
  }, [justificativa]);

  function getValidators() {
    var validators = {
      justificativa: required,
    };

    validators.inicioBloqueio = () => {
      if (inicioBloqueio >= fimBloqueio && !isFullDay) {
        return 'Horário inválido';
      }
    };

    validators.fimBloqueio = () => {
      if (inicioBloqueio >= fimBloqueio && !isFullDay) {
        return 'Horário inválido';
      }
    };

    validators.otherReason = () => {
      if (justificativa == 5 && otherReason == '') {
        return 'Campo obrigatório';
      }
    };

    return validators;
  }

  return (
    <Modal open={open}>
      <Grid container className={classes.backgroundModalExcluir}>
        <Grid container style={{ flexDirection: 'row-reverse' }}>
          <Button
            id="basic-button"
            aria-haspopup="true"
            onClick={() => onClose()}
          >
            <CloseOutlined />
          </Button>
        </Grid>
        <div className={classes.innerModal}>
          <Grid style={{ textAlign: '-webkit-center' }}>
            <img src={Bloqueio} alt="" />
            <h2 className={classes.mainTitle}>Período de bloqueio</h2>
            <h3 className={classes.titleDelete}>
              Selecione o período de bloqueio desejado
            </h3>
          </Grid>

          <Grid container>
            <Grid item md={4} className={classes.columnGrid}>
              <TInputTimeBase
                error={error.inicioBloqueio}
                required
                label="Inicio"
                name="inicio_bloqueio"
                placeholder="00:00"
                value={inicioBloqueio}
                onChange={(e) => setInicioBloqueio(e.target.value)}
              />
            </Grid>
            <Grid item md={4} className={classes.columnGrid}>
              <TInputTimeBase
                error={error.fimBloqueio}
                required
                label="Fim"
                name="fim_bloqueio"
                placeholder="00:00"
                value={fimBloqueio}
                onChange={(e) => setFimBloqueio(e.target.value)}
              />
            </Grid>
            <Grid
              item
              md={4}
              className={classes.columnGrid}
              style={{ marginTop: 40 }}
            >
              <Checkbox
                className={classes.checkbox}
                value={isFullDay}
                onChange={(e) => setIsFullDay(e.target.checked)}
              />
              O dia todo
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={12} className={classes.columnGrid}>
              <TSelectNative
                label="Justificativa *"
                name="justificativa"
                error={error.justificativa}
                options={justificativasOptions}
                value={justificativa}
                onChange={(e) => {
                  setJustificativa(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          {enableOtherReason && (
            <Grid container>
              <Grid item md={12} className={classes.columnGrid}>
                <TInputBase
                  label="Outra *"
                  name="outra"
                  error={error.otherReason}
                  placeholder="Digite aqui.."
                  onChange={(e) => {
                    if (e.target.value.length === 100) {
                      return;
                    } else {
                      setOtherReason(e.target.value);
                    }
                  }}
                  value={otherReason}
                />
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item md={6} className={classes.columnGrid}>
              <Button
                className={classes.btnExcluir}
                onClick={(e) => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item md={6} className={classes.columnGrid}>
              <Button
                onClick={(e) => {
                  if (
                    !validarFormulario({
                      inicioBloqueio: inicioBloqueio,
                      fimBloqueio: fimBloqueio,
                      justificativa: justificativa,
                    })
                  ) {
                    return;
                  } else {
                    toast.success(
                      'Bloqueio pré-configurado com sucesso, lembre-se de salvar para confirmar.',
                    );
                  }

                  onBloquear({
                    inicioBloqueio,
                    fimBloqueio,
                    justificativa: justificativasOptions.find(
                      (option) => option.value == justificativa,
                    )?.label,
                    isFullDay,
                    otherReason,
                  });
                }}
                className={classes.btnEditar}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Modal>
  );
}

BloqueioAgendaModal.defaultProps = {
  onBloquear: () => console.log('Bloquear'),
  open: true,
};
