import {
  useConfiguracaoAgenda,
  ConfiguracaoAgendaProvider,
} from 'context/ConfiguracaoAgendaContext';
import Cadastro from './component/Form/Cadastro';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ConfiguracaoGeral from './component/Form/Geral/ConfiguracaoGeral';
import { ConfiguracaoAgendaGeralProvider } from 'context/ConfiguracaoAgendaGeralContext';

function ConfiguracaoAgenda(props) {
  return (
    <>
      <Switch>
        <Route path="/configuracao-agenda/cadastro">
          <ConfiguracaoAgendaProvider>
            <Cadastro />
          </ConfiguracaoAgendaProvider>
        </Route>
        <Route path="/configuracao-agenda/geral">
          <ConfiguracaoAgendaGeralProvider>
            <ConfiguracaoGeral />
          </ConfiguracaoAgendaGeralProvider>
        </Route>
      </Switch>
    </>
  );
}

export default ConfiguracaoAgenda;
