import moment from 'moment';

export function getMonth(month = new Date().getMonth()) {
  const year = new Date().getFullYear();
  const date = new Date();

  const firstDayOfTheMonth = moment(new Date(year, month, 1)).day();
  let currentMonthDay = 0 - firstDayOfTheMonth;

  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthDay++;
      return moment(new Date(year, month, currentMonthDay));
    });
  });
  return daysMatrix;
}
