import { useState, useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { listMedicines } from './../../../../api/medicine/index';
import Alergias from './../../../../assets/dados/alergias.json';
import DAutoCompleteCreate from './../../../shared/DAutoCompleteCreate/DAutoCompleteCreate';
import DMultAutoComplete from './../../../shared/DMultAutoComplete';

function AlergiasEdit(props) {
  const { healthAttendance } = useNewHealthAttendance();

  const [medicines, setMedicines] = useState([]);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [drugAllergies, setDrugAllergies] = useState([]);
  const [foodAllergiesEdit, setFoodAllergiesEdit] = useState(false);
  const [drugAllergiesEdit, setDrugAllergiesEdit] = useState(false);

  useEffect(() => {
    listMedicines(localStorage.getItem('token'))
      .then((res) => {
        if (res.status) {
          var new_medicines = [{ id: 0, value: 'Desconhece' }];
          res.medicines.map((medicine) =>
            new_medicines.push({ id: medicine.id, value: medicine.name }),
          );
          setMedicines(new_medicines);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (healthAttendance) {
      var foodAllergy = [];

      healthAttendance.anamnese_pre_form
        .filter((pre_form) => pre_form.anamnese_pre_food_allergy.length > 0)
        .map((pre_form) =>
          pre_form.anamnese_pre_food_allergy.map((food_allergy) => {
            foodAllergy.push(food_allergy.name);
          }),
        );

      var new_allergies = Alergias.filter(
        (alergia) =>
          foodAllergy.filter((f_allergy) => f_allergy === alergia.value)
            .length > 0,
      );
      setFoodAllergies(new_allergies);
      props.onChange(new_allergies, drugAllergies);
    }
  }, [healthAttendance]);

  useEffect(() => {
    if (healthAttendance && medicines.length > 0) {
      var drugAllergy = [];

      healthAttendance.anamnese_pre_form
        .filter((pre_form) => pre_form.anamnese_pre_medicine_allergy.length > 0)
        .map((pre_form) =>
          pre_form.anamnese_pre_medicine_allergy.map((medicine_allergy) => {
            drugAllergy.push(medicine_allergy.name);
          }),
        );

      var new_allergies = medicines.filter(
        (alergia) =>
          drugAllergy.filter((d_allergy) => d_allergy === alergia.value)
            .length > 0,
      );
      setDrugAllergies(new_allergies);
      props.onChange(foodAllergies, new_allergies);
    }
  }, [healthAttendance, medicines]);

  return (
    <>
      <DAutoCompleteCreate
        id="alergia-alimentar"
        placeholder="Alergia Alimentar"
        label="Alimentar"
        labelColor="black"
        error={props.error.foodAllergies}
        helperText={props.error.foodAllergies}
        value={foodAllergies}
        options={Alergias}
        onNewChange={(values) => {
          // delete error.foodAllergies;
          setFoodAllergies(values);
          props.onChange(values, drugAllergies);
          props.editAllergies(true, drugAllergiesEdit);
        }}
      />
      <DMultAutoComplete
        placeholder="Medicamentosas"
        label="Medicamentosa"
        labelColor="black"
        error={props.error.drugAllergies}
        helperText={props.error.drugAllergies}
        options={medicines}
        getOptionLabel={(option) => option.value}
        getOptionSelected={(option, value) => value && option.id === value.id}
        value={drugAllergies}
        onChange={(event, newValue) => {
          // delete error.drugAllergies;
          setDrugAllergies(newValue);
          props.onChange(foodAllergies, newValue);
          props.editAllergies(drugAllergiesEdit, true);
        }}
      />
    </>
  );
}

AlergiasEdit.defaultProps = {
  error: {
    foodAllergies: '',
    drugAllergies: '',
  },
  onChange: (foodAllergies, drugAllergies) => {
    console.log(foodAllergies, drugAllergies);
  },
};

export default AlergiasEdit;
