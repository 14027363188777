import { toast } from 'react-toastify';

class NovaBiomedidaHelper {
  constructor() {
    this.biomedida = null;
  }

  setBiomedida(biomedida) {
    this.biomedida = biomedida;

    return this;
  }

  getFieldType = (type) => {
    switch (type) {
      case 2:
        return 'lista';
      case 3:
        return 'opcoes';
      case 4:
        return 'verdadeiro_falso';
      default:
        return 'numero';
    }
  };

  getValuesDefault(fields) {
    let new_values = fields.biomeasure_exam_field.map(
      (biomeasure_exam_field) => {
        var value = '';

        if (!this.biomedida) {
          value =
            this.getFieldType(
              biomeasure_exam_field.biomeasure_exam_field_type.id,
            ) === 'verdadeiro_falso'
              ? 'falso'
              : '';
        } else {
          let new_value =
            this.biomedida.biomeasure_exam_health_attendance_value.find(
              (val) =>
                val.biomeasure_exam_field_id === biomeasure_exam_field.id,
            );

          if (new_value) value = new_value.value;
        }

        let return_value = {
          id: biomeasure_exam_field.id,
          value,
        };

        return return_value;
      },
    );

    return new_values;
  }

  setFields(setFieldsBiomedida, fields) {
    setFieldsBiomedida(
      fields.biomeasure_exam_field.map((biomeasure_exam_field) => {
        return {
          id: biomeasure_exam_field.id,
          type: this.getFieldType(
            biomeasure_exam_field.biomeasure_exam_field_type_id,
          ),
          name: biomeasure_exam_field.label,
          showName: biomeasure_exam_field.show_label === 1 ? true : false,
          unit: biomeasure_exam_field.measure,
          showUnit: biomeasure_exam_field.show_measure === 1 ? true : false,
          multiple: biomeasure_exam_field.multiple === 1 ? true : false,
          options: biomeasure_exam_field.biomeasure_exam_field_option.map(
            (option) => {
              return {
                id: option.option,
                value: option.option,
              };
            },
          ),
        };
      }),
    );

    return this;
  }

  validateFields(setErrorValues, values) {
    let valid = true;

    var error_value = [];

    if (values.length <= 0) {
      valid = false;
      toast.error('Preencha pelo menos um campo.');
    }

    values.map((value) => {
      if (!value.value || value.value[0] === '') {
        valid = false;
        error_value.push('Campo obrigatório');
      } else {
        error_value.push(null);
      }
    });

    setErrorValues(error_value);

    if (!valid) {
      toast.warn('Prencha todos os campos');
    }

    return valid;
  }
}

export default new NovaBiomedidaHelper();
