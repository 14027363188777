class Error {
  constructor(index, message = '') {
    this.index = index;
    this.message = message;
  }

  getIndex() {
    return this.index;
  }

  getMessage() {
    return this.message;
  }
}

export default Error;
