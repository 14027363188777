import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getCitiesByStateId } from '../../../../../../../../api/getCitiesByStateId';
import { usePatient } from '../../../../../../../../context/PatientContext';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import { useStyles } from './styles';

interface ICity {
  id: number;
  description: string;
  code: string;
  created_at: string;
  deleted_at?: string | null | undefined;
  updated_at: string;
  state_id: number;
}

interface ICityWithValue extends ICity {
  value: string;
}

interface IDemographicDataProps {
  errors: {
    estadoNascimento?: string;
    nacionalidade?: string;
    municipioNascimento?: string;
    paisNascimento?: string;
    raca?: string;
    etnia?: string;
  };
  states: {
    id: number;
    value: string;
    description: string;
  }[];
  countries: {
    id: number;
    value: string;
    description: string;
  }[];
  racasMap: {
    id: number;
    value: string;
  }[];
  etnias: {
    id: number;
    value: string;
  }[];
  removeError: (key: string) => void;
  setErrors: (error: { [key: string]: string }) => void;
}

export const DemographicData = ({
  errors,
  countries,
  states,
  etnias,
  racasMap,
  removeError,
  setErrors,
}: IDemographicDataProps) => {
  const classes = useStyles();

  const [cities, setCities] = useState<ICityWithValue[]>();
  const [loadingCities, setLoadingCities] = useState(false);

  const {
    dadosPrincipais,
    dadosDemograficos,
    setDadosDemograficos,
    setDadosIdentidadeNaturalizado,
    setDadosPrincipais,
    setDadosIdentidadeEstrangeiro,
  } = usePatient();

  useEffect(() => {
    if (dadosDemograficos?.estadoNascimento) {
      getCitiesByStateId(dadosDemograficos?.estadoNascimento).then(
        (response) => {
          if (response.status) {
            const formattedCitys = response?.data?.cities?.map(
              (city: ICity) => ({
                ...city,
                value: city.description,
              }),
            );
            setCities(formattedCitys);
          }
        },
      );
    }
  }, []);

  const handleGetCitiesByState = async (state: number) => {
    if (!state) return;
    setLoadingCities(true);

    const cities = await getCitiesByStateId(state);
    if (!cities?.data?.status) {
      setErrors({
        municipioNascimento: 'Cidades inexistentes',
      });
    } else {
      const formattedCitys = cities?.data?.cities?.map((city: ICity) => ({
        ...city,
        value: city.description,
      }));
      setCities(formattedCitys);
    }
    setLoadingCities(false);
  };

  return (
    <>
      {dadosPrincipais.nacionalidade === 1 && (
        <>
          <Grid item xs={6} md={6} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <DDropDownSimple
                  id="estadoNascimento"
                  placeholder="Estado de nascimento"
                  label="Estado de nascimento"
                  fullWidth
                  error={errors.estadoNascimento}
                  helperText={errors.estadoNascimento}
                  value={dadosDemograficos.estadoNascimento}
                  options={states}
                  onChange={(id: number) => {
                    removeError('estadoNascimento');
                    setDadosDemograficos((prevState) => {
                      return {
                        ...prevState,
                        estadoNascimento: id,
                      };
                    });
                    handleGetCitiesByState(id);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <DDropDownSimple
                  id="municipioNascimento"
                  placeholder="Município de nascimento"
                  label="Município de nascimento"
                  fullWidth
                  error={errors.municipioNascimento}
                  helperText={errors.municipioNascimento}
                  value={dadosDemograficos.municipioNascimento}
                  options={cities}
                  disabled={
                    loadingCities ||
                    !dadosDemograficos.estadoNascimento ||
                    cities?.length === 0
                  }
                  onChange={(id) => {
                    removeError('municipioNascimento');
                    setDadosDemograficos((prevState) => {
                      return {
                        ...prevState,
                        municipioNascimento: id,
                      };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {dadosPrincipais.nacionalidade === 2 && (
        <>
          <Grid item xs={6} md={6} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <DAutoComplete
                  id="paisNascimento"
                  label="País de nascimento"
                  placeholder="País de nascimento"
                  fullWidth
                  error={errors.paisNascimento}
                  helperText={errors.paisNascimento}
                  defaultValue={
                    countries?.filter(
                      (res) => res.id === dadosPrincipais.paisNascimento,
                    )[0]
                  }
                  options={countries}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  onChange={(_, newValue) => {
                    removeError('paisNascimento');
                    const pais = newValue ? newValue.id : null;

                    setDadosIdentidadeNaturalizado((prevState) => {
                      return {
                        ...prevState,
                        paisNascimentoNaturalizado: pais,
                      };
                    });

                    setDadosPrincipais((prevState) => {
                      return {
                        ...prevState,
                        paisNascimento: pais,
                      };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {dadosPrincipais.nacionalidade === 3 && (
        <>
          <Grid item xs={6} md={6} className={classes.field_padding}>
            <Grid container spacing={3} className={classes.alignItemsEnd}>
              <Grid item md={12} style={{ paddingTop: 0 }}>
                <DAutoComplete
                  id="paisNascimento"
                  label="País de nascimento"
                  placeholder="País de nascimento"
                  fullWidth
                  error={errors.paisNascimento}
                  helperText={errors.paisNascimento}
                  defaultValue={
                    countries &&
                    countries.length &&
                    countries.filter(
                      (res) => res.id === dadosPrincipais.paisNascimento,
                    )[0]
                  }
                  options={countries}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  onChange={(_, newValue) => {
                    removeError('paisNascimento');
                    const pais = newValue ? newValue.id : null;

                    setDadosIdentidadeEstrangeiro((prevState) => {
                      return {
                        ...prevState,
                        passaportePaisNascimento: pais,
                      };
                    });

                    setDadosPrincipais((prevState) => {
                      return {
                        ...prevState,
                        paisNascimento: pais,
                      };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item md={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <div className="input-info">
              <DDropDownSimple
                id="raca"
                placeholder="Raça"
                label="Raça"
                primaryColorSelectIcon
                fullWidth
                error={errors.raca}
                helperText={errors.raca}
                value={dadosDemograficos.raca}
                options={racasMap}
                onChange={(id) => {
                  removeError('etnia');
                  removeError('raca');
                  if (id !== 5) {
                    setDadosDemograficos((prevState) => {
                      return {
                        ...prevState,
                        raca: id,
                        etnia: null,
                      };
                    });
                  } else {
                    setDadosDemograficos((prevState) => {
                      return {
                        ...prevState,
                        raca: id,
                      };
                    });
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <div className="input-info">
              <DDropDownSimple
                id="etnia"
                placeholder="Etnia"
                primaryColorSelectIcon
                label="Etnia"
                fullWidth
                error={errors.etnia}
                disabled={
                  (dadosDemograficos.raca && dadosDemograficos.raca) != 5 ||
                  dadosDemograficos.raca === null
                }
                helperText={errors.etnia}
                value={dadosDemograficos.etnia}
                options={etnias}
                onChange={(id) => {
                  removeError('etnia');

                  setDadosDemograficos((prevState) => {
                    return {
                      ...prevState,
                      etnia: id,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
