import { Card, CardContent, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getPatientById } from 'api/patient';
import {
  getEspecialtyWithSchedule,
  getProfessionalSpecialityScheduleById,
  getProfessionalsByEspecialty,
  getScheduleTimesByIdAndMonth
} from 'api/schedule/agenda-medico';
import CalendarReagenda from 'component/AgendaMedico/components/ReagendarCreate/components/CalendarReagenda';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import { PatientContext } from 'context/PatientContext';
import { usePatient } from 'context/usePatient';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Configuration from '../../../../api/configuration';
import { useAppProvider } from '../../../../context/AppContext';

// import { useSolicitacaoAgenda } from "context/SolicitacaoAgenda";

export const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    minHeight: 700,
  },
  titleSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
  },
  subtitle: {
    color: '#006fb5',
    fontWeight: 700,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 16,
  },
  item: {
    border: '2px solid #F4F6F8',
    borderRadius: 8,
    padding: 16,
    height: 50,
    marginBottom: 16,
  },
}));

export default function Agendamento(props) {
  const {
    monthIndex,
  } = useContext(GlobalContext);

  const classes = useStyles();

  const [listaEspecialidades, setListaEspecialidades] = useState([]);
  const [especialidade, setEspecialidade] = useState(null);
  const [listaProfissionais, setListaProfissionais] = useState([]);
  const [profissional, setProfissional] = useState(null);
  const [configuracoes, setConfiguracoes] = useState(null);
  const [tipoAgendamento, setTipoAgendamento] = useState(null);

  const listaTiposAgendamento =
    configuracoes && Boolean(configuracoes.allow_fit)
      ? [
          { value: 1, label: 'Primeira consulta' },
          { value: 2, label: 'Retorno' },
          { value: 3, label: 'Encaixe' },
        ]
      : [
          { value: 1, label: 'Primeira consulta' },
          { value: 2, label: 'Retorno' },
        ];

  const { patientData } = usePatient(PatientContext);
  const { LoadingControl } = useAppProvider();
  const {
    setHorariosAgenda,
    setFormError,
    setPatientDocuments,
    setNomePaciente,
    horariosAgenda,
  } = useConfiguracaoAgenda();

  useEffect(() => {

    getEspecialtyWithSchedule().then((res) => {
      if (res.status === true) {
        setListaEspecialidades(
          res.medical_speciality?.map((x) => ({
            value: x.id,
            label: x.description,
          })) || [],
        );
      }
    });

    handleAgendarCreate();

    Configuration.get(localStorage.getItem('token'))
      .then((res) => {
        if (res.status) {
          setConfiguracoes(res.configuration);
        } else {
        }
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (profissional) getProfessionalSchedules(profissional);
  }, [monthIndex])

  useEffect(() => {
    if (especialidade) {
      setListaProfissionais([]);
      setProfissional(null);

      LoadingControl.showLoading('Carregando médicos...');

      getProfessionalsByEspecialty(especialidade).then((res) => {
        if (res.status === true) {
          setListaProfissionais(
            res.medical_speciality_user?.map((user) => ({
              value: user.id,
              label: user.social_name || user.full_name,
            })) || [],
          );
        } else {
          toast.error(
            'Erro ao buscar profissionais para esta especialidade. Por favor, tente novamente em alguns minutos.',
          );
        }

        LoadingControl.hideLoading();
      });
    }
  }, [especialidade]);

  useEffect(() => {
    if (especialidade && tipoAgendamento && profissional) {
      setFormError(true);
    } else {
      setFormError(false);
    }
  }, [especialidade, tipoAgendamento, profissional]);

  const handleMenuItem = (itemParam) => {
    props.handleMenuItem(itemParam);
  };

  const handleAgendarCreate = () => {
    setNomePaciente(
      patientData.patient.social_name || patientData.patient.full_name,
    );
    getEnderecoPaciente(patientData.patient.id);
  };

  function getEnderecoPaciente(idPacient) {
    getPatientById(localStorage.getItem('token'), idPacient).then(
      (response) => {
        if (response.status) {
          setPatientDocuments(response.details.patient.patient_document);
        } else {
          toast.error(
            'Ocorreu um erro ao selecionar o agendamento. Por favor, tente novamente em alguns minutos.',
          );
          props.onClose();
        }
      },
    );
  }

  function dateDiffInMonths(date) {
    var today = new Date();
    var birthDate = new Date(date);

    var year = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();

    year = year * 12 + month;

    return year;
  }

  function dateDiffInDays(date) {
    const MILISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const utc2 = Date.now();

    return Math.floor((utc2 - utc1) / MILISECONDS_PER_DAY);
  }

  function dateDiffInAges(date) {
    var ageDifMs = Date.now() - date;
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  function verifyScheduleRequirements(schedule) {
    var dataNascimento = new Date(patientData.patient.birth_date);
    var sexo = patientData.patient.biological_sex;

    var response = {
      isValid: true,
      erros: {
        sexo: false,
        idade: false,
      },
    };

    if (
      schedule.sex !== 'ambos' &&
      schedule.sex.toLowerCase() !== sexo.toLowerCase()
    ) {
      response.erros.sexo = true;
      response.isValid = false;
    }

    if (schedule.age_begin !== null) {
      if (schedule.age_begin_type === 'year') {
        if (dateDiffInAges(dataNascimento) < schedule.age_begin) {
          response.erros.idade = true;
          response.isValid = false;
        }
      } else if (schedule.age_begin_type === 'month') {
        if (dateDiffInMonths(dataNascimento) < schedule.age_begin) {
          response.erros.idade = true;
          response.isValid = false;
        }
      } else {
        if (dateDiffInDays(dataNascimento) < schedule.age_begin) {
          response.erros.idade = true;
          response.isValid = false;
        }
      }
    }

    if (schedule.age_end !== null) {
      if (schedule.age_end_type === 'year') {
        if (dateDiffInAges(dataNascimento) > schedule.age_end) {
          response.erros.idade = true;
          response.isValid = false;
        }
      } else if (schedule.age_begin_type === 'month') {
        if (dateDiffInMonths(dataNascimento) > schedule.age_end) {
          response.erros.idade = true;
          response.isValid = false;
        }
      } else {
        if (dateDiffInDays(dataNascimento) > schedule.age_end) {
          response.erros.idade = true;
          response.isValid = false;
        }
      }
    }

    return response;
  }

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  async function getProfessionalSchedules(professional) {
    LoadingControl.showLoading('Carregando agenda...');
    
    if (!professional) {
      setProfissional('');
      LoadingControl.hideLoading();
      return;
    }

    await getProfessionalSpecialityScheduleById(professional, especialidade)
      .then(async (response) => {
        if (response.status) {
          if (response.schedules.length) {
            var arrayHorarios = [];
            var erros = [];

            for await (const schedule of response.schedules) {
              if (schedule.status === 1) {
                if (verifyScheduleRequirements(schedule).isValid) {
                  const scheduleTimes = await getProfessionalScheduleTimes(
                    schedule.id,
                  );
                  scheduleTimes && arrayHorarios.push(...scheduleTimes);
                } else {
                  let _err = verifyScheduleRequirements(schedule).erros;
                  Object.keys(_err)
                    .filter((x) => _err[x])
                    .map((x) => capitalize(x))
                    .forEach((errType) => {
                      if (!erros.includes(errType)) {
                        erros.push(errType);
                      }
                    });
                }
              }
            }

            if (arrayHorarios.length) {
              setProfissional(professional);
              setHorariosAgenda(arrayHorarios);
            } else {
              // setProfissional('');
              setHorariosAgenda([]);
              toast.warn(
                'Não foi possível encontrar agendas compatíveis' +
                  (erros.length ? ': ' + erros.join(', ') : ''),
              );
            }
          } else {
            setProfissional('');
            toast.error('O profissional não possui agendamentos disponíveis');
          }
        } else {
          toast.error(
            'Ocorreu um erro ao buscar os horários do profissional selecionado',
          );
        }
      })
      .finally(() => LoadingControl.hideLoading());
  }

  async function getProfessionalScheduleTimes(idAgendamento) {
    const date = moment(new Date(moment().year(), monthIndex))
    .format('yyyyMM')

    const response = await getScheduleTimesByIdAndMonth(idAgendamento, date)

    if (response.status) {
      return response.schedule.schedule_times;
    } else {
      return null;
    }
  }

  const parseAttendanceType = (type) => {
    switch (type) {
      case '1':
        return 'primeiraConsulta';
      case '2':
        return 'retorno';
      case '3':
        return 'encaixe';
      default:
        return '';
    }
  };

  return (
    <div className={classes.background}>
      <Container maxWidth="xl" style={{ backgroundColor: '#F5F6F6' }}>
        <Grid container>
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 61,
              marginBottom: 16,
            }}
          >
            <a
              href="#"
              className={classes.titleSection}
              onClick={(e) => props.onClose()}
            >
              <ArrowBackIosIcon color="primary" fontSize="large" />
              {patientData.patient.social_name || patientData.patient.full_name}
            </a>
          </Grid>
        </Grid>

        <Card>
          <CardContent>
            <Grid container spacing={5}>
              <Grid item md={4} style={{ paddingLeft: 32, paddingRight: 32 }}>
                {/* <TInputBase
                  // error
                  // helperText="Mensagem de apoio"
                  required
                  label="Especialidade selecionada"
                  name="especialidade"
                  placeholder="Especialidade selecionada"
                  //value={ selectedSpecialty }
                  //onChange={ e => setSelectedSpecialty(e.target.value) }
                /> */}

                <TSelectNative
                  label="Selecionar especialidade"
                  name="especialidade"
                  required
                  options={listaEspecialidades}
                  value={especialidade}
                  onChange={(e) => setEspecialidade(e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <TSelectNative
                  label="Tipo de agendamento"
                  name="tipo_agendamento"
                  // error
                  // helperText="Mensagem de apoio"
                  required
                  options={listaTiposAgendamento}
                  value={tipoAgendamento}
                  onChange={(e) => setTipoAgendamento(e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <TSelectNative
                  label="Selecionar profissional"
                  name="selecionar_profissional"
                  required
                  options={listaProfissionais}
                  value={profissional}
                  onChange={async (e) => await getProfessionalSchedules(e.target.value)}
                />
              </Grid>

              <Grid item md={12} style={{ padding: 0 }}>
                <CalendarReagenda
                  isAgendamento
                  patientFile={props.receivePatientFile}
                  dataAnamneseForm={props.receiveDataAnamnesePreForm}
                  horariosAgenda={horariosAgenda}
                  tipoAgendamento={parseAttendanceType(tipoAgendamento)}
                  handleMenuItem={handleMenuItem}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
