import React from 'react';
import env from 'react-dotenv';
import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import Logo from '../../assets/img/Telemed24h_LOGO_BCO 1.svg';
import Medicine from '../../assets/img/undraw_medicine_b1ol 1.svg';
import ReCAPTCHA from 'react-google-recaptcha';

import FormRecover from './components/FormRecover';
import FooterLogin from '../shared/FooterLogin';

import { refreshPassword, checkAuth } from './../../api/auth';

class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acoes: 0,
      senhaTrocada: false,
      token: null,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    checkAuth(params.get('token')).then((res) => {
      if (res.status)
        this.setState({ ...this.state, token: params.get('token') });
      else {
        this.setState({ ...this.state, token: true, acoes: 1 });
      }
    });
  }

  refreshAuthPassword(dados) {
    this.setState({ ...this.state, loading: true });
    refreshPassword(dados.senha, this.state.token).then((res) => {
      if (!res.error) {
        this.setState({ ...this.state, loading: false, senhaTrocada: true });
      } else {
        //console.log(res);
      }
    });
  }

  getAction(props) {
    var acoes = [
      <FormRecover
        loading={this.state.loading}
        modal={this.state.senhaTrocada}
        onCloseModal={(e) =>
          this.setState({ ...this.state, senhaTrocada: false, acoes: 1 })
        }
        voltar={(e) => this.setState({ ...this.state, acoes: 1 })}
        refresh={(dados) => {
          props.recaptchaRef.current.execute();
          props.recaptchaRef.current.reset();
          this.refreshAuthPassword(dados);
        }}
      />,
      <Redirect to="/" />,
    ];

    return acoes[this.state.acoes];
  }

  render() {
    this.recaptchaRef = React.createRef();

    return (
      <Container className="bg-gradient-primary-am container-login">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className="logo-content">
            <img alt="telemed 24h" src={Logo} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9}>
            <Grid container spacing={0} className="card-default">
              <Grid
                item
                md={7}
                className="container-login-medicos bg-white-transparent"
              >
                <img alt="médicos" src={Medicine} />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                className="tema-light container-login-forms bg-white"
              >
                {this.state.token ? (
                  this.getAction({ recaptchaRef: this.recaptchaRef })
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    style={{ height: 427 }}
                  >
                    <Grid item md={12} className="text-center">
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  size="invisible"
                  sitekey={env.SITE_KEY}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FooterLogin />
      </Container>
    );
  }
}

export default withRouter(Recover);
