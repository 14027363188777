import React, { useState, useEffect } from 'react';
import AnamneseNewTab from '../../NewTabs/AnamneseNewTab';
import HistoricoNewTab from '../../NewTabs/HistoricoNewTab';
import PrescricaoNewTab from '../../NewTabs/PrescricaoNewTab';
import NovaSolicitacaoNewTab from '../../NewTabs/NovaSolicitacaoNewTab';
import VisualizadorClinNewTab from '../../NewTabs/VisualizadorClinNewTab';
import '../index.scss';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  assumirAtendimento,
  getHealthAttendanceByPageFilter,
} from './../../../../api/health_attendances';
import { toast } from 'react-toastify';
import AssumirAtendimento from './AssumirAtendimento';

const useStyles = makeStyles((theme) => ({
  card_content: {
    width: '100%',
    height: 400,
    position: 'absolute',
    // background: "black",
    backdropFilter: 'blur(2px)',
    zIndex: 1501,
  },
  card_inner: {
    width: 328,
    height: 184,
  },
  container_blocked: {
    maxHeight: 400,
    overflow: 'hidden',
    position: 'relative',
  },
}));

function SubmenuNewCardsAtendimento(props) {
  const { healthAttendance, setHealthAttendance, setFinish, setFinishLock } =
    useNewHealthAttendance();

  const classes = useStyles();

  const [tab, setTab] = useState(0);

  const tabList = [
    <AnamneseNewTab onReset={(e) => props.onReset(true)} />,
    <PrescricaoNewTab />,
    <VisualizadorClinNewTab />,
    <HistoricoNewTab />,
  ];
  const checkActive = (index) => {
    if (tab === index) {
      return 'item_selected_submenu_new_cards background-color';
    }
  };

  const handleMenu = (key) => {
    setFinish(false);
    setTab(key);
    if (key === 0) setFinishLock(false);
    else setFinishLock(true);
  };

  const submenuOptions = [
    'Anamnese',
    'Prescrição',
    'Visualizador Clínico',
    'Histórico do atendimento',
  ];
  return (
    <div className="main_flex_submenu_new_cards">
      <div className="background_submenu_new_cards">
        {submenuOptions.map((submenuOption, key) => (
          <div
            className={'flex_1_submenu_new_cards ' + checkActive(key)}
            onClick={(e) => handleMenu(key)}
          >
            {submenuOption}
          </div>
        ))}
      </div>
      <div
        className={`mt40-submenu-new-cards ${healthAttendance && healthAttendance.health_attendance_status_id === 1 ? classes.container_blocked : ''}`}
      >
        {healthAttendance &&
          healthAttendance.health_attendance_status_id === 1 && (
            <AssumirAtendimento />
          )}
        {tabList[tab]}
      </div>
    </div>
  );
}

SubmenuNewCardsAtendimento.defaultProps = {
  onReset: function () {
    console.log('onReset');
  },
};

export default SubmenuNewCardsAtendimento;
