import { Paper, Grid, Typography } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 362,
    height: 56,
    borderRadius: 8,
    paddingTop: 16,
    paddingRight: 8,
    paddingBottom: 16,
    paddingLeft: 16,
  },
  paper: {
    width: 362,
    height: 56,
    background: '#F5F6F6',
    cursor: 'pointer',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  arrowColor: {
    color: theme.palette.primary.main,
  },
  arrowSize: {
    fontSize: 16,
  },
  text: {
    color: '#4F4F4F',
  },
}));

function DPaperAction(props) {
  const classes = useStyles();

  return (
    <Paper onClick={(e) => props.onAction()} classes={{ root: classes.paper }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ root: classes.root }}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={`text-regular text-mt ${classes.text}`}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <ArrowForwardIos
            classes={{
              colorPrimary: classes.arrowColor,
              fontSizeSmall: classes.arrowSize,
            }}
            color="primary"
            fontSize="small"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

DPaperAction.defaultProps = {
  title: 'Titulo',
  onAction: function () {
    console.log('onAction');
  },
};

export default DPaperAction;
