import NewCards from '../../shared/NewCards';
import SubmenuNewCardsAtendimento from '../../shared/SubMenuNewCards/SubmenuNewCardsAtendimento';
import { Grid } from '@material-ui/core';
import Container from '@mui/material/Container';
import { useState, useEffect } from 'react';
import SubmenuNewCardsSolicitacao from '../../shared/SubMenuNewCards/SubmenuNewCardsSolicitacao';
import SubmenuNewCardsPaciente from '../../shared/SubMenuNewCards/SubmenuNewCardsPaciente';
import AnamneseNewTab from '../../shared/NewTabs/AnamneseNewTab';
import HistoricoNewTab from '../../shared/NewTabs/HistoricoNewTab';
import SubmenuNewCardsUltimos from '../../shared/SubMenuNewCards/SubmenuNewCardsUltimos';
import SubmenuNewCardsPrescricao from '../../shared/SubMenuNewCards/SubmenuNewCardsPrescricao';
import { useNewHealthAttendance } from '../../../context/NewHealthAttendance';

function Content(props) {
  const { setFinishLock, finished } = useNewHealthAttendance();
  const [card, setCard] = useState(0);
  const componentList = [
    <SubmenuNewCardsSolicitacao />,
    <SubmenuNewCardsPaciente />,
    <SubmenuNewCardsUltimos onReset={(e) => setCard(0)} />,
    <SubmenuNewCardsPrescricao />,
    <SubmenuNewCardsAtendimento onReset={(e) => setCard(0)} />,
  ];

  useEffect(() => {
    if (finished) props.onFinished(true);
  }, [finished]);

  const checkActive = (index) => {
    return card == index;
  };

  const handleCard = (key) => {
    if (key !== card) {
      if (parseInt(key) === 4) {
        setFinishLock(false);
      } else {
        setFinishLock(true);
      }
    }
    setCard(key);
  };
  return (
    <Container maxWidth="xl" style={{ backgroundColor: '#F5F6F6' }}>
      <Grid
        item
        md={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        <NewCards
          active={checkActive(0)}
          valor="0"
          title="Dados da Solicitação"
          subtitle_1="Solicitante"
          subtitle_2="Paciente"
          subtitle_3="Grupo de paciente"
          handleCard={handleCard}
        />
        <NewCards
          active={checkActive(1)}
          valor="1"
          title="Dados do Paciente"
          subtitle_1="Dados principais"
          subtitle_2="Documento"
          subtitle_3="Dados demográficos"
          handleCard={handleCard}
        />
        <NewCards
          active={checkActive(2)}
          valor="2"
          title="Últimos atendimentos"
          subtitle_1="Protocolo"
          subtitle_2="Unidades"
          subtitle_3="Últimos atendimentos"
          handleCard={handleCard}
        />
        <NewCards
          active={checkActive(3)}
          valor="3"
          title="Prescrições"
          subtitle_1="Tipo"
          subtitle_2="Integrações"
          subtitle_3="Dados da Prescrição"
          handleCard={handleCard}
        />
        <NewCards
          active={checkActive(4)}
          valor="4"
          title="Atendimento"
          subtitle_1="Formulário da Anamnese"
          subtitle_2="Hipótese Diagnóstica"
          subtitle_3="Prescrição"
          handleCard={handleCard}
        />
      </Grid>
      <Grid item md={12} style={{ marginTop: '40px' }}>
        {componentList[card]}
      </Grid>
    </Container>
  );
}

Content.defaultProps = {
  onFinished: function () {
    console.log('onFinished');
  },
};

export default Content;
