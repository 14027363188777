import { useState, useContext, useEffect } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Clear,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Redo,
  Search,
  TextFields,
  Undo,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import DButton from '../../../../shared/DButton';
import DDropzone from '../../../../shared/DDropzone';
import { getPrescriptions } from '../../../../../api/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AtendimentoContext } from '../../../../../context/AtendimentoContext';
import {
  listHealthAttendanceById,
  listHealthAttendanceFiles,
  uploadHealthAttendanceFile,
} from '../../../../../api/healthAttendance';
import formJSON from './formElement.json';
import Elements from './ElementsBuilder/Elements';
import { FormBuilderContext } from '../../../../../context/FormBuilderContext';
import { useHealthAttendance } from '../../../../../context/HealthAttendanceContext';
import '../../styles.scss';
import construtor from '../../../../../api/construtor';
import {
  anamneseFormAnswer,
  anamneseFormDischarge,
  getAnamneseFormAnswer,
  getFormConditionals,
} from '../../../../../api/anamnese';
import useError from '../../../../../hooks/useError';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles({
  box: {
    background: '#F9F9F9',
    boxShadow:
      '0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)',
    borderRadius: '8px',
    // marginTop: 141,
    alignSelf: 'flex-start',
    maxHeight: '75vh',
    overflow: 'scroll',
  },
  backDrop: {
    zIndex: 3000,
    color: '#FFF',
  },
});

export default function Anamnese(props) {
  const [file, setFile] = useState(null);
  const [textArea, setTextArea] = useState(``);
  const [healthAttendanceFile, setHealthAttendanceFiles] = useState(null);
  const { acoes, setAcoes } = useHealthAttendance();

  const classes = useStyles();

  useEffect(() => {
    if (acoes.ligacao && acoes.finalizar && acoes.submit) handleSubmit();
  }, [acoes]);

  const {
    atendimentoInfo,
    setDadosAnamnese,
    dadosAnamnese,
    startPrescription,
    patientPrescriptions,
    setPatientPrescriptions,
    emAtendimento,
    setEmAtendimento,
    setAtendimentoStatus,
    readOnlyAnamneseForm,
  } = useContext(AtendimentoContext);

  const [elements, setElements] = useState(null);
  const [elementsForValidate, setElementsForValidate] = useState(null);
  const [loading, setLoading] = useState(null);

  const { fields, page_label } = elements ?? {};
  const [data, setData] = useState([]);
  // const [fields, setFields] = useState([]);

  const [error, validarFormulario, setErrors] = useError();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sendFirstReply, setSendFirstReply] = useState(false);
  const [loadingFinishDischarge, setLoadingFinishDischarge] = useState(false);
  const [idFormAnwser, setIdFormAnwser] = useState(null);
  const [errorField, setErrorField] = useState([]);
  const [answerToConditional, setAnswerToConditional] = useState([]);
  const [formId, setFormId] = useState(); //Anamnse Form ID
  const [formularioId, setFormularioId] = useState(); //Form ID
  const [nameForm, setNameForm] = useState();
  const [lineOfCare, setLineOfCare] = useState();

  const [formAnswers, setFormAnswers] = useState();
  const [flagHandleConditionals, setFlagHandleConditionals] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (file) {
      uploadHealthAttendanceFile(
        localStorage.getItem('token'),
        file,
        atendimentoInfo.id,
      )
        .then((response) => {
          props.toast.success('Arquivo enviado com sucesso!');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file]);

  // useEffect(() => {
  //   if (readOnlyAnamneseForm) {
  //     setIsReadOnly(true);
  //   }
  // }, [readOnlyAnamneseForm]);

  useEffect(() => {
    if (atendimentoInfo && atendimentoInfo.id) {
      getPrescriptions(localStorage.getItem('token'), atendimentoInfo.id)
        .then((data) => {
          if (data.error) {
            // Visual response?
            throw data.error;
          }

          setPatientPrescriptions(data.details);
        })
        .catch((err) => console.error(err));
    }
  }, [atendimentoInfo]);

  useEffect(() => {
    if (props.idAtendimento && emAtendimento)
      listHealthAttendanceById(
        localStorage.getItem('token'),
        props.idAtendimento,
      )
        .then((res) => {
          if (res.status) {
            if (
              res.healthAttendance.health_attendance_anamnese_form.length > 0
            ) {
              setFormularioId(
                res.healthAttendance.health_attendance_anamnese_form[
                  res.healthAttendance.health_attendance_anamnese_form.length -
                    1
                ].anamnese_form.form_id,
              );
              setFormId(
                res.healthAttendance.health_attendance_anamnese_form[
                  res.healthAttendance.health_attendance_anamnese_form.length -
                    1
                ].anamnese_form_id,
              );
              setNameForm(
                res.healthAttendance.health_attendance_anamnese_form[
                  res.healthAttendance.health_attendance_anamnese_form.length -
                    1
                ].anamnese_form.name,
              );
              if (res.healthAttendance.line_care != null) {
                setLineOfCare(res.healthAttendance.line_care.name);
              }
            }
            if (
              res.healthAttendance.health_attendance_anamnese_form[
                res.healthAttendance.health_attendance_anamnese_form.length - 1
              ].closed_at != null
            ) {
              setIsReadOnly(true);
            }
            if (
              res.healthAttendance.anamnese_form_answer.length > 0 &&
              res.healthAttendance.anamnese_form_answer[
                res.healthAttendance.anamnese_form_answer.length - 1
              ].closed_at == null
            ) {
              setFormAnswers(
                res.healthAttendance.anamnese_form_answer[
                  res.healthAttendance.anamnese_form_answer.length - 1
                ].data,
              );
              setData(
                res.healthAttendance.anamnese_form_answer[
                  res.healthAttendance.anamnese_form_answer.length - 1
                ].data,
              );
            }
          }
        })
        .catch((err) => console.error(err));
  }, [emAtendimento]);

  useEffect(() => {
    setLoading(true);

    if (formId)
      construtor.show(localStorage.getItem('token'), formId).then((res) => {
        if (res.status && res.form.status !== 'rascunho') {
          setElements({ fields: res.form.fields });

          setElementsForValidate(
            res.form.fields.map((item) => {
              return {
                id: item.id,
                readOnly: item.is_read_only === 1 ? true : false,
                required: item.is_required === 1 ? true : false,
              };
            }),
          );
        }

        if (res.status && res.form.status === 'rascunho')
          toast.warn(
            'Formulário indisponível. Solicite a ativação do formulário ao suporte!',
          );

        setLoading(false);
      });
  }, [formId]);

  /**
   * Condiciona a primeira abertura do formulario a respeitar as condicionais, antes mesmo do usuario inserir o primeiro input.
   */

  useEffect(() => {
    console.log(
      formId !== undefined &&
        fields !== undefined &&
        data.length > 0 &&
        !flagHandleConditionals,
    );
    if (
      formId !== undefined &&
      fields !== undefined &&
      data.length > 0 &&
      !flagHandleConditionals
    ) {
      setLoadingFinishDischarge(true);
      anamneseFormAnswer(localStorage.getItem('token'), null, {
        health_attendance_id: props.idAtendimento,
        anamnese_form_id: formId,
        data: data,
      }).then((res) => {
        if (res.status) {
          console.log(res.fields);
          setAnswerToConditional(res.fields);
          setIdFormAnwser(res.details.id ? res.details.id : null);
        }
        setLoadingFinishDischarge(false);
        setFlagHandleConditionals(true);
      });
    }
  }, [formId, fields, data]);

  useEffect(() => {
    let fieldsToAnswerToConditional = [];
    fields &&
      fields.forEach((element, index) => {
        fieldsToAnswerToConditional.push({
          id: `${fields[index].id}`,
          value: '',
        });
      });
    setData(fieldsToAnswerToConditional);
  }, []);

  function getValidators() {
    let arrayWithFormResponses = data;

    const initalArrMapFormResponses = arrayWithFormResponses.reduce(
      (prev, current) => {
        return { ...prev, [current.id]: current };
      },
      {},
    );

    let errorCheck = false;
    if (elementsForValidate && elementsForValidate.length > 0) {
      elementsForValidate.forEach((element, index) => {
        const id = elementsForValidate[index].id;

        if (elementsForValidate[index].required === true) {
          try {
            if (
              elementsForValidate[index].id &&
              (!initalArrMapFormResponses[id] ||
                initalArrMapFormResponses[id].value === '' ||
                initalArrMapFormResponses[id].value === null)
            ) {
              errorCheck = true;
              setErrorField((prevState) => {
                if (prevState && Array.isArray(prevState)) {
                  if (
                    prevState.find(
                      (x) => x.id === elementsForValidate[index].id,
                    )
                  ) {
                    return prevState;
                  }
                }

                return [
                  ...prevState,
                  {
                    id: elementsForValidate[index].id,
                    value: 'Este campo é obrigatório',
                  },
                ];
              });
            }
          } catch (e) {
            toast.error('Erro ao realizar ação.');
          }
        }
      });
      if (errorCheck) {
        toast.warning('Campos obrigatórios não preenchidos.');
      }
    }
    return errorCheck;
  }

  useEffect(() => {
    if (formId && data.length > 0) {
      try {
        if (data && idFormAnwser == null && !sendFirstReply) {
          setLoadingFinishDischarge(true);

          anamneseFormAnswer(localStorage.getItem('token'), null, {
            health_attendance_id: props.idAtendimento,
            anamnese_form_id: formId,
            data: data,
          }).then((res) => {
            if (res.status) {
              setSendFirstReply(true);
              setAnswerToConditional(res.fields);
              setIdFormAnwser(res.details.id ? res.details.id : null);
            }
            setLoadingFinishDischarge(false);
          });
        } else if (idFormAnwser != null) {
          setLoadingFinishDischarge(true);
          anamneseFormAnswer(localStorage.getItem('token'), idFormAnwser, {
            health_attendance_id: props.idAtendimento,
            anamnese_form_id: formId,
            data: data,
          }).then((res) => {
            if (res.status) {
              setAnswerToConditional(res.fields);
            }
            setLoadingFinishDischarge(false);
          });
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao realizar ação.');
      }
    }
  }, [formId, data]);
  //}, [data]);

  const handleSubmit = () => {
    setErrorField([]);
    // event.preventDefault();

    if (!getValidators()) {
      try {
        if (data) {
          if (acoes.ligacao && acoes.finalizar && acoes.submit) {
            anamneseFormDischarge(localStorage.getItem('token'), idFormAnwser, {
              health_attendance_id: props.idAtendimento,
              anamnese_form_id: formId,
            }).then((res) => {
              if (res.status) {
                setIsReadOnly(true);
                setEmAtendimento(false);
                setAtendimentoStatus(0);
                setSendFirstReply(false);
                props.returnListHealthAttendance(true);
              }
            });
            toast.success('Processo realizado com sucesso.');
          } else {
            setAcoes({ ...acoes, finalizar: true });
          }
        }
      } catch (error) {
        toast.error('Erro ao realizar ação.');
      }
    }
  };

  const handleInputSubmit = (answer) => {
    const result = {
      id: Object.keys(answer)[0],
      value: answer[Object.keys(answer)[0]],
    };

    const old_data = [...data];

    let new_field = true;

    old_data.map(function (item, index) {
      if (item.id === result.id) {
        old_data[index] = result;
        new_field = false;
      }
    });
    if (new_field) {
      old_data.push(result);
    }

    setData(old_data);
  };

  return (
    <>
      <Typography
        variant="body1"
        className="text-bold text-primary"
        style={{ fontSize: '1em', marginTop: 104, marginBottom: 13 }}
      >
        {lineOfCare ? `${lineOfCare} - ` : ''} {nameForm ? nameForm : ''}
      </Typography>
      <Box className={classes.box} p={3}>
        <aside>
          <div className="container-boxe">
            <FormBuilderContext.Provider value={{ handleInputSubmit }}>
              {loading && (
                <Backdrop className={classes.backDrop} open={true}>
                  <CircularProgress color="inherit" />
                  &nbsp;Carregando informações e o formulário de atendimento
                </Backdrop>
              )}
              <div className="container-boxe">
                <div className="builder-form mb-3">
                  <form autoComplete="off">
                    {fields
                      ? fields.map((field, i) => (
                          <Elements
                            key={i}
                            field={field}
                            errorField={errorField}
                            conditional={answerToConditional}
                            readOnly={isReadOnly}
                            formAnswers={formAnswers}
                          />
                        ))
                      : null}
                    {fields && (
                      <section className="handle-add">
                        <DButton
                          variant="secondary"
                          size="sm"
                          style={{ marginRight: 10 }}
                          disabled={isReadOnly || loadingFinishDischarge}
                          onClick={(e) => handleSubmit(e)}
                        >
                          Salvar Formulário
                        </DButton>
                      </section>
                    )}
                  </form>
                </div>
              </div>
            </FormBuilderContext.Provider>
          </div>

          <section>
            <span className="title">Prescrições</span>

            {patientPrescriptions &&
              patientPrescriptions.map((prescription, index) => {
                return (
                  <div className="prescricoes-box" key={index}>
                    <span className="head semi-bold">
                      {prescription.type || 'type'}
                    </span>
                    <span>Nome: {prescription.name || 'name'}</span>
                    <span>
                      {prescription.type === 'ATESTADO' ? 'Motivo' : ''}
                      {prescription.type === 'EXAME' ? 'Observação' : ''}
                      {prescription.type === 'MEDICAMENTOS' ? 'Via' : ''}:{' '}
                      {prescription.label || 'via'}
                    </span>
                  </div>
                );
              })}
          </section>

          <section className="handle-add">
            <DButton
              variant="secondary"
              size="md"
              onClick={(e) => startPrescription()}
            >
              Criar nova prescrição
            </DButton>
          </section>
        </aside>
      </Box>
    </>
  );
}
