import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DButton from '../shared/DButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import DInput from '../shared/DInput';

import {
  getAllComorbidities,
  createComorbidity,
  updateComorbidity,
  deleteComorbidity,
  putStatusComorbidity,
} from '../../api/comorbidity';
import { nameRegex } from '../../helpers/formatacao';
import RightbarComorbidities from './component/Rightbar/RightbarComorbidities';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
  titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
  buttonToolbar: { marginTop: 24, marginBottom: 24 },
  flex: { display: 'flex' },
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
  },
  disabledTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    textTransform: 'none',
  },
  listItem: {
    padding: '16px 0',
    margin: 0,
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
  },
  inactiveListButton: {
    color: theme.palette.support.error,
    borderColor: theme.palette.support.error,
    marginRight: 16,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
  },
  addButton: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  firstListItem: {
    padding: '16px 0',
    margin: 0,
  },
  deleteButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
  },
  activeButton: {
    color: 'green',
    textTransform: 'none',
    fontWeight: 400,
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  item_text: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
  backgroundSnow: {
    background: theme.palette.background.snow,
  },
});

class Comorbidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMedicalUnits: true,
      Comorbidities: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      oldComorbidity: '',
      error: [],
      showRightbar: false,
      delete: false,
      rowSelected: '',
      indexSelected: '',
    };
  }

  componentDidMount() {
    this.listComorbidities();
  }

  listComorbidities() {
    getAllComorbidities(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.comorbidity.map((row) => {
          var new_state = this.state.Comorbidities;
          new_state.push({
            id: row.id,
            description: row.description,
            active: row.active == 1 ? true : false,
            isEdit: false,
            isLoading: false,
          });
          this.setState({ ...this.state, Comorbidities: new_state });
        });
      } else {
        this.setState({ ...this.state, Comorbidities: [] });
      }
    });
  }

  saveComorbidity(Comorbidity, index) {
    if (Comorbidity.description.trim() == '') {
      var new_state = this.state.error;
      new_state.push({ index: index, erro: 'Campo obrigatório' });
      this.setState({ ...this.state, error: new_state });
    } else {
      var new_state = this.state.Comorbidities;
      new_state[index].isLoading = !Comorbidity.isLoading;
      this.setState({ ...this.state, Comorbidities: new_state });

      if (Comorbidity.id) {
        updateComorbidity(localStorage.getItem('token'), Comorbidity).then(
          (res) => {
            if (res.status) {
              toast.success('Processo realizado com sucesso.');
              new_state[index].isLoading = !Comorbidity.isLoading;
              new_state[index].isEdit = !Comorbidity.isEdit;
              this.setState({ ...this.state, Comorbidities: new_state });
            } else {
              if (res.description !== undefined) {
                toast.error(
                  'Erro: formato inválido, este campo não aceita números ou caracteres especiais.',
                );
              } else {
                if (res.error == 'já cadastrado no sistema') {
                  var new_error = this.state.error;
                  new_error.push({
                    index: index,
                    erro: `${Comorbidity.description} já cadastrado no sistema`,
                  });
                  this.setState({ ...this.state, error: new_error });
                  toast.error(
                    `${Comorbidity.description} já cadastrado no sistema`,
                  );
                } else {
                  if (res?.status_code != 401)
                    toast.error(
                      res.message ? res.message : 'Erro ao realizar ação.',
                    );
                }
              }

              new_state[index].isLoading = !Comorbidity.isLoading;
              this.setState({ ...this.state, Comorbidities: new_state });
            }
          },
        );
      } else {
        createComorbidity(localStorage.getItem('token'), Comorbidity).then(
          (res) => {
            if (res.status) {
              toast.success('Processo realizado com sucesso.');
              new_state[index].id = res.comorbidity.id;
              new_state[index].isLoading = !Comorbidity.isLoading;
              new_state[index].isEdit = !Comorbidity.isEdit;
              this.setState({ ...this.state, Comorbidities: new_state });
            } else {
              if (res.description !== undefined) {
                toast.error(
                  'Erro: formato inválido, este campo não aceita números ou caracteres especiais.',
                );
              } else {
                if (res.error == 'já cadastrado no sistema') {
                  var new_error = this.state.error;
                  new_error.push({
                    index: index,
                    erro: `${Comorbidity.description} já cadastrado no sistema`,
                  });
                  this.setState({ ...this.state, error: new_error });
                  toast.error(
                    `${Comorbidity.description} já cadastrado no sistema`,
                  );
                } else {
                  if (res?.status_code != 401)
                    toast.error(
                      res.message ? res.message : 'Erro ao realizar ação.',
                    );
                }
              }

              new_state[index].isLoading = !Comorbidity.isLoading;
              this.setState({ ...this.state, Comorbidities: new_state });
            }
          },
        );
      }
    }
  }

  confirmDelete(Comorbidity, index) {
    var new_state = this.state.Comorbidities;
    new_state[index].isLoading = !Comorbidity.isLoading;
    this.setState({ ...this.state, Comorbidities: new_state });

    deleteComorbidity(localStorage.getItem('token'), Comorbidity.id).then(
      (res) => {
        if (res.status) {
          toast.success('Processo realizado com sucesso.');
          new_state.splice(index, 1);
          this.setState({ ...this.state, Comorbidities: new_state });
        } else {
          toast.error(res.message ? res.message : 'Erro ao realizar ação.');
          new_state[index].isLoading = !Comorbidity.isLoading;
          this.setState({ ...this.state, Comorbidities: new_state });
        }
      },
    );
  }

  enableOrDisableComorbidity(Comorbidity, index) {
    var new_state = this.state.Comorbidities;
    new_state[index].isLoading = !Comorbidity.isLoading;
    this.setState({ ...this.state, Comorbidities: new_state });

    var dados_post = {
      id: Comorbidity.id,
      description: Comorbidity.description,
      active: !Comorbidity.active,
    };

    putStatusComorbidity(localStorage.getItem('token'), dados_post)
      .then((res) => {
        if (res.status) {
          new_state[index].isLoading = !Comorbidity.isLoading;
          new_state[index].active = !Comorbidity.active;
          this.setState({ ...this.state, Comorbidities: new_state });
          toast.success('Processo realizado com sucesso.');
        } else {
          new_state[index].isLoading = !Comorbidity.isLoading;
          this.setState({ ...this.state, Comorbidities: new_state });
          toast.error(res.message ? res.message : 'Erro ao realizar ação.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  hideRightBar() {
    this.setState({ ...this.state, showRightbar: false });
  }

  modalConfirmDelete() {
    const styles = {
      contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px',
      },
      titleRemove: {
        borderBottom: 'none',
      },
      buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
      buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
    };
    return (
      <Dialog
        aria-labelledby="conditional-remove-title"
        scroll="body"
        onClose={(e) => {
          this.setState({ ...this.state, delete: false });
        }}
        open={true}
      >
        <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={10}>
              <Typography variant="body1" className="text-regular text-st">
                Se seguir com essa ação todos os dados serão perdidos, deseja
                confirmar essa ação?
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={(e) => {
                  this.setState({ ...this.state, delete: false });
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={styles.contentRemove}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonBack}
                variant="secondary"
                size="none"
                className="btn-error"
                onClick={(e) => this.setState({ ...this.state, delete: false })}
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonConfirm}
                variant="primary"
                size="none"
                className="btn-error"
                onClick={(e) => {
                  this.confirmDelete(
                    this.state.rowSelected,
                    this.state.indexSelected,
                  );
                  this.setState({ ...this.state, delete: false });
                }}
              >
                Confirmar Exclusão
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;

    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
    };

    return (
      <>
        <Container className="tema-light">
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Atendimento
          </Typography>
          <Card className={classes.backgroundSnow}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant="h4">Comorbidades</Typography>
                </Grid>
                <Grid
                  item
                  md={9}
                  className={classes.flex}
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outlined"
                    className={classes.inactiveListButton}
                    size="sm"
                    onClick={() =>
                      this.setState({ ...this.state, showRightbar: true })
                    }
                  >
                    Inativas
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.addButton}
                    size="sm"
                    startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                    // style={ this.state.Comorbidities.find(x => x.isEdit) ? classes.disabledTagButton : classes.addTagButton }
                    disabled={this.state.Comorbidities.find((x) => x.isEdit)}
                    onClick={(e) => {
                      if (this.state.Comorbidities.find((x) => x.isEdit))
                        return; // Já há um edit a ser preenchido
                      var new_group = this.state.Comorbidities;
                      new_group.unshift({
                        id: null,
                        description: '',
                        isEdit: true,
                        isLoading: false,
                      });
                      this.setState({
                        ...this.state,
                        Comorbidities: new_group,
                      });
                    }}
                  >
                    <span style={{ marginTop: 2, display: 'block' }}>
                      Criar comorbidade
                    </span>
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    {this.state.Comorbidities.length
                      ? this.state.Comorbidities.map((row, index) => (
                          <>
                            {row.isEdit ? (
                              <ListItem
                                className={
                                  index == 0
                                    ? classes.firstListItem
                                    : classes.listItem
                                }
                              >
                                <Grid item md={6}>
                                  <DInput
                                    id="name"
                                    inputProps={{ maxLength: 50 }}
                                    label="Nome da comorbidade"
                                    placeholder="Digite o nome da nova comorbidade"
                                    value={row.description}
                                    defaultValue={row.description}
                                    error={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? this.state.error.find(
                                            (error) => error.index == index,
                                          ).erro
                                        : ''
                                    }
                                    type="text"
                                    fullWidth={true}
                                    positionIcon="end"
                                    onChange={(e) => {
                                      if (
                                        nameRegex(e.target.value) ||
                                        e.target.value == ''
                                      ) {
                                        var new_value =
                                          this.state.Comorbidities;
                                        new_value[index].description =
                                          e.target.value;
                                        this.setState({
                                          ...this.state,
                                          Comorbidities: new_value,
                                          error: [],
                                        });
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <ListItemSecondaryAction style={{ right: 0 }}>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <DeleteIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.deleteButton}
                                      onClick={(e) => {
                                        var new_comorbities =
                                          this.state.Comorbidities;

                                        if (!this.state.isEdit)
                                          new_comorbities.splice(index, 1);
                                        else {
                                          new_comorbities[index].isEdit =
                                            !row.isEdit;
                                          new_comorbities[index].description =
                                            this.state.oldComorbidity;
                                        }

                                        this.setState({
                                          ...this.state,
                                          isEdit: false,
                                          Comorbidities: new_comorbities,
                                          error: [],
                                        });
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <SaveIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.saveButton}
                                      onClick={(e) => {
                                        if (!row.isLoading) {
                                          this.saveComorbidity(row, index);
                                        }
                                      }}
                                    >
                                      {row.isLoading ? (
                                        <CircularProgress
                                          size={15}
                                          color="inherit"
                                        />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </ListItemSecondaryAction>
                                </Grid>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={
                                  index == 0
                                    ? classes.firstListItem
                                    : classes.listItem
                                }
                              >
                                <ListItemText
                                  primary={row.description}
                                  disableTypography={true}
                                  className={classes.item_text}
                                />
                                <ListItemSecondaryAction style={{ right: 0 }}>
                                  <IconButton
                                    size="sm"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        this.setState({
                                          ...this.state,
                                          delete: true,
                                          rowSelected: row,
                                          indexSelected: index,
                                        });
                                      }
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    className={
                                      row.active
                                        ? classes.activeButton
                                        : classes.deleteButton
                                    }
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        this.enableOrDisableComorbidity(
                                          row,
                                          index,
                                        );
                                      }
                                    }}
                                  >
                                    {row.active ? (
                                      <CheckCircleOutlineIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    ) : (
                                      <NotInterestedIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    className={classes.editButton}
                                    onClick={(e) => {
                                      var new_state = this.state.Comorbidities;
                                      new_state[index].isEdit = !row.isEdit;
                                      this.state.oldComorbidity =
                                        new_state[index].description;
                                      this.setState({
                                        ...this.state,
                                        isEdit: true,
                                        Comorbidities: new_state,
                                      });
                                    }}
                                  >
                                    <EditIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </>
                        ))
                      : 'Não existem comorbidades cadastradas'}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>

        {this.state.showRightbar ? (
          <div className={classes.dark_background}></div>
        ) : (
          ''
        )}
        {this.state.delete ? this.modalConfirmDelete() : ''}
        <RightbarComorbidities
          show={this.state.showRightbar}
          hide={() => this.hideRightBar()}
          comorbidities={this.state.Comorbidities}
          enableOrDisableComorbidity={(e) =>
            this.enableOrDisableComorbidity(e.comorbidity, e.index)
          }
          className={classes.rightbar}
        />
      </>
    );
  }
}

export default withStyles(styles)(Comorbidades);
