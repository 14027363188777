import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@material-ui/core';
import DButton from '../DButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Cancel } from '@material-ui/icons';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const container = {
  border: 'dashed rgba(39, 39, 39, 0.32) 3px',
  padding: '54px 10px',
  borderRadius: 5,
  textAlign: 'center',
};

const dropzone = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const MbToBytes = (mb) => mb * 1024 * 1024;

/**
 * @description Componente para upload de arquivos
 * @param {object} props Propriedades do componente
 * @param {string | number} props.maxSize Tamanho máximo do arquivo em MB
 * @param {string | number} props.minSize Tamanho mínimo do arquivo em MB
 * @param {string?} props.accept Tipos de arquivos aceitos
 * @param {function?} props.onError função que devolve um erro interno de Validação
 * @param {string?} props.defaultAceptErrorMessage Mensagem padrão de erro de tipo de arquivo
 * @param {string?} props.acceptWarn Formatos permitidos para mensagem de aviso
 * @param {string?} props.defaultSizeErrorMessage Mensagem personalizada de erro de tamanho máximo do arquivo
 * @param {string?} props.defaultErrorMessage Mensagem personalizada de erro padrão
 */
function DDropzone(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: props.accept ? props.accept : 'image/*',
    maxFiles: props.maxFiles ? props.maxFiles : 1,
    multiple: props.multiple ? props.multiple : false,
    minSize: props.minSize ? props.minSize : 0,
    maxSize: props.maxSize ? MbToBytes(props.maxSize) : 10485760, // 10 MB em bytes
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        props.emit(acceptedFiles);

        var files = acceptedFiles.map(function (file) {
          return file.name;
        });

        setFiles((prevFiles) => {
          var _temp = [...prevFiles, ...files];

          _temp = _temp.slice(0, props.maxFiles ? props.maxFiles : 1);

          return _temp;
        });
      }
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    console.log(files);
  }, [files]);

  useEffect(() => {
    if (Array.isArray(fileRejections) && fileRejections.length > 0) {
      if (fileRejections[0].errors[0].code == 'file-too-large') {
        props.onError &&
          props.onError(
            props.defaultSizeErrorMessage
              ? props.defaultSizeErrorMessage
              : `O arquivo é muito grande. Tamanho máximo permitido: ${props.maxSize ? props.maxSize : '10'} MB`,
          );
      } else if (fileRejections[0].errors[0].code == 'file-invalid-type') {
        props.onError &&
          props.onError(
            props.defaultAceptErrorMessage
              ? props.defaultAceptErrorMessage
              : 'O arquivo não é permitido. Formato permitido: ' +
                  props.acceptWarn
                ? props.acceptWarn
                : props.accept,
          );
      } else {
        props.onError &&
          props.onError(
            props.defaultErrorMessage
              ? props.defaultErrorMessage
              : 'O arquivo não é permitido. Verifique as condições para envio.',
          );
      }
    }
  }, [fileRejections]);

  return (
    <section className="container" style={container}>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <input {...getInputProps()} />

        {files.length > 0 ? (
          <>
            {files.map(function (item, key) {
              return (
                <DButton
                  onClick={(x) => {
                    setFiles((prevFiles) => {
                      var _temp = [...prevFiles];

                      _temp.splice(key, 1);

                      return _temp;
                    });

                    x.stopPropagation();
                  }}
                  key={`dropzone-item-${key}`}
                  style={{ marginTop: 16, marginBottom: 16 }}
                  variant="ghost"
                  icon={<CloudUploadIcon fontSize="small" />}
                  endIcon={
                    <Cancel fontSize="small" style={{ fill: '#dd4040' }} />
                  }
                  spanStyle={{ margin: '0px 8px', overflow: 'hidden' }}
                  size="sm"
                >
                  <Typography
                    variant="body2"
                    style={{ overflow: 'hidden', minWidth: 'max-content' }}
                  >
                    {item}
                  </Typography>
                </DButton>
              );
            })}
          </>
        ) : (
          <>
            <Grid item md={12} xs={12}>
              <Typography variant="h4">Arraste o arquivo aqui</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                className="text-mt text-regular"
                variant="body1"
                style={{ marginTop: 8 }}
              >
                Ou se preferir...
              </Typography>
            </Grid>

            <DButton variant="secondary" size="sm" style={{ marginTop: 8 }}>
              Busque o arquivo no computador
            </DButton>

            {props.formatMessage ? (
              <Typography
                className="text-est text-light"
                variant="body1"
                style={{ marginTop: 8 }}
              >
                {props.formatMessage}
              </Typography>
            ) : (
              <Typography
                className="text-est text-light"
                variant="body1"
                style={{ marginTop: 8 }}
              >
                {props.description
                  ? props.description
                  : 'Formatos .xls ou .csv com até 10 MB'}
              </Typography>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default DDropzone;
