import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import DRadio from '../../../../../../../shared/DRadio';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  withoutLabel: {
    marginLeft: theme.spacing(3),
  },
  textField: {
    width: '15% !important',
  },
}));

const EBCapillaryBloodGlucose = ({
  id,
  title,
  name,
  label,
  placeholder,
  additional_data,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();

  const [input, setInput] = useState(null);
  const [options, setOptions] = useState({});
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setOptions(e.target.value);
  };

  useEffect(() => {
    let error = false;
    if (input && input.length < 2) {
      setShowError(true);
      error = true;
    } else {
      setShowError(false);
      error = false;
    }

    const data = {
      value: {
        indiceGlicemico: input,
        jejum: options,
      },
    };
    if (data.value.jejum !== undefined && !error) {
      handleInputSubmit({ [id]: data.value });
    }
  }, [input, options]);

  useEffect(() => {
    if (answer.length > 0) {
      setInput(answer[0].indiceGlicemico);
      setOptions(answer[0].jejum);
    }
  }, []);

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold mb-2"
            style={{ fontSize: '0.8em' }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${label ? label : 'Glicemia Capilar'}`,
              }}
            />
          </Typography>
          <Box className={classes.root}>
            <TextField
              id={id}
              label={label ? label : ''}
              placeholder={placeholder ? placeholder : ''}
              // readOnly={is_read_only === 1 ? true : false}
              required={is_required === 1 ? true : false}
              error={erro[0] ? erro[0].value : ''}
              helperText={erro[0] ? erro[0].value : ''}
              value={input == null ? '' : input}
              defaultValue={answer ? answer[0] : ''}
              onChange={(e) => {
                if (erro !== null && erro.length > 0) {
                  delete erro[0].value;
                }
                setInput(e.target.value);
              }}
              // onBlur={e => {handleInputSubmit(input)}}
              className={classes.textField}
              variant="outlined"
              inputProps={{ maxLength: 3, minLength: 2, readOnly: readOnly }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
            <span className="text-regular text-info-input">mg/dl</span>
            <div>
              {additional_data.options.length > 0 ? (
                <div className={classes.withoutLabel}>
                  <DRadio
                    inline
                    name={name}
                    // value={additional_data.options.value}
                    onChange={handleChange}
                    value={options}
                    disabled={readOnly}
                    checked={additional_data.options.value}
                    options={additional_data.options}
                  />
                </div>
              ) : (
                'Não existe dados cadastrados'
              )}
            </div>
          </Box>
          <span style={{ fontSize: 12 }} className="text-light">
            {helper_text ? helper_text : ''}
          </span>
          <span
            style={{ fontSize: 12, color: '#f44336' }}
            className="text-light"
          >
            {showError ? 'Glicemia Capilar deve ser no mínimo 2 dígitos' : ''}
          </span>
        </div>
      </div>
    </section>
  );
};

export default EBCapillaryBloodGlucose;
