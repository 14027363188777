import { React, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import DInput from '../../../../shared/DInput';
import DSwitch from '../../../../shared/DSwitch';
import { getAllProfilesNotPaginate } from './../../../../../api/profiles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
    marginBottom: 24,
    position: 'relative',
  },

  card_title: {
    color: theme.palette.neutral.black,
    fontSize: 16,
    margin: '0 0 16px',
  },

  card_subtitle: {
    color: theme.palette.neutral.black,
    fontSize: 14,
    margin: '0 0 16px',
  },

  card_delete: {
    position: 'absolute',
    top: 2,
    right: 8,
    color: `${theme.palette.support.error} !important`,
  },

  card_history: {
    position: 'absolute',
    top: 2,
    right: 8,
    color: `${theme.palette.primary.light} !important`,
  },

  card_switch_container: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 0 -12px',
    height: 30,
  },

  card_outline: {
    padding: 16,
    marginBottom: 24,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 4,
    fontSize: 18,
  },
}));

function Configuracao(props) {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [viewHistory, setViewHistory] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [allProfiles, setAllProfiles] = useState(false);

  useEffect(() => {
    setName(props.config.name);
    setViewHistory(props.config.viewHistory);
    setProfiles(props.config.profiles);
  }, [props.config]);

  useEffect(() => {
    let allProfile = true;

    profiles.map((profile) => {
      if (!profile.checked) allProfile = false;
    });

    setAllProfiles(allProfile);
  }, [profiles]);

  return (
    <>
      <Card className={classes.card}>
        <h5 className={classes.card_title} style={{ marginBottom: 8 }}>
          Nome
        </h5>
        <DInput
          id="nome"
          name="name"
          placeholder="Nome"
          type="text"
          error={props.error ? true : false}
          helperText={props.error ? props.error : ''}
          fullWidth
          inputProps={{
            maxLength: 30,
          }}
          onChange={(e) => {
            setName(e.target.value);
            props.onChange(e.target.value, viewHistory, profiles);
          }}
          value={name}
        />
      </Card>
      <Card
        className={classes.card}
        style={{ display: 'flex', alignItems: 'center', paddingLeft: 6 }}
      >
        <DSwitch
          checked={viewHistory}
          onChange={(e) => {
            let new_view_history = !viewHistory;
            setViewHistory(new_view_history);
            props.onChange(name, new_view_history, profiles);
          }}
          name="view_history"
        />
        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
          Mostrar histórico da medida
        </Typography>
      </Card>
      <Card className={classes.card}>
        <h5 className={classes.card_title}>Perfis que podem utilizá-la</h5>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item md={12}>
            <Grid container alignItems="center">
              <Grid item style={{ marginLeft: -14 }}>
                <DSwitch
                  checked={allProfiles}
                  onChange={(e) => {
                    let new_all_profiles = !allProfiles;

                    let new_profiles = profiles.map((profile) => {
                      return {
                        id: profile.id,
                        description: profile.description,
                        checked: new_all_profiles,
                      };
                    });
                    setAllProfiles(new_all_profiles);
                    setProfiles(new_profiles);
                    props.onChange(name, viewHistory, new_profiles);
                  }}
                  name={`profile-option-0`}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" className="text-medium text-st">
                  Todos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {profiles.map((option, index) => (
            <Grid key={`config-profiles-${index}`} item md={6}>
              <Grid container alignItems="center">
                <Grid item style={{ marginLeft: -14 }}>
                  <DSwitch
                    key={`profile-option-${index}`}
                    checked={option.checked}
                    onChange={(e) => {
                      let new_profiles = [...profiles];
                      new_profiles[index].checked = !option.checked;

                      setProfiles(new_profiles);
                      props.onChange(name, viewHistory, new_profiles);
                    }}
                    name={`profile-option-${index}`}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1" className="text-medium text-st">
                    {option.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Card>
    </>
  );
}

Configuracao.defaultProps = {
  error: false,
  config: {
    name: null,
    viewHistory: false,
    profiles: [],
  },
  onChange: function (name, viewHistory, profiles) {
    console.log(name);
    console.log(viewHistory);
    console.log(profiles);
  },
};

export default Configuracao;
