import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { joinRoomPatient } from '../../../api/videocall';
import { useNewHealthAttendance } from '../../../context/NewHealthAttendance';
import FloatingChat from '../../AtendimentoImediato/components/floatingChat';
import Guests, { Guest } from './components/Guests';
import Title from './components/Title';
import ToolBar from './components/ToolBar';
import { useStyles } from './theme';
import useVideoCall, { Participant } from './useVideoCall';

const DebugVideo = (props) => {
  return (
    <div>
      <Participant
        key={props.activeRoom.localParticipant.sid}
        participant={props.activeRoom.localParticipant}
      />
    </div>
  );
};

export default function VideoCall(props) {
  const classes = useStyles();
  const [chat, setChat] = useState(null);
  const [browser, setBrowser] = useState(false);
  const [timer, setTimer] = useState(0);
  const [activeParticipants, setActiveParticipants] = useState([]);
  const [featuredSID, setFeaturedSID] = useState(null);
  const { patient, healthAttendance } = useNewHealthAttendance();

  const { setFeaturedVideo } = props;

  const {
    activeRoom,
    participants,
    onMute,
    onUnmute,
    leaveCall,
    disableVideo,
    enableVideo,
    roomInfo,
    dominantSpeaker,
    isWaiting,
  } = useVideoCall();

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [setTimer]);

  const localParticipant = useMemo(() => {
    if (!activeRoom) return;

    const element = (
      <Participant
        key={activeRoom.localParticipant.sid}
        participant={activeRoom.localParticipant}
      />
    );

    setFeaturedVideo(activeRoom.localParticipant);

    return element;
  }, [activeRoom, setFeaturedVideo]);

  const remoteFeaturedParticipant = useMemo(() => {
    if (!activeRoom) return;

    if (
      participants.filter((p) => p.sid !== activeRoom.localParticipant.sid)
        .length === 0
    ) {
      setFeaturedSID(activeRoom.localParticipant.sid);

      return (
        <Participant
          key={activeRoom.localParticipant.sid}
          participant={activeRoom.localParticipant}
        />
      );
    }

    const featured = participants.filter(
      (p) => p.sid !== activeRoom.localParticipant.sid,
    )[0];

    setFeaturedSID(featured.sid);

    const element = <Participant key={featured.sid} participant={featured} />;

    return element;
  }, [activeRoom, participants]);

  const participantsJsx = useMemo(() => {
    if (!activeRoom) return;

    // Filter out the featured user from the Guests.
    var tracks = participants
      .filter((p) => p.sid !== featuredSID)
      .map((participant) => {
        return <Participant key={participant.sid} participant={participant} />;
      });

    return [localParticipant, ...tracks];
  }, [participants, localParticipant, activeRoom, featuredSID]);

  const callPatient = () => {
    if (patient && roomInfo && roomInfo.conferenceId) {
      joinRoomPatient(localStorage.getItem('token'), {
        roomName: healthAttendance && healthAttendance.id,
        patient_id: patient.id,
        health_attendance_id: healthAttendance && healthAttendance.id,
        conference_id: roomInfo.conferenceId,
        typeVideoCall: props.typeVideoCall,
      })
        .then((res) => {
          toast.success('Convite reenviado ao paciente.');
        })
        .catch((err) => console.log(err));
    }
  };

  const WaitMessage = () => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        style={{ top: '50%', width: '100%', position: 'absolute' }}
      >
        <Typography
          variant="h5"
          style={{ color: 'white', textAlign: 'center' }}
        >
          Aguarde o participante para começar.
        </Typography>
      </Box>
    );
  };

  const remoteActiveParticipant = {
    element:
      participants && participants.length > 0 ? (
        <Participant
          key={'remoteParticipantFeatured'}
          participant={participants[0]}
        />
      ) : null,
    sid: participants && participants.length > 0 ? participants[0].sid : null,
  };

  const participantsData = activeRoom
    ? [
        <Participant
          key={activeRoom.localParticipant.sid}
          participant={activeRoom.localParticipant}
        />,
        ...participants?.map((participant) =>
          participants[0].sid !== participant.sid ? (
            <Participant key={participant.sid} participant={participant} />
          ) : (
            ''
          ),
        ),
      ]
    : [];

  return (
    <>
      {
        <Box
          id="maxVideoBox"
          width={!browser ? (!chat ? '100%' : '70%') : 369}
          height={!browser ? '100%' : 187}
          overflow="hidden"
          top={browser ? window.innerHeight - (187 + 38) : 0}
          right={browser ? 38 : false}
          left={browser ? false : 0}
          position="fixed"
          zIndex="tooltip"
          borderRadius={browser ? 8 : 0}
          bgcolor="rgba(39, 39, 39, 1)"
        >
          {participants.filter((x) => x.sid !== activeRoom.localParticipant.sid)
            .length > 0 ? (
            <Guest guest={remoteActiveParticipant.element} />
          ) : (
            <WaitMessage />
          )}

          <Title solicitante={props.solicitante} />
          <ToolBar
            duration={props.duration}
            loading={isWaiting}
            onFinish={(e) => {
              leaveCall();
              props.onFinish(true);
            }}
            onAddUser={(e) => {
              callPatient();
            }}
            onBrowser={(e) => {
              setBrowser(true);
              const maxVideoBox = document.getElementById('maxVideoBox');
              const titleRequestingUser = document.getElementById(
                'titleRequestingUser',
              );
              const toolbarVideo = document.getElementById('toolbarVideo');
              const participantsList =
                document.getElementById('participantsList');

              if (maxVideoBox) {
                const isMaximized = maxVideoBox.style.width === '369px';
                const width = isMaximized ? (chat ? '70%' : '100%') : '369px';
                const height = isMaximized ? '100%' : '187px';
                const left = isMaximized ? '0' : 'inherit';
                const right = isMaximized ? 'inherit' : '38px';
                const top = isMaximized
                  ? '0'
                  : `${window.innerHeight - (187 + 38)}px`;

                maxVideoBox.style.position = 'fixed';
                maxVideoBox.style.bottom = '50px';
                maxVideoBox.style.left = left;
                maxVideoBox.style.right = right;
                maxVideoBox.style.width = width;
                maxVideoBox.style.height = height;
                maxVideoBox.style.overflow = 'hidden';
                maxVideoBox.style.zIndex = '9999';
                maxVideoBox.style.top = top;

                if (toolbarVideo) {
                  toolbarVideo.style.transform = isMaximized
                    ? 'scale(1)'
                    : 'scale(0.5)';
                  toolbarVideo.style.transition = 'transform 0.3s ease';
                  toolbarVideo.style.bottom = isMaximized ? '34px' : '1px';
                }

                if (titleRequestingUser) {
                  titleRequestingUser.style.display = isMaximized
                    ? 'block'
                    : 'none';
                }

                if (participantsList) {
                  participantsList.style.display = isMaximized
                    ? 'block'
                    : 'none';
                }
              }
            }}
            onChat={(e) => {
              const maxVideoBox = document.getElementById('maxVideoBox');
              if (maxVideoBox && maxVideoBox.style.width === '369px') {
                toast.info(
                  'Para utilizar o chat, a janela da videochamada precisa estar maximizada.',
                );
              } else {
                setChat(!chat);
                if (chat) {
                  maxVideoBox.style.width = '100%';
                } else {
                  maxVideoBox.style.width = '70%';
                }
              }
            }}
            onVideo={(e) => {
              enableVideo();
            }}
            onNotVideo={(e) => {
              disableVideo();
            }}
            onMute={(e) => onMute()}
            onUnmute={(e) => onUnmute()}
            timer={timer}
            setTimer={setTimer}
          />

          {participantsData ? (
            <Guests
              guests={participantsData.filter(
                (x) => x.sid !== remoteActiveParticipant.sid,
              )}
            />
          ) : null}
        </Box>
      }

      {/* Chat da videochamada */}
      {chat && (
        <Box
          id="chatBox"
          width="30%"
          height="100%"
          top={0}
          right={0}
          overflow="auto"
          position="fixed"
          zIndex="tooltip"
          bgcolor="rgba(39, 39, 39, 1)"
        >
          {chat && healthAttendance ? (
            <FloatingChat
              openChat={chat}
              idUser={localStorage.getItem('uid')}
              videocall
              notFlutuant
              idAtendimento={healthAttendance.id}
            />
          ) : (
            <Grid
              classes={{ root: classes.boxTitleContent }}
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <CircularProgress
                  classes={{ colorPrimary: classes.textWhite }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  classes={{ root: classes.textWhite }}
                  align="center"
                >
                  Carregando chat
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
}

VideoCall.defaultProps = {
  duration: '0:00',
  solicitante: 'João da Silva',
  guest: [],
  featuredGuest: null,
  chat: true,
  patientId: null,
  healthAttendanceId: null,
  onChat: function () {
    console.log('onChat');
  },
  onAddUser: function () {
    console.log('onAddUser');
  },
  onFinish: function () {
    console.log('onFinish');
  },
};
