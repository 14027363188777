import { Component } from 'react';

import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';

import FormsContent from './component/Forms/FormsContent';

import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import env from 'react-dotenv';

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      construtor: null,
      construtorOpen: false,
    };

    this.pusher = new Pusher(env.PUSHER_APP_KEY, {
      cluster: env.PUSHER_APP_CLUSTER,
    });
  }

  componentDidMount() {
    const channel = this.pusher.subscribe('my-construtor');
    channel.bind('finish-form', this.fechar.bind(this));
  }

  componentWillUnmount() {
    this.pusher.unsubscribe('my-construtor');
    this.pusher.disconnect();
  }

  fechar(data) {
    if (parseInt(data.id) === this.state.construtor) {
      toast.success('Processo finalizado com sucesso.');
      this.setState({ ...this.state, construtor: null, construtorOpen: false });
    }
  }

  render() {
    return (
      <FormsContent
        onOpenConstrutor={(formId) =>
          this.setState({
            ...this.state,
            construtorOpen: true,
            construtor: formId,
          })
        }
        onCloseConstrutor={(e) =>
          this.setState({
            ...this.state,
            construtorOpen: false,
            construtor: null,
          })
        }
        construtorOpen={this.state.construtorOpen}
        construtor={this.state.construtor}
      />
    );
  }
}

export default Forms;
