import { Grid } from '@material-ui/core';
import generoCode from '../../../../../../../../assets/dados/genero.json';
import justificativaDadosCode from '../../../../../../../../assets/dados/justificativasDadosIncompletos.json';
import orientacoesSexuaisCode from '../../../../../../../../assets/dados/orientacoesSexuais.json';
import pronomesCode from '../../../../../../../../assets/dados/pronomes.json';
import racasCode from '../../../../../../../../assets/dados/racas.json';
import sexoCode from '../../../../../../../../assets/dados/sexo.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { IError } from '../../../../../../../../hooks/useNewError';
import { useStyles } from './styles';

import { useMemo } from 'react';
import { IUserDominios } from '../../../../../../../../types/userDominios';
import { Address } from './Address';
import { BrazilianIdentity } from './BrazilianIdentity';
import { Cns } from './Cns';
import { ComplementaryData } from './ComplementaryData';
import { DemographicData } from './DemographicData';
import { ForeignIdentity } from './ForeignIdentity';
import { MainData } from './MainData';
import { NaturalizedIdentity } from './NaturalizedIdentity';
import { IPhone, Phones } from './Phones';
import { Pregnant } from './Pregnant';
import { Units } from './Units';

const nacionalidades = [
  { id: 1, value: 'Brasileiro' },
  { id: 2, value: 'Naturalizado' },
  { id: 3, value: 'Estrangeiro' },
];

interface EditDetailsProps {
  phones: IPhone[];
  setPhones: (phones: IPhone[]) => void;
  grauDeRelacionamentoList: {
    id: number;
    value: string;
  }[];
  unidadeRefs: {
    id: number;
    value: string;
  }[];
  unidadesVinculadas: {
    id: number;
    value?: string;
  }[];
  unidadeRef: number | undefined;
  setUnidadeRef: (id: number | undefined) => void;
  unidadeVinculada: {
    id: number;
    value?: string;
  }[];
  setUnidadeVinculada: (
    unidadeVinculada: {
      id: number;
      value?: string;
    }[],
  ) => void;
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
  error: IError;
}

export const EditDetails = ({
  phones,
  setPhones,
  grauDeRelacionamentoList,
  unidadeRefs,
  unidadesVinculadas,
  removeError,
  setErrors,
  error,
  setUnidadeRef,
  setUnidadeVinculada,
  unidadeRef,
  unidadeVinculada,
}: EditDetailsProps) => {
  const classes = useStyles();
  const userDominios = localStorage.getItem('user_dominios');
  const userDominiosObj = JSON.parse(userDominios as string) as IUserDominios;

  const { dadosPrincipais } = usePatient();

  const states =
    userDominiosObj?.state?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  const etnias =
    userDominiosObj?.etnia?.map((item) => {
      return { id: item.id, value: item.description };
    }) || [];

  const countries =
    userDominiosObj?.country?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  const sexoMap = useMemo(
    () =>
      sexoCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  const justificativaDadosMap = useMemo(
    () =>
      justificativaDadosCode.map((item) => {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  const orientacoesSexuaisMap = useMemo(
    () =>
      orientacoesSexuaisCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const generosMap = useMemo(
    () =>
      generoCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const pronomeMap = useMemo(
    () =>
      pronomesCode.map((x) => {
        return { id: x.id, value: x.name };
      }),
    [],
  );

  const racasMap = useMemo(
    () =>
      racasCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  return (
    <>
      {/* DADOS PRINCIPAIS */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12} className={classes.field_padding}>
            <h5 className={classes.dados_titulo}>Dados Principais</h5>
          </Grid>

          <MainData
            errors={error}
            removeError={removeError}
            setErrors={setErrors}
            generosMap={generosMap}
            justificativaDadosMap={justificativaDadosMap}
            nacionalidades={nacionalidades}
            orientacoesSexuaisMap={orientacoesSexuaisMap}
            pronomeMap={pronomeMap}
            sexoMap={sexoMap}
          />
        </Grid>
      </div>

      {/* DOCUMENTOS */}
      {dadosPrincipais.nacionalidade && (
        <div className={classes.dados_container}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              {dadosPrincipais.nacionalidade === 1 && (
                <BrazilianIdentity
                  errors={{ ...error }}
                  removeError={removeError}
                  setErrors={setErrors}
                  states={states}
                />
              )}

              {dadosPrincipais.nacionalidade === 2 && (
                <NaturalizedIdentity
                  errors={{ ...error }}
                  removeError={removeError}
                  setErrors={setErrors}
                  states={states}
                />
              )}

              {dadosPrincipais.nacionalidade === 3 && (
                <ForeignIdentity
                  errors={{ ...error }}
                  removeError={removeError}
                  setErrors={setErrors}
                  countries={countries}
                />
              )}
            </Grid>
          </Grid>
        </div>
      )}

      {/* CNS */}
      <div className={classes.dados_container}>
        <Cns errors={error} />
      </div>

      {/* DADOS DEMOGRAFICOS */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <h5 className={classes.dados_titulo}>Dados demográficos</h5>
          </Grid>

          <DemographicData
            countries={countries}
            states={states}
            etnias={etnias}
            racasMap={racasMap}
            removeError={removeError}
            errors={error}
            setErrors={setErrors}
          />
        </Grid>
      </div>

      {/* GESTANTE */}
      {sexoMap.find((x) => x.id === dadosPrincipais.sexo)?.value ===
        'Feminino' && (
        <div className={classes.dados_container}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Gravidez</h5>
            </Grid>

            <Pregnant
              errors={{ ...error }}
              removeError={removeError}
              setErrors={setErrors}
            />
          </Grid>
        </div>
      )}

      {/* TELEFONES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <h5 className={classes.dados_titulo}>Telefones</h5>
          </Grid>

          <Phones
            errors={{ ...error }}
            removeError={removeError}
            phones={phones}
            setPhones={setPhones}
          />
        </Grid>
      </div>

      {/* DADOS COMPLEMENTARES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Dados complementares</h5>
          </Grid>

          <ComplementaryData
            errors={error}
            removeError={removeError}
            grauDeRelacionamentoList={grauDeRelacionamentoList}
          />
        </Grid>
      </div>

      {/* UNIDADES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Unidades</h5>
          </Grid>

          <Units
            errors={error}
            removeError={removeError}
            setUnidadeRef={setUnidadeRef}
            unidadeRef={unidadeRef}
            setUnidadeVinculada={setUnidadeVinculada}
            unidadeVinculada={unidadeVinculada}
            unidadeRefs={unidadeRefs}
            unidadesVinculadas={unidadesVinculadas}
          />
        </Grid>
      </div>

      <div
        className={classes.dados_container}
        style={{ marginBottom: 0, border: 0 }}
      >
        <Grid container spacing={1}>
          <Grid item md={12} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Endereço</h5>
          </Grid>

          <Address
            errors={error}
            removeError={removeError}
            setErrors={setErrors}
            states={states}
          />
        </Grid>
      </div>
    </>
  );
};
