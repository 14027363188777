import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircle from '@material-ui/icons/AddCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useContext } from 'react';
import { useAnamneseFormFilter } from '../../../../../../context/AnamneseFormFilterContext';
import { AppContext } from '../../../../../../context/AppContext';
import {
  PatientContext,
  usePatient,
} from '../../../../../../context/usePatient';
import DButton from '../../../../../shared/DButton';
import './index.scss';

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.background.cloud,
  },
  paciente__topo: {
    position: 'absolute',
    left: 0,
    top: 88,
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.snow,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
    zIndex: 1,
  },
  icon: {
    marginLeft: -7,
    cursor: 'pointer',
    height: 18.77,
  },
}));

export function HeaderPatient(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { preForm, loading } = usePatient(PatientContext);
  const { resetFilter } = useAnamneseFormFilter();

  const { userInfo, selectedProfile } = useContext(AppContext);

  const [selectedUnity, setSelectedUnity] = React.useState('');

  React.useEffect(() => {
    if (userInfo && selectedProfile) {
      if (Array.isArray(selectedProfile.units)) {
        var _selectedUnity = selectedProfile.units.find(
          (x) => x.medical_unit_id === userInfo.unidade,
        );

        if (_selectedUnity) {
          setSelectedUnity(_selectedUnity.description || '');
        }
      }
    }
  }, [userInfo, selectedProfile]);

  return (
    <Grid item className={classes.paciente__topo}>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <ArrowBackIosIcon
                  classes={{ root: classes.icon }}
                  onClick={(e) => {
                    resetFilter();
                    props.cadastrar(true);
                  }}
                  color="primary"
                  size={24}
                />
              </Grid>
              <Grid item>
                <Typography variant="h4" component="h1">
                  Nova Solicitação
                </Typography>
              </Grid>
              <Grid item>
                <span className="line-separator"></span>
              </Grid>
              <Grid item>
                <Typography variant="body1" className="text-regular text-st">
                  {selectedUnity}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {loading ? (
              <>
                <CircularProgress size={15} color="inherit" /> Salvando...
              </>
            ) : (
              <DButton
                form="anamnese-pre-form"
                className="btn-success"
                variant="primary"
                icon={<AddCircle fontSize="small" />}
                size="md"
                onClick={(e) =>
                  preForm.current.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  )
                }
              >
                Solicitar atendimento
              </DButton>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
