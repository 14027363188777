import React, { useEffect, useState, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Backdrop,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import EmailCreateConfig from './EmailCreateConfig';
import { getAllCategories, getTemplateEmail } from './api/EmailConfig';

const styles = (theme) => ({
  barOpened: {
    background: theme.palette.background.snow,
    height: 55,
    width: 5,
    borderRadius: 8,
    marginRight: 18,
  },
  barClosed: {
    background: theme.palette.primary.light,
    height: 55,
    width: 5,
    borderRadius: 8,
    marginRight: 18,
  },
  barInner: {
    height: 64,
    width: 5,
    background: theme.palette.primary.light,
    borderRadius: 8,
    marginRight: 8,
    marginLeft: 32,
  },
  titleTop: {
    marginTop: 32,
    marginBottom: 24,
    marginLeft: 14,
  },
  goEditor: {
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
  },
  activeBox: {
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    marginBottom: 8,
  },
  normalBox: {
    background: theme.palette.background.cloud,
    marginBottom: 8,
  },
});

class EmailConfig extends Component {
  token;
  emailInit = {
    title: '',
    category_id: null,
    versionId: null,
    body: '',
    signature: '',
    footer: '',
    isToDelete: false,
    variables: [],
  };

  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');

    this.state = {
      loadingCategories: false,
      showEmailEditor: false,
      categories: [],
      emailSelected: this.emailInit,
    };
  }

  componentDidMount() {
    this.listCategories();
  }

  showCategoryEmails(e, index) {
    var new_category = this.state.categories;
    new_category[index].isOpened = !new_category[index].isOpened;
    this.setState({ ...this.state, categories: new_category });
  }

  showEmailEditor(index, emailIndex, showEmailEditor) {
    const email = this.state.categories[index].emails[emailIndex];
    this.setState({ ...this.state, loadingCategories: true });
    getTemplateEmail(this.token, email.id, email.versionId).then((res) => {
      const emailSel = {
        ...email,
        body: res.template.body,
        footer: res.template.footer,
        signature: res.template.sign,
        title: res.template.title,
        variables: res.template.variables,
      };

      this.setState({
        ...this.state,
        showEmailEditor,
        emailSelected: emailSel,
        loadingCategories: false,
      });
    });
  }

  hideEmailEditor() {
    this.setState({ ...this.state, showEmailEditor: false }, () => {
      this.listCategories();
    });
  }

  listCategories() {
    this.setState({ ...this.state, listCategories: true });
    getAllCategories(this.token).then((response) => {
      if (typeof response !== 'undefined') {
        this.setState({ ...this.state, categories: response }, () => {
          this.setState({ ...this.state, listCategories: false });
        });
      }
    });
  }

  renderCategoryEmails(index) {
    const { classes } = this.props;
    const category = this.state.categories[index];
    if (category.isOpened && category.emails.length > 0) {
      return (
        <>
          {category.emails.map((email, emailIndex) => (
            <Card className={classes.normalBox}>
              <CardContent
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Grid
                    item
                    md={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <div className={classes.barInner}></div>
                    <div>
                      <Typography style={{ fontWeight: 500 }}>
                        {email.title}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item md={4}>
                    <div
                      style={{
                        marginTop: -24,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        className={classes.goEditor}
                        size="sm"
                        onClick={(e) =>
                          this.showEmailEditor(index, emailIndex, true)
                        }
                      >
                        Ir para o editor
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  }

  renderCategory(index) {
    const { classes } = this.props;
    const category = this.state.categories[index];
    return (
      <>
        <CardContent
          style={{ padding: 8, display: 'flex', alignItems: 'center' }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
              onClick={(e) => this.showCategoryEmails(e, index)}
            >
              <div
                className={
                  category.isOpened ? classes.barOpened : classes.barClosed
                }
              ></div>
              <Typography variant="h5">{category.name}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  renderCategories() {
    const { classes } = this.props;
    return this.state.categories.map((category, index) => (
      <>
        <Card
          className={category.isOpened ? classes.activeBox : classes.normalBox}
        >
          {this.renderCategory(index)}
        </Card>

        {this.renderCategoryEmails(index)}
      </>
    ));
  }

  render() {
    const { classes } = this.props;

    if (!this.state.showEmailEditor) {
      return (
        <>
          <Container className="tema-light">
            <Backdrop
              style={{ zIndex: 3000, color: 'white' }}
              open={this.state.loadingCategories}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Typography className={classes.titleTop} variant="h3">
              E-mails
            </Typography>

            {this.renderCategories()}
          </Container>
        </>
      );
    } else {
      return (
        <>
          <EmailCreateConfig
            emailParam={this.state.emailSelected}
            callbackCloseEmailEditor={() => this.hideEmailEditor()}
          />
        </>
      );
    }
  }
}

export default withStyles(styles)(EmailConfig);
