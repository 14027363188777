import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { fetchCurrentUserDetails } from '../../api/user';
import EmailConfig from '../EmailConfig/EmailConfig';
import Faq from '../Faq/Faq';
import TermosUso from '../TermosUso';
import Biomedidas from './Biomedidas';
import Comorbidades from './Comorbidades';
import Especialidades from './Especialidades';
import Forms from './Forms';
import Gravacoes from './Gravacoes';
import Grupos from './Grupos';
import Ligacoes from './Ligacoes';
import Linhas from './Linhas';
import Profiles from './Profiles';
import Sintomas from './Sintomas';
import Temas from './Temas';
import Unidades from './Unidades';

class Configuracao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: false,
    };
  }

  componentDidMount() {
    fetchCurrentUserDetails(localStorage.getItem('token'))
      .then((res) => {
        if (res.error) this.setState({ login: true });
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <>
        {this.state.login ? <Redirect to="/" /> : ''}
        {this.props.active === 'unidades' && <Unidades />}
        {this.props.active === 'especialidades' && <Especialidades />}
        {this.props.active === 'linhas' && <Linhas />}
        {this.props.active === 'grupos' && <Grupos />}
        {this.props.active === 'perfis' && <Profiles />}
        {this.props.active === 'comorbidades' && <Comorbidades />}
        {this.props.active === 'sintomas' && <Sintomas />}
        {this.props.active === 'forms' && <Forms />}
        {this.props.active === 'faq' && <Faq />}
        {this.props.active === 'email' && <EmailConfig />}
        {this.props.active === 'biomedidas' && <Biomedidas />}
        {this.props.active === 'gravacoes' && <Gravacoes />}
        {this.props.active === 'ligacoes' && <Ligacoes />}
        {this.props.active === 'temas' && <Temas />}
        {this.props.active === 'termosUso' && <TermosUso />}
      </>
    );
  }
}

export default Configuracao;
