import { Card, CardContent, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { getPatientById } from 'api/patient';
import {
  AgendaMedico,
  getHasScheduleTime,
  getProfessionalScheduleData,
  getScheduleTimesByIdAndMonth,
  putScheduleTime,
  unlockScheduleById
} from 'api/schedule/agenda-medico';
import { getDominios } from 'api/unidades';
import { getUsers } from 'api/user';
import CalendarMonth from 'component/shared/CalendarMonth';
import ModalCancel from 'component/shared/CalendarMonth/components/Modais/ModalCancel';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import TInputDateBase from 'component/shared/TInputDateBase/TInputDateBase';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import TSelectNativeMultiple from 'component/shared/TSelectNativeMultiple/TSelectNativeMultiple';
import { useAppProvider } from 'context/AppContext';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import useError from 'hooks/useError';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    minHeight: 700,
  },
  btnReagendar: {
    textTransform: 'none !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important',
    height: 36,
    marginRight: '10px !important',
  },
  btnCancelar: {
    textTransform: 'none !important',
    color: `${theme.palette.support.error} !important`,
    fontWeight: '400 !important',
    height: 36,
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 3,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  rootInput: {
    borderRadius: '8px',
  },
  card: {
    background: theme.palette.background.snow,
    border: `2px solid ${theme.palette.neutral.midlightgray} !important`,
    marginBottom: 28.8,
  },
  cardBody: {
    padding: 15,
    paddingBottom: '15px !important',
  },
}));

export default function AgendaVisualizar({ scheduleId, handleMenuItem }) {
  const classes = useStyles();

  const { LoadingControl } = useAppProvider();

  const {
    setSegunda,
    setTerca,
    setQuarta,
    setQuinta,
    setSexta,
    setSabado,
    setDomingo,
    especialidade,
    setEspecialidade,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
    dataInicial,
    setDataInicial,
    setDadosAgendamento,
    dataFinal,
    setDataFinal,
    intervaloAtendimento,
    setIntervaloAtendimento,
    setNomePaciente,
    ocupacao,
    setOcupacao,
    conselho,
    setConselho,
    numeroRegistro,
    setNumeroRegistro,
    faixaEtariaMaior,
    setFaixaEtariaMaior,
    faixaEtariaMenor,
    setFaixaEtariaMenor,
    faixaEtariaMaiorSelecionar,
    setFaixaEtariaMaiorSelecionar,
    faixaEtariaMenorSelecionar,
    setFaixaEtariaMenorSelecionar,
    sexo,
    setSexo,
    setDataScheduleItem,
    setPatientDocuments,
    setDays,
    setHorariosAgendados: setHorariosAgendadosContext,
  } = useConfiguracaoAgenda();

  const {
    openScheduleOfTheDay,
    dayBlock,
    setDayBlock,
    setOpenModalBloqueioDia,
    setOpenModalBloqueio,
    setOpenModalDesbloqueio,
    monthIndex,
  } = useContext(GlobalContext);

  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [anamneseForms, setAnamneseForms] = useState([]);
  const [professionalData, setProfessionalData] = useState({});
  const [horariosAgendados, setHorariosAgendados] = useState([]);
  const [dadosAgenda, setDadosAgenda] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);

  // Controls
  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [modalCancelarAgenda, setModalCancelarAgenda] = useState(false);
  const [bloqueioAtivo, setBloqueioAtivo] = useState(false);
  const [diasBloqueados, setDiasBloqueados] = useState([]);


  const ocupacoes =
    professionalData.ocupacoes?.map((x) => ({
      value: x.code,
      label: x.value,
      ...x,
    })) || [];
  const especialidades =
    professionalData.medical_speciality?.map((x) => ({
      value: x.id,
      label: x.value,
    })) || [];
  const conselhos =
    professionalData.conselhos?.map((x) => ({
      value: x.id,
      label: `${x.tipo}/${x.uf}`,
      ...x,
    })) || [];

  const [error] = useError();

  useEffect(() => {
    (async () => {
    
      const dataAtual =
        String(new Date().getFullYear()) +
        String(new Date().getMonth() + 1).padStart(2, '0') +
        String(new Date().getDate()).padStart(2, '0');
  
      await checarHorariosAgendamento(dataAtual);
  
      getUsers(localStorage.getItem('token'))
        .then((data) => {
          if (data.users && Array.isArray(data.users)) {
            setUsers(
              data.users.filter((usr) => Object.values(usr.conselho).length > 0),
            );
          }
        })
        .catch(console.error);
  
      getDominios(localStorage.getItem('token'))
        .then((res) => {
          setAnamneseForms(
            res.anamnese.map((item) => {
              return {
                value: item.form_id,
                label: item.name,
              };
            }),
          );
        })
        .catch(console.error);
  
      await refreshSchedule();
    })();

    return () => {
      setBloqueioAtivo(false);
      setOpenModalBloqueioDia(false);
      setOpenModalBloqueio(false);
      setOpenModalDesbloqueio(false);
      setSegunda(0, 0, 0, 0);
      setTerca(0, 0, 0, 0);
      setQuarta(0, 0, 0, 0);
      setQuinta(0, 0, 0, 0);
      setSexta(0, 0, 0, 0);
      setSabado(0, 0, 0, 0);
      setDomingo(0, 0, 0, 0);
      setEspecialidade([]);
      setNomePaciente('');
      setFormularios([]);
      setDuracaoAtendimento('00:00');
      setIntervaloAtendimento('00:00');
      setDataInicial(null);
      setDataFinal(null);
      setOcupacao(null);
      setConselho(null);
      setNumeroRegistro(null);
      setSexo('');
      setDays([]);
      setFaixaEtariaMaior(null);
      setFaixaEtariaMenor(null);
      setFaixaEtariaMaiorSelecionar(null);
      setFaixaEtariaMenorSelecionar(null);
    };
  }, []);

  

  useEffect(() => refreshSchedule(), [monthIndex]);

  const handleReagendarCreate = async (horarioAgend, indexHorarioAgend) => {
    try {
      let participants = horarioAgend.schedule_participants[0];
      let patient = participants ? participants.patient : null;

      if (!patient) {
        return toast.error(
          !participants
            ? 'Sem participantes neste agendamento.'
            : 'Sem paciente neste agendamento.',
        );
      }

      setNomePaciente(
        patient?.social_name != '' ? patient?.social_name : patient?.full_name,
      );
      setDuracaoAtendimento(renderHour(horarioAgend.data_time));
      setDataInicial(horarioAgend.data_time);
      setDataScheduleItem(horarioAgend);

      setDadosAgendamento({
        ...dadosAgenda,
        horarioAgendado: horarioAgend,
        fromView: true,
      });

      await getEnderecoPaciente(patient?.id);

      handleMenuItem('reagendar_create');
    } catch (err) {
      console.error(err);
      toast.error('Erro ao reagendar agendamento');
    }
  };

  async function refreshSchedule() {
    if (scheduleId) {
      LoadingControl.showLoading('Carregando os dados do agendamento...');

      const date = moment(new Date(moment().year(), monthIndex)).format('yyyyMM')

      await getScheduleTimesByIdAndMonth(scheduleId, date)
        .then(async (res) => {
          if (res) {
            const schedule = res.schedule
            
            if(!userSelected) {
              await getProfessionalScheduleData(schedule.schedule_professional.user_id)
                .then((data) => {
                  if (data) {
                    setProfessionalData(data);
  
                    setUserSelected({
                      id: schedule.schedule_professional.user_id,
                      label: data.professional.full_name,
                    });
  
                    let _conselho = data.conselhos.find(
                      (x) =>
                        x.formated_value ==
                        schedule.schedule_professional.concil_value,
                    );
  
                    setConselho(_conselho && _conselho.id);
  
                    setDataInicial(schedule.start);
  
                    setDataFinal(schedule.end);
  
                    setSexo(schedule.sex);
  
                    setFormularios(
                      schedule.schedule_form.map((form) => {
                        return {
                          value: form.anamnese_form.form_id,
                          label: form.anamnese_form.name,
                        };
                      }),
                    );
  
                    if (data.conselhos && Array.isArray(data.conselhos)) {
                      setNumeroRegistro(data.conselhos[0].numero);
                    }
                  }
                })
                .catch(console.error);
            }

            setEspecialidade(schedule.schedule_professional.medical_speciality_id);

            setNumeroRegistro(schedule.schedule_professional.document_value);

            setFaixaEtariaMaior(schedule.age_begin);
            setFaixaEtariaMaiorSelecionar(schedule.age_begin_type);
            setFaixaEtariaMenorSelecionar(schedule.age_end_type);
            setFaixaEtariaMenor(schedule.age_end);

            setDuracaoAtendimento(parseIntMinutesToHours(schedule.duration_service));
            setIntervaloAtendimento(
              parseIntMinutesToHours(schedule.interval_attendance),
            );

            setDadosAgenda(schedule);

            setDayBlock(
              schedule.schedule_blocked_times
                .filter((x) => !x.justification.includes('Desbloqueio'))
                .map((x) => {
                  return {
                    day: x.date.split('-').reverse().join('/'),
                    block: true,
                    period: !x.all_day_long,
                  };
                }),
            );

            setDiasBloqueados(
              schedule.schedule_blocked_times.filter(
                (x) => !x.justification.includes('Desbloqueio'),
              ),
            );

            const dist_days = [];

            schedule.schedule_attendance_type_distribution.forEach((item, index) => {
              if (dist_days['dayweek' + item.day_week]) {
                dist_days['dayweek' + item.day_week] = {
                  ...dist_days['dayweek' + item.day_week],
                  [item.type]: item.quantity,
                };
              } else {
                dist_days['dayweek' + item.day_week] = {
                  [item.type]: item.quantity,
                };
              }
            });

            setSegunda(
              dist_days['dayweek' + 1]?.primeiraConsulta,
              dist_days['dayweek' + 1]?.retorno,
              dist_days['dayweek' + 1]?.encaixe,
              dist_days['dayweek' + 1]?.total,
            );
            setTerca(
              dist_days['dayweek' + 2]?.primeiraConsulta,
              dist_days['dayweek' + 2]?.retorno,
              dist_days['dayweek' + 2]?.encaixe,
              dist_days['dayweek' + 2]?.total,
            );
            setQuarta(
              dist_days['dayweek' + 3]?.primeiraConsulta,
              dist_days['dayweek' + 3]?.retorno,
              dist_days['dayweek' + 3]?.encaixe,
              dist_days['dayweek' + 3]?.total,
            );
            setQuinta(
              dist_days['dayweek' + 4]?.primeiraConsulta,
              dist_days['dayweek' + 4]?.retorno,
              dist_days['dayweek' + 4]?.encaixe,
              dist_days['dayweek' + 4]?.total,
            );
            setSexta(
              dist_days['dayweek' + 5]?.primeiraConsulta,
              dist_days['dayweek' + 5]?.retorno,
              dist_days['dayweek' + 5]?.encaixe,
              dist_days['dayweek' + 5]?.total,
            );
            setSabado(
              dist_days['dayweek' + 6]?.primeiraConsulta,
              dist_days['dayweek' + 6]?.retorno,
              dist_days['dayweek' + 6]?.encaixe,
              dist_days['dayweek' + 6]?.total,
            );
            setDomingo(
              dist_days['dayweek' + 7]?.primeiraConsulta,
              dist_days['dayweek' + 7]?.retorno,
              dist_days['dayweek' + 7]?.encaixe,
              dist_days['dayweek' + 7]?.total,
            );
          }
        })
        .finally(() => LoadingControl.hideLoading())
        .catch(console.error);
    }
  }

  async function getEnderecoPaciente(idPacient) {
    LoadingControl.showLoading('Carregando reagendamento...');

    return new Promise((res, rej) => {
      getPatientById(localStorage.getItem('token'), idPacient)
        .then((response) => {
          res(response);
          if (response.status) {
            setPatientDocuments(response.details.patient.patient_document);
          } else {
            toast.error(
              'Ocorreu um erro ao selecionar o agendamento. Por favor, tente novamente em alguns minutos.',
            );
          }
        })
        .finally(() => LoadingControl.hideLoading())
        .catch((error) => {
          console.error(error);
          rej(error);
        });
    });
  }

  function parseIntMinutesToHours(min) {
    var h = Math.floor(min / 60);
    var m = min % 60;

    return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
  }

  const handleOpen = (horarioAgend, indexHorarioAgend) => {
    setSelectedTime({ index: indexHorarioAgend, horarioAgend: horarioAgend });
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTime(null);
    setOpen(false);
  };

  function cancelarAgendamento(horarioAgendObj, justificativa) {
    LoadingControl.showLoading('Cancelando agendamento...');

    var dadosObj = {
      status: 'cancelado',
      justification: justificativa,
      reagendar: false,
    };

    putScheduleTime(horarioAgendObj.horarioAgend.id, dadosObj)
      .then((response) => {
        if (response.status !== false) {
          setHorariosAgendadosContext(
            horariosAgendados.splice(horarioAgendObj.index, 1),
          );

          handleClose();
          toast.success('Agendamento cancelado com sucesso');

          if (
            Array.isArray(horariosAgendados) &&
            horariosAgendados.length == 0
          ) {
            handleMenuItem('lista_agendas');
          }
        } else {
          toast.error(
            'Ocorreu um erro ao excluir o agendamento. Por favor, tente novamente em alguns minutos.',
          );
          console.error(response.message);
        }
      })
      .finally(() => LoadingControl.hideLoading())
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao excluir o agendamento. Por favor, tente novamente em alguns minutos.',
        );
        console.error(error);
      });
  }

  function parseStrHourToIntMinutes(hour) {
    const [hours, minutes] = hour.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  const renderDate = (strDate) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format('DD/MM/YYYY');
  };

  const renderHour = (strHour) => {
    const hourObj = new Date(strHour);
    const momentObj = moment(hourObj);
    return momentObj.format('HH:mm');
  };

  async function checarHorariosAgendamento(data) {
    LoadingControl.showLoading('Carregando agendamentos...');
    return new Promise((resolve, reject) => {
      AgendaMedico.getSchedulesByDateAndId(data, scheduleId)
        .then((res) => {
          if (res.status) {
            let scheduleTimes = res.scheduleTimes || [];
            setHorariosAgendados(scheduleTimes);
          }

          resolve(res);
        })
        .finally(() => LoadingControl.hideLoading())
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  function desbloquearDia(day) {
    unlockScheduleById(scheduleId, {
      days: [
        {
          schedule_id: scheduleId,
          user_id: localStorage.getItem('uid'),
          date: day.split('/').reverse().join('-'),
          time_start: '00:00',
          time_end: '23:59',
          all_day_long: 1,
          justification: 'Desbloqueio',
        },
      ],
    }).finally(async () => {
      setDayBlock(dayBlock.filter((x) => x.day != day));
      toast.success('Desbloqueio realizado com sucesso');
      setOpenModalDesbloqueio(false);
      await refreshSchedule();
    });
  }

  function handleListaPeriodos(
    scheduleId,
    horario = moment().format('DD/MM/YYYY'),
  ) {
    getHasScheduleTime(scheduleId).then((data) => {
      if (data.status === false) {
        const times = data.scheduleDataExtract.map((x) => x[0]);

        const selectedDayTimes = times.filter(
          (x) =>
            x.data_time.slice(0, 10).split('-').reverse().join('/') === horario,
        );

        setHorariosAgendadosContext(selectedDayTimes);
        setDadosAgendamento({ ...dadosAgenda, fromView: true });
        handleMenuItem('lista_agendamentos');
      } else {
        handleMenuItem('lista_agendas');
      }
    });
  }

  async function bloquearHorariosAgendamento() {
    const tempDayBlocks = dayBlock
      .filter((x) => x.temp)
      .map((dia) => {
        return {
          schedule_id: scheduleId,
          date: String(dia.day).split('/').reverse().join('-'),
          time_start: dia.inicioBloqueio || '00:00',
          time_end: dia.fimBloqueio || '23:59',
          all_day_long: Number(Boolean(dia.isFullDay)),
          user_id: localStorage.getItem('uid'),
          justification:
            dia.justificativa == 'Outra'
              ? dia.justificativa + ': ' + dia.otherReason
              : dia.justificativa || '',
        };
      });

    LoadingControl.showLoading('Bloqueando horários...');

    AgendaMedico.blockTimesPerDays(scheduleId, {
      days: tempDayBlocks,
    })
      .then((res) => {
        if (res.status) {
          toast.success('Operação realizada com sucesso');

          setDayBlock(
            dayBlock.map((x) => {
              return {
                ...x,
                day: x.day,
                block: true,
                temp: false,
              };
            }),
          );
        } else {
          setDayBlock(dayBlock.filter((x) => !x.temp));
        }
      })
      .finally(async () => {
        LoadingControl.hideLoading();
        await refreshSchedule();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (professionalData && dadosAgenda) {
      const _ocupacoes =
        professionalData.ocupacoes?.map((x) => ({
          value: x.code,
          label: x.value,
        })) || [];

      let _ocupacao = _ocupacoes?.find(
        (x) => x.label == dadosAgenda.schedule_professional?.document_value,
      );

      setOcupacao(_ocupacao ? _ocupacao.label : null);
    }
  }, [professionalData, dadosAgenda]);

  const checkHorariosConflito = (dadosAgenda = {}, agendadas = []) => {
    if (agendadas.length > 0 && dadosAgenda.period == false) {
      // Tem consultas e está tentando bloquear o dia inteiro;
      return true;
    }

    // Não há horários agendados para conflitos;
    if (agendadas.length === 0) return false;

    const haveConflict = agendadas.filter((cv) => {
      const [dataInicio, dataFim] = [
        moment(
          `${dadosAgenda.day.split('/').reverse().join('-')} ${dadosAgenda.inicioBloqueio}`,
        ),
        moment(
          `${dadosAgenda.day.split('/').reverse().join('-')} ${dadosAgenda.fimBloqueio}`,
        ),
      ];

      let [dataAgenda, horaAgenda] = cv.data_time.split(' ');

      const dataAgendada = moment(`${dataAgenda} ${horaAgenda.slice(0, 5)}`);

      return dataAgendada.isBetween(dataInicio, dataFim);
    });

    return haveConflict.length > 0;
  };

  if (scheduleId === undefined) {
    return <Redirect to="/agenda" />;
  }

  return (
    <>
      {modalCancelarAgenda && (
        <ModalCancel
          open={modalCancelarAgenda}
          onClose={() => setModalCancelarAgenda(false)}
          onCancel={(...props) => console.log('onCancel', props)}
        />
      )}
      <div className={classes.background}>
        <Card>
          <CardContent>
            <h1>Visualizar Agenda</h1>
            <Grid container spacing={5}>
              <Grid item md={4}>
                <InputLabel
                  error={error.nome_profissional}
                  style={{
                    fontSize: 13,
                  }}
                  classes={{
                    root: classes.label,
                    error: classes.errorLabel,
                  }}
                >
                  Nome do profissional *
                </InputLabel>
                {professionalData && professionalData.professional && (
                  <Autocomplete
                    disablePortal
                    options={users.map((x) => ({ id: x.id, label: x.name }))}
                    classes={{
                      root: classes.rootInput,
                    }}
                    onChange={(e, value) => {
                      setUserSelected(value);
                      setConselho({});
                      setNumeroRegistro('');
                    }}
                    disabled
                    name="nome_profissional"
                    id="nome_profissional"
                    defaultValue={{
                      id: professionalData.professional.id,
                      label: professionalData.professional.full_name,
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        {...params}
                      />
                    )}
                  />
                )}

                {professionalData && !professionalData.professional && (
                  <Autocomplete
                    disablePortal
                    options={users.map((x) => ({ id: x.id, label: x.name }))}
                    classes={{
                      root: classes.rootInput,
                    }}
                    disabled
                    onChange={(e, value) => {
                      setUserSelected(value);
                      setConselho({});
                      setNumeroRegistro('');
                    }}
                    name="nome_profissional"
                    id="nome_profissional"
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        {...params}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item md={4}>
                <TSelectNative
                  label="Especialidade"
                  name="especialidade"
                  id="especialidade"
                  required
                  disabled
                  options={especialidades || []}
                  value={especialidade}
                  onChange={(e) => {
                    setEspecialidade(parseInt(e.target.value));
                  }}
                  error={error.especialidade}
                />
              </Grid>
              <Grid item md={4}>
                <TSelectNative
                  label="Ocupação"
                  name="ocupacao"
                  id="ocupacao"
                  required
                  disabled
                  options={ocupacoes || []}
                  value={ocupacao}
                  onChange={(e) => setOcupacao(e.target.value)}
                  error={error.ocupacao}
                />
              </Grid>
              <Grid item md={6}>
                <TSelectNative
                  label="Conselho"
                  name="conselho"
                  id="conselho"
                  error={error.conselho}
                  disabled
                  required
                  options={conselhos || []}
                  value={conselho}
                  onChange={(e) => setConselho(e.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <TInputBase
                  required
                  disabled
                  label="Código do conselho"
                  name="codigo_conselho"
                  placeholder="Código do conselho"
                  value={numeroRegistro}
                />
              </Grid>
              <Grid item md={6}>
                <TInputDateBase
                  autoOk
                  required
                  label="Data Inicial"
                  name="data_inicial"
                  id="dataInicial"
                  placeholder="Data Inicial"
                  invalidDateMessage="Data inválida"
                  minDateMessage="Data não pode ser menor que a data mínima"
                  maxDateMessage="Data não pode ser maior que a data máxima"
                  value={dataInicial}
                  disabled
                  error={error.dataInicial}
                  onChange={(e) => {
                    setDataInicial(e);
                    delete error.dataInicial;
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TInputDateBase
                  required
                  label="Data Final (opcional)"
                  name="data_final"
                  id="dataFinal"
                  placeholder="Data Final"
                  invalidDateMessage="Data inválida"
                  minDateMessage="Data não pode ser menor que a data mínima"
                  maxDateMessage="Data não pode ser maior que a data máxima"
                  value={dataFinal}
                  error={error.dataFinal}
                  disabled
                  onChange={(e) => {
                    setDataFinal(e);
                    delete error.dataFinal;
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <TInputBase
                  label="Faixa etária maior ou igual a"
                  name="faixa_etaria_maior"
                  placeholder="Faixa etária"
                  disabled
                  value={faixaEtariaMaior}
                  onChange={(e) =>
                    setFaixaEtariaMaior(
                      e.target.value.replace(/\D/g, '').slice(0, 2),
                    )
                  }
                />
              </Grid>
              <Grid item md={3}>
                <TSelectNative
                  name="faixa_etaria_maior_selecionar"
                  label="Selecione o ano, mês ou dia"
                  options={[
                    {
                      value: 'day',
                      label: 'Dia(s)',
                    },
                    {
                      value: 'month',
                      label: 'Mês(es)',
                    },
                    {
                      value: 'year',
                      label: 'Ano(s)',
                    },
                  ]}
                  value={faixaEtariaMaiorSelecionar}
                  disabled
                  onChange={(e) =>
                    setFaixaEtariaMaiorSelecionar(e.target.value)
                  }
                />
              </Grid>
              <Grid item md={3}>
                <TInputBase
                  label="Faixa etária menor ou igual a"
                  name="faixa_etaria_menor"
                  placeholder="Faixa etária"
                  value={faixaEtariaMenor}
                  disabled
                  onChange={(e) => setFaixaEtariaMenor(e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <TSelectNative
                  label="Selecione o ano, mês ou dia"
                  name="faixa_etaria_menor_selecionar"
                  options={[
                    {
                      value: 'day',
                      label: 'Dia(s)',
                    },
                    {
                      value: 'month',
                      label: 'Mês(es)',
                    },
                    {
                      value: 'year',
                      label: 'Ano(s)',
                    },
                  ]}
                  value={faixaEtariaMenorSelecionar}
                  disabled
                  onChange={(e) =>
                    setFaixaEtariaMenorSelecionar(e.target.value)
                  }
                />
              </Grid>
              <Grid item md={6}>
                <TSelectNative
                  label="Sexo"
                  name="sexo"
                  disabled
                  options={[
                    {
                      value: 'masculino',
                      label: 'Masculino',
                    },
                    {
                      value: 'feminino',
                      label: 'Feminino',
                    },
                    {
                      value: 'ambos',
                      label: 'Ambos',
                    },
                  ]}
                  value={sexo}
                  onChange={(e) => setSexo(e.target.value)}
                />
              </Grid>
              <Grid item md={12}>
                <TSelectNativeMultiple
                  label="Formulários"
                  name="formularios"
                  placeholder="Formulários"
                  id="formularios"
                  error={error.formularios}
                  required
                  disabled
                  options={anamneseForms}
                  value={formularios}
                  onChange={(e, newValue) => {
                    setFormularios(newValue);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TInputTimeBase
                  required
                  disabled
                  label="Duração do atendimento"
                  name="duracao_atendimento"
                  id="duracaoAtendimento"
                  error={error.duracaoAtendimento}
                  placeholder="Duração do atendimento"
                  value={duracaoAtendimento}
                  onChange={(e) => {
                    setDuracaoAtendimento(e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TInputTimeBase
                  required
                  disabled
                  label="Intervalo entre os atendimentos"
                  name="intervalo_atendimento"
                  id="intervaloAtendimento"
                  error={error.intervaloAtendimento}
                  placeholder="Intervalo entre os atendimentos"
                  value={intervaloAtendimento}
                  onChange={(e) => setIntervaloAtendimento(e.target.value)}
                />
              </Grid>

              <Grid md={12} xs={12}>
                <CalendarMonth
                  horariosAgenda={dadosAgenda?.schedule_times}
                  GerenciarAgendamentos={(e) =>
                    handleListaPeriodos(scheduleId, selectedDay)
                  }
                  onBloquearDia={async (e) => {
                    var res = await checarHorariosAgendamento(
                      openScheduleOfTheDay.day.split('/').reverse().join(''),
                    );

                    if (
                      checkHorariosConflito(
                        {
                          temp: true,
                          block: true,
                          day: openScheduleOfTheDay.day,
                          period: e.isFullDay ? false : true,
                          ...e,
                        },
                        res.scheduleTimes.filter((x) => x.status == 'agendado'),
                      )
                    ) {
                      // Tem conflitos
                      return setOpenModalBloqueio(true);
                    } else {
                      setDayBlock((prev) => [
                        ...prev,
                        {
                          temp: true,
                          block: true,
                          day: openScheduleOfTheDay.day,
                          period: e.isFullDay ? false : true,
                          ...e,
                        },
                      ]);
                      return;
                    }
                  }}
                  handleClickDia={async (e) => {
                    let dataAgendaChecagem = e.split('/').reverse().join('');
                    let dataAgenda = e.split('/').reverse().join('-');

                    setSelectedDay(e);

                    if (bloqueioAtivo) {
                      try {
                        checarHorariosAgendamento(dataAgendaChecagem);

                        if (
                          diasBloqueados &&
                          diasBloqueados.find((x) => x.date == dataAgenda)
                        ) {
                          setOpenModalDesbloqueio(true);
                          return;
                        }

                        return setOpenModalBloqueioDia(true);
                      } catch (err) {
                      }
                    } else {
                      checarHorariosAgendamento(dataAgendaChecagem);
                    }
                  }}
                  unlockSchedule={(day) => desbloquearDia(day)}
                  onGerenciarBloqueios={(bloqueioAtivo) => {
                    if (bloqueioAtivo == 'save') {
                      bloquearHorariosAgendamento();
                      setBloqueioAtivo(false);
                    } else if (bloqueioAtivo == 'cancel') {
                      setDayBlock(dayBlock.filter((x) => !x.temp));
                      setBloqueioAtivo(false);
                    } else {
                      setBloqueioAtivo(bloqueioAtivo);
                    }
                  }}
                  handleMenuItem={handleMenuItem}
                />
              </Grid>

              <ModalCancel
                open={open}
                horarioAgendado={selectedTime}
                onCancel={(scheduleTime, justification) =>
                  cancelarAgendamento(scheduleTime, justification)
                }
                onClose={() => handleClose()}
              />
              <Grid item md={12}>
                {Array.isArray(horariosAgendados) &&
                  horariosAgendados.map((item, index) => {
                    let participants = item.schedule_participants[0];

                    let patient = participants ? participants.patient : null;

                    let patientName =
                      patient?.social_name || patient?.full_name;

                    return (
                      <Card classes={{ root: classes.card }}>
                        <CardContent classes={{ root: classes.cardBody }}>
                          <Grid container alignItems="center">
                            <Grid item>
                              <div
                                style={{
                                  paddingRight: 36,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: '#006FB5',
                                  }}
                                >
                                  {renderDate(item.data_time)}
                                </Typography>
                                <div
                                  style={{
                                    height: 12,
                                    width: 1,
                                    backgroundColor: '#c7c7c7',
                                    marginBottom: 4,
                                  }}
                                ></div>
                                <Typography
                                  style={{ fontSize: 14, fontWeight: 300 }}
                                  className={classes.colorPrimary}
                                >
                                  {renderHour(item.data_time)}
                                </Typography>
                              </div>
                            </Grid>
                            <div className={classes.bckContent}></div>

                            <Grid item>
                              <Typography
                                style={{
                                  color: '#272727',
                                  fontWeight: 300,
                                  fontSize: 16,
                                  paddingLeft: 32,
                                }}
                              >
                                {patientName}
                                &nbsp;&nbsp;
                                <div className={classes.statusIcon}>
                                  <div
                                    className={
                                      item.status == 'disponivel'
                                        ? classes.statusHealthAttendanceOn
                                        : classes.statusHealthAttendanceOff
                                    }
                                  ></div>
                                  <p
                                    style={{
                                      margin: '4px 0 4px 0',
                                      fontSize: 14,
                                    }}
                                  >
                                    {item.status[0].toUpperCase() +
                                      item.status.substring(1)}
                                  </p>
                                </div>
                              </Typography>
                            </Grid>

                            {item.status == 'agendado' && (
                              <Grid item style={{ marginLeft: 'auto' }}>
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    className={classes.btnReagendar}
                                    startIcon={
                                      <BorderColorOutlinedIcon
                                        fontSize="small"
                                        className={classes.startIconCustom}
                                      />
                                    }
                                    size="sm"
                                    onClick={() =>
                                      handleReagendarCreate(item, index)
                                    }
                                  >
                                    <span>Reagendar</span>
                                  </Button>
                                  <Button
                                    className={classes.btnCancelar}
                                    startIcon={
                                      <CancelPresentationOutlinedIcon
                                        fontSize="small"
                                        className={classes.startIconCustom}
                                      />
                                    }
                                    onClick={() => handleOpen(item, index)}
                                  >
                                    <span>Cancelar</span>
                                  </Button>
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    );
                  })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

AgendaVisualizar.defaultProps = {
  onBack: () => {},
};
