import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DAutoComplete from '../../../DAutoComplete';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  estiloModalAtencao: {
    backgroundColor: theme.palette.neutral.white,
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    border: 'none',
    padding: '60px 100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconeModalAtencao: {
    color: theme.palette.neutral.black,
    fontSize: '14px',
  },
  titleModalAtencao: {
    fontSize: '20px',
    color: theme.palette.neutral.black,
    fontWeight: '700',
    marginTop: '20px',
  },
  fontDescricaoModalAtencao: {
    fontSize: '16px',
    color: theme.palette.neutral.darkgray,
    marginTop: '20px',
  },
  alinhaBotoesModalAtencao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    marginTop: '50px',
  },
  botaoConfirmar: {
    background: `${theme.palette.support.error} !important`,
    border: `1px solid ${theme.palette.support.error} !important`,
    color: `${theme.palette.neutral.white} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  botaoCancelar: {
    backgroundColor: 'transparent',
    // border: `1px solid ${theme.palette.support.error} !important`,
    color: `${theme.palette.neutral.black} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  justificativa: {
    marginTop: '24px',
    width: '100%',
    '&>span:first-child': {
      color: `${theme.palette.neutral.darkgray} !important`,
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  rootInput: {
    borderRadius: '8px',
    paddingTop: 8,
  },
}));

export default function ModalCancel(props) {
  const classes = useStyles();

  const [justificativa, setJustificativa] = useState(null);

  function handleCancel() {
    props.onCancel(props.horarioAgendado, justificativa);
    setJustificativa(null);
  }

  function handleClose() {
    props.onClose(true);
    setJustificativa(null);
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className={classes.estiloModalAtencao}>
          <div className={classes.iconeModalAtencao}>
            <CalendarMonthOutlinedIcon style={{ fontSize: 56 }} />
          </div>
          <div className={classes.titleModalAtencao} id="modal-modal-title">
            Cancelar agendamento
          </div>
          <div className={classes.fontDescricaoModalAtencao}>
            Deseja cancelar o agendamento selecionado?
          </div>
          <div className={classes.justificativa}>
            <span>Justificativa</span>
            <Autocomplete
              disablePortal
              disableClearable={true}
              options={[
                { value: 1, label: 'Bloqueio de agenda' },
                { value: 2, label: 'Má conexão com o paciente' },
                { value: 3, label: 'Má conexão com o médico' },
                { value: 4, label: 'Solicitação do paciente' },
                { value: 5, label: 'Exclusão de agenda' },
              ]}
              classes={{
                root: classes.rootInput,
              }}
              onChange={(e, value) => {
                setJustificativa(value.label);
              }}
              name="justificativa"
              id="justificativa"
              renderInput={(params) => (
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  {...params}
                  label="Selecionar"
                />
              )}
            />
          </div>
          <div className={classes.alinhaBotoesModalAtencao}>
            <Button
              className={classes.botaoCancelar}
              onClick={(e) => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              className={classes.botaoConfirmar}
              onClick={(e) =>
                justificativa
                  ? handleCancel()
                  : toast.error('É necessário informar uma justificativa.')
              }
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
