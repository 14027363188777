import {
  Checkbox,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Create, Delete, Save } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  checkCnes,
  checkCnpj,
  getAllUnidades,
  getDominios,
  getUnidade,
  updateMedicalUnit,
  updateMedicalUnitAnamneses,
  updateMedicalUnitLink,
  updateMedicalUnitProfile,
  validateCNPJ,
} from '../../../../api/unidades';
import establishmentTypeJson from '../../../../assets/dados/establishmentType.json';
import logradouroCode from '../../../../assets/dados/logradouro.json';
import '../../../../assets/sass/global.scss';
import { getCep } from '../../../../helpers/apis';
import {
  mascaraCEP,
  mascaraCNPJ,
  mascaraNumero,
} from '../../../../helpers/formatacao';
import { required, validarCNPJ } from '../../../../helpers/validacoes';
import useError from '../../../../hooks/useError';
import DAutoComplete from '../../../shared/DAutoComplete';
import DButton from '../../../shared/DButton';
import DDropDownSimple from '../../../shared/DDropDownSimple';
import DDropdown from '../../../shared/DDropdown';
import DInput from '../../../shared/DInput';
import DRadio from '../../../shared/DRadio';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getCitiesByStateId } from '../../../../api/getCitiesByStateId';

UnidadeView.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  rootHide: {
    display: 'none',
  },
  title: {
    borderBottom: 'solid 1px #C7C7C7',
  },
  titleRemove: {
    borderBottom: 'none',
  },
  content: {
    width: 545,
    height: 450,
    paddingTop: 16,
  },
  contentRemove: {
    width: 320,
    height: 50,
    paddingTop: 16,
  },
  footer: {
    borderTop: 'solid 1px #C7C7C7',
    padding: 16,
  },
  icon: {
    color: '#949494',
  },
  chip: {
    background: '#E6E6E6',
  },
  formLabel: {
    color: '#272727',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
  },
  btnSalvar: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
  },
  radioRoot: {
    color: '#272727',
  },
  radioColor: {
    color: `${theme.palette.primary.light} !important`,
  },
  text400: {
    fontWeight: 400,
  },
  text500: {
    fontWeight: 500,
  },
  backgroundRed: {
    backgroundColor: `${theme.palette.support.error} !important`,
    color: '#fff',
    textTransform: 'none',
  },
  backgroundRedHover: {
    backgroundColor: `${theme.palette.transparency.red16} !important`,
  },
  backgroundGreen: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: '#fff',
    textTransform: 'none',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow:
      '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
    fontSize: 20,
    marginTop: 3,
  },
  arrow: {
    color: '#FFF',
  },
}))(Tooltip);

function UnidadeView(props) {
  const classes = useStyles();

  function DadosBasicosEdit(props) {
    const [typeMedicalUnit, setTypeMedicalUnit] = useState(
      props.unidade.medicalUnit.medical_unit_type,
    );
    const [cnes, setCnes] = useState(props.unidade.medicalUnit.cnes);
    const [cnpj, setCNPJ] = useState(
      mascaraCNPJ(props.unidade.medicalUnit.cnpj),
    );
    const [name, setName] = useState(props.unidade.medicalUnit.company_name);
    const [businessName, setBusinessName] = useState(
      props.unidade.medicalUnit.business_name,
    );
    const [companyName, setCompanyName] = useState(
      props.unidade.medicalUnit.company_name,
    );
    const [establishmentType, setEstablishmentType] = useState(
      props.unidade.medicalUnit.establishment_type,
    );
    const [phone, setPhone] = useState(
      mascaraNumero(props.unidade.medicalUnit.phone_number),
    );
    const [email, setEmail] = useState(props.unidade.medicalUnit.email);
    const [cep, setCEP] = useState(
      props.unidade.address ? mascaraCEP(props.unidade.address.zip_code) : '',
    );
    const [addressType, setAddressType] = useState(
      props.unidade.address ? props.unidade.address.address_name : '',
    );
    const [address, setAddress] = useState(
      props.unidade.address ? props.unidade.address.street : '',
    );
    const [number, setNumber] = useState(
      props.unidade.address ? props.unidade.address.number : '',
    );
    const [district, setDistrict] = useState(
      props.unidade.address ? props.unidade.address.district : '',
    );
    const [complement, setComplement] = useState(
      props.unidade.address ? props.unidade.address.complement : '',
    );
    const [city, setCity] = useState(
      props.unidade.address ? props.unidade.address.city_name : '',
    );
    const [cityId, setCityId] = useState(
      props.unidade.address ? props.unidade.address.city_id : '',
    );
    const [state, setState] = useState(
      props.unidade.address ? props.unidade.address.state_name : '',
    );
    const [stateId, setStateId] = useState(
      props.unidade.address ? props.unidade.address.state_id : '',
    );
    const [formBlock, setFormBlock] = useState(false);
    const [labelCnes, setLabelCnes] = useState(checkMedicalUnit());
    const [validateCheckCnpj, setValidateCheckCnpj] = useState(null);
    const [validateCheckCnes, setValidateCheckCnes] = useState(null);

    const [addressTypeName, setAddressTypeName] = useState('');

    const [error, validarFormulario, setErrors] = useError(getValidators());

    useEffect(getFullAddressByCep, [cep]);

    function checkMedicalUnit() {
      return props.unidade.medicalUnit.medical_unit_type == 'Administrativa'
        ? 'CNES'
        : 'CNES';
    }

    function getValidators() {
      var validators = {};

      validators = {
        typeMedicalUnit: required,
        cnpj: required,
        cnes: required,
        name: required,
        companyName: required,
        phone: required,
        email: required,
        cep: required,
        addressType: required,
        address: required,
        number: required,
        district: required,
        city: required,
        state: required,
      };

      if (typeMedicalUnit === 'Assistencial') {
        validators.establishmentType = required;
      }

      if (typeMedicalUnit === 'Administrativa') {
        validators.businessName = required;
      }

      if (
        establishmentType !== 'Escola' &&
        typeMedicalUnit !== 'Administrativa'
      ) {
        validators.cnes = required;
      }

      return validators;
    }

    useEffect(() => {
      console.log('error', error);

      try {
        if (Object.keys(error).length) {
          const elementByID = document.getElementById(Object.keys(error)[0]);

          console.log('elementByID', elementByID);

          if (elementByID) {
            document.getElementById(Object.keys(error)[0]).scrollIntoView({
              block: 'start',
            });
          }

          const initValue = (
            <p>
              Não foi possível editar a unidade. Verifique os campos
              obrigatórios
            </p>
          );

          const msg = Object.entries(error)?.reduce((m, err) => {
            return (
              <>
                {m}
                <p style={{ margin: 0 }}>{getErrorMessage(err[0])}</p>
              </>
            );
          }, initValue);

          toast.error(msg, { timeout: 1000000000 });
        }
      } catch (err) {
        console.log(err);
      }
    }, [error]);

    const getErrorMessage = (type) => {
      var drinks = {
        address: 'Endereço é obrigatório',
        cep: 'CEP é obrigatório',
        city: 'Cidade obrigatório',
        cnpj: 'CNPJ é obrigatório',
        cnes: 'CNES é obrigatório',
        cnpjCheck: 'CNPJ já cadastrado',
        cnesCheck: 'CNES já cadastrado',
        establishmentType: 'Tipo de estabelecimento é obrigatório',
        district: 'Bairro é obrigatório',
        email: 'E-mail é obrigatório',
        name: 'Nome é obrigatório',
        number: 'Número é obrigatório',
        phone: 'Telefone é obrigatório',
        state: 'Estado é obrigatório',
        profiles: 'Ao menos um Perfil é obrigatório',
        anamneses: 'Ao menos um formulário de anamnese é obrigatório',
        businessName: 'Nome empresarial é obrigatório',
        companyName: 'Nome fantasia é obrigatório',
        default: '',
      };
      return drinks[type] || drinks['default'];
    };

    function validaCnesCnpj(cnes, cnpj, id) {
      if (cnes !== '' && cnpj !== '') {
        validateCNPJ(localStorage.getItem('token'), cnpj).then((res) => {
          if (!res.status) {
            setFormBlock(true);
            delete error.cnes;
            setErrors({ ...error, cnpj: 'CNPJ inválido' });

            if (document.getElementById('cnpj')) {
              document.getElementById('cnpj').scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
            }
          } else {
            delete error.cnes;
            delete error.cnpj;
            setFormBlock(false);
          }
        });
      } else {
        setFormBlock(true);
      }
    }

    function getDados() {
      var dados = {
        typeMedicalUnit,
        cnes,
        cnpj,
        name,
        businessName,
        companyName,
        establishmentType,
        phone,
        email,
        cep,
        addressType,
        address,
        number,
        district,
        city,
        cityId,
        state,
        stateId,
        complement,
      };

      dados.cnpj = dados.cnpj.toString().replace(/[^0-9]/g, '');
      dados.cep = dados.cep.toString().replace(/[^0-9]/g, '');
      dados.phone = dados.phone.toString().replace(/[^0-9]/g, '');

      if (typeMedicalUnit === 'Assistencial') {
        // dados.businessName = 'null'
        dados.businessName = null;
        dados.companyName = 'null';

        if (dados.establishmentType == 'null') {
          dados.establishmentType = null;
        }
      } else {
        dados.funcao = 'null';
        dados.name = 'null';
        // dados.establishmentType = 'null'
        dados.establishmentType = null;
      }

      return dados;
    }

    function removeAccents(text) {
      const accentsMap = new Map([
        ['A', 'Á|À|Ã|Â|Ä'],
        ['a', 'á|à|ã|â|ä'],
        ['E', 'É|È|Ê|Ë'],
        ['e', 'é|è|ê|ë'],
        ['I', 'Í|Ì|Î|Ï'],
        ['i', 'í|ì|î|ï'],
        ['O', 'Ó|Ò|Ô|Õ|Ö'],
        ['o', 'ó|ò|ô|õ|ö'],
        ['U', 'Ú|Ù|Û|Ü'],
        ['u', 'ú|ù|û|ü'],
        ['C', 'Ç'],
        ['c', 'ç'],
        ['N', 'Ñ'],
        ['n', 'ñ'],
      ]);

      const reducer = (acc, [key]) =>
        acc.replace(new RegExp(accentsMap.get(key), 'g'), key);

      return [...accentsMap].reduce(reducer, text);
    }

    function getFullAddressByCep() {
      try {
        if (cep.length >= 10) {
          getCep(cep)
            .then(async (data) => {
              if (data.status) {
                delete error.address;
                delete error.district;
                delete error.city;
                delete error.state;

                const stateSelect = props.states.find(
                  (city) => city.code == data.estado_sigla,
                );

                const citiesByState = await getCitiesByStateId(stateSelect.id);

                if (citiesByState?.data?.status) {
                  const citySelected = citiesByState?.data?.cities?.find(
                    (city) =>
                      city.description ===
                        removeAccents(data.localidade.toUpperCase()) &&
                      city.state_id === stateSelect.id,
                  );

                  if (citySelected) {
                    setCity(data.localidade);
                    setCityId(citySelected.id);
                  }
                }

                let logradouro = logradouroCode.find(
                  (res) => res.value === data.logradouro_tipo,
                );

                setAddressType(logradouro ? logradouro.id : null);
                setAddressTypeName(logradouro ? logradouro.value : null);

                setDistrict(data.bairro);
                setAddress(data.logradouro);
                setState(data.estado_sigla);

                if (stateSelect.id) {
                  setState(data.estado_sigla);
                  setStateId(stateSelect.id);
                }
              } else {
                setDistrict('');
                setAddress('');
                setState('');
                setStateId('');
                setCity('');
                setCityId('');
                toast.error(data.message);
              }
            })
            .catch((err) => {
              toast.error(err);
            });
        }
      } catch (err) {
        setDistrict('');
        setAddress('');
        setAddressTypeName('');
        setState('');
        setStateId('');
        setCity('');
        setCityId('');

        var new_erro = {};
        new_erro.cep = 'CEP inexistente';
        setErrors(new_erro);
      }
    }

    return (
      <form
        autoComplete="off"
        onSubmit={(form) => {
          form.preventDefault();

          var dados = getDados();
          dados.id = props.unidade.medicalUnit.id;

          if (validarFormulario({ ...dados })) {
            checkCnpj(
              localStorage.getItem('token'),
              cnpj,
              props.unidade.medicalUnit.id,
            ).then((res) => {
              console.log('checkCnpj');

              if (!res.status) {
                if (res?.status_code != 401) {
                  setErrors({ ...error, cnpjCheck: 'CNPJ já cadastrado.' });
                  setValidateCheckCnpj(false);
                }
              } else {
                if (validarCNPJ(cnpj)) {
                  console.log('validarCNPJ');

                  delete error.cnpj;
                  delete error.cnpjCheck;
                  setValidateCheckCnpj(true);

                  //valida cnes
                  checkCnes(
                    localStorage.getItem('token'),
                    cnes,
                    props.unidade.medicalUnit.id,
                  ).then((res) => {
                    if (!res.status) {
                      if (res?.status_code != 401) {
                        setErrors({
                          ...error,
                          cnesCheck: 'CNES já cadastrado.',
                        });
                        setValidateCheckCnes(false);
                      }
                    } else {
                      setValidateCheckCnes(true);
                      props.updateUnidade(dados);
                    }
                  });
                } else {
                  setErrors({ ...error, cnpj: 'CNPJ inválido.' });
                  setValidateCheckCnpj(false);
                }
              }
            });
          } else {
            console.log(dados);
            console.log(error);
          }
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className="text-primary" variant="h6">
                  Dados da unidade
                </Typography>
              </Grid>
              <Grid item>
                {props.loading ? (
                  <>
                    <CircularProgress size={15} color="inherit" /> Salvando...
                  </>
                ) : (
                  <DButton
                    disabled={formBlock}
                    type="submit"
                    variant="ghost"
                    icon={<Save fontSize="small" />}
                    size="sm"
                    multiClass={`btn-success ${classes.btnSalvar}`}
                  >
                    Salvar alterações
                  </DButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <DRadio
              inline
              name="type_medical_unit"
              onChange={(e) => {
                setTypeMedicalUnit(e.target.value);

                if (e.target.value == 'Administrativa') {
                  setLabelCnes('CNES (opcional)');
                  setFormBlock(false);
                } else {
                  setLabelCnes('CNES');
                }
              }}
              value={typeMedicalUnit}
              labelClass={classes.formLabel}
              withoutBold
              options={[
                { value: 'Assistencial', label: 'Assistencial' },
                { value: 'Administrativa', label: 'Administrativa' },
              ]}
            />
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item md={10}>
                <DInput
                  id="cnes"
                  label={labelCnes}
                  placeholder="CNES"
                  type="text"
                  error={error.cnes || error.cnesCheck}
                  helperText={error.cnes || error.cnesCheck}
                  positionIcon="end"
                  inputProps={{ maxLength: 7 }}
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.cnes;
                    delete error.cnesCheck;
                    setCnes(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (
                      typeMedicalUnit != 'Administrativo' &&
                      e.target.value !== ''
                    ) {
                      validaCnesCnpj(
                        e.target.value,
                        cnpj,
                        props.unidade.medicalUnit.id,
                      );
                    }
                  }}
                  value={cnes}
                />
              </Grid>
              <Grid item md={2}>
                {!error.cnes && !error.cnesCheck && (
                  <LightTooltip
                    title={
                      <Typography
                        variant="body1"
                        className="text-regular text-st"
                      >
                        Cadastro Nacional de
                        <br /> Estabelecimentos de Saúde
                      </Typography>
                    }
                    placement="right"
                    arrow
                  >
                    <ErrorIcon
                      color="primary"
                      classes={{ colorPrimary: classes.icon }}
                    />
                  </LightTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item md={10}>
                <DInput
                  id="cnpj"
                  label="CNPJ"
                  placeholder="CNPJ"
                  error={error.cnpj || error.cnpjCheck}
                  helperText={error.cnpj || error.cnpjCheck}
                  positionIcon="end"
                  type="text"
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.cnpj;
                    delete error.cnpjCheck;
                    setCNPJ(mascaraCNPJ(e.target.value));
                  }}
                  onBlur={(e) => {
                    validaCnesCnpj(
                      cnes,
                      e.target.value,
                      props.unidade.medicalUnit.id,
                    );
                  }}
                  value={cnpj}
                />
              </Grid>
              <Grid item md={2}>
                {/* <LightTooltip title={
                <Typography variant="body1" className="text-regular text-st">Cadastro Nacional da<br /> Pessoa Jurídica</Typography>
            } placement="right" arrow>
            <ErrorIcon color="primary" classes={{ colorPrimary: classes.icon }} />
            </LightTooltip> */}
                {!error.cnpj && !error.cnpjCheck && (
                  <LightTooltip
                    title={
                      <Typography
                        variant="body1"
                        className="text-regular text-st"
                      >
                        Cadastro Nacional da
                        <br /> Pessoa Jurídica
                      </Typography>
                    }
                    placement="right"
                    arrow
                  >
                    <ErrorIcon
                      color="primary"
                      classes={{ colorPrimary: classes.icon }}
                    />
                  </LightTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          {typeMedicalUnit === 'Assistencial' ? (
            <>
              <Grid item md={12}>
                <DInput
                  id="name"
                  label="Nome"
                  placeholder="Nome"
                  type="text"
                  error={error.name}
                  helperText={error.name}
                  positionIcon="end"
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.name;
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </Grid>
              <Grid item md={12}>
                <DAutoComplete
                  id="establishment-type"
                  label="Tipo de estabelecimento"
                  placeholder="Tipo de estabelecimento"
                  fullWidth
                  defaultValue={
                    establishmentTypeJson.filter(
                      (res) => res.value === establishmentType,
                    )[0]
                  }
                  error={error.establishmentType}
                  helperText={error.establishmentType}
                  options={establishmentTypeJson}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    delete error.establishmentType;
                    setEstablishmentType(newValue ? newValue.id : '');
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={12}>
                <DInput
                  id="business-name"
                  label="Nome empresarial"
                  placeholder="Nome empresarial"
                  type="text"
                  error={error.businessName}
                  helperText={error.businessName}
                  positionIcon="end"
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.businessName;
                    setBusinessName(e.target.value);
                  }}
                  value={
                    businessName != '' &&
                    businessName != 'null' &&
                    businessName != null
                      ? businessName
                      : ''
                  }
                />
              </Grid>
              <Grid item md={12}>
                <DInput
                  id="company-name"
                  label="Nome fantasia"
                  placeholder="Nome fantasia"
                  type="text"
                  error={error.companyName}
                  helperText={error.companyName}
                  positionIcon="end"
                  fullWidth
                  onChange={(e) => {
                    delete error.companyName;
                    setCompanyName(e.target.value);
                  }}
                  value={companyName}
                />
              </Grid>
            </>
          )}
          <Grid item md={12}>
            <DInput
              id="telefone"
              label="Telefone"
              placeholder="Telefone"
              type="text"
              error={error.phone}
              helperText={error.phone}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.phone;
                setPhone(mascaraNumero(e.target.value));
              }}
              value={phone}
            />
          </Grid>
          <Grid item md={12}>
            <DInput
              id="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              error={error.email}
              helperText={error.email}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.email;
                setEmail(e.target.value);
              }}
              value={email}
            />
          </Grid>
          <Grid item md={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item md={7}>
                <DInput
                  id="CEP"
                  label="CEP"
                  placeholder="CEP"
                  type="text"
                  error={error.cep}
                  helperText={error.cep}
                  positionIcon="end"
                  fullWidth
                  onChange={(e) => {
                    delete error.cep;
                    setCEP(mascaraCEP(e.target.value));

                    getFullAddressByCep(e.target.value);
                  }}
                  value={cep}
                />
              </Grid>
              <Grid item md={5}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <a
                      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                      target="_blank"
                      className="text-mt text-regular text-link"
                      rel="noreferrer"
                    >
                      Nãe sei o CEP
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <DAutoComplete
              id="logradouro"
              label="Logradouro"
              placeholder="Logradouro"
              fullWidth
              error={error.addressType}
              helperText={error.addressType}
              options={logradouroCode}
              defaultValue={
                logradouroCode.filter((res) => res.id == addressType)[0]
              }
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              value={logradouroCode.find((item) => item.id == addressType)}
              inputValue={addressTypeName}
              onInputChange={(event, newInputValue) => {
                setAddressTypeName(newInputValue);
              }}
              onChange={(event, newValue) => {
                delete error.addressType;
                setAddressType(newValue ? newValue.id : '');
              }}
            />
          </Grid>
          <Grid item md={8}>
            <DInput
              id="address"
              label="Endereço"
              placeholder="Endereço"
              type="text"
              error={error.address}
              helperText={error.address}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.address;
                setAddress(e.target.value);
              }}
              value={address}
            />
          </Grid>
          <Grid item md={4}>
            <DInput
              id="number"
              label="Número"
              placeholder="Número"
              type="text"
              error={error.number}
              helperText={error.number}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.number;
                setNumber(e.target.value);
              }}
              value={number}
            />
          </Grid>
          <Grid item md={8}>
            <DInput
              id="complement"
              label="Complemento (opcional)"
              placeholder="Complemento"
              type="text"
              error={error.complement}
              helperText={error.complement}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.complement;
                setComplement(e.target.value);
              }}
              value={complement}
            />
          </Grid>
          <Grid item md={12}>
            <DInput
              id="district"
              label="Bairro"
              placeholder="Bairro"
              type="text"
              error={error.district}
              helperText={error.district}
              positionIcon="end"
              fullWidth
              onChange={(e) => {
                delete error.district;
                setDistrict(e.target.value);
              }}
              value={district}
            />
          </Grid>
          <Grid item md={6}>
            <DInput
              id="state"
              label="Estado"
              placeholder="Estado"
              labelColor="black"
              type="text"
              disabled={true}
              error={error.state}
              helperText={error.state}
              fullWidth={true}
              onChange={(e) => {
                delete error.state;
                setState(e.target.value);
              }}
              value={state}
              positionIcon="end"
              icon={<ExpandMore />}
            />
          </Grid>
          <Grid item md={6}>
            <DInput
              id="city"
              label="Municipio"
              placeholder="Municipio"
              labelColor="black"
              type="text"
              disabled={true}
              error={error.city}
              helperText={error.city}
              fullWidth={true}
              onChange={(e) => {
                delete error.city;
                setCity(e.target.value);
              }}
              value={city}
              positionIcon="end"
              icon={<ExpandMore />}
            />
          </Grid>
        </Grid>
      </form>
    );
  }

  function DadosBasicosView(props) {
    return (
      <Grid container>
        <Grid item md={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className="text-primary" variant="h6">
                Dados da unidade
              </Typography>
            </Grid>
            <Grid item>
              <DButton
                onClick={(e) => props.onEdit(true)}
                variant="ghost"
                icon={<Create fontSize="small" />}
                size="sm"
              >
                <span style={{ fontWeight: 400 }}>Editar</span>
              </DButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Typography className="text-st text-regular mt-3" variant="body1">
            <strong>CNPJ: </strong> {props.unidade.medicalUnit.cnpj}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className="text-st text-regular mt-3" variant="body1">
            <strong>CNES: </strong>{' '}
            {props.unidade.medicalUnit.cnes
              ? props.unidade.medicalUnit.cnes
              : 'Não possui'}
          </Typography>
        </Grid>
        {props.unidade.medicalUnit.medical_unit_type === 'Administrativa' ? (
          <Grid item md={12}>
            <Typography className="text-st text-regular mt-3" variant="body1">
              <strong>Nome empresarial: </strong>{' '}
              {props.unidade.medicalUnit.business_name}
            </Typography>
          </Grid>
        ) : (
          <Grid item md={12}>
            <Typography className="text-st text-regular mt-3" variant="body1">
              <strong>Tipo de estabelecimento: </strong>{' '}
              {props.unidade.medicalUnit.establishment_type != '' &&
              props.unidade.medicalUnit.establishment_type != 'null' &&
              props.unidade.medicalUnit.establishment_type != null
                ? props.unidade.medicalUnit.establishment_type
                : null}
            </Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Typography className="text-st text-regular mt-3" variant="body1">
            <strong>Telefone: </strong> {props.unidade.medicalUnit.phone_number}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className="text-st text-regular mt-3" variant="body1">
            <strong>E-mail: </strong> {props.unidade.medicalUnit.email}
          </Typography>
        </Grid>
        {props.unidade.address ? (
          <>
            <Grid item md={12}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>CEP: </strong> {props.unidade.address.zip_code}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Logradouro: </strong>{' '}
                {props.unidade.address.address_name
                  ? logradouroCode.filter(
                      (res) => res.id == props.unidade.address.address_name,
                    )[0]
                    ? logradouroCode.filter(
                        (res) => res.id == props.unidade.address.address_name,
                      )[0].value
                    : '-'
                  : '-'}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Endereço: </strong> {props.unidade.address.street}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Número: </strong> {props.unidade.address.number}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Complemento: </strong>{' '}
                {props.unidade.address.complement}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Bairro: </strong> {props.unidade.address.district}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Município: </strong> {props.unidade.address.city_name}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Estado: </strong> {props.unidade.address.state.code}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item md={6}>
            <Typography className="text-st text-regular mt-3" variant="body1">
              Unidade não possui endereço cadastrado
            </Typography>
          </Grid>
        )}
        {props.unidade.medicalUnit.below_mother_medical_unit === 'yes' &&
        props.unidade.medicalUnit.medical_unit_type !== 'Assistencial' ? (
          <Grid item md={12}>
            <Typography className="text-st text-regular mt-3" variant="body1">
              <strong>Unidade mãe: </strong>{' '}
              {props.unidade.medicalUnit.mother_medical_unit.company_name}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    );
  }

  function DadosBasicos(props) {
    const [loading, setLoading] = useState(false);
    const [unidade, setUnidade] = useState(props.unidade);
    const [edit, setEdit] = useState(false);
    const [states, setStates] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [anamneseForms, setAnamneseForms] = useState([]);
    const [medicalUnits, setMedicalUnits] = useState([]);
    const [executingMedicalUnits, setExecutingMedicalUnits] = useState([]);

    useEffect(function () {
      getDominios(localStorage.getItem('token')).then((res) => {
        setStates(res.states);
        setProfiles(res.profiles);
        setAnamneseForms(res.anamnese);
      });

      getAllUnidades(localStorage.getItem('token')).then((res) => {
        if (!res.error && res.length > 0) {
          setMedicalUnits(res);
          setExecutingMedicalUnits(
            res.filter(
              (item) => item.funcao === 'Executante' || item.funcao === 'Ambos',
            ),
          );
        }
      });
    }, []);

    function update(dados) {
      props.onUpdating(true);
      setLoading(true);
      updateMedicalUnit(localStorage.getItem('token'), dados).then((res) => {
        setLoading(false);
        props.onUpdated(true);
        props.onUpdating(true);
        if (res.status) {
          toast.success('Unidade atualizada com sucesso!');
        }
      });
    }

    return (
      <>
        {!edit ? (
          <DadosBasicosView
            onEdit={(e) => setEdit(true)}
            unidade={props.unidade}
          />
        ) : (
          <DadosBasicosEdit
            loading={loading}
            onUpdated={(e) => props.onUpdated(true)}
            updateUnidade={(dados) => update(dados)}
            unidade={unidade}
            states={states.map(function (item) {
              return {
                id: item.id,
                value: item.code,
                code: item.code,
                description: item.description,
              };
            })}
            profiles={profiles}
            anamneseForms={anamneseForms}
            medicalUnits={medicalUnits.map(function (item) {
              return { id: item.id, value: item.company_name };
            })}
            executingMedicalUnits={executingMedicalUnits.map(function (item) {
              return { id: item.id, value: item.company_name };
            })}
          />
        )}
      </>
    );
  }

  function ProfileView(props) {
    return (
      <Grid container classes={{ root: 'mt-3' }} spacing={2}>
        <Grid
          item
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography className="text-primary" variant="h6">
            Perfis disponíveis na unidade
          </Typography>
          <DButton
            disabled={!props.edit}
            onClick={(e) => props.onEdit(true)}
            variant="ghost"
            icon={<Create fontSize="small" />}
            size="sm"
          >
            <span style={{ fontWeight: 400 }}>Editar</span>
          </DButton>
        </Grid>

        <Grid item md={12}>
          <Grid container alignItems="flex-start">
            {props.profiles.map(function (item, index) {
              return (
                <Grid item md={6} key={`profiles-checkbox-${index}`}>
                  <FormControl component="fieldset" autoComplete="off">
                    <FormGroup>
                      <FormControlLabel
                        disabled={true}
                        control={
                          <Checkbox
                            disabled={true}
                            checked={true}
                            value={item.id}
                            name="profiles[]"
                          />
                        }
                        label={
                          <Typography className={classes.formLabel}>
                            {item.profile_name}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function ProfileEdit(props) {
    const [profiles, setProfiles] = useState(props.values);
    const [error, validarFormulario, setErrors] = useError({
      profiles: required,
    });

    function changeProfiles(e) {
      if (e.target.checked) {
        setProfiles([...profiles, parseInt(e.target.value)]);
      } else {
        var old_profiles = [...profiles];
        old_profiles.splice(profiles.indexOf(parseInt(e.target.value)), 1);
        setProfiles(old_profiles);
      }
    }

    return (
      <form
        onSubmit={(form) => {
          form.preventDefault();
          if (validarFormulario({ profiles })) props.onUpdate(profiles);
        }}
      >
        <Grid container classes={{ root: 'mt-3' }} spacing={2}>
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography className="text-primary" variant="h6">
              Perfis disponíveis na unidade
            </Typography>
            {props.loading ? (
              <>
                <CircularProgress size={15} color="inherit" /> Salvando...
              </>
            ) : (
              <DButton
                type="submit"
                variant="ghost"
                icon={<Save fontSize="small" />}
                size="sm"
                multiClass={`btn-success ${classes.btnSalvar}`}
              >
                Salvar alterações
              </DButton>
            )}
          </Grid>
          <Grid item md={12}>
            <Grid container alignItems="flex-start">
              {props.profiles.map(function (item, index) {
                return (
                  <Grid item md={6} key={`profiles-checkbox-${index}`}>
                    <FormControl component="fieldset" autoComplete="off">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={profiles.indexOf(item.id) > -1}
                              onChange={changeProfiles}
                              value={item.id}
                              name="profiles[]"
                            />
                          }
                          label={
                            <Typography className={classes.formLabel}>
                              {item.description}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  function Profiles(props) {
    const [edit, setEdit] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function () {
      getDominios(localStorage.getItem('token')).then((res) => {
        setProfiles(res.profiles);
      });
    }, []);

    function updateProfiles(profiles) {
      props.onUpdating(true);
      setLoading(true);
      updateMedicalUnitProfile(
        localStorage.getItem('token'),
        profiles,
        props.unidade.medicalUnit.id,
      ).then((res) => {
        setLoading(false);
        props.onUpdated(true);
        props.onUpdating(false);
      });
    }

    return (
      <>
        {!edit ? (
          <ProfileView
            onEdit={(e) => setEdit(true)}
            edit={profiles.length > 0}
            profiles={props.profiles}
          />
        ) : (
          <ProfileEdit
            loading={loading}
            onUpdate={updateProfiles}
            profiles={profiles}
            values={props.profiles.map((item) => item.profile_id)}
          />
        )}
      </>
    );
  }

  function AnamnesesView(props) {
    return (
      <Grid container classes={{ root: 'mt-3' }}>
        <Grid item md={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className="text-primary" variant="h6">
                Formulários de anamnese
              </Typography>
            </Grid>
            <Grid item>
              <DButton
                disabled={!props.edit}
                onClick={(e) => props.onEdit(true)}
                variant="ghost"
                icon={<Create fontSize="small" />}
                size="sm"
              >
                <span style={{ fontWeight: 400 }}>Editar</span>
              </DButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container alignItems="flex-start">
            {props.anamneses.map(function (item, index) {
              return (
                <Grid item md={6} key={`anameses-checkbox-${index}`}>
                  <FormControl component="fieldset" autoComplete="off">
                    <FormGroup>
                      <FormControlLabel
                        disabled={true}
                        control={
                          <Checkbox
                            disabled={true}
                            checked={true}
                            value={item.id}
                            name="anamneses[]"
                          />
                        }
                        label={
                          <Typography className={classes.formLabel}>
                            {item.anamnese_name}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function AnamnesesEdit(props) {
    const [anamneses, setAnamneses] = useState(props.values);
    const [error, validarFormulario, setErrors] = useError({
      anamneses: required,
    });

    function changeAnamneses(e) {
      if (e.target.checked) {
        setAnamneses([...anamneses, parseInt(e.target.value)]);
      } else {
        var old_anamneses = [...anamneses];
        old_anamneses.splice(anamneses.indexOf(parseInt(e.target.value)), 1);
        setAnamneses(old_anamneses);
      }
    }

    return (
      <form
        autoComplete="off"
        onSubmit={(form) => {
          form.preventDefault();
          if (validarFormulario({ anamneses })) props.onUpdate(anamneses);
        }}
      >
        <Grid container classes={{ root: 'mt-3' }}>
          <Grid item md={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className="text-primary" variant="h6">
                  Formulários de anamnese
                </Typography>
              </Grid>
              <Grid item>
                {props.loading ? (
                  <>
                    <CircularProgress size={15} color="inherit" /> Salvando...
                  </>
                ) : (
                  <DButton
                    type="submit"
                    variant="ghost"
                    icon={<Save fontSize="small" />}
                    size="sm"
                    multiClass={`btn-success ${classes.btnSalvar}`}
                  >
                    Salvar alterações
                  </DButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container alignItems="flex-start">
              <Grid item>
                <FormControl component="fieldset" autoComplete="off">
                  <FormGroup>
                    {props.anamneses.map(function (item, index) {
                      return (
                        <FormControlLabel
                          key={`profiles-checkbox-${index}`}
                          control={
                            <Checkbox
                              checked={anamneses.indexOf(item.id) > -1}
                              onChange={changeAnamneses}
                              value={item.id}
                              name="anamneses[]"
                            />
                          }
                          label={
                            <Typography className={classes.formLabel}>
                              {item.name}
                            </Typography>
                          }
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  function Anamneses(props) {
    const [edit, setEdit] = useState(false);
    const [anamneses, setAnamneses] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function () {
      getDominios(localStorage.getItem('token')).then((res) => {
        setAnamneses(res.anamnese);
      });
    }, []);

    function updateAnamneses(anamneses) {
      props.onUpdating(true);
      setLoading(true);
      updateMedicalUnitAnamneses(
        localStorage.getItem('token'),
        anamneses,
        props.unidade.medicalUnit.id,
      ).then((res) => {
        setLoading(false);
        if (res.status) props.onUpdated(true);

        props.onUpdating(false);
      });
    }

    return (
      <>
        {!edit ? (
          <AnamnesesView
            edit={anamneses.length > 0}
            onEdit={(e) => setEdit(true)}
            anamneses={props.anamneses}
          />
        ) : (
          <AnamnesesEdit
            loading={loading}
            anamneses={anamneses}
            values={props.anamneses.map((res) => res.anamnese_id)}
            onUpdate={updateAnamneses}
          />
        )}
      </>
    );
  }

  function MedicalUnitLinkView(props) {
    return (
      <Grid container classes={{ root: 'mt-3' }} spacing={2}>
        <Grid
          item
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography className="text-primary" variant="h6">
            Vínculos da unidade
          </Typography>
          <DButton
            disabled={!props.edit}
            onClick={(e) => props.onEdit(true)}
            variant="ghost"
            icon={<Create fontSize="small" />}
            size="sm"
          >
            <span style={{ fontWeight: 400 }}>Editar</span>
          </DButton>
        </Grid>

        {props.unidade.medicalUnit.below_mother_medical_unit === 'yes' ? (
          <Grid item md={12}>
            <Typography className="text-st text-regular mt-3" variant="body1">
              <strong>Unidade mãe: </strong>{' '}
              {props.unidade.medicalUnit.mother_medical_unit.company_name}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        {props.unidade.medicalUnit.medical_unit_type === 'Assistencial' && (
          <>
            <Grid item md={12} classes={{ root: 'mb-2' }}>
              <Typography className="text-st text-regular mt-3" variant="body1">
                <strong>Solicita para as unidades: </strong>
              </Typography>
            </Grid>
            {props.unidade.medicalUnit.medical_unit_executant.length > 0 ? (
              <Grid item md={12}>
                {props.unidade.medicalUnit.medical_unit_executant.map(
                  function (item, index) {
                    return (
                      <Chip
                        variant="outlined"
                        classes={{ root: classes.chip }}
                        key={`executantes-chip-${index}`}
                        label={item.medical_unit.company_name}
                      />
                    );
                  },
                )}
              </Grid>
            ) : (
              ''
            )}
          </>
        )}

        <Grid item md={12}>
          <Typography className="text-st text-regular mt-3" variant="body1">
            <strong>Está vinculada a uma organização social?: </strong>{' '}
            {props.unidade.medicalUnit.below_social_medical_unit == 1
              ? 'Sim'
              : 'Não'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function MedicalUnitLinkEdit(props) {
    console.log('props MedicalUnitLinkEdit:', props);

    const [belowMotherMedicalUnit, setBelowMotherMedicalUnit] = useState(
      props.unidade.medicalUnit.below_mother_medical_unit === 'yes'
        ? 'Sim'
        : 'Não',
    );
    const [motherMedicalUnit, setMotherMedicalUnit] = useState(
      props.unidade.medicalUnit.mother_medical_unit_id,
    );
    const [funcao, setFuncao] = useState(props.unidade.medicalUnit.funcao);
    const [executingMedicalUnits, setExecutingMedicalUnits] = useState(
      props.unidade.medicalUnit.medical_unit_executant.map(
        (res) => res.executant_medical_unit_id,
      ),
    );

    const [vinculoOrganizacaoSocial, setVinculOrganizacaoSocial] = useState(
      props.unidade.medicalUnit.below_social_medical_unit,
    );
    const [socialMedicalUnit, setSocialMedicalUnit] = useState(
      props.unidade.medicalUnit.social_medical_unit_id,
    );
    const [administrativeMedicalUnits, setAdministrativeMedicalUnits] =
      useState(props.administrativeMedicalUnits);

    const [error, validarFormulario, setErrors] = useError(
      getValidatorsMedicalUnitEdit(),
    );

    function getValidatorsMedicalUnitEdit() {
      var validators = {};

      validators = {
        belowMotherMedicalUnit: required,
        funcao: required,
        executingMedicalUnits: required,
        vinculoOrganizacaoSocial: required,
      };

      if (props.unidade.medicalUnit.below_mother_medical_unit === 'yes') {
        validators.motherMedicalUnit = required;
      }

      if (vinculoOrganizacaoSocial == 1) {
        validators.socialMedicalUnit = required;
      }

      return validators;
    }

    function handleExecutingMedicalUnits(e) {
      delete error.executingMedicalUnits;
      console.log(e);
      if (e.indexOf('reset') > -1) {
        setExecutingMedicalUnits([]);
      } else if (e.indexOf('todos') > -1) {
        setExecutingMedicalUnits(['todos', ...props.executingMedicalUnits]);
      } else {
        setExecutingMedicalUnits(e);
      }
    }

    function getDados() {
      var dados = {
        belowMotherMedicalUnit,
        motherMedicalUnit,
        funcao,
        executingMedicalUnits: executingMedicalUnits.filter(
          (e) => e !== 'todos',
        ),
        vinculoOrganizacaoSocial,
        socialMedicalUnit,
      };

      dados.vinculoOrganizacaoSocial =
        dados.vinculoOrganizacaoSocial.toString();

      if (vinculoOrganizacaoSocial == 0) {
        dados.socialMedicalUnit = null;
      }

      if (
        funcao === 'Executante' ||
        props.unidade.medicalUnit.medical_unit_type === 'Administrativa'
      ) {
        dados.executingMedicalUnits = 'null';
      }

      return dados;
    }

    function selectSingleOrganizacaoSocial() {
      return (
        <>
          <Grid item md={12}>
            <DDropDownSimple
              id="nacionalidade"
              label="Selecionar a organização social vinculada"
              placeholder="Selecionar a organização social vinculada"
              fullWidth
              error={error.socialMedicalUnit}
              helperText={error.socialMedicalUnit}
              value={socialMedicalUnit}
              options={administrativeMedicalUnits.map(function (item) {
                return { id: item.id, value: item.company_name };
              })}
              onChange={(event) => {
                delete error.socialMedicalUnit;
                setSocialMedicalUnit(event);
              }}
            />
          </Grid>
        </>
      );
    }

    return (
      <form
        autoComplete="off"
        onSubmit={(form) => {
          form.preventDefault();

          var dados = getDados();

          if (validarFormulario({ ...dados })) {
            dados.id = props.unidade.medicalUnit.id;
            props.onUpdate(dados);
          }
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          classes={{ root: 'mt-3' }}
        >
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography className="text-primary" variant="h6">
              Vínculos da unidade
            </Typography>
            {props.loading ? (
              <>
                <CircularProgress size={15} color="inherit" /> Salvando...
              </>
            ) : (
              <DButton
                type="submit"
                variant="ghost"
                icon={<Save fontSize="small" />}
                size="sm"
                multiClass={`btn-success ${classes.btnSalvar}`}
              >
                Salvar alterações
              </DButton>
            )}
          </Grid>
          <Grid item md={12}>
            {/* <DRadio inline
                        label="Esta unidade está abaixo de uma unidade mãe?"
                        name="below_mother_medical_unit"
                        onChange={ e => setBelowMotherMedicalUnit(e.target.value) }
                        value={ belowMotherMedicalUnit }
                        options={[
                            { value: 'Não', label: 'Não' },
                            { value: 'Sim', label: 'Sim', disabled: props.medicalUnits.length < 1 }
                        ]} /> */}

            <FormLabel
              component="legend"
              style={{ color: '#272727', fontSize: 14, fontWeight: 400 }}
            >
              Esta unidade está abaixo de uma unidade mãe?
              <LightTooltip
                title={
                  <Typography variant="body1" className="text-regular text-st">
                    A unidade mãe é a unidade
                    <br />
                    responsável{' '}
                  </Typography>
                }
                placement="right"
                arrow
                style={{ marginLeft: 8 }}
              >
                <ErrorIcon
                  color="primary"
                  classes={{ colorPrimary: classes.icon }}
                  style={{ height: 18, verticalAlign: 'text-bottom' }}
                />
              </LightTooltip>
            </FormLabel>
            <RadioGroup
              row
              name="below_mother_medical_unit"
              value={belowMotherMedicalUnit}
              onChange={(e) => setBelowMotherMedicalUnit(e.target.value)}
            >
              <FormControlLabel
                value="Não"
                control={
                  <Radio
                    color="primary"
                    classes={{
                      root: classes.radioRoot,
                      checked: classes.radioColor,
                    }}
                  />
                }
                label="Não"
                classes={{ root: classes.formLabel }}
              />
              <FormControlLabel
                value="Sim"
                control={
                  <Radio
                    color="primary"
                    classes={{
                      root: classes.radioRoot,
                      checked: classes.radioColor,
                    }}
                  />
                }
                label="Sim"
                classes={{ root: classes.formLabel }}
              />
            </RadioGroup>
            {belowMotherMedicalUnit === 'Sim' && (
              <DAutoComplete
                id="mother-medical-unit"
                label="Unidade mãe"
                labelColor="#272727"
                placeholder="Selecione unidade mãe"
                fullWidth
                error={error.motherMedicalUnit}
                helperText={error.motherMedicalUnit}
                defaultValue={
                  props.medicalUnits.filter(
                    (res) => res.id === motherMedicalUnit,
                  )[0]
                }
                options={props.medicalUnits.filter(
                  (res) => res.id !== props.unidade.medicalUnit.id,
                )}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) =>
                  value && option.id === value.id
                }
                onChange={(event, newValue) => {
                  delete error.motherMedicalUnit;
                  setMotherMedicalUnit(newValue ? newValue.id : '');
                }}
                style={{ marginTop: 8 }}
              />
            )}
          </Grid>
          {props.unidade.medicalUnit.medical_unit_type === 'Assistencial' && (
            <>
              <Grid item md={12}>
                <DRadio
                  inline
                  label="Qual será a função dessa unidade?"
                  name="funcao"
                  onChange={(e) => setFuncao(e.target.value)}
                  value={funcao}
                  options={[
                    {
                      value: 'Solicitante',
                      label: 'Solicitante',
                      disabled: props.executingMedicalUnits.length < 1,
                    },
                    { value: 'Executante', label: 'Executante' },
                    {
                      value: 'Ambas',
                      label: 'Ambas',
                      disabled: props.executingMedicalUnits.length < 1,
                    },
                  ]}
                />
              </Grid>
              {funcao === 'Solicitante' || funcao === 'Ambas' ? (
                <Grid item md={12}>
                  <DDropdown
                    id="other-mother-medical-unit"
                    label="Unidades executantes"
                    placeholder="Selecione unidades executantes"
                    onChange={handleExecutingMedicalUnits}
                    value={executingMedicalUnits}
                    options={props.executingMedicalUnits.filter(
                      (res) => res.id !== props.unidade.medicalUnit.id,
                    )}
                    error={error.executingMedicalUnits}
                    helperText={error.executingMedicalUnits}
                  />
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
          <Grid item md={12}>
            <DRadio
              inline
              label="Está vinculada a uma organização social?"
              name="vinculoOrganizacaoSocial"
              onChange={(e) => setVinculOrganizacaoSocial(e.target.value)}
              value={vinculoOrganizacaoSocial}
              options={[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]}
            />
          </Grid>
          {vinculoOrganizacaoSocial == 1 ? selectSingleOrganizacaoSocial() : ''}
        </Grid>
      </form>
    );
  }

  function MedicalUnitLink(props) {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [medicalUnits, setMedicalUnits] = useState([]);
    const [executingMedicalUnits, setExecutingMedicalUnits] = useState([]);
    const [administrativeMedicalUnits, setAdministrativeMedicalUnits] =
      useState([]);

    useEffect(function () {
      getAllUnidades(localStorage.getItem('token')).then((res) => {
        if (!res.error && res.length > 0) {
          setMedicalUnits(res);
          setExecutingMedicalUnits(
            res.filter(
              (item) => item.funcao === 'Executante' || item.funcao === 'Ambos',
            ),
          );
          setAdministrativeMedicalUnits(
            res.filter((item) => item.medical_unit_type === 'Administrativa'),
          );
        }
      });
    }, []);

    function updateLink(dados) {
      props.onUpdating(true);
      setLoading(true);
      updateMedicalUnitLink(localStorage.getItem('token'), dados).then(
        (res) => {
          setLoading(false);
          props.onUpdated(true);
          props.onUpdating(false);
        },
      );
    }

    return (
      <>
        {!edit ? (
          <MedicalUnitLinkView
            edit={medicalUnits.length > 0}
            onEdit={(e) => setEdit(true)}
            unidade={props.unidade}
          />
        ) : (
          <MedicalUnitLinkEdit
            loading={loading}
            onUpdate={updateLink}
            administrativeMedicalUnits={administrativeMedicalUnits}
            medicalUnits={medicalUnits.map(function (item) {
              return { id: item.id, value: item.company_name };
            })}
            executingMedicalUnits={executingMedicalUnits.map(function (item) {
              return { id: item.id, value: item.company_name };
            })}
            unidade={props.unidade}
          />
        )}
      </>
    );
  }

  function RemoveMedicalUnit(props) {
    return (
      <Dialog
        aria-labelledby="medical-unity-create-title"
        scroll="body"
        onClose={(e) => props.onClose(true)}
        open={props.open}
      >
        <DialogTitle
          id="medical-unity-create-title"
          classes={{ root: classes.titleRemove }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>Confirmar a exclusão dessa unidade?</Typography>
            </Grid>
            <Grid item>
              <Close onClick={(e) => props.onClose(true)} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentRemove }}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                variant="secondary"
                size="sm"
                className="btn-error"
                onClick={(e) => props.onClose(true)}
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                variant="primary"
                size="sm"
                className="btn-error"
                classes={{
                  root: classes.backgroundRed,
                  label: classes.text500,
                }}
                onClick={(e) => props.onRemove(props.unidade)}
              >
                Confirmar e excluir
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  function Modal(props) {
    const [unidade, setUnidade] = useState(null);
    const [excluir, setExcluir] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(function () {
      getUnidade(localStorage.getItem('token'), props.unidade).then((res) => {
        setUnidade(res.details);
      });
    }, []);

    return (
      <>
        <Dialog
          classes={{ paper: classes.dialog }}
          aria-labelledby="medical-unity-create-title"
          scroll="body"
          onClose={(e) => props.onClose(true)}
          open={props.open}
        >
          {unidade && !props.remove ? (
            <>
              <DialogTitle
                id="medical-unity-create-title"
                classes={{ root: classes.title }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="h2"
                      className="text-mt text-regular text-primary"
                    >
                      {unidade.medicalUnit.company_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Close onClick={(e) => props.onClose(true)} />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <DadosBasicos
                  onUpdating={(e) => setLoading(e)}
                  unidade={unidade}
                  onUpdated={(e) => props.onUpdated(true)}
                />
                <MedicalUnitLink
                  onUpdating={(e) => setLoading(e)}
                  onUpdated={(e) => props.onUpdated(true)}
                  unidade={unidade}
                />

                <Profiles
                  onUpdating={(e) => setLoading(e)}
                  onUpdated={(e) => props.onUpdated(true)}
                  unidade={unidade}
                  profiles={unidade.medicalUnitProfile}
                />

                {unidade.medicalUnitAnamneseForm.length > 0 &&
                  unidade.medicalUnit.medical_unit_type === 'Assistencial' && (
                    <Anamneses
                      onUpdating={(e) => setLoading(e)}
                      onUpdated={(e) => props.onUpdated(true)}
                      unidade={unidade}
                      anamneses={unidade.medicalUnitAnamneseForm}
                    />
                  )}
              </DialogContent>
              <DialogActions classes={{ root: classes.footer }}>
                <DButton
                  disabled={loading}
                  type="button"
                  variant="success"
                  icon={
                    <Delete style={{ height: 16, width: 16, marginTop: -2 }} />
                  }
                  size="sm"
                  onClick={(e) => setExcluir(true)}
                  classes={{
                    root: classes.backgroundRed,
                    label: classes.text500,
                  }}
                >
                  Excluir unidade
                </DButton>

                <DButton
                  disabled={loading}
                  type="button"
                  variant="green"
                  size="sm"
                  onClick={(e) => props.onClose(true)}
                  style={{ minWidth: 'auto !important' }}
                  classes={{
                    label: classes.text500,
                    root: classes.backgroundGreen,
                  }}
                >
                  Fechar
                </DButton>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle
                id="medical-unity-create-title"
                classes={{ root: classes.title }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="h2"
                      className="text-mt text-regular text-primary"
                    >
                      {props.remove ? 'Removendo' : 'Carregando'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Close onClick={(e) => props.onClose(true)} />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 400 }}
                >
                  <Grid item className="text-center">
                    <CircularProgress
                      className="text-success"
                      style={{ fontSize: 64 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          )}
        </Dialog>
        <RemoveMedicalUnit
          open={excluir}
          onClose={(e) => setExcluir(false)}
          onRemove={(unidade) => props.onRemoveMedicalUnit(unidade)}
          unidade={props.unidade}
        />
      </>
    );
  }

  return (
    <>
      {props.unidade ? (
        <Modal
          onClose={(e) => props.onClose(true)}
          onUpdated={(e) => props.onUpdated(true)}
          open={props.open}
          unidade={props.unidade}
          onRemoveMedicalUnit={(unidade) => props.onRemoveMedicalUnit(unidade)}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default UnidadeView;
