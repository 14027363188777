import axios from 'axios';
import env from 'react-dotenv';

class ScheduleReminder {
  #base_uri = new URL(env.API_URL).origin + '/schedule/schedule-reminder';

  async getAll() {
    let uri = new URL(this.#base_uri);
    uri.searchParams.append('token', localStorage.getItem('token'));

    const data = await axios.get(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    });

    if (data.response) {
      return data.response.data;
    }

    return data.data;
  }

  async create({ hour, minute, message }) {
    let uri = new URL(`${this.#base_uri}/store`);
    uri.searchParams.append('token', localStorage.getItem('token'));

    const data = await axios.post(
      uri,
      {
        hour,
        minute,
        message,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    if (data.response) {
      return data.response.data;
    }

    return data.data;
  }

  async delete(id) {
    let uri = new URL(`${this.#base_uri}/${id}/delete`);

    uri.searchParams.append('token', localStorage.getItem('token'));

    const data = await axios.delete(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    });

    if (data.response) {
      return data.response.data;
    }

    return data.data;
  }

  async edit({ hour, minute, message, id }) {
    let uri = new URL(`${this.#base_uri}/${id}`);

    uri.searchParams.append('token', localStorage.getItem('token'));

    const data = await axios.put(
      uri,
      {
        hour,
        minute,
        message,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    if (data.response) {
      return data.response.data;
    }

    return data.data;
  }
}

export default new ScheduleReminder();
