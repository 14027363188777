import axios from 'axios';
import env from 'react-dotenv';

export async function listAllSchedule(page) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/list`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  page > 1 && uri.searchParams.append('page', page);
  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function filterSchedule(selectedFilters) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/filter`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  selectedFilters.search &&
    selectedFilters.search != '' &&
    uri.searchParams.append('search', selectedFilters.search);
  selectedFilters.start_date_min &&
    uri.searchParams.append('start_date_min', selectedFilters.start_date_min);
  selectedFilters.start_date_max &&
    uri.searchParams.append('start_date_max', selectedFilters.start_date_max);
  selectedFilters.status.length == 1 &&
    selectedFilters.status[0].label != 'Todos' &&
    uri.searchParams.append(
      'statusSchedule',
      selectedFilters.status[0].id == 'ativo' ? 'scheduled' : 'finished',
    );
  uri.searchParams.append('user_id', localStorage.getItem('uid'));
  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function filterScheduleList(selectedFilters) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/filter-list`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  selectedFilters.search &&
    selectedFilters.search != '' &&
    uri.searchParams.append('search', selectedFilters.search);
  selectedFilters.start_date_min &&
    uri.searchParams.append('start_date_min', selectedFilters.start_date_min);
  selectedFilters.start_date_max &&
    uri.searchParams.append('start_date_max', selectedFilters.start_date_max);
  selectedFilters.status?.length == 1 &&
    selectedFilters.status[0].label != 'Todos' &&
    uri.searchParams.append(
      'statusSchedule',
      selectedFilters.status[0].id == 'ativo' ? '1' : '2',
    );

  selectedFilters.nextSchedules &&
    uri.searchParams.append('nextSchedules', true);
  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function createNewSchedule(dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/store`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const data = await axios.post(uri, dados, config);

  if (data.response) {
    return data.response.data;
  }

  return data.data;
}

/**
 * Verifica se tem disponibilidade para o agendamento selecionado
 * @param {*} scheduleId
 * @param {string} date yyyy-MM-dd
 * @param {"primeiraConsulta" | "retorno" | "encaixe"} distributionType
 */
export async function checkScheduleDistribution(
  scheduleId,
  date,
  distributionType,
) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(
    `${api_url}/schedule/${scheduleId}/schedule-distribution/check`,
  );

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('date', date);
  uri.searchParams.append('type', distributionType);
  uri.searchParams.append('token', localStorage.getItem('token'));

  const data = await axios.get(uri, config);

  if (data.response) {
    return data.response.data;
  }

  return data.data;
}

export async function updateStatusSchedule(dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${id}/status`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const response = await axios.put(uri, dados, config);

  return response.data;
}

export async function editScheduleById(dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${id}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const data = await axios.put(uri, dados, config);

  if (data.response) {
    return data.response.data;
  }

  return data;
}

export async function editPartialScheduleById(dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/partial/${id}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const data = await axios.patch(uri, dados, config);

  if (data.response) {
    return data.response.data;
  }

  return data;
}

export async function getScheduleById(id) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${id}/show`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getProfessionalScheduleData(professionalId) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${professionalId}/professional`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getHasScheduleTime(idSchedule) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${idSchedule}/scheduletime/check`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getEspecialtyWithSchedule() {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/medical-speciality`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getProfessionalsByEspecialty(idEspecialty) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${idEspecialty}/medical-speciality`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getProfessionalScheduleById(idProfessional) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/user/${idProfessional}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getProfessionalSpecialityScheduleById(
  idProfessional,
  medicalSpecialityId,
) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/user/${idProfessional}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));
  uri.searchParams.append('medical_speciality_id', medicalSpecialityId);

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getScheduleTimesById(idSchedule) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${idSchedule}/list-schedule-times`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);

  return data;
}

export async function getScheduleTimesByIdAndMonth(idSchedule, date) {
  console.time('getScheduleTimesByIdAndMonth')
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${idSchedule}/list-schedule-times/month/${date}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.get(uri, config);
  console.timeEnd('getScheduleTimesByIdAndMonth')

  return data;
}

export async function postScheduleTime(idSchedule, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(
    `${api_url}/schedule/scheduletime/${idSchedule}/schedule-an-appointment`,
  );

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.post(uri, dados, config);

  return data;
}

export async function checkScheduleConflictFromUser(idUser, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/check-schedule-conflict/${idUser}`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.post(uri, dados, config);

  return data;
}

export async function putScheduleTime(idSchedule, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/scheduletime/${idSchedule}/update`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.put(uri, dados, config);

  return data;
}

export async function deleteSchedule(idSchedule) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(`${api_url}/schedule/${idSchedule}/delete`);

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.delete(uri, config);

  return data;
}

export async function blockTimesPerDays(idSchedule, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(
    `${api_url}/schedule/${idSchedule}/schedule-blocked-times-per-day`,
  );

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.post(uri, dados, config);

  return data;
}

export async function unlockScheduleById(idSchedule, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(
    `${api_url}/schedule/${idSchedule}/schedule-unlock-times-per-day`,
  );

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const { data } = await axios.post(uri, dados, config);

  return data;
}

/**
 *
 * @param {string | "yyyyMMdd"} date
 * @param {string | number} idSchedule
 * @returns {Promise<any>}
 */
export async function getSchedulesByDateAndId(date, idSchedule) {
  var api_url = new URL(env.API_URL).origin; // a api de schedule não tem /api na rota.
  var uri = new URL(
    `${api_url}/schedule/${idSchedule}/list-schedule-times/${date}`,
  );

  const config = {
    headers: {
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  uri.searchParams.append('token', localStorage.getItem('token'));

  const data = await axios.get(uri, config);

  return data.response?.data || data.data;
}

export const AgendaMedico = {
  unlockScheduleById,
  createNewSchedule,
  editScheduleById,
  getScheduleById,
  getProfessionalScheduleData,
  getHasScheduleTime,
  getEspecialtyWithSchedule,
  getProfessionalsByEspecialty,
  getProfessionalScheduleById,
  getProfessionalSpecialityScheduleById,
  getScheduleTimesById,
  postScheduleTime,
  putScheduleTime,
  getScheduleById,
  filterSchedule,
  listAllSchedule,
  deleteSchedule,
  getSchedulesByDateAndId,
  blockTimesPerDays,
  checkScheduleConflictFromUser,
  updateStatusSchedule,
};
