import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './../theme';

function Title(props) {
  const classes = useStyles();

  return (
    <Box
      id="titleRequestingUser"
      width={267}
      height={35}
      top={36}
      left={35}
      position="absolute"
      zIndex="tooltip"
      bgcolor="rgba(68, 151, 147, 1)"
      color="white"
      borderRadius={18}
    >
      <Grid
        classes={{ root: classes.boxTitleContent }}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography
            classes={{ colorInherit: classes.textWhite }}
            variant="h6"
            component="h1"
            align="center"
            color="inherit"
          >
            Solicitante: {props.solicitante}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

Title.defaultProps = {
  solicitante: '{Nome Social ou Nome}',
};

export default Title;
