import { useState } from 'react';

export interface IError {
  [key: string]: string | undefined;
}

function useNewError(validations: {
  [key: string]: (value: any) => string | string[] | undefined;
}) {
  const [error, setError] = useState<IError>({});

  function validaFormulario(dados: { [key: string]: any }): boolean {
    let error: { [key: string]: string } = {};

    for (var validation in validations) {
      let isInvalid: string | undefined;
      try {
        isInvalid = validations[validation](dados[validation]);
      } catch (e) {
        console.log(`Erro ao validar campo ${validation}`);
        console.log(e);
      }
      if (isInvalid !== undefined)
        error = Object.assign({ [validation]: isInvalid }, error);
    }

    setError(error);

    return Object.entries(error).length < 1;
  }

  function setErrors(errors: { [key: string]: string }): void {
    setError({ ...error, ...errors });
  }

  function removeError(key: string) {
    setError({ ...error, [key]: undefined });
  }

  return { error, validaFormulario, setErrors, removeError };
}

export default useNewError;
