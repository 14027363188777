import { Grid, Divider, TextField } from '@material-ui/core';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import PatientGroup from './PatientGroup/PatientGroup';

function GroupsEdit(props) {
  const { healthAttendance, patient } = useNewHealthAttendance();

  return (
    <>
      <Grid item md={12}>
        {patient && (
          <PatientGroup
            onGroup={(e) => props.onPregnant(true)}
            saveClose
            onClose={(dados) => {
              if (dados.group.length > 0 && dados.groups.length > 0) {
                const groupPatient = dados.groups.filter(
                  (res) => dados.group.indexOf(res.id) >= 0,
                );
                props.close(true);
              }
            }}
            paciente={patient}
          />
        )}
      </Grid>
    </>
  );
}

export default GroupsEdit;
