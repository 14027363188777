import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import MailIcon from '@material-ui/icons/Mail';
import NotificationItem from './components/item';
import {
  listNotification,
  readNotification,
} from '../../../../api/notificationUser';
import { fetchCurrentUserDetails } from '../../../../api/user';
import { Notification } from '../../../AtendimentoImediato/styles';
import { AppContext } from '../../../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    right: 0,
    top: 98,
    padding: 16,
    width: 450,
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
    background: '#F4F4F4',
    zIndex: 9999,
    boxShadow:
      '0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: '-8px 0 12px -16px',
  },
  title: {
    margin: 0,
    fontSize: 20,
    color: theme.palette.primary.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  info_item: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.primary.light,
    padding: 8,
    cursor: 'pointer',
    borderRadius: 8,
  },
  separator: {
    border: 0,
    borderBottom: '1px solid #c7c7c7',
    margin: '24px 0',
  },
}));

function NotificationCentral(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { notifications, UnreadNotifications } = useContext(AppContext);

  useEffect(() => {
    props.open && readAll();
  }, []);

  if (!props.open) {
    return null;
  }

  const readAll = async () => {
    if (!props.open || !notifications) return null;
    notifications.forEach((notification) => {
      const { id, user_id, read, notification_id } = notification;

      if (!read) {
        readNotification(
          localStorage.getItem('token'),
          id,
          user_id,
          notification_id,
        )
          .then(console.log)
          .catch(console.error);
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <IconButton onClick={props.back}>
          <ChevronLeftIcon
            fontSize="large"
            style={{ color: theme.palette.primary.light }}
          />
        </IconButton>
        <h2 className={classes.title}>Central de notificações</h2>
      </div>
      <div className={classes.info}>
        <div
          className={classes.info_item}
          style={{
            marginRight: 24,
            backgroundColor: theme.palette.background.grass,
          }}
        >
          <MailIcon style={{ fontSize: 16, marginRight: 12 }} /> Não lidas (
          {UnreadNotifications})
        </div>
        <div className={classes.info_item} onClick={(e) => readAll()}>
          <RestoreFromTrashIcon style={{ fontSize: 16, marginRight: 12 }} />{' '}
          Marcar todas como lidas
        </div>
      </div>
      <hr className={classes.separator} />
      {/* { props.children } */}
      {notifications &&
        notifications.map((notification, index) => {
          return (
            <Notification key={index}>
              <NotificationItem
                time="40m"
                img="https://i.pravatar.cc/40"
                note="Paciente Igor Josef Teufel, aguardando para ser atendido"
              >
                Cadastro de medico realizado com sucesso
              </NotificationItem>
            </Notification>
          );
        })}
    </div>
  );
}

export default NotificationCentral;
