import env from 'react-dotenv';
import axios from 'axios';

function getAllTrails(token, page, dados) {
  return axios
    .post(`${env.API_URL}/trail?page=${page}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllActions(token) {
  return axios
    .get(`${env.API_URL}/trail/actions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllProfiles(token) {
  return axios
    .get(`${env.API_URL}/trail/profiles`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUnidades(token) {
  return axios
    .get(`${env.API_URL}/trail/unidades`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUsers(token) {
  return axios
    .get(`${env.API_URL}/trail/users`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllTrails,
  getAllActions,
  getAllProfiles,
  getAllUsers,
  getAllUnidades,
};
