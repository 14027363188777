import {
  Typography,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 238,
    height: 53,
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    padding: '12px 16px 12px 16px',
  },
  rootDisabled: {
    borderColor: '#F5F6F6',
    background: '#F5F6F6',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    marginBottom: 8,
  },
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
}));

function DiaSemana(props) {
  const classes = useStyles();

  const { somaSimples, getNumero } = useConfiguracaoAgenda();

  const { value, setValue } = { ...props };

  return (
    <Grid container direction="column">
      {props.titulo && (
        <Grid item>
          <Typography
            component="h5"
            variant="h5"
            classes={{ root: classes.titulo }}
          >
            {props.titulo}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={5}>
          <Grid item>
            <InputLabel classes={{ root: classes.label }}>
              Primeira consulta
            </InputLabel>
            <InputBase
              name="primaira_consulta"
              placeholder="Primeira consulta"
              disabled={!props.primeiraConsulta}
              value={value.primeiraConsulta}
              onChange={(e) => {
                setValue(
                  getNumero(e.target.value),
                  value.retorno,
                  value.encaixe,
                  somaSimples(e.target.value, value.retorno),
                );
              }}
              classes={{
                root: classes.root,
                disabled: classes.rootDisabled,
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel classes={{ root: classes.label }}>Retorno</InputLabel>
            <InputBase
              name="retorno"
              placeholder="Retorno"
              disabled={!props.retorno}
              value={value.retorno}
              onChange={(e) =>
                setValue(
                  value.primeiraConsulta,
                  getNumero(e.target.value),
                  value.encaixe,
                  somaSimples(
                    value.primeiraConsulta,
                    getNumero(e.target.value),
                  ),
                )
              }
              classes={{
                root: classes.root,
                disabled: classes.rootDisabled,
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel classes={{ root: classes.label }}>
              Encaixe (Não contabiliza)
            </InputLabel>
            <InputBase
              name="encaixe"
              placeholder="Encaixe (Não contabiliza)"
              disabled={!props.encaixe}
              value={value.encaixe}
              onChange={(e) =>
                setValue(
                  value.primeiraConsulta,
                  value.retorno,
                  getNumero(e.target.value),
                  value.totalAtendimento,
                )
              }
              classes={{
                root: classes.root,
                disabled: classes.rootDisabled,
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel classes={{ root: classes.label }}>
              Total de atendimentos
            </InputLabel>
            <InputBase
              name="total_atendimento"
              placeholder="Total de atendimentos"
              disabled={!props.totalAtendimento}
              value={value.totalAtendimento}
              classes={{
                root: classes.root,
                disabled: classes.rootDisabled,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

DiaSemana.defaultProps = {
  value: {
    primeiraConsulta: '',
    retorno: '',
    encaixe: '',
    totalAtendimento: '',
  },
  setValue: (value) => {
    console.log(value, 'setValue');
  },
};

export default DiaSemana;
