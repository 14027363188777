import { toast } from "react-toastify";
import { createProfile as createProfileAPI, updateProfile } from "../../../../../api/profiles";
import ProfileCreate from "../../ProfileCreate";
import { IState } from "../../interfaces/state.interface";

interface IProps {
  state: IState;
  token: string | null;
  setState: React.Dispatch<React.SetStateAction<IState>>;
  listProfiles: () => void;
}

const ModalProfile = (props: IProps) => {
  const createProfile = (data: any): void => {
    props.setState({ ...props.state, createRegistry: true });

    const payload = {
      description: data[0],
      permissions: data[1],
      documents: data[2],
    };

    createProfileAPI(props.token, payload).then(async (res) => {
      if (res.status) {
        toast.success('Processo realizado com sucesso.');
        props.setState({
          ...props.state,
          isEdit: { status: false, id: null, duplicate: false },
          novoPerfil: false,
          createdProfile: true,
          createRegistry: false,
          registryDuplicaded: false,
        });
        props.listProfiles();
      } else {
        toast.error(res.message ? res.message : 'Erro ao realizar ação.');
        props.setState({
          ...props.state,
          registryDuplicaded:
            res.details !== undefined && res.details.description !== undefined,
          createRegistry: false,
        });
      }
    });
  }

  const editProfile = (data: any) => {
    if (props.state.isEdit.duplicate) createProfile(data);
    else {
      const payload = {
        id: props.state.isEdit.id,
        description: data[0],
        permissions: data[1],
        documents: data[2],
      };

      props.setState({ ...props.state, createRegistry: true });

      updateProfile(props.token, payload).then((res) => {
        if (res.status) {
          toast.success('Processo realizado com sucesso.');
          props.setState({
            ...props.state,
            isEdit: { status: false, id: null, duplicate: false },
            novoPerfil: false,
            createdProfile: true,
            createRegistry: false,
            registryDuplicaded: false,
          });
          props.listProfiles();
        } else {
          toast.error(res.message ? res.message : 'Erro ao realizar ação.');
          props.setState({
            ...props.state,
            registryDuplicaded:
              res.details !== undefined &&
              res.details.description !== undefined,
            createRegistry: false,
          });
        }

        props.setState({ ...props.state, createRegistry: false });
      });
    }
  }

  return (
    <ProfileCreate
      created={props.state.createdProfile}
      setErrors={(setErrors: any) => props.setState({ ...props.state, setErrors })}
      error={props.state.createProfileError}
      open={props.state.novoPerfil}
      counterPermission={props.state.counterPermission}
      permission={props.state.permissions}
      documents={props.state.documents}
      editData={props.state.isEdit}
      loading={props.state.createRegistry}
      registryDuplicaded={props.state.registryDuplicaded}
      createProfile={(data: any) => createProfile(data)}
      editProfile={(data: any) => editProfile(data)}
      token={props.token}
      onConfirmClose={() =>
        props.setState({
          ...props.state,
          confirmClose: true,
          registryDuplicaded: false,
        })
      }
      onClose={() => {
        props.setState({
          ...props.state,
          isEdit: { status: false, id: null, duplicate: false },
          novoPerfil: false,
          registryDuplicaded: false,
        });
      }}
    />
  )
}

export default ModalProfile;