import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    textHelperSelected: {
      marginLeft: '50px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      color: `${theme.palette.primary.light}`,
    },
    rootHide: {
      display: 'none',
    },
    title: {
      borderBottom: `1px solid ${theme.palette.grey[300]} !important`,
    },
    content: {
      height: 500,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.grey[300]} !important`,
      padding: '16px 20px',
    },
    icon: {
      color: theme.palette.grey[300],
    },
    doc_popover: {
      listStyle: 'none',
      padding: 0,
      margin: '0 16px 0 0',
      width: '100%',
    },
    doc_popoverItem: {
      listStyle: 'none',
      margin: 0,
      color: theme.palette.common.black,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      lineHeight: '21px',
    },
    accordion: {
      margin: '8px 0',
      background: theme.palette.grey[100],
      boxShadow: 'none',
      border: 0,
      borderRadius: 4,
      width: '100%',
    },
    accordion_heading: {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    subaccordion: {
      margin: '8px 0',
      background: theme.palette.grey[100],
      boxShadow: 'none',
      borderRadius: 4,
      width: '100%',
    },
    subaccordion_heading: {
      fontWeight: 'bold',
      color: theme.palette.common.black,
      fontSize: 14,
    },
    saveButton: {
      fontSize: '14px',
      borderRadius: 25,
      borderWidth: 2,
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      textTransform: 'none',
      padding: '6px 18px',
    },
    backButton: {
      fontSize: '14px',
      borderRadius: 25,
      borderWidth: 2,
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      textTransform: 'none',
      padding: '6px 18px',
      marginRight: 16,
    },
  }));
  
