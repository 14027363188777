import React, { useContext } from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './index.scss';
import { AppContext } from '../../../../../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.neutral.lightgray,
  },
  paciente__topo: {
    position: 'absolute',
    left: 0,
    top: 88,
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.grass,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  icon: {
    marginLeft: -7,
    cursor: 'pointer',
    height: 18.77,
  },
}));

export function HeaderCreate(props) {
  const classes = useStyles();
  const { userInfo, selectedProfile } = useContext(AppContext);

  const [selectedUnity, setSelectedUnity] = React.useState('');

  React.useEffect(() => {
    if (userInfo && selectedProfile) {
      if (Array.isArray(selectedProfile.units)) {
        var _selectedUnity = selectedProfile.units.find(
          (x) => x.medical_unit_id === userInfo.unidade,
        );

        if (_selectedUnity) {
          setSelectedUnity(_selectedUnity.description || '');
        }
      }
    }
  }, [userInfo, selectedProfile]);

  return (
    <Grid item className={classes.paciente__topo}>
      <Container>
        <Grid container alignItems="center">
          <Grid item>
            <ArrowBackIosIcon
              classes={{ root: classes.icon }}
              onClick={(e) => props.listar(true)}
              color="primary"
              fontSize="large"
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h1">
              Nova Solicitação
            </Typography>
          </Grid>
          <Grid item>
            <span className="line-separator"></span>
          </Grid>
          <Grid item>
            <Typography variant="body1" className="text-regular text-st">
              {selectedUnity}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
