import axios from 'axios';
import env from 'react-dotenv';

class Construtor {
  async getList(token) {
    try {
      const { data } = await axios.get(`${env.API_URL}/anamnese-form`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFullList(token, status) {
    try {
      const { data } = await axios.get(
        `${env.API_URL}/anamnese-form-full?active=${status}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async show(token, id) {
    try {
      const { data } = await axios.get(`${env.API_URL}/anamnese-form/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFormById(token, formId) {
    return await axios
      .get(`${env.API_URL_SERVICE}/form/form/${formId}/show`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      })
      .then((res) => res.data);
  }

  async showByVersion(token, version, id) {
    try {
      const { data } = await axios.get(
        `${env.API_URL}/anamnese-form/${id}?version=${version}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async showByForm(token, id) {
    try {
      const { data } = await axios.get(
        `${env.API_URL}/anamnese-form/${id}/by-form`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createForm(token, dados) {
    try {
      const response = await axios
        .post(`${env.API_URL}/anamnese-form`, JSON.stringify(dados), {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        })
        .then((res) => res.data)
        .catch((error) => error.response);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async showConditional(token, id) {
    try {
      const { data } = await axios.get(
        `${env.API_URL}/anamnese-form/${id}/conditionals`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateConditional(token, id, body) {
    try {
      const { data } = await axios.put(
        `${env.API_URL}/anamnese-form/${id}/conditionals`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addConditional(token, id, body) {
    try {
      const { data } = await axios.patch(
        `${env.API_URL}/anamnese-form/${id}/conditionals`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateAction(token, id, body) {
    try {
      const { data } = await axios.put(
        `${env.API_URL}/anamnese-form/${id}/action`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async put(token, id, dados) {
    try {
      const response = await axios
        .put(`${env.API_URL}/anamnese-form/${id}`, JSON.stringify(dados), {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        })
        .then((res) => res.data)
        .catch((error) => error.response);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addAction(token, id, body) {
    try {
      const { data } = await axios.patch(
        `${env.API_URL}/anamnese-form/${id}/action`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async destroyConditional(token, id) {
    try {
      const { data } = await axios.delete(
        `${env.API_URL}/anamnese-form/${id}/conditionals`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addCondition(token, id, body) {
    const { data } = await axios.patch(
      `${env.API_URL}/anamnese-form/${id}/condition`,
      body,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    );
    return data;
  }

  async updateCondition(token, id, body) {
    try {
      const { data } = await axios.put(
        `${env.API_URL}/anamnese-form/${id}/condition`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(token, id) {
    try {
      const { data } = await axios.delete(
        `${env.API_URL}/anamnese-form/${id}`,
        {
          validateStatus: function (status) {
            return status < 500;
          },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addConditionFull(token, id, body) {
    try {
      const { data } = await axios.patch(
        `${env.API_URL}/anamnese-form/${id}/conditionals-full`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async open(token, id) {
    try {
      const { data } = await axios.put(
        `${env.API_URL}/anamnese-form/${id}/open`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async statusUpdate(token, id, body) {
    try {
      const { data } = await axios.patch(
        `${env.API_URL}/anamnese-form-active/${id}`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async approvedForm(token, id) {
    try {
      const { data } = await axios.post(
        `${env.API_URL_SERVICE}/formfield/field/approved/${id}`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new Construtor();
