import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography } from '@material-ui/core';
import DInput from './../../../../../../shared/DInput';
import DRadio from './../../../../../../shared/DRadio';
import DAutoComplete from './../../../../../../shared/DAutoComplete';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#000000 !important',
  },
  input_root: {
    width: '50px !important',
    marginRight: 16,
    '& input': {
      padding: 8,
      fontSize: 14,
    },
  },
  input_root_tempo: {
    width: '40px !important',
    height: '36px !important',
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    '& input': {
      width: '40px !important',
      height: '36px !important',
      padding: '8px 12px 7px 12px',
      boxSizing: 'border-box',
      fontSize: 14,
    },
  },
  input_root_tempo_maior: {
    width: '49px !important',
    height: '36px !important',
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    '& input': {
      width: '49px !important',
      height: '36px !important',
      padding: '8px 13px 7px 12px',
      boxSizing: 'border-box',
      fontSize: 14,
    },
  },
  dropDownMedio: {
    fontSize: 14,
  },
}));

function Tabagismo(props) {
  const classes = useStyles();

  const [tipo, setTipo] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [tempo, setTempo] = useState({
    valor: null,
    tipo: null,
  });
  const [tempoParado, setTempoParado] = useState({
    valor: null,
    tipo: null,
  });

  const getNumber = (valor) => {
    return valor.toString().replace(/[^0-9]/, '');
  };

  return (
    <>
      <div className="fonte_preta_subtitulo_new_tabs">Tabagismo</div>
      <div className="box_info_flex_nova_solicitacao mt_35_new_tab">
        <Grid container spacing={1}>
          <Grid item md={12} style={{ paddingLeft: 10 }}>
            <DRadio
              inline
              name="smoking"
              onChange={(e) => {
                setTipo(e.target.value);
                props.onChange(e.target.value, tempo, tempoParado, quantidade);
              }}
              value={tipo}
              labelClass={classes.label}
              options={props.options}
            />
          </Grid>
          {tipo !== null && tipo !== 'Não fumante' && (
            <Grid item md={12}>
              <Grid container alignItems="center">
                <Grid item>
                  {`${tipo === 'Fumante' ? 'Fuma há' : 'Fumou por'} quanto tempo?`}
                </Grid>
                <Grid item>
                  <TextField
                    classes={{ root: classes.input_root_tempo }}
                    label=""
                    variant="outlined"
                    placeholder="00"
                    inputProps={{ maxLength: 2 }}
                    error={props.error && tempo.valor <= 0}
                    value={tempo.valor}
                    onChange={(e) => {
                      let new_tempo = {
                        ...tempo,
                        valor: getNumber(e.target.value),
                      };
                      setTempo(new_tempo);
                      props.onChange(tipo, new_tempo, tempoParado, quantidade);
                    }}
                  />
                </Grid>
                <Grid item>
                  <DAutoComplete
                    label=""
                    disableClearable
                    placeholder=""
                    labelColor="black"
                    openText="Visualizar listagem"
                    style={{ marginTop: -16, width: 104 }}
                    classes={{ input: classes.dropDownMedio }}
                    error={props.error && !tempo.tipo}
                    onChange={(e, newValue) => {
                      let new_tempo = {
                        ...tempo,
                        tipo: newValue ? newValue : null,
                      };
                      setTempo(new_tempo);
                      props.onChange(tipo, new_tempo, tempoParado, quantidade);
                    }}
                    options={props.yearOptions}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      value && option.id === value.id
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {tipo === 'Ex-fumante' && (
            <Grid item md={12}>
              <Grid container alignItems="center">
                <Grid item>Parou há quanto tempo?</Grid>
                <Grid item>
                  <TextField
                    label=""
                    variant="outlined"
                    placeholder="00"
                    inputProps={{ maxLength: 2 }}
                    error={props.error && tempoParado.valor <= 0}
                    value={tempoParado.valor}
                    onChange={(e) => {
                      let new_tempo_parado = {
                        ...tempoParado,
                        valor: getNumber(e.target.value),
                      };
                      setTempoParado(new_tempo_parado);
                      props.onChange(tipo, tempo, new_tempo_parado, quantidade);
                    }}
                    classes={{ root: classes.input_root_tempo }}
                  />
                </Grid>
                <Grid item>
                  <DAutoComplete
                    label=""
                    placeholder=""
                    disableClearable
                    labelColor="black"
                    openText="Visualizar listagem"
                    error={props.error && !tempoParado.tipo}
                    style={{ marginTop: -16, width: 119 }}
                    classes={{ input: classes.dropDownMedio }}
                    options={props.yearOptions}
                    onChange={(e, newValue) => {
                      let new_tempo_parado = {
                        ...tempoParado,
                        tipo: newValue ? newValue : null,
                      };
                      setTempoParado(new_tempo_parado);
                      props.onChange(tipo, tempo, new_tempo_parado, quantidade);
                    }}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      value && option.id === value.id
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {tipo === 'Fumante' && (
            <Grid item md={12}>
              <Grid container alignItems="center">
                <Grid item>Quantos cigarros por dia?</Grid>
                <Grid item>
                  <TextField
                    label=""
                    variant="outlined"
                    placeholder="000"
                    inputProps={{ maxLength: 3 }}
                    error={props.error && quantidade <= 0}
                    value={quantidade}
                    onChange={(e) => {
                      let new_quantidade = getNumber(e.target.value);
                      setQuantidade(new_quantidade);
                      props.onChange(tipo, tempo, tempoParado, new_quantidade);
                    }}
                    classes={{ root: classes.input_root_tempo_maior }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

Tabagismo.defaultProps = {
  error: undefined,
  options: [
    { value: 'Não fumante', label: 'Não fumante' },
    { value: 'Ex-fumante', label: 'Ex-fumante' },
    { value: 'Fumante', label: 'Fumante' },
  ],
  yearOptions: [
    { id: 1, value: 'Ano(s)' },
    { id: 2, value: 'Mês(es)' },
    { id: 3, value: 'Dia(s)' },
  ],
  onChange: function (tipo, tempo, tempoParado, quantidade) {
    console.log(tipo);
    console.log(tempo);
    console.log(tempoParado);
    console.log(quantidade);
  },
};

export default Tabagismo;
