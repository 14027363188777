import React from 'react';

export const HealthAttendanceContext = React.createContext({});

export const HealthAttendanceProvider = (props) => {
  const [acoes, setAcoes] = React.useState({
    finalizar: false,
    ligacao: false,
    submit: false,
  });

  return (
    <HealthAttendanceContext.Provider value={{ acoes, setAcoes }}>
      {props.children}
    </HealthAttendanceContext.Provider>
  );
};

export const useHealthAttendance = () =>
  React.useContext(HealthAttendanceContext);
