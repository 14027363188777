import React, { useMemo } from 'react';
import '../index.scss';
import { TextField } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { useState } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { getPatientById } from '../../../../api/patient';
import { useEffect } from 'react';

export default function UnidadesView(props) {
  const { patient, setPatient } = useNewHealthAttendance();
  const [unidadeRef, setUnidadeRef] = useState();

  useEffect(() => {
    if (patient) {
      getPatientById(localStorage.getItem('token'), patient.id).then((res) => {
        if (res.status) {
          setUnidadeRef(
            res.details.patient.medical_unit.length
              ? res.details.patient.medical_unit[0].id
              : '',
          );
        }
      });
    }
  }, [patient]);

  const unidadeRefs = useMemo(
    () =>
      props.unidadesRef.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [],
  );

  return (
    <div>
      <div className="main_flex_new_tab">
        <div className="fonte_paciente_tab">Unidade de Referência</div>
      </div>
      <TextField
        className="input_new_solicitante_new_tab"
        disabled
        id="standard-basic"
        label={
          unidadeRefs
            ? unidadeRefs.filter((res) => res.id == unidadeRef)[0]
              ? unidadeRefs.filter((res) => res.id == unidadeRef)[0].value
              : 'Não informado'
            : 'Não informado'
        }
        variant="standard"
      />
    </div>
  );
}
