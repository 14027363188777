import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import deficienciasCode from '../../../../../../../../assets/dados/deficiencia.json';
import populacaoEspecCode from '../../../../../../../../assets/dados/populacaoEspec.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import {
  mascaraPIS,
  mascaraRG,
} from '../../../../../../../../helpers/formatacao';
import DDateInput from '../../../../../../../shared/DDateInput';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import DMultAutoComplete from '../../../../../../../shared/DMultAutoComplete';
import { useStyles } from './styles';

import { useMemo } from 'react';

interface IComplementaryDataProps {
  errors: {
    numeroPis?: string;
    deficiencia?: string;
    responsavelLegal?: string;
    responsavelLegalDocumento?: string;
    responsavelLegalDocumentoEmissao?: string;
    populacaoEspec?: string;
  };
  grauDeRelacionamentoList: {
    id: number;
    value: string;
  }[];
  removeError: (key: string) => void;
}

export const ComplementaryData = ({
  errors,
  grauDeRelacionamentoList,
  removeError,
}: IComplementaryDataProps) => {
  const classes = useStyles();
  const { dadosComplementares, setDadosComplementares } = usePatient();

  const deficienciasMap = useMemo(
    () =>
      deficienciasCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  const populacaoEspecMap = useMemo(
    () =>
      populacaoEspecCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  return (
    <>
      <Grid container spacing={3} className={classes.alignItemsEnd}>
        <Grid item md={6}>
          <DInput
            id="numeroPis"
            placeholder="Número do NIT-PIS-PASEP"
            label="Número do NIT-PIS-PASEP"
            type="text"
            error={errors.numeroPis}
            helperText={errors.numeroPis}
            fullWidth={true}
            onChange={(e) => {
              removeError('numeroPis');

              setDadosComplementares((prevState) => {
                return {
                  ...prevState,
                  numeroPis: mascaraPIS(e.target.value),
                };
              });
            }}
            value={dadosComplementares.numeroPis}
          />
        </Grid>

        <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <strong style={{ alignSelf: 'center' }}>Possui Deficiência:</strong>
        </Grid>

        <Grid item md={3} style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={dadosComplementares.temDeficiencia}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onClick={(e) => {
                    setDadosComplementares((prevState) => {
                      return {
                        ...prevState,
                        temDeficiencia: true,
                      };
                    });
                  }}
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                />
              }
              label="Sim"
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!dadosComplementares.temDeficiencia}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onClick={(e) => {
                    setDadosComplementares((prevState) => {
                      return {
                        ...prevState,
                        temDeficiencia: false,
                        deficiencia: [],
                      };
                    });
                  }}
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                />
              }
              label="Não"
            />
          </div>
        </Grid>

        {dadosComplementares.temDeficiencia && (
          <Grid item md={6} style={{ alignSelf: 'center' }}>
            <DMultAutoComplete
              id="deficiencias"
              placeholder="Deficiências"
              label="Deficiências"
              error={errors.deficiencia}
              inputValue=""
              helperText={errors.deficiencia}
              defaultValue={
                dadosComplementares.deficiencia.length
                  ? dadosComplementares.deficiencia.find((res) =>
                      deficienciasMap.find((item) => res.value === item.value),
                    )
                    ? dadosComplementares.deficiencia.filter((res) =>
                        deficienciasMap.find(
                          (item) => res.value === item.value,
                        ),
                      )
                    : dadosComplementares.deficiencia
                  : dadosComplementares.deficiencia
              }
              options={deficienciasMap}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              onChange={(_, newValue) => {
                removeError('deficiencia');

                setDadosComplementares((prevState) => {
                  return {
                    ...prevState,
                    deficiencia: newValue,
                  };
                });
              }}
            />
          </Grid>
        )}
        <Grid item md={6} style={{ paddingTop: 0 }}>
          <DInput
            id="responsavel-legal"
            placeholder="Responsável legal"
            label="Responsável legal"
            type="text"
            error={errors.responsavelLegal}
            helperText={errors.responsavelLegal}
            fullWidth
            onChange={(e) => {
              setDadosComplementares({
                ...dadosComplementares,
                responsavelLegal: e.target.value,
              });
            }}
            value={dadosComplementares.responsavelLegal}
          />
        </Grid>
        <Grid item md={6} style={{ paddingTop: 0 }}>
          <DDropDownSimple
            id="grau-parentesco"
            placeholder="Grau de relacionamento"
            label="Grau de relacionamento"
            fullWidth
            value={dadosComplementares.grauDeRelacionamento}
            options={grauDeRelacionamentoList}
            onChange={(event) => {
              setDadosComplementares({
                ...dadosComplementares,
                grauDeRelacionamento: event,
              });
            }}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <DInput
            id="responsavelLegalDocumento"
            label="Número de identidade"
            placeholder="Número de identidade"
            labelColor="black"
            type="text"
            error={errors.responsavelLegalDocumento}
            helperText={errors.responsavelLegalDocumento}
            inputProps={{ maxLength: 100 }}
            positionIcon="end"
            fullWidth
            value={dadosComplementares.responsavelLegalDocumento}
            onChange={(e) => {
              setDadosComplementares({
                ...dadosComplementares,
                responsavelLegalDocumento: mascaraRG(e.target.value),
              });
            }}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <DDateInput
            id="responsavelLegalDocumentoEmissao"
            fullWidth
            labelColor="black"
            label="Data de emissão"
            error={errors.responsavelLegalDocumentoEmissao}
            helperText={errors.responsavelLegalDocumentoEmissao}
            maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
            maxDateMessage="A data de emissão não pode ser maior que a data atual"
            value={dadosComplementares.responsavelLegalDocumentoEmissao}
            onChange={(arg1) => {
              setDadosComplementares({
                ...dadosComplementares,
                responsavelLegalDocumentoEmissao: arg1,
              });
            }}
          />
        </Grid>
        <Grid
          item
          md={12}
          className={classes.field_padding}
          style={{ overflow: 'hidden' }}
        >
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDropDownSimple
              id="populacaoEspec"
              placeholder="População específica"
              label="População específica"
              fullWidth
              SelectProps={{ multiple: true }}
              error={errors.populacaoEspec}
              helperText={errors.populacaoEspec}
              value={
                dadosComplementares.populacaoEspec
                  ? dadosComplementares.populacaoEspec
                  : ''
              }
              options={populacaoEspecMap}
              onChange={(event) => {
                removeError('populacaoEspec');
                setDadosComplementares((prevState) => {
                  return {
                    ...prevState,
                    populacaoEspec: event,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
