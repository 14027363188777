import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useMemo } from 'react';
import orgaoEmissorCode from '../../../../../../../../assets/dados/orgaoEmissor.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { mascaraRNE } from '../../../../../../../../helpers/formatacao';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DDateInput from '../../../../../../../shared/DDateInput';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

interface INaturalizedIdentityProps {
  errors: {
    identidadeNaturalNumero?: string;
    identidadeNaturalOrgaoEmissor?: string;
    identidadeNaturalUF?: string;
    identidadeNaturalDataEmissao?: string;
    identidadeNaturalEmissao?: string;
    nomeMae?: string;
    nomePai?: string;
  };
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
  states: { id: number; value: string; description: string }[];
}

export const NaturalizedIdentity = ({
  errors,
  removeError,
  setErrors,
  states,
}: INaturalizedIdentityProps) => {
  const classes = useStyles();

  const {
    dadosIdentidadeNaturalizado,
    setDadosIdentidadeNaturalizado,
    dadosIdentidadeGeral,
    setDadosIdentidadeGeral,
  } = usePatient();

  const orgaoEmissorMap = useMemo(
    () =>
      orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  return (
    <Grid container spacing={3} className={classes.alignItemsEnd}>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>

      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="NumeroIdentidadeNatural"
              placeholder="Número do RNE"
              label="Número do RNE"
              style={{ marginTop: 5 }}
              type="text"
              error={errors.identidadeNaturalNumero}
              helperText={errors.identidadeNaturalNumero}
              fullWidth={true}
              value={dadosIdentidadeNaturalizado.identidadeNumero}
              onChange={(e) => {
                removeError('identidadeNaturalNumero');
                setDadosIdentidadeNaturalizado((prevState) => {
                  return {
                    ...prevState,
                    identidadeNumero: mascaraRNE(e.target.value),
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDateInput
              id="identidade-natural-emissao"
              label="Data de emissão"
              error={errors.identidadeNaturalEmissao}
              helperText={errors.identidadeNaturalEmissao}
              fullWidth
              className="datePicker"
              maxDate={new Date()}
              maxDateMessage="A data de emissão não pode ser maior que a data atual"
              value={dadosIdentidadeNaturalizado.identidadeDataEmissao}
              onError={(errorMessage) => {
                setErrors({
                  ...errors,
                  identidadeNaturalEmissao: errorMessage,
                });
              }}
              onChange={(arg1) => {
                removeError('identidadeNaturalEmissao');
                setDadosIdentidadeNaturalizado((prevState) => {
                  return {
                    ...prevState,
                    identidadeDataEmissao: arg1,
                  };
                });

                // setIdentidadeNaturalEmissao(arg1);
                // setIdentidadeEmissaoValida(arg2.isValid);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <div className="input-info">
              <DAutoComplete
                id="orgao-emissor-natural"
                placeholder="Órgão emissor"
                label="Órgão emissor"
                fullWidth
                error={errors.identidadeNaturalOrgaoEmissor}
                helperText={errors.identidadeNaturalOrgaoEmissor}
                defaultValue={orgaoEmissorMap.find(
                  (oe) =>
                    oe.value ===
                    dadosIdentidadeNaturalizado.identidadeOrgaoEmissor,
                )}
                options={orgaoEmissorMap}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) =>
                  value && option.id === value.id
                }
                onChange={(_, newValue) => {
                  removeError('identidadeNaturalOrgaoEmissor');
                  setDadosIdentidadeNaturalizado((prevState) => {
                    return {
                      ...prevState,
                      identidadeOrgaoEmissor: newValue ? newValue.id : null,
                    };
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDropDownSimple
              id="identidade-natural-uf"
              placeholder="UF"
              label="UF"
              fullWidth
              error={errors.identidadeNaturalUF}
              helperText={errors.identidadeNaturalUF}
              value={dadosIdentidadeNaturalizado.identidadeUF}
              options={states}
              onChange={(newValue) => {
                removeError('identidadeNaturalUF');
                setDadosIdentidadeNaturalizado((prevState) => {
                  return {
                    ...prevState,
                    identidadeUF: newValue ? newValue : null,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="NomeMae"
              placeholder="Nome da mãe"
              label="Nome da mãe"
              disabled={dadosIdentidadeGeral.maeDesconhecida}
              style={{ marginTop: 5 }}
              type="text"
              error={errors.nomeMae}
              helperText={errors.nomeMae}
              fullWidth
              onChange={(e) => {
                removeError('nomeMae');
                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomeMae: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomeMae}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                onClick={(e) => {
                  setDadosIdentidadeGeral((prevState) => {
                    return {
                      ...prevState,
                      nomeMae: '',
                      maeDesconhecida: e.target.checked,
                    };
                  });

                  if (!dadosIdentidadeGeral.maeDesconhecida) {
                    setDadosIdentidadeGeral((prevState) => {
                      return {
                        ...prevState,
                        nomeMae: '',
                      };
                    });
                    removeError('nomeMae');
                  }
                }}
              />
            }
            label="Mãe desconhecida"
          />
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="nomePai"
              placeholder="Nome do pai"
              label="Nome do pai"
              disabled={dadosIdentidadeGeral.paiDesconhecido}
              style={{ marginTop: 5 }}
              type="text"
              error={errors.nomePai}
              helperText={errors.nomePai}
              fullWidth={true}
              onChange={(e) => {
                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomePai: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomePai}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container spacing={3}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                onClick={(e) => {
                  if (!dadosIdentidadeGeral.paiDesconhecido) {
                    return setDadosIdentidadeGeral((prevState) => {
                      return {
                        ...prevState,
                        nomePai: '',
                        paiDesconhecido: e.target.checked,
                      };
                    });
                  }

                  setDadosIdentidadeGeral((prevState) => {
                    return {
                      ...prevState,
                      paiDesconhecido: e.target.checked,
                    };
                  });
                }}
              />
            }
            label="Pai desconhecido"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
