import './index.scss';
import { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Container from '@mui/material/Container';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Clear } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useStyles } from './theme';
import {
  MuteCall,
  UnmuteCall,
  AssumirELigar,
  AbdicarAtendimento,
  AbdicarAtendimentoNew,
  LigarParaEspecialista,
  LigarParaPaciente,
  Desligar,
  DesligarEFinalizar,
  Finalizar,
  AssumirAtendimento,
  FinalizarAtendimento,
  ChatButton,
  ChamadadeVoz,
  ChamadadeVideo,
  CancelarButtom,
  ConfirmarButtom,
  ConfirmarAbdicar,
  BackButtom,
} from './components/Buttons';

import ConfirmCustom from './components/ConfirmCustom';
import Confirm from './components/Confirm';
import { Assumir, Abdicar } from './modalcontent';
import InCall from './components/InCall';
import { toast } from 'react-toastify';
import { useNewHealthAttendance } from './../../../context/NewHealthAttendance';
import { AtendimentoContext } from '../../../context/AtendimentoContext';

function VoipCall(props) {
  const classes = useStyles();
  const {
    hangUpCall,
    finishLock,
    healthAttendance,
    setInCallCtx,
    inCallCtx,
    timeCallCtx,
    setClickOffButton,
    clickOffButton,
  } = useNewHealthAttendance();

  const [confirm, setConfirm] = useState(false);
  const [assumido, setAssumido] = useState(props.assumido);
  const [inCall, setInCall] = useState(props.inCall);
  const [motivoAbdicar, setMotivoAbdicar] = useState(null);

  useEffect(() => {
    if (!props.videoCall || !props.videoCall.status) setInCall(false);
  }, [props.videoCall]);

  useEffect(() => {
    if (inCallCtx) {
      setInCall(false);
      if (clickOffButton === false) {
        props.onEndCall('patient', props.call.phone.number, timeCallCtx);
        hangUpCall();
      }
    } else if (inCallCtx === false) {
      setInCall(true);
    }
  }, [inCallCtx]);

  useEffect(() => {
    setAssumido(props.assumido);
  }, [props.assumido]);

  useEffect(() => {
    if (props.selectedNumber) {
      handleGetCall(props.selectedAction || 'paciente_solicitante');
    }
  }, [props.selectedNumber]);

  const handleAssumir = () => {
    setConfirm(false);
    props.onAssumir(true);
  };

  const handleAbdicar = () => {
    if (motivoAbdicar) {
      setConfirm(false);
      props.onAbdicar(motivoAbdicar);
    } else toast.error('Escolha pelo menos uma opção.');
  };

  const handleGetNumber = (act) => {
    props.onGetNumber(act);
  };

  /**
   * @param {"paciente_solicitante" | "solicitante"} action
   */
  const handleGetCall = (action) => {
    // setInCall(true);
    props.onCall(action, (success) => {
      setInCall(success);
    });
  };

  const handleEndCall = (time, number) => {
    setInCall(false);
    props.onEndCall('patient', props.call.phone.number, time);
    hangUpCall();
  };

  const handleVideoCall = (action) => {
    // setInCall(true);
    props.onVideoCall(action);
  };

  return (
    <>
      <Grid
        classes={{ root: classes.toolBar }}
        className="actions-end"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item classes={{ root: classes.innerToolBar }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item md={4}>
              <Grid container alignItems="center">
                <Grid item>
                  <ArrowBackIosIcon
                    onClick={(e) => props.onBack()}
                    classes={{
                      root: classes.arrowBackRoot,
                      colorPrimary: classes.arrowBackColor,
                      fontSizeSmall: classes.arrowBackSize,
                    }}
                    color="primary"
                    fontSize="small"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h4"
                    component="h1"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      height: 33,
                      maxWidth: 400,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {props.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {props.locked ? (
              ''
            ) : (
              <Grid item style={{ flex: '1' }}>
                {assumido ? (
                  <>
                    {inCall ? (
                      <InCall
                        call={props.call}
                        onEndCall={(e) => handleEndCall(e)}
                        onMute={(e) => props.onMute(true)}
                        onUnMute={(e) => props.onMute(false)}
                        onAddUser={(e) => props.onAddUser(true)}
                      >
                        {props.chatButton && (
                          <ChatButton onClick={(e) => props.onChat(true)} />
                        )}
                      </InCall>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        {props.abdicarButton && (
                          <Grid item>
                            <AbdicarAtendimentoNew
                              onClick={(e) => setConfirm(true)}
                            >
                              Abdicar atendimento
                            </AbdicarAtendimentoNew>
                          </Grid>
                        )}
                        <Grid item>
                          {props.chatButton && (
                            <ChatButton
                              interconsultation={
                                healthAttendance &&
                                !!healthAttendance.interconsultation
                              }
                              onClick={(e) => props.onChat(true)}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ minWidth: 'max-content !important' }}
                        >
                          {props.callButton && (
                            <ChamadadeVoz
                              interconsultation={
                                healthAttendance &&
                                !!healthAttendance.interconsultation
                              }
                              onAction={(action) => {
                                if (action === 'solicitante') {
                                  handleGetCall(action);
                                } else if (
                                  action === 'paciente_solicitante' ||
                                  action === 'paciente'
                                ) {
                                  handleGetNumber(action);
                                } else {
                                  return false;
                                }
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ minWidth: 'max-content !important' }}
                        >
                          {props.videoCallButton && (
                            <ChamadadeVideo
                              interconsultation={
                                healthAttendance &&
                                !!healthAttendance.interconsultation
                              }
                              onAction={(action) => {
                                if (
                                  action === 'paciente_solicitante' ||
                                  action === 'paciente' ||
                                  action === 'solicitante'
                                ) {
                                  handleVideoCall(action);
                                } else {
                                  return false;
                                }
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ minWidth: 'max-content !important' }}
                        >
                          <FinalizarAtendimento
                            disabled={finishLock}
                            onClick={(e) => props.onFinish(true)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <AssumirAtendimento onClick={(e) => setConfirm(true)} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Modal de confirmação ao assumir um atendimento */}
      {confirm && !assumido && (
        <Confirm
          open={confirm}
          onClose={(e) => setConfirm(false)}
          children={<Assumir />}
          footer={
            <Grid container justifyContent="center">
              <Grid item md={6}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CancelarButtom onClick={(e) => setConfirm(false)}>
                      Cancelar
                    </CancelarButtom>
                  </Grid>
                  <Grid item>
                    <ConfirmarButtom onClick={handleAssumir}>
                      Confirmar
                    </ConfirmarButtom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}

      {confirm && assumido && (
        <ConfirmCustom
          open={confirm}
          onClose={(e) => setConfirm(false)}
          header={
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  classes={{ root: classes.textColorRed }}
                  variant="body1"
                  className="text-bold text-mt"
                >
                  Abdicar atendimento
                </Typography>
              </Grid>
              <Grid item>
                <Clear
                  onClick={(e) => setConfirm(false)}
                  color="primary"
                  classes={{ colorPrimary: classes.crossIcon }}
                />
              </Grid>
            </Grid>
          }
          children={
            <Abdicar
              onChange={(value, outro) => {
                if (value === 'Outro') setMotivoAbdicar(outro);
                else setMotivoAbdicar(value);
              }}
            />
          }
          footer={
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <BackButtom onClick={(e) => setConfirm(false)}>
                  Voltar
                </BackButtom>
              </Grid>
              <Grid item>
                <ConfirmarAbdicar onClick={handleAbdicar}>
                  Abdicar atendimento
                </ConfirmarAbdicar>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}

VoipCall.protoTypes = {
  title: PropTypes.string,
  assumido: PropTypes.bool,
  inCall: PropTypes.bool,
  onAssumir: PropTypes.func,
  onAbdicar: PropTypes.func,
  onCall: PropTypes.func,
  onEndCall: PropTypes.func,
  onChat: PropTypes.func,
  onFinish: PropTypes.func,
  onMute: PropTypes.func,
  onUnMute: PropTypes.func,
  onAddUser: PropTypes.func,
};

VoipCall.defaultProps = {
  locked: false,
  title: 'Titulo',
  abdicarButton: false,
  chatButton: false,
  callButton: false,
  videoCallButton: false,
  assumido: false,
  inCall: false,
  call: {
    status: true,
    duration: '0:00',
    phone: {
      nickname: 'Apelido',
      number: '(11) 00000-0000',
    },
  },
  videoCall: {
    status: false,
  },
  onBack: function () {
    console.log('onBack');
  },
  onAssumir: function () {
    console.log('onAssumir');
  },
  onAbdicar: function (motivo) {
    console.log(motivo);
    console.log('onAbdicar');
  },
  onChat: function () {
    console.log('onChat');
  },
  onCall: function () {
    console.log('onCall');
  },
  onEndCall: function () {
    console.log('onEndCall');
  },
  onFinish: function () {
    console.log('onFinish');
  },
  onMute: function () {
    console.log('onMute');
  },
  onUnMute: function () {
    console.log('onUnMute');
  },
  onAddUser: function () {
    console.log('onAddUser');
  },
  onVideoCall: function () {
    console.log('onVideoCall');
  },
};

export default VoipCall;
