import env from 'react-dotenv';
import axios from 'axios';

/**
 * @param {Object.<string, string>} params
 * @returns {Array.<{key: string, value: string}>}
 * @description Função que retorna os parâmetros da url em chave e valor
 */
function getParams(params) {
  if (typeof params === 'object') {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

/**
 * @param {*} token
 * @param {object} params
 * @param {string?} params.pcd Ambos = null | Sim = 1 | Não = 0
 * @param {string?} params.group_id
 * @param {string?} params.medical_unit_id
 * @param {string?} params.search
 * @param {string?} params.min_age
 * @param {string?} params.max_age
 * @description Filtra por pacientes pelos parâmetros passados
 **/
export async function filterPatient(token, params) {
  const uri = new URL(`${env.API_URL}/patient/filter`);

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  const response = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': token,
    },
  });
  return response.data;
}

/**
 * @param {*} token
 * @param {object} params
 * @param {string?} params.pcd Ambos = null | Sim = 1 | Não = 0
 * @param {string?} params.group_id
 * @param {string?} params.medical_unit_id
 * @param {string?} params.search
 * @param {string?} params.min_age
 * @param {string?} params.max_age
 * @description Filtra as agendas de um profissional.
 **/
export async function simpleFilterPatient(token, params) {
  const uri = new URL(`${env.API_URL}/patient/simple-filter`);

  delete params.idade;

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  const response = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': token,
    },
  });
  return response.data;
}

export async function listPronouns() {
  const response = await axios.get(`${env.API_URL}/pronoun`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': localStorage.getItem('token'),
    },
  });
  return response.data;
}

function getAllPatients(token, page = 1) {
  return axios
    .get(`${env.API_URL}/patient?page=${page}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllPatientsFilter(token, page = 1, filter) {
  return axios
    .get(
      `${env.API_URL}/patient?page=${page}&search=${filter.search}&grupo=${filter.grupo}&unidade=${filter.unidade}&pcd=${filter.pcd}&idadede=${filter.idade.de}&idadeate=${filter.idade.ate}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getPatientByPage(token, page) {
  return axios
    .get(`${env.API_URL}/patient/filter?page=${page}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

function getPatientById(token, id) {
  return axios
    .get(`${env.API_URL}/patient/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createPatient(token, dados) {
  return axios
    .post(`${env.API_URL}/patient`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getPatientDeficiencies(token) {
  return axios
    .get(`${env.API_URL}/patientDeficienciesType`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updatePatient(token, dados, id) {
  return axios
    .put(`${env.API_URL}/patient/${id}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

async function updatePatientFull(token, dados, id) {
  return axios
    .put(`${env.API_URL}/patient/${id}/full`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deletePatient(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createDocument(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-document`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateDocument(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-document`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteDocument(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-document`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateCNSDocument(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-document-cns`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createPregnancy(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-pregnancy`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updatePregnancy(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-pregnancy`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deletePregnancy(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-pregnancy`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createDeficiencies(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-deficiencies`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateDeficiencies(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-deficiencies`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteDeficiencies(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-deficiencies`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createContact(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-contact`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateContact(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-contact`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteContact(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-contact`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createHasMedicalUnit(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-has-medical-unit`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateHasMedicalUnit(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-has-medical-unit`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteHasMedicalUnit(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-has-medical-unit`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createAddress(token, dados, id) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/patient-address`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateAddress(token, dados, id) {
  return axios
    .put(
      `${env.API_URL}/patient/${id}/patient-address`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteAddress(token, id) {
  return axios
    .delete(`${env.API_URL}/patient/${id}/patient-address`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getPatientByFilter(token, dados, tipo) {
  if (tipo == 'sidebar') {
    var url = '';

    if (dados.min_age.length) {
      url += `&min_age=${dados.min_age}`;
    }

    if (dados.max_age.length) {
      url += `&max_age=${dados.max_age}`;
    }

    if (dados.pcd != 'null') {
      url += `&pcd=${dados.pcd}`;
    }

    if (dados.group_id) {
      url += `&group_id=${dados.group_id}`;
    }

    if (dados.medical_unit_id) {
      url += `&medical_unit_id=${dados.medical_unit_id}`;
    }

    return axios
      .get(`${env.API_URL}/patient/filter?${url}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      })
      .then((res) => res.data)
      .catch((error) => error.response);
  } else {
    return axios
      .get(`${env.API_URL}/patient/filter?search=${dados}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      })
      .then((res) => res.data)
      .catch((error) => error.response);
  }
}

function getPatientBySimpleFilter(token, dados, page = 1) {
  return axios
    .get(`${env.API_URL}/patient/simple-filter?page=${page}&search=${dados}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateGroup(token, id, dados) {
  return axios
    .post(
      `${env.API_URL}/patient/${id}/all-patient-has-group`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getHistoryByPatient(token, patientId) {
  return axios
    .get(`${env.API_URL}/health-attendance-history/patient/${patientId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllPatients,
  getAllPatientsFilter,
  getPatientById,
  createPatient,
  updatePatient,
  deletePatient,
  updateCNSDocument,
  createDocument,
  updateDocument,
  deleteDocument,
  createPregnancy,
  updatePregnancy,
  deletePregnancy,
  createDeficiencies,
  updateDeficiencies,
  deleteDeficiencies,
  createContact,
  updateContact,
  deleteContact,
  createHasMedicalUnit,
  updateHasMedicalUnit,
  deleteHasMedicalUnit,
  createAddress,
  updateAddress,
  deleteAddress,
  getPatientByFilter,
  getPatientBySimpleFilter,
  updateGroup,
  getPatientByPage,
  updatePatientFull,
  getPatientDeficiencies,
  getHistoryByPatient,
};
