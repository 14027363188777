import { Box, Grid } from '@material-ui/core';

function Guests(props) {
  return (
    <Box
      id="participantsList"
      width={props.width}
      height="100%"
      top={36}
      right={22.31}
      zIndex="tooltip"
      overflow="auto"
      position="absolute"
    >
      <Grid container direction="column" spacing={1} alignItems="center">
        {props.guests.map((guest, index) => {
          if (guest != '') {
            return (
              <Grid item>
                <Box
                  width={210}
                  height={127}
                  overflow="hidden"
                  borderRadius={8}
                >
                  {guest}
                </Box>
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
}

export function Guest(props) {
  return (
    <Box width="100%" height="100%" overflow="hidden">
      {props.guest}
    </Box>
  );
}

Guests.defaultProps = {
  width: 230,
  guests: [],
};

Guest.defaultProps = {
  guest: null,
};

export default Guests;
