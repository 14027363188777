import axios from 'axios';
import env from 'react-dotenv';

function getFormByHealthAttendanceId(token, id) {
  return axios
    .get(
      `${env.API_URL}/anamnese-form/health-attendance-type/${id}?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createAnamnesePreForm(token, dados) {
  return axios
    .post(
      `${env.API_URL}/anamnese-pre-form?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function anamneseFormByLineCare(token, dados) {
  return axios
    .post(
      `${env.API_URL}/anamnese-form/by-line-care?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function anamneseFormByFilter(token, dados) {
  return axios
    .post(
      `${env.API_URL}/anamnese-form/filter?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function createAnamnese(token, dados) {
  return axios
    .post(`${env.API_URL}/anamnese?token=${token}`, dados, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function anamneseFormAnswer(token, anamneseId, dados) {
  return axios
    .patch(
      `${env.API_URL}/anamnese-form-answer/${anamneseId}?token=${token}`,
      dados,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getFormAnswer(token, formanswerid) {
  return axios
    .get(
      `${env.API_URL_SERVICE}/form/form/form-answer/${formanswerid}?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getFormConditionals(token, formId) {
  return axios
    .get(
      `${env.API_URL}/anamnese-form-answer/${formId}/show-all-conditionals?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function anamneseFormHistory(token, anamneseId, dados) {
  return axios
    .put(
      `${env.API_URL}/anamnese-form/${anamneseId}?token=${token}`,
      {
        anamnese_id: anamneseId,
        data: dados,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function anamneseFormDischarge(token, anamneseFormId, dados) {
  return axios
    .post(
      `${env.API_URL}/anamnese-form/health-attendance/${anamneseFormId}/discharge?token=${token}`,
      dados,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getAnamneseFormAnswer(token, anamneseFormId) {
  return axios
    .get(
      `${env.API_URL}/anamnese-form-answer/${anamneseFormId}/show?token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getAnamneseFormInterconsultation(token, medical_speciality_id) {
  return axios
    .post(
      `${env.API_URL}/anamnese-form/anamnese-for-interconsult?token=${token}`,
      {
        medical_speciality_id,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getFormByHealthAttendanceId,
  createAnamnesePreForm,
  anamneseFormByLineCare,
};
