import { Grid, makeStyles } from '@material-ui/core';
import SimpleModal from '../../shared/Modal';
import { useContext } from 'react';
import { AtendimentoContext } from '../../../context/AtendimentoContext';

const useStyles = makeStyles({
  modalItem: {
    margin: '10px',
  },

  modalFidi: {
    width: '100%',
    height: '100%',
  },
});

export function AtendimentoCMD(props) {
  const classes = useStyles();

  return (
    <SimpleModal
      open={props.open || false}
      onClose={(e) => props.setModal()}
      fullWidth
      style={{ width: 200 }}
      title="Atendimento CMD"
    >
      <Grid container spacing={3} id="atendimento-cmd">
        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Estabelecimento de Saúde: </strong>
            Hosp Mun Pirituba Jose Soares Hungria
          </span>
        </Grid>
        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Início: </strong>
            12/01/2012
          </span>

          <span>
            <strong>Horário: </strong>
            19:22
          </span>

          <span>
            <strong>Fim: </strong>-
          </span>

          <span>
            <strong>Horário: </strong>
            20:22
          </span>
        </Grid>

        <Grid item xs={12} className={classes.modalItem}>
          <span className="mr">
            <strong>Precedência: </strong>
            19:22
          </span>

          <span>
            <strong>Modalidade: </strong>
            Atenção à Urgência / Emergência
          </span>
        </Grid>

        <Grid item xs={12} className={classes.modalItem}>
          <span>Caráter: </span>

          <button className="highlight-box mr">Urgência</button>

          <span>
            <strong>Motivo Desfecho: </strong>
            Alta clínica
          </span>
        </Grid>

        <Grid item>
          <hr />
        </Grid>
        <Grid item xs={12} className={classes.modalItem}>
          <span className="head">
            <strong>Dados Sistema Externo</strong>
          </span>
        </Grid>

        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Sistema</strong>:{' '}
          </span>

          <span>SGH_CMD_ATENDIMENTO_2224_Pronto_Socorro</span>
        </Grid>

        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Código Paciente</strong>:{' '}
          </span>

          <span className="mr">3712382</span>

          <span>
            <strong>Código Atendimento</strong>:{' '}
          </span>

          <span>772125</span>
        </Grid>
        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Código prontuário</strong>: 92227172
          </span>
        </Grid>
        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Data inclusão</strong>:{' '}
          </span>

          <span className="mr">17/04/2019 19:25</span>

          <span>
            <strong>Log inclusão</strong>:{' '}
          </span>

          <span>8/01/2021 05:58</span>
        </Grid>
        <Grid item xs={12} className={classes.modalItem}>
          <span>
            <strong>Data inclusão</strong>:{' '}
          </span>

          <span className="mr">17/04/2019 19:25</span>

          <span>
            <strong>Log inclusão</strong>:{' '}
          </span>

          <span>8/01/2021 05:58</span>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}

export function Fidi(props) {
  return (
    <SimpleModal fullPage open={props.open} onClose={(e) => props.setModal()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          item
          className="text-center"
          style={{ height: '100%', width: '100%' }}
        >
          <iframe
            width="100%"
            height="100%"
            noborder
            src="https://myvue.fidi.org.br/portal/Login.aspx"
          ></iframe>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}

export function Prescricao(props) {
  const { newPrescription, setNewPrescription } =
    useContext(AtendimentoContext);

  return (
    <SimpleModal
      fullPage
      open={newPrescription}
      onClose={(e) => setNewPrescription(null)}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          item
          className="text-center"
          style={{ height: '100%', width: '100%' }}
        >
          {newPrescription && newPrescription.url && (
            <iframe
              width="100%"
              height="94%"
              noborder
              src={newPrescription.url}
            ></iframe>
          )}
        </Grid>
      </Grid>
    </SimpleModal>
  );
}
