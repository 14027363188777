import { Grid } from '@material-ui/core';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { useStyles } from './styles';

interface ForeignIdentityProps {
  patientRgUfCode: string;
  countries: { id: number; value: string }[];
}

export const ForeignIdentity = ({
  patientRgUfCode,
  countries,
}: ForeignIdentityProps) => {
  const classes = useStyles();
  const { dadosIdentidadeEstrangeiro, dadosIdentidadeGeral } = usePatient();

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Número do passaporte:</strong>{' '}
        {dadosIdentidadeEstrangeiro.numeroPassaporte || 'Não informado'}
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Data de emissão do passaporte:</strong>{' '}
        {dadosIdentidadeEstrangeiro.dataEmissaoPassaporte || 'Não informado'}
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Data de validade do passaporte:</strong>{' '}
        {dadosIdentidadeEstrangeiro.dataValidade || 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>País emissor do passaporte:</strong>{' '}
        {countries.find(
          (x) => x.id === dadosIdentidadeEstrangeiro.paisPassaporte,
        )
          ? countries.find(
              (x) => x.id === dadosIdentidadeEstrangeiro.paisPassaporte,
            )?.value
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>UF:</strong> {patientRgUfCode}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Nome da mãe:</strong>{' '}
        {dadosIdentidadeGeral.maeDesconhecida
          ? 'Mãe desconhecida'
          : dadosIdentidadeGeral.nomeMae || 'Não informado'}
      </Grid>

      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Nome do pai:</strong>{' '}
        {dadosIdentidadeGeral.paiDesconhecido
          ? 'Pai desconhecido'
          : dadosIdentidadeGeral.nomePai || 'Não informado'}
      </Grid>
    </Grid>
  );
};
