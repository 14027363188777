import { Grid, Divider, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';

function GroupsView(props) {
  const { healthAttendance, patient } = useNewHealthAttendance();
  console.log(patient);
  return (
    <>
      <Grid item md={12}>
        <div className="fonte_paciente_tab">Grupo</div>
        <div className="main_div_tag_paciente_tab mt10_new_tabs">
          {patient &&
            patient.patient_has_group
              .filter((res) => res.group !== null)
              .map((item) => {
                return (
                  <div className="tag_paciente_tab">{item.group.name}</div>
                );
              })}

          {patient &&
            patient.patient_has_group
              .filter((res) => res.group.length === 0)
              .map((item) => <div className="tag_paciente_tab">Nenhum</div>)}
        </div>
      </Grid>
    </>
  );
}

export default GroupsView;
