import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { createAnamnesePreForm } from 'api/anamnese';
import { uploadHealthAttendanceFile } from 'api/healthAttendance';
import {
  checkScheduleDistribution,
  getHasScheduleTime,
  postScheduleTime,
  putScheduleTime,
} from 'api/schedule/agenda-medico';
import ModalReagendamento from 'component/shared/CalendarMonth/components/Modais/ModalReagendamento';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import { useAppProvider } from 'context/AppContext';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import { PatientContext } from 'context/PatientContext';
import {
  PreHealthAttendanceContext,
  usePreHealthAttendance,
} from 'context/PreHealthAttendanceContext';
import { usePatient } from 'context/usePatient';
import { isPast } from 'date-fns';
import { isFuture } from 'date-fns/esm';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  scheduleItem: {
    padding: 32,
  },
  card: {
    background: theme.palette.background.snow,
    border: `2px solid ${theme.palette.neutral.midlightgray} !important`,
    marginBottom: 28.8,
  },
  cardBody: {
    padding: 15,
    paddingBottom: '15px !important',
  },
  bckContent: {
    borderRadius: 2,
    width: 1,
    backgroundColor: theme.palette.neutral.darkgray,
    opacity: 0.3,
    height: '50px',
  },
  btnReagendar: {
    textTransform: 'none !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important',
    height: 36,
    marginRight: '10px !important',
  },
  btnCancelar: {
    textTransform: 'none !important',
    color: `${theme.palette.support.error} !important`,
    fontWeight: '400 !important',
    height: 36,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'baseline',
  },
  statusHealthAttendanceOn: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.check,
    marginRight: 5,
  },
  statusHealthAttendanceOff: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.error,
    marginRight: 5,
  },
  titleSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 28,
  },
}));

export default function TimesAvailableAgenda(props) {
  const classes = useStyles();

  let user_id = localStorage.getItem('uid');

  const [open, setOpen] = useState(false);
  const [horariosAgendaDoDia, setHorariosAgendaDoDia] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [loading, setLoading] = useState(null);

  const horariosAgendadosFiltro = horariosAgendaDoDia.filter(
    (x) => isFuture(new Date(x.data_time)) && x.status == 'disponivel',
  );

  const {
    justificativa,
    horariosAgenda,
    setHorariosAgendados,
    dataSheduleItem,
    dadosAgendamento,
    patientDocuments,
    anamneseFormId,
    setFormError,
  } = useConfiguracaoAgenda();

  const {
    patientFile,
    comorbidity,
    comorbidityFamilyAF,
    lineOfCare,
    healthAttendanceType,
    healthAttendanceId,
    setHealthAttendanceId,
    anamneseId,
    continuousUseMedications,
    foodAllergies,
    drugAllergies,
    physycalActivity,
    smoking,
    smoker,
    exSmoker,
    smoking_amount,
    smoking_period,
    smoking_period_without,
    alcoholic,
    alcoholicPeriod,
    drugs,
    drugsList,
    symptomItems,
    healthAttendanceAnamneseForm,
    group,
    serviceCharacter,
    interconsultation,
    drinkstate,
  } = usePreHealthAttendance(PreHealthAttendanceContext);

  const { LoadingControl } = useAppProvider();
  const { patientData, idPatient } = usePatient(PatientContext);
  const { setOpenScheduleOfTheDay } = useContext(GlobalContext);

  useEffect(() => {
    if (props.isAgendamento) updateSelectedTime();
    else
      setHorariosAgendaDoDia(
        horariosAgenda.filter(
          (item) =>
            moment(new Date(item.data_time)).format('DD/MM/YYYY') ===
            props.dataScheduleItem.day,
        ),
      );
  }, [horariosAgenda, props.dataScheduleItem]);

  async function updateSelectedTime() {
    if (Array.isArray(horariosAgenda) && horariosAgenda.length > 0) {
      try {
        LoadingControl.showLoading('Verificando disponibilidade...');

        const availableSchedules = [];

        const agendasDoDia = horariosAgenda.filter(
          (item) =>
            moment(new Date(item.data_time)).format('DD/MM/YYYY') ===
            props.dataScheduleItem.day,
        );

        if(!agendasDoDia.length) {
          setHorariosAgendaDoDia([]);
          return
        } 

        const scheduleIds = [
          ...new Set(agendasDoDia.map((x) => x.schedule_id)),
        ];

        for await (const schedule_id of scheduleIds) {
          let { day } = props.dataScheduleItem;

          if (typeof day === 'string' && props.tipoAgendamento) {
            day = day.split('/').reverse().join('-'); // yyyy-MM-dd

            await checkScheduleDistribution(
              schedule_id,
              day,
              props.tipoAgendamento,
            )
              .then((response) => {
                if (response.status != false) availableSchedules.push(schedule_id);
              })
              .catch(console.error);
          }
        }

        if (availableSchedules.length) {
          if (agendasDoDia.length === 0) {
            return setHorariosAgendaDoDia([]);
          }

          setHorariosAgendaDoDia(
            agendasDoDia.filter((item) =>
              availableSchedules.includes(item.schedule_id),
            ),
          );
        } else {
          toast.error(
            'Sem disponibilidade para o tipo de agendamento selecionado.',
          );
          setHorariosAgendaDoDia([]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        LoadingControl.hideLoading();
      }
    } else {
      setHorariosAgendaDoDia([]);
    }
  }

  const handleClose = () => {
    setSelectedTime(null);
    setOpen(false);
  };

  const handleReagendarCreate = (horarioAgend) => {
    if (justificativa) {
      setSelectedTime(horarioAgend);
      setOpen(true);
    } else {
      setFormError(false);
      toast.error('O preenchimento da justificativa é obrigatório');
    }
  };

  const handleAgendarCreate = (horarioAgend) => {
    setSelectedTime(horarioAgend);
    setOpen(true);
  };

  const renderDate = (strDate) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format('DD/MM/YYYY');
  };

  const renderHour = (strHour) => {
    const hourObj = new Date(strHour);
    const momentObj = moment(hourObj);
    return momentObj.format('HH:mm');
  };

  // TODO: IMPLEMENTAR VALIDAÇÃO DE COTAS
  const validateQuotes = (data) => {
    const validated = false;

    if (!validated) {
      toast.error('Favor implementar as cotas <3');
    }
    setLoading(false);
    return validated;
  };

  function confirmarReagendamento() {
    setLoading(true);

    var _patient = dataSheduleItem.patient;

    const { health_attendance } =
      dadosAgendamento?.horarioAgendado?.schedule_health_attendance;

    if (
      !_patient &&
      Array.isArray(dataSheduleItem.schedule_participants) &&
      dataSheduleItem.schedule_participants.length > 0
    ) {
      let patientData = dataSheduleItem.schedule_participants.find(
        (x) => x.patient_id,
      );

      _patient = patientData ? patientData.patient : null;
    }

    if (!_patient) {
      toast.error('Erro! Não foi possível encontrar o paciente.');
      console.error('Sem pacientes em', dataSheduleItem);
      return;
    }

    var validDocument = patientDocuments.find(
      (item) =>
        item.patient_document_fields[0].value &&
        item.patient_document_fields[0].value != '',
    );

    var postObj = {
      name: _patient.full_name || _patient.social_name,
      status: 'agendado',
      patient: {
        id: _patient.id,
        document_type: validDocument.document_type.description,
        document_value: validDocument.patient_document_fields[0].value,
      },
      anamneseForm: {
        id: anamneseFormId,
      },
      healthAttendance: {
        interconsultation: health_attendance
          ? Number(Boolean(health_attendance.interconsultation))
          : Number(Boolean(interconsultation)),
      },
    };

    // if (!validateQuotes()) return

    postScheduleTime(selectedTime.id, postObj)
      .then((response) => {
        if (response.status) {
          cancelarAgendamentoAnterior(dataSheduleItem, justificativa);

          props.handleMenuItem('form_anamnese');
        } else {
          toast.error(
            'Ocorreu um erro ao reagendar. Por favor, tente novamente em alguns minutos.',
          );
          console.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao reagendar. Por favor, tente novamente em alguns minutos.',
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  /**
   * Utilizado na solicitacao de atendimento do tipo Agendado
   */
  function confirmarAgendamento() {
    setLoading(true);

    var validDocument = patientDocuments.find(
      (item) =>
        item.patient_document_fields[0].value &&
        item.patient_document_fields[0].value != '',
    );

    var postObj = {
      name: patientData.patient.full_name,
      status: 'agendado',
      patient: {
        id: patientData.patient.id,
        document_type: validDocument.document_type.description,
        document_value: validDocument.patient_document_fields[0].value,
      },
      anamneseForm: {
        id: healthAttendanceAnamneseForm.id,
      },
      healthAttendance: {
        interconsultation: Number(Boolean(interconsultation)),
      },
    };

    // if (!validateQuotes()) return

    postScheduleTime(selectedTime.id, postObj)
      .then((response) => {
        if (response.status) {
          if (props.dataAnamneseForm) {
            let dataAnamnesePreForm = props.dataAnamneseForm;
            dataAnamnesePreForm['health_attendance_id'] =
              response.healthAttendance.id;
            sendDataAnamneseForm(dataAnamnesePreForm);
          }

          if (props.patientFile) {
            attachPatientFile(response.healthAttendance.id);
          }

          handleSuccess();

          props.handleMenuItem('form_anamnese');
        } else {
          toast.error(
            'Ocorreu um erro ao agendar. Por favor, tente novamente em alguns minutos.',
          );
          console.error(response.message);
        }

        setLoading(false);
      })
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao agendar. Por favor, tente novamente em alguns minutos.',
        );
        console.error(error);

        setLoading(false);
      });
  }

  function cancelarAgendamentoAnterior(horarioAgendObj, justificativa) {
    setLoading(true);

    var dadosObj = {
      status: 'cancelado',
      justification: justificativa,
    };

    putScheduleTime(horarioAgendObj.id, dadosObj)
      .then((response) => {
        if (response.status !== false) {
          handleClose();
          toast.success('Reagendamento realizado com sucesso');

          checarHorariosAgendamento();
        } else {
          toast.error(
            'Ocorreu um erro ao reagendar. Por favor, tente novamente em alguns minutos.',
          );
        }
      })
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao reagendar. Por favor, tente novamente em alguns minutos.',
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function checarHorariosAgendamento() {
    getHasScheduleTime(dadosAgendamento.id).then((data) => {
      if (data.status == false) {
        setHorariosAgendados(data.scheduleDataExtract);
        props.handleMenuItem('lista_agendamentos');
      } else {
        props.handleMenuItem('lista_agendas');
      }
    });
  }

  function handleSuccess() {
    toast.success('Solicitação realizada com sucesso');
  }

  function handleError() {
    toast.error('Erro! Algo deu errado!');
  }

  function getData() {
    let medicine = continuousUseMedications.map(function (med) {
      return {
        id: med.id,
        name: med.value,
      };
    });

    let food_allergy = foodAllergies.map(function (food) {
      return {
        id: food.id,
        name: food.value,
      };
    });

    let medicine_allergy = drugAllergies.map(function (drug) {
      return {
        id: drug.id,
        name: drug.value,
      };
    });

    var data;

    data = {
      comorbidity: comorbidity
        .filter((y) => y.checked)
        .map((x) => {
          return {
            id: x.id,
            observation: x.observation,
          };
        }),
      symptom: symptomItems,
      // symptoms,
      // symptomsDays,
      comorbidity_family_history: comorbidityFamilyAF
        .filter((y) => y.checked)
        .map((x) => {
          return {
            id: x.id,
            observation: x.observation,
          };
        }),
      lineOfCare,
      healthAttendanceType,
      serviceCharacter,
      health_attendance_id: healthAttendanceId,
      healthAttendanceAnamneseForm,
      anamneseId,
      medicine,
      food_allergy,
      medicine_allergy,
      physical_activity: physycalActivity,
      smoking,
      smoking_amount, // string "20 por dia"
      smoking_period, // string "6 anos (Periodo fumando)"
      smoking_period_without, // string | null  2 anos (Periodo sem fumar)
      alcoholic,
      alcoholic_type: null, // string "destilada" / "Destilada, Alguma coisa"
      alcoholic_period: null, // string "16 anos"
      drugs,
      drugs_list: drugsList,
      user_id,
    };

    if (smoking == 'Ex-fumante') {
      data.smoking_period = `${exSmoker.field_1.time} ${
        exSmoker.field_1.period ? exSmoker.field_1.period.value : ''
      } (Período fumando)`;
      data.smoking_period_without = `${exSmoker.field_2.time} ${
        exSmoker.field_2.period ? exSmoker.field_2.period.value : ''
      } (Período sem fumar)`;
    } else if (smoking == 'Fumante') {
      data.smoking_period = `${smoker.field_1.time} ${
        smoker.field_1.period ? smoker.field_1.period.value : ''
      }`;
      data.smoking_period_without = `${smoker.field_2.amount} por dia`;
    }

    if (drugs == 'Não') {
      data.drugs_list = [];
    }

    if (alcoholic != 'Abstinente') {
      data.alcoholic_type = `${
        drinkstate.checkedA
          ? drinkstate.checkedB
            ? 'Fermentada, '
            : 'Fermentada'
          : ''
      }${drinkstate.checkedB ? 'Destilada' : ''}`;
      data.alcoholic_period = `${alcoholicPeriod.field_1.time} ${
        alcoholicPeriod.field_1.period
          ? alcoholicPeriod.field_1.period.value
          : ''
      }`;
    }

    return data;
  }

  function attachPatientFile(healthAttendanceId) {

    if (patientFile && Array.isArray(patientFile) && healthAttendanceId) {
      var _files = patientFile.flatMap((x) => x);

      _files.forEach((file) => {
        uploadHealthAttendanceFile(
          localStorage.getItem('token'),
          file,
          healthAttendanceId,
        ).catch(console.error);
      });
    }
  }

  function sendDataAnamneseForm(data) {
    setLoading(true);

    createAnamnesePreForm(localStorage.getItem('token'), data)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        handleError();
        setLoading(false);
      });
  }

  const renderCTA = (item) => {
    if (item.status != 'disponivel') return null;

    if (props.isAgendamento) {
      if (isPast(new Date(item.data_time))) {
        // Data passada
        return null;
      }

      return (
        <Grid item style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex' }}>
            <Button
              className={classes.btnReagendar}
              startIcon={
                <BorderColorOutlinedIcon
                  fontSize="small"
                  className={classes.startIconCustom}
                />
              }
              size="sm"
              onClick={() => handleAgendarCreate(item)}
            >
              <span>Agendar</span>
            </Button>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex' }}>
            <Button
              className={classes.btnReagendar}
              startIcon={
                <BorderColorOutlinedIcon
                  fontSize="small"
                  className={classes.startIconCustom}
                />
              }
              size="sm"
              onClick={() => handleAgendarCreate(item)}
            >
              <span>Reagendar</span>
            </Button>
          </div>
        </Grid>
      );
    }
  };

  return (
    <div className={classes.scheduleItem}>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: 'white' }} />
      </Backdrop>
      <Grid container>
        <Grid item md={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" className={classes.titleSection}>
            {'Horários'}
          </Typography>
        </Grid>
        <Grid item md={12}>
          {horariosAgendadosFiltro && horariosAgendadosFiltro.length
            ? horariosAgendadosFiltro.map((item, index) => (
                <Card classes={{ root: classes.card }}>
                  <CardContent classes={{ root: classes.cardBody }}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <div
                          style={{
                            paddingRight: 36,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: '#006FB5',
                            }}
                          >
                            {renderDate(item.data_time)}
                          </Typography>
                          <div
                            style={{
                              height: 12,
                              width: 1,
                              backgroundColor: '#c7c7c7',
                              marginBottom: 4,
                            }}
                          ></div>
                          <Typography
                            style={{ fontSize: 14, fontWeight: 300 }}
                            className={classes.colorPrimary}
                          >
                            {renderHour(item.data_time)}
                          </Typography>
                        </div>
                      </Grid>
                      <div className={classes.bckContent}></div>

                      <Grid item>
                        <Typography
                          style={{
                            color: '#272727',
                            fontWeight: 300,
                            fontSize: 16,
                            paddingLeft: 32,
                          }}
                        >
                          <div className={classes.statusIcon}>
                            <div
                              className={
                                item.status == 'disponivel'
                                  ? classes.statusHealthAttendanceOn
                                  : classes.statusHealthAttendanceOff
                              }
                            ></div>
                            <p
                              style={{
                                margin: '4px 0 4px 0',
                                fontSize: 14,
                              }}
                            >
                              {item.status[0].toUpperCase() +
                                item.status.substring(1)}
                            </p>
                          </div>
                        </Typography>
                      </Grid>

                      {renderCTA(item)}
                    </Grid>
                  </CardContent>
                </Card>
              ))
            : 'Nenhum horário disponível'}
        </Grid>
      </Grid>

      <ModalReagendamento
        open={open}
        isAgendamento={props.isAgendamento}
        horarioAgendado={selectedTime}
        onConfirm={() =>
          props.isAgendamento
            ? confirmarAgendamento(selectedTime)
            : confirmarReagendamento(selectedTime)
        }
        onClose={() => handleClose()}
      />
    </div>
  );
}
