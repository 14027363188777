import axios from 'axios';
import env from 'react-dotenv';

export async function listVacinas(token, healthAttendanceId) {
  const res = await axios.get(
    `${env.API_URL}/above/${healthAttendanceId}/vaccinationList?token=${token}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res;
}

export async function vacinaById(token, vaccinationId) {
  const res = await axios.get(
    `${env.API_URL}/above/vaccination/${vaccinationId}?token=${token}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res;
}

export async function includeVacinas(token, healthAttendanceId, data) {
  const res = axios.post(
    `${env.API_URL}/above/${healthAttendanceId}/vaccinationInclude?token=${token}`,
    data,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res;
}

export default {
  listVacinas,
  includeVacinas,
  vacinaById,
};
