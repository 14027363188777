import { useEffect, useState } from 'react';
import DInput from '../../../../shared/DInput';
import { useContionals } from './../../../../../context/ConditionalsContext';
import Construtor from '../../../../../api/construtor';
import { toast } from 'react-toastify';

function FormConditionalsCondition(props) {
  const { conditionals } = useContionals();
  const [name, setName] = useState(undefined);

  useEffect(function () {
    if (conditionals !== undefined) setName(conditionals.name);
  }, []);

  return (
    <>
      {conditionals !== undefined && (
        <form>
          <DInput
            type="text"
            label="Nome da condicional"
            name="nome_condicional"
            onBlur={(e) => {
              toast.warn('Atualizando, aguarde...');

              Construtor.updateConditional(
                localStorage.getItem('token'),
                conditionals.id,
                { name },
              ).then((res) => {
                if (res.status) toast.success(res.message);
                else toast.error(res.message);
              });
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            fullWidth
          />
        </form>
      )}
    </>
  );
}

export default FormConditionalsCondition;
