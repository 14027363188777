import { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IconButton, withStyles, useTheme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { createTheme } from '@material-ui/core';
import { Card, CardContent, Paper } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { weekTheme, monthTheme, yearTheme } from './theme';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Chip from '@material-ui/core/Chip';
import AgendaSemana from './components/AgendaSemana';
import { months, weeks } from './../../../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 390,
    boxShadow: 'none',
  },
  toolbar: {
    marginBottom: 32,
  },
  dayToolbarContent: {
    position: 'relative',
  },
  dayToolbar: {
    position: 'absolute',
    top: 7,
    width: 33,
    textAlign: 'center',
  },
  paperMonth: {
    height: 22,
    background: 'transparent',
    padding: '2px 0 0 20px',
    borderRadius: 8,
    color: theme.palette.neutral.black,
    boxShadow: 'none',
  },
  paperMonthSelected: {
    height: 22,
    background: theme.palette.primary.light,
    padding: '2px 0 0 20px',
    borderRadius: 8,
    boxShadow: 'none',
  },
  paperSwitchs: {
    height: 33,
    background: 'rgba(74, 74, 233, 0.08)',
    borderRadius: 100,
    borderTop: 32,
    boxShadow: 'none',
  },
  switchButton: {
    width: '100%',
    textTransform: 'capitalize',
    borderRadius: 100,
  },
  switchButtonActive: {
    width: '100%',
    textTransform: 'capitalize',
    borderRadius: 100,
    background: theme.palette.primary.main,
    color: theme.palette.neutral.white,
  },
  dayNotSelected: {
    '& .MuiPickersDay-day': {
      background: 'transparent !important',
    },
    '& .MuiIconButton-label': {
      color: theme.palette.primary.light,
    },
  },
  dayNotWeekSelected: {
    '& .MuiPickersDay-day': {
      background: 'transparent !important',
    },
    '& .MuiIconButton-label': {
      color: theme.palette.neutral.black,
    },
  },
  daySelected: {
    '& .MuiPickersDay-daySelected': {
      borderRadius: 100,
      background: `${theme.palette.primary.light} !important`,
    },
    '& .MuiPickersDay-hidden': {
      background: 'transparent !important',
    },
    '& .MuiIconButton-label': {
      color: `${theme.palette.neutral.black} !important`,
    },
  },
}));

function Calendar(props) {
  const theme = useTheme();
  const [openTo, setOpenTo] = useState('week');
  const [date, setDate] = useState(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    let day_center = {
      isToday: true,
      day: weeks[date.getDay()].abrev,
      dayNumber: date.getDate(),
      scheduleCommitment: [],
    };

    let date_prev = [];
    let date_next = [];
    for (let cont = 0; cont < 3; cont++) {
      let date_object_prev = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - (cont + 1),
      );
      let new_date_prev = {
        isToday: false,
        day: weeks[date_object_prev.getDay()].abrev,
        dayNumber: date_object_prev.getDate(),
        date: date_object_prev.getTime(),
        scheduleCommitment: [],
      };
      date_prev.push(new_date_prev);

      let date_object_next = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + (cont + 1),
      );

      let new_date_next = {
        isToday: false,
        day: weeks[date_object_next.getDay()].abrev,
        dayNumber: date_object_next.getDate(),
        date: date_object_next.getTime(),
        scheduleCommitment: [],
      };
      date_next.push(new_date_next);
    }

    let new_days_of_week = [...date_prev, { ...day_center }, ...date_next];

    let new_days_of_week_ordened = new_days_of_week.sort((a, b) => {
      return a.date - b.date;
    });

    setDaysOfWeek(new_days_of_week_ordened);
  }, [date]);

  const nextDate = () => {
    let new_date = new Date(date.getFullYear(), date.getUTCMonth() + 1, 1);
    setDate(new_date);
    props.onChange(new_date);
  };

  const prevDate = () => {
    let new_date = new Date(date.getFullYear(), date.getUTCMonth() - 1, 1);
    setDate(new_date);
    props.onChange(new_date);
  };

  const nextDay = () => {
    let new_date = new Date(
      date.getFullYear(),
      date.getUTCMonth(),
      date.getDate() + 1,
    );
    setDate(new_date);
    props.onChange(new_date);
  };

  const prevDay = () => {
    let new_date = new Date(
      date.getFullYear(),
      date.getUTCMonth(),
      date.getDate() - 1,
    );
    setDate(new_date);
    props.onChange(new_date);
  };

  const CustomToolBar = (props) => {
    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={classes.toolbar}
      >
        <Grid item md={12}>
          <Grid container alignItems="center">
            <Grid item md={2}>
              <div className={classes.dayToolbarContent}>
                <CalendarTodayIcon
                  style={{ color: theme.palette.primary.light, fontSize: 33 }}
                />
                <Typography
                  classes={{ root: classes.dayToolbar }}
                  variant="body1"
                  className="text-bold text-lt text-primary"
                >
                  {date.getDate()}
                </Typography>
              </div>
            </Grid>
            <Grid item md={10}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    className="text-bold text-ht text-primary"
                  >{`${months[date.getUTCMonth()].value} ${date.getFullYear()}`}</Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <IconButton
                        disabled={openTo !== 'week'}
                        aria-label="Mês anterior"
                        onClick={(e) => prevDate()}
                      >
                        <ArrowBackIosIcon
                          style={{
                            color: theme.palette.primary.light,
                            fontSize: 24,
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={openTo !== 'week'}
                        aria-label="Mês posterior"
                        onClick={(e) => nextDate()}
                      >
                        <ArrowForwardIosIcon
                          style={{
                            color: theme.palette.primary.light,
                            fontSize: 24,
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Paper className={classes.paperSwitchs}>
            <Grid container>
              <Grid item md={4}>
                <Button
                  onClick={(e) => setOpenTo('today')}
                  classes={{
                    root:
                      openTo === 'today'
                        ? classes.switchButtonActive
                        : classes.switchButton,
                  }}
                >
                  <Typography variant="body1" className="text-regular text-st">
                    Hoje
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button
                  onClick={(e) => setOpenTo('week')}
                  classes={{
                    root:
                      openTo === 'week'
                        ? classes.switchButtonActive
                        : classes.switchButton,
                  }}
                >
                  <Typography variant="body1" className="text-regular text-st">
                    Semana
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button
                  onClick={(e) => setOpenTo('month')}
                  classes={{
                    root:
                      openTo === 'month'
                        ? classes.switchButtonActive
                        : classes.switchButton,
                  }}
                >
                  <Typography variant="body1" className="text-regular text-st">
                    Mês
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const CustomToolbarMonth = (props) => {
    return (
      <Paper
        className={
          props.selected ? classes.paperMonthSelected : classes.paperMonth
        }
      >
        <Typography
          variant="body1"
          className={`text-regular text-st ${props.selected ? 'text-white' : ''}`}
        >{`${months[props.month].value}`}</Typography>
      </Paper>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {openTo === 'today' ? (
          <>
            <CustomToolBar />
            <AgendaSemana
              totais={props.totais}
              selectedWeekDays={daysOfWeek}
              onUpDate={prevDay}
              onDownDate={nextDay}
            />
          </>
        ) : (
          <>
            {openTo === 'week' ? (
              <>
                <CustomToolBar />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <ThemeProvider theme={weekTheme}>
                    <DatePicker
                      autoOk
                      allowKeyboardControl={false}
                      disableToolbar
                      variant="static"
                      defaultValue={date}
                      value={date}
                      onChange={(e) => {
                        setDate(e);
                        props.onChange(e);
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </>
            ) : (
              <>
                <CustomToolBar />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <ThemeProvider theme={yearTheme}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                      let minDate = new Date(date.getFullYear(), month - 1, 1);
                      let maxDate = new Date(date.getFullYear(), month, 0);

                      return (
                        <DatePicker
                          autoOk
                          allowKeyboardControl={false}
                          ToolbarComponent={() => (
                            <CustomToolbarMonth
                              month={minDate.getMonth()}
                              selected={minDate.getMonth() === date.getMonth()}
                            />
                          )}
                          renderDay={(
                            day,
                            selectedDate,
                            Date,
                            dayComponent,
                          ) => {
                            return (
                              <div
                                className={
                                  day.getDate() === date.getDate() &&
                                  minDate.getMonth() === date.getMonth() &&
                                  date.getTime() <= maxDate.getTime()
                                    ? classes.daySelected
                                    : day.getDay() === 0
                                      ? classes.dayNotWeekSelected
                                      : classes.dayNotSelected
                                }
                              >
                                {dayComponent}
                              </div>
                            );
                          }}
                          variant="static"
                          minDate={minDate}
                          maxDate={maxDate}
                          initialFocusedDate={maxDate}
                          value={
                            date.getMonth() === minDate.getMonth() ? date : null
                          }
                          onChange={(e) => {
                            setDate(e);
                            props.onChange(e);
                          }}
                        />
                      );
                    })}
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}

Calendar.defaultProps = {
  totais: [0, 0, 0, 0, 0, 0, 0],
  onChange: function (new_date) {
    console.log(new_date);
  },
};

export default Calendar;
