import { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { recoverpassword } from './../../../../api/recoverpassword';
import useError from './../../../../hooks/useError';
import { mascaraNumero } from '../../../../helpers/formatacao';
import { required } from '../../../../helpers/validacoes';
import { DefaultButton } from './../../../shared/ButtonDefault/index.jsx';
import { RadioDefault } from './../../../shared/RadioDefault/index.jsx';
import DInput from './../../../shared/DInput';

function FormRecoverPassword(props) {
  const [typeAction, setTypeAction] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.removeItem('token_recoverpassword');
    localStorage.removeItem('email_recoverpassword');
    localStorage.removeItem('phone_recoverpassword');
  }, []);

  const options = [
    { value: 'email', label: 'E-mail' },
    { value: 'sms', label: 'SMS' },
  ];

  const [error, validarFormulario, setErrors] = useError({
    typeAction: required,
    phone: required,
    email: required,
  });

  const handleSolicitacao = () => {
    setLoading(true);

    recoverpassword({ typeAction, phone, email }).then((res) => {
      if (res.status) {
        toast.success('Solicitação enviada com sucesso.');

        localStorage.setItem('token_recoverpassword', res.token);

        if (typeAction === 'email')
          localStorage.setItem(
            'email_recoverpassword',
            `${email.substring(0, 1)}*****${email.substring(email.indexOf('@'))}`,
          );
        else
          localStorage.setItem(
            'phone_recoverpassword',
            `${phone.substring(0, 4)} *****${phone.substring(phone.indexOf('-'))}`,
          );

        setLoading(false);

        props.onCode();
      } else {
        toast.error(res.message);

        setLoading(false);
      }
    });
  };

  return (
    <form
      onSubmit={(form) => {
        form.preventDefault();

        var dados = { typeAction, phone, email };

        if (typeAction === 'sms') dados.email = 'N/A';
        else dados.phone = 'N/A';

        if (validarFormulario(dados)) handleSolicitacao();
        else toast.warn('Preencha todos os campos obrigatórios.');
      }}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="body1" className="text-regular text-mt">
                Enviaremos um código para a recuperação da senha
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className="text-regular text-mt">
                Deseja receber por:
              </Typography>
            </Grid>
            <Grid item>
              <RadioDefault
                row
                ariaLabel="type_action"
                name="type_action"
                disabled={loading}
                error={error.typeAction}
                value={typeAction}
                onChange={(e) => {
                  delete error.typeAction;

                  setTypeAction(e.target.value);
                }}
                options={options}
              />
            </Grid>
            {typeAction !== null && (
              <Grid item style={{ marginBottom: 49 }}>
                {typeAction === 'sms' && (
                  <DInput
                    disabled={loading}
                    labelColor="#4F4F4F"
                    label="Informe seu celular"
                    placeholder="celular"
                    type="text"
                    error={error.phone ? true : false}
                    helperText={error.phone ? error.phone : ''}
                    fullWidth
                    onChange={(e) => {
                      delete error.phone;

                      setPhone(mascaraNumero(e.target.value));
                    }}
                    value={phone}
                  />
                )}

                {typeAction === 'email' && (
                  <DInput
                    disabled={loading}
                    labelColor="#4F4F4F"
                    label="Informe seu e-mail"
                    placeholder="E-mail"
                    type="text"
                    inputProps={{ maxLength: 254 }}
                    error={error.email ? true : false}
                    helperText={error.email ? error.email : ''}
                    fullWidth
                    onChange={(e) => {
                      delete error.email;

                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                )}
              </Grid>
            )}
            <Grid item>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <DefaultButton
                    disabled={loading}
                    onClick={(e) => props.onBack()}
                    type="button"
                    variant="outlined"
                    color="primary"
                  >
                    Voltar
                  </DefaultButton>
                </Grid>
                <Grid item>
                  <DefaultButton
                    disabled={loading}
                    type="submit"
                    variant="text"
                    color="primary"
                  >
                    {loading ? <CircularProgress size={15} /> : 'Avançar'}
                  </DefaultButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

FormRecoverPassword.defaultProps = {
  onCode: function () {
    console.log('onCode');
  },
  onBack: function () {
    console.log('onBack');
  },
};

export default FormRecoverPassword;
