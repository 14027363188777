import {
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { getEvents } from '../shared.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 53,
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    fontSize: 14,
    padding: '12px 16px 12px 16px',
  },
  rootDisabled: {
    borderColor: '#F5F6F6',
    background: '#F5F6F6',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
}));

function TInputBase(props) {
  const spread = { ...props };

  const classes = useStyles();

  return (
    <>
      <InputLabel
        error={props.error}
        classes={{
          root: classes.label,
          error: classes.errorLabel,
        }}
      >
        {props.label} {props['required'] ? '*' : null}
      </InputLabel>
      <InputBase
        error={props.error}
        name={props.name}
        placeholder={props.placeholder ?? props.label}
        value={props.value}
        defaultValue={props.defaultValue}
        onBlur={props.onBlur}
        onChange={props.onChange}
        disabled={props.disabled}
        autoComplete="off"
        classes={{
          root: classes.root,
          disabled: classes.rootDisabled,
          error: classes.error,
        }}
        {...spread}
      />
      {props.error && (
        <p
          className={`${classes.helperText} ${props.error ? classes.helperTextError : ''}`}
        >
          {props.error}
        </p>
      )}
      {props.helperText && (
        <p
          className={`${classes.helperText} ${props.error ? classes.helperTextError : ''}`}
        >
          {props.helperText}
        </p>
      )}
    </>
  );
}

TInputBase.defaultProps = {
  label: 'Input Base',
  name: 'input_base',
  placeholder: '',
  error: false,
  helperText: '',
  onBlur: (e) => {
    console.log('onBlur');
  },
};

export default TInputBase;
