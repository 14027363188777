import TextComponent from './TextComponent';
import NumberComponent from './NumberComponent';
import ListComponent from './ListComponent';
import VerdadeiroFalsoComponent from './VerdadeiroFalsoComponent';
import OptionComponent from './OptionComponent';

function FieldType(props) {
  return (
    <>
      {props.campo.type === 'texto' && (
        <TextComponent
          value={props.value}
          error={props.error}
          onChange={(value) => props.onChange([value])}
          showName={props.campo.showName}
          name={props.campo.name}
        />
      )}

      {props.campo.type === 'numero' && (
        <NumberComponent
          value={props.value}
          error={props.error}
          onChange={(value) => props.onChange([value])}
          showName={props.campo.showName}
          showUnit={props.campo.showUnit}
          name={props.campo.name}
          unit={props.campo.unit}
        />
      )}

      {props.campo.type === 'lista' && (
        <ListComponent
          value={props.value}
          error={props.error}
          onChange={(value) => props.onChange(value)}
          showName={props.campo.showName}
          name={props.campo.name}
          multiple={props.campo.multiple}
          options={props.campo.options}
        />
      )}

      {props.campo.type === 'verdadeiro_falso' && (
        <VerdadeiroFalsoComponent
          value={props.value}
          onChange={(value) => props.onChange(value)}
          name={props.campo.name}
          showName={props.campo.showName}
        />
      )}

      {props.campo.type === 'opcoes' && (
        <OptionComponent
          value={props.value}
          error={props.error}
          onChange={(value) => props.onChange(value)}
          showName={props.campo.showName}
          name={props.campo.name}
          multiple={props.campo.multiple}
          options={props.campo.options}
        />
      )}
    </>
  );
}

FieldType.defaultProps = {
  value: null,
  error: false,
  campo: null,
  onChange: function (value) {
    console.log(value);
  },
};

export default FieldType;
