import DiaSemana from './DiaSemana';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import TSelectNativeMultiple from 'component/shared/TSelectNativeMultiple/TSelectNativeMultiple';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import { Grid, Card, CardContent } from '@material-ui/core';

function Cadastro() {
  const {
    segunda,
    setSegunda,
    terca,
    setTerca,
    quarta,
    setQuarta,
    quinta,
    setQuinta,
    sexta,
    setSexta,
    sabado,
    setSabado,
    domingo,
    setDomingo,
    especialidade,
    setEspecialidade,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
  } = useConfiguracaoAgenda();

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <TSelectNative
              label="Especialidade"
              name="especialidade"
              // error
              // helperText="Mensagem de apoio"
              required
              options={[{ value: 1, label: 'Valor 1' }]}
              value={especialidade}
              onChange={(e) => setEspecialidade(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TSelectNativeMultiple
              label="Formulários"
              name="formularios"
              placeholder="Formulários"
              // error
              // helperText="Mensagem de apoio"
              required
              options={[
                { value: 1, label: 'Anamnese' },
                { value: 2, label: 'Covid 19' },
              ]}
              value={formularios}
              onChange={(e, newValue) => setFormularios(newValue)}
            />
          </Grid>
          <Grid item>
            <TInputTimeBase
              // error
              // helperText="Mensagem de apoio"
              required
              label="Duração do atendimento"
              name="duracao_atendimento"
              placeholder="Duração do atendimento"
              value={duracaoAtendimento}
              onChange={(e) => setDuracaoAtendimento(e.target.value)}
            />
          </Grid>
          <Grid item>
            <DiaSemana
              titulo="Segunda-feira"
              primeiraConsulta
              retorno
              encaixe
              value={segunda}
              setValue={setSegunda}
            />
            <DiaSemana
              titulo="Terça-feira"
              primeiraConsulta
              retorno
              encaixe
              value={terca}
              setValue={setTerca}
            />
            <DiaSemana
              titulo="Quarta-feira"
              primeiraConsulta
              retorno
              encaixe
              value={quarta}
              setValue={setQuarta}
            />
            <DiaSemana
              titulo="Quinta-feira"
              primeiraConsulta
              retorno
              encaixe
              value={quinta}
              setValue={setQuinta}
            />
            <DiaSemana
              titulo="Sexta-feira"
              primeiraConsulta
              retorno
              encaixe
              value={sexta}
              setValue={setSexta}
            />
            <DiaSemana
              titulo="Sabado"
              primeiraConsulta
              retorno
              encaixe
              value={sabado}
              setValue={setSabado}
            />
            <DiaSemana
              titulo="Domingo"
              primeiraConsulta
              retorno
              encaixe
              value={domingo}
              setValue={setDomingo}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Cadastro;
