import {
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import RedefinirModal from '.';
import SimpleModal from '../../../shared/Modal';
import { useEffect, useState } from 'react';
import { Step1, Step2, Step3 } from './modalSteps';
import './styles.scss';
import DButton from '../../../shared/DButton';
import { VpnKey } from '@material-ui/icons';
import {
  requestUpdatePassword,
  updatePassword,
  verifyResetPasswordCode,
} from '../../../../api/user';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  modalPassword: {
    position: 'absolute',
    width: '100%',
    maxWidth: 450,
    margin: '0px 30px',
    backgroundColor: 'white',
    border: '1px solid #949494',
    borderRadius: 15,
  },
});

export function RedefinirSenha(props) {
  const classes = useStyles();

  const [formValid, setFormValid] = useState(false);
  const [reenviar, setReenviar] = useState(10);
  const [sendForm, setSendForm] = useState(false);

  const [step, setStep] = useState(0);

  // Step 2
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invalidField, setInvalidField] = useState(false);
  const [loadingSendForm, setLoadingSendForm] = useState(false);
  const [codigo, setCodigo] = useState([]);
  const [wrongCode, setWrongCode] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const [validateError, setValidateError] = useState({
    minimumCharacters: false,
    oneUppercaseAndDowncase: false,
    oneNumber: false,
    oneSpecialCharacter: false,
    done: false,
  });

  const startRequest = () => {
    requestUpdatePassword(localStorage.getItem('token'))
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.error(err);
        setLoadingSendForm(false);
      });
  };

  const verifyCode = () => {
    let temp_code = Object.values(codigo).toString().split(',').join('');

    setLoadingCode(true);
    verifyResetPasswordCode(localStorage.getItem('token'), temp_code)
      .then((data) => {
        if (data.hasOwnProperty('status')) {
          if (data.status) {
            setFormValid(true);
          } else {
            setFormValid(false);
            setWrongCode(true);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    let temp_code = Object.values(codigo);

    if (temp_code.length < 5) return;

    verifyCode();
  }, [codigo]);

  useEffect(() => {
    if (props.open == true) startRequest();
  }, [props.open]);

  const handleSendForm = () => {
    if (validateError.done) {
      let temp_code = Object.values(codigo);
      temp_code = temp_code.toString().replaceAll(',', '');
      // Send change password request;
      setLoadingSendForm(true);

      updatePassword(localStorage.getItem('token'), temp_code, password).then(
        (data) => {
          if (data.hasOwnProperty('status')) {
            if (data.status) {
              setLoadingSendForm(false);
              setStep(2);
              return;
            }

            console.error('Unknown response from backend', data);

            setStep(0);

            setLoadingSendForm(false);

            props.setModal();
          }
        },
      );
    } else {
      setInvalidField(true);
      toast.error('Verifique as senhas utilizadas.');
    }
  };

  useEffect(() => validateRedefinir(), [password, confirmPassword]);

  const validateRedefinir = () => {
    /**
     * Min 1 uppercase
     * Min 1 lowercase
     * Min 1 special character
     * Min 1 number
     * Min 8 characters
     */

    // let full = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/

    let least_one_number = /[0-9]/;

    let progress = {
      minimumCharacters: false,
      oneUppercaseAndDowncase: false,
      oneNumber: false,
      oneSpecialCharacter: false,
    };

    if (password != confirmPassword) {
      setInvalidField(true);
    } else {
      setInvalidField(false);
    }

    if (password.length >= 8) progress.minimumCharacters = true;

    if (password.match('.*[A-Z].*') && password.match('.*[a-z].*'))
      progress.oneUppercaseAndDowncase = true;

    if (password.match(least_one_number)) progress.oneNumber = true;

    if (password.match('^(?=.*[^0-9a-zA-Z])'))
      progress.oneSpecialCharacter = true;

    setValidateError({ ...progress });

    if (
      progress.minimumCharacters == true &&
      progress.oneNumber == true &&
      progress.oneSpecialCharacter == true &&
      progress.oneUppercaseAndDowncase == true &&
      password == confirmPassword
    ) {
      setValidateError({ ...progress, done: true });
    } else {
      setValidateError({ ...progress, done: false });
    }
  };

  function ConfirmClose(props) {
    return (
      <SimpleModal
        open={props.open}
        onClose={(e) => props.onClose(true)}
        title="Redefinir senha"
        notButtonClose
      >
        <Grid container justifyContent="center">
          <Grid item md={11} style={{ textAlign: 'center', paddingTop: 15 }}>
            <Typography style={{ marginBottom: '2em' }}>
              Todas as informações serão perdidas, deseja continuar?
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item md={6} style={{ textAlign: 'center', paddingBottom: 15 }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={(e) => props.onClose(true)}
            >
              Não
            </DButton>
          </Grid>
          <Grid item md={6} style={{ textAlign: 'center', paddingBottom: 15 }}>
            <DButton
              variant="primary"
              size="sm"
              onClick={(e) => props.onConfirmClose(true)}
            >
              Sim
            </DButton>
          </Grid>
        </Grid>
      </SimpleModal>
    );
  }

  return (
    <>
      <ConfirmClose
        open={confirmClose}
        onClose={(e) => setConfirmClose(false)}
        onConfirmClose={(e) => {
          props.setModal();
          setStep(0);
          setLoadingSendForm(false);
        }}
      />
      <RedefinirModal
        formValid={formValid}
        step={step}
        setStep={(e) => {
          setStep(e);
          e === 1 && setFormValid(false);
        }}
        validate={(e) => validateRedefinir()}
        sendRedefinir={(e) => handleSendForm()}
        loading={loadingSendForm}
        open={props.open || false}
        onClose={(e) => {
          if (step === 2) {
            props.setModal();
            setStep(0);
            setLoadingSendForm(false);
          } else {
            setConfirmClose(true);
          }
        }}
        style={{ width: 200 }}
        title="Redefinir senha"
        customStyle={classes.modalPassword}
      >
        <Grid id="redefinir-senha">
          {step === 0 ? (
            <Step1
              reenviar={props.reenviar}
              started={props.open}
              wrongCode={wrongCode}
              codigo={codigo}
              loading={loadingCode}
              setCodigo={(e) => setCodigo(e)}
            />
          ) : step === 1 ? (
            <Step2
              password={password}
              setPassword={(e) => {
                setPassword(e);
              }}
              confirmPassword={confirmPassword}
              setConfirmPassword={(e) => setConfirmPassword(e)}
              error={invalidField}
              validationProgress={validateError}
            />
          ) : (
            <Step3 />
          )}
        </Grid>
      </RedefinirModal>
    </>
  );
}

export function ConfirmationModal(props) {
  return (
    <SimpleModal
      open={props.open}
      onClose={(e) => props.setModal()}
      title="Redefinir senha"
    >
      <Grid container justifyContent="center">
        <Grid item style={{ margin: '20px' }}>
          <Typography style={{ marginBottom: '2em' }}>
            Deseja mesmo redefinir sua senha?
          </Typography>
          <DButton
            variant="secondary"
            size="sm"
            onClick={(e) => props.openRedefinir()}
          >
            Sim
          </DButton>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}
