import axios from 'axios';
import env from 'react-dotenv';

export const api = axios.create({
  baseURL: 'http://localhost:3004/',
});

const getParams = (params) => {
  if (typeof params === 'object') {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
};

export function healthAttendanceList(token, search) {
  return axios
    .get(`${env.API_URL}/healthAttendance?search=${search}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getAllHealthAttendance(token) {
  return axios
    .get(`${env.API_URL}/healthAttendance`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getAllHealthAttendanceRequest(token, requested_at, search) {
  return axios
    .get(
      `${env.API_URL}/healthAttendance/userrequesting?requested_at=${requested_at}&search=${search}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function getHealthAttendanceByPage(token, page) {
  return axios
    .get(`${env.API_URL}/healthAttendance?page=${page}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getHealthAttendanceByPageFilter(token, page, filter, params) {
  return axios
    .post(`${env.API_URL}/healthAttendance/filter?page=${page}`, filter, {
      params: params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getHealthAttendanceById(token, patientId, page) {
  var url = '';

  if (page) {
    url = `${env.API_URL}/healthAttendance?patient_id=${patientId}&page=${page}`;
  } else {
    url = `${env.API_URL}/healthAttendance?patient_id=${patientId}`;
  }

  return axios
    .get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getByHealthAttendanceId(token, healthAttendanceId) {
  return axios
    .get(`${env.API_URL}/healthAttendance/${healthAttendanceId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function assumirAtendimento(token, healthAttendanceId) {
  return axios
    .patch(
      `${env.API_URL}/healthAttendance/${healthAttendanceId}/attendance`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function abdicarAtendimento(token, healthAttendanceId, body = null) {
  if (body)
    return axios
      .patch(
        `${env.API_URL}/healthAttendance/${healthAttendanceId}/abdicate-attendance`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      )
      .then((res) => res.data);
  else
    return axios
      .patch(
        `${env.API_URL}/healthAttendance/${healthAttendanceId}/abdicate-attendance`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      )
      .then((res) => res.data);
}

export function getHealthAttendance(token, paramsFilter) {
  var uri = new URL(`${env.API_URL}/healthAttendance`);

  if (paramsFilter && paramsFilter.id) {
    return axios
      .get(`${env.API_URL}/healthAttendance/${paramsFilter.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      })
      .then((res) => res.data);
  }

  var params = getParams(paramsFilter);

  if (params && Array.isArray(params)) {
    params.forEach((param) => {
      uri.searchParams.append(param.key, param.value);
    });
  }

  return axios
    .get(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function storeInterconsultationByHealthAttendance(
  token,
  healthAttendanceId,
  anamneseFormId,
  userInviteId,
  medicalUnitExecuting,
) {
  return axios
    .post(
      `${env.API_URL}/healthAttendance/health-attendance-interconsult`,
      {
        health_attendance_id: healthAttendanceId,
        anamnese_form_id: anamneseFormId,
        user_invite_id: userInviteId,
        medical_unit_id: medicalUnitExecuting,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function getInterconsultationInvite(token) {
  return axios
    .get(
      `${env.API_URL}/healthAttendance/health-attendance-interconsult/invite`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function assumirInterconsultation(token, healthAttendanceInviteId) {
  return axios
    .put(
      `${env.API_URL}/healthAttendance/health-attendance-interconsult/assumir/${healthAttendanceInviteId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function abdicarInterconsultation(token, healthAttendanceInviteId) {
  return axios
    .put(
      `${env.API_URL}/healthAttendance/health-attendance-interconsult/abdicar/${healthAttendanceInviteId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}
