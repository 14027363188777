// React import
import React from 'react';
import { Component } from 'react';

// Material styles and icons import
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DButton from '../shared/DButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

// Shared components import
import DInput from '../shared/DInput';

// Sidebar(right) import
import Rightbar from './component/Rightbar/RightbarSpecialty';

// API routes import
import Linecares from '../../api/lineCare';
import {
  getAllMedicalSpecialties,
  updateStatusMedicalSpecialty,
} from '../../api/medicalSpecialty';
import { toast } from 'react-toastify';

const stylesv = (theme) => ({
  primary: {
    color: `${theme.palette.primary.light} !important`,
  },
  deleteButton: {
    color: `${theme.palette.support.error} !important`,
    textTransform: 'none',
    fontWeight: 400,
  },
  activeButton: {
    color: `${theme.palette.primary.light} !important`,
    textTransform: 'none',
    fontWeight: 400,
  },
  inactiveListButton: {
    color: `${theme.palette.support.error} !important`,
    borderColor: `${theme.palette.support.error} !important`,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
  },
  backgroundSnow: {
    background: theme.palette.background.snow,
  },
  listItem: {
    padding: '16px 0',
    margin: 0,
    borderTop: `1px solid ${theme.palette.neutral.midlightgray}`,
  },
  firstListItem: {
    padding: '16px 0',
    margin: 0,
  },
});

class Linhas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMedicalUnits: true,
      loadingSpecialties: true,
      Linecares: [],
      specialties: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      error: [],
      showRightbar: false,
    };
  }

  componentDidMount() {
    this.listLinecares();
    this.listSpecialties();
  }

  listLinecares() {
    Linecares.listLineCare(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.lineCare.data.map((row) => {
          var new_state = this.state.Linecares;
          new_state.push({
            id: row.id,
            name: row.name,
            active: row.active == 1 ? true : false,
            isEdit: false,
            isLoading: false,
          });
          this.setState({ ...this.state, Linecares: new_state });
        });
      } else {
        this.setState({ ...this.state, Linecares: [] });
      }
    });
  }

  listSpecialties() {
    getAllMedicalSpecialties(localStorage.getItem('token'))
      .then((data) => {
        this.setState({ ...this.state, specialties: data });
        this.setState({ ...this.state, loadingSpecialties: false });
      })
      .catch((err) => {
        this.setState({ ...this.state, loadingSpecialties: false });
        console.error(err);
      });
  }

  enableOrDisableSpecialties(Specialty) {
    // console.log(Specialty, `${Specialty.active ? "Desativando" : "Ativando"}`);
    if (this.state.loadingSpecialties) return;

    this.setState({ ...this.state, loadingSpecialties: true });

    updateStatusMedicalSpecialty(localStorage.getItem('token'), Specialty.id, {
      active: Specialty.active == 0 ? 1 : 0,
    })
      .then((data) => {
        console.log(data);
        toast.success(
          `${Specialty.active ? 'Especialidade desativada.' : 'Especialidade re-ativada com sucesso.'}`,
        );
        this.listSpecialties();
      })
      .catch((err) => {
        console.error(err);

        this.setState({ ...this.state, loadingSpecialties: false });
      });
  }

  hideRightBar() {
    this.setState({ ...this.state, showRightbar: false });
  }

  render() {
    const { classes } = this.props;
    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
      buttonToolbar: { marginTop: 24, marginBottom: 24 },
      flex: { display: 'flex' },
      dark_background: {
        width: '100%',
        height: 'calc(100vh - 98px)',
        background: 'rgba(39,39,39,0.25)',
        position: 'fixed',
        top: 98,
        zIndex: 2010,
      },
      item_text: {
        fontSize: 14,
        margin: 0,
        padding: 0,
      },
    };

    return (
      <>
        <Container className="tema-light">
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Atendimento
          </Typography>
          <Card className={[classes.primary, classes.backgroundSnow]}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant="h4">Especialidades</Typography>
                </Grid>
                <Grid item md={9} style={styles.flex} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    className={classes.inactiveListButton}
                    size="sm"
                    onClick={() =>
                      this.setState({ ...this.state, showRightbar: true })
                    }
                  >
                    Inativas
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    {this.state.specialties.map((row, index) => (
                      <>
                        {
                          <ListItem
                            className={
                              index == 0
                                ? classes.firstListItem
                                : classes.listItem
                            }
                          >
                            <ListItemText
                              primary={row.description}
                              disableTypography={true}
                              style={styles.item_text}
                            />
                            <ListItemSecondaryAction style={{ right: 0 }}>
                              <IconButton
                                size="sm"
                                className={
                                  row.active
                                    ? classes.activeButton
                                    : classes.deleteButton
                                }
                                onClick={(e) => {
                                  this.enableOrDisableSpecialties(row);
                                }}
                              >
                                {row.active ? (
                                  <CheckCircleOutlineIcon
                                    style={{ fontSize: 20 }}
                                  />
                                ) : (
                                  <NotInterestedIcon style={{ fontSize: 20 }} />
                                )}
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        }
                      </>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>

        {this.state.showRightbar ? (
          <div style={styles.dark_background}></div>
        ) : (
          ''
        )}

        <Rightbar
          show={this.state.showRightbar}
          hide={() => this.hideRightBar()}
          specialties={this.state.specialties}
          // linecares={ this.state.Linecares }
          enableOrDisableSpecialtie={(e) => this.enableOrDisableSpecialties(e)}
          style={styles.rightbar}
        />
      </>
    );
  }
}

export default withStyles(stylesv)(Linhas);
