import React, { useState, useEffect, useContext } from 'react';
import '../index.scss';
import { TextField } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Fidi } from './../../../AtendimentoImediato/modais/index';
import VisualizadorClinico from './../../../Ipe';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { getUserInfoById } from './../../../../api/user';
import { getUnidade } from './../../../../api/unidades';
import Configuration from './../../../../api/configuration';
import { AppContext } from './../../../../context/AppContext';

export default function PacienteNewTab(props) {
  const { healthAttendance, setHealthAttendance, patient, setPatient } =
    useNewHealthAttendance();
  const { userInfo } = useContext(AppContext);
  const [modalFidi, setModalFidi] = useState(false);
  const [modalIpeVisualizadorClinico, setModalIpeVisualizadorClinico] =
    useState(false);
  const [fullName, setFullName] = useState('-');
  const [socialName, setSocialName] = useState('');
  const [pronoun, setPronoun] = useState('');
  const [age, setAge] = useState('-');
  const [sex, setSex] = useState('-');
  const [medicineContinuos, setMedicineContinuos] = useState([]);
  const [pregnancyQuantity, setPregnancyQuantity] = useState(0);
  const [pregnancyBornAlive, setPregnancyBornAlive] = useState(0);
  const [pregnancyLastMestruationDate, setPregnancyLastMestruationDate] =
    useState(null);
  const [pregnancyAbortion, setPregnancyAbortion] = useState(false);
  const [pregnancyQuantityAbortion, setPregnancyQuantityAbortion] = useState(0);
  const [pregnancyLastAbortionDate, setPregnancyLastAbortionDate] =
    useState(null);
  const [cpf, setCpf] = useState(null);
  const [cpfProfissional, setCpfProfissional] = useState(null);
  const [unit, setUnit] = useState(null);
  const [urlVisualizadorClinicoIpe, setUrlVisualizadorClinicoIpe] =
    useState(null);

  const loadUserData = () => {
    if (cpfProfissional === null) {
      getUserInfoById(
        localStorage.getItem('token'),
        localStorage.getItem('uid'),
      )
        .then((data) => {
          if (data.status) {
            console.log('data.document', data.document);

            if (data.document && Array.isArray(data.document)) {
              const cpf = formatDocument(
                data.document.find((x) => x.type === 'CPF'),
              );

              if (cpf && cpf.length > 0) {
                setCpfProfissional(cpf[0].value);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    getUnidade(localStorage.getItem('token'), userInfo.unidade).then((data) => {
      if (data.status) {
        setUnit(data.details.medicalUnit);
      }
    });
  }, [userInfo]);

  useEffect(() => {
    if (cpf !== null && cpfProfissional !== null && unit !== null) {
      Configuration.getCredentialIpe(localStorage.getItem('token'), null).then(
        (data) => {
          const token = data.token;

          if (data.status) {
            setUrlVisualizadorClinicoIpe(
              `https://dev.ipes.tech/portalembedded/#/embedded?resource-id=${cpf}&subject-id=${cpfProfissional}&organization-id=${unit.cnes}&purposeofuse=Atendimento&token=${token}`,
            );
          }
        },
      );
    }
  }, [cpf, cpfProfissional, unit]);

  useEffect(() => {
    console.log('urlVisualizadorClinicoIpe', urlVisualizadorClinicoIpe);
  }, [urlVisualizadorClinicoIpe]);

  useEffect(() => {
    if (patient) {
      setFullName(patient.full_name);
      setSocialName(patient.social_name || '');
      setPronoun(patient.pronoun?.name || '');
      setAge(patient.age);
      setSex(patient.biological_sex);
      //only numbers
      setCpf(patient.cpf.replace(/\D/g, ''));

      if (
        patient.biological_sex === 'Feminino' &&
        patient.patient_pregnancy.length > 0
      ) {
        setPregnancyQuantity(patient.patient_pregnancy[0].quantity);
        setPregnancyBornAlive(patient.patient_pregnancy[0].born_alive);
        setPregnancyLastMestruationDate(
          patient.patient_pregnancy[0].formated_last_mestruation_date,
        );
        setPregnancyAbortion(patient.patient_pregnancy[0].abortion === 1);
        setPregnancyQuantityAbortion(
          patient.patient_pregnancy[0].quantity_abortion,
        );
        setPregnancyLastAbortionDate(
          patient.patient_pregnancy[0].formated_last_abortion_date,
        );
      }

      loadUserData();
    }
  }, [patient]);

  useEffect(() => {
    if (healthAttendance) {
      if (healthAttendance.anamnese_pre_form.length > 0) {
        var pre_medicine = healthAttendance.anamnese_pre_form
          .filter((pre_form) => pre_form.anamnese_pre_medicine.length > 0)
          .map((pre_form) =>
            pre_form.anamnese_pre_medicine.map((pre_medicine) => {
              return pre_medicine.name;
            }),
          );

        setMedicineContinuos(pre_medicine[0] ? pre_medicine[0] : []);
      }
    }
  }, [healthAttendance]);

  const formatDocument = (document) => {
    if (typeof document !== 'undefined') {
      return document.fields.map((x) => {
        return {
          id: x.id,
          value: x.value,
          user_document_id: x.document_type_field_id,
        };
      });
    }
  };

  return (
    <div className="fundo_new_tab">
      <div className="main_flex_new_tab">
        <div className="fonte_new_tab text-primary">Paciente</div>
      </div>
      <div className="mt_35_new_tab separa_inputs_new_tabs">
        <TextField
          className="input_new_solicitante_new_tab"
          id="standard-basic"
          label="Nome Completo"
          disabled
          variant="standard"
          value={fullName}
        />
        <TextField
          className="input_new_solicitante_new_tab"
          id="standard-basic"
          label="Nome Social"
          disabled
          variant="standard"
          value={`${socialName} - ${pronoun}`}
        />
      </div>
      <div className="mt_35_new_tab separa_inputs_new_tabs">
        <TextField
          className="input_new_solicitante_new_tab"
          id="standard-basic"
          label="Idade"
          disabled
          variant="standard"
          value={age > 1 ? `${patient.age} anos` : `${patient.age} ano`}
        />
        <TextField
          className="input_new_solicitante_new_tab"
          id="standard-basic"
          label="Sexo Biológico"
          disabled
          variant="standard"
          value={sex}
        />
      </div>
      <div className="mt_35_new_tab">
        <div className="flex_paciente_divide">
          <div className="fonte_paciente_tab">Uso Contínuo</div>
          <div className="flex_end_paciente_tab">
            <DButton
              className="buttonVisualizadorClinico"
              variant="secondary"
              icon={<OpenInNewIcon fontSize="small" />}
              size="xs"
              onClick={(e) => setModalIpeVisualizadorClinico(true)}
            >
              Visualizador Clínico
            </DButton>
            <DButton
              className="botao_fidi"
              variant="secondary"
              icon={<OpenInNewIcon fontSize="small" />}
              size="xs"
              onClick={(e) => setModalFidi(true)}
            >
              Visualizador FIDI
            </DButton>
          </div>
        </div>
        <div className="main_div_tag_paciente_tab">
          {medicineContinuos.map((medicine) => (
            <div className="tag_paciente_tab">{medicine}</div>
          ))}
        </div>
      </div>
      {patient &&
        patient.biological_sex === 'Feminino' &&
        patient.patient_pregnancy.length > 0 && (
          <>
            <div className="main_flex_new_tab mt_35_new_tab">
              <div className="fonte_new_tab text-primary">Gravidez</div>
            </div>
            <div className="mt_35_new_tab separa_inputs_new_tabs">
              <TextField
                className="input_new_solicitante_new_tab"
                id="standard-basic"
                label="Quantas vezes já engravidou:"
                value={pregnancyQuantity}
                variant="standard"
              />
              <TextField
                className="input_new_solicitante_new_tab"
                id="standard-basic"
                label="Filhos nascidos vivos:"
                value={pregnancyBornAlive}
                variant="standard"
              />
            </div>
            <div className="mt_35_new_tab separa_inputs_new_tabs">
              <TextField
                className="input_new_solicitante_new_tab"
                id="standard-basic"
                label="Data da última menstruação"
                value={pregnancyLastMestruationDate}
                variant="standard"
              />
              <TextField
                className="input_new_solicitante_new_tab"
                id="standard-basic"
                label="Já teve aborto"
                value={pregnancyAbortion ? 'Sim' : 'Não'}
                variant="standard"
              />
            </div>
            {pregnancyAbortion && (
              <div className="mt_35_new_tab separa_inputs_new_tabs">
                <TextField
                  className="input_new_solicitante_new_tab"
                  id="standard-basic"
                  label="Quantas vezes já abortou"
                  value={pregnancyQuantityAbortion}
                  variant="standard"
                />
                <TextField
                  className="input_new_solicitante_new_tab"
                  id="standard-basic"
                  label="Data do último aborto"
                  value={pregnancyLastAbortionDate}
                  variant="standard"
                />
              </div>
            )}
          </>
        )}
      <Fidi open={modalFidi} setModal={(_) => setModalFidi(false)} />

      {/* POC RESENDE  */}
      {urlVisualizadorClinicoIpe !== null && (
        <VisualizadorClinico
          open={modalIpeVisualizadorClinico}
          setModal={(_) => setModalIpeVisualizadorClinico(false)}
          url={urlVisualizadorClinicoIpe}
        />
      )}
    </div>
  );
}
