import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { usePatient } from '../../../../../../../../context/PatientContext';
import DDateInput from '../../../../../../../shared/DDateInput';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

interface IPregnantProps {
  errors: {
    qtdVezesGravidez?: string;
    dtUltimaMenstruacao?: string;
    filhosVivos?: string;
    qtdVezesAborto?: string;
    dtUltimoAborto?: string;
  };
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
}

export const Pregnant = ({
  errors,
  removeError,
  setErrors,
}: IPregnantProps) => {
  const classes = useStyles();
  const { setDadosGravidez, dadosGravidez } = usePatient();
  return (
    <>
      <Grid item md={6} xs={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DInput
              id="qtdVezesGravidez"
              placeholder="Quantas vezes já engravidou"
              label="Quantas vezes já engravidou"
              type="text"
              error={errors.qtdVezesGravidez}
              helperText={errors.qtdVezesGravidez}
              fullWidth={true}
              onChange={(e) => {
                removeError('qtdVezesGravidez');
                setDadosGravidez((prevState) => {
                  return {
                    ...prevState,
                    qtdVezesGravidez: e.target.value.replace(/[^0-9]/g, ''),
                  };
                });
                // }
              }}
              value={dadosGravidez.qtdVezesGravidez}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DDateInput
              id="dtUltimaMenstruacao"
              label="Data da última menstruação"
              placeholder="Data da última menstruação"
              error={errors.dtUltimaMenstruacao}
              helperText={errors.dtUltimaMenstruacao}
              fullWidth
              className="datePicker"
              style={{ paddingBottom: 0 }}
              maxDate={new Date()}
              maxDateMessage="A data da ultima menstruação não pode ser maior que a data atual"
              value={dadosGravidez.dtUltimaMenstruacao}
              onError={(errorMessage) => {
                setErrors({
                  ...errors,
                  dtUltimaMenstruacao: errorMessage,
                });
              }}
              onChange={(arg1, arg2) => {
                removeError('dtUltimaMenstruacao');
                setDadosGravidez((prevState) => {
                  return {
                    ...prevState,
                    dtUltimaMenstruacao: arg1,
                    dtUltimaMenstruacaoValida: arg2.isValid,
                  };
                });

                // setDtUltimaMenstruacao(arg1);
                // setDtUltimaMenstruacaoValida(arg2.isValid);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <DInput
              id="filhosVivos"
              placeholder="Filhos nascidos vivos"
              label="Filhos nascidos vivos"
              type="text"
              error={errors.filhosVivos}
              helperText={errors.filhosVivos}
              fullWidth={true}
              onChange={(e) => {
                removeError('filhosVivos');
                setDadosGravidez((prevState) => {
                  return {
                    ...prevState,
                    filhosVivos: e.target.value.replace(/[^0-9]/g, ''),
                  };
                });
              }}
              value={dadosGravidez.filhosVivos}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6} className={classes.field_padding}>
        <Grid container spacing={3} className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  label="Já teve algum aborto"
                  placeholder="Já teve algum aborto"
                  checked={dadosGravidez.teveAborto}
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                  onClick={(e) => {
                    if (dadosGravidez.teveAborto) {
                      setDadosGravidez((prevState) => {
                        return {
                          ...prevState,
                          qtdVezesAborto: 0,
                        };
                      });
                    }
                    setDadosGravidez((prevState) => {
                      return {
                        ...prevState,
                        teveAborto: !dadosGravidez.teveAborto,
                      };
                    });
                  }}
                />
              }
              label="Já teve algum aborto"
              placeholder="Já teve algum aborto"
            />
          </Grid>
        </Grid>
      </Grid>
      <>
        {dadosGravidez.teveAborto && (
          <>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <Grid container spacing={3} className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="qtdVezesAborto"
                    placeholder="Quantas vezes já abortou"
                    label="Quantas vezes já abortou"
                    type="text"
                    error={errors.qtdVezesAborto}
                    helperText={errors.qtdVezesAborto}
                    fullWidth={true}
                    onChange={(e) => {
                      removeError('qtdVezesAborto');
                      if (e.target.value.length <= 2) {
                        setDadosGravidez((prevState) => {
                          return {
                            ...prevState,
                            qtdVezesAborto: e.target.value.replace(
                              /[^0-9]/g,
                              '',
                            ),
                          };
                        });
                      }
                    }}
                    value={dadosGravidez.qtdVezesAborto}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <Grid container spacing={3} className={classes.alignItemsEnd}>
                <Grid item md={12}>
                  <DDateInput
                    id="dtUltimoAborto"
                    error={errors.dtUltimoAborto}
                    helperText={errors.dtUltimoAborto}
                    fullWidth
                    label="Data do último aborto"
                    placeholder="Data do último aborto"
                    className="datePicker"
                    style={{ paddingBottom: 0 }}
                    maxDate={new Date()}
                    maxDateMessage="A data do ultimo abordo não pode ser maior que a data atual"
                    value={dadosGravidez.dtUltimoAborto}
                    onError={(errorMessage) => {
                      setErrors({
                        ...errors,
                        dtUltimoAborto: errorMessage,
                      });
                    }}
                    onChange={(arg1) => {
                      removeError('dtUltimoAborto');
                      setDadosGravidez((prevState) => {
                        return {
                          ...prevState,
                          dtUltimoAborto: arg1,
                        };
                      });

                      // setDtUltimoAborto(arg1);
                      // setDtUltimoAbortoValida(arg2.isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  );
};
