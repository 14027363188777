import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import ModalDesbloquearAgenda from './Modais/ModalDesbloquearAgenda';

const useStyles = makeStyles((theme) => ({
  scheduleItem: {
    padding: 32,
  },
  card: {
    background: theme.palette.background.snow,
    border: `2px solid ${theme.palette.neutral.midlightgray} !important`,
  },
  cardBody: {
    padding: 15,
    paddingBottom: '15px !important',
  },
  bckContent: {
    borderRadius: 2,
    width: 1,
    backgroundColor: theme.palette.neutral.darkgray,
    opacity: 0.3,
    height: '50px',
  },
  btnReagendar: {
    textTransform: 'none !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important',
    height: 36,
    marginRight: '10px !important',
  },
  btnCancelar: {
    textTransform: 'none !important',
    color: `${theme.palette.support.error} !important`,
    fontWeight: '400 !important',
    height: 36,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'baseline',
  },
  statusHealthAttendanceOn: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.check,
    marginRight: 5,
  },
  statusHealthAttendanceOff: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: theme.palette.support.error,
    marginRight: 5,
  },
}));

export default function ScheduleItemBlock(props) {
  const classes = useStyles();
  const [status, setStatus] = useState(2);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <div className={classes.scheduleItem}>
      <Typography variant="h4" className="text-medium">
        {'Bloqueios'}
      </Typography>
      <Card classes={{ root: classes.card }}>
        <CardContent classes={{ root: classes.cardBody }}>
          <Grid container alignItems="center">
            <Grid item>
              <div
                style={{
                  paddingRight: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: '#006FB5',
                  }}
                >
                  {props.dataScheduleItem.day}
                </Typography>
                <div
                  style={{
                    height: 12,
                    width: 1,
                    backgroundColor: '#c7c7c7',
                    marginBottom: 4,
                  }}
                ></div>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: '#006FB5',
                  }}
                >
                  {props.dataScheduleItem.day}
                </Typography>
              </div>
            </Grid>
            <div className={classes.bckContent}></div>

            <Grid item>
              <Typography
                style={{
                  color: '#272727',
                  fontWeight: 300,
                  fontSize: 16,
                  paddingLeft: 32,
                }}
              >
                <div className={classes.statusIcon}>
                  <div
                    className={
                      status
                        ? status < 2
                          ? classes.statusHealthAttendanceOff
                          : classes.statusHealthAttendanceOff
                        : classes.statusHealthAttendanceOn
                    }
                  ></div>
                  <p style={{ margin: '4px 0 4px 0', fontSize: 14 }}>
                    {status
                      ? status < 2
                        ? 'Agendado'
                        : 'Bloqueado'
                      : 'Disponível'}
                  </p>
                </div>
              </Typography>
            </Grid>
            <div
              className={classes.bckContent}
              style={{ marginLeft: '32px', marginRight: '32px' }}
            ></div>
            <Grid item>
              <Typography
                style={{
                  color: '#272727',
                  fontWeight: 300,
                  fontSize: 16,
                }}
              >
                {'Justificativa Férias'}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <div style={{ display: 'flex' }}>
                <Button
                  className={classes.btnReagendar}
                  startIcon={
                    <LockOutlinedIcon
                      fontSize="small"
                      className={classes.startIconCustom}
                    />
                  }
                  size="sm"
                  onClick={handleOpen}
                >
                  <span>Desbloquear</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModalDesbloquearAgenda
        open={open}
        value={'shedule'}
        onSubmit={(e) => {
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
      ></ModalDesbloquearAgenda>
    </div>
  );
}
