import React, { Component, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import TSelectNativeMultiple from 'component/shared/TSelectNativeMultiple/TSelectNativeMultiple';
import TInputDateBase from 'component/shared/TInputDateBase/TInputDateBase';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Typography,
  TextField,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import TSearchBase from 'component/shared/TSearchBase/TSearchBase';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';
import CalendarReagenda from './components/CalendarReagenda';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import DSwitch from 'component/shared/DSwitch';
import { useState } from 'react';
import {
  getProfessionalsByEspecialty,
  getProfessionalSpecialityScheduleById,
  getScheduleTimesById,
} from 'api/schedule/agenda-medico';
import { toast } from 'react-toastify';
import { Autocomplete } from '@mui/material';
import { useContext } from 'react';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import moment from 'moment';
import { getFormatDate } from 'helpers/formatacao';
import { getUserInfoById } from 'api/user';
import Professional from 'component/Profissional/Professional';

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    minHeight: 700,
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  titleSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 28,
  },
  switchButton: {
    display: 'flex',
    alignItems: 'center',
  },
  rootInput: {
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    fontSize: 14,
    padding: '12px 16px 12px 16px',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  textFieldInput: {
    paddingRight: 0,
  },
}));

export default function ReagendarCreate(props) {
  const classes = useStyles();

  const {
    justificativa,
    setJustificativa,
    nomePaciente,
    profissional,
    setProfissional,
    changeProfessional,
    setChangeProfessional,
    duracaoAtendimento,
    dataInicial,
    dadosAgendamento,
    horariosAgenda,
    setHorariosAgenda,
    setAnamneseFormId,
    dataSheduleItem,
    formError,
    setFormError,
    setNomePaciente,
    setDuracaoAtendimento,
    setDataInicial,
    setDataScheduleItem,
    isExcluir,
  } = useConfiguracaoAgenda();

  const { setOpenScheduleOfTheDay } = useContext(GlobalContext);

  const [professionalList, setProfissionalList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [horarioDoAtendimento, setHorarioDoAtendimento] = useState(null);
  const [diaDoAtendimento, setDiaDoAtendimento] = useState(null);

  useEffect(() => {
    setJustificativa(null);

    getProfessionalSchedules(dadosAgendamento.schedule_professional?.user);
    getProfessionalScheduleTimes(dadosAgendamento.id);

    if (dadosAgendamento && dadosAgendamento.schedule_participants) {
      const participant = dadosAgendamento.schedule_participants[0];

      setNomePaciente(
        participant?.patient?.social_name || participant?.patient?.full_name,
      );
    }

    if (dadosAgendamento && dadosAgendamento.horarioAgendado) {
      setDataInicial(
        moment(dadosAgendamento.horarioAgendado.data_time).format('YYYY-MM-DD'),
      );
    }

    setDuracaoAtendimento(
      parseIntMinutesToHours(dadosAgendamento.duration_service),
    );

    if (dataSheduleItem.data_time)
      setDiaDoAtendimento(dataSheduleItem.data_time);
    setHorarioDoAtendimento(moment(dataSheduleItem.data_time).format('HH:mm'));

    if (isExcluir) {
      setOpenScheduleOfTheDay(null);
      setProfissional(null);
      setChangeProfessional(true);
      toast.warn(
        'Durante a exclusão da agenda, para reagendamento, selecione outro profissional.',
      );
    }
  }, []);

  useEffect(() => {
    if (changeProfessional) {
      getProfessionalsByEspecialty(
        dadosAgendamento.schedule_professional.medical_speciality_id,
      ).then((response) => {
        if (response.status) {
          setProfissionalList(response.medical_speciality_user);
        } else {
          toast.error('Ocorreu um erro ao buscar os profissionais');
        }
      });
    } else {
      getProfessionalSchedules(dadosAgendamento.schedule_professional?.user);
    }
  }, [changeProfessional]);

  function parseIntMinutesToHours(min) {
    var h = Math.floor(min / 60);
    var m = min % 60;

    return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
  }

  function mascaraRegistro(value) {
    try {
      var numero = value.substr(0, 9);

      numero = numero.replace(/\D/g, '');
      numero = numero.replace(/(\d{7})(\d{2})$/, '$1-$2');

      return numero;
    } catch (err) {
      console.error(err);
      return value;
    }
  }

  const renderHour = (strHour) => {
    const hourObj = new Date(strHour);
    const momentObj = moment(hourObj);
    return momentObj.format('HH:mm');
  };
  const handleChangeProfessional = (value) => {
    setOpenScheduleOfTheDay(null);
    setProfissional(null);
    setChangeProfessional(value);
  };

  const handleMenuItem = (itemParam) => {
    handleChangeProfessional(false);
    setJustificativa(null);
    props.handleMenuItem(itemParam);
  };

  function getProfessionalSchedules(profisissional) {
    if (!profisissional) {
      return;
    }

    setLoading(true);

    let medicalSpecialityId =
      dadosAgendamento.schedule_professional.medical_speciality_id;

    getProfessionalSpecialityScheduleById(
      profisissional.id,
      medicalSpecialityId,
    ).then(async (response) => {
      if (response.status) {
        if (response.schedules.length) {
          setProfissional(profisissional);

          var arrayHorarios = [];
          for await (const schedule of response.schedules) {
            if (schedule.status == 1) {
              var scheduleTimes = await getProfessionalScheduleTimes(
                schedule.id,
              );
              arrayHorarios.push(...scheduleTimes);
            }
          }

          setHorariosAgenda(arrayHorarios);
          if (!changeProfessional) {
            setAnamneseFormId(
              arrayHorarios.find(
                (item) =>
                  item.status == 'agendado' && item.id == dataSheduleItem.id,
              )
                ? arrayHorarios.find(
                    (item) =>
                      item.status == 'agendado' &&
                      item.id == dataSheduleItem.id,
                  ).schedule_health_attendance.health_attendance
                    .health_attendanceh_anamnese_form[0].anamnese_form_id
                : null,
            );
          }
        } else {
          setProfissional(null);
          toast.error('O profissional não possui agendamentos disponíveis');
        }
      } else {
        toast.error(
          'Ocorreu um erro ao buscar os horários do profissional selecionado',
        );
      }

      setLoading(false);
    });
  }

  async function getProfessionalScheduleTimes(idAgendamento) {
    return await getScheduleTimesById(idAgendamento).then((response) => {
      if (response.status) {
        var schedule = response.scheduleTimes;

        return schedule;
      } else {
        toast.error('Ocorreu um erro ao buscar os horários disponíveis');
        return null;
      }
    });
  }

  useEffect(() => {
    if (dadosAgendamento && dadosAgendamento.schedule_professional) {
      getAgendamentoProfessional(
        dadosAgendamento.schedule_professional.user_id,
      );
    }
  }, [dadosAgendamento]);

  const getAgendamentoProfessional = async (uid) => {
    let medicalSpecialityId =
      dadosAgendamento.schedule_professional.medical_speciality_id;

    getProfessionalSpecialityScheduleById(uid, medicalSpecialityId).then(
      async (response) => {
        if (response.status) {
          if (response.schedules.length) {
            var arrayHorarios = [];
            for await (const schedule of response.schedules) {
              if (schedule.status == 1) {
                var scheduleTimes = await getProfessionalScheduleTimes(
                  schedule.id,
                );
                arrayHorarios.push(...scheduleTimes);
              }
            }

            setHorariosAgenda(arrayHorarios);
            if (!changeProfessional) {
              setAnamneseFormId(
                arrayHorarios.find(
                  (item) =>
                    item.status == 'agendado' && item.id == dataSheduleItem.id,
                )
                  ? arrayHorarios.find(
                      (item) =>
                        item.status == 'agendado' &&
                        item.id == dataSheduleItem.id,
                    ).schedule_health_attendance.health_attendance
                      .health_attendanceh_anamnese_form[0].anamnese_form_id
                  : null,
              );
            }
          } else {
            setProfissional(null);
            toast.error('O profissional não possui agendamentos disponíveis');
          }
        } else {
          toast.error(
            'Ocorreu um erro ao buscar os horários do profissional selecionado',
          );
        }
      },
    );
  };

  return (
    <div className={classes.background}>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: 'white' }} />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href="#"
              className={classes.titleSection}
              onClick={(e) => {
                if (dadosAgendamento.fromView) {
                  if (
                    !dadosAgendamento.schedule_id &&
                    Array.isArray(dadosAgendamento.schedule_times) &&
                    dadosAgendamento.schedule_times.length
                  ) {
                    return handleMenuItem(
                      'visualizar_agenda:' +
                        dadosAgendamento.schedule_times[0].schedule_id,
                    );
                  }

                  if (dadosAgendamento.schedule_id)
                    return handleMenuItem(
                      'visualizar_agenda:' + dadosAgendamento.schedule_id,
                    );

                  handleMenuItem('lista_agendas');
                } else if (dadosAgendamento.schedule_professional) {
                  handleMenuItem('lista_agendamentos');
                } else {
                  handleMenuItem('lista_agendas');
                }
              }}
            >
              <ArrowBackIosIcon color="primary" fontSize="large" />{' '}
              Reagendamento
            </a>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <TInputBase
                required
                label="Nome do paciente"
                name="nome_paciente"
                placeholder="Nome do paciente"
                value={nomePaciente}
              />
            </Grid>
            <Grid item md={6}>
              <TInputTimeBase
                required
                label="Horário"
                name="horario"
                placeholder="Horário"
                value={horarioDoAtendimento}
              />
            </Grid>
            <Grid item md={6}>
              <TInputDateBase
                required
                label="Dia da Consulta"
                name="dia-consulta"
                disabled={true}
                placeholder="Dia da consulta"
                value={diaDoAtendimento}
                invalidDateMessage=""
              />
            </Grid>
            <Grid item md={6}>
              <TSelectNative
                label="Justificativa do reagendamento"
                name="justificativa_reagendamento"
                error={!formError}
                helperText={!formError ? 'Campo obrigatório' : ''}
                required
                options={[
                  { value: 1, label: 'Bloqueio de agenda' },
                  { value: 2, label: 'Má conexão com o paciente' },
                  { value: 3, label: 'Má conexão com o médico' },
                  { value: 4, label: 'Solicitação do paciente' },
                  { value: 5, label: 'Exclusão de agenda' },
                ]}
                value={justificativa}
                onChange={(e) => {
                  if (e.target.value == '') {
                    setJustificativa(null);
                    toast.error(
                      'Selecione uma justificativa válida para o reagendamento.',
                    );
                  } else {
                    setJustificativa(e.target.value);
                    setFormError(true);
                  }
                }}
              />
            </Grid>
            <Grid item md={12} className={classes.switchButton}>
              <DSwitch
                checked={changeProfessional}
                onChange={(e) => handleChangeProfessional(e.target.checked)}
                name="recording"
              />
              <Typography variant="body1" className="text-regular text-st">
                Alterar profissional
              </Typography>
            </Grid>
            {changeProfessional && (
              <Grid item md={6}>
                <InputLabel
                  classes={{
                    root: classes.label,
                  }}
                >
                  Nome do profissional *
                </InputLabel>
                <Autocomplete
                  disablePortal
                  options={
                    professionalList &&
                    professionalList.map((x) => ({
                      id: x.id,
                      label:
                        x.social_name && x.social_name != ''
                          ? x.social_name
                          : x.full_name,
                    }))
                  }
                  classes={{
                    root: classes.rootInput,
                  }}
                  value={profissional}
                  onChange={(e, value) => {
                    getProfessionalSchedules(value);
                  }}
                  name="Nome do Profissional"
                  id="nome_profissional"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Pesquisar"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        className: classes.textFieldInput,
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item md={12}>
              {(isExcluir && profissional == null) || justificativa == null ? (
                ''
              ) : (
                <CalendarReagenda
                  handleMenuItem={handleMenuItem}
                  horariosAgenda={horariosAgenda}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
