import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Container from '@mui/material/Container';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import { useStyles } from './../theme';
import { MuteCall, UnmuteCall, AddUserCall, Desligar } from './Buttons';
import { secondsToHours } from '../../../../helpers/utils';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';

function InCall(props) {
  const classes = useStyles();

  const [mute, setMute] = useState(false);
  const [time, setTime] = useState(0);
  const { setTimeCallCtx, setClickOffButton } = useNewHealthAttendance();

  const handleMute = () => {
    setMute(true);
    props.onMute(true);
  };

  const handleUnMute = () => {
    setMute(false);
    props.onUnMute(true);
  };

  useEffect(() => {
    var timerInterval = setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    setTimeCallCtx(secondsToHours(time));
  }, [time]);

  return (
    <>
      {props.call.status && (
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography
              classes={{ root: classes.textColorLightBlack }}
              variant="body1"
              className="text-regular text-st"
            >
              {`${props.call.phone.number} - ${props.call.phone.nickname}`}
            </Typography>
          </Grid>
          <Grid item>{props.children}</Grid>
          <Grid item>
            <AddUserCall onClick={(e) => props.onAddUser(true)} />
          </Grid>
          <Grid item>
            {!mute ? (
              <MuteCall onClick={handleMute} />
            ) : (
              <UnmuteCall onClick={handleUnMute} />
            )}
          </Grid>
          <Grid item>
            <Typography
              classes={{ root: classes.textColorBlack }}
              variant="body1"
              className="text-regular text-lt"
            >
              {secondsToHours(time)}
            </Typography>
          </Grid>
          <Grid item className="actions-end">
            <Desligar
              onClick={(e) => {
                props.onEndCall(secondsToHours(time), props.call.phone.number);
                setClickOffButton(true);
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

InCall.defaultProps = {
  call: {
    status: true,
    duration: '0:00',
    phone: {
      nickname: 'Apelido',
      number: '(11) 00000-0000',
    },
  },
  onEndCall: function () {
    console.log('onEndCall');
  },
  onMute: function () {
    console.log('onMute');
  },
  onUnMute: function () {
    console.log('onUnMute');
  },
  onAddUser: function () {
    console.log('onAddUser');
  },
};

export default InCall;
