import React from 'react';
import EBStateGeral from './Elements/Clinical Measurements/EBStateGeral';
import EBTemperature from './Elements/Clinical Measurements/EBTemperature';
import EBDateTime from './Elements/EBDateTime';
import EBHr from './Elements/EBHr';
import EBInput from './Elements/EBInput';
import EBMail from './Elements/EBMail';
import EBNumber from './Elements/EBNumber';
import EBParagraph from './Elements/EBParagraph';
import EBPhone from './Elements/EBPhone';
import EBRadioButton from './Elements/EBRadioButton';
import EBSelect from './Elements/EBSelect';
import EBSpace from './Elements/EBSpace';
import EBSubtitle from './Elements/EBSubtitle';
import EBTitle from './Elements/EBTitle';
import EBTxRich from './Elements/EBTxRich';
import EBUpload from './Elements/EBUpload';
import EBSymptom from './Elements/Symptoms/EBSymptom';
import EBCheckbox from './Elements/EBCheckbox.jsx';
import EBCapillaryBloodGlucose from './Elements/Clinical Measurements/EBCapillaryBloodGlucose';
import EBBloodPressure from './Elements/Clinical Measurements/EBBloodPressure';
import EBBodyMassIndex from './Elements/Clinical Measurements/EBBodyMassIndex';
import EBRange from './Elements/Clinical Measurements/EBRange';
import EBComorbidities from './Elements/Antecedents/EBComorbidities';
import EBPhysicalActivity from './Elements/Antecedents/EBPhysicalActivity';
import EBSmoking from './Elements/Antecedents/EBSmoking';
import EBAlcoholicBeverage from './Elements/Antecedents/EBAlcoholicBeverage';
import EBDrugs from './Elements/Antecedents/EBDrugs';
import EBAntecedentFamily from './Elements/Antecedents/EBAntecedentFamily';
import EBDiagnosticHypothesis from './Elements/DiagnosticHypothesis/EBDiagnosticHypothesis';
import EBFieldDefaultHPMA from './Elements/FieldsDefault/EBFieldDefaultHPMA';
import EBFieldDefaultObservation from './Elements/FieldsDefault/EBFieldDefaultObservation';
import EBFieldDefaultConductClinical from './Elements/FieldsDefault/EBFieldDefaultConductClinical';
import { RssFeedTwoTone } from '@material-ui/icons';

const Elements = ({
  field: {
    field_type_id,
    id,
    is_default,
    title,
    field_category_id,
    label,
    placeholder,
    value,
    additional_data,
    helper_text,
    showCID10,
    showCIPE,
    showCIAP,
    is_required,
    is_read_only,
  },
  errorField,
  conditional,
  readOnly,
  formAnswers,
}) => {
  const erro = errorField.filter(
    (res) =>
      res.id == id &&
      (res.value !== undefined || res.value !== '' || res.value !== null),
  );

  const condicional = conditional.filter(
    (res) =>
      res.id == id &&
      (res.action !== undefined || res.action !== '' || res.action !== null),
  );

  const answer =
    formAnswers && formAnswers.length > 0
      ? formAnswers
          .filter((res) => res.id == id)
          .map((filteredObj) => filteredObj.value)
      : [];

  switch (additional_data.construct.element) {
    case 'FieldDefaultHPMA': //Textarea - HPMA
      return (
        <EBFieldDefaultHPMA
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          title={title}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'FieldDefaultObservation': //TextRich - Observacao
      return (
        <EBFieldDefaultObservation
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          title={title}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'FieldDefaultConductClinical': //Textarea - Conduta clinica
      return (
        <EBFieldDefaultConductClinical
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          title={title}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'TextInput': //Input - Texto Curto
      return (
        <EBInput
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          title={title}
          additional_data={additional_data}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'LineBreak': //Diagramação - Linha de Separação
      return <EBHr condicional={condicional} />;
    case 'Space': //Diagramação - Espaço
      return <EBSpace condicional={condicional} />;
    case 'Header': //Diagramação - Título
      return (
        <EBTitle
          label={label}
          helper_text={helper_text}
          title={title}
          additional_data={additional_data}
          condicional={condicional}
        />
      );
    case 'Subtitulo': //Diagramação - Subtítulo
      return (
        <EBSubtitle
          label={label}
          helper_text={helper_text}
          title={title}
          additional_data={additional_data}
          condicional={condicional}
        />
      );
    case 'Paragraph': //Diagramação - Paragráfo
      return (
        <EBParagraph
          label={label}
          helper_text={helper_text}
          title={title}
          additional_data={additional_data}
          condicional={condicional}
        />
      );
    case 'TextRich': //Textarea - Texto Longo
      return (
        <EBTxRich
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          condicional={condicional}
          erro={erro}
          readOnly={readOnly}
          answer={answer}
          additional_data={additional_data}
        />
      );
    case 'NumberInput': //Input - Numero
      return (
        <EBNumber
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'Checkboxes': //Input - Checkbox
      return (
        <EBCheckbox
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          field_category_id={field_category_id}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );

    case 'RadioButtons': //Input - Radiobuttons
      return (
        <EBRadioButton
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          field_category_id={field_category_id}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );

    case 'Dropdown': //Input - Select
      return (
        <EBSelect
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputEmail': //Input - E-mail
      return (
        <EBMail
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'DatePicker': //Input - Data e Hora
      return (
        <EBDateTime
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'Download': //Input File - Upload
      return (
        <EBUpload
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
        />
      );
    case 'InputPhone': //Input - Phone
      return (
        <EBPhone
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputSymptomDuration': //Pré-Formato - Medicoes clinicas
      return (
        <EBSymptom
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'RadioEstadoGeral': //Pré-Formato - Medicoes clinicas
      return (
        <EBStateGeral
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          additional_data={additional_data}
          helper_text={helper_text}
          field_category_id={field_category_id}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputTemperature': //Pré-Formato - Medicoes clinicas
      return (
        <EBTemperature
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputCapillaryBloodGlucose': //Pré-Formato - Medicoes clinicas
      return (
        <EBCapillaryBloodGlucose
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputBloodPressure': //Pré-Formato - Medicoes clinicas
      return (
        <EBBloodPressure
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputBodyMassIndex': //Pré-Formato - Medicoes clinicas
      return (
        <EBBodyMassIndex
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'Range': //Pré-Formato - Medicoes clinicas
      return (
        <EBRange
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'CheckboxesComorbidities': //Pré-Formato - Antecendentes
      return (
        <EBComorbidities
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'RadioButtonsPhysicalActivity': //Pré-Formato - Antecendentes
      return (
        <EBPhysicalActivity
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'RadioButtonsSmoking': //Pré-Formato - Antecendentes
      return (
        <EBSmoking
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'RadioButtonsAlcoholicBeverage': //Pré-Formato - Antecendentes
      return (
        <EBAlcoholicBeverage
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'RadioButtonsDrugs': //Pré-Formato - Antecendentes
      return (
        <EBDrugs
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'CheckboxesAntecedentFamily': //Pré-Formato - Antecendentes
      return (
        <EBAntecedentFamily
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );
    case 'InputDiagnosticHypothesis': //Pré-Formato - Antecendentes
      return (
        <EBDiagnosticHypothesis
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          helper_text={helper_text}
          additional_data={additional_data}
          title={title}
          field_category_id={field_category_id}
          showCID10={showCID10}
          showCIPE={showCIPE}
          showCIAP={showCIAP}
          is_required={is_required}
          is_read_only={is_read_only}
          erro={erro}
          condicional={condicional}
          readOnly={readOnly}
          answer={answer}
        />
      );

    default:
      return null;
  }
};

export default Elements;
