import {
  DialogContent,
  DialogContentClassKey,
  Grid,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close } from '@material-ui/icons';
import DButton from '../../../../shared/DButton';
import { IState } from '../../interfaces/state.interface';
import { styles } from './styles';
  
  interface IProps {
    state: IState
    setState: React.Dispatch<React.SetStateAction<IState>>
  }
  
  const ModalConfirmClose = (props: IProps) => {
    return (
      <Dialog
        aria-labelledby="conditional-remove-title"
        scroll="body"
        onClose={() => {
          props.setState({ ...props.state, confirmClose: false });
        }}
        open={true}
      >
        <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={10}>
              <Typography variant="body1" className="text-regular text-st">
                Se seguir com essa ação{' '}
                {props.state.isEdit.status
                  ? 'todas as alterações serão perdidas'
                  : 'todos os dados serão perdidos'}
                , deseja confirmar essa ação?
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={() => {
                  props.setState({ ...props.state, confirmClose: false });
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={styles.contentRemove as Partial<ClassNameMap<DialogContentClassKey>>}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonBack}
                variant="secondary"
                size="none"
                className="btn-error"
                onClick={() =>
                  props.setState({
                    ...props.state,
                    confirmClose: false,
                    isEdit: { status: false, id: null, duplicate: false },
                  })
                }
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonConfirm}
                variant="primary"
                size="none"
                className="btn-error"
                onClick={() =>
                  props.setState({
                    ...props.state,
                    confirmClose: false,
                    novoPerfil: false,
                    isEdit: { status: false, id: null, duplicate: false },
                  })
                }
              >
                Confirmar e fechar
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
  
  export default ModalConfirmClose;