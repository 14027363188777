import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { usePatient } from '../../../../../../../../context/PatientContext';
import DDateInput from '../../../../../../../shared/DDateInput';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

interface IForeignIdentityProps {
  errors: {
    numeroPassaporte?: string;
    dataEmissaoPassaporte?: string;
    dataValidadePassaporte?: string;
    paisEmissorPassaporte?: string;
    emissao?: string;
    nomeMae?: string;
    nomePai?: string;
  };
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
  countries: { id: number; value: string; description: string }[];
}

export const ForeignIdentity = ({
  errors,
  setErrors,
  removeError,
  countries,
}: IForeignIdentityProps) => {
  const classes = useStyles();

  const {
    dadosIdentidadeEstrangeiro,
    setDadosIdentidadeEstrangeiro,
    dadosIdentidadeGeral,
    setDadosIdentidadeGeral,
  } = usePatient();

  return (
    <Grid container spacing={3} className={classes.alignItemsEnd}>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="numeroPassaporte"
              placeholder="Número do passaporte"
              label="Número do passaporte"
              style={{ marginTop: 5 }}
              type="text"
              error={errors.numeroPassaporte}
              helperText={errors.numeroPassaporte}
              fullWidth={true}
              value={dadosIdentidadeEstrangeiro.numeroPassaporte}
              onChange={(e) => {
                removeError('numeroPassaporte');
                setDadosIdentidadeEstrangeiro((prevState) => {
                  return {
                    ...prevState,
                    numeroPassaporte: e.target.value,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDateInput
              id="data-emissao-passaporte"
              error={errors.dataEmissaoPassaporte}
              helperText={errors.dataEmissaoPassaporte}
              fullWidth
              className="datePicker"
              label="Data de emissão do passaporte"
              maxDate={new Date()}
              maxDateMessage="A data de emissão não pode ser maior que a data atual"
              value={dadosIdentidadeEstrangeiro.dataEmissaoPassaporte}
              onError={(errorMessage) => {
                setErrors({
                  ...errors,
                  dataEmissaoPassaporte: errorMessage,
                });
              }}
              onChange={(arg1) => {
                removeError('dataEmissaoPassaporte');
                setDadosIdentidadeEstrangeiro((prevState) => {
                  return {
                    ...prevState,
                    dataEmissaoPassaporte: arg1,
                  };
                });
                // setIdentidadeEmissaoValida(arg2.isValid);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDateInput
              id="data-validade-passaporte"
              error={errors.dataValidadePassaporte}
              helperText={errors.dataValidadePassaporte}
              fullWidth
              className="datePicker"
              label="Data de validade do passaporte"
              minDate={new Date()}
              minDateMessage="A data de validade não pode ser menor que a data atual"
              value={dadosIdentidadeEstrangeiro.dataValidade}
              onError={(errorMessage) => {
                setErrors({
                  ...errors,
                  dataValidadePassaporte: errorMessage,
                });
              }}
              onChange={(arg1, arg2, e) => {
                removeError('dataValidadePassaporte');
                setDadosIdentidadeEstrangeiro((prevState) => {
                  return {
                    ...prevState,
                    dataValidade: arg1,
                  };
                });
                // setIdentidadeEmissaoValida(arg2.isValid);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDropDownSimple
              primaryColorSelectIcon
              id="pais-emissor-passaporte"
              label="País emissor do passaporte"
              placeholder="País emissor do passaporte"
              fullWidth
              // required={!dadosPrincipais.dadosIncompletos}
              error={errors.paisEmissorPassaporte}
              helperText={errors.paisEmissorPassaporte}
              value={dadosIdentidadeEstrangeiro.paisPassaporte}
              options={countries}
              onChange={(event) => {
                removeError('paisEmissorPassaporte');
                setDadosIdentidadeEstrangeiro((prevState) => {
                  return {
                    ...prevState,
                    paisEmissorPassaporte: event,
                  };
                });
              }}
            />
            {/* <DDateInput
                    id="pais-emissor-passaporte"
                    error={error.paisEmissorPassaporte}
                    helperText={error.paisEmissorPassaporte}
                    fullWidth
                    className="datePicker"
                    // minDate={dadosPrincipais.dataNascimento}
                    label=""
                    value={dadosIdentidadeEstrangeiro.paisEmissorPassaporte}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        paisEmissorPassaporte: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.paisEmissorPassaporte;

                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          paisEmissorPassaporte: arg1,
                        };
                      });
                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="NomeMae"
              placeholder="Nome da mãe"
              label="Nome da mãe"
              disabled={dadosIdentidadeGeral.maeDesconhecida}
              style={{ marginTop: 5 }}
              type="text"
              error={errors.nomeMae}
              helperText={errors.nomeMae}
              fullWidth
              onChange={(e) => {
                removeError('nomeMae');
                // setNomeMae(e.target.value);

                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomeMae: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomeMae}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                onClick={(e) => {
                  setDadosIdentidadeGeral((prevState) => {
                    return {
                      ...prevState,
                      maeDesconhecida: e.target.checked,
                    };
                  });

                  if (!dadosIdentidadeGeral.maeDesconhecida) {
                    removeError('nomeMae');
                    setDadosIdentidadeGeral((prevState) => {
                      return {
                        ...prevState,
                        nomeMae: '',
                      };
                    });
                  }
                }}
              />
            }
            label="Mãe desconhecida"
          />
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="nomePai"
              placeholder="Nome do pai"
              label="Nome do pai"
              disabled={dadosIdentidadeGeral.paiDesconhecido}
              style={{ marginTop: 5 }}
              type="text"
              error={errors.nomePai}
              helperText={errors.nomePai}
              fullWidth={true}
              onChange={(e) => {
                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomePai: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomePai}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                onClick={(e) => {
                  if (!dadosIdentidadeGeral.paiDesconhecido) {
                    return setDadosIdentidadeGeral((prevState) => {
                      return {
                        ...prevState,
                        nomePai: '',
                        paiDesconhecido: e.target.checked,
                      };
                    });
                  }

                  setDadosIdentidadeGeral((prevState) => {
                    return {
                      ...prevState,
                      paiDesconhecido: e.target.checked,
                    };
                  });
                }}
              />
            }
            label="Pai desconhecido"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
