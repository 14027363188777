import axios from 'axios';
import env from 'react-dotenv';

async function getCep(cep) {
  var new_cep = cep.replace(/[^0-9]/gm, '');

  if (new_cep.length === 8) {
    var url = `${env.API_URL}/search/cep/${new_cep}`;

    return fetch(url, {
      method: 'get',
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        throw err;
      });
  } else {
    throw new Error('CEP inválido'); // Devolve erro para tratamento no front.
  }
}

function cnsUnique(cns, id, token) {
  return axios
    .post(
      `${env.API_URL}/document/unique?token=${token}`,
      {
        type: 'cns',
        value: cns,
        id,
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}

function cpfUnique(cpf, id, token) {
  return axios
    .post(
      `${env.API_URL}/document/unique?token=${token}`,
      {
        type: 'cpf',
        value: cpf,
        id,
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data);
}

export { cnsUnique, cpfUnique, getCep };
