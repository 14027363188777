import React, { useContext } from 'react';
import { Grid, Button, Container, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { SolicitacaoFiltro } from '../../../SolicitacaoFiltro';
import DButton from '../../../../../shared/DButton';
import DButtonCustom from '../../../../../shared/DButtonCustom';
import './index.scss';
import { AppContext } from '../../../../../../context/AppContext';
import {
  getMedicalUnitesById,
  getMedicalUnites,
} from '../../../../../../api/user';

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.background.snow,
  },
  paciente__topo: {
    position: 'absolute',
    left: 0,
    top: 88,
    width: '100%',
    height: 61,
    display: 'flex',
    padding: '10px 40px',
    background: theme.palette.background.grass,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  btnActive: {
    background: `${theme.palette.background.grass} !important`,
  },
  btnNomal: {
    background: 'transparent !important',
  },
}));

const EmAndamentoButton = withStyles((theme) => ({
  root: {
    height: '33px !important',
    width: '143px !important',
    minWidth: '143px !important',
    borderRadius: '100px !important',
    paddingTop: '6px !important',
    paddingRight: '18px !important',
    paddingBottom: '6px !important',
    paddingLeft: '18px !important',
    borderColor: `${theme.palette.primary.light} !important`,
  },
  label: {
    width: '103px !important',
    color: `${theme.palette.primary.light} !important`,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'initial',
    textIndent: 'initial',
    '&:hover': {
      textIndent: 'initial',
    },
    '&:active': {
      textIndent: 'initial',
    },
    '&:focus': {
      textIndent: 'initial',
    },
  },
}))(DButtonCustom);

const HistoricoButton = withStyles((theme) => ({
  root: {
    height: '33px !important',
    width: '93px !important',
    minWidth: '93px !important',
    borderRadius: '100px !important',
    paddingTop: '6px !important',
    paddingRight: '18px !important',
    paddingBottom: '6px !important',
    paddingLeft: '18px !important',
    borderColor: `${theme.palette.primary.light} !important`,
  },
  label: {
    width: '57px !important',
    color: `${theme.palette.primary.light} !important`,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'initial',
    textIndent: 'initial',
    '&:hover': {
      textIndent: 'initial',
    },
    '&:active': {
      textIndent: 'initial',
    },
    '&:focus': {
      textIndent: 'initial',
    },
  },
}))(DButtonCustom);

const SolicitacaoButton = withStyles((theme) => ({
  root: {
    height: '33px !important',
    width: '142px !important',
    minWidth: '142px !important',
    borderRadius: 100,
    paddingTop: '6px !important',
    paddingRight: '18px !important',
    paddingBottom: '6px !important',
    paddingLeft: '18px !important',
    backgroundColor: `${theme.palette.primary.light} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  label: {
    width: '106px !important',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21px !important',
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'initial',
    textIndent: 'initial !important',
    '&:hover': {
      textIndent: 'initial !important',
      border: 'none',
    },
    '&:active': {
      textIndent: 'initial !important',
      border: 'none',
    },
    '&:focus': {
      textIndent: 'initial !important',
      border: 'none',
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
}))(DButtonCustom);

function a11yProps(index) {
  return {
    id: 'tab-${index}',
    'aria-controls': 'tab-${index}',
  };
}

export function Header(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { userInfo, selectedProfile } = useContext(AppContext);

  document.body.style.backgroundColor = '#f4f4f4';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedUnity, setSelectedUnity] = React.useState('');

  React.useEffect(() => {
    if (userInfo && selectedProfile) {
      if (Array.isArray(selectedProfile.units)) {
        var _selectedUnity = selectedProfile.units.find(
          (x) => x.medical_unit_id === userInfo.unidade,
        );

        if (_selectedUnity) {
          setSelectedUnity(_selectedUnity.description || '');
        }
      }
    }
  }, [userInfo, selectedProfile]);

  return (
    <Grid item className={classes.paciente__topo}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="h4" component="h1">
                  Solicitações
                </Typography>
              </Grid>
              <Grid item>
                <span className="line-separator"></span>
              </Grid>
              <Grid item>
                <Typography variant="body1" className="text-regular text-st">
                  Solicitações
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <EmAndamentoButton
                  onClick={(e) => props.setHistoryAttendance(false)}
                  size="md"
                  className={
                    !props.historyAttendance
                      ? classes.btnActive
                      : classes.btnNomal
                  }
                  notGrid
                  variant="secondary"
                >
                  {props.historyAttendance ? 'Em andamento' : 'Em atendimento'}
                </EmAndamentoButton>
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <HistoricoButton
                      onClick={(e) => props.setHistoryAttendance(true)}
                      size="md"
                      className={
                        props.historyAttendance
                          ? classes.btnActive
                          : classes.btnNomal
                      }
                      variant="secondary"
                    >
                      Histórico
                    </HistoricoButton>
                  </Grid>
                  <Grid item>
                    <SolicitacaoFiltro />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <SolicitacaoButton
                  onClick={(e) => props.cadastrar(true)}
                  variant="primary"
                  size="md"
                >
                  Nova Solicitação
                </SolicitacaoButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
