import { useState, useEffect } from 'react';
import { useNewHealthAttendance } from '../../../context/NewHealthAttendance';
import VoipCall from '../../shared/VoipCall';
import { getPatientName, atender, abdicar } from '../helper.functions';
import { mascaraNumero } from '../../../helpers/formatacao';
import { toast } from 'react-toastify';
import { getUserInfoById, updateCall } from '../../../api/user';
import ModalChamadaDeVoz from '../../shared/ModalChamadaDeVoz';
import { useChat } from '../../../context/ChatContext';
import {
  createConversation,
  getConversationByAttendenceId,
} from '../../../api/conversations';
import Configuration from '../../../api/configuration';

function Toolbar(props) {
  const {
    healthAttendance,
    setHealthAttendance,
    patient,
    startCall,
    muteCall,
    callData,
    visualizador,
  } = useNewHealthAttendance();

  const {
    setConversationId,
    setSid,
    setServiceSid,
    chatAttendance,
    setChatAttendance,
  } = useChat();

  const [assumido, setAssumido] = useState(false);
  const [locked, setLocked] = useState(false);
  const [patientContact, setPatientContact] = useState({
    name: '',
    phone: '',
  });
  const [openModalPatientNumber, setOpenModalPatientNumber] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  useEffect(() => {
    // Só irá criar o conversation ID caso seja necessário
    if (chatAttendance && healthAttendance) {
      // Cria a ou recupera conversation ID
      createConversation(localStorage.getItem('token'), {
        health_attendance_id: healthAttendance.id,
      }).then((res) => {
        console.log(res);
        if (res?.status) {
          setSid(res.conversation.conversation_sid);
          setServiceSid(res.conversation.chat_service_id);
          setConversationId(res.conversation.id);
        } else {
          getConversationByAttendenceId(
            localStorage.getItem('token'),
            healthAttendance.id,
          ).then((res) => {
            if (res.status) {
              setConversationId(res.conversation[0].id);
              setSid(res.conversation[0].conversation_sid);
              setServiceSid(res.conversation[0].chat_service_id);
            }
          });
        }
      });
    }
  }, [healthAttendance, chatAttendance]);

  useEffect(() => {
    Configuration.get(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setChatAttendance(
          res.configuration && res.configuration.chat_attendance_enable,
        );
      }
    });
  }, []);

  useEffect(() => {
    // Se o health_attendance_status_id === 2 o atendimento já está assumido
    if (!healthAttendance) return;

    if (
      healthAttendance &&
      parseInt(healthAttendance.health_attendance_status_id) === 2
    )
      setAssumido(true);
    else setAssumido(false);

    // Se o id do usuário logado for igual ao user_id do atendimento os controles da toolbar são liberados, caso contrário ficam escondidos
    // O atendimento deve estar com o health_attendance_status_id === 1 (aguardando atendimento)
    let locked = false;

    console.log('locked', locked);

    if (
      healthAttendance &&
      healthAttendance.user_id !== null &&
      parseInt(healthAttendance.user_id) !==
        parseInt(localStorage.getItem('uid'))
    )
      locked = true;

    if (
      healthAttendance &&
      healthAttendance.user_id !== null &&
      parseInt(healthAttendance.user_id) !==
        parseInt(localStorage.getItem('uid')) &&
      parseInt(healthAttendance.health_attendance_status_id) === 2
    )
      locked = true;

    if (
      healthAttendance &&
      (parseInt(healthAttendance.health_attendance_status_id) === 3 ||
        parseInt(healthAttendance.health_attendance_status_id) === 4)
    )
      locked = true;

    if (
      healthAttendance.user_requesting_id ===
        parseInt(localStorage.getItem('uid')) &&
      visualizador == true
    ) {
      locked = true;
    }

    setLocked(locked);

    console.log('locked finish', locked);
  }, [healthAttendance]);

  const handleAssumir = async () => {
    let response = await atender(
      localStorage.getItem('token'),
      healthAttendance.id,
    );

    if (response.status) {
      setAssumido(true);
      setHealthAttendance({
        ...healthAttendance,
        health_attendance_status_id: 2,
        user_id: localStorage.getItem('uid'),
      });
      toast.success('Atendimento assumido com sucesso.');
    } else {
      setAssumido(false);
      toast.error('Erro ao assumir atendimento.');
    }
  };

  const handleAbdicar = async (motivo = '') => {
    let response = await abdicar(
      localStorage.getItem('token'),
      healthAttendance.id,
      motivo,
    );

    if (response.status) {
      setAssumido(false);
      setHealthAttendance({
        ...healthAttendance,
        health_attendance_status_id: 1,
      });
      toast.success('Atendimento abdicado com sucesso.');
    } else {
      toast.error('Erro ao abdicar atendimento.');
    }
  };

  /**
   * @param {"paciente_solicitante" | "paciente"} action
   */
  const validaNumeroSolicitante = async (action) => {
    if (action === 'paciente_solicitante') {
      const requestingData = await getUserInfoById(
        localStorage.getItem('token'),
        healthAttendance.user_requesting_id,
      );

      if (!requestingData.status) {
        toast.warning('Não foi possível recuperar os dados do solicitante.');
        return false;
      } else {
        console.log(requestingData);
        if (requestingData && !requestingData.contact) {
          toast.warning('O Solicitante não tem um contato informado.');
          return false;
        } else {
          if (
            !(
              Array.isArray(requestingData.contact) &&
              requestingData.contact.length > 0
            )
          ) {
            toast.warning('O Solicitante não tem um contato informado.');
            return false;
          }
        }
      }

      setOpenModalPatientNumber(true);
    } else if (action === 'paciente') {
      setOpenModalPatientNumber(true);
      setSelectedAction('paciente');
    }
  };

  /**
   * @param {"paciente_solicitante" | "solicitante"} action
   * @param {function} successCb Função callback que diz se a operação de validação para iniciação de uma call foi bem sucedida.
   */
  const handleCall = async (action, successCb) => {
    if (!healthAttendance) {
      toast.error('Atendimento não encontrado.');
      return successCb(false);
    }

    if (action === 'solicitante') {
      if (healthAttendance.user_requesting_id === null) {
        toast.warning('Não há solicitante para este atendimento.');
        return successCb(false);
      }

      if (
        healthAttendance.user_requesting_id ===
        parseInt(localStorage.getItem('uid'))
      ) {
        toast.warning('Você não pode chamar você mesmo.');
        return successCb(false);
      }

      const requestingData = await getUserInfoById(
        localStorage.getItem('token'),
        healthAttendance.user_requesting_id,
      );

      var dataSolicitante = {
        phoneNumber: null,
        nameParticipant: null,
        nameRoom: 'Room ' + healthAttendance.id,
        health_attendance_id: healthAttendance.id,
      };

      if (!requestingData.status) {
        toast.warning('Não foi possível recuperar os dados do solicitante.');
        return successCb(false);
      } else {
        if (requestingData && !requestingData.contact) {
          toast.warning('O Solicitante não tem um contato informado.');
          return successCb(false);
        } else {
          if (
            Array.isArray(requestingData.contact) &&
            requestingData.contact.length > 0
          ) {
            dataSolicitante.phoneNumber = String(
              requestingData.contact[0].value,
            ).replace(/[^0-9]/g, '');
          } else {
            toast.warning('O Solicitante não tem um contato informado.');
            return successCb(false);
          }
        }

        dataSolicitante.nameParticipant = requestingData.user
          ? requestingData.user.full_name || requestingData.user.social_name
          : null;
      }

      startCall(action, null, dataSolicitante);
      return successCb(true);
    } else {
      // paciente e solicitante

      // if(healthAttendance.user_requesting_id === null) {
      //   toast.warning("Não há solicitante para este atendimento.");
      //   return successCb(false);
      // }

      // if(healthAttendance.user_requesting_id === parseInt(localStorage.getItem("uid"))) {
      //   toast.warning("Você não pode chamar você mesmo.");
      //   return successCb(false);
      // }

      if (healthAttendance.patient_id === null) {
        toast.warning('Não há paciente para este atendimento.');
        return successCb(false);
      }

      var _patientContact = selectedNumber;

      if (!_patientContact) {
        toast.warning(
          'Não há um número de contato informado para este paciente.',
        );
        return successCb(false);
      }

      var dataPaciente = {
        phoneNumber: _patientContact.phone,
        nameParticipant:
          patient &&
          (patient.full_name || patient.social_name) +
            (patient.pronoun ? patient.pronoun.name : ''),
        nameRoom: 'Room ' + healthAttendance.id,
        health_attendance_id: healthAttendance.id,
      };

      dataPaciente.nameParticipant = dataPaciente.nameParticipant
        ? dataPaciente.nameParticipant
        : 'Paciente';

      setPatientContact({
        name: dataPaciente.nameParticipant,
        phone: dataPaciente.phoneNumber,
      });

      var requestingData = {};

      if (healthAttendance.user_requesting_id)
        requestingData = await getUserInfoById(
          localStorage.getItem('token'),
          healthAttendance.user_requesting_id,
        );
      else
        requestingData = {
          user: {
            full_name: 'Solicitante',
          },
          contact: [
            {
              value: '(11) 991170792',
            },
          ],
        };

      var dataSolicitante = {
        phoneNumber: null,
        nameParticipant: null,
        nameRoom: 'Room ' + healthAttendance.id,
        health_attendance_id: healthAttendance.id,
      };

      if (
        Array.isArray(requestingData.contact) &&
        requestingData.contact.length > 0
      ) {
        dataSolicitante.phoneNumber = String(
          requestingData.contact[0].value,
        ).replace(/[^0-9]/g, '');
      }

      dataSolicitante.nameParticipant = requestingData.user
        ? requestingData.user.full_name || requestingData.user.social_name
        : null;

      startCall(action, dataPaciente, dataSolicitante);
      return successCb(true);
    }
  };

  /**
   * @param {"paciente_solicitante" | "solicitante"} action
   * @param {function} successCb Função callback que diz se a operação de validação para iniciação de uma call foi bem sucedida.
   */
  const onAddUserVoip = (action) => {
    handleCall(action, (successCb) => {
      if (successCb) {
        toast.success(
          `Chamando ${action === 'solicitante' ? 'o solicitante' : 'o paciente'} novamente.`,
        );
      }
    });
  };

  const updateCallTime = (type, to, duration) => {
    if (healthAttendance && callData) {
      const callId = callData.id;

      const updateCallData = {
        duration: duration,
        to,
        to_type: (type = 'patient'),
      };

      updateCall(localStorage.getItem('token'), callId, updateCallData)
        .then((response) => {
          console.log('Call updated time', response);
        })
        .catch((error) => {
          console.log('Call updated time error', error);
        });

      // if(type === "patient" && callData.patient) {
      //   const dataCallPatient = callData.patient;

      //   const updateCallData = {
      //     duration: duration,
      //     to,
      //     to_type: type,
      //   }

      //   updateCall(localStorage.getItem("token"), dataCallPatient.id, updateCallData)
      //     .then(response => {
      //       console.log("Call updated time", response);
      //     })
      //     .catch(error => {
      //       console.log("Call updated time error", error);
      //     })
      // }

      // if(type === "requesting" && callData.requesting) {
      //   const dataCallRequesting = callData.requesting;

      //   const updateCallData = {
      //     duration: duration,
      //     to,
      //     to_type: type,
      //   }

      //   updateCall(localStorage.getItem("token"), dataCallRequesting.id, updateCallData)
      //     .then(response => {
      //       console.log("Call updated time", response);
      //     })
      //     .catch(error => {
      //       console.log("Call updated time error", error);
      //     })
      // }
    }
  };

  return (
    <>
      <ModalChamadaDeVoz
        open={openModalPatientNumber}
        value={patient.patient_contact}
        onSubmit={(e) => {
          setSelectedNumber(e);
          setOpenModalPatientNumber(false);
        }}
        onClose={() => setOpenModalPatientNumber(false)}
      />
      <VoipCall
        title={getPatientName(patient)}
        abdicarButton={false}
        chatButton={false}
        callButton
        videoCallButton
        locked={locked}
        assumido={assumido}
        selectedNumber={selectedNumber}
        selectedAction={selectedAction}
        onAssumir={handleAssumir}
        onAddUser={(action) => onAddUserVoip(action)}
        onAbdicar={handleAbdicar}
        onChat={(e) => props.onChat()}
        onCall={(action, success) => {
          if (!action) return;

          console.log('ACTION', action, action === '');

          handleCall(action, success);
        }}
        onGetNumber={(action) => {
          validaNumeroSolicitante(action);
          setSelectedAction(action);
        }}
        onVideoCall={(action) => {
          if (!action) return;
          props.onVideoCall(action);
        }}
        onEndCall={(type, to, duration) => {
          setSelectedNumber(null);
          updateCallTime(type, to, duration);
        }}
        onMute={(val) => {
          muteCall(val);
        }}
        onFinish={(e) => props.onFinish(true)}
        call={{
          status: true,
          duration: '0:00',
          phone: {
            nickname: patientContact && patientContact.name,
            number: patientContact && mascaraNumero(patientContact.phone), //"(11) 00000-0000",
          },
        }}
        onBack={(e) => {
          props.onBack(true);
        }}
      />
    </>
  );
}

Toolbar.defaultProps = {
  onFinish: function () {
    console.log('onFinish');
  },
  onBack: function () {
    console.log('onBack');
  },
  onChat: function () {
    console.log('onBack');
  },
};

export default Toolbar;
