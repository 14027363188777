import { styled } from '@mui/material/styles';
import { InputLabel, makeStyles, TextField } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 53,
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    fontSize: 14,
    padding: '12px 16px 12px 16px',
    '&:disabled': {
      backgroundColor: '#F5F5F5',
    },
  },
  rootDisabled: {
    borderColor: '#F5F6F6',
    background: '#F5F6F6',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
  clockIcon: {
    color: '#006FB5 !important',
  },
  clockIconError: {
    color: '#FF6B57 !important',
  },
  customInput: {
    marginTop: 0,
    marginBottom: 0,

    '& > div': {
      border: 'solid #D0D3D9 1px',
      borderRadius: '5px',
    },

    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },

    '& .MuiInput-underline': {
      padding: '8px',
      paddingBottom: '13px',
    },

    '&:disabled': {
      backgroundColor: '#F5F5F5',
    },
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-focused fieldset': {
    border: 'solid rgba(0, 0, 0, 0.87) 1px',
  },
  'input[type="date"]::-webkit-calendar-picker-indicator': {
    background: 'none',
  },
});

function TInputDateBase({
  id,
  label,
  name,
  value,
  placeholder,
  onChange,
  error,
  helperText,
  ...props
}) {
  const classes = useStyles();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <InputLabel
          error={error}
          id={id}
          classes={{
            root: classes.label,
            error: classes.errorLabel,
          }}
        >
          {label} {props['required'] ? '*' : null}
        </InputLabel>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <KeyboardDatePicker
            {...props}
            className={[classes.customInput]}
            margin="normal"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            value={value}
            onChange={onChange}
            name={name}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              lang: 'pt-BR',
            }}
          />
        </MuiPickersUtilsProvider>
        {helperText && (
          <p
            className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
          >
            {helperText}
          </p>
        )}
        {error && (
          <p
            className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
          >
            {error}
          </p>
        )}
      </LocalizationProvider>
    </>
  );
}

TInputDateBase.defaultProps = {
  id: 'InpurBase',
  label: 'Input Base',
  name: 'input_base',
  value: '',
  disabled: false,
  placeholder: '',
  error: false,
  helperText: '',
  onChange: (e) => {
    console.log('onChange');
  },
};

export default TInputDateBase;
