import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useMemo } from 'react';
import orgaoEmissorCode from '../../../../../../../../assets/dados/orgaoEmissor.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { mascaraRG } from '../../../../../../../../helpers/formatacao';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DDateInput from '../../../../../../../shared/DDateInput';
import DInput from '../../../../../../../shared/DInput';
import { useStyles } from './styles';

interface BrazilianIdentityProps {
  errors: {
    identidadeNumero?: string;
    identidadeOrgaoEmissor?: string;
    identidadeUF?: string;
    identidadeDataEmissao?: string;
    identidadeEmissao?: string;
    nomeMae?: string;
    nomePai?: string;
  };
  setErrors: (errors: { [key: string]: string }) => void;
  removeError: (key: string) => void;
  states: { id: number; value: string; description: string }[];
}

export const BrazilianIdentity = ({
  errors,
  removeError,
  setErrors,
  states,
}: BrazilianIdentityProps) => {
  const classes = useStyles();
  const {
    dadosIdentidadeBrasileiro,
    setDadosIdentidadeBrasileiro,
    dadosIdentidadeGeral,
    setDadosIdentidadeGeral,
  } = usePatient();

  const orgaoEmissorMap = useMemo(
    () =>
      orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>

      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="identidadeNumero"
              label="Número de identidade"
              type="text"
              error={errors.identidadeNumero}
              helperText={errors.identidadeNumero}
              fullWidth={true}
              value={dadosIdentidadeBrasileiro.identidadeNumero}
              onChange={(e) => {
                removeError('identidadeNumero');
                setDadosIdentidadeBrasileiro((prevState) => {
                  return {
                    ...prevState,
                    identidadeNumero: mascaraRG(e.target.value),
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DDateInput
              id="identidadeEmissao"
              placeholder="Data de emissão"
              error={errors.identidadeEmissao}
              helperText={errors.identidadeEmissao}
              fullWidth
              className="datePicker"
              label="Data de emissão"
              maxDate={new Date()}
              maxDateMessage="A data de emissão não pode ser maior que a data atual"
              value={dadosIdentidadeBrasileiro.identidadeDataEmissao}
              onError={(errorMessage) => {
                setErrors({
                  ...errors,
                  identidadeEmissao: errorMessage,
                });
              }}
              onChange={(arg1) => {
                removeError('identidadeEmissao');
                setDadosIdentidadeBrasileiro((prevState) => {
                  return {
                    ...prevState,
                    identidadeDataEmissao: arg1,
                  };
                });

                // setIdentidadeEmissaoValida(arg2.isValid);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DAutoComplete
              id="identidadeOrgaoEmissor"
              placeholder="Órgão emissor"
              label="Órgão emissor"
              fullWidth
              fixedLabel
              error={errors.identidadeOrgaoEmissor}
              helperText={errors.identidadeOrgaoEmissor}
              defaultValue={orgaoEmissorMap.find(
                (oe) =>
                  oe.value === dadosIdentidadeBrasileiro.identidadeOrgaoEmissor,
              )}
              options={orgaoEmissorMap}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              onChange={(_, newValue) => {
                removeError('identidadeOrgaoEmissor');

                setDadosIdentidadeBrasileiro((prevState) => {
                  return {
                    ...prevState,
                    identidadeOrgaoEmissor: newValue ? newValue.id : null,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DAutoComplete
              id="identidadeUF"
              placeholder="UF"
              label="UF"
              fullWidth
              error={errors.identidadeUF}
              helperText={errors.identidadeUF}
              defaultValue={states.find(
                (oe) => oe.id === dadosIdentidadeBrasileiro.identidadeUF,
              )}
              options={states}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              onChange={(_, newValue) => {
                removeError('identidadeUF');
                setDadosIdentidadeBrasileiro((prevState) => {
                  return {
                    ...prevState,
                    identidadeUF: newValue ? newValue.id : null,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="nomeMae"
              label="Nome da mãe"
              placeholder="Nome da mãe"
              disabled={dadosIdentidadeGeral.maeDesconhecida}
              type="text"
              error={errors.nomeMae}
              helperText={errors.nomeMae}
              fullWidth
              onChange={(e) => {
                removeError('nomeMae');
                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomeMae: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomeMae}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                  onClick={(e) => {
                    removeError('nomeMae');

                    setDadosIdentidadeGeral((prevState) => {
                      return {
                        ...prevState,
                        nomeMae: '',
                        maeDesconhecida: e.target.checked,
                      };
                    });
                  }}
                />
              }
              label="Mãe desconhecida"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container className={classes.alignItemsEnd}>
          <Grid item md={12} style={{ paddingTop: 0 }}>
            <DInput
              id="nomePai"
              placeholder="Nome do pai"
              label="Nome do pai"
              disabled={dadosIdentidadeGeral.paiDesconhecido}
              type="text"
              error={errors.nomePai}
              helperText={errors.nomePai}
              fullWidth={true}
              onChange={(e) => {
                setDadosIdentidadeGeral((prevState) => {
                  return {
                    ...prevState,
                    nomePai: e.target.value,
                  };
                });
              }}
              value={dadosIdentidadeGeral.nomePai}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <Grid container>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                onClick={(e) => {
                  setDadosIdentidadeGeral((prevState) => {
                    return {
                      ...prevState,
                      nomePai: '',
                      paiDesconhecido: e.target.checked,
                    };
                  });
                }}
              />
            }
            label="Pai desconhecido"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
