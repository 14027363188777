import axios from 'axios';
import env from 'react-dotenv';

function getAllComorbidities(token) {
  return axios
    .get(`${env.API_URL}/comorbidity/list?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createComorbidity(token, dados) {
  return axios
    .post(`${env.API_URL}/comorbidity?token=${token}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateComorbidity(token, dados) {
  return axios
    .put(
      `${env.API_URL}/comorbidity/${dados.id}?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function putStatusComorbidity(token, dados) {
  return axios
    .patch(
      `${env.API_URL}/comorbidity/${dados.id}?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteComorbidity(token, id) {
  return axios
    .delete(`${env.API_URL}/comorbidity/${id}?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllComorbidities,
  createComorbidity,
  updateComorbidity,
  deleteComorbidity,
  putStatusComorbidity,
};
