import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '20% !important',
  },
}));

const EBTemperature = ({
  id,
  title,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();
  const [input, setInput] = useState({});

  const handleInputChange = (e) => {
    const old_input = { ...input, [e.target.id]: e.target.value };
    setInput(old_input);
    // handleInputSubmit(old_input)
  };

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold mb-2"
            style={{ fontSize: '0.8em' }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${label ? label : 'Temperatura'}`,
              }}
            />
          </Typography>
          <Box className={classes.root}>
            <TextField
              id={id}
              className={classes.textField}
              label={label ? label : ''}
              placeholder={placeholder ? placeholder : ''}
              readOnly={is_read_only === 1 ? true : false}
              required={is_required === 1 ? true : false}
              error={erro[0] ? erro[0].value : ''}
              helperText={erro[0] ? erro[0].value : ''}
              defaultValue={answer ? answer[0] : ''}
              value={input[id]}
              onChange={(e) => {
                if (erro !== null && erro.length > 0) {
                  delete erro[0].value;
                }
                handleInputChange(e);
              }}
              inputProps={{ maxLength: 4, readOnly: readOnly }}
              onBlur={(e) => {
                !readOnly
                  ? handleInputSubmit(input)
                  : console.log('effect onBlur');
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9,.]/g, '');
              }}
              className={classes.textField}
              variant="outlined"
            />
            <span className="text-regular text-info-input">°C</span>
          </Box>
          <span style={{ fontSize: 12 }} className="text-light">
            {helper_text ? helper_text : ''}
          </span>
        </div>
      </div>
    </section>
  );
};

export default EBTemperature;
