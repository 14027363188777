import { NewHealthAttendanceProvider } from 'context/NewHealthAttendance';
import { useEffect, useState } from 'react';
import { getAllPatients } from './../../api/patient';
import { useAuthRoute } from './../../context/AuthRouteContext';
import Paciente from './Paciente';

function PacienteContent(props) {
  const { auth, setAuth } = useAuthRoute();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getAllPatients(localStorage.getItem('token'), 1).then((res) => {
      if (!res.status) setAuth({ ...auth, route: false });
      else setLoaded(true);
    });
  }, [localStorage.getItem('token')]);

  return (
    <NewHealthAttendanceProvider>
      {loaded && <Paciente filteredData={props.filteredData} />}
    </NewHealthAttendanceProvider>
  );
}

export default PacienteContent;
