import React, { useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import List from './components/ListAgenda/List';
import AgendaCreate from './components/AgendaCreate/Index';
import { ConfiguracaoAgendaProvider } from 'context/ConfiguracaoAgendaContext';
import ContextWrapper from 'component/shared/CalendarMonth/context/ContextWrapper';
import CalendarMonth from 'component/shared/CalendarMonth';
import ScheduleItem from 'component/shared/CalendarMonth/components/ScheduleItem';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import ViewItemAgenda from './components/ViewItemAgenda/Index';
import ReagendarCreate from './components/ReagendarCreate/ReagendarCreate';
import { ConfiguracaoAgendaGeralProvider } from 'context/ConfiguracaoAgendaGeralContext';
import ConfiguracaoGeral from 'component/ConfiguracaoAgenda/component/Form/Geral/ConfiguracaoGeral';
import AgendaEdit from './components/AgendaEdit/Index';
import AgendaEditDuplicar from './components/AgendaEditDuplicar/Index';
import AgendaVisualizar from './components/AgendaVisualizar';

const useStyles = makeStyles((theme) => ({
  contentCss: {
    padding: 20,
  },

  backgroundToolbar: {
    background: theme.palette.background.snow,
    overflow: 'auto',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },

  btnItemMenuToolbar: {
    padding: 10,
    margin: 5,
    marginLeft: 25,
  },

  btnVoltar: {
    padding: 10,
    margin: 5,
    marginBottom: 10,
    fontSize: '9pt',
    marginLeft: 25,
  },
}));

export default function AgendaMedico() {
  const classes = useStyles();
  const history = useHistory();
  const [currentContent, setCurrentContent] = useState('lista_agendas');
  const [dataAgenda, setDataAgenda] = useState(null);
  const { dataSheduleItem } = useContext(GlobalContext);

  const handleMenuItem = (itemParam, data) => {
    setCurrentContent(itemParam);
    setDataAgenda(data);
  };

  /**
   *
   * @param {string} scheduleContent currentContent
   * @returns {JSX.Element | null}
   */
  const catchContentWithId = (scheduleContent) => {
    const [op, id] = scheduleContent.split(':');

    switch (op) {
      case 'editar_agenda':
        return (
          <ConfiguracaoAgendaProvider>
            <AgendaEdit scheduleId={id} handleMenuItem={handleMenuItem} />
          </ConfiguracaoAgendaProvider>
        );
      case 'visualizar_agenda':
        return (
          <ConfiguracaoAgendaProvider>
            <ContextWrapper>
              <AgendaVisualizar
                scheduleId={id}
                handleMenuItem={handleMenuItem}
              />
            </ContextWrapper>
          </ConfiguracaoAgendaProvider>
        );
      default:
        return null;
    }
  };

  const renderCurrentContent = () => {
    let contents = {
      lista_agendas: (
        <ConfiguracaoAgendaProvider>
          <List handleMenuItem={handleMenuItem} />
        </ConfiguracaoAgendaProvider>
      ),
      parametros_gerais: (
        <ConfiguracaoAgendaGeralProvider>
          <ConfiguracaoGeral />
        </ConfiguracaoAgendaGeralProvider>
      ),
      create_agenda: (
        <ConfiguracaoAgendaProvider>
          <AgendaCreate onBack={() => handleMenuItem('lista_agendas')} />
        </ConfiguracaoAgendaProvider>
      ),
      calendar: (
        <ContextWrapper>
          <CalendarMonth />
        </ContextWrapper>
      ),
      lista_agendamentos: (
        <ConfiguracaoAgendaProvider>
          <ContextWrapper>
            <ScheduleItem handleMenuItem={handleMenuItem} />
          </ContextWrapper>
        </ConfiguracaoAgendaProvider>
      ),
      view_item_agenda: (
        <ConfiguracaoAgendaProvider>
          <ContextWrapper>
            <ViewItemAgenda handleMenuItem={handleMenuItem} />
          </ContextWrapper>
        </ConfiguracaoAgendaProvider>
      ),
      duplicar_agenda: (
        <ConfiguracaoAgendaProvider>
          <AgendaEditDuplicar
            onBack={() => setCurrentContent('lista_agendas')}
            scheduleId={dataAgenda?.id}
          />
        </ConfiguracaoAgendaProvider>
      ),
      reagendar_create: (
        <ConfiguracaoAgendaProvider>
          <ContextWrapper>
            <ReagendarCreate handleMenuItem={handleMenuItem} />
          </ContextWrapper>
        </ConfiguracaoAgendaProvider>
      ),
    };
    return catchContentWithId(currentContent) || contents[currentContent];
  };

  return (
    <>
      <Grid container>
        <Grid item md={2}>
          <div className={classes.backgroundToolbar}>
            <Grid item md={12}>
              <Button
                className={classes.btnVoltar}
                onClick={() => {
                  if (history.length > 1) {
                    history.goBack();
                  }
                }}
              >
                <ArrowBackIcon />
                &nbsp;Voltar
              </Button>
            </Grid>

            <Grid item md={12}>
              <Button
                className={classes.btnItemMenuToolbar}
                onClick={() => handleMenuItem('lista_agendas')}
              >
                <CalendarTodayIcon />
                &nbsp;Lista de agendas
              </Button>
            </Grid>

            <Grid item md={12}>
              <Button
                className={classes.btnItemMenuToolbar}
                onClick={() => handleMenuItem('parametros_gerais')}
              >
                <SettingsIcon />
                &nbsp;Parâmetros gerais
              </Button>
            </Grid>
          </div>
        </Grid>
        <Grid item md={10} className={classes.contentCss}>
          {renderCurrentContent()}
        </Grid>
      </Grid>
    </>
  );
}
