import env from 'react-dotenv';
import axios from 'axios';

function checkCnpj(token, cnpj, medicalUnitId) {
  return axios
    .get(
      `${env.API_URL}/medicalunit/check-cnpj?cnpj=${cnpj}&medical_unit_id=${medicalUnitId}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function checkCnes(token, cnes, medicalUnitId) {
  return axios
    .get(
      `${env.API_URL}/medicalunit/check-cnes?cnes=${cnes}&medical_unit_id=${medicalUnitId}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getUnidade(token, id) {
  return axios
    .get(`${env.API_URL}/medicalunit/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUnidades(token) {
  return axios
    .get(`${env.API_URL}/medicalunit`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUnidadesExecutantes(token) {
  return axios
    .get(`${env.API_URL}/medicalunit/executantes`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUnidadesArvore(token) {
  return axios
    .get(`${env.API_URL}/medicalunit/arvore/list`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllUnidadesArvoreSearch(token, dados) {
  return axios
    .get(
      `${env.API_URL}/medicalunit/arvore/list?search=${dados.search}&tipo=${dados.tipo}&medical_unit_id=${dados.medical_unit_id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getDominios(token) {
  return axios
    .get(`${env.API_URL}/medicalunit/dominios`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function storeMedicalUnit(token, dados) {
  var resquest = {
    medicalUnit: {
      medical_unit_type: dados.typeMedicalUnit,
      company_name:
        dados.typeMedicalUnit === 'Assistencial'
          ? dados.name
          : dados.companyName,
      establishment_type: dados.establishmentType,
      phone_number: dados.phone,
      email: dados.email,
      below_mother_medical_unit:
        dados.belowMotherMedicalUnit === 'Sim' ? 'yes' : 'no',
      mother_medical_unit:
        dados.belowMotherMedicalUnit === 'Sim' ? 'yes' : 'no',
      mother_medical_unit_id:
        dados.motherMedicalUnit !== 'null' ? dados.motherMedicalUnit : null,
      business_name: dados.businessName,
      cnes: dados.cnes,
      cnpj: dados.cnpj,
      below_social_medical_unit: dados.vinculoOrganizacaoSocial,
      social_medical_unit_id: dados.socialMedicalUnit,
    },
    address: {
      zip_code: dados.cep,
      address_name: dados.addressType,
      street: dados.address,
      number: dados.number,
      complement: dados.complement,
      district: dados.district,
      city_id: dados.cityId,
      state_id: dados.stateId,
    },
    executingMedicalUnitField: [
      {
        description: dados.funcao,
      },
    ],
    medicalUnitProfile: dados.profiles.map(function (item) {
      return { profile_id: item, description: 'N/A' };
    }),
    medicalUnitAnamneseForm:
      dados.anamneses !== 'null'
        ? dados.anamneses.map(function (item) {
            return { anamnese_form_id: item };
          })
        : [],
    executants:
      dados.executingMedicalUnits !== 'null'
        ? dados.executingMedicalUnits
        : null,
  };

  return axios
    .post(`${env.API_URL}/medicalunit`, JSON.stringify(resquest), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateMedicalUnitProfile(token, profiles, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/update-profile`,
      JSON.stringify({ profiles }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateMedicalUnitAnamneses(token, anamneses, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/update-anamnese`,
      JSON.stringify({ anamneses }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateMedicalUnit(token, dados) {
  var resquest = {
    medicalUnit: {
      medical_unit_type: dados.typeMedicalUnit,
      company_name:
        dados.typeMedicalUnit === 'Assistencial'
          ? dados.name
          : dados.companyName,
      establishment_type: dados.establishmentType,
      phone_number: dados.phone,
      email: dados.email,
      business_name: dados.businessName,
      cnes: dados.cnes,
      cnpj: dados.cnpj,
    },
    address: {
      zip_code: dados.cep,
      address_name: dados.addressType,
      street: dados.address,
      number: dados.number,
      complement: dados.complement,
      district: dados.district,
      city_id: dados.cityId,
      state_id: dados.stateId,
    },
  };

  return axios
    .put(`${env.API_URL}/medicalunit/${dados.id}`, JSON.stringify(resquest), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateMedicalUnitLink(token, dados) {
  var resquest = {
    medicalUnit: {
      below_mother_medical_unit:
        dados.belowMotherMedicalUnit === 'Sim' ? 'yes' : 'no',
      mother_medical_unit:
        dados.belowMotherMedicalUnit === 'Sim' ? 'yes' : 'no',
      mother_medical_unit_id:
        dados.motherMedicalUnit !== 'null' ? dados.motherMedicalUnit : null,
      below_social_medical_unit: dados.vinculoOrganizacaoSocial,
      social_medical_unit_id: dados.socialMedicalUnit,
    },
    executingMedicalUnitField: [
      {
        description: dados.funcao,
      },
    ],
    executants:
      dados.executingMedicalUnits !== 'null'
        ? dados.executingMedicalUnits
        : null,
  };

  return axios
    .put(
      `${env.API_URL}/medicalunit/${dados.id}/update-link`,
      JSON.stringify(resquest),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function validateCnes(token, cnes, id = undefined) {
  var url = `/medicalunit/validate-cnes${id !== undefined ? `/${id}` : ''}`;

  return axios
    .post(`${env.API_URL}${url}`, JSON.stringify({ cnes }), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function validateCNPJ(token, cnpj, id = undefined) {
  var url = `/medicalunit/validate-cnpj${id !== undefined ? `/${id}` : ''}`;

  return axios
    .post(`${env.API_URL}${url}`, JSON.stringify({ cnpj }), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteMedicalUnit(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalunit/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getMedicalUnitPermission(token, id) {
  return axios
    .get(`${env.API_URL}/medicalunit/${id}/list-permission`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalUnitPermissionCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/call-enabled`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalUnitPermissionCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalunit/${id}/call-enabled`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalUnitPermissionSequentialCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/sequential-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalUnitPermissionSequentialCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalunit/${id}/sequential-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalUnitPermissionGeneralGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/call-in-general-group?token=${token}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalUnitPermissionGeneralGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalunit/${id}/call-in-general-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalUnitPermissionTotalGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/call-in-total-group`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalUnitPermissionTotalGroup(token, id) {
  return axios
    .delete(
      `${env.API_URL}/medicalunit/${id}/call-in-total-group?token=${token}`,
      {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableMedicalUnitPermissionDirectCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/medicalunit/${id}/direct-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableMedicalUnitPermissionDirectCall(token, id) {
  return axios
    .delete(`${env.API_URL}/medicalunit/${id}/direct-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllUnidades,
  getAllUnidadesExecutantes,
  getAllUnidadesArvore,
  checkCnpj,
  checkCnes,
  getAllUnidadesArvoreSearch,
  getDominios,
  storeMedicalUnit,
  getUnidade,
  deleteMedicalUnit,
  updateMedicalUnit,
  updateMedicalUnitProfile,
  updateMedicalUnitAnamneses,
  updateMedicalUnitLink,
  validateCnes,
  validateCNPJ,
  getMedicalUnitPermission,
  enableMedicalUnitPermissionCall,
  disableMedicalUnitPermissionCall,
  enableMedicalUnitPermissionSequentialCall,
  disableMedicalUnitPermissionSequentialCall,
  enableMedicalUnitPermissionGeneralGroup,
  disableMedicalUnitPermissionGeneralGroup,
  enableMedicalUnitPermissionTotalGroup,
  disableMedicalUnitPermissionTotalGroup,
  enableMedicalUnitPermissionDirectCall,
  disableMedicalUnitPermissionDirectCall,
};
