import env from 'react-dotenv';
import axios from 'axios';

function getList(token) {
  let url = `${env.API_URL}/sendgrid/group`;
  return axios
    .get(url.replace('api/', ''), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

function getTemplateEmail(token, templateId, versionId) {
  let url = `${env.API_URL}/sendgrid/template/${templateId}/${versionId}`;
  return axios
    .get(url.replace('api/', ''), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

function saveTemplateEmail(token, dados) {
  let url = `${env.API_URL}/sendgrid/template`;

  return axios
    .put(url.replace('api/', ''), JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllCategories(token) {
  return getList(token).then((response) => {
    if (response.status) {
      return response.groups.map((group) => {
        const emails = group.templates.map((temp) => {
          return {
            id: temp.id,
            category_id: group.id,
            versionId: temp.versionId,
            title: temp.name,
            body: '',
            signature: '',
            footer: '',
            isLoading: false,
            isToDelete: false,
            variables: [],
          };
        });

        return {
          id: group.id,
          name: group.name,
          isOptionList: null,
          isOpened: false,
          isEdit: false,
          isToDelete: false,
          isLoading: false,
          emails,
        };
      });
    }
  });
}

export { getAllCategories, saveTemplateEmail, getTemplateEmail };
