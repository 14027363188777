import styledComponents from 'styled-components';

export const FilterBarContainer = styledComponents.div`
display: flex;
justify-content: space-between;
flex-direction: row;
margin-bottom: 32px;
align-items: center;
padding: 4px 12px;
border-radius: 9px;
font-size: 14px;

button:not(.filter_reset) {
    padding: 4px 12px;
    display: flex;
    cursor: pointer;
}

span {
    margin-right: 8px;
}

span, svg {
    color: #949494;
}

svg {
    width: 16px;
    height: 16px;
}

button.filter_reset {
    cursor: pointer;
    background-color: ${(props) => (props.primaryColor ? props.primaryColor : '#006FB5')};
    height: 37px;
    width: 100px;
    border-radius: 8px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    
    svg {
        margin-right: 8px;
        color: white;
        
        transform: rotate(-120deg);
        width: 20px;
        height: 20px;
    }
}
`;
