import env from 'react-dotenv';
import axios from 'axios';

function checkPatientDocument(documentValue, token) {
  return axios
    .get(
      `${env.API_URL}/document/patient/check?documentValue=${documentValue}&token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function checkUserDocument(documentValue, token) {
  return axios
    .get(
      `${env.API_URL}/document/user/check?documentValue=${documentValue}&token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { checkPatientDocument, checkUserDocument };
