import React, { Fragment, useContext, useEffect, useState } from 'react';
import CalendarHeader from './components/CalendarHeader';
import Month from './components/Month';
import { getMonth } from './util';
import GlobalContext from './context/GlobalContext';
import { useConfiguracaoAgenda } from '../../../context/ConfiguracaoAgendaContext';
import ScheduleItem from './components/ScheduleItem';
import ScheduleItemBlock from './components/ScheduleItemBlock';
import DaysAndHours from './components/DaysAndHours';
import ModalHorarioBloqueio from './components/Modais/ModalHorarioBloqueio';
import BloqueioAgendaModal from 'component/AgendaMedico/components/Modais/BloqueioAgendaModal';
import ModalDesbloquearAgenda from './components/Modais/ModalDesbloquearAgenda';

export default function CalendarMonth(props) {
  const [currentMonth, setCurrentMonth] = useState(getMonth);
  const {
    monthIndex,
    openScheduleOfTheDay,
    openModalBloqueio,
    setOpenModalBloqueio,
    openModalBloqueioDia,
    setOpenModalBloqueioDia,
    setOpenModalDesbloqueio,
    openModalDesbloqueio,
    dayBlock,
  } = useContext(GlobalContext);
  const [lastMIndex, setLastMIndex] = useState(monthIndex);

  const { setIsExcluir } = useConfiguracaoAgenda();

  useEffect(() => {
    const monthDays = getMonth(monthIndex);
    setLastMIndex(monthIndex);

    setCurrentMonth(monthDays);
  }, [monthIndex]);

  return (
    <Fragment>
      <div>
        <CalendarHeader onGerenciarBloqueios={props.onGerenciarBloqueios} />
        <div>
          <Month
            month={currentMonth}
            handleClickDay={props.handleClickDia}
            horariosAgenda={props.horariosAgenda}
          />

          <ModalHorarioBloqueio
            GerenciarAgendamentos={props.GerenciarAgendamentos}
            open={openModalBloqueio}
            value={'shedule'}
            onSubmit={(e) => {
              setOpenModalBloqueio(false);
            }}
            onClose={() => setOpenModalBloqueio(false)}
            handleMenuItem={(e) => props.handleMenuItem(e)}
          />

          <ModalDesbloquearAgenda
            open={openModalDesbloqueio}
            onClose={(desbloquear) => {
              if (
                desbloquear &&
                openScheduleOfTheDay &&
                openScheduleOfTheDay.day &&
                dayBlock &&
                dayBlock.find((x) => x.day == openScheduleOfTheDay.day)
              ) {
                props.unlockSchedule(openScheduleOfTheDay.day);
              } else {
                setOpenModalDesbloqueio(false);
              }
            }}
          />

          {openModalBloqueioDia && (
            <BloqueioAgendaModal
              open={openModalBloqueioDia}
              onBloquear={(e) => {
                setIsExcluir(false);
                props.onBloquearDia(e);
                setOpenModalBloqueioDia(false);
              }}
              onClose={() => setOpenModalBloqueioDia(false)}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

CalendarMonth.defaultProps = {
  handleClickDia: (e) => {
    console.log('handleClickDia', e);
  },
  onGerenciarBloqueios: (e) => {
    console.log('onGerenciarBloqueios', e);
  },
  onBloquearDia: (e) => {
    console.log('onBloquearDia', e);
  },
  handleMenuItem: () => {
    console.log('handleMenuItem');
  },
  unlockSchedule: () => {
    console.log('unlockSchedule');
  },
  handleGerenciarAgendamentos: () => {
    console.log('handleGerenciarAgendamentos');
  },
};
