function requiredEmail(valor) {
  if (valor == '' || valor === undefined || !valor) {
    return false;
  }

  const regexEmailValidation =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  //Vai me retornar um booleano, caso esteja errado motra erro
  if (regexEmailValidation.test(valor)) {
    return true;
  } else {
    return false;
  }
}

function validaTel(tel) {
  if (
    !tel ||
    tel.length > 15 ||
    tel.length < 14 ||
    tel == '(00) 00000-0000' ||
    tel == '(11) 11111-1111' ||
    tel == '(22) 22222-2222' ||
    tel == '(33) 33333-3333' ||
    tel == '(44) 44444-4444' ||
    tel == '(55) 55555-5555' ||
    tel == '(66) 66666-6666' ||
    tel == '(77) 77777-7777' ||
    tel == '(88) 88888-8888' ||
    tel == '(99) 99999-9999'
  ) {
    return false;
  } else {
    return true;
  }
}

export { requiredEmail, validaTel };
