import env from 'react-dotenv';
import axios from 'axios';

function getAllTypes(token) {
  return axios
    .get(`${env.API_URL}/healthAttendanceType`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAll(token) {
  return axios
    .get(`${env.API_URL}/healthAttendanceType/all`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createType(token, dados) {
  return axios
    .post(`${env.API_URL}/healthAttendanceType`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateType(token, dados, id) {
  return axios
    .put(`${env.API_URL}/healthAttendanceType/${id}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteType(token, id) {
  return axios
    .delete(`${env.API_URL}/healthAttendanceType/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getAll, getAllTypes, createType, updateType, deleteType };

export default { getAllTypes, createType, updateType, deleteType };
