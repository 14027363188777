import { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { useContionals } from '../../../../../context/ConditionalsContext';
import AddFormCondition from './AddFormCondition';
import AddTrueFormCondition from './AddTrueFormCondition';
import AddFalseFormCondition from './AddFalseFormCondition';
import DButton from '../../../../shared/DButton';
import Save from '@material-ui/icons/Save';
import Construtor from '../../../../../api/construtor';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentDiv: {
    border: `dashed ${theme.palette.neutral.midgrey} 2px`,
    padding: '15px 15px 25px 15px',
    textAlign: 'center',
    borderRadius: 5,
  },
}));

function FormsConditionContent(props) {
  const classes = useStyles();
  const {
    conditionals,
    setConditionals,
    forms,
    fields,
    loading,
    setPendingSave,
  } = useContionals();
  const [formConditionalsCondition, setFormConditionalsCondition] = useState(
    [],
  );
  const [formConditionalsActionTrue, setFormConditionalsActionTrue] = useState(
    [],
  );
  const [formConditionalsActionFalse, setFormConditionalsActionFalse] =
    useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState([]);
  const [errorActionTrue, setErrorActionTrue] = useState([]);
  const [errorActionFalse, setErrorActionFalse] = useState([]);

  useEffect(
    function () {
      setFormConditionalsActionTrue([
        {
          id: null,
          action: null,
          type: true,
          serviceCharacter: null,
          field: null,
          form: null,
        },
      ]);
      setFormConditionalsActionFalse([
        {
          id: null,
          action: null,
          type: false,
          serviceCharacter: null,
          field: null,
          form: null,
        },
      ]);
      setFormConditionalsCondition([
        {
          id: null,
          field_id: null,
          operator: null,
          compare_field_id: null,
          contain: [],
          compareOptions: [],
        },
      ]);

      if (conditionals !== undefined) {
        if (conditionals.form_conditionals_action.length > 0) {
          setFormConditionalsActionTrue(
            conditionals.form_conditionals_action.filter((res) => res.type),
          );
          setFormConditionalsActionFalse(
            conditionals.form_conditionals_action.filter((res) => !res.type),
          );
        }

        if (conditionals.form_conditionals_condition.length > 0)
          setFormConditionalsCondition(
            conditionals.form_conditionals_condition,
          );
      }
    },
    [conditionals],
  );

  useEffect(() => {
    setError([]);
    setErrorActionTrue([]);
    setErrorActionFalse([]);
  }, []);

  function saveConditions() {
    setLoadingSave(true);
    setPendingSave(false);

    var body = {
      form_conditionals_condition: formConditionalsCondition.filter(
        (res) => res.operator !== null,
      ),
      form_conditionals_action: formConditionalsActionTrue
        .filter((res) => res.action !== null)
        .concat(
          formConditionalsActionFalse.filter((res) => res.action !== null),
        ),
    };

    var error_field = false;
    var new_error = [...error];
    var new_error_action_true = [...errorActionTrue];
    var new_error_action_false = [...errorActionFalse];

    new_error_action_false = formConditionalsActionFalse.map((res, index) => {
      return {
        action: null,
        type: false,
        service_character: null,
        field: null,
        form: null,
      };
    });

    new_error_action_true = formConditionalsActionTrue.map((res, index) => {
      return {
        action: null,
        type: true,
        service_character: null,
        field: null,
        form: null,
      };
    });

    new_error = formConditionalsCondition.map((res, index) => {
      return {
        fieldId: null,
        operator: null,
        compareFieldId: null,
        contain: [],
        compareOptions: [],
      };
    });

    formConditionalsCondition.map((res, index) => {
      if (!res.field_id) {
        new_error[index] = {
          ...new_error[index],
          fieldId: 'Campo obrigatório',
        };
        error_field = true;
      } else {
        new_error[index] = { ...new_error[index], fieldId: null };
      }

      if (!res.operator) {
        new_error[index] = {
          ...new_error[index],
          operator: 'Campo obrigatório',
        };
        error_field = true;
      } else {
        new_error[index] = { ...new_error[index], operator: null };

        switch (res.operator) {
          case 'diferente':
          case 'igual':
            if (!res.contain) {
              error_field = true;
              new_error[index] = {
                ...new_error[index],
                contain: 'Campo obrigatório',
              };
            } else new_error[index] = { ...new_error[index], contain: null };
            break;
          case 'contem':
          case 'nao_contem':
            if (!res.contain) {
              error_field = true;
              new_error[index] = {
                ...new_error[index],
                contain: 'Campo obrigatório',
              };
            } else new_error[index] = { ...new_error[index], contain: null };
            break;
          default:
            new_error[index] = {
              ...new_error[index],
              contain: null,
              compareFieldId: null,
            };
            break;
        }
      }

      setError(new_error);
    });

    formConditionalsActionTrue.map((res, index) => {
      if (res.action) {
        new_error_action_true[index] = {
          ...new_error_action_true[index],
          action: null,
        };

        switch (res.action) {
          case 'exibir':
          case 'esconder':
            if (!res.field_id) {
              error_field = true;
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                field: 'Campo obrigatório',
              };
            } else
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                field: null,
              };
            break;
          case 'enviar_para':
            if (!res.form_id) {
              error_field = true;
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                form: 'Campo obrigatório',
              };
            } else
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                form: null,
              };

            if (!res.service_character) {
              error_field = true;
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                serviceCharacter: 'Campo obrigatório',
              };
            } else
              new_error_action_true[index] = {
                ...new_error_action_true[index],
                serviceCharacter: null,
              };
            break;
          default:
            new_error_action_true[index] = {
              ...new_error_action_true[index],
              form: null,
              field: null,
              serviceCharacter: null,
            };
            break;
        }
      }

      setErrorActionTrue(new_error_action_true);
    });

    formConditionalsActionFalse.map((res, index) => {
      if (res.action) {
        new_error_action_false[index] = {
          ...new_error_action_false[index],
          action: null,
        };

        switch (res.action) {
          case 'exibir':
          case 'esconder':
            if (!res.field_id) {
              error_field = true;
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                field: 'Campo obrigatório',
              };
            } else
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                field: null,
              };
            break;
          case 'enviar_para':
            if (!res.form_id) {
              error_field = true;
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                form: 'Campo obrigatório',
              };
            } else
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                form: null,
              };

            if (!res.service_character) {
              error_field = true;
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                serviceCharacter: 'Campo obrigatório',
              };
            } else
              new_error_action_false[index] = {
                ...new_error_action_false[index],
                serviceCharacter: null,
              };
            break;
          default:
            new_error_action_false[index] = {
              ...new_error_action_false[index],
              form: null,
              field: null,
              serviceCharacter: null,
            };
            break;
        }
      }

      setErrorActionFalse(new_error_action_false);
    });

    if (!error_field) {
      Construtor.addConditionFull(
        localStorage.getItem('token'),
        conditionals.id,
        body,
      )
        .then((res) => {
          if (res.status) {
            setConditionals({ ...body, id: conditionals.id });
            toast.success(res.message);
          } else toast.error(res.message);

          setLoadingSave(false);
        })
        .catch((err) => {
          console.log(err, 'err');
          toast.error('Erro inesperado');
          setLoadingSave(false);
        });
    } else {
      toast.error('Preencha os campos obrigatórios.');
      setLoadingSave(false);
    }
  }

  return (
    <>
      {conditionals !== undefined && forms.length > 0 && fields.length > 0 ? (
        <>
          <AddFormCondition
            error={error}
            loading={loadingSave}
            conditions={{
              formConditionalsCondition,
              setFormConditionalsCondition,
            }}
          />
          <AddTrueFormCondition
            error={errorActionTrue}
            loading={loadingSave}
            actions={{
              formConditionalsActionTrue,
              setFormConditionalsActionTrue,
            }}
          />
          <AddFalseFormCondition
            error={errorActionFalse}
            loading={loadingSave}
            actions={{
              formConditionalsActionFalse,
              setFormConditionalsActionFalse,
            }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <DButton
                disabled={loadingSave}
                onClick={saveConditions}
                size="sm"
                type="button"
                variant="primary"
                icon={
                  loadingSave ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Save size="sm" />
                  )
                }
              >
                {loadingSave ? 'Salvando' : 'Salvar condicional'}
              </DButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className={classes.contentDiv}>
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <Typography
                style={{ marginTop: 15, marginBottom: 24 }}
                variant="h4"
              >
                Selecione uma regra cadastrada
              </Typography>
              <Typography variant="body1" className="text-regular text-st">
                Ou se preferir, adicione uma nova condicional
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default FormsConditionContent;
