import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import { Grid, Typography } from '@material-ui/core';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    '& $line': {
      backgroundColor: '#C7C7C7',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#C7C7C7',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#C7C7C7',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    border: 'solid 2px #949494',
    zIndex: 1,
    color: '#949494',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: '#FFF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#4ABA78',
    borderColor: '#4ABA78',
    color: '#FFF',
  },
}));

function StepLine(props) {
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PersonIcon />,
      2: <DescriptionIcon />,
      3: <PersonIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  function getSteps() {
    return ['Dados pessoais', 'Currículo', 'Perfil'];
  }

  const steps = getSteps();

  return (
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <Stepper
          alternativeLabel
          activeStep={props.step}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  className="text-mt text-regular"
                  variant="body1"
                  gutterBottom
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}

export default StepLine;
