import axios from 'axios';
import env from 'react-dotenv';

/**
 * @param {Object.<string, string>} params
 * @returns {Array.<{key: string, value: string}>}
 * @description Função que retorna os parâmetros da url em chave e valor
 */
function getParams(params) {
  if (typeof params === 'object') {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

/**
 * @param {string} token
 * @param {object} params
 * @param {string?} params.search
 * @param {string?} params.grupo_id "Pesquisa por id do grupo de formulário"
 * @description Filtra as agendas de um profissional.
 **/
export async function getHealthAttendanceFilter(token, params) {
  const uri = new URL(`${env.API_URL}/healthAttendance/filter`);

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  const response = await axios.post(
    uri,
    {},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    },
  );
  return response.data;
}

/**
 * @param {string} token
 * @param {object} params
 * @description Filtra os atendimentos de acordo com filtros.
 **/
export async function getHealthAttendance(token, params) {
  const uri = new URL(`${env.API_URL}/healthAttendance`);

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  const response = await axios.get(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': token,
    },
  });
  return response.data;
}

export function createHealthAttendance(token, dados) {
  return axios
    .post(`${env.API_URL}/healthAttendance`, dados, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function listHealthAttendanceById(token, id) {
  return axios
    .get(`${env.API_URL}/healthAttendance/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getHealthAttendancePDF(token, id) {
  return fetch(
    `${env.API_URL}/healthAttendance/${id}/health-attendance-consultation-form-pdf`,
    {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    },
  ).then((res) => res.arrayBuffer());
}

export function getAttendanceBundlePDF(token, id) {
  return fetch(
    `${env.API_URL_SERVICE}/attendancebundle/pdf/${id}?token=${token}`,
    {
      method: 'get',
    },
  ).then((res) => res.arrayBuffer());
}

export function getHealthAttendanceObservation(token, healthAttendanceId) {
  return axios
    .get(
      `${env.API_URL}/health-attendance-observation/health-attendance/${healthAttendanceId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function createHealthAttendanceObservation(
  token,
  observation,
  userId,
  healthAttendanceId,
) {
  return axios
    .post(
      `${env.API_URL}/health-attendance-observation`,
      {
        user_id: userId,
        observation: observation,
        health_attendance_id: healthAttendanceId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function updateHealthAttendanceObservation(
  token,
  observation,
  observationId,
  health_attendance_id,
  userId,
) {
  return axios
    .put(
      `${env.API_URL}/health-attendance-observation/${observationId}`,
      {
        health_attendance_id,
        user_id: userId,
        observation,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function listHealthAttendanceFiles(token, healthAttendanceId) {
  return axios
    .get(
      `${env.API_URL}/health-attendance-file/health-attendance/${healthAttendanceId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function listHealthAttendanceFilesTypePatient(
  token,
  healthAttendanceId,
) {
  return axios
    .get(
      `${env.API_URL}/health-attendance-file/health-attendance/${healthAttendanceId}/typePatient`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function updateAttendanceStatus(
  token,
  healthAttendanceId,
  { active, reason },
) {
  return axios
    .put(
      `${env.API_URL}/healthAttendance/${healthAttendanceId}/health-attendance-status`,
      {
        active,
        reason,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function updateHealthAttendance(token, healthAttendanceId, body) {
  return axios
    .put(`${env.API_URL}/healthAttendance/${healthAttendanceId}`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function uploadHealthAttendanceFile(token, file, healthAttendanceId) {
  var body = new FormData();

  body.append('file', file);
  body.append('health_attendance_id', healthAttendanceId);

  return axios
    .post(`${env.API_URL}/health-attendance-file`, body, {
      method: 'POST',
      headers: {
        'x-domain': env.X_DOMAIN,
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export function getHealthAttendanceFile(token, fileId) {
  return axios
    .get(`${env.API_URL}/health-attendance-file/${fileId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function showHealthAttendancePdf(token, healthAttendanceId) {
  return axios
    .get(
      `${env.API_URL}/healthAttendance/${healthAttendanceId}/health-attendance-consultation-form-pdf`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export function history(token, healthAttendanceId) {
  return axios
    .get(`${env.API_URL}/healthAttendance/history/${healthAttendanceId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function allergysByPatient(token, patientId) {
  return axios
    .get(`${env.API_URL}/anamnese-pre-form/${patientId}/allergys`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function healthAttendanceByPatient(token, patientId) {
  return axios
    .get(
      `${env.API_URL}/healthAttendance/health-attendance/${patientId}/patient`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data);
}

export default {
  createHealthAttendance,
  listHealthAttendanceById,
  getHealthAttendancePDF,
  getHealthAttendanceObservation,
  createHealthAttendanceObservation,
  updateHealthAttendanceObservation,
  listHealthAttendanceFiles,
  uploadHealthAttendanceFile,
  history,
  getHealthAttendanceFilter,
  listHealthAttendanceFilesTypePatient,
  getAttendanceBundlePDF,
};
