import { Grid } from '@material-ui/core';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DMultAutoComplete from '../../../../../../../shared/DMultAutoComplete';
import { useStyles } from './styles';

interface IUnitsProps {
  errors: {
    unidadeRef?: string;
    unidadeVinculada?: string;
  };
  removeError: (key: string) => void;
  unidadeVinculada: {
    id: number;
    value?: string;
  }[];
  setUnidadeVinculada: (
    unidadeVinculada: {
      id: number;
      value: string | undefined;
    }[],
  ) => void;
  unidadesVinculadas: {
    id: number;
    value?: string;
  }[];
  unidadeRefs: {
    id: number;
    value: string;
  }[];
  unidadeRef?: number;
  setUnidadeRef: (unidadeRef: number) => void;
}

export const Units = ({
  errors,
  removeError,
  unidadeVinculada,
  setUnidadeVinculada,
  unidadesVinculadas,
  unidadeRefs,
  unidadeRef,
  setUnidadeRef,
}: IUnitsProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} className={classes.alignItemsEnd}>
        <Grid item md={6} style={{ paddingTop: 0 }}>
          <DAutoComplete
            id="unidadeRef"
            placeholder="Unidade de referência"
            label="Unidade de referência"
            fullWidth={true}
            error={errors.unidadeRef}
            helperText={errors.unidadeRef}
            defaultValue={unidadeRefs.filter((res) => res.id === unidadeRef)[0]}
            options={unidadeRefs}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) =>
              value && option.id === value.id
            }
            onChange={(_, newValue) => {
              removeError('unidadeRef');
              setUnidadeRef(newValue ? newValue.id : null);
            }}
          />
        </Grid>

        <Grid item md={6} style={{ paddingTop: 0 }}>
          <DMultAutoComplete
            id="unidadeVinculada"
            placeholder="Unidades vinculadas ao paciente"
            label="Unidades vinculadas ao paciente"
            error={errors.unidadeVinculada}
            helperText={errors.unidadeVinculada}
            defaultValue={unidadeVinculada}
            options={unidadesVinculadas}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) =>
              value && option.id === value.id
            }
            onChange={(_, newValue) => {
              removeError('unidadeVinculada');
              setUnidadeVinculada(newValue);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
