import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import { getUnidade } from 'api/unidades';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { userDominios } from '../api/dominios';
import { listNotification } from '../api/notificationUser';
import {
  fetchCurrentUserDetails,
  getMedicalUnitesById,
  getProfileId,
  getUserAvatar,
} from '../api/user';

export const AppContext = createContext({});

export default function AppContextProvider({ children }) {
  var history = useHistory();

  const [userInfo, setUserInfo] = useState({
    perfil: '', // Id
    unidade: '', // Id
  });

  const [avatar, setAvatar] = useState(null);
  const [userId, setUserId] = useState(false);
  const [dominios, setDominios] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileInfo, setProfileInfo] = useState([]);
  const [interconsultationInvite, setInterconsultationInvite] = useState(null);

  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [UnreadNotifications, setUnreadNotifications] = useState(0);
  const [emailLogin, setEmailLogin] = useState('');
  const [currentMedicalUnit, setCurrentMedicalUnit] = useState(null);

  const [unitChangedInConfiguration, setUnitChangedInConfiguration] =
    useState(false);

  const [lockAdministrativeUnitMenu, setLockAdministrativeUnitMenu] =
    useState(false);

  const [attendancesList, setAttendancesList] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);

  const [AttendanceFilter, setAttendanceFilter] = useState({
    // Está no Context geral pois é utilizado na sidebar.
    status: {
      todos: false,
      assumido: false,
      aberto: false,
      finalizado: false,
    },
    quadro: {
      todos: false,
      leve: false,
      moderado: false,
      grave: false,
      nao_indicativo: false,
    },
    linhaDeCuidado: {},
    periodo: {
      from: null,
      to: null,
    },
  });

  const [totais, setTotais] = useState({
    status: {
      todos: 0,
      assumido: 0,
      aberto: 0,
      finalizado: 0,
    },
    quadro: {
      todos: 0,
      leve: 0,
      moderado: 0,
      grave: 0,
      nao_indicativo: 0,
    },
  });

  const [updateAcl, setUpdateAcl] = useState(false);

  const [loading, setLoading] = useState();

  const hideLoading = () => setLoading(false);

  const showLoading = (message = true) => setLoading(message);

  /**
   *
   * @param {Promise<any>} promise
   */
  const promise = async (promise) => {
    showLoading('Carregando...');
    try {
      try {
        return await promise;
      } finally {
        hideLoading();
      }
    } catch (error) {
      hideLoading();
      return error;
    }
  };

  const LoadingControl = {
    hideLoading,
    showLoading,
    promise,
    isLoading: loading,
  };

  useEffect(() => {
    // Get user notifications
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('scope') === 'acl'
    ) {
      fetchCurrentUserDetails(localStorage.getItem('token'))
        .then((x) => {
          if (x.id !== undefined) {
            setUserId(x.id);
            setEmailLogin(x.email);
          }

          listNotification(localStorage.getItem('token'), 1 || x.id)
            .then((data) => {
              if (data && data.status) {
                setNotifications(data.profiles.data);
                if (data.profiles && data.profiles.data) {
                  var unreadNotification = 0;

                  data.profiles.data.forEach((notification) => {
                    if (!notification.read) {
                      unreadNotification++;
                    }
                  });

                  setUnreadNotifications(unreadNotification);
                }
              }
            })
            .catch(console.error);
        })
        .catch(console.error);

      updateUserAvatar();
    }
  }, [localStorage.getItem('token')]);

  function updateUserAvatar() {
    getUserAvatar(localStorage.getItem('token'))
      .then((data) => {
        if (data.status) {
          setAvatar(data.avatar);
        }
      })
      .catch((err) => console.error(err));
  }

  function logoutUser() {
    localStorage.removeItem('token');
    history.push('/');
    window.location.reload();
  }

  async function newHandleProgfiles() {
    try {
      console.log('newHandleProgfiles');

      var filteredProfilesById = [];

      const profiles = await getProfileId(localStorage.getItem('token'));

      if (profiles && Array.isArray(profiles)) {
        filteredProfilesById = profiles.filter(
          (v, i, a) => a.findIndex((t) => t.profile_id === v.profile_id) === i,
        );

        filteredProfilesById.forEach(async (profile, index) => {
          await getMedicalUnitsFromProfileSameProfile(profile, index);
        });
      } else {
        throw new Error('Não foi possível obter os perfis');
      }
    } catch (err) {
      console.error('AppContext => handleGetProfiles', err.message || err);
    }
  }

  async function handleGetProfiles() {
    if (Array.isArray(profileInfo) && profileInfo.length > 0) return;

    try {
      const profiles = await getProfileId(localStorage.getItem('token'));

      if (profiles && Array.isArray(profiles)) {
        var filteredProfilesById = profiles.filter(
          (v, i, a) => a.findIndex((t) => t.profile_id === v.profile_id) === i,
        );

        filteredProfilesById.forEach(async (profile, index) => {
          await getMedicalUnitsFromProfile(profile, index);
        });
      } else {
        throw new Error('Não foi possível obter os perfis');
      }
    } catch (err) {
      console.error('AppContext => handleGetProfiles', err.message || err);
    }
  }

  async function getUserDominios() {
    if (dominios) return dominios;

    try {
      const data = await userDominios();

      if (data.status) {
        setDominios(data);
        return data;
      }
    } catch (error) {
      console.error('getUserDominios error:' + error);
      return null;
    }
  }

  async function getMedicalUnitsFromProfile(profile, index) {
    const { profile_id } = profile;

    try {
      const { medical_units } = await getMedicalUnitesById(
        localStorage.getItem('token'),
        profile_id,
      );

      var units = Array.isArray(medical_units)
        ? medical_units
        : typeof medical_units === 'object'
          ? Object.values(medical_units)
          : [medical_units];

      setUpdateAcl(false);

      setProfileInfo((prevState) => {
        if (prevState && Array.isArray(prevState)) {
          if (prevState.find((x) => x.profile_id == profile_id)) {
            return prevState;
          }
        }

        if (index == 0) {
          setSelectedProfile({
            profile_id,
            description: profile.description,
            units: units || [],
          });

          let _medical_unit_id =
            Array.isArray(units) && units.length
              ? units[0].medical_unit_id
              : null;

          setUserInfo({
            perfil: profile_id,
            unidade: _medical_unit_id ? _medical_unit_id : '',
          });
        }

        setUpdateAcl(true);

        return [
          ...prevState,
          {
            profile_id: profile_id,
            description: profile.description,
            units: units || [],
          },
        ];
      });
    } catch (err) {
      console.error(
        'AppContext => getMedicalUnitsFromProfile',
        err.message || err,
      );
    }
  }

  async function getMedicalUnitsFromProfileSameProfile(profile, index) {
    const { profile_id } = profile;

    try {
      const { medical_units } = await getMedicalUnitesById(
        localStorage.getItem('token'),
        profile_id,
      );

      var units = Array.isArray(medical_units)
        ? medical_units
        : typeof medical_units === 'object'
          ? Object.values(medical_units)
          : [medical_units];

      setProfileInfo((prevState) => {
        if (prevState && Array.isArray(prevState)) {
          if (prevState.find((x) => x.profile_id == profile_id)) {
            return prevState;
          }
        }

        // if(index == 0){
        //   setSelectedProfile({
        //     profile_id,
        //     description: profile.description,
        //     units: units || []
        //   });

        //   let _medical_unit_id = (Array.isArray(units) && units.length) ? units[0].medical_unit_id : null;

        //   setUserInfo({
        //     perfil: profile_id,
        //     unidade: _medical_unit_id ? _medical_unit_id : ""
        //   })
        // }

        return [
          ...prevState,
          {
            profile_id: profile_id,
            description: profile.description,
            units: units || [],
          },
        ];
      });
    } catch (err) {
      console.error(
        'AppContext => getMedicalUnitsFromProfile',
        err.message || err,
      );
    }
  }

  useEffect(() => {
    if (userInfo.unidade) {
      getUnidade(localStorage.getItem('token'), userInfo.unidade)
        .then((res) => {
          // Assistencial ou Administrativa
          setCurrentMedicalUnit(res.details?.medicalUnit || null);
          checkUrlUnitFunction(res.details?.medicalUnit || null);
        })
        .catch(console.log);
    }
  }, [userInfo.unidade]);

  const checkUrlUnitFunction = (medicalUnit) => {
    if (medicalUnit !== null) {
      const path = window.location.pathname.split('/');
      const clearedPath = path.filter((a) => a);

      return clearedPath[0]
        ? redirectUnitFunction(medicalUnit.funcao, clearedPath[0])
        : false;
    }
  };

  const redirectUnitFunction = (medicalUnitFunction, path) => {
    if (medicalUnitFunction == 'Executante' && path == 'solicitacao') {
      window.location = '/meu-perfil';
    } else if (
      medicalUnitFunction == 'Solicitante' &&
      path == 'atendimento-imediato'
    ) {
      window.location = '/meu-perfil';
    }
  };

  return (
    <AppContext.Provider
      value={{
        userId,
        userInfo,
        setUserInfo,
        getUserDominios,
        dominios,
        selectedProfile,
        setSelectedProfile,
        profileInfo,
        setProfileInfo,
        logoutUser,
        setAttendanceFilter,
        AttendanceFilter,
        attendancesList,
        setAttendancesList,
        handleGetProfiles,
        newHandleProgfiles,
        openNotifications,
        setOpenNotifications,
        interconsultationInvite,
        setInterconsultationInvite,
        setNotifications,
        notifications,
        UnreadNotifications,
        emailLogin,
        setEmailLogin,
        setLockAdministrativeUnitMenu,
        lockAdministrativeUnitMenu,
        updateAcl,
        setUpdateAcl,
        avatar,
        setAvatar,
        updateUserAvatar,
        unitChangedInConfiguration,
        setUnitChangedInConfiguration,
        totais,
        setTotais,
        applyFilter,
        setApplyFilter,
        LoadingControl,
        currentMedicalUnit,
      }}
    >
      {children}
      {loading ? (
        <Backdrop
          style={{ zIndex: 3000, color: 'white', textAlign: 'center' }}
          open={Boolean(loading)}
        >
          <Grid container>
            <Grid item md={12}>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1" className="text-regular text-st">
                {typeof loading === 'string' ? loading : 'Carregando...'}
              </Typography>
            </Grid>
          </Grid>
        </Backdrop>
      ) : null}
    </AppContext.Provider>
  );
}

export const useAppProvider = () => useContext(AppContext);
