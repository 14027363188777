import env from 'react-dotenv';
import axios from 'axios';

export function acceptTermUse(termId, userId) {
  return axios
    .post(
      `${env.API_URL_SERVICE}/terms/accept-term`,
      {
        term_id: termId,
        user_id: userId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': localStorage.getItem('token'),
        },
      },
    )
    .then((res) => res.data);
}

export function denyTermUse(termId, userId) {
  return axios
    .post(
      `${env.API_URL_SERVICE}/terms/deny-term`,
      {
        term_id: termId,
        user_id: userId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': localStorage.getItem('token'),
        },
      },
    )
    .then((res) => res.data);
}

export function getTermActive(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/terms/get-term-active`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': localStorage.getItem('token'),
      },
    })
    .then((res) => res.data);
}
