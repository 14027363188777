import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Backdrop,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

// Import Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DInput from '../shared/DInput';

import {
  getAllGroups,
  createGroup,
  updateGroup,
  deleteGroup,
} from '../../api/groups';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@material-ui/icons';
import DButton from '../shared/DButton';

const styles = (theme) => ({
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
  },
  addTagButtonDisabled: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
    opacity: 0.5,
  },
  deleteButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  cancelarButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
    marginRight: '43px',
  },
  saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
});

class Grupos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMedicalUnits: true,
      groups: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      loadingGroups: false,
      isEdit: false,
      isAdd: false,
      oldGroup: '',
      error: [],
      delete: false,
      rowSelected: '',
      indexSelected: '',
    };
  }

  componentDidMount() {
    this.listGroups();
  }

  listGroups() {
    this.setState({ ...this.state, loadingGroups: true });

    getAllGroups(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.group.map((row) => {
          var new_state = this.state.groups;
          new_state.push({
            id: row.id,
            name: row.name,
            isEdit: false,
            isLoading: false,
          });
          this.setState({
            ...this.state,
            groups: new_state,
            loadingGroups: false,
          });
        });
      } else {
        this.setState({ ...this.state, groups: [], loadingGroups: false });
      }
    });
  }

  saveGroup(group, index) {
    if (group.name.trim() == '') {
      var new_state = this.state.error;
      new_state.push({ index: index, erro: 'Campo obrigatório' });
      this.setState({ ...this.state, error: new_state });
    } else {
      var new_state = this.state.groups;
      new_state[index].isLoading = !group.isLoading;
      this.setState({ ...this.state, groups: new_state });

      if (group.id) {
        updateGroup(localStorage.getItem('token'), group).then((res) => {
          if (res.status) {
            new_state[index].isLoading = !group.isLoading;
            new_state[index].isEdit = !group.isEdit;
            this.setState({ ...this.state, groups: new_state });

            toast.success('Grupo alterado com sucesso!');
          } else {
            if (res.status == false) {
              if (res.message == 'já cadastrado no sistema') {
                var new_error = this.state.error;
                new_error.push({
                  index: index,
                  erro: `${group.name} já cadastrado no sistema`,
                });
                this.setState({ ...this.state, error: new_error });
                toast.error(`${group.name} já cadastrado no sistema`);
              } else {
                if (res?.status_code != 401)
                  toast.error('Ocorreu um erro atualizar o grupo.');
              }
            }

            new_state[index].isLoading = !group.isLoading;
            this.setState({ ...this.state, groups: new_state });
          }
        });
      } else {
        createGroup(localStorage.getItem('token'), group).then((res) => {
          if (res.status) {
            new_state[index].id = res.group.id;
            new_state[index].isLoading = !group.isLoading;
            new_state[index].isEdit = !group.isEdit;
            this.setState({ ...this.state, groups: new_state });

            toast.success('Grupo cadastrado com sucesso!');
          } else {
            if (res.status == false) {
              if (res.message == 'já cadastrado no sistema') {
                var new_error = this.state.error;
                new_error.push({
                  index: index,
                  erro: `${group.name} já cadastrado no sistema`,
                });
                this.setState({ ...this.state, error: new_error });
                toast.error(`${group.name} já cadastrado no sistema`);
              } else {
                if (res?.error && res.error.includes('Duplicate entry')) {
                  toast.error('Grupo já cadastrado no sistema');
                } else if (res?.status_code != 401) {
                  toast.error('Ocorreu um erro cadastrar o grupo.');
                }
              }
            }

            new_state[index].isLoading = !group.isLoading;
            this.setState({ ...this.state, groups: new_state });
          }
        });
      }
    }
  }

  confirmDelete(group, index) {
    var new_state = this.state.groups;
    new_state[index].isLoading = !group.isLoading;
    this.setState({ ...this.state, groups: new_state });

    deleteGroup(localStorage.getItem('token'), group.id).then((res) => {
      if (res.group == 'Deletado com sucesso') {
        new_state.splice(index, 1);
        this.setState({ ...this.state, groups: new_state });

        toast.success('Grupo deletado com sucesso!');
      } else {
        new_state[index].isLoading = !group.isLoading;
        this.setState({ ...this.state, groups: new_state });

        toast.error('Ocorreu um erro deletar o grupo.');
      }
    });
  }

  modalConfirmDelete() {
    const styles = {
      contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px',
      },
      titleRemove: {
        borderBottom: 'none',
      },
      buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
      buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
    };
    return (
      <Dialog
        aria-labelledby="conditional-remove-title"
        scroll="body"
        onClose={(e) => {
          this.setState({ ...this.state, delete: false });
        }}
        open={true}
      >
        <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={10}>
              <Typography variant="body1" className="text-regular text-st">
                Se seguir com essa ação todos os dados serão perdidos, deseja
                confirmar essa ação?
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={(e) => {
                  this.setState({ ...this.state, delete: false });
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={styles.contentRemove}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonBack}
                variant="secondary"
                size="none"
                className="btn-error"
                onClick={(e) => this.setState({ ...this.state, delete: false })}
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonConfirm}
                variant="primary"
                size="none"
                className="btn-error"
                onClick={(e) => {
                  this.confirmDelete(
                    this.state.rowSelected,
                    this.state.indexSelected,
                  );
                  this.setState({ ...this.state, delete: false });
                }}
              >
                Confirmar Exclusão
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
      buttonToolbar: { marginTop: 24, marginBottom: 24 },
      flex: { display: 'flex' },

      listItem: {
        padding: '16px 0',
        margin: 0,
        borderTop: '1px solid #c7c7c7',
      },
      firstListItem: {
        padding: '16px 0',
        margin: 0,
      },
      item_text: {
        fontSize: 14,
        margin: 0,
        padding: 0,
      },
      containerMargin: {
        marginLeft: 14,
      },
    };
    const { classes } = this.props;

    return (
      <>
        <Container className="tema-light" style={{ marginLeft: 14 }}>
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Atendimento
          </Typography>
          <Card style={{ background: '#f9f9f9' }}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container>
                <Grid item md={9}>
                  <Typography variant="h4">Grupos de pacientes</Typography>
                </Grid>
                <Grid item md={3} style={styles.flex} justifyContent="flex-end">
                  <Button
                    disabled={this.state.groups.find((x) => x.isEdit)}
                    variant="outlined"
                    size="sm"
                    startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                    className={
                      this.state.loadingGroups
                        ? classes.addTagButtonDisabled
                        : classes.addTagButton
                    }
                    onClick={(e) => {
                      var new_group = this.state.groups;
                      new_group.unshift({
                        id: null,
                        name: '',
                        isEdit: true,
                        isLoading: false,
                      });
                      this.setState({ ...this.state, groups: new_group });
                    }}
                  >
                    Adicionar grupo
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    {this.state.groups.length
                      ? this.state.groups.map((row, index) => (
                          <>
                            {row.isEdit ? (
                              <ListItem
                                style={
                                  index == 0
                                    ? styles.firstListItem
                                    : styles.listItem
                                }
                              >
                                <Grid item md={6}>
                                  <DInput
                                    id="name"
                                    label="Nome do grupo de pacientes"
                                    placeholder="Digite o nome do novo grupo"
                                    value={row.name}
                                    defaultValue={row.name}
                                    error={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? this.state.error.find(
                                            (error) => error.index == index,
                                          ).erro
                                        : ''
                                    }
                                    type="text"
                                    fullWidth={true}
                                    inputProps={{ maxLength: 50 }}
                                    positionIcon="end"
                                    onChange={(e) => {
                                      var new_value = this.state.groups;

                                      var _value = e.target.value.replace(
                                        /[^a-zA-Zà-úÀ-Ú'" ]/g,
                                        '',
                                      );

                                      new_value[index].name = _value;
                                      this.setState({
                                        ...this.state,
                                        groups: new_value,
                                        error: [],
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <ListItemSecondaryAction style={{ right: 0 }}>
                                    <Button
                                      size="sm"
                                      className={classes.cancelarButton}
                                      onClick={(e) => {
                                        var new_group = this.state.groups;

                                        if (!this.state.isEdit)
                                          new_group.splice(index, 1);
                                        else {
                                          new_group[index].isEdit = !row.isEdit;
                                          new_group[index].name =
                                            this.state.oldGroup;
                                        }

                                        this.setState({
                                          ...this.state,
                                          isEdit: false,
                                          groups: new_group,
                                          error: [],
                                        });
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <SaveIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.saveButton}
                                      onClick={(e) => {
                                        if (!row.isLoading) {
                                          this.saveGroup(row, index);
                                        }
                                      }}
                                    >
                                      {row.isLoading ? (
                                        <CircularProgress
                                          size={15}
                                          color="inherit"
                                        />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </ListItemSecondaryAction>
                                </Grid>
                              </ListItem>
                            ) : (
                              <ListItem
                                style={
                                  index == 0
                                    ? styles.firstListItem
                                    : styles.listItem
                                }
                              >
                                <ListItemText
                                  primary={row.name}
                                  disableTypography={true}
                                  style={styles.item_text}
                                />
                                <ListItemSecondaryAction style={{ right: 0 }}>
                                  <IconButton
                                    size="sm"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        // this.confirmDelete(row, index)
                                        this.setState({
                                          ...this.state,
                                          delete: true,
                                          rowSelected: row,
                                          indexSelected: index,
                                        });
                                      }
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: 20 }} />
                                  </IconButton>

                                  <IconButton
                                    size="sm"
                                    className={classes.editButton}
                                    onClick={(e) => {
                                      var new_state = this.state.groups;
                                      new_state[index].isEdit = !row.isEdit;
                                      this.state.oldGroup =
                                        new_state[index].name;
                                      this.setState({
                                        ...this.state,
                                        isEdit: true,
                                        groups: new_state,
                                      });
                                    }}
                                  >
                                    <EditIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </>
                        ))
                      : 'Não existem grupos de pacientes cadastrados'}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Backdrop
            style={{ zIndex: 3000, color: 'white' }}
            open={this.state.loadingGroups}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
        {this.state.delete ? this.modalConfirmDelete() : ''}
      </>
    );
  }
}

export default withStyles(styles)(Grupos);
