import { Grid } from '@material-ui/core';
import { AppContext } from 'context/AppContext';
import Pusher from 'pusher-js';
import { useContext, useEffect, useState } from 'react';
import env from 'react-dotenv';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  abdicarInterconsultation,
  assumirInterconsultation,
  getInterconsultationInvite,
} from './../../../api/health_attendances';
import { CancelarButtom, ConfirmarButtom } from './components/Buttons';
import Confirm from './components/Confirm';
import { Assumir } from './components/modalcontent';

function InviteInterconsultation(props) {
  const [invite, setInvite] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const { interconsultationInvite, setInterconsultationInvite } =
    useContext(AppContext);

  useEffect(() => {
    getInterconsultationInvite(localStorage.getItem('token'))
      .then((res) => {
        if (res.status) setInvite(res.health_attendance_invite);
      })
      .catch((err) => console.log(err));

    const pusher = new Pusher(env.PUSHER_APP_KEY, {
      cluster: env.PUSHER_APP_CLUSTER,
    });

    const channel = pusher.subscribe('interconsultation');

    channel.bind('new-invite', (health_attendance_invite) => {
      if (health_attendance_invite.user_id == localStorage.getItem('uid'))
        setInvite(health_attendance_invite);
    });

    var pusherInvite;

    if (env.PUSHER_APP_KEY) {
      pusherInvite = new Pusher(env.PUSHER_APP_KEY, {
        cluster: env.PUSHER_APP_CLUSTER,
      });

      const channelInvite = pusherInvite.subscribe('interconsulta');

      channelInvite.bind('calling-requesting-user', (userInvite) => {
        setInterconsultationInvite((currentValue) => {
          if (
            userInvite.user_id == localStorage.getItem('uid') &&
            !currentValue
          ) {
            setInvite(userInvite);
          }

          return currentValue;
        });
      });
    }

    return () => {
      pusher.unsubscribe('interconsultation');
      pusher.disconnect();

      pusherInvite.unsubscribe('interconsulta');
      pusherInvite.disconnect();
    };
  }, []);

  const handleAbdicar = () => {
    if (invite.id) {
      abdicarInterconsultation(localStorage.getItem('token'), invite.id)
        .then((res) => {
          if (res.status) {
            toast.success('Interconsulta abdicada com sucesso.');
            setInvite(null);
          } else {
            toast.error('Erro ao abdicar interconsulta.');
          }
        })
        .catch((err) => console.log(err));
    } else {
      setInvite(null);
    }
  };

  const handleAssumir = () => {
    if (invite.id) {
      assumirInterconsultation(localStorage.getItem('token'), invite.id)
        .then((res) => {
          if (res.status) {
            localStorage.setItem(
              'health_attendance_interconsultation',
              invite.health_attendance_id,
            );
            toast.success('Interconsulta assumida com sucesso.');
            setInvite(null);
            setRedirect(true);
          } else {
            toast.error('Erro ao assumir interconsulta.');
          }
        })
        .catch((err) => console.log(err));
    } else {
      setInterconsultationInvite(invite);
      setInvite(null);
    }
  };

  return (
    <>
      {redirect && <Redirect to="/atendimento-imediato?view=1" />}
      {invite && (
        <Confirm
          open={true}
          footer={
            <Grid container justifyContent="center">
              <Grid item md={7}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CancelarButtom onClick={handleAbdicar}>
                      Cancelar
                    </CancelarButtom>
                  </Grid>
                  <Grid item>
                    <ConfirmarButtom onClick={handleAssumir}>
                      {invite.id ? 'Assumir e iniciar' : 'iniciar'}
                    </ConfirmarButtom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        >
          <Assumir protocolo={invite.health_attendance_id} />
        </Confirm>
      )}
    </>
  );
}

export default InviteInterconsultation;
