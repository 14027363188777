import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../index.scss';
import { Divider, Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Vacinacao } from '../../../../../../api/above';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../../../context/NewHealthAttendance';

export default function ModalVacinacao(props) {
  const [open, setOpen] = React.useState(false);
  const [vacina, setVacina] = React.useState(false);
  const { healthAttendance } = useNewHealthAttendance();
  const handleOpen = () => setOpen(true);
  const handleClose = () => props.setModal();

  useEffect(() => {
    Vacinacao.vacinaById(localStorage.getItem('token'), props.open).then(
      (data) => {
        if (data.status) {
          setVacina(data.details);
        }
      },
    );
  }, []);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => props.setModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box md className="estilo_modal_vacinacao">
          <div className="fonte_titulo_modal_vacinacao">Vacinação</div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Estabelecimento de saúde:
              </div>
            </div>
            <Grid item md={9}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.health_establishment ? vacina.health_establishment : ''}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Profissional:</div>
            </div>
            <Grid item md={10}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.professional ? vacina.professional : ''}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Atendimento:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.health_attendance_id ? vacina.health_attendance_id : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Data:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.application ? vacina.application : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Vacina:</div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.vaccine ? vacina.vaccine : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Dose:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.dosage ? vacina.dosage : ''}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Lote:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.lot ? vacina.lot : ''}
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">Lote anterior:</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.previous_lot ? vacina.previous_lot : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Validade:</div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.valid_lot ? vacina.valid_lot : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Fornecedor:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.provider ? vacina.provider : ''}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Gestante:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.pregnant ? vacina.pregnant : ''}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Puerpera:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.puerpera ? vacina.puerpera : ''}
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Outro estabelecimento:
              </div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {vacina.other_health_establishment
                  ? vacina.other_health_establishment
                  : ''}
              </div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_bold_modal_vacinacao">Estratégia:</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">GVE:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Apazamento:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Guia de atendimento:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Sistema de origem:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Criado no Sistema Externo:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Atualizado no Sistema Externo:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Criado no repositório:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Atualizado no repositório:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="flex_end_modal_vacinacao mt20_modal_vacinacao">
            <Button
              onClick={handleClose}
              className="botao_modal_voltar_vacinacao "
            >
              Voltar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
