import styled from 'styled-components';

export const UnidadeCard = styled.div`
  display: grid;
  grid-template-columns: 60% 15% 25%;
  grid-template-rows: auto;
  background: #f4f4f4;
  padding: 24px;
  margin-bottom: 10px;
  grid-gap: 5px;

  div {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border-bottom: 1px solid black;
  }
`;
