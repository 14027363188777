import DInput from '../shared/DInput';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import Image from '@material-ui/icons/Image';
import { useState } from 'react';

function Textareas() {
  const [contador, setContador] = useState(0);
  const style = {
    light: { background: '#FFF' },
    dark: { background: '#272727' },
    textLight: { color: '#FFF' },
  };

  return (
    <>
      <Container className="tema-light">
        <Typography variant="h3">Textareas (light)</Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Default (Padrão)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Selected (Selecionado)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // disabled={true}
                  // success={true}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Filled (Preenchido) </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // disabled={true}
                  // error={true}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Required field (Campo obrigatório)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  error={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Campo obrigatório"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Error (erro)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  error={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Campo obrigatório"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Success (sucesso)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  success={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Success message"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.light}>
              <CardContent>
                <Typography>Disabled (Desabilitado)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // required={true}
                  // success={true}
                  disabled={true}
                  positionIcon="end"
                  helperText="Success message"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <hr style={{ marginTop: '50px', marginBottom: '50px' }} />
      <Container className="tema-dark">
        <Typography variant="h3">Textareas (dark)</Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Default (Padrão)
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Selected (Selecionado)
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // disabled={true}
                  // success={true}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Filled (Preenchido){' '}
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // disabled={true}
                  // error={true}
                  positionIcon="end"
                  helperText="Assistive text (optional)"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth={true}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Required field (Campo obrigatório)
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  error={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Campo obrigatório"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>Error (erro)</Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  error={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Campo obrigatório"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Success (sucesso)
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  required={true}
                  success={true}
                  // disabled={true}
                  positionIcon="end"
                  helperText="Success message"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={style.dark}>
              <CardContent>
                <Typography style={style.textLight}>
                  Disabled (Desabilitado)
                </Typography>
                <DInput
                  label="Label"
                  textarea={3}
                  // required={true}
                  // success={true}
                  disabled={true}
                  positionIcon="end"
                  helperText="Success message"
                  icon={<Image />}
                  contador={contador}
                  maxSize={10}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="Placeholder"
                  value="Lorem Ipsum"
                  onChange={(e) => setContador(e.target.value.length)}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Textareas;
