import { useState, useContext, useEffect } from 'react';
import Navbar from '../LayoutAdmin/components/Navbar';
import Sidebar from './components/Sidebar';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../../../context/AppContext';
import { useTheme } from '../../../context/ThemeContext';

const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: 228,
    marginLeft: -228,
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarOpen: {
    width: 228,
    marginLeft: 0,
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    zIndex: '1 !important',
  },
  sideBarButtonOpen: {
    left: 228,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    zIndex: '1 !important',
  },
  content: {
    background: theme.palette.background.carpet,
    minWidth: 'calc(100vw - 232px)',
    maxWidth: 'calc(100vw - 232px)',
    paddingBottom: 50,
    '@media(minWidth: 768)': {
      minWidth: '100%',
    },
  },
  contentFull: {
    minWidth: '100%',
    paddingBottom: 50,
    background: theme.palette.background.snow,
  },
  footer: {
    width: '98%',
    height: 20,
    background: theme.palette.background.snow,
    padding: '15px 1%',
    position: 'fixed',
    bottom: 0,
    zIndex: 10,
  },
  hideSidebarButton: {
    display: 'none!important',
  },
}));

function LayoutAdminContext(props) {
  const { theme, setTheme } = useTheme();

  // const {handleGetProfiles} = useContext(AppContext)

  const classes = useStyles();

  const [sidebar, setSidebar] = useState(
    props.sidebarOpen ? props.sidebarOpen : false,
  );

  return (
    <>
      <Navbar active={props.active} open={(e) => setSidebar(true)} />
      <Container maxWidth={false} disableGutters={true}>
        {theme.sidebar ? (
          <>
            <Grid container justifyContent="space-between">
              <Grid
                item
                classes={{
                  root: sidebar ? classes.sideBarOpen : classes.sideBar,
                }}
              >
                <Sidebar open={sidebar} onClose={(e) => setSidebar(false)}>
                  {props.sidebar}
                </Sidebar>
              </Grid>
              <Grid
                item
                classes={{
                  root: sidebar ? classes.content : classes.contentFull,
                }}
              >
                {props.children}
              </Grid>
            </Grid>
            <Button
              classes={
                props.hideSidebarButton
                  ? classes.hideSidebarButton
                  : {
                      root: sidebar
                        ? classes.sideBarButtonOpen
                        : classes.sideBarButton,
                    }
              }
              className="menu-button-sidebar"
              onClick={(e) => {
                setSidebar(!sidebar);
              }}
            >
              {sidebar ? <MenuOpenIcon /> : <MenuIcon />}
            </Button>
          </>
        ) : (
          <>{props.children}</>
        )}
      </Container>
      <footer className={classes.footer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1" className="text-regular text-st">
              TeleMed24h &copy; {new Date().getFullYear()} - DEV Build 0.0.1
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className="text-regular text-st text-link"
            >
              Termos de uso e Política de privacidade
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default LayoutAdminContext;
