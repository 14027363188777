import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import './index.scss';

// Toast
import { toast } from 'react-toastify';

// Routes
import { getUserInfoById } from '../../../api/user';
import { getPatientById } from 'api/patient';
import { userDominios as getUserDominios } from 'api/dominios';
import { getAllUnidades } from 'api/trail';
import { useNewHealthAttendance } from 'context/NewHealthAttendance';
import { ChatProvider } from 'context/ChatContext';
import VideoCall from '../VideoCall';
import { Participant } from 'twilio-video';
import { AppContext } from 'context/AppContext';
import { useContext } from 'react';

export default function ModalCallInterconsultation(props) {
  // States
  const [open, setOpen] = useState(false);
  const [userDominios, setUserDominios] = useState(null);
  const [user, setUser] = useState(null);
  const [unidadesRef, setUnidadesRef] = useState(null);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState(null);
  const [featuredVideo, setFeaturedVideo] = useState(null);

  const { interconsultationInvite, setInterconsultationInvite } =
    useContext(AppContext);

  const {
    setFinish,
    setFinished,
    setFinishLock,
    setPatient,
    patient,
    setHealthAttendance,
    setTypeVideoCall,
  } = useNewHealthAttendance();

  const [invitePusherData, setInvitePusherData] = useState(null);

  useEffect(() => {
    if (interconsultationInvite) {
      setInvitePusherData(interconsultationInvite);
      setHealthAttendance({ id: interconsultationInvite.health_attendance_id });
      setTypeVideoCall('requisitante');
      handleOpen();
    }
  }, [interconsultationInvite]);

  useEffect(async () => {
    if (props.open) {
      handleOpen();
    }
  }, [props.open]);

  useEffect(async () => {
    if (open) {
      await getUserInfoById(
        localStorage.getItem('token'),
        localStorage.getItem('uid'),
      )
        .then(async (res) => {
          if (res.error) {
            toast.error('Ocorreu um erro ao abrir a chamada');
            console.log(res.error);
          } else {
            setUser(res.user);

            handleOpen();
          }
        })
        .catch((err) => {
          toast.error('Ocorreu um erro ao abrir a chamada');
          console.log(err);
        });
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (invitePusherData) {
      if (!invitePusherData.patient_id) {
        toast.error('Ocorreu um erro ao abrir a chamada');

        return;
      }

      setFinishLock(true);
      setFinish(false);
      setFinished(false);

      getPatientById(
        localStorage.getItem('token'),
        invitePusherData.patient_id,
      ).then((res) => {
        if (res.status) setPatient(res.details.patient);
      });

      getUserDominios().then((res) => {
        setUserDominios(res);
      });

      getAllUnidades(localStorage.getItem('token')).then((res) => {
        if (Array.isArray(res)) {
          setUnidadesRef(
            res.filter((x) => x.below_mother_medical_unit === 'no'),
          );
          setUnidadesVinculadas(
            res.filter((x) => x.below_mother_medical_unit !== 'no'),
          );
        }
      });

      localStorage.removeItem('health_attendance_interconsultation');
    }
  }, [invitePusherData]);

  function onFinish() {
    setInterconsultationInvite(null);
    setInvitePusherData(null);

    setOpen(!open);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={(e) => {
          handleClose();
          props.setCloseModal(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChatProvider>
          <VideoCall
            idAtendimento={
              invitePusherData && invitePusherData.health_attendance_id
            }
            paciente={patient ? { patient: patient } : null}
            solicitante={user ? user.full_name : null}
            idUser={user && user.id}
            userDominios={userDominios}
            unidadesRef={unidadesRef}
            unidadesVinculadas={unidadesVinculadas}
            patientData={patient || {}}
            toggleModal={(e) => null}
            getPDF={(e) => null}
            setModalAtendimentoCmd={(e) => null}
            onFinish={(e) => onFinish()}
            setFeaturedVideo={setFeaturedVideo}
          >
            {featuredVideo && <Participant participant={featuredVideo} />}
          </VideoCall>
        </ChatProvider>
      </Modal>
    </div>
  );
}
