import HealthAttendanceTypeGrid from './components/HealthAttendanceTypeGrid';
import HealthAttendanceTypeFilter from './components/HealthAttendanceTypeFilter';
import { Grid } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import DButton from '../../../shared/DButton';

function HealthAttendanceTypeList(props) {
  return (
    <>
      <Grid container alignItems="center">
        <Grid item md={3}>
          <h1>Tipo de Atendimento</h1>
        </Grid>
        <Grid item md={9}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item md={6}>
              <HealthAttendanceTypeFilter />
            </Grid>
            <Grid container justifyContent="flex-end" item md={4}>
              <DButton
                onClick={(e) => props.cadastrar(true)}
                variant="primary"
                icon={<AddCircle fontSize="small" />}
                size="md"
              >
                Cadastrar
              </DButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <HealthAttendanceTypeGrid
        alterar={(e) => props.alterar(e)}
        excluir={(e) => props.excluir(e)}
        types={props.types}
      />
    </>
  );
}

export default HealthAttendanceTypeList;
