import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { IconButton } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DButton from '../../../../../shared/DButton';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  modal_title: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 0 16px',
    fontSize: 16,
    fontWeight: 700,
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    color: theme.palette.primary.light,
  },
  modal_label: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.neutral.black,
  },
  finish__button: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: theme.palette.neutral.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

export function EncerrarGestacaoModal(props) {
  const classes = useStyles();
  const [_reason, setReason] = useState('');

  const [disableOther, setDisableOther] = useState(false);
  const [others, setOthers] = useState('');

  return (
    <Dialog
      open={props.open}
      disableBackdropClick={true}
      groupFormInput
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.modal_title}>
        Motivo
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <FormControl autoComplete="off" component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            defaultValue=""
            onChange={(e) => {
              if (e.target.value !== '') {
                props.setReason(e.target.value);
                setReason(e.target.value);
                console.log(e.target.value);
              }
            }}
          >
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Alta da mãe/puérpera e do recém-nascido"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Alta da mãe/puérpera e do recém-nascido"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Alta da mãe/puérpera e permanência do recém-nascido"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Alta da mãe/puérpera e permanência do recém-nascido"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Alta da mãe/puérpera e óbito do recém-nascido"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Alta da mãe/puérpera e óbito do recém-nascido"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Alta da mãe/puérpera com óbito fetal"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Alta da mãe/puérpera com óbito fetal"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Óbito da gestante e do concepto"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Óbito da gestante e do concepto"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Óbito da mãe/puérpera e alta do recém-nascido"
              control={
                <Radio
                  onClick={(e) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Óbito da mãe/puérpera e alta do recém-nascido"
            />
            <FormControlLabel
              classes={{ label: classes.modal_label }}
              value="Óbito da mãe/puérpera e permanência do recém-nascido"
              control={
                <Radio
                  onClick={(_) => {
                    setDisableOther(true);
                    setOthers(null);
                  }}
                />
              }
              label="Óbito da mãe/puérpera e permanência do recém-nascido"
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                classes={{ label: classes.modal_label }}
                control={
                  <Radio
                    onClick={(e) => {
                      setDisableOther(false);
                      setReason('');
                    }}
                  />
                }
                value=""
              />
              {disableOther ? (
                <TextField
                  onChange={(e) => null}
                  value={''}
                  disabled={true}
                  id="standard-basic"
                  label=""
                  placeholder="Outro"
                />
              ) : (
                <TextField
                  onChange={(e) => {
                    props.setReason(e.target.value.slice(0, 255));
                    setReason(e.target.value.slice(0, 255));
                    setOthers(e.target.value.slice(0, 255));
                  }}
                  value={others}
                  disabled={disableOther}
                  id="standard-basic"
                  label=""
                  placeholder="Outro"
                />
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <DButton
          variant="secondary"
          size="sm"
          style={{ marginRight: 16 }}
          onClick={props.handleClose}
        >
          Voltar
        </DButton>
        <DButton
          className={classes.finish__button}
          size="sm"
          onClick={(e) => {
            if (_reason === '') {
              toast.error('Preenchimento de um motivo obrigatório!');
              return;
            }

            props.handleTerminatePregnancy(e);
          }}
        >
          Encerrar gestação
        </DButton>
      </DialogActions>
    </Dialog>
  );
}
