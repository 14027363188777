import { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { getAllUser, getAllUserStatus } from '../../../../api/user';
import { listAllPerfis } from '../../../../api/dominios';
import { getAllUnidades } from '../../../../api/unidades';
import { InputLabel, Select, Typography } from '@material-ui/core';
import DButton from '../../../shared/DButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SideBarFilterProfessionalContext } from '../../../../context/useFilterProfessional';
import LiberadoIcon from '../../../../assets/img-icon/assumedIcon.svg';
import AguardandoIcon from '../../../../assets/img-icon/openIcon.svg';
import BloqueadoIcon from '../../../../assets/img-icon/finishedIcon.svg';

const screen_height = window.innerHeight - 98;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: `${screen_height}px !important`,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  label: {
    color: '#272727 !important',
    '& span': {
      color: '#272727 !important',
    },
  },
  button: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '4vh',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '8vh',
    },
  },
}));

export default function SideBar(props) {
  const classes = useStyles();
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [users, setUsers] = useState(['']);
  const [profiles, setProfiles] = useState('0');
  const [units, setUnits] = useState('0');
  const {
    getProfessionalsByFilter,
    setWaiting,
    setReleased,
    setBlocked,
    setAll,
    all,
    waiting,
    released,
    blocked,
    totaisStatus,
    setTotaisStatus,
    activeFilter,
    setActiveFilter,
  } = useContext(SideBarFilterProfessionalContext);
  const [status, setStatus] = useState([]);
  const [listUnidades, setListUnidades] = useState([]);
  const [listPerfis, setListPerfis] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const handleChangeStatus = (event) => {
    if (event.target.name === 'Todos') {
      let checked = event.target.checked;

      setCheckedStatus({
        ...checkedStatus,
        Liberado: checked,
        Aguardando: checked,
        Bloqueado: checked,
      });
    } else {
      setCheckedStatus({
        ...checkedStatus,
        [event.target.name]: event.target.checked,
      });
    }
  };

  checkedStatus.Todos ? setAll('1,2,3') : setAll('');
  checkedStatus.Aguardando ? setWaiting(1) : setWaiting('');
  checkedStatus.Liberado ? setReleased(2) : setReleased('');
  checkedStatus.Bloqueado ? setBlocked(3) : setBlocked('');

  const handleChangeProfiles = (event) => {
    setProfiles(event.target.value);
    setActiveFilter({ ...activeFilter, profile_id: event.target.value });
  };

  const handleChangeUnits = (event) => {
    setUnits(event.target.value);
    setActiveFilter({ ...activeFilter, medical_unit_id: event.target.value });
  };

  async function handleListProfessional(event) {
    event.preventDefault();

    await getProfessionalsByFilter();
  }

  useEffect(function () {
    getAllUser(localStorage.getItem('token'), 1).then((res) => {
      if (res.status || !res.error) {
        setUsers(res.users);
      } else {
        setUsers([
          { status: false, name: false, conselho: [], cpf: false, perfis: [] },
        ]);
      }
    });

    getAllUserStatus(localStorage.getItem('token')).then((res) => {
      setTotaisStatus(res.total);
    });

    listAllPerfis(localStorage.getItem('token')).then((res) => {
      if (res.status) setListPerfis(res.profiles.data);
    });

    getAllUnidades(localStorage.getItem('token')).then((res) => {
      if (res.status != false) {
        setListUnidades(res);
      }
    });
  }, []);

  useEffect(() => {
    let total = 0;
    if (totaisStatus && totaisStatus.length > 0) {
      totaisStatus
        .filter((res) => res.active !== 4)
        .map((res) => (total += res.total));
      setTotalRegistros(total);
    }
  }, [totaisStatus]);

  function CheckBoxStatus() {
    if (typeof totaisStatus != 'undefined') {
      return totaisStatus.map((item) => {
        if (item.active === 1) {
          return (
            <FormControlLabel
              className={classes.label}
              control={
                <>
                  <Checkbox
                    onChange={handleChangeStatus}
                    checked={checkedStatus.Aguardando}
                    name="Aguardando"
                  />
                  <img src={AguardandoIcon} style={{ marginRight: 8 }} />
                </>
              }
              label={
                <Typography className="text-mt text-regular" variant="body1">
                  Aguardando <strong>({item.total})</strong>
                </Typography>
              }
            />
          );
        } else if (item.active === 2) {
          return (
            <FormControlLabel
              className={classes.label}
              control={
                <>
                  <Checkbox
                    onChange={handleChangeStatus}
                    checked={checkedStatus.Liberado}
                    name="Liberado"
                  />
                  <img src={LiberadoIcon} style={{ marginRight: 8 }} />
                </>
              }
              label={
                <Typography className="text-mt text-regular" variant="body1">
                  Liberado <strong>({item.total})</strong>
                </Typography>
              }
            />
          );
        } else if (item.active === 3) {
          return (
            <FormControlLabel
              className={classes.label}
              control={
                <>
                  <Checkbox
                    onChange={handleChangeStatus}
                    checked={checkedStatus.Bloqueado}
                    name="Bloqueado"
                  />
                  <img src={BloqueadoIcon} style={{ marginRight: 8 }} />
                </>
              }
              label={
                <Typography className="text-mt text-regular" variant="body1">
                  Bloqueado <strong>({item.total})</strong>
                </Typography>
              }
            />
          );
        }
      });
    }
  }

  return (
    <div className={classes.root}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleListProfessional(event);
        }}
      >
        <FormControl row className={classes.formControl}>
          <FormGroup>
            <Typography
              className="text-mt text-regular text-primary mt-3"
              variant="body1"
            >
              <strong>Filtro </strong>{' '}
            </Typography>
          </FormGroup>
          <FormGroup>
            <Typography
              className="text-mt text-regular text-primary mt-3"
              variant="body1"
            >
              <strong>Status </strong>{' '}
            </Typography>
            <FormControlLabel
              className={classes.label}
              control={<Checkbox onChange={handleChangeStatus} name="Todos" />}
              label={
                <Typography className="text-mt text-regular" variant="body1">
                  Todos <strong>({totalRegistros})</strong>
                </Typography>
              }
            />
            <CheckBoxStatus onChange={handleChangeStatus}></CheckBoxStatus>
          </FormGroup>

          <FormGroup>
            <Typography
              className="text-mt text-regular text-primary mt-3"
              variant="body1"
            >
              <strong>Perfil </strong>{' '}
            </Typography>
            <InputLabel htmlFor="age-native-simple"></InputLabel>
            <Select
              value={profiles}
              fullWidth={true}
              onChange={handleChangeProfiles}
            >
              <MenuItem value="0">Todos</MenuItem>
              {listPerfis.map((item) => (
                <MenuItem value={item.id}>{item.description}</MenuItem>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Typography
              className="text-mt text-regular text-primary mt-3"
              variant="body1"
            >
              <strong>Unidades </strong>{' '}
            </Typography>
            <InputLabel htmlFor="age-native-simple"></InputLabel>
            <Select value={units} fullWidth={true} onChange={handleChangeUnits}>
              <MenuItem value="0">Todos</MenuItem>
              {listUnidades.map((item) => (
                <MenuItem value={item.id}>{item.company_name}</MenuItem>
              ))}
            </Select>

            <div className={classes.button}>
              <DButton
                variant="primary"
                type="submit"
                size="sm"
                className={classes.button}
              >
                Aplicar Filtros
              </DButton>
            </div>
          </FormGroup>
        </FormControl>
      </form>
    </div>
  );
}
