import { styled } from '@mui/material/styles';
import { InputLabel, makeStyles, TextField } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 53,
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
    fontSize: 14,
    padding: '12px 16px 12px 16px',
  },
  rootDisabled: {
    borderColor: '#F5F6F6',
    background: '#F5F6F6',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
  clockIcon: {
    color: '#006FB5 !important',
  },
  clockIconError: {
    color: '#FF6B57 !important',
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-focused fieldset': {
    border: 'solid rgba(0, 0, 0, 0.87) 1px',
  },
  'input[type="time"]::-webkit-calendar-picker-indicator': {
    background: 'none',
  },
});

function TInputTimeBase({
  id,
  label,
  name,
  value,
  placeholder,
  onChange,
  error,
  helperText,
  ...props
}) {
  const classes = useStyles();

  return (
    <>
      <InputLabel
        error={error}
        classes={{
          root: classes.label,
          error: classes.errorLabel,
        }}
      >
        {label} {props['required'] ? '*' : null}
      </InputLabel>
      <CustomTextField
        {...props}
        id={id}
        error={error}
        variant="outlined"
        type="time"
        name={name}
        placeholder={placeholder}
        defaultValue="00:00"
        inputProps={{
          step: 300, // 5 min
        }}
        InputProps={{
          endAdornment: (
            <AccessTimeIcon
              classes={{
                colorPrimary: error
                  ? classes.clockIconError
                  : classes.clockIcon,
              }}
            />
          ),
        }}
        value={value}
        onChange={onChange}
      />
      {helperText && (
        <p
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
        >
          {helperText}
        </p>
      )}
      {error && (
        <p
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
        >
          {error}
        </p>
      )}
    </>
  );
}

TInputTimeBase.defaultProps = {
  id: 'InpurBase',
  label: 'Input Base',
  name: 'input_base',
  value: '',
  placeholder: '',
  error: false,
  helperText: '',
  onChange: (e) => {
    console.log('onChange');
  },
};

export default TInputTimeBase;
