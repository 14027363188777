import React from 'react';
import '../index.scss';
import { Button } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import TabelaVacinas from '../../NovasTabelasVisualizadorClinico/TabelaVacinas';
import TabelaResultadoExames from '../../NovasTabelasVisualizadorClinico/TabelaResultadoExames';
import TabelaExamesLaboratoriais from '../../NovasTabelasVisualizadorClinico/TabelaExamesLaboratoriais';
import TabelaExamesEnviados from '../../NovasTabelasVisualizadorClinico/TabelaExamesEnviados';
import TabelaGestacao from '../../NovasTabelasVisualizadorClinico/TabelaGestacao';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import BiomedidasVisualizadorClinico from '../../BiomedidasVisualizadorClinico/BiomedidasVisualizadorClinico';
import NovaBiomedidaVisualizadorClinico from '../../NovaBiomedidaVisualizadorClinico/NovaBiomedidaVisualizadorClinico';
import { fetchCurrentUserDetails } from '../../../../api/user';
import HeaderCards from './Components/HeaderCards/HeaderCards';
import { useEffect } from 'react';

export default function VisualizadorClinico(props) {
  const { healthAttendance, setHealthAttendance, patient } =
    useNewHealthAttendance();

  const [openModal, setOpenModal] = useState(false);
  const [profile, setProfile] = useState(null);
  const [updateListBiomedidas, setUpdateListBiomedidas] = useState(false);
  const [updateBiomedida, setUpdateBiomedida] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchCurrentUserDetails(localStorage.getItem('token')).then((response) => {
      if (response.token_details && response.token_details.acl)
        setProfile(response.token_details.acl.profile_id);
    });
  }, []);

  return (
    <>
      {healthAttendance && (
        <HeaderCards healthAttendance={healthAttendance.id} />
      )}
      <div className="fundo_new_tab" style={{ marginTop: 16 }}>
        <div className="main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Biomedidas e exames</div>
          <div className="flex_end_new_tab">
            {/* <Button className="botao_editar_new_tab" startIcon={<PrintIcon />}>
              Imprimir
            </Button> */}
            <Button
              disabled={!profile}
              onClick={(e) => setOpenModal(true)}
              className="botao_editar_new_tab  text-primary"
              startIcon={<AddCircleIcon />}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <div className="mt_35_new_tab main_flex_box_bio_exames">
          {openModal && healthAttendance && (
            <NovaBiomedidaVisualizadorClinico
              profile={profile}
              biomedida={updateBiomedida}
              healthAttendanceId={healthAttendance.id}
              open={openModal}
              onSaved={(e) => {
                setUpdateBiomedida(null);
                setUpdateListBiomedidas(true);
                setOpenModal(false);
              }}
              onClose={(e) => {
                setOpenModal(false);
              }}
            />
          )}
          {healthAttendance && (
            <BiomedidasVisualizadorClinico
              updateList={updateListBiomedidas}
              onUpdated={(e) => setUpdateListBiomedidas(false)}
              healthAttendanceId={healthAttendance.id}
              patientId={patient ? patient.id : null}
              onEdit={(biomedida) => {
                setUpdateBiomedida(biomedida);
                setOpenModal(true);
              }}
            />
          )}
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Vacinas</div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaVacinas />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">
            Resultados de exames de imagens anteriores
          </div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaResultadoExames />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">Exames laboratoriais</div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaExamesLaboratoriais />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        <div className="mt_35_new_tab main_flex_new_tab">
          <div className="fonte_new_tab text-primary">
            Exames enviados pelo paciente
          </div>
        </div>
        <div className="mt_35_new_tab">
          <TabelaExamesEnviados />
        </div>
        <div className="mt_35_new_tab">
          <Divider variant="fullWidth" className="divide_new_tabela" />
        </div>
        {patient && patient.biological_sex === 'Feminino' ? (
          <>
            <div className="mt_35_new_tab main_flex_new_tab">
              <div className="fonte_new_tab text-primary">
                Acompanhamento de gestação
              </div>
            </div>
            <div className="mt_35_new_tab">
              <TabelaGestacao />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
