import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import MonthReagenda from './MonthReagenda';
import TimesAvailableAgenda from './TimesAvailableAgenda';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    padding: 32,
    display: 'flex',
    flex: 1,
  },
  colorButtonDate: {
    color: theme.palette.neutral.cloud,
  },
  buttonDateMonth: {
    display: 'flex',
    background: `${theme.palette.advanced.sidebar} !important`,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.advanced.sidebar} !important`,
    height: '36px',
  },
}));

export default function CalendarReagenda(props) {
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(getMonth);
  const {
    monthIndex,
    openScheduleOfTheDay,
    setMonthIndex,
  } = useContext(GlobalContext);

  function getMonth(month = new Date().getMonth()) {
    const year = new Date().getFullYear();
    const date = new Date();

    const firstDayOfTheMonth = moment(new Date(year, month, 1)).day();
    let currentMonthDay = 0 - firstDayOfTheMonth;

    const daysMatrix = new Array(6).fill([]).map(() => {
      return new Array(7).fill(null).map(() => {
        currentMonthDay++;
        return moment(new Date(year, month, currentMonthDay));
      });
    });
    return daysMatrix;
  }

  
  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const handleMenuItem = (itemParam) => {
    props.handleMenuItem(itemParam);
  };

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }

  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }

  return (
    <>
      <div className="">
        <Grid container>
          <Grid item md={4} className={classes.sidebar}>
            <div
              style={{
                display: 'flex',
                alignItems: 'unset',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div className={classes.buttonDateMonth}>
                <IconButton>
                  <ChevronLeftIcon
                    fontSize="large"
                    className={classes.colorButtonDate}
                    onClick={handlePrevMonth}
                  />
                </IconButton>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 14,
                    }}
                    className={classes.colorButtonDate}
                  >
                    {moment(new Date(moment().year(), monthIndex))
                      .format('MMMM yyyy')
                      .toUpperCase()}
                  </h1>
                </div>
                <IconButton>
                  <ChevronRightIcon
                    fontSize="large"
                    className={classes.colorButtonDate}
                    onClick={handleNextMonth}
                  />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="">
          <MonthReagenda
            month={currentMonth}
            horariosAgenda={props.horariosAgenda}
          />
          {openScheduleOfTheDay && (
            <TimesAvailableAgenda
              tipoAgendamento={props.tipoAgendamento}
              dataScheduleItem={openScheduleOfTheDay}
              isAgendamento={props.isAgendamento}
              patientFile={props.patientFile}
              dataAnamneseForm={props.dataAnamneseForm}
              handleMenuItem={handleMenuItem}
            />
          )}
        </div>
      </div>
    </>
  );
}
