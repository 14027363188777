import React, { useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MdMoreHoriz from '@material-ui/icons/MoreHoriz';
import { IconButton, makeStyles } from '@material-ui/core';
import MdPatients from '../Modais/MdPatients';
import MdResendInformationPatient from '../Modais/MdResendInformationPatient';
import MdCancelConsult from '../Modais/MdCancelConsult';
import MdPreConsult from '../Modais/MdPreConsult';
import { Cancel, Person } from '@material-ui/icons';
import { Assignment } from '@mui/icons-material';
import { getPatientById } from '../../../../api/patient';
import { showHealthAttendancePdf } from '../../../../api/healthAttendance';
import FichaConsulta from '../../../AtendimentoImediato/AtendimentoList/components/AtendimentoGrid/modais/FichaConsulta';
import Print from '@material-ui/icons/Print';
import { toast } from 'react-toastify';
import { AnamnesePreFormByHealthAttendanceId } from '../../../../api/anamnesePreForm';
import { ReactComponent as DoctorIcon } from '../../../../assets/img-icon/DoctorIcon.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
  },
  item_root: {
    padding: '12px',
    fontSize: 12,
  },
  doctorIcon: {
    filter:
      'invert(7%) sepia(9%) saturate(0%) hue-rotate(191deg) brightness(0%) contrast(89%)',
  },
}));

export default function SplitButton(props) {
  const classes = useStyles();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openModalPatient, setOpenModalPatient] = useState(false);
  const [openMdResendInformationPatient, setOpenMdResendInformationPatient] =
    useState(false);
  const [openMdCancelConsult, setOpenMdCancelConsult] = useState(false);
  const [undoCancelConsult, setUndoCancelConsult] = useState(false);
  const [openMdPreConsult, setOpenMdPreConsult] = useState(false);
  const [openFichaConsulta, setOpenFichaConsulta] = useState(false);

  const [patient, setPatient] = useState(null);
  const [anamnesePreForm, setAnamnesePreForm] = React.useState(null);

  async function handleOpenModalPdf() {
    const { attendance } = props;

    if (attendance) {
      try {
        const res = await showHealthAttendancePdf(
          localStorage.getItem('token'),
          attendance.id,
        );

        if (res) {
          if (typeof res.status_code == 'undefined') {
            if (props.openPdf) {
              props.openPdf(attendance.id);
            }
          } else {
            toast.error('Não foi possível carregar o PDF.');
          }
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error('Algo deu errado, tente novamente.');
    }
  }

  async function handleOpenModalPatient() {
    const { attendance } = props;

    if (attendance) {
      try {
        const res = await getPatientById(
          localStorage.getItem('token'),
          attendance.patient_id,
        );

        if (res.status && res.details) {
          setPatient(res.details);
          setOpenModalPatient(true);
        } else {
          toast.error('Não foi possível buscar o paciente.');
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error('Algo deu errado, tente novamente.');
    }
  }

  function handleCloseModalPatient() {
    if (props.updateAttendances) {
      props.updateAttendances();
    }
    setOpenModalPatient(false);
  }

  function handleOpenMdResendInformationPatient() {
    setOpenMdResendInformationPatient(true);
  }

  function handleCloseMdResendInformationPatient() {
    setOpenMdResendInformationPatient(false);
  }

  function handleOpenMdCancelConsult() {
    setOpenMdCancelConsult(true);
  }

  function handleCloseMdCancelConsult() {
    setOpenMdCancelConsult(false);

    if (props.updateAttendances) {
      props.updateAttendances();
    }
  }

  function handleOpenMdPreConsult() {
    AnamnesePreFormByHealthAttendanceId(
      localStorage.getItem('token'),
      props.attendance.id,
    )
      .then((data) => {
        setAnamnesePreForm(data[0]);
        setOpenMdPreConsult(true);
        console.log(data[0]);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Não foi possível buscar a pré-consulta.');
      });
  }

  function handleCloseMdPreConsult() {
    setOpenMdPreConsult(false);
  }

  function handleUndoMdCancelConsult() {
    setUndoCancelConsult(true);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const renderMenuItems = (attendanceId) => {
    switch (attendanceId) {
      case 1:
        return (
          <>
            <MenuItem
              onClick={(event) => handleOpenModalPatient()}
              classes={{ root: classes.item_root }}
            >
              <Person style={{ fontSize: '20px' }} />
              &nbsp; Dados do paciente
            </MenuItem>
            <MenuItem
              onClick={(event) => handleOpenMdPreConsult()}
              classes={{ root: classes.item_root }}
            >
              <Assignment style={{ fontSize: '20px' }} />
              &nbsp; Pré consulta
            </MenuItem>
            <MenuItem
              onClick={(event) => handleOpenModalPdf()}
              classes={{ root: classes.item_root }}
            >
              <Print style={{ fontSize: '20px' }} />
              &nbsp; Criar PDF
            </MenuItem>
            <MenuItem
              onClick={(event) => props.onCancel(true)}
              style={{ color: 'red' }}
              classes={{ root: classes.item_root }}
            >
              <Cancel style={{ fontSize: '20px' }} />
              &nbsp; Cancelar consulta
            </MenuItem>
          </>
        );
      case 2:
        return (
          <>
            <MenuItem
              onClick={(event) => handleOpenModalPatient()}
              classes={{ root: classes.item_root }}
            >
              <Person style={{ fontSize: '20px' }} />
              &nbsp; Dados do paciente
            </MenuItem>
            <Link
              to={{
                pathname: '/atendimento-imediato/' + props.attendance.id,
                state: {
                  atendimento: props.attendance,
                  visualizador: true,
                },
              }}
              style={{ textDecoration: 'none', color: '#000' }}
            >
              <MenuItem
                onClick={(event) => null}
                classes={{ root: classes.item_root }}
              >
                {/* <Person style={{ fontSize: "20px" }} /> */}
                <DoctorIcon
                  className={classes.doctorIcon}
                  width="16px"
                  height="16px"
                />
                &nbsp; Atendimento
              </MenuItem>
            </Link>
          </>
        );
      case 3:
        return (
          <>
            <MenuItem
              onClick={(event) => handleOpenModalPatient()}
              classes={{ root: classes.item_root }}
            >
              <Person style={{ fontSize: '20px' }} />
              &nbsp; Dados do paciente
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                console.log(props.attendance, props);
                setOpenFichaConsulta(true);
              }}
              classes={{ root: classes.item_root }}
            >
              <Assignment style={{ fontSize: '20px' }} />
              &nbsp; Ficha da consulta
            </MenuItem>
            <MenuItem
              onClick={(event) => handleOpenModalPdf()}
              classes={{ root: classes.item_root }}
            >
              <Print style={{ fontSize: '20px' }} />
              &nbsp; Criar PDF
            </MenuItem>
          </>
        );
      case 4:
        return (
          <>
            <MenuItem
              onClick={(event) => handleOpenModalPatient()}
              classes={{ root: classes.item_root }}
            >
              <Person style={{ fontSize: '20px' }} />
              &nbsp; Dados do paciente
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                console.log(props.attendance, props);
                setOpenFichaConsulta(true);
              }}
              classes={{ root: classes.item_root }}
            >
              <Assignment style={{ fontSize: '20px' }} />
              &nbsp; Ficha da consulta
            </MenuItem>
            <MenuItem
              onClick={(event) => handleOpenModalPdf()}
              classes={{ root: classes.item_root }}
            >
              <Print style={{ fontSize: '20px' }} />
              &nbsp; Criar PDF
            </MenuItem>
            <MenuItem
              onClick={(event) => handleUndoMdCancelConsult()}
              style={{ color: 'red' }}
              classes={{ root: classes.item_root }}
            >
              <Cancel style={{ fontSize: '20px' }} />
              &nbsp; Desfazer cancelamento de consulta
            </MenuItem>
          </>
        );
    }
  };

  return (
    <>
      {props.attendance && openFichaConsulta && (
        <FichaConsulta
          open={openFichaConsulta}
          attendance={props.attendance}
          close={(e) => setOpenFichaConsulta(false)}
          openPdf={(e) => handleOpenModalPdf()}
        />
      )}
      <MdPatients
        attendance={props.attendance}
        patient={patient}
        open={openModalPatient}
        onClose={handleCloseModalPatient}
        unidadesVinculadas={props.unidadesVinculadas}
        unidadesRef={props.unidadesRef}
        userDominios={props.userDominios}
      />
      <MdResendInformationPatient
        open={openMdResendInformationPatient}
        onClose={handleCloseMdResendInformationPatient}
      />
      {openMdCancelConsult || undoCancelConsult ? (
        <MdCancelConsult
          open={openMdCancelConsult}
          onClose={handleCloseMdCancelConsult}
          attendance={props.attendance}
          undoCancelConsult={undoCancelConsult}
        />
      ) : (
        ''
      )}
      <MdPreConsult
        openPdf={(_) => handleOpenModalPdf()}
        open={openMdPreConsult}
        onClose={handleCloseMdPreConsult}
        anamnesePreForm={anamnesePreForm}
        attendance={props.attendance}
      />
      <Grid className={classes.root}>
        <IconButton
          ref={anchorRef}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <MdMoreHoriz />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" style={{ padding: 0 }}>
                    {props.attendance &&
                      props.attendance.health_attendance_status_id &&
                      renderMenuItems(
                        props.attendance.health_attendance_status_id,
                      )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </>
  );
}
