import React from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Duplicar from '../../../../assets/img/Duplicar.png';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    justifyContent: 'left',
  },
  backgroundModalExcluir: {
    minWidth: '450px',
    maxWidth: '500px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.neutral.white,
    margin: '200px auto',
    padding: '20px',
  },
  mainTitle: {
    textAlign: 'center',
    color: theme.palette.neutral.black,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  titleDelete: {
    textAlign: 'center',
    color: theme.palette.neutral.darkgray,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  columnGrid: {
    textAlign: 'center',
    marginTop: 20,
  },

  btnExcluir: {
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    borderRadius: '10px',
    gap: '10px',
  },
  btnEditar: {
    background: `${theme.palette.primary.dark}`,
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    gap: '10px',
    borderRadius: '10px',
    color: 'white',
    transition: 'all 200ms linear',

    '&:hover': {
      color: 'black',
    },
  },
  innerModal: {
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));
export default function AgendaDuplicadaModal({
  onCancelar,
  onDuplicar,
  open,
  NomeDaAgenda,
  agendaDados,
}) {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <Grid container className={classes.backgroundModalExcluir}>
        <Grid container style={{ flexDirection: 'row-reverse' }}>
          <Button
            id="basic-button"
            aria-haspopup="true"
            onClick={() => onCancelar()}
          >
            <CloseOutlined />
          </Button>
        </Grid>
        <div className={classes.innerModal}>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={Duplicar} alt="" />
            <h2 className={classes.mainTitle}>Duplicar agenda?</h2>
            <h3 className={classes.titleDelete}>
              Deseja duplicar a agenda de "{NomeDaAgenda}"
            </h3>
          </Grid>

          <Grid container>
            <Grid item md={6} className={classes.columnGrid}>
              <Button
                onClick={(e) => {
                  onCancelar();
                }}
                className={classes.btnExcluir}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item md={6} className={classes.columnGrid}>
              <Button
                className={classes.btnEditar}
                onClick={(e) => {
                  console.log(agendaDados);
                  onDuplicar(agendaDados);
                }}
              >
                Duplicar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Modal>
  );
}

AgendaDuplicadaModal.defaultProps = {
  onCancelar: () => console.log('Cancelar'),
  onDuplicar: () => console.log('Duplicar'),
  open: true,
  NomeDaAgenda: 'Nome da pessoa',
  agendaDados: {},
};
