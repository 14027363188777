import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';

function Titulos() {
  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant="h1" component="h2" gutterBottom>
            Titulos
          </Typography>
          <Typography variant="h1" component="h2" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <Typography variant="h1" component="h2" gutterBottom>
            Textos
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Huge text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-ht text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-ht text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-ht text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-ht text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Extra Large Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-el text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-el text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-el text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-el text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Large Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-lt text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-lt text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-lt text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-lt text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-mt text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-mt text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-mt text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-mt text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Small Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-st text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-st text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-st text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-st text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Extra Small Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-est text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-est text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-est text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-est text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                10
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-xs text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-xs text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-xs text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-xs text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                8
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-sxs text-light"
                variant="body1"
                gutterBottom
              >
                Light Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-sxs text-regular"
                variant="body1"
                gutterBottom
              >
                Regular Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-sxs text-medium"
                variant="body1"
                gutterBottom
              >
                Medium Text
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="text-sxs text-bold"
                variant="body1"
                gutterBottom
              >
                Bold Text
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Titulos;
