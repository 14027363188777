import { useState } from 'react';
import { dateRegex, getFormatDate } from '../../../helpers/formatacao';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { getType, getEvents } from '../shared.helper';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  disabled: {
    opacity: '0.25!important',
  },
}));

export default function DDateInput(props) {
  const containerStyle = useStyles();
  const [validateError, setValidateError] = useState('');

  function getIcon(props) {
    if (props.error && !props.notIcon) return <Error />;

    if (props.success) return <CheckCircle />;

    if (props.icon) return props.icon;
  }

  function getHelperText() {
    if (props.textarea && props.helperText)
      return (
        <FormHelperText className="d-flex">
          <span>{props.helperText}</span>
          <span>
            {props.contador ? props.contador : 0}/
            {props.maxSize ? props.maxSize : 100}
          </span>
        </FormHelperText>
      );
    else if (props.textarea && !props.helperText)
      return (
        <FormHelperText className="d-flex">
          <span>&nbsp;</span>
          <span>
            {props.contador ? props.contador : 0}/
            {props.maxSize ? props.maxSize : 100}
          </span>
        </FormHelperText>
      );
    else if (!props.textarea && props.helperText)
      return <FormHelperText>{props.helperText}</FormHelperText>;
    else return '';
  }

  return (
    <FormControl
      className={`${props.fullWidth ? containerStyle.fullWidth : ''} ${props.success ? 'form-control-success' : ''} ${props.error ? 'form-control-error' : ''} ${props.disabled ? 'form-control-disabled' : ''} ${props.disabled && containerStyle.disabled} ${props.textarea ? 'form-control-textarea' : ''}`}
    >
      <InputLabel
        data-shrink={props.textarea > 0}
        className={`${props.fixedLabel ? 'MuiInputLabel-shrink' : ''}`}
      >
        {props.labelColor ? (
          <span style={{ color: props.labelColor }}>{props.label}</span>
        ) : (
          props.label
        )}{' '}
        {props.required === true && <span style={{ color: 'red' }}>*</span>}
      </InputLabel>
      <Input
        {...getEvents(props)}
        onClick={(e) => {
          props.onClick && props.onClick(e);
        }}
        onChange={(e) => {
          var isValid = true;

          var value = dateRegex(e.target.value);

          var [day, month, year] = value.split('/');

          if (Number(day) > 31 || Number(month) > 12 || Number(year) > 9999) {
            isValid = false;
            setValidateError('Formato de data inválido');
            props.onError && props.onError('Formato de data inválido');
          }

          if (day && month && year && String(year).length === 4) {
            if (props.minDate) {
              try {
                var minDate = new Date(props.minDate);
                var date = new Date(value.split('/').reverse().join('-'));

                if (date < minDate) {
                  isValid = false;
                  let message = props.minDateMessage
                    ? props.minDateMessage
                    : 'Data inválida (menor que o mínimo permitido)';

                  setValidateError(message);

                  props.onError && props.onError(message);
                }
              } catch (error) {
                console.log(error);
              }
            }

            if (props.maxDate) {
              try {
                var maxDate = new Date(props.maxDate);
                var date = new Date(value.split('/').reverse().join('-'));

                if (date > maxDate) {
                  isValid = false;
                  let message = props.maxDateMessage
                    ? props.maxDateMessage
                    : 'Data inválida (maior que o máximo permitido)';

                  props.onError && props.onError(message);

                  setValidateError(message);
                }
              } catch (error) {
                console.log(error);
              }
            }

            if (isValid) setValidateError(null);

            let [_day, _month, _year] = value.split('/');

            props.onChange &&
              props.onChange(value, { day, month, year, isValid }, e);
            return;
          }

          let [_day, _month, _year] = value.split('/');

          props.onChange &&
            props.onChange(value, { day, month, year, isValid }, e);
        }}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
        }}
        id={props.id}
        error={props.error || validateError}
        disabled={props.disabled}
        name={props.name}
        readOnly={props.readOnly}
        type={getType(props.type)}
        required={props.required === true}
        placeholder={props.placeholder}
        value={props.value}
        multiline={props.textarea > 0}
        rows={props.textarea}
        inputProps={{ ...props.inputProps }}
        className={props.className}
        style={props.style}
        endAdornment={
          (props.icon || props.error) && props.positionIcon != 'start' ? (
            <InputAdornment position="end">{getIcon(props)}</InputAdornment>
          ) : (
            false
          )
        }
        startAdornment={
          (props.icon || props.error) && props.positionIcon == 'start' ? (
            <InputAdornment position="start">{getIcon(props)}</InputAdornment>
          ) : (
            false
          )
        }
      />
      {getHelperText()}
    </FormControl>
  );
}
