import { makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import DInput from '../../../../../../shared/DInput';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#D13D2E  !important',
    },
  },
}));

const EBInput = ({
  id,
  label,
  title,
  additional_data,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleChange, handleInputSubmit } = useContext(FormBuilderContext);
  const [input, setInput] = useState({});
  const classes = useStyles();

  const handleInputChange = (e) => {
    const old_input = { ...input, [e.target.id]: e.target.value };
    setInput(old_input);
    // handleInputSubmit(old_input)
  };
  console.log(condicional);
  console.log(id);
  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: '1em', display: 'flex' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${additional_data.construct.showTitle ? label : ''}`,
            }}
          />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
            {is_required === 1 ? '*' : ''}
          </span>
        </Typography>

        <TextField
          id={id}
          // label={additional_data.construct.showTitle ? additional_data.construct.showTitle : ""}
          placeholder={placeholder ? placeholder : ''}
          value={input[id]}
          // defaultValue={value ? value : ""}
          defaultValue={answer ? answer[0] : ''}
          fullWidth
          required={is_required === 1 ? true : false}
          error={erro[0] ? erro[0].value : ''}
          helperText={erro[0] ? erro[0].value : ''}
          className={classes.textField}
          type="text"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: readOnly,
          }}
          onChange={(e) => {
            if (erro !== null && erro.length > 0) {
              delete erro[0].value;
            }
            handleInputChange(e);
          }}
          onBlur={(e) => {
            !readOnly ? handleInputSubmit(input) : console.log('effect onBlur');
          }}
        />
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBInput;
