import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dados_container: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingBottom: 24,
    marginBottom: 24,
    fontSize: 14,
  },
  dados_titulo: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    margin: 0,
  },
  dados_editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  dados_saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  dados_chip: {
    background: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    height: 26,
    marginBottom: 16,
    marginRight: 8,
  },
  dados_card: {
    background: theme.palette.background.snow,
    boxShadow:
      '0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)',
    borderRadius: 4,
    marginBottom: 16,
    padding: 20,
  },
  field_padding: {
    paddingTop: '10px!important',
    display: 'flex',
  },
  alignItemsEnd: {
    alignItems: 'end',
  },
}));
