import axios from 'axios';
import env from 'react-dotenv';

class CBO {
  async get(token) {
    try {
      const { data } = await axios.get(`${env.API_URL}/cbo?token=${token}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new CBO();
