import {
  Typography,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import configuration from 'api/configuration';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import { useConfiguracaoAgendaGeral } from 'context/ConfiguracaoAgendaGeralContext';
import useDebounce from 'hooks/useDebounce';
import useError from 'hooks/useError';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
}));

function GerenciamentoAtendimento() {
  const classes = useStyles();

  const {
    diasAgendamento,
    setDiasAgendamento,
    periodoRetorno,
    setPeriodoRetorno,
  } = useConfiguracaoAgendaGeral();
  const [loading, setLoading] = useState(false);

  const [diasAgendamentoEdit, setDiasAgendamentoEdit] = useState(null);
  const [periodoRetornoEdit, setPeriodoRetornoEdit] = useState(null);
  const [error, validarFormulario, setErrors] = useError();

  const debounceDiasAgendamento = useDebounce(diasAgendamentoEdit, 1000);
  const debouncePeriodoRetorno = useDebounce(periodoRetornoEdit, 1000);

  function putGerenciamentoAtendimento(configName, configValue) {
    var configBody = {};

    if (configName == 'diasAgendamento') {
      if (configValue == '') {
        return;
      }

      configBody.schedule_days = configValue;
    } else if (configName == 'periodoRetorno') {
      if (configValue == '') {
        return;
      }

      configBody.range_max_return = configValue;
    }

    setLoading(true);

    const promise = configuration
      .putEnableOrDisableConfig(localStorage.getItem('token'), 1, configBody)
      .then((res) => {
        if (res.status) {
          if (configName == 'diasAgendamento') {
            setDiasAgendamento(res.configuration.schedule_days);
          } else if (configName == 'periodoRetorno') {
            setPeriodoRetorno(res.configuration.range_max_return);
          }
        }

        setLoading(false);
      });

    toast.promise(promise, {
      pending: 'Salvando...',
      success: 'Salvo com sucesso!',
    });
  }

  useEffect(() => {
    if (debounceDiasAgendamento === null) return;
    putGerenciamentoAtendimento('diasAgendamento', debounceDiasAgendamento);
  }, [debounceDiasAgendamento]);

  useEffect(() => {
    if (debouncePeriodoRetorno === null) return;
    putGerenciamentoAtendimento('periodoRetorno', debouncePeriodoRetorno);
  }, [debouncePeriodoRetorno]);

  const renderDiasAgendamento = () => {
    return (
      <TInputBase
        label="Dias para agendamento"
        name="dias_agendamento"
        placeholder="Quantos dias após a data atual?"
        defaultValue={diasAgendamento}
        error={error.dias_agendamento}
        onChange={(e) => {
          const num = e.target.value.replace(/\D/g, '').slice(0, 3);

          if (num <= 0) {
            setErrors({
              ...error,
              dias_agendamento: 'O valor mínimo para retorno é 1',
            });
            return toast.error('O valor mínimo é 1 dia');
          }

          delete error.dias_agendamento;

          setDiasAgendamentoEdit(e.target.value.replace(/\D/g, '').slice(0, 3));
        }}
        value={diasAgendamentoEdit}
      />
    );
  };

  const renderPeriodoRetorno = () => {
    return (
      <TInputBase
        label="Período máximo de retorno"
        name="periodo_retorno"
        placeholder="Quantos dias após a primeira consulta?"
        value={periodoRetornoEdit}
        error={error.periodo_retorno}
        defaultValue={periodoRetorno}
        onChange={(e) => {
          const num = e.target.value.replace(/\D/g, '').slice(0, 3);

          if (num <= 0) {
            setErrors({
              ...error,
              periodo_retorno: 'O valor mínimo para retorno é 1',
            });
            return toast.error('O valor mínimo para retorno é 1');
          }

          delete error.periodo_retorno;

          setPeriodoRetornoEdit(e.target.value.replace(/\D/g, '').slice(0, 3));
        }}
      />
    );
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: 'white' }} />
      </Backdrop>
      <Typography
        variant="h3"
        component="h1"
        classes={{ root: classes.titulo }}
      >
        Gerenciamento de atendimentos
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          {diasAgendamento && renderDiasAgendamento(diasAgendamento)}
          {!diasAgendamento && renderDiasAgendamento(diasAgendamento)}
        </Grid>
        <Grid item xs={6}>
          {periodoRetorno && renderPeriodoRetorno(periodoRetorno)}
          {!periodoRetorno && (
            <TInputBase
              label="Período máximo de retorno"
              name="periodo_retorno"
              placeholder="Quantos dias após a primeira consulta?"
              defaultValue={0}
              onBlur={(e) => {
                const num = e.target.value.replace(/\D/g, '').slice(0, 3);

                if (num <= 0) {
                  setErrors({
                    ...error,
                    periodo_retorno: 'O valor mínimo para retorno é 1',
                  });
                  return toast.error('O valor mínimo para retorno é 1');
                }

                delete error.periodo_retorno;

                putGerenciamentoAtendimento('periodoRetorno', e.target.value);
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GerenciamentoAtendimento;
