import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import orgaoEmissorCode from '../../../../../../../../assets/dados/orgaoEmissor.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { IUserDominios } from '../../../../../../../../types/userDominios';
import { useStyles } from './styles';

export const NaturalizedIdentity = () => {
  const userDominios = localStorage.getItem('user_dominios');
  const userDominosObj = JSON.parse(userDominios as string) as IUserDominios;
  const classes = useStyles();
  const { dadosIdentidadeNaturalizado, dadosIdentidadeGeral } = usePatient();

  const orgaoEmissorMap = useMemo(
    () =>
      orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  const states =
    userDominosObj?.state?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <h5 className={classes.dados_titulo}>Identidade</h5>
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Número do RNE:</strong>{' '}
        {dadosIdentidadeNaturalizado.identidadeNumero
          ? dadosIdentidadeNaturalizado.identidadeNumero
          : 'Não informado'}
      </Grid>
      <Grid item md={4} xs={4} className={classes.field_padding}>
        <strong>Data de emissão:</strong>{' '}
        {/* {identidadeEmissao
                ? format(new Date(identidadeEmissao), "dd/MM/yyyy")
                : "Não informado"} */}
        {dadosIdentidadeNaturalizado.identidadeDataEmissao || 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Órgão emissor:</strong>{' '}
        {orgaoEmissorMap?.find(
          (oe) =>
            oe?.value === dadosIdentidadeNaturalizado?.identidadeOrgaoEmissor,
        )
          ? orgaoEmissorMap?.find(
              (oe) =>
                oe?.value ===
                dadosIdentidadeNaturalizado?.identidadeOrgaoEmissor,
            )?.value
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>UF:</strong>{' '}
        {states.find(
          (state) => state.id === dadosIdentidadeNaturalizado.identidadeUF,
        )
          ? states?.find(
              (state) =>
                state?.id === dadosIdentidadeNaturalizado?.identidadeUF,
            )?.description
          : 'Não informado'}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field_padding}>
        <strong>Nome da mãe:</strong>{' '}
        {dadosIdentidadeGeral.maeDesconhecida
          ? 'Mãe desconhecida'
          : dadosIdentidadeGeral.nomeMae || 'Não informado'}
      </Grid>

      <Grid item xs={4} className={classes.field_padding}>
        <strong>Nome do pai:</strong>{' '}
        {dadosIdentidadeGeral.paiDesconhecido
          ? 'Pai desconhecido'
          : dadosIdentidadeGeral.nomePai || 'Não informado'}
      </Grid>
    </Grid>
  );
};
