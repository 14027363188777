import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
  Box,
  Grid,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import { required } from '../../../../../../../../helpers/validacoes';
import useError from '../../../../../../../../hooks/useError';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DRadio from '../../../../../../../shared/DRadio';
import './styles.scss';

const optionsPeriodSelector = [
  { id: 1, value: 'Ano(s)' },
  { id: 2, value: 'Mês(es)' },
  { id: 3, value: 'Dia(s)' },
];
const EBSmoking = ({
  id,
  label,
  name,
  title,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [options, setOptions] = useState({});
  const [smokedTime, setSmokedTime] = useState(); //Fumou por quanto tempo?
  const [smokedTimePeriod, setSmokedTimePeriod] = useState(); //Fumou por quanto tempo? Mês/Ano/Dia
  const [stopSmokingTime, setStopSmokingTime] = useState(); //Parou há quanto tempo?
  const [stopSmokingTimePeriod, setStopSmokingTimePeriod] = useState(); //Parou há quanto tempo?

  const [smokeTime, setSmokeTime] = useState(); //Fuma há quanto tempo?
  const [smokeTimePeriod, setSmokeTimePeriod] = useState(); //Fuma há quanto tempo? Mês/Ano/Dia
  const [cigarettesDay, setCigarettesDay] = useState(); //Quantos cigarros por dia?

  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setOptions(e.target.value);
    handleInputSubmit({ [id]: e.target.value });
  };

  const validateFields = {
    validateSmokedTime: () => {
      let msg = 'Campo inválido';

      try {
        if (smokedTime) {
          if (smokedTime == null || smokedTime == '' || smokedTime == undefined)
            throw 'Campo obrigatório';

          if (smokedTime.length < 2) throw 'Campo inválido';

          if (smokedTime == '0') {
            return msg;
          }
        } else {
          throw 'Campo obrigatório';
        }
      } catch (err) {
        if (err == 'Campo obrigatório') return 'Campo obrigatório';
        // return `Data inválida (${err}).`;
        return msg;
      }
    },
    validateStopSmokingTime: () => {
      let msg = 'Campo inválido';

      try {
        if (stopSmokingTime) {
          if (
            stopSmokingTime == null ||
            stopSmokingTime == '' ||
            stopSmokingTime == undefined
          )
            throw 'Campo obrigatório';

          if (stopSmokingTime.length < 2) throw 'Campo inválido';

          if (stopSmokingTime == '0') {
            return msg;
          }
        } else {
          throw 'Campo obrigatório';
        }
      } catch (err) {
        if (err == 'Campo obrigatório') return 'Campo obrigatório';
        // return `Data inválida (${err}).`;
        return msg;
      }
    },
    validateSmokeTime: () => {
      let msg = 'Campo inválido';

      try {
        if (smokeTime) {
          if (smokeTime == null || smokeTime == '' || smokeTime == undefined)
            throw 'Campo obrigatório';

          if (smokeTime.length < 2) throw 'Campo inválido';

          if (smokeTime == '0') {
            return msg;
          }
        } else {
          throw 'Campo obrigatório';
        }
      } catch (err) {
        if (err == 'Campo obrigatório') return 'Campo obrigatório';
        // return `Data inválida (${err}).`;
        return msg;
      }
    },
    validateCigarettesDay: () => {
      let msg = 'Campo inválido';

      try {
        if (cigarettesDay) {
          if (
            cigarettesDay == null ||
            cigarettesDay == '' ||
            cigarettesDay == undefined
          )
            throw 'Campo obrigatório';

          if (cigarettesDay.length < 2) throw 'Campo inválido';

          if (cigarettesDay == '0') {
            return msg;
          }
        } else {
          throw 'Campo obrigatório';
        }
      } catch (err) {
        if (err == 'Campo obrigatório') return 'Campo obrigatório';
        // return `Data inválida (${err}).`;
        return msg;
      }
    },
  };

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    var validators = {};

    if (options == 2) {
      validators.smokedTime = validateFields.validateSmokedTime;
      validators.smokedTimePeriod = required;
      validators.stopSmokingTime = validateFields.validateStopSmokingTime;
      validators.stopSmokingTimePeriod = required;
    }
    if (options == 3) {
      validators.smokeTime = validateFields.validateSmokeTime;
      validators.smokeTimePeriod = required;
      validators.cigarettesDay = validateFields.validateCigarettesDay;
    }

    return validators;
  }

  function getData() {
    var dados = {
      stopSmokingTime,
      smokedTime,
      stopSmokingTimePeriod,
      smokedTimePeriod,
      smokeTime,
      smokeTimePeriod,
      cigarettesDay,
    };

    return dados;
  }

  useEffect(() => {
    let data = {};

    if (options == 1) {
      data = {
        value: options,
      };
    }
    if (options == 2) {
      setSmokeTime(null);
      setSmokeTimePeriod(null);
      setCigarettesDay(null);

      data = {
        value: options,
        stopSmokingTime: {
          stopSmokingTime: stopSmokingTime,
          stopSmokingTimePeriod: stopSmokingTimePeriod,
        },
        smokedTime: {
          smokedTime: smokedTime,
          smokedTimePeriod: smokedTimePeriod,
        },
      };
    }
    if (options == 3) {
      setStopSmokingTime(null);
      setSmokedTime(null);
      setStopSmokingTimePeriod(null);
      setSmokedTimePeriod(null);

      data = {
        value: options,
        smokeTime: {
          smokeTime: smokeTime,
          smokeTimePeriod: smokeTimePeriod,
        },
        cigarettesDay: {
          cigarettesDay: cigarettesDay,
        },
      };
    }
    var dados = getData();

    if (data !== undefined && validarFormulario({ ...dados })) {
      handleInputSubmit({ [id]: data });
    }
  }, [
    options,
    stopSmokingTime,
    stopSmokingTimePeriod,
    smokedTime,
    smokedTimePeriod,
    smokeTime,
    smokeTimePeriod,
    cigarettesDay,
  ]);

  useEffect(() => {
    if (answer.length > 0) {
      setOptions(answer[0].value);
      if (answer[0].stopSmokingTime) {
        setStopSmokingTime(answer[0].stopSmokingTime.stopSmokingTime);
        setStopSmokingTimePeriod(
          answer[0].stopSmokingTime.stopSmokingTimePeriod,
        );
      }
      if (answer[0].smokedTime) {
        setSmokedTime(answer[0].smokedTime.smokedTime);
        setSmokedTimePeriod(answer[0].smokedTime.smokedTimePeriod);
      }
      if (answer[0].cigarettesDay) {
        setCigarettesDay(answer[0].cigarettesDay.cigarettesDay);
      }
      if (answer[0].smokeTime) {
        setSmokeTime(answer[0].smokeTime.smokeTime);
        setSmokeTimePeriod(answer[0].smokeTime.smokeTimePeriod);
      }
    }
  }, []);

  return (
    <section
      style={{
        display: `${
          condicional[0]
            ? condicional[0].action === 'esconder'
              ? 'none'
              : 'block'
            : ''
        }`,
      }}
    >
      {field_category_id === 3 && (
        <div>
          <div className="mb-3">
            <div className="box-medicoes-clinicas">
              <Typography
                variant="body1"
                className="text-bold mb-2"
                style={{ fontSize: '0.8em' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${label ? label : 'Tabagismo'}`,
                  }}
                />
              </Typography>
              {additional_data.options.length > 0 ? (
                <>
                  <DRadio
                    inline
                    id={id}
                    name={name}
                    disabled={readOnly}
                    // onChange={(e) => setOptions(e.target.value)}
                    // value={answer ? answer[0] : ''}
                    onChange={handleChange}
                    value={
                      options && options != null
                        ? options
                        : answer.length > 0
                          ? answer[0].value
                          : ''
                    }
                    options={additional_data.options}
                  />
                </>
              ) : (
                'Não existe dados cadastrados'
              )}
              <br />
              {options == 2 ? (
                <>
                  <Grid container justifyContent="right" className="tema-light">
                    <Grid item md={5} sm={6}>
                      <Typography
                        className={`text-regular mb-1 `}
                        variant="body1"
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                      >
                        <strong>Fumou por quanto tempo? </strong>
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={6}>
                      <TextField
                        id={options}
                        // style={{ paddingRight: "30px", paddingLeft: "-20px" }}
                        // label={"Fumou por quanto tempo?"}
                        placeholder={'00'}
                        // defaultValue={options[option.value].obs}
                        error={error.smokedTime}
                        helperText={error.smokedTime}
                        value={smokedTime}
                        autoComplete={false}
                        onChange={(e) => {
                          delete error.smokedTime;
                          setSmokedTime(e.target.value);
                        }}
                        inputProps={{ maxLength: 2, readOnly: readOnly }}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                        }}
                        //   onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: options}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                    <Grid item md={4} sm={6} style={{ paddingRight: '30px' }}>
                      <DAutoComplete
                        id="select-form"
                        label={'Dia/Mês/Ano'}
                        style={{ paddingLeft: '25px' }}
                        // placeholder={placeholder ? placeholder : ""}
                        labelColor="black"
                        error={error.smokedTimePeriod}
                        helperText={error.smokedTimePeriod}
                        fullWidth={true}
                        disabled={readOnly}
                        defaultValue={
                          optionsPeriodSelector.filter(
                            (res) => res.value === smokedTimePeriod,
                          )[0]
                        }
                        options={optionsPeriodSelector}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.smokedTimePeriod;
                          setSmokedTimePeriod(newValue ? newValue.value : '');
                        }}
                        // onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: selected}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    className="tema-light mt-3"
                  >
                    <Grid item md={5} sm={6}>
                      <Typography
                        className={`text-regular mb-1 `}
                        variant="body1"
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                      >
                        <strong>Parou há quanto tempo?</strong>
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={6}>
                      <TextField
                        id={options}
                        // style={{ paddingRight: "30px", paddingLeft: "-20px" }}
                        // label={"Fumou por quanto tempo?"}
                        placeholder={'00'}
                        // defaultValue={options[option.value].obs}
                        value={stopSmokingTime}
                        autoComplete={false}
                        error={error.stopSmokingTime}
                        helperText={error.stopSmokingTime}
                        onChange={(e) => {
                          delete error.stopSmokingTime;
                          setStopSmokingTime(e.target.value);
                        }}
                        inputProps={{ maxLength: 2, readOnly: readOnly }}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                        }}
                        //   onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: options}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                    <Grid item md={4} sm={6} style={{ paddingRight: '30px' }}>
                      <DAutoComplete
                        id="select-form"
                        label={'Dia/Mês/Ano'}
                        style={{ paddingLeft: '25px' }}
                        // placeholder={placeholder ? placeholder : ""}
                        labelColor="black"
                        error={error.stopSmokingTimePeriod}
                        helperText={error.stopSmokingTimePeriod}
                        fullWidth={true}
                        disabled={readOnly}
                        // defaultValue={ stopSmokingTimePeriod }
                        defaultValue={
                          optionsPeriodSelector.filter(
                            (res) => res.value === stopSmokingTimePeriod,
                          )[0]
                        }
                        options={optionsPeriodSelector}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.stopSmokingTimePeriod;
                          setStopSmokingTimePeriod(
                            newValue ? newValue.value : '',
                          );
                        }}
                        // onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: selected}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}

              {options == 3 ? (
                <>
                  <Grid container justifyContent="right" className="tema-light">
                    <Grid item md={5} sm={6}>
                      <Typography
                        className={`text-regular mb-1 `}
                        variant="body1"
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                      >
                        <strong>Fuma há quanto tempo? </strong>
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={6}>
                      <TextField
                        id={options}
                        // style={{ paddingRight: "30px", paddingLeft: "-20px" }}
                        // label={"Fumou por quanto tempo?"}
                        placeholder={'00'}
                        // defaultValue={options[option.value].obs}
                        error={error.smokeTime}
                        helperText={error.smokeTime}
                        value={smokeTime}
                        autoComplete={false}
                        onChange={(e) => {
                          delete error.smokeTime;
                          setSmokeTime(e.target.value);
                        }}
                        inputProps={{ maxLength: 2, readOnly: readOnly }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                        }}
                        variant="outlined"
                        //   onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: options}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                    <Grid item md={4} sm={6} style={{ paddingRight: '30px' }}>
                      <DAutoComplete
                        id="select-form"
                        label={'Dia/Mês/Ano'}
                        style={{ paddingLeft: '25px' }}
                        // placeholder={placeholder ? placeholder : ""}
                        labelColor="black"
                        error={error.smokeTimePeriod}
                        helperText={error.smokeTimePeriod}
                        fullWidth={true}
                        disabled={readOnly}
                        defaultValue={
                          optionsPeriodSelector.filter(
                            (res) => res.value === smokeTimePeriod,
                          )[0]
                        }
                        options={optionsPeriodSelector}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.smokeTimePeriod;
                          setSmokeTimePeriod(newValue ? newValue.value : '');
                        }}
                        // onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: selected}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    className="tema-light mt-3"
                  >
                    <Grid item md={5} sm={6}>
                      <Typography
                        className={`text-regular mb-1 `}
                        variant="body1"
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                      >
                        <strong>Quantos cigarros por dia?</strong>
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={6}>
                      <TextField
                        id={options}
                        // style={{ paddingRight: "30px", paddingLeft: "-20px" }}
                        // label={"Fumou por quanto tempo?"}
                        placeholder={'00'}
                        // defaultValue={options[option.value].obs}
                        error={error.cigarettesDay}
                        helperText={error.cigarettesDay}
                        value={cigarettesDay}
                        autoComplete={false}
                        onChange={(e) => {
                          delete error.cigarettesDay;
                          setCigarettesDay(e.target.value);
                        }}
                        inputProps={{ maxLength: 3, readOnly: readOnly }}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                        }}
                        //   onBlur={ e => {
                        //     !readOnly ? handleInputSubmit({[id]: options}) : console.log('effect onBlur')
                        // } }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {erro[0] ? erro[0].value : ''}
              </span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBSmoking;
