import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  withRouter,
} from 'react-router-dom';
import { fetchCurrentUserDetails } from '../../api/user';
import AtendimentoList from './AtendimentoList/AtendimentoList';
import { AtendimentoContext } from '../../context/AtendimentoContext';
import { userDominios } from '../../api/dominios';
import { getAllUnidades } from '../../api/unidades';
import { listHealthAttendanceById } from '../../api/healthAttendance';
import { getPatientById } from '../../api/patient';
import { toast } from 'react-toastify';
import Api_form from '../../api/construtor';
import AtendimentoAnamnese from '../AtendimentoAnamnese';
import { useEffect } from 'react';
import { getByHealthAttendanceId } from 'api/health_attendances';
import { useState } from 'react';

function OpenAttendance(props) {
  const { id } = useParams();
  const [atendimento, setAtendimento] = useState(null);
  const [origemSalaEspera, setOrigemSalaEspera] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const scheduleParam = urlSearchParams.get('schedule');

    if (scheduleParam == 'true') setOrigemSalaEspera(true);

    getByHealthAttendanceId(localStorage.getItem('token'), id)
      .then((res) => {
        if (res.status) setAtendimento(res.healthAttendance);
      })
      .catch(console.log);
  }, []);

  if (atendimento === null) {
    return null;
  }

  return (
    <AtendimentoAnamnese
      healthAttendance={atendimento}
      visualizador={origemSalaEspera == true ? false : true}
      onBack={(e) => {
        props.onBack();
      }}
    />
  );
}

class AtendimentoImediato extends Component {
  constructor(props) {
    console.log('AtendimentoImediato');

    super(props);

    this.state = {
      componentLoaded: false,
      isCadastro: false,
      isDetail: true,
      cadastrar: false,
      login: true,
      paciente: {},
      userDominios: {},
      unidadesRef: [],
      unidadesVinculadas: [],
      atender: false,
      atendimento: null,
      userId: null,
      visualizador: false,
    };
  }

  async componentDidMount() {
    await fetchCurrentUserDetails(localStorage.getItem('token')).then((res) => {
      if (res.error) this.setState({ ...this.state, login: false });
      else this.setState({ ...this.state, userId: res.id });
    });

    this.handleRedirectProps(this.props);

    await userDominios().then((res) => {
      this.setState({
        ...this.state,
        userDominios: res,
        componentLoaded: true,
      });
    });

    await getAllUnidades(localStorage.getItem('token')).then((res) => {
      var unidadesRef = [];
      var unidadesVinculadas = [];

      res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == 'no') {
            unidadesRef.push(item);
            this.setState({ ...this.state, unidadesRef: unidadesRef });
          } else {
            unidadesVinculadas.push(item);
            this.setState({
              ...this.state,
              unidadesVinculadas: unidadesVinculadas,
            });
          }
        });
    });
  }

  handleRedirectProps(props) {
    if (props.location && props.location.state) {
      if (props.location.state.atendimento) {
        this.buscaPacienteById(props.location.state.atendimento);
        this.setState({
          ...this.state,
          atendimento: props.location.state.atendimento,
        });
        this.props.setAtendimentoIndex(false);
      }
    } else if (props.location && props.location.pathname) {
      var _attendanceId = props.location.pathname.split('/')[2];

      try {
        if (!isNaN(_attendanceId)) {
          listHealthAttendanceById(
            localStorage.getItem('token'),
            _attendanceId,
          ).then(async (data) => {
            if (data.status) {
              if (data.healthAttendance) {
                var anamneseId =
                  data.healthAttendance.health_attendance_anamnese_form[0]
                    .anamnese_form.id;

                if (anamneseId) {
                  const _anamneseForm = await Api_form.show(
                    localStorage.getItem('token'),
                    anamneseId,
                  );

                  if (_anamneseForm && _anamneseForm.anamnese_form) {
                    data.healthAttendance.health_attendance_anamnese_form[0].anamnese_form =
                      { ..._anamneseForm.anamnese_form } || {};

                    this.setState({ atendimento: data.healthAttendance });
                    this.buscaPacienteById(data.healthAttendance);
                  }
                }
              }
            } else {
              toast.error('Atendimento encontrado');
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async getAnamneseFormById(id) {
    const data = await Api_form.show(localStorage.getItem('token'), id);

    if (data.status) {
      return data;
    } else {
      return null;
    }
  }

  buscaPacienteById(attendance) {
    getPatientById(localStorage.getItem('token'), attendance.patient_id).then(
      (res) => {
        if (res.status) {
          this.props.setAtendimentoIndex(false);
          this.setState({
            ...this.state,
            isDetail: true,
            paciente: res.details,
          });
        }
      },
    );
  }

  render() {
    return (
      <>
        {!this.state.login ? <Redirect to="/" /> : ''}

        <Switch>
          <Route path="/atendimento-imediato/:id">
            <OpenAttendance
              onBack={(e) => {
                this.props.sidebar(true);
                this.props.setAtendimentoIndex(true);
                this.setState({ ...this.state, isDetail: false });
                this.props.history.push('/atendimento-imediato');
              }}
            />
          </Route>

          <Route path="/atendimento-imediato/" exact>
            {this.props.atendimentoIndex ? (
              <Container className="tema-light">
                <AtendimentoList
                  atender={(e) => {
                    this.props.sidebar(false);
                    this.props.setAtendimentoIndex(false);
                    this.buscaPacienteById(e);
                    this.setState({
                      ...this.state,
                      atendimento: e,
                    });
                  }}
                />
              </Container>
            ) : (
              <AtendimentoAnamnese
                healthAttendance={this.state.atendimento}
                visualizador={this.state.visualizador}
                onBack={(e) => {
                  this.props.sidebar(true);
                  this.props.setAtendimentoIndex(true);
                  this.setState({ ...this.state, isDetail: false });
                }}
              />
            )}
          </Route>
        </Switch>
      </>
    );
  }
}

AtendimentoImediato.contextType = AtendimentoContext;

export default withRouter(AtendimentoImediato);
