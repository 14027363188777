import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import { required } from '../../../../../../../../helpers/validacoes';
import useError from '../../../../../../../../hooks/useError';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DRadio from '../../../../../../../shared/DRadio';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline !important',
  },
  label: {
    color: '#000000 !important',
  },
  input_root: {
    width: '50px !important',
    marginRight: 16,
    '& input': {
      padding: 8,
      fontSize: 14,
    },
  },
  autocomplete: {
    fontSize: 14,
  },
  error: {
    color: '#D13D2E',
  },
}));

const drinkOptions = [
  {
    value: 'Abstinente',
    label: 'Abstinente',
    note: '1 vez ao ano ou nunca',
    width: 170,
  },
  { value: 'Raro', label: 'Raro', note: 'menos de 1 vez por mês', width: 170 },
  {
    value: 'Ocasional',
    label: 'Ocasional',
    note: 'de 1 a 3 vezes por mês',
    width: 170,
  },
  {
    value: 'Frequente',
    label: 'Frequente',
    note: 'de 1 a 4 vezes por semana',
    width: 170,
  },
  {
    value: 'Muito Frequente',
    label: 'Muito Frequente',
    note: 'bebe todos os dias',
    width: 170,
  },
];
const optionsPeriod = [
  { id: 1, value: 'Ano(s)' },
  { id: 2, value: 'Mês(es)' },
  { id: 3, value: 'Dia(s)' },
];
const EBAlcoholicBeverage = ({
  id,
  label,
  name,
  title,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [options, setOptions] = useState({});

  const classes = useStyles();

  const [showError, setShowError] = useState(false);

  const [alcoholic, setAlcoholic] = useState('');
  const [alcoholicType, setAlcoholicType] = useState(null);
  const [alcoholicPeriod, setAlcoholicPeriod] = useState({
    field_1: {
      time: '',
      period: null,
    },
  });
  const [drinkstate, setdrinkState] = useState({
    checkedA: false,
    checkedB: false,
  });

  const handledrinkChange = (event) => {
    setdrinkState({ ...drinkstate, [event.target.name]: event.target.checked });
  };

  const [error, validarFormulario, setErrors] = useError();

  function getData() {
    var data;

    data = {
      alcoholic,
      alcoholicType: null, // string "destilada" / "Destilada, Alguma coisa"
      alcoholic_period: null, // string "16 anos"
    };

    if (alcoholic != 'Abstinente') {
      data.alcoholicType = `${drinkstate.checkedA ? (drinkstate.checkedB ? 'Fermentada, ' : 'Fermentada') : ''}${drinkstate.checkedB ? 'Destilada' : ''}`;
      data.alcoholic_period = `${alcoholicPeriod.field_1.time} ${alcoholicPeriod.field_1.period ? alcoholicPeriod.field_1.period.value : ''}`;
    }

    return data;
  }

  useEffect(() => {
    var data = getData();
    let error = false;

    if (alcoholic != 'Abstinente' && alcoholic != '') {
      if (
        (drinkstate.checkedA !== false || drinkstate.checkedB !== false) &&
        alcoholicPeriod.field_1.period != null &&
        alcoholicPeriod.field_1.period.value != null &&
        alcoholicPeriod.field_1.time.length > 0
      ) {
        setShowError(false);
        error = false;
      } else {
        setShowError(true);
        error = true;
      }
    }
    if (alcoholic == 'Abstinente') {
      setShowError(false);
      error = false;
    }
    if (!error && validarFormulario({ ...data })) {
      handleInputSubmit({ [id]: data });
    }
  }, [alcoholic, alcoholicPeriod]);

  useEffect(() => {
    if (answer.length > 0) {
      setAlcoholic(answer[0].alcoholic);
      if (answer[0].alcoholicType == 'Fermentada') {
        setdrinkState({ ...drinkstate, checkedA: true });
      }
      if (answer[0].alcoholicType == 'Destilada') {
        setdrinkState({ ...drinkstate, checkedB: true });
      }
      if (answer[0].alcoholicType == 'Fermentada, Destilada') {
        setdrinkState({ ...drinkstate, checkedB: true, checkedA: true });
      }

      let selectedPeriod = null;

      if (
        typeof answer[0].alcoholic_period !== 'undefined' &&
        answer[0].alcoholic_period !== null
      ) {
        selectedPeriod = answer[0].alcoholic_period.split(' ')[1];
      }

      setAlcoholicPeriod({
        field_1: {
          time: answer[0].alcoholic_period
            ? answer[0].alcoholic_period.split(' ')[0]
            : '',
          period: optionsPeriod.filter((res) =>
            res.value == selectedPeriod
              ? answer[0].alcoholic_period.split(' ')[1]
              : '',
          )[0],
        },
      });
    }
  }, []);

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      {field_category_id === 3 && (
        <div>
          <div className="mb-3">
            <div className="box-medicoes-clinicas">
              <Typography
                variant="body1"
                className="text-bold mb-2"
                style={{ fontSize: '0.8em' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${label ? label : 'Uso de bebida alcoólica'}`,
                  }}
                />
              </Typography>
              <Box style={{ paddingRight: 64 }}>
                <DRadio
                  inline
                  id={id}
                  disabled={readOnly}
                  name="alcoholic-beverage-use"
                  onChange={(e) => setAlcoholic(e.target.value)}
                  error={error.alcoholic}
                  helperText={error.alcoholic}
                  value={alcoholic}
                  labelClass={classes.label}
                  options={drinkOptions}
                />

                {alcoholic && alcoholic != 'Abstinente' ? (
                  <Grid container style={{ marginTop: 16 }}>
                    <Grid
                      item
                      sm={4}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                      }}
                      className={`${showError ? classes.error : ''}`}
                    >
                      Tipo de bebida:
                    </Grid>
                    <Grid item sm={8} style={{ marginBottom: 8 }}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              error={showError}
                              checked={drinkstate.checkedA}
                              onChange={handledrinkChange}
                              name="checkedA"
                            />
                          }
                          label="Fermentada"
                          error={showError}
                          classes={{ label: classes.autocomplete }}
                          onChange={(e) => {
                            console.log(e);
                            setAlcoholicType(null);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              error={showError}
                              checked={drinkstate.checkedB}
                              onChange={handledrinkChange}
                              name="checkedB"
                            />
                          }
                          label="Destilada"
                          error={showError}
                          classes={{ label: classes.autocomplete }}
                          onChange={(e) => {
                            console.log(e);
                            setAlcoholicType(null);
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      style={{ display: 'flex', alignItems: 'center' }}
                      className={`${showError ? classes.error : ''}`}
                    >
                      Há quanto tempo?
                    </Grid>
                    <Grid item sm={8}>
                      <TextField
                        label=""
                        variant="outlined"
                        error={showError}
                        value={alcoholicPeriod.field_1.time}
                        onChange={(e) => {
                          var value = e.target.value.replace(/[^0-9]/g, '');
                          setAlcoholicPeriod({
                            ...alcoholicPeriod,
                            field_1: {
                              ...alcoholicPeriod.field_1,
                              time: value,
                            },
                          });
                        }}
                        classes={{
                          root: classes.input_root,
                        }}
                      />
                      <DAutoComplete
                        label=""
                        placeholder=""
                        labelColor="black"
                        error={showError}
                        style={{ marginTop: -16, width: 120 }}
                        classes={{ input: classes.autocomplete }}
                        defaultValue={alcoholicPeriod.field_1.period}
                        // defaultValue={alcoholicPeriod.field_1.period}
                        onChange={(e, x) => {
                          setAlcoholicPeriod({
                            ...alcoholicPeriod,
                            field_1: {
                              ...alcoholicPeriod.field_1,
                              period: x,
                            },
                          });
                        }}
                        options={optionsPeriod}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Box>
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {erro[0] ? erro[0].value : ''}
              </span>
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {showError ? 'Campos em destaque obrigatórios' : ''}
              </span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBAlcoholicBeverage;
