import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LaunchIcon from '@mui/icons-material/Launch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Stack from '@mui/material/Stack';
import {
  // Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  // TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import './index.scss';

import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import {
  getPrescriptionsByPatientId,
  getPrescriptions as getPrescriptionsByHealthAttendanceId,
  searchPrescriptions as filterPrescriptions,
} from './../../../../api/user';
import { toast } from 'react-toastify';
import { Prescricao } from '../../../AtendimentoAnamnese/components/Modais/Prescricao';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f9f9f9',
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  pagination: {
    margin: '20px 0 30px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,

    '& .MuiPagination-ul': {
      color: '#272727',
      background: 'transparent',
      fontWeight: 400,

      '& > li': {
        '& button': {
          fontSize: 16,
        },
      },
      '& > li:first-child': {
        '& button': {
          border: `1px solid ${theme.palette.primary.light}`,
          color: theme.palette.primary.light,
        },
      },
      '& > li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.primary.light}`,
          color: theme.palette.primary.light,
        },
      },
      '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
        background: '#fff',
        fontWeight: 500,
      },
    },
  },
  iconResultadoTabelaUltimosAtendimentos: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

// function createData(tipo, dia, prescricao, resultado) {
//   return { tipo, dia, prescricao, resultado };
// }

// const rows = [
//   createData(
//     "Medicação",
//     "27/05/2021",
//     <>
//       <div className="pai_flex_prescricao_tabela">
//         <div className="tag_prescricao_tabela">Omeprazol - 50mg</div>
//       </div>
//     </>,
//     <Button
//       className="icon_resultado_tabela_utimos_atendimentos"
//       startIcon={<LaunchIcon />}
//     >
//       {" "}
//     </Button>
//   ),
//   createData(
//     "Medicação",
//     "27/05/2021",
//     <>
//       <div className="pai_flex_prescricao_tabela">
//         <div className="tag_prescricao_tabela">Hemograma</div>
//         <div className="tag_prescricao_tabela">Tomografia Abdômen Total</div>
//         <div className="tag_prescricao_tabela">Urinálise</div>
//       </div>
//     </>,
//     <Button
//       className="icon_resultado_tabela_utimos_atendimentos"
//       startIcon={<LaunchIcon />}
//     >
//       {" "}
//     </Button>
//   ),
// ];

export default function CustomizedTables(props) {
  const { patient, healthAttendance } = useNewHealthAttendance();
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const [prescricoes, setPrecricoes] = useState([]);
  const [prescricoesPag, setPrescricoesPag] = useState({
    page: 1,
    pages: 1,
  });

  const parseBackendPrescriptions = (prescriptions) => {
    var _prescriptions = [];

    if (prescriptions && Array.isArray(prescriptions)) {
      prescriptions.forEach((_prescription) => {
        if (_prescription.revenue) {
          var revenue = _prescription.revenue;

          let _parsedPrescription = {
            Type: 'RECEITA',
            Date: _prescription.prescriptionData
              ? format(new Date(_prescription.prescriptionData), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : null,
            Prescriptions: [],
            Id: revenue.revenueDocument ? revenue.revenueDocument.id : null,
            PrescriptionId: revenue.revenueDocument
              ? revenue.revenueDocument.prescriptions_id
              : null,
            Url: revenue.revenueDocument ? revenue.revenueDocument.url : '',
            Orientation: _prescription?.orientation,
          };

          if (
            Array.isArray(revenue.revenueItens) &&
            revenue.revenueItens.length > 0
          ) {
            _parsedPrescription.Prescriptions = revenue.revenueItens || [];
          }

          if (_parsedPrescription.Prescriptions.length > 0) {
            _prescriptions.push(_parsedPrescription);
          }
        }

        if (_prescription.forwarding) {
          var _forwarding = _prescription.forwarding;

          let _parsedPrescription = {
            Type: 'Encaminhamento',
            Date: _prescription.prescriptionData
              ? format(new Date(_prescription.prescriptionData), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : null,
            Prescriptions: [],
            Id: _forwarding.forwardingDocument
              ? _forwarding.forwardingDocument.id
              : null,
            PrescriptionId: _forwarding.forwardingDocument
              ? _forwarding.forwardingDocument.prescriptions_id
              : null,
            Url: _forwarding.forwardingDocument
              ? _forwarding.forwardingDocument.url
              : '',
            Orientation: _prescription?.orientation,
          };

          if (
            Array.isArray(_forwarding.forwardingItens) &&
            _forwarding.forwardingItens.length > 0
          ) {
            _parsedPrescription.Prescriptions =
              _forwarding.forwardingItens || [];
          }

          if (_parsedPrescription.Prescriptions.length > 0) {
            _prescriptions.push(_parsedPrescription);
          }
        }

        if (_prescription.certificate) {
          var _certificateItens = Array.isArray(
            _prescription.certificate.certificateItens,
          )
            ? _prescription.certificate.certificateItens
            : Object.values(_prescription.certificate.certificateItens);
          var _certificateDocument = _prescription.certificate
            ? _prescription.certificate.certificateDocument
            : null;

          let _parsedPrescription = {
            Type: 'Atestado',
            Date: _prescription.prescriptionData
              ? format(new Date(_prescription.prescriptionData), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : null,
            Prescriptions: [],
            Id: _certificateDocument ? _certificateDocument.id : null,
            PrescriptionId: _certificateDocument
              ? _certificateDocument.prescriptions_id
              : null,
            Url: _certificateDocument ? _certificateDocument.url : '',
            Orientation: _prescription?.orientation,
          };

          if (
            Array.isArray(_certificateItens) &&
            _certificateItens.length > 0
          ) {
            _parsedPrescription.Prescriptions = _certificateItens || [];
          }

          if (_parsedPrescription.Prescriptions.length > 0) {
            _prescriptions.push(_parsedPrescription);
          }
        }

        if (_prescription.exam) {
          var _exames = Array.isArray(_prescription.exam.examItens)
            ? _prescription.exam.examItens
            : Object.values(_prescription.exam.examItens);
          var _examesDocumentos = _prescription.exam.examDocument || null;

          let _parsedPrescription = {
            Type: 'Exame',
            Date: _prescription.prescriptionData
              ? format(new Date(_prescription.prescriptionData), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : null,
            Prescriptions: [],
            Id: _examesDocumentos ? _examesDocumentos.id : null,
            PrescriptionId: _examesDocumentos
              ? _examesDocumentos.prescriptions_id
              : null,
            Url: _examesDocumentos ? _examesDocumentos.url : '',
            Orientation: _prescription?.orientation,
          };

          if (Array.isArray(_exames) && _exames.length > 0) {
            _parsedPrescription.Prescriptions = _exames || [];
          }

          if (_parsedPrescription.Prescriptions.length > 0) {
            _prescriptions.push(_parsedPrescription);
          }
        }
      });
    }

    return _prescriptions;
  };

  const getPrescriptionsByPage = (page = 1) => {
    getPrescriptionsByPatientId(localStorage.getItem('token'), patient.id, page)
      .then((res) => {
        if (res.status) {
          const _prescriptions = parseBackendPrescriptions(res.prescription);

          setPrecricoes(_prescriptions);
          setPrescricoesPag({
            page: res.paginate.current_page,
            pages: res.paginate.last_page,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (patient) {
      getPrescriptionsByPage();
    }
  }, [patient]);

  useEffect(() => {
    return () => {
      setPrecricoes([]);
    };
  }, []);

  const fixBackendPrescriptionResponse = (response) => {
    if (typeof response !== 'object') return response;

    if (response.status) {
      if (
        response.prescriptionsType &&
        Array.isArray(response.prescriptionsType)
      ) {
        var prescriptions = [];

        response.prescriptionsType.forEach((prescription) => {
          let { prescriptions: item } = prescription;

          var _prescription = {
            Type: item.type,
            Date: item.created_at
              ? format(new Date(item.created_at), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : null,
            Prescriptions: [item],
            Id: item.id,
            PrescriptionId: item.prescriptions_id,
            Url: item.url, // PARÂMETRO FALTANDO.
            Orientation: item?.orientation,
          };
          prescriptions.push(_prescription);
        });

        return prescriptions;
      } else {
        return null;
      }
    } else {
      return response;
    }
  };

  function searchPrescriptions(query) {
    if (query === '') {
      return getPrescriptionsByPage();
    }

    if (!patient) return;

    filterPrescriptions(localStorage.getItem('token'), {
      search: query,
      patient_id: patient.id,
    })
      .then(fixBackendPrescriptionResponse)
      .then((data) => {
        if (data && Array.isArray(data)) {
          setPrecricoes(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="tabela_prescricao">
      <FormControl
        className="div_pai_input_prescisao"
        variant="outlined"
        size="small"
        autoComplete="off"
      >
        <OutlinedInput
          className="input_prescricao"
          id="outlined"
          type="text"
          placeholder="Buscar por prescrição"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searchPrescriptions(search);
            }
          }}
          onChange={(e) => {
            setSearch(e.target.value);
            console.log(e.target.value);
          }}
          value={search}
          labelWidth={0}
          startAdornment={
            <InputAdornment
              position="start"
              onClick={(e) => {
                searchPrescriptions(search);
                console.log('cees ');
              }}
            >
              <IconButton edge="start">
                <SearchIcon
                  className={classes.iconResultadoTabelaUltimosAtendimentos}
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <TableContainer className="estilo_tabela_prescricao">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          style={{ marginTop: '20px' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                Tipo{' '}
                <Button
                  className={classes.iconResultadoTabelaUltimosAtendimentos}
                  startIcon={<KeyboardArrowDownIcon />}
                >
                  {' '}
                </Button>
              </StyledTableCell>
              <StyledTableCell align="left">
                Data{' '}
                <Button
                  className={classes.iconResultadoTabelaUltimosAtendimentos}
                  startIcon={<KeyboardArrowDownIcon />}
                >
                  {' '}
                </Button>
              </StyledTableCell>
              <StyledTableCell align="left">Prescrição</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map((row) => (
              <StyledTableRow key={row.tipo}>
                <StyledTableCell component="th" scope="row">
                  {row.tipo}
                </StyledTableCell>
                <StyledTableCell align="left">{row.dia}</StyledTableCell>
                <StyledTableCell align="left">{row.prescricao}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.resultado}
                </StyledTableCell>
              </StyledTableRow>
            ))} */}
            {prescricoes &&
              prescricoes.map((item, key) => {
                return (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row">
                      {item.Type}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.Date}</StyledTableCell>
                    <StyledTableCell align="left">
                      <div className="pai_flex_prescricao_tabela">
                        {item.Prescriptions.map((prescription, index) => {
                          return (
                            <div className="tag_prescricao_tabela" key={index}>
                              {prescription.label}
                            </div>
                          );
                        })}
                      </div>
                    </StyledTableCell>
                    {item.Orientation &&
                    item.Orientation.orientationDocument !== null ? (
                      <StyledTableCell align="center">
                        <Button
                          className={
                            classes.iconResultadoTabelaUltimosAtendimentos
                          }
                          // endIcon={<LaunchIcon />}
                          onClick={() =>
                            item.Orientation.orientationDocument.url
                              ? window.open(
                                  item.Orientation.orientationDocument.url,
                                  '_blank',
                                )
                              : toast.error(
                                  'Sem orientações para este documento...',
                                )
                          }
                        >
                          {'Orientações'}
                        </Button>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="center" />
                    )}
                    <StyledTableCell align="center">
                      <Button
                        className={
                          classes.iconResultadoTabelaUltimosAtendimentos
                        }
                        startIcon={<LaunchIcon />}
                        onClick={() =>
                          item.Url
                            ? window.open(item.Url, '_blank')
                            : toast.error(
                                'Sem documentos para esta prescrição...',
                              )
                        }
                      >
                        {' '}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className={`flex_alinha_paginacao ${classes.pagination}`}>
          <Stack spacing={2}>
            <Pagination
              count={prescricoesPag.pages}
              onCha
              page={prescricoesPag.page}
              // color="primary"
              onChange={(event, page) => {
                setPrescricoesPag((prevState) => {
                  return {
                    ...prevState,
                    page,
                  };
                });

                getPrescriptionsByPage(page);
              }}
            />
          </Stack>
        </div>
      </TableContainer>
    </div>
  );
}
