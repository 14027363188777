import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import Smoking from './Smoking';
import Drugs from './Drugs';
import Alcoholic from './Alcoholic';

function AntecedentesView(props) {
  const { healthAttendance, setHealthAttendance, patient, setPatient } =
    useNewHealthAttendance();
  const [comorbidities, setComorbidities] = useState([]);
  const [familyComorbidities, setFamilyComorbidities] = useState([]);
  const [physicalActivity, setPhysicalActivity] = useState('-');

  useEffect(() => {
    if (healthAttendance) {
      var new_comorbidities = [];
      healthAttendance.anamnese_pre_form.map((pre_form) =>
        pre_form.anamnese_pre_comorbidity
          .filter((comorbidity) => comorbidity.comorbidity !== null)
          .map((comorbidity) => {
            new_comorbidities.push(
              `${comorbidity.comorbidity.description}: ${comorbidity.observation}`,
            );
          }),
      );
      setComorbidities(new_comorbidities);

      var new_family_comorbidities = [];
      healthAttendance.anamnese_pre_form.map((pre_form) =>
        pre_form.anamnese_pre_comorbidity_family_history
          .filter(
            (comorbidity_family) =>
              comorbidity_family.comorbidity_family_history !== null,
          )
          .map((comorbidity_family) => {
            new_family_comorbidities.push(
              `${comorbidity_family.comorbidity_family_history.description}: ${comorbidity_family.observation}`,
            );
          }),
      );
      setFamilyComorbidities(new_family_comorbidities);

      var new_physical_activity = healthAttendance.anamnese_pre_form.map(
        (pre_form) => pre_form.physical_activity,
      );
      setPhysicalActivity(new_physical_activity.join(','));
    }
  }, [healthAttendance]);

  return (
    <>
      <div className="main_flex_new_tab">
        <div className="fonte_new_tab text-primary">Antecedentes pessoais</div>
        <div className="flex_end_new_tab">{props.children}</div>
      </div>
      <div className="mt_35_new_tab">
        <div>
          <Grid item md={4}>
            <div className="fonte_paciente_tab">Comorbidades</div>
            <div className="main_div_tag_paciente_tab mt10_new_tabs">
              {comorbidities.map((comorbidity) => (
                <div className="tag_paciente_tab">{comorbidity}</div>
              ))}
            </div>
          </Grid>
          <div className="mt_35_new_tab">
            <Grid item md={4}>
              <div className="fonte_paciente_tab">Linhas de cuidado</div>
              <div className="main_div_tag_paciente_tab mt10_new_tabs">
                {healthAttendance && healthAttendance.line_care && (
                  <div className="tag_paciente_tab">
                    {healthAttendance.line_care.name}
                  </div>
                )}
              </div>
            </Grid>
          </div>
          <div className="mt_35_new_tab">
            <Grid item md={4}>
              <div className="fonte_paciente_tab">Antecedentes Familiares</div>
              <div className="main_div_tag_paciente_tab mt10_new_tabs">
                {familyComorbidities.map((family_comorbidity) => (
                  <div className="tag_paciente_tab">{family_comorbidity}</div>
                ))}
              </div>
            </Grid>
          </div>
          <div className="main_flex_new_tab mt_35_new_tab">
            <div className="fonte_new_tab text-primary">Hábitos sociais</div>
          </div>
          <div className="mt_35_new_tab separa_pequeno_inputs_new_tabs">
            <Grid item md={3}>
              <TextField
                className="input_new_solicitante_new_tab"
                id="standard-basic"
                disabled
                value={physicalActivity}
                label="Atividade Física"
                variant="standard"
              />
            </Grid>
            <Grid item md={9}>
              {healthAttendance && (
                <Smoking
                  preForm={
                    healthAttendance.anamnese_pre_form
                      ? healthAttendance.anamnese_pre_form[0]
                      : null
                  }
                />
              )}
            </Grid>
          </div>
          <div className="mt10_new_tabs separa_inputs_new_tabs">
            <Grid item md={12}>
              {healthAttendance && (
                <Alcoholic
                  preForm={
                    healthAttendance.anamnese_pre_form
                      ? healthAttendance.anamnese_pre_form[0]
                      : null
                  }
                />
              )}
            </Grid>
          </div>
          <div className="mt10_new_tabs separa_inputs_new_tabs">
            <Grid item md={12}>
              {healthAttendance && (
                <Drugs
                  preForm={
                    healthAttendance.anamnese_pre_form
                      ? healthAttendance.anamnese_pre_form[0]
                      : null
                  }
                />
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default AntecedentesView;
