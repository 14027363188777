import { Container, Typography } from '@material-ui/core';
import DButton from './../shared/DButton';
import AddCircle from '@material-ui/icons/AddCircle';

function Buttons() {
  const style = {
    light: {
      background: '#FFF',
      padding: '12px',
    },
    dark: {
      background: '#272727',
      padding: '12px',
    },
    titleDark: {
      color: '#FFF',
    },
  };

  return (
    <>
      <Container style={style.dark} maxWidth={'md'} className="tema-dark">
        <Typography variant="h3" style={style.titleDark}>
          Botões (Dark)
        </Typography>
        <DButton variant="primary">Botão primário</DButton>
        <DButton variant="primary" size="md">
          Botão primário
        </DButton>
        <DButton variant="primary" size="sm">
          Botão primário
        </DButton>
        <DButton variant="primary" size="sm" disabled={true}>
          Botão primário
        </DButton>
        <hr />
        <DButton variant="secondary">Botão secundário</DButton>
        <DButton variant="secondary" size="md">
          Botão secundário
        </DButton>
        <DButton variant="secondary" size="sm">
          Botão secundário
        </DButton>
        <DButton variant="secondary" size="sm" disabled={true}>
          Botão secundário
        </DButton>
        <hr />
        <DButton variant="primary" icon={<AddCircle fontSize="small" />}>
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão primário
        </DButton>
        <hr />
        <DButton variant="secondary" icon={<AddCircle fontSize="small" />}>
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão secundário
        </DButton>
        <hr />
        <DButton variant="ghost">Botão ghost</DButton>
        <DButton variant="ghost" size="md">
          Botão ghost
        </DButton>
        <DButton variant="ghost" size="sm">
          Botão ghost
        </DButton>
        <DButton variant="ghost" size="sm" disabled={true}>
          Botão ghost
        </DButton>
        <hr />
        <DButton variant="ghost" icon={<AddCircle fontSize="small" />}>
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão ghost
        </DButton>
        <hr />
        <DButton variant="card">Botão card</DButton>
        <DButton variant="card" size="md">
          Botão card
        </DButton>
        <DButton variant="card" size="sm">
          Botão card
        </DButton>
        <DButton variant="card" size="sm" disabled={true}>
          Botão card
        </DButton>
        <hr />
        <DButton variant="card" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton variant="card" size="md" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton variant="card" size="sm" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton
          variant="card"
          size="sm"
          icon={<AddCircle fontSize="small" />}
          disabled={true}
        >
          Botão card
        </DButton>
      </Container>
      <Container style={style.light} maxWidth={'md'} className="tema-light">
        <Typography variant="h3">Botões (Light)</Typography>
        <DButton variant="primary">Botão primário</DButton>
        <DButton variant="primary" size="md">
          Botão primário
        </DButton>
        <DButton variant="primary" size="sm">
          Botão primário
        </DButton>
        <DButton variant="primary" size="sm" disabled={true}>
          Botão primário
        </DButton>
        <hr />
        <DButton variant="secondary">Botão secundário</DButton>
        <DButton variant="secondary" size="md">
          Botão secundário
        </DButton>
        <DButton variant="secondary" size="sm">
          Botão secundário
        </DButton>
        <DButton variant="secondary" size="sm" disabled={true}>
          Botão secundário
        </DButton>
        <hr />
        <DButton variant="primary" icon={<AddCircle fontSize="small" />}>
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão primário
        </DButton>
        <DButton
          variant="primary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão primário
        </DButton>
        <hr />
        <DButton variant="secondary" icon={<AddCircle fontSize="small" />}>
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão secundário
        </DButton>
        <DButton
          variant="secondary"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão secundário
        </DButton>
        <hr />
        <DButton variant="ghost">Botão ghost</DButton>
        <DButton variant="ghost" size="md">
          Botão ghost
        </DButton>
        <DButton variant="ghost" size="sm">
          Botão ghost
        </DButton>
        <DButton variant="ghost" size="sm" disabled={true}>
          Botão ghost
        </DButton>
        <hr />
        <DButton variant="ghost" icon={<AddCircle fontSize="small" />}>
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="md"
        >
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="sm"
        >
          Botão ghost
        </DButton>
        <DButton
          variant="ghost"
          icon={<AddCircle fontSize="small" />}
          size="sm"
          disabled={true}
        >
          Botão ghost
        </DButton>
        <hr />
        <DButton variant="card">Botão card</DButton>
        <DButton variant="card" size="md">
          Botão card
        </DButton>
        <DButton variant="card" size="sm">
          Botão card
        </DButton>
        <DButton variant="card" size="sm" disabled={true}>
          Botão card
        </DButton>
        <hr />
        <DButton variant="card" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton variant="card" size="md" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton variant="card" size="sm" icon={<AddCircle fontSize="small" />}>
          Botão card
        </DButton>
        <DButton
          variant="card"
          size="sm"
          icon={<AddCircle fontSize="small" />}
          disabled={true}
        >
          Botão card
        </DButton>
      </Container>
    </>
  );
}

export default Buttons;
