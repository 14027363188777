import { Card, CardContent, FormControl, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  Switch,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { anamneseFormByFilter } from 'api/anamnese';
import {
  editPartialScheduleById,
  editScheduleById,
  getHasScheduleTime,
  getProfessionalScheduleData,
  getScheduleById,
} from 'api/schedule/agenda-medico';
import { getUnidade } from 'api/unidades';
import { listUserByMedicalUnitNotPaginated } from 'api/user';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import TInputDateBase from 'component/shared/TInputDateBase/TInputDateBase';
import TInputTimeBase from 'component/shared/TInputTimeBase/TInputTimeBase';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import TSelectNativeMultiple from 'component/shared/TSelectNativeMultiple/TSelectNativeMultiple';
import { AppContext, useAppProvider } from 'context/AppContext';
import { useAuthRoute } from 'context/AuthRouteContext';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import { convertToYear, getFormatDate } from 'helpers/formatacao';
import { required } from 'helpers/validacoes';
import useError from 'hooks/useError';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DaysAndHours from '../../../shared/CalendarMonth/components/DaysAndHours';
import AgendaDuplicadaModal from '../Modais/AgendaDuplicadaModal';
import AgendaComHorariosModal from './components/AgendaComHorariosModal';
import DiaSemana from './components/DiaSemana';

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    minHeight: 700,
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 3,
  },
  formControlRoot: {
    width: '100%',
    border: 'solid #D0D3D9 1px',
    borderRadius: 8,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  formControlErrorRoot: {
    width: '100%',
    border: 'solid #FF6B57 1px',
    borderRadius: 8,
  },
  rootInput: {
    borderRadius: '8px',
  },
  helperTextError: {
    color: '#FF6B57',
    fontSize: 12,
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function AgendaEdit({ scheduleId, handleMenuItem }) {
  const classes = useStyles();

  const {
    segunda,
    setSegunda,
    terca,
    setTerca,
    quarta,
    setQuarta,
    quinta,
    setQuinta,
    sexta,
    setSexta,
    sabado,
    setSabado,
    domingo,
    setDomingo,
    especialidade,
    setEspecialidade,
    formularios,
    setFormularios,
    duracaoAtendimento,
    setDuracaoAtendimento,
    setDataScheduleItem,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDadosAgendamento,
    setDataFinal,
    intervaloAtendimento,
    setIntervaloAtendimento,
    profissional,
    setProfissional,
    ocupacao,
    setOcupacao,
    conselho,
    setConselho,
    numeroRegistro,
    setNumeroRegistro,
    faixaEtariaMaior,
    setFaixaEtariaMaior,
    faixaEtariaMenor,
    setFaixaEtariaMenor,
    faixaEtariaMaiorSelecionar,
    setFaixaEtariaMaiorSelecionar,
    faixaEtariaMenorSelecionar,
    setFaixaEtariaMenorSelecionar,
    sexo,
    setSexo,
    days,
    setPatientDocuments,
    setNomePaciente,
    setHorariosAgendados,
    setDays,
  } = useConfiguracaoAgenda();
  const { userInfo } = useContext(AppContext);
  const { LoadingControl } = useAppProvider();
  const { auth, setAuth } = useAuthRoute();
  const [diasAgendadosDefault, setDiasAgendadosDefault] = useState(null);
  const [dadosAgenda, setDadosAgenda] = useState({});
  const [distribuir, setDistribuir] = useState(false);
  const [agendaDuplicada, setAgendaDuplicadaModal] = useState(false);
  const [scheduleAvaliableTimes, setScheduleAvaliableTimes] = useState(null);
  const [isPartialEdit, setIsPartialEdit] = useState(true);
  const [changedInitialDate, setChangedInitialDate] = useState(false);
  const [modalAgendaComHorarios, setModalAgendaComHorarios] = useState(false);
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [professionalName, setProfissionalName] = useState('');
  const [anamneseForms, setAnamneseForms] = useState([]);
  const [professionalData, setProfessionalData] = useState({});
  const [totalHoras, setTotalHoras] = useState({
    segunda: 0,
    terca: 0,
    quarta: 0,
    quinta: 0,
    sexta: 0,
    sabado: 0,
    domingo: 0,
  });

  const ocupacoes =
    professionalData.ocupacoes?.map((x) => ({
      value: x.code,
      label: x.value,
      ...x,
    })) || [];
  const especialidades =
    professionalData.medical_speciality?.map((x) => ({
      value: x.id,
      label: x.value,
    })) || [];
  const conselhos =
    professionalData.conselhos?.map((x) => ({
      value: x.id,
      label: `${x.tipo}/${x.uf}`,
      ...x,
    })) || [];

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    if (isPartialEdit) {
      var validators = {
        ocupacao: required,
        conselho: required,
        formularios: required,
        nome_profissional: required,
        sexo: required,
      };

      if (faixaEtariaMaior && faixaEtariaMaior != 0) {
        validators.faixaEtariaMaiorSelecionar = () => {
          if (
            faixaEtariaMaiorSelecionar == null ||
            faixaEtariaMaiorSelecionar == ''
          )
            return 'Campo obrigatório';
        };
      }

      if (faixaEtariaMenor && faixaEtariaMenor != 0) {
        validators.faixaEtariaMenorSelecionar = () => {
          if (
            faixaEtariaMenorSelecionar == null ||
            faixaEtariaMenorSelecionar == ''
          )
            return 'Campo obrigatório';
        };
      }

      validators.faixaEtariaMaior = () => {
        if (faixaEtariaMaior == '0') {
          return 'Número inválido';
        } else if (faixaEtariaMaior && faixaEtariaMenor) {
          if (faixaEtariaMaiorSelecionar && faixaEtariaMenorSelecionar) {
            var faixaEtariaMaiorFormat = convertToYear(
              faixaEtariaMaior,
              faixaEtariaMaiorSelecionar,
            );
            var faixaEtariaMenorFormat = convertToYear(
              faixaEtariaMenor,
              faixaEtariaMenorSelecionar,
            );

            if (faixaEtariaMaiorFormat == 0) return 'Número inválido';

            if (faixaEtariaMaiorFormat > faixaEtariaMenorFormat)
              return 'Faixa etária inicial superior a final';
          } else {
            if (faixaEtariaMaior > faixaEtariaMenor)
              return 'Faixa etária inicial superior a final';
          }
        } else if (!faixaEtariaMaior && faixaEtariaMenor) {
          return 'Campo obrigatório';
        }
      };

      validators.faixaEtariaMenor = () => {
        if (faixaEtariaMenor == '0') {
          return 'Número inválido';
        } else if (faixaEtariaMaior && faixaEtariaMenor) {
          if (faixaEtariaMaiorSelecionar && faixaEtariaMenorSelecionar) {
            var faixaEtariaMaiorFormat = convertToYear(
              faixaEtariaMaior,
              faixaEtariaMaiorSelecionar,
            );
            var faixaEtariaMenorFormat = convertToYear(
              faixaEtariaMenor,
              faixaEtariaMenorSelecionar,
            );

            if (faixaEtariaMenorFormat == 0) return 'Número inválido';

            if (faixaEtariaMaiorFormat > faixaEtariaMenorFormat)
              return 'Faixa etária final inferior a inicial';
          } else {
            if (faixaEtariaMaior > faixaEtariaMenor)
              return 'Faixa etária final inferior a inicial';
          }
        } else if (faixaEtariaMaior && !faixaEtariaMenor) {
          return 'Campo obrigatório';
        }
      };

      validators.especialidade = () => {
        if (ocupacao) {
          let _ocupacaoSelected = ocupacoes.find((x) => x.value == ocupacao);

          if (String(_ocupacaoSelected?.label).includes('Médico')) {
            return required(especialidade);
          }
        }
      };

      return validators;
    } else {
      var validators = {
        ocupacao: required,
        conselho: required,
        formularios: required,
        nome_profissional: required,
        dataFinal: required,
        dataInicial: required,
        sexo: required,
      };

      validators.duracaoAtendimento = () => {
        if (!duracaoAtendimento) return 'Campo obrigatório.';

        if (duracaoAtendimento == '00:00')
          return 'Duração do atendimento deve ser maior que 0 minutos.';
      };

      validators.intervaloAtendimento = () => {
        if (!intervaloAtendimento) return 'Campo obrigatório.';

        if (intervaloAtendimento == '00:00')
          return 'Duração do atendimento deve ser maior que 0 minutos.';
      };

      if (faixaEtariaMaior && faixaEtariaMaior != 0) {
        validators.faixaEtariaMaiorSelecionar = () => {
          if (
            faixaEtariaMaiorSelecionar == null ||
            faixaEtariaMaiorSelecionar == ''
          )
            return 'Campo obrigatório';
        };
      }

      if (faixaEtariaMenor && faixaEtariaMenor != 0) {
        validators.faixaEtariaMenorSelecionar = () => {
          if (
            faixaEtariaMenorSelecionar == null ||
            faixaEtariaMenorSelecionar == ''
          )
            return 'Campo obrigatório';
        };
      }

      validators.faixaEtariaMaior = () => {
        if (faixaEtariaMaior == '0') {
          return 'Número inválido';
        } else if (faixaEtariaMaior && faixaEtariaMenor) {
          if (faixaEtariaMaiorSelecionar && faixaEtariaMenorSelecionar) {
            var faixaEtariaMaiorFormat = convertToYear(
              faixaEtariaMaior,
              faixaEtariaMaiorSelecionar,
            );
            var faixaEtariaMenorFormat = convertToYear(
              faixaEtariaMenor,
              faixaEtariaMenorSelecionar,
            );

            if (faixaEtariaMaiorFormat == 0) return 'Número inválido';

            if (faixaEtariaMaiorFormat > faixaEtariaMenorFormat)
              return 'Faixa etária inicial superior a final';
          } else {
            if (faixaEtariaMaior > faixaEtariaMenor)
              return 'Faixa etária inicial superior a final';
          }
        } else if (!faixaEtariaMaior && faixaEtariaMenor) {
          return 'Campo obrigatório';
        }
      };

      validators.faixaEtariaMenor = () => {
        if (faixaEtariaMenor == '0') {
          return 'Número inválido';
        } else if (faixaEtariaMaior && faixaEtariaMenor) {
          if (faixaEtariaMaiorSelecionar && faixaEtariaMenorSelecionar) {
            var faixaEtariaMaiorFormat = convertToYear(
              faixaEtariaMaior,
              faixaEtariaMaiorSelecionar,
            );
            var faixaEtariaMenorFormat = convertToYear(
              faixaEtariaMenor,
              faixaEtariaMenorSelecionar,
            );

            if (faixaEtariaMenorFormat == 0) return 'Número inválido';

            if (faixaEtariaMaiorFormat > faixaEtariaMenorFormat)
              return 'Faixa etária final inferior a inicial';
          } else {
            if (faixaEtariaMaior > faixaEtariaMenor)
              return 'Faixa etária final inferior a inicial';
          }
        } else if (faixaEtariaMaior && !faixaEtariaMenor) {
          return 'Campo obrigatório';
        }
      };

      Object.keys(totalHoras).forEach((key) => {
        validators[key] = (d) => validaDias(d, totalHoras[key]);
      });

      validators.especialidade = () => {
        if (ocupacao) {
          let _ocupacaoSelected = ocupacoes.find((x) => x.value == ocupacao);

          if (String(_ocupacaoSelected?.label).includes('Médico')) {
            return required(especialidade);
          }
        }
      };

      validators.dataInicial = () => {
        if (!dataInicial) return 'Data inicial é obrigatória';
        let dAtual = new Date(new Date().setHours(0, 0, 0, 0));
        let dInicial = new Date(new Date(dataInicial).setHours(0, 0, 0, 0));

        if (dInicial < dAtual && changedInitialDate)
          return 'Data inicial deve ser maior ou igual a data atual';

        if (dataFinal) {
          let dFinal = new Date(new Date(dataFinal).setHours(0, 0, 0, 0));

          if (dInicial && dFinal) {
            if (dFinal.getTime() == dInicial.getTime()) return undefined;

            if (dInicial > dFinal) {
              return 'Data inicial deve ser menor que a data final';
            }
          }
        }
      };

      validators.dataFinal = () => {
        if (!dataFinal) return undefined;

        let dAtual = new Date(new Date().setHours(0, 0, 0, 0));
        let dInicial = new Date(new Date(dataInicial).setHours(0, 0, 0, 0));
        let dFinal = new Date(new Date(dataFinal).setHours(0, 0, 0, 0));

        if (dFinal < dAtual)
          return 'Data final deve ser maior ou igual a data atual';

        if (dInicial && dFinal) {
          if (dFinal.getTime() == dInicial.getTime()) return undefined;

          if (dFinal < dInicial) {
            return 'Data final deve ser maior que a data inicial';
          }
        }
      };

      return validators;
    }
  }

  function validaDias(dia, totalHora) {
    let err = {
      primeiraConsulta: null,
      retorno: null,
      encaixe: null,
      total: null,
    };

    Object.keys(dia).forEach((k) => {
      if (dia[k] !== 0 && dia[k] === '') {
        if (distribuir) {
          err[k] = 'Campo obrigatório';
        }
      }
    });

    if (Object.keys(err).filter((x) => err[x]).length > 0) {
      return err;
    } else {
      let total = 0;

      Object.keys(dia).forEach((k) => {
        if (k === 'totalAtendimento' || k === 'encaixe') return;

        total += parseInt(dia[k]);
      });

      if (total > totalHora) {
        err.total =
          'A quantidade de primeira consulta e retorno não pode ser maior que o total de atendimentos do dia.';
        return err;
      }

      return undefined;
    }
  }

  useEffect(() => {
    try {
      if (Object.keys(error).length) {
        toast.error('Corrija os erros antes de continuar');
        console.log(error);
        document.getElementById(Object.keys(error)[0]).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    } catch (err) {
      console.log(err);
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    if (userInfo.unidade) {
      listUserByMedicalUnitNotPaginated(
        localStorage.getItem('token'),
        userInfo.unidade,
      )
        .then((data) => {
          if (data.users && Array.isArray(data.users)) {
            setUsers(
              data.users.filter(
                (usr) => Object.values(usr.conselho).length > 0,
              ),
            );
          }
        })
        .catch(console.log);
    }
  }, [userInfo.unidade]);

  useEffect(() => {
    setIsLoading(true);

    loadScheduleAsync().finally(() => setIsLoading(false));

    return () => {
      setSegunda(0, 0, 0, 0);
      setTerca(0, 0, 0, 0);
      setQuarta(0, 0, 0, 0);
      setQuinta(0, 0, 0, 0);
      setSexta(0, 0, 0, 0);
      setSabado(0, 0, 0, 0);
      setDomingo(0, 0, 0, 0);
      setEspecialidade([]);
      setNomePaciente('');
      setFormularios([]);
      setDuracaoAtendimento('00:00');
      setIntervaloAtendimento('00:00');
      setDataInicial(null);
      setDataFinal(null);
      setOcupacao(null);
      setConselho(null);
      setNumeroRegistro(null);
      setSexo('');
      setDays([]);
      setFaixaEtariaMaior(null);
      setFaixaEtariaMenor(null);
      setFaixaEtariaMaiorSelecionar(null);
      setFaixaEtariaMenorSelecionar(null);
    };
  }, []);

  useEffect(() => {
    if (userSelected) {
      // LoadingControl.showLoading("Carregando dados do usuário...")
      getProfessionalScheduleData(userSelected.id)
        .then((data) => {
          if (data) {
            setProfissionalName(data.professional.full_name);
            setProfessionalData(data);
          }
        })
        .catch(console.log);
    }
  }, [userSelected]);

  useEffect(() => {
    var numRegistro = conselhos.find((x) => x.id == conselho);
    numRegistro
      ? setNumeroRegistro(mascaraRegistro(numRegistro.numero))
      : setNumeroRegistro('');
  }, [conselho]);

  useEffect(() => {
    if (professionalData && dadosAgenda) {
      const _ocupacoes =
        professionalData.ocupacoes?.map((x) => ({
          value: x.code,
          label: x.value,
        })) || [];

      let _ocupacao = _ocupacoes?.find(
        (x) => x.label == dadosAgenda.schedule_professional?.document_value,
      );

      setOcupacao(_ocupacao ? _ocupacao.label : null);
    }
  }, [professionalData, dadosAgenda]);

  useEffect(() => {
    if (
      !intervaloAtendimento ||
      !duracaoAtendimento ||
      (intervaloAtendimento && intervaloAtendimento == '00:00') ||
      (duracaoAtendimento && duracaoAtendimento == '00:00')
    ) {
      return;
    }

    if (days && Array.isArray(days)) {
      var totais = {
        SEG: 0,
        TER: 0,
        QUA: 0,
        QUI: 0,
        SEX: 0,
        SAB: 0,
        DOM: 0,
      };

      days.forEach((day) => {
        let d = new Date();
        let [h, m] = day.hrInitial.split(':');
        var _date = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDay(),
          Number(h),
          Number(m),
          0,
          0,
        );

        let [h2, m2] = day.hrFinal.split(':');
        const _date2 = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDay(),
          Number(h2),
          Number(m2),
          0,
          0,
        );

        while (_date < _date2) {
          totais[day.day]++;
          _date.setMinutes(
            _date.getMinutes() +
              parseStrHourToIntMinutes(duracaoAtendimento) +
              parseStrHourToIntMinutes(intervaloAtendimento),
            0,
            0,
          );
        }
      });

      setTotalHoras({
        segunda: totais['SEG'],
        terca: totais['TER'],
        quarta: totais['QUA'],
        quinta: totais['QUI'],
        sexta: totais['SEX'],
        sabado: totais['SAB'],
        domingo: totais['DOM'],
      });
    }
  }, [days, duracaoAtendimento, intervaloAtendimento]);

  useEffect(() => {
    if (userInfo.unidade) {
      getUnidade(localStorage.getItem('token'), userInfo.unidade)
        .then((res) => {
          if (res.details?.medicalUnit?.medical_unit_type != 'Assistencial') {
            setAuth({ ...auth, route: false });
          }
        })
        .catch(console.log);
    }
  }, [userInfo.unidade]);

  useEffect(() => {
    // Reload anamnese forms.

    let _selectedOcupacao = ocupacoes.find((x) => x.value == ocupacao);
    setFormularios([]);

    anamneseFormByFilter(localStorage.getItem('token'), {
      medical_speciality_id: especialidade,
      medical_unit_id: userInfo.unidade,
      sex: sexo,
      age_group_begin: faixaEtariaMaior,
      age_group_begin_type: faixaEtariaMaiorSelecionar,
      age_group_end: faixaEtariaMenor,
      age_group_end_type: faixaEtariaMenorSelecionar,
    })
      .then((res) => {
        if (res.status) {
          let forms = res.anamnese_forms.map((form) => {
            form.value = form.id;
            form.label = form.name;

            return form;
          });

          forms = forms.filter((form) => {
            if (_selectedOcupacao) {
              if (form && form.cbo && form.cbo.code) {
                return form.cbo.code == _selectedOcupacao.code;
              }

              return false;
            }

            return false;
          });

          setAnamneseForms(forms);
        } else {
          setAnamneseForms([]);
        }
      })
      .catch(console.log);
  }, [
    especialidade,
    userInfo.unidade,
    ocupacao,
    sexo,
    faixaEtariaMaior,
    faixaEtariaMaiorSelecionar,
    faixaEtariaMenor,
    faixaEtariaMenorSelecionar,
  ]);

  async function loadScheduleAsync() {
    try {
      if (scheduleId) {
        await getScheduleById(scheduleId)
          .then(async (res) => {
            if (res) {
              getProfessionalScheduleData(res.schedule_professional.user_id)
                .then((data) => {
                  if (data) {
                    setProfissionalName(data.professional.full_name);
                    setProfessionalData(data);

                    setUserSelected({
                      id: res.schedule_professional.user_id,
                      label: data.professional.full_name,
                    });

                    let _conselho = data.conselhos.find(
                      (x) =>
                        x.formated_value ==
                        res.schedule_professional.concil_value,
                    );

                    setConselho(_conselho && _conselho.id);

                    setDataInicial(res.start);

                    setDataFinal(res.end);

                    setSexo(res.sex);

                    setFormularios(
                      res.schedule_form.map((form) => {
                        return {
                          value: form.anamnese_form.id,
                          label: form.anamnese_form.name,
                        };
                      }),
                    );

                    if (data.conselhos && Array.isArray(data.conselhos)) {
                      setNumeroRegistro(data.conselhos[0].numero);
                    }
                  }
                })
                .catch(console.log);

              setEspecialidade(res.schedule_professional.medical_speciality_id);

              setNumeroRegistro(res.schedule_professional.document_value);

              setFaixaEtariaMaior(res.age_begin);
              setFaixaEtariaMaiorSelecionar(res.age_begin_type);
              setFaixaEtariaMenorSelecionar(res.age_end_type);
              setFaixaEtariaMenor(res.age_end);

              setDuracaoAtendimento(
                parseIntMinutesToHours(res.duration_service),
              );
              setIntervaloAtendimento(
                parseIntMinutesToHours(res.interval_attendance),
              );

              setDadosAgenda(res);

              let _dayWeek = {
                1: 'SEG',
                2: 'TER',
                3: 'QUA',
                4: 'QUI',
                5: 'SEX',
                6: 'SAB',
                7: 'DOM',
              };

              let daysDefault = res.schedule_avaliable_times.map((item) => {
                return {
                  day: _dayWeek[item.day_week],
                  hrInitial: String(item.time_start).slice(0, 5),
                  hrFinal: String(item.time_end).slice(0, 5),
                };
              });

              setDiasAgendadosDefault(daysDefault);

              const dist_days = [];

              res.schedule_attendance_type_distribution.forEach(
                (item, index) => {
                  if (dist_days['dayweek' + item.day_week]) {
                    dist_days['dayweek' + item.day_week] = {
                      ...dist_days['dayweek' + item.day_week],
                      [item.type]: item.quantity,
                    };
                  } else {
                    dist_days['dayweek' + item.day_week] = {
                      [item.type]: item.quantity,
                    };
                  }
                },
              );

              let _fillDistribution = {
                1: setSegunda,
                2: setTerca,
                3: setQuarta,
                4: setQuinta,
                5: setSexta,
                6: setSabado,
                7: setDomingo,
              };

              Object.keys(_fillDistribution).forEach((key) => {
                const { primeiraConsulta, retorno, total, encaixe } =
                  dist_days[`dayweek${key}`];

                _fillDistribution[parseInt(key)](
                  primeiraConsulta || 0,
                  retorno || 0,
                  total || 0,
                  encaixe || 0,
                );
              });
            }
          })
          .catch(console.log);
      }
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar dados da agenda');
    }
  }

  async function handleCheckCanFullEdit() {
    try {
      LoadingControl.showLoading('Carregando...');

      const agendaData = await getScheduleById(scheduleId);
      const response = await getHasScheduleTime(scheduleId);

      if (response && response.message != 'Agenda sem horários agendados.') {
        setModalAgendaComHorarios(true);
        setHorariosAgendados(response.scheduleDataExtract);
        setDadosAgendamento(agendaData);

        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      return true;
    } finally {
      LoadingControl.hideLoading();
    }
  }

  async function handleAgendamentosPeriodo(horariosAgend = [], dadosAgenda) {
    setHorariosAgendados(horariosAgend);
    setDadosAgendamento(dadosAgenda);
    handleMenuItem('lista_agendamentos');
  }

  function parseIntMinutesToHours(min) {
    var h = Math.floor(min / 60);
    var m = min % 60;

    return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
  }

  function mascaraRegistro(value) {
    try {
      var numero = value.substr(0, 9);

      numero = numero.replace(/\D/g, '');
      numero = numero.replace(/(\d{7})(\d{2})$/, '$1-$2');

      return numero;
    } catch (err) {
      console.error(err);
      return value;
    }
  }

  function parseStrHourToIntMinutes(hour) {
    const [hours, minutes] = hour.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  const handlePartialEdit = () => {
    console.log('Edição parcial');
    LoadingControl.showLoading('Salvando...');

    if (
      !validarFormulario({
        especialidade: especialidade,
        formularios: formularios,
        nome_profissional: professionalName,
        faixaEtariaMaiorSelecionar,
        faixaEtariaMenorSelecionar,
        sexo,
        ocupacao: ocupacao,
        conselho: conselho,
      })
    ) {
      LoadingControl.hideLoading();
      return;
    }

    var dadosPatch = {
      schedule: {
        name: professionalName,
        medical_unit_id: userInfo ? Number(userInfo.unidade) : null,
        sex: sexo,
        age_begin: faixaEtariaMaior != '' ? Number(faixaEtariaMaior) : null,
        age_begin_type: faixaEtariaMaiorSelecionar,
        age_end: faixaEtariaMenor != '' ? Number(faixaEtariaMenor) : null,
        age_end_type: faixaEtariaMenorSelecionar,
        status: 1,
      },
      scheduleForm: Array.isArray(formularios)
        ? formularios.map((x) => ({ anamnese_form_id: x.value }))
        : [],
    };

    editPartialScheduleById(dadosPatch, scheduleId)
      .then((res) => {
        if (res && res.status == true) {
          toast.success('Agenda atualizada com sucesso');
        } else if (res && res.status == 422) {
          if (res.message && res.message.includes('Schedule com configura')) {
            setAgendaDuplicadaModal(true);
          } else {
            toast.error(res.message || 'Erro ao editar agenda');
          }
        } else {
          if (res && res.data) {
            if (res.data.status == true) {
              toast.success('Agenda atualizada com sucesso');
            } else if (res.data.status == 422) {
              if (
                res.data.message &&
                res.data.message.includes('Schedule com configura')
              ) {
                setAgendaDuplicadaModal(true);
              } else {
                toast.error(res.data.message || 'Erro ao editar agenda');
              }
            } else {
              toast.error(res.data.message || 'Erro ao editar agenda');
            }

            return;
          }

          toast.error('Erro ao editar agenda');
          console.log(res || 'Erro ao editar agenda');
        }
      })
      .finally(() => {
        LoadingControl.hideLoading();
      });
  };

  function getScheduleAvaliableTimes() {
    return [
      ...days
        .filter((x) => x.day === 'SEG')
        .map((x) => ({
          day_week: 1,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'TER')
        .map((x) => ({
          day_week: 2,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'QUA')
        .map((x) => ({
          day_week: 3,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'QUI')
        .map((x) => ({
          day_week: 4,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'SEX')
        .map((x) => ({
          day_week: 5,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'SAB')
        .map((x) => ({
          day_week: 6,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
      ...days
        .filter((x) => x.day === 'DOM')
        .map((x) => ({
          day_week: 7,
          time_start: x.hrInitial,
          time_end: x.hrFinal,
        })),
    ];
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isPartialEdit) {
      handlePartialEdit();
      return;
    }

    if (days && days.length == 0) {
      return toast.error('Preencha pelo menos uma jornada do dia.');
    }

    if (
      !validarFormulario({
        especialidade: especialidade,
        ocupacao: ocupacao,
        conselho: conselho,
        formularios: formularios,
        nome_profissional: professionalName,
        faixaEtariaMaiorSelecionar,
        faixaEtariaMenorSelecionar,
        dataFinal,
        sexo,
        dataInicial,
        duracaoAtendimento,
        intervaloAtendimento,
        segunda,
        terca,
        quarta,
        quinta,
        sexta,
        sabado,
        domingo,
      })
    )
      return;

    const retornos_consultas = [
      segunda,
      terca,
      quarta,
      quinta,
      sexta,
      sabado,
      domingo,
    ]
      .map((dia, i) => {
        return {
          day_week: i + 1,
          type: 'retorno',
          quantity: dia.retorno ? Number(dia.retorno) : 0,
        };
      })
      .filter((x) => x);

    const primeiras_consultas = [
      segunda,
      terca,
      quarta,
      quinta,
      sexta,
      sabado,
      domingo,
    ]
      .map((dia, i) => {
        return {
          day_week: i + 1,
          type: 'primeiraConsulta',
          quantity: dia.primeiraConsulta ? Number(dia.primeiraConsulta) : 0,
        };
      })
      .filter((x) => x);

    const encaixes_consultas = [
      segunda,
      terca,
      quarta,
      quinta,
      sexta,
      sabado,
      domingo,
    ]
      .map((dia, i) => {
        return {
          day_week: i + 1,
          type: 'encaixe',
          quantity: dia.encaixe ? Number(dia.encaixe) : 0,
        };
      })
      .filter((x) => x);

    // const total_consultas = [
    //   segunda,
    //   terca,
    //   quarta,
    //   quinta,
    //   sexta,
    //   sabado,
    //   domingo,
    // ]
    //   .map((dia, i) => {
    //     return {
    //       day_week: i + 1,
    //       type: "total",
    //       quantity: dia.totalAtendimento ? Number(dia.totalAtendimento) : 0,
    //     };
    //   })
    //   .filter((x) => x);

    let dWeek = {
      1: {
        week: 'SEG',
        totalRef: 'segunda',
      },
      2: {
        week: 'TER',
        totalRef: 'terca',
      },
      3: {
        week: 'QUA',
        totalRef: 'quarta',
      },
      4: {
        week: 'QUI',
        totalRef: 'quinta',
      },
      5: {
        week: 'SEX',
        totalRef: 'sexta',
      },
      6: {
        week: 'SAB',
        totalRef: 'sabado',
      },
      7: {
        week: 'DOM',
        totalRef: 'domingo',
      },
    };

    const total_consultas = Object.keys(dWeek).map((dayweek) => {
      return {
        day_week: Number(dayweek),
        type: 'total',
        quantity: totalHoras[dWeek[dayweek].totalRef],
      };
    });

    let _selectedConselho = conselhos.find((x) => x.id == conselho);

    let _selectedOcupacao = ocupacoes.find((x) => x.value == ocupacao);

    setScheduleAvaliableTimes(getScheduleAvaliableTimes());

    const dados = {
      schedule: {
        name: professionalName,
        start: dataInicial ? getFormatDate(dataInicial, 'yyyy-MM-dd') : null,
        end: dataFinal ? getFormatDate(dataFinal, 'yyyy-MM-dd') : null,
        medical_unit_id: userInfo ? Number(userInfo.unidade) : null,
        sex: sexo,
        age_begin: faixaEtariaMaior != '' ? Number(faixaEtariaMaior) : null,
        age_begin_type: faixaEtariaMaiorSelecionar,
        age_end: faixaEtariaMenor != '' ? Number(faixaEtariaMenor) : null,
        age_end_type: faixaEtariaMenorSelecionar,
        duration_service: parseStrHourToIntMinutes(duracaoAtendimento),
        interval_attendance: parseStrHourToIntMinutes(intervaloAtendimento),
        status: 1,
      },
      scheduleAttendanceTypeDistribution: [
        ...retornos_consultas,
        ...primeiras_consultas,
        ...encaixes_consultas,
        ...total_consultas,
      ],
      scheduleProfessional: {
        user_id: Number(userSelected.id),
        medical_speciality_id: isNaN(Number(especialidade))
          ? null
          : Number(especialidade),
        cbo_id: _selectedOcupacao ? _selectedOcupacao.id : null,
        document_type: _selectedOcupacao ? _selectedOcupacao.label : null,
        document_value: _selectedOcupacao ? _selectedOcupacao.value : null,
        concil_type: _selectedConselho ? _selectedConselho.tipo : null,
        concil_value: _selectedConselho
          ? _selectedConselho.formated_value
          : null,
      },
      scheduleForm: Array.isArray(formularios)
        ? formularios.map((x) => ({ anamnese_form_id: x.value }))
        : [],
      scheduleAvaliableTimes: getScheduleAvaliableTimes(),
    };

    LoadingControl.showLoading('Salvando...');
    if (isPartialEdit) {
      console.log('Edição parcial');
      var dadosPatch = {
        schedule: {
          name: professionalName,
          medical_unit_id: userInfo ? Number(userInfo.unidade) : null,
          sex: sexo,
          age_begin: Number(faixaEtariaMaior),
          age_begin_type: faixaEtariaMaiorSelecionar,
          age_end: Number(faixaEtariaMenor),
          age_end_type: faixaEtariaMenorSelecionar,
          status: 1,
        },
        scheduleForm: Array.isArray(formularios)
          ? formularios.map((x) => ({ anamnese_form_id: x.value }))
          : [],
      };

      editPartialScheduleById(dadosPatch, scheduleId)
        .then((res) => {
          if (res && res.status == true) {
            toast.success('Agenda atualizada com sucesso');
          } else if (res && res.status == 422) {
            if (res.message && res.message.includes('Schedule com configura')) {
              setAgendaDuplicadaModal(true);
            } else {
              toast.error(res.message || 'Erro ao editar agenda');
            }
          } else {
            if (res && res.data) {
              if (res.data.status == true) {
                toast.success('Agenda atualizada com sucesso');
              } else if (res.data.status == 422) {
                if (
                  res.data.message &&
                  res.data.message.includes('Schedule com configura')
                ) {
                  setAgendaDuplicadaModal(true);
                } else {
                  toast.error(res.data.message || 'Erro ao editar agenda');
                }
              } else {
                toast.error(res.data.message || 'Erro ao editar agenda');
              }

              return;
            }

            toast.error('Erro ao editar agenda');
            console.log(res || 'Erro ao editar agenda');
          }
        })
        .finally(() => {
          LoadingControl.hideLoading();
        });
    } else {
      const canEdit = await handleCheckCanFullEdit();

      if (!canEdit) {
        LoadingControl.hideLoading();
        return;
      }

      editScheduleById(dados, scheduleId)
        .then((res) => {
          if (res && res.status == true) {
            toast.success('Agenda atualizada com sucesso');
          } else if (res && res.status == 422) {
            if (res.message && res.message.includes('Schedule com configura')) {
              setAgendaDuplicadaModal(true);
            } else {
              toast.error(res.message || 'Erro ao editar agenda');
            }
          } else {
            if (res && res.data) {
              if (res.data.status == true) {
                toast.success('Agenda atualizada com sucesso');
              } else if (res.data.status == 422) {
                if (
                  res.data.message &&
                  res.data.message.includes('Schedule com configura')
                ) {
                  setAgendaDuplicadaModal(true);
                } else {
                  toast.error(res.data.message || 'Erro ao editar agenda');
                }
              } else {
                toast.error(res.data.message || 'Erro ao editar agenda');
              }

              return;
            }

            toast.error('Erro ao editar agenda');
            console.log(res || 'Erro ao editar agenda');
          }
        })
        .finally(() => {
          LoadingControl.hideLoading();
        });
    }
  };

  const distribuirAtendimentos = (checked) => {
    if (checked) {
      setSegunda(totalHoras['segunda'] || 0);
      setTerca(totalHoras['terca'] || 0);
      setQuarta(totalHoras['quarta'] || 0);
      setQuinta(totalHoras['quinta'] || 0);
      setSexta(totalHoras['sexta'] || 0);
      setSabado(totalHoras['sabado'] || 0);
      setDomingo(totalHoras['domingo'] || 0);
    } else {
      setSegunda(0);
      setTerca(0);
      setQuarta(0);
      setQuinta(0);
      setSexta(0);
      setSabado(0);
      setDomingo(0);
    }
  };

  if (scheduleId === undefined) {
    return <Redirect to="/agenda" />;
  }

  return (
    <>
      <AgendaDuplicadaModal
        onExcluir={() => setAgendaDuplicadaModal()}
        onEditar={() => {
          setAgendaDuplicadaModal(false);
          handleMenuItem('lista_agendas');
        }}
        open={agendaDuplicada}
        onClose={() => setAgendaDuplicadaModal(false)}
        daysWeek={scheduleAvaliableTimes}
        userProfessional={userSelected ? Number(userSelected.id) : null}
      />

      <AgendaComHorariosModal
        open={modalAgendaComHorarios}
        onConfirm={() => {
          handleMenuItem('lista_agendamentos');
        }}
        onClose={(e) => {
          setModalAgendaComHorarios(false);
        }}
      />
      <Backdrop
        style={{ zIndex: 3000, color: 'white', textAlign: 'center' }}
        open={isLoading}
      >
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <form onSubmit={onSubmit}>
        <div className={classes.background}>
          <Card>
            <CardContent>
              <h1>Editar Agenda</h1>
              <Grid container spacing={5}>
                <Grid item md={4}>
                  <InputLabel
                    error={error.nome_profissional}
                    style={{
                      fontSize: 13,
                    }}
                    classes={{
                      root: classes.label,
                      error: classes.errorLabel,
                    }}
                  >
                    Nome do profissional *
                  </InputLabel>
                  {professionalData && professionalData.professional && (
                    <FormControl
                      classes={{
                        root: error.nome_profissional
                          ? classes.formControlErrorRoot
                          : classes.formControlRoot,
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        options={users.map((x) => ({
                          id: x.id,
                          label: x.name,
                        }))}
                        classes={{
                          root: classes.rootInput,
                        }}
                        onChange={(e, value) => {
                          if (value == null) {
                            setProfissionalName('');
                            setProfessionalData({});
                          }

                          setUserSelected(value);
                          setConselho(null);
                          setNumeroRegistro('');
                          setIsPartialEdit(false);
                        }}
                        name="nome_profissional"
                        id="nome_profissional"
                        defaultValue={{
                          id: professionalData.professional.id,
                          label: professionalData.professional.full_name,
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              width: '100%',
                            }}
                            {...params}
                          />
                        )}
                      />
                    </FormControl>
                  )}

                  {professionalData && !professionalData.professional && (
                    <FormControl
                      classes={{
                        root: error.nome_profissional
                          ? classes.formControlErrorRoot
                          : classes.formControlRoot,
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        options={users.map((x) => ({
                          id: x.id,
                          label: x.name,
                        }))}
                        classes={{
                          root: classes.rootInput,
                        }}
                        onChange={(e, value) => {
                          setUserSelected(value);
                          setConselho(null);
                          setNumeroRegistro('');
                          setIsPartialEdit(false);
                        }}
                        name="nome_profissional"
                        id="nome_profissional"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              width: '100%',
                            }}
                            {...params}
                          />
                        )}
                      />
                    </FormControl>
                  )}

                  {error.nome_profissional && (
                    <p
                      className={`${classes.helperText} ${classes.helperTextError}`}
                    >
                      {error.nome_profissional}
                    </p>
                  )}
                </Grid>
                <Grid item md={4}>
                  <TSelectNative
                    label="Especialidade"
                    name="especialidade"
                    id="especialidade"
                    // error
                    // helperText="Mensagem de apoio"
                    required
                    options={especialidades || []}
                    value={especialidade}
                    onChange={(e) => {
                      setEspecialidade(parseInt(e.target.value));
                      setIsPartialEdit(false);
                    }}
                    error={error.especialidade}
                  />
                </Grid>
                <Grid item md={4}>
                  <TSelectNative
                    label="Ocupação"
                    name="ocupacao"
                    id="ocupacao"
                    // helperText="Mensagem de apoio"
                    required
                    options={ocupacoes || []}
                    value={ocupacao}
                    onChange={(e) => {
                      setOcupacao(e.target.value);
                      setIsPartialEdit(false);
                    }}
                    error={error.ocupacao}
                  />
                </Grid>
                <Grid item md={6}>
                  {conselho ? (
                    <TSelectNative
                      label="Conselho"
                      name="conselho"
                      id="conselho"
                      error={error.conselho}
                      // helperText="Mensagem de apoio"
                      required
                      options={conselhos || []}
                      value={conselho}
                      onChange={(e) => {
                        setConselho(e.target.value);
                        setIsPartialEdit(false);
                      }}
                    />
                  ) : null}

                  {!conselho ? (
                    <TSelectNative
                      label="Conselho"
                      name="conselho"
                      id="conselho"
                      error={error.conselho}
                      // helperText="Mensagem de apoio"
                      required
                      options={conselhos || []}
                      onChange={(e) => {
                        setConselho(e.target.value);
                        setIsPartialEdit(false);
                      }}
                    />
                  ) : null}
                </Grid>
                <Grid item md={6}>
                  <TInputBase
                    // error
                    // helperText="Mensagem de apoio"
                    required
                    disabled
                    label="Código do conselho"
                    name="codigo_conselho"
                    placeholder="Código do conselho"
                    value={numeroRegistro}
                  />
                </Grid>
                <Grid item md={6}>
                  <TInputDateBase
                    // error
                    // helperText="Mensagem de apoio"

                    required
                    label="Data Inicial"
                    name="data_inicial"
                    id="dataInicial"
                    disabled="true"
                    placeholder="Data Inicial"
                    invalidDateMessage="Data inválida"
                    minDateMessage="Data não pode ser menor que a data mínima"
                    maxDateMessage="Data não pode ser maior que a data máxima"
                    value={dataInicial}
                    error={error.dataInicial}
                    onChange={(e) => {
                      setDataInicial(e);
                      delete error.dataInicial;
                      setIsPartialEdit(false);
                      setChangedInitialDate(true);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TInputDateBase
                    // error
                    // helperText="Mensagem de apoio"
                    label="Data Final (opcional)"
                    name="data_final"
                    id="dataFinal"
                    placeholder="Data Final (opcional)"
                    invalidDateMessage="Data inválida"
                    minDateMessage="Data não pode ser menor que a data mínima"
                    maxDateMessage="Data não pode ser maior que a data máxima"
                    value={dataFinal}
                    error={error.dataFinal}
                    onChange={(e) => {
                      setDataFinal(e);
                      delete error.dataFinal;
                      setIsPartialEdit(false);
                    }}
                  />
                </Grid>

                {/* <Grid item md={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                                        <KeyboardDatePicker
                                                        clearable
                                                        value={dataFinal}
                                                        placeholder="dd/mm/aaaa"
                                                        onChange={(date) => setDataFinal(date)}
                                                        invalidDateMessage="Data inválida"
                                                        minDateMessage="Data não pode ser menor que a data atual"
                                                        format="dd/MM/yyyy"
                                                        />
                                                        </MuiPickersUtilsProvider>
                                                      </Grid> */}
                <Grid item md={3}>
                  <TInputBase
                    // error
                    // helperText="Mensagem de apoio"
                    label="Faixa etária maior ou igual a"
                    name="faixa_etaria_maior"
                    placeholder="Faixa etária"
                    error={error.faixaEtariaMaior}
                    value={faixaEtariaMaior}
                    onChange={(e) =>
                      setFaixaEtariaMaior(
                        e.target.value.replace(/\D/g, '').slice(0, 2),
                      )
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <TSelectNative
                    name="faixa_etaria_maior_selecionar"
                    label="Selecione o ano, mês ou dia"
                    // error
                    // helperText="Mensagem de apoio"
                    id="faixaEtariaMaiorSelecionar"
                    options={[
                      {
                        value: 'day',
                        label: 'Dia(s)',
                      },
                      {
                        value: 'month',
                        label: 'Mês(es)',
                      },
                      {
                        value: 'year',
                        label: 'Ano(s)',
                      },
                    ]}
                    error={error.faixaEtariaMaiorSelecionar}
                    value={faixaEtariaMaiorSelecionar}
                    onChange={(e) =>
                      setFaixaEtariaMaiorSelecionar(e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <TInputBase
                    // error
                    // helperText="Mensagem de apoio"
                    label="Faixa etária menor ou igual a"
                    name="faixa_etaria_menor"
                    error={error.faixaEtariaMenor}
                    placeholder="Faixa etária"
                    value={faixaEtariaMenor}
                    onChange={(e) => setFaixaEtariaMenor(e.target.value)}
                  />
                </Grid>
                <Grid item md={3}>
                  <TSelectNative
                    label="Selecione o ano, mês ou dia"
                    name="faixa_etaria_menor_selecionar"
                    id="faixaEtariaMenorSelecionar"
                    // error
                    // helperText="Mensagem de apoio"
                    error={error.faixaEtariaMenorSelecionar}
                    options={[
                      {
                        value: 'day',
                        label: 'Dia(s)',
                      },
                      {
                        value: 'month',
                        label: 'Mês(es)',
                      },
                      {
                        value: 'year',
                        label: 'Ano(s)',
                      },
                    ]}
                    value={faixaEtariaMenorSelecionar}
                    onChange={(e) =>
                      setFaixaEtariaMenorSelecionar(e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TSelectNative
                    label="Sexo"
                    name="sexo"
                    // error
                    // helperText="Mensagem de apoio"
                    required
                    error={error.sexo}
                    options={[
                      {
                        value: 'masculino',
                        label: 'Masculino',
                      },
                      {
                        value: 'feminino',
                        label: 'Feminino',
                      },
                      {
                        value: 'ambos',
                        label: 'Ambos',
                      },
                    ]}
                    value={sexo}
                    onChange={(e) => {
                      setSexo(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TSelectNativeMultiple
                    label="Formulários"
                    name="formularios"
                    placeholder="Formulários"
                    id="formularios"
                    error={error.formularios}
                    // helperText="Mensagem de apoio"
                    required
                    options={anamneseForms}
                    value={formularios}
                    onChange={(e, newValue) => {
                      setFormularios(newValue);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TInputTimeBase
                    // error
                    // helperText="Mensagem de apoio"
                    required
                    label="Duração do atendimento"
                    name="duracao_atendimento"
                    id="duracaoAtendimento"
                    error={error.duracaoAtendimento}
                    placeholder="Duração do atendimento"
                    value={duracaoAtendimento}
                    onChange={(e) => {
                      setDuracaoAtendimento(e.target.value);
                      setIsPartialEdit(false);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TInputTimeBase
                    // error
                    // helperText="Mensagem de apoio"
                    required
                    label="Intervalo entre os atendimentos"
                    name="intervalo_atendimento"
                    id="intervaloAtendimento"
                    error={error.intervaloAtendimento}
                    placeholder="Intervalo entre os atendimentos"
                    value={intervaloAtendimento}
                    onChange={(e) => {
                      setIntervaloAtendimento(e.target.value);
                      setIsPartialEdit(false);
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  {/* {
                                                          days && days.length == 0 ? <Typography variant="h3" >Preencha pelo menos um dia de jornada.</Typography> : null
                                                        } */}
                  <DaysAndHours
                    defaultData={diasAgendadosDefault}
                    haveError={false}
                    setIsPartialEdit={setIsPartialEdit}
                  />
                </Grid>

                {days && days.length ? (
                  <Fragment>
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked={distribuir}
                          />
                        }
                        label="Distribuir atendimentos"
                        onChange={(e) => {
                          setDistribuir(e.target.checked);
                          distribuirAtendimentos(e.target.checked);
                          setIsPartialEdit(false);
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <DiaSemana
                        titulo="Segunda-feira"
                        total={totalHoras['segunda']}
                        idSemana={'segunda'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={segunda}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setSegunda(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.segunda}
                      />
                      <DiaSemana
                        titulo="Terça-feira"
                        total={totalHoras['terca']}
                        idSemana={'terca'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={terca}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setTerca(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.terca}
                      />
                      <DiaSemana
                        titulo="Quarta-feira"
                        total={totalHoras['quarta']}
                        idSemana={'quarta'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={quarta}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setQuarta(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.quarta}
                      />
                      <DiaSemana
                        titulo="Quinta-feira"
                        total={totalHoras['quinta']}
                        idSemana={'quinta'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={quinta}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setQuinta(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.quinta}
                      />
                      <DiaSemana
                        titulo="Sexta-feira"
                        total={totalHoras['sexta']}
                        idSemana={'sexta'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={sexta}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setSexta(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.sexta}
                      />
                      <DiaSemana
                        titulo="Sabado"
                        total={totalHoras['sabado']}
                        idSemana={'sabado'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={sabado}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setSabado(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.sabado}
                      />
                      <DiaSemana
                        titulo="Domingo"
                        total={totalHoras['domingo']}
                        idSemana={'domingo'}
                        primeiraConsulta
                        retorno
                        encaixe
                        value={domingo}
                        setValue={(
                          primeiraConsulta,
                          retorno,
                          encaixe,
                          total,
                        ) => {
                          setDomingo(primeiraConsulta, retorno, encaixe, total);
                          setIsPartialEdit(false);
                        }}
                        error={error.domingo}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} spacing={3}>
                      <Button type="submit" variant="contained" color="primary">
                        Editar agenda
                      </Button>
                    </Grid>
                  </Fragment>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </div>
      </form>
    </>
  );
}

AgendaEdit.defaultProps = {
  onBack: () => {},
  handleMenuItem: () => {},
  scheduleId: null,
};
