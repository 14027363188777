import axios from 'axios';
import env from 'react-dotenv';

function listAllUnidades(token) {
  return axios
    .get(`${env.API_URL}/medicalunit`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function listAllPerfis(token) {
  return axios
    .post(
      `${env.API_URL}/profile/list`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

/**
 * @param {Boolean} ignoreLocalStorage - ignora ou não o valor em storage e busca um novo
 * @param {number} timeToKeepCacheInHours - Tempo para manter o cache da user_dominios para um usuário (padrão 48 horas)
 */
async function userDominios(
  ignoreLocalStorage = false,
  timeToKeepCacheInHours = 48,
) {
  try {
    const cachedDominios = getStoredDominios();

    if (
      !ignoreLocalStorage &&
      cachedDominios &&
      isCacheValid(cachedDominios, timeToKeepCacheInHours)
    ) {
      return cachedDominios;
    }

    const storedDominios = await getUserDominiosFromApi();
    return storedDominios;
  } catch (error) {
    console.error(error);

    const storedDominios = await getUserDominiosFromApi();
    return storedDominios;
  }
}

async function getUserDominiosFromApi() {
  return await axios
    .get(`${env.API_URL}/user-dominios`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((response) => {
      const data = response.data;
      return setStoredDominios(data);
    });
}

function isCacheValid(cachedDominios, timeToKeepCacheInHours) {
  const cacheExpirationTime =
    Date.now() - 1000 * 60 * 60 * timeToKeepCacheInHours;
  return cachedDominios.last_update > cacheExpirationTime;
}

function getStoredDominios() {
  const storedDominios = localStorage.getItem('user_dominios');
  return storedDominios ? JSON.parse(storedDominios) : null;
}

function setStoredDominios(data) {
  const updatedData = { ...data, last_update: Date.now() };
  localStorage.setItem('user_dominios', JSON.stringify(updatedData));
  return updatedData;
}

export async function getRelationShipDegree(token) {
  const { data } = await axios.get(`${env.API_URL}/relationship-degree/list`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-domain': env.X_DOMAIN,
      'x-auth-token': token,
    },
  });

  return data;
}

export function listCityByStateId(stateId) {
  return axios
    .get(`${env.API_URL}/city?state_id=${stateId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function getCityById(cityId) {
  return axios
    .get(`${env.API_URL}/city/${cityId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function getStatesList() {
  return axios
    .get(`${env.API_URL}/api/state`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function getModelInvite(token) {
  return axios
    .get(`${env.API_URL}/model-invite?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function getStates(token) {
  return axios
    .get(`${env.API_URL}/state`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getCitys(token) {
  return axios
    .get(`${env.API_URL}/city`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getCountrys(token) {
  return axios
    .get(`${env.API_URL}/country`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export function getEtnias(token) {
  return axios
    .get(`${env.API_URL}/ethnicity`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data);
}

export { listAllPerfis, listAllUnidades, userDominios };

