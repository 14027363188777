import React from 'react';
import { Component } from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Backdrop,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DInput from '../shared/DInput';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

import SupportIcon from '../../assets/img-icon/support_agent.png';

import QuestionCreate from './component/QuestionCreate';

// Import Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// API - imports
import {
  getAllCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
} from './api/faqCategory';
import {
  getAllQuestions,
  getAllQuestionsByTitle,
  getQuestionById,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  notifyNewQuestion,
} from './api/faqQuestion';
import { linkQuestion, unlinkQuestion } from './api/faqLinker';
import {
  getAllContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
} from './api/faqContact';

// formatters and Validators
import { requiredEmail, validaTel } from './helpers/validacoes';
import { mascaraTelefone } from './helpers/formatacao';
import { useTheme } from '@material-ui/styles';

const styles = (theme) => ({
  titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
  flex: { display: 'flex' },
  listItem: {
    padding: '16px 0',
    margin: 0,
    borderTop: '1px solid #c7c7c7',
  },
  inactiveListButton: {
    color: theme.palette.support.error,
    borderColor: theme.palette.support.error,
    marginRight: 16,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
  },
  addButton: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  addQuestion: {
    color: theme.palette.background.snow,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    marginTop: 12,
    letterSpacing: 0,
  },
  goEditor: {
    color: theme.palette.background.snow,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
  },
  btConfirmDelete: {
    color: theme.palette.background.snow,
    background: theme.palette.support.error,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
  },
  btSave: {
    color: theme.palette.background.snow,
    background: theme.palette.primary.light,
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '6px 20px',
    letterSpacing: 0,
    marginLeft: 8,
  },
  btBack: {
    color: theme.palette.support.error,
    borderColor: theme.palette.support.error,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  btBack2: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  detailButton: {
    background: 'none !important',
    color: theme.palette.primary.light,
    textTransform: 'none',
    marginLeft: -8,
    marginTop: 8,
    marginBottom: -4,
    borderRadius: 20,
    padding: '4px 12px',
  },
  detailButton2: {
    background: 'none !important',
    color: theme.palette.primary.light,
    textTransform: 'none',
    marginLeft: 24,
    marginTop: 20,
    borderRadius: 20,
    padding: '4px 12px',
  },
  activeBox: {
    color: theme.palette.background.snow,
    background: theme.palette.primary.light,
    marginBottom: 8,
  },
  activeBox2: {
    background: theme.palette.background.cloud,
    marginBottom: 8,
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
    minWidth: 'auto !important',
  },
  deleteButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
    paddingRight: 20,
  },
  iconDelete: {
    color: theme.palette.support.error,
  },
  emptyBox: {
    padding: 16,
    display: 'flex',
    color: '#c7c7c7',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundPrimaryLight: {
    background: theme.palette.primary.light,
  },
  backgroundSnow: {
    background: theme.palette.background.snow,
  },
});

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCategories: false,
      loadingContacts: false,
      categories: [],
      contacts: [],
      isList: true,
      selectedCategory: null,
      selectedQuestion: null,
      isEditAnswer: false,
      modalQuestion: false,
      createContact: false,
      contact: '',
      contactType: 0,
      oldName: '',
      isAddCategory: false,
    };
  }

  componentDidMount() {
    this.listCategories();
    this.listContacts();
  }

  listCategories() {
    this.setState({ ...this.state, categories: [], loadingCategories: true });

    getAllCategories(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.categories.map((row) => {
          var new_state = this.state.categories;
          var expandAnswerArray = [];
          var modalQuestion = [];

          row.questions.map((question) => {
            expandAnswerArray.push(false);
          });

          row.questions.map((question) => {
            modalQuestion.push(false);
          });

          new_state.push({
            id: row.id,
            name: row.name,
            isEdit: false,
            questions: row.questions,
            isOptionList: null,
            isAccordion: false,
            isExpandAnswer: expandAnswerArray,
            isConfirmModal: false,
            isConfirmModalQuestion: modalQuestion,
            isLoading: false,
          });

          this.setState({
            ...this.state,
            categories: new_state,
            loadingCategories: false,
          });
        });
      } else {
        this.setState({
          ...this.state,
          categories: [],
          loadingCategories: false,
        });
        if (res?.status_code != 401)
          toast.error('Ocorreu um erro consultar as categorias.');
      }
    });
  }

  saveCategory(category, index) {
    if (category.name.trim() == '') {
      toast.error('Nome da categoria não pode ser vazio.');
    } else {
      var new_state = this.state.categories;
      new_state[index].isLoading = !category.isLoading;
      this.setState({ ...this.state, categories: new_state });

      var payloadCreate = {
        name: category.name.trim(),
      };

      if (category.id) {
        updateCategory(
          localStorage.getItem('token'),
          payloadCreate,
          category.id,
        ).then((res) => {
          if (res.status === true) {
            new_state[index].isLoading = !category.isLoading;
            new_state[index].isEdit = !category.isEdit;
            this.setState({
              ...this.state,
              isAddCategory: false,
              categories: new_state,
            });

            toast.success('Categoria alterada com sucesso!');
          } else {
            if (res.status === false) {
              if (res.message) {
                toast.error(res.message);
              } else {
                toast.error('Ocorreu um erro ao atualizar a categoria.');
              }
            }

            new_state[index].isLoading = !category.isLoading;
            this.setState({
              ...this.state,
              isAddCategory: false,
              categories: new_state,
            });
          }
        });
      } else {
        createCategory(localStorage.getItem('token'), payloadCreate).then(
          (res) => {
            if (res.status === true) {
              new_state[index].id = res.category.id;
              new_state[index].isLoading = !category.isLoading;
              new_state[index].isEdit = !category.isEdit;
              this.setState({
                ...this.state,
                isAddCategory: false,
                categories: new_state,
              });

              toast.success('Categoria cadastrada com sucesso!');
            } else {
              if (res.status === false) {
                if (res.message) {
                  toast.error(res.message);
                } else {
                  toast.error('Ocorreu um erro ao cadastrar a categoria.');
                }
              }

              new_state[index].isLoading = !category.isLoading;
              this.setState({
                ...this.state,
                isAddCategory: false,
                categories: new_state,
              });
            }
          },
        );
      }
    }
  }

  confirmDeleteCategory(category, index) {
    var new_state = this.state.categories;
    new_state[index].isLoading = !category.isLoading;
    this.setState({ ...this.state, categories: new_state });

    deleteCategory(localStorage.getItem('token'), category.id).then((res) => {
      console.log(res);
      if (res.status) {
        new_state[index].isOptionList = null;
        new_state[index].isConfirmModal = false;
        new_state.splice(index, 1);

        this.setState({ ...this.state, categories: new_state });

        toast.success('Categoria deletada com sucesso!');
      } else {
        new_state[index].isLoading = !category.isLoading;
        new_state[index].isOptionList = null;

        this.setState({ ...this.state, categories: new_state });

        toast.error('Ocorreu um erro deletar a categoria.');
      }
    });
  }

  saveQuestion(title, value, category, question) {
    var payload = {
      title: title,
      description: value,
      type: 'text',
    };

    if (question && question.id) {
      updateQuestion(localStorage.getItem('token'), payload, question.id).then(
        (res) => {
          if (res.status === true) {
            this.listCategories();
            this.setState({
              ...this.state,
              isList: true,
              selectedCategory: null,
              selectedQuestion: null,
              isEditAnswer: false,
            });
            toast.success('Pergunta alterada com sucesso!');
          } else {
            if (res.status === false) {
              if (res.message == 'já cadastrado no sistema') {
                toast.error(`${title} já cadastrada no sistema`);
              } else {
                toast.error('Ocorreu um erro atualizar a pergunta.');
              }
            }
          }
        },
      );
    } else {
      createQuestion(localStorage.getItem('token'), payload).then((res) => {
        if (res.status === true) {
          var payloadLinker = {
            faq_id: res.question.id,
            category_id: category.id,
          };

          var question_id = res.question.id;

          linkQuestion(localStorage.getItem('token'), payloadLinker).then(
            (res) => {
              if (res.status === true) {
                this.listCategories();
                this.setState({
                  ...this.state,
                  isList: true,
                  selectedCategory: null,
                  selectedQuestion: null,
                  isEditAnswer: false,
                });
                toast.success('Pergunta cadastrada com sucesso!');

                notifyNewQuestion(
                  localStorage.getItem('token'),
                  question_id,
                ).then((res) => {
                  if (res.status) {
                    console.log('Notificado com sucesso!');
                  } else {
                    console.log('Erro ao notificar!');
                  }
                });
              } else {
                if (res.status === false) {
                  if (typeof res.status_code != 'undefined') {
                    toast.error(res.message);
                  } else {
                    if (res.message == 'já cadastrado no sistema') {
                      toast.error(`${title} já cadastrada no sistema`);
                    } else {
                      toast.error('Ocorreu um erro cadastrar a pergunta.');
                    }
                  }
                }
              }
            },
          );
        } else {
          if (res.status === false) {
            if (typeof res.status_code != 'undefined') {
              toast.error(res.message);
            } else {
              if (res.message == 'já cadastrado no sistema') {
                toast.error(`${title} já cadastrada no sistema`);
              } else {
                toast.error('Ocorreu um erro cadastrar a pergunta.');
              }
            }
          }
        }
      });
    }
  }

  confirmDeleteQuestion(question, indexCategory, indexQuestion) {
    var new_state = this.state.categories;
    new_state[indexCategory].isLoading = !new_state[indexCategory].isLoading;
    this.setState({ ...this.state, categories: new_state });

    deleteQuestion(localStorage.getItem('token'), question.id).then((res) => {
      console.log(res);
      if (res.status) {
        new_state[indexCategory].isLoading =
          !new_state[indexCategory].isLoading;
        new_state[indexCategory].isConfirmModalQuestion[indexQuestion] = false;
        new_state[indexCategory].questions.splice(indexQuestion, 1);

        this.setState({ ...this.state, categories: new_state });

        toast.success('Pergunta deletada com sucesso!');
      } else {
        new_state[indexCategory].isLoading =
          !new_state[indexCategory].isLoading;

        this.setState({ ...this.state, categories: new_state });

        toast.error('Ocorreu um erro deletar a pergunta.');
      }
    });
  }

  listContacts() {
    this.setState({ ...this.state, contacts: [], loadingContacts: true });

    getAllContacts(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        res.contacts.map((row) => {
          var new_state = this.state.contacts;

          new_state.push({
            id: row.id,
            name: row.value,
            type: row.type,
            isEdit: false,
            isOptionList: null,
            isAccordion: false,
            isConfirmModal: false,
            isLoading: false,
          });

          this.setState({
            ...this.state,
            contacts: new_state,
            loadingContacts: false,
          });
        });
      } else {
        this.setState({ ...this.state, contacts: [], loadingContacts: false });
        if (res?.status_code != 401)
          toast.error('Ocorreu um erro consultar os contatos.');
      }
    });
  }

  insertContact(contact, type) {
    if (type == 'email') {
      if (contact == '' || typeof contact == 'undefined') {
        toast.error('E-mail é obrigatório!');
        return;
      } else {
        if (!requiredEmail(contact)) {
          toast.error('E-mail inválido!');
          return;
        }
      }
    } else {
      if (contact == '' || typeof contact == 'undefined') {
        toast.error('Telefone é obrigatório!');
        return;
      } else {
        if (!validaTel(contact)) {
          toast.error('Telefone inválido!');
          return;
        }
      }
    }

    var payloadCreate = {
      type: type,
      value: contact,
    };

    createContact(localStorage.getItem('token'), payloadCreate).then((res) => {
      if (res.status === true) {
        var new_state = this.state.contacts;

        new_state.push({
          id: res.contact.id,
          name: res.contact.value,
          type: res.contact.type,
          isEdit: false,
          isOptionList: null,
          isAccordion: false,
          isConfirmModal: false,
          isLoading: false,
        });

        this.setState({
          ...this.state,
          contacts: new_state,
          createContact: false,
          contact: '',
        });

        toast.success('Contato cadastrado com sucesso!');
      } else {
        if (res.status === false) {
          if (res.message == 'já cadastrado no sistema') {
            toast.error(`${contact.name} já cadastrado no sistema`);
          } else {
            toast.error('Ocorreu um erro cadastrar o contato.');
          }
        }
      }
    });
  }

  editContact(contact, index) {
    if (contact.type == 'email') {
      if (!requiredEmail(contact.name)) {
        toast.error('E-mail inválido!');
        return;
      }
    } else {
      if (!validaTel(contact.name)) {
        toast.error('Telefone inválido!');
        return;
      }
    }

    var new_state = this.state.contacts;
    new_state[index].isLoading = !contact.isLoading;
    this.setState({ ...this.state, contacts: new_state });

    var payload = {
      type: contact.type,
      value: contact.name,
    };

    updateContact(localStorage.getItem('token'), payload, contact.id).then(
      (res) => {
        if (res.status === true) {
          new_state[index].isLoading = !contact.isLoading;
          new_state[index].isEdit = !contact.isEdit;
          this.setState({ ...this.state, contacts: new_state });

          toast.success('Contato alterado com sucesso!');
        } else {
          if (res.status === false) {
            if (res.message == 'já cadastrado no sistema') {
              toast.error(`${contact.name} já cadastrado no sistema`);
            } else {
              toast.error('Ocorreu um erro atualizar o contato.');
            }
          }

          new_state[index].isLoading = !contact.isLoading;
          this.setState({ ...this.state, categories: new_state });
        }
      },
    );
  }

  confirmDeleteContact(contact, index) {
    var new_state = this.state.contacts;
    new_state[index].isLoading = !contact.isLoading;
    this.setState({ ...this.state, contacts: new_state });

    deleteContact(localStorage.getItem('token'), contact.id).then((res) => {
      console.log(res);
      if (res.status) {
        new_state[index].isOptionList = null;
        new_state[index].isConfirmModal = false;
        new_state.splice(index, 1);

        this.setState({ ...this.state, contacts: new_state });

        toast.success('Contato deletado com sucesso!');
      } else {
        new_state[index].isLoading = !contact.isLoading;

        this.setState({ ...this.state, contacts: new_state });

        toast.error('Ocorreu um erro deletar o contato.');
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.isList ? (
          <Container className="tema-light">
            <Backdrop
              style={{ zIndex: 3000, color: 'white' }}
              open={this.state.loadingCategories}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Typography className={classes.titleTop} variant="h3">
              FAQ - Perguntas Frequentes
            </Typography>

            <Card style={{ background: '#f9f9f9', marginBottom: 40 }}>
              <CardContent style={{ padding: 16 }}>
                <Grid container>
                  <Grid item md={3}>
                    <Typography variant="h4">Categorias</Typography>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    style={{ display: 'flex' }}
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="outlined"
                      className={classes.addButton}
                      size="sm"
                      startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                      disabled={this.state.isAddCategory ? true : false}
                      onClick={(e) => {
                        var new_category = this.state.categories;
                        new_category.unshift({
                          id: null,
                          name: '',
                          isEdit: true,
                          questions: [],
                          isOptionList: null,
                          isAccordion: false,
                          isExpandAnswer: [],
                          isConfirmModal: false,
                          isConfirmModalQuestion: [],
                          isLoading: false,
                        });

                        this.setState({
                          ...this.state,
                          isAddCategory: true,
                          categories: new_category,
                        });
                      }}
                    >
                      <span style={{ marginTop: 2, display: 'block' }}>
                        Criar categoria
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {this.state.categories.length ? (
              this.state.categories.map((row, index) => (
                <>
                  <Card
                    className={
                      row.isAccordion ? classes.activeBox : classes.activeBox2
                    }
                  >
                    {row.isEdit ? (
                      <CardContent
                        style={{
                          padding: 8,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className={classes.backgroundPrimaryLight}
                          style={{
                            height: 55,
                            width: 5,
                            borderRadius: 8,
                            marginRight: 8,
                          }}
                        ></div>
                        <DInput
                          id="name"
                          label="Nome da categoria"
                          placeholder="Digite o nome da nova categoria"
                          type="text"
                          value={row.name}
                          defaultValue={row.name}
                          fullWidth={true}
                          inputProps={{ maxLength: 50 }}
                          positionIcon="end"
                          style={{ width: 360 }}
                          onChange={(e) => {
                            var new_value = this.state.categories;
                            new_value[index].name = e.target.value;

                            this.setState({
                              ...this.state,
                              categories: new_value,
                            });
                          }}
                        />
                        {this.state.isAddCategory && (
                          <Button
                            size="sm"
                            startIcon={<CloseIcon style={{ fontSize: 20 }} />}
                            classsName={classes.saveButton}
                            style={{ minWidth: 'auto' }}
                            onClick={(e) => {
                              var new_state = this.state.categories;
                              new_state[index].isOptionList = null;
                              new_state[index].isConfirmModal = false;
                              new_state.splice(index, 1);

                              this.setState({
                                ...this.state,
                                isAddCategory: false,
                                categories: new_state,
                              });
                            }}
                          >
                            {row.isLoading ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              'Cancelar'
                            )}
                          </Button>
                        )}
                        <Button
                          size="sm"
                          startIcon={<SaveIcon style={{ fontSize: 20 }} />}
                          classsName={classes.saveButton}
                          style={{ minWidth: 'auto' }}
                          onClick={(e) => {
                            const arr = this.state.categories.filter(
                              (c) =>
                                c.id != row.id &&
                                c.name.toUpperCase() == row.name.toUpperCase(),
                            );
                            if (arr.length > 0) {
                              toast.warning(
                                `Já existe uma categoria com o nome ${row.name}`,
                              );
                              var new_value = this.state.categories;
                              new_value[index].name = this.state.oldName;
                              this.setState({
                                ...this.state,
                                categories: new_value,
                              });
                              return;
                            }

                            if (!row.isLoading) {
                              this.saveCategory(row, index);
                            }
                          }}
                        >
                          {row.isLoading ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            'Salvar'
                          )}
                        </Button>
                      </CardContent>
                    ) : (
                      <CardContent
                        style={{
                          padding: 8,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            md={11}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                            }}
                            onClick={(e) => {
                              var new_category = this.state.categories;
                              new_category[index].isAccordion =
                                !new_category[index].isAccordion;
                              new_category[index].isExpandAnswer = [];

                              this.setState({
                                ...this.state,
                                categories: new_category,
                              });
                            }}
                          >
                            <div
                              className={
                                row.isAccordion
                                  ? classes.backgroundSnow
                                  : classes.backgroundPrimaryLight
                              }
                              style={{
                                height: 55,
                                width: 5,
                                borderRadius: 8,
                                marginRight: 18,
                              }}
                            ></div>
                            <Typography variant="h5">{row.name}</Typography>
                          </Grid>

                          <Grid
                            item
                            md={1}
                            style={{ display: 'flex', justifyContent: 'end' }}
                          >
                            <div>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                style={row.isAccordion ? { color: '#fff' } : {}}
                                onClick={(e) => {
                                  var new_category = this.state.categories;
                                  new_category[index].isOptionList =
                                    e.currentTarget;
                                  this.setState({
                                    ...this.state,
                                    categories: new_category,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                keepMounted
                                anchorEl={
                                  this.state.categories[index].isOptionList
                                }
                                open={Boolean(
                                  this.state.categories[index].isOptionList,
                                )}
                                onClose={(e) => {
                                  var new_category = this.state.categories;
                                  new_category[index].isOptionList = null;
                                  this.setState({
                                    ...this.state,
                                    categories: new_category,
                                  });
                                }}
                                PaperProps={{
                                  style: {
                                    width: '185px',
                                  },
                                }}
                              >
                                <Grid
                                  item
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    padding: 15,
                                  }}
                                >
                                  <CloseIcon
                                    fontSize="small"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      var new_category = this.state.categories;
                                      new_category[index].isOptionList = null;
                                      this.setState({
                                        ...this.state,
                                        categories: new_category,
                                      });
                                    }}
                                  />
                                </Grid>
                                <MenuItem
                                  onClick={(e) => {
                                    var new_category = this.state.categories;
                                    new_category[index].isOptionList = null;

                                    this.setState({
                                      ...this.state,
                                      isList: false,
                                      category: new_category,
                                      selectedCategory: row,
                                    });
                                  }}
                                >
                                  <ListItemIcon style={{ minWidth: '30px' }}>
                                    <AddCircleIcon
                                      fontSize="small"
                                      style={{ color: 'black' }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary="Criar pergunta" />
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    var new_category = this.state.categories;
                                    new_category[index].isEdit = !row.isEdit;
                                    new_category[index].isOptionList = null;
                                    new_category[index].isAccordion = false;

                                    this.setState({
                                      ...this.state,
                                      categories: new_category,
                                      oldName: new_category[index].name,
                                    });
                                  }}
                                >
                                  <ListItemIcon style={{ minWidth: '30px' }}>
                                    <CreateIcon
                                      fontSize="small"
                                      style={{ color: 'black' }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary="Editar categoria" />
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    var new_category = this.state.categories;
                                    new_category[index].isConfirmModal = true;
                                    new_category[index].isOptionList = null;

                                    this.setState({
                                      ...this.state,
                                      categories: new_category,
                                    });
                                  }}
                                >
                                  <ListItemIcon style={{ minWidth: '30px' }}>
                                    <CancelIcon
                                      fontSize="small"
                                      className={classes.iconDelete}
                                    />
                                  </ListItemIcon>
                                  Excluir categoria
                                </MenuItem>
                              </Menu>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    )}

                    <Dialog open={this.state.categories[index].isConfirmModal}>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        style={{
                          color: '#949494',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }}
                        onClick={(e) => {
                          var new_category = this.state.categories;
                          new_category[index].isConfirmModal = false;

                          this.setState({
                            ...this.state,
                            categories: new_category,
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent style={{ padding: 16, width: 267 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            marginBottom: 16,
                          }}
                        >
                          Confirmar a exclusão dessa
                          <br />
                          categoria?
                        </Typography>
                        <Grid container>
                          <Grid
                            item
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Button
                              variant="outlined"
                              className={classes.btBack}
                              size="sm"
                              onClick={(e) => {
                                var new_category = this.state.categories;
                                new_category[index].isConfirmModal = false;

                                this.setState({
                                  ...this.state,
                                  categories: new_category,
                                });
                              }}
                            >
                              Voltar
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.btConfirmDelete}
                              size="sm"
                              onClick={(e) => {
                                this.confirmDeleteCategory(row, index);
                              }}
                            >
                              {row.isLoading ? (
                                <CircularProgress
                                  size={15}
                                  style={{ color: '#fff' }}
                                />
                              ) : (
                                'Confirmar excluir'
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                  </Card>

                  {row.isAccordion ? (
                    <>
                      {row.questions.length > 0 &&
                      row.questions.find((value) => value != null) ? (
                        <>
                          {row.questions.map((question, indexQuestion) => {
                            return (
                              <>
                                {question ? (
                                  <Card
                                    style={{
                                      background: '#f9f9f9',
                                      marginBottom: 8,
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        padding: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Grid
                                          item
                                          md={8}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                          }}
                                        >
                                          <div
                                            className={
                                              classes.backgroundPrimaryLight
                                            }
                                            style={{
                                              height: 64,
                                              width: 5,
                                              borderRadius: 8,
                                              marginRight: 8,
                                              marginLeft: 32,
                                            }}
                                          ></div>
                                          <div>
                                            <Typography
                                              style={{ fontWeight: 500 }}
                                            >
                                              {question.title}
                                            </Typography>

                                            {row.isExpandAnswer[
                                              indexQuestion
                                            ] ? (
                                              <Button
                                                size="sm"
                                                className={classes.detailButton}
                                                onClick={(e) => {
                                                  var new_category =
                                                    this.state.categories;
                                                  new_category[
                                                    index
                                                  ].isExpandAnswer[
                                                    indexQuestion
                                                  ] =
                                                    !new_category[index]
                                                      .isExpandAnswer[
                                                      indexQuestion
                                                    ];

                                                  this.setState({
                                                    ...this.state,
                                                    categories: new_category,
                                                  });
                                                }}
                                              >
                                                Ocultar resposta
                                              </Button>
                                            ) : (
                                              <Button
                                                size="sm"
                                                className={classes.detailButton}
                                                onClick={(e) => {
                                                  var new_category =
                                                    this.state.categories;
                                                  new_category[
                                                    index
                                                  ].isExpandAnswer[
                                                    indexQuestion
                                                  ] =
                                                    !new_category[index]
                                                      .isExpandAnswer[
                                                      indexQuestion
                                                    ];

                                                  this.setState({
                                                    ...this.state,
                                                    categories: new_category,
                                                  });
                                                }}
                                              >
                                                Ver resposta
                                              </Button>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item md={4}>
                                          <div
                                            style={{
                                              marginTop: -24,
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <IconButton
                                              aria-label="more"
                                              aria-controls="long-menu"
                                              aria-haspopup="true"
                                              className={classes.iconDelete}
                                              onClick={(e) => {
                                                var new_category =
                                                  this.state.categories;
                                                new_category[
                                                  index
                                                ].isConfirmModalQuestion[
                                                  indexQuestion
                                                ] = true;

                                                this.setState({
                                                  ...this.state,
                                                  categories: new_category,
                                                });
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                            <Button
                                              variant="contained"
                                              className={classes.goEditor}
                                              size="sm"
                                              onClick={(e) => {
                                                this.setState({
                                                  ...this.state,
                                                  isList: false,
                                                  selectedCategory: row,
                                                  selectedQuestion: question,
                                                  isEditAnswer: true,
                                                });
                                              }}
                                            >
                                              Ir para o editor
                                            </Button>
                                          </div>
                                        </Grid>

                                        {row.isExpandAnswer[indexQuestion] ? (
                                          <Grid
                                            item
                                            md={12}
                                            style={{ padding: 16 }}
                                          >
                                            <div
                                              style={{ marginLeft: 32 }}
                                              dangerouslySetInnerHTML={{
                                                __html: question.description,
                                              }}
                                            />
                                            <Button
                                              size="sm"
                                              className={classes.detailButton2}
                                              onClick={(e) => {
                                                var new_category =
                                                  this.state.categories;
                                                new_category[
                                                  index
                                                ].isExpandAnswer[
                                                  indexQuestion
                                                ] =
                                                  !new_category[index]
                                                    .isExpandAnswer[
                                                    indexQuestion
                                                  ];

                                                this.setState({
                                                  ...this.state,
                                                  categories: new_category,
                                                });
                                              }}
                                            >
                                              Ocultar resposta
                                            </Button>
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>

                                      <Dialog
                                        open={
                                          this.state.categories[index]
                                            .isConfirmModalQuestion[
                                            indexQuestion
                                          ]
                                        }
                                      >
                                        <IconButton
                                          aria-label="more"
                                          aria-controls="long-menu"
                                          aria-haspopup="true"
                                          style={{
                                            color: '#949494',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                          }}
                                          onClick={(e) => {
                                            var new_category =
                                              this.state.categories;
                                            new_category[
                                              index
                                            ].isConfirmModalQuestion[
                                              indexQuestion
                                            ] = false;

                                            this.setState({
                                              ...this.state,
                                              categories: new_category,
                                            });
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                        <DialogContent
                                          style={{ padding: 16, width: 267 }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 400,
                                              marginBottom: 16,
                                            }}
                                          >
                                            Confirmar a exclusão dessa
                                            <br />
                                            pergunta?
                                          </Typography>
                                          <Grid container>
                                            <Grid
                                              item
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                              }}
                                            >
                                              <Button
                                                variant="outlined"
                                                className={classes.btBack}
                                                size="sm"
                                                onClick={(e) => {
                                                  var new_category =
                                                    this.state.categories;
                                                  new_category[
                                                    index
                                                  ].isConfirmModalQuestion[
                                                    indexQuestion
                                                  ] = false;

                                                  this.setState({
                                                    ...this.state,
                                                    categories: new_category,
                                                  });
                                                }}
                                              >
                                                Voltar
                                              </Button>
                                              <Button
                                                variant="contained"
                                                className={
                                                  classes.btConfirmDelete
                                                }
                                                size="sm"
                                                onClick={(e) => {
                                                  if (!row.isLoading) {
                                                    this.confirmDeleteQuestion(
                                                      question,
                                                      index,
                                                      indexQuestion,
                                                    );
                                                  }
                                                }}
                                              >
                                                {row.isLoading ? (
                                                  <CircularProgress
                                                    size={15}
                                                    style={{ color: '#fff' }}
                                                  />
                                                ) : (
                                                  'Confirmar excluir'
                                                )}
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </DialogContent>
                                      </Dialog>
                                    </CardContent>
                                  </Card>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <Card
                            style={{ background: '#f9f9f9', marginBottom: 8 }}
                          >
                            <CardContent className={classes.emptyBox}>
                              <InfoOutlinedIcon />
                              <Typography
                                style={{ fontWeight: 500, marginTop: 8 }}
                              >
                                Ainda não há perguntas cadastradas nesta
                                categoria
                              </Typography>
                              <Button
                                variant="contained"
                                className={classes.addQuestion}
                                size="sm"
                                startIcon={
                                  <AddCircleIcon style={{ fontSize: 20 }} />
                                }
                                onClick={(e) => {
                                  this.setState({
                                    ...this.state,
                                    isList: false,
                                    selectedCategory: row,
                                  });
                                }}
                              >
                                <span
                                  style={{ marginTop: 2, display: 'block' }}
                                >
                                  Criar Pergunta
                                </span>
                              </Button>
                            </CardContent>
                          </Card>
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))
            ) : (
              <>
                <Card style={{ background: '#f9f9f9', marginBottom: 8 }}>
                  <CardContent className={classes.emptyBox}>
                    <InfoOutlinedIcon />
                    <Typography style={{ fontWeight: 500, marginTop: 8 }}>
                      Ainda não há categorias cadastradas
                    </Typography>
                  </CardContent>
                </Card>
              </>
            )}

            <Card
              style={{ background: '#f9f9f9', marginBottom: 40, marginTop: 40 }}
            >
              <CardContent style={{ padding: 16 }}>
                <Grid container>
                  <Grid item md={3}>
                    <Typography variant="h4">Contato</Typography>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    style={{ display: 'flex' }}
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="outlined"
                      className={classes.addButton}
                      size="sm"
                      startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                      onClick={(e) => {
                        this.setState({ ...this.state, createContact: true });
                      }}
                    >
                      <span style={{ marginTop: 2, display: 'block' }}>
                        Adicionar contato
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {this.state.contacts.length ? (
              this.state.contacts.map((row, index) => (
                <>
                  <Card style={{ background: '#f9f9f9', marginBottom: 8 }}>
                    <CardContent
                      style={{
                        padding: 16,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid
                          item
                          md={10}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: 5,
                          }}
                        >
                          <Typography
                            style={{ minWidth: 'max-content' }}
                            variant="h5"
                          >
                            {row.type == 'email' ? 'E-mail: ' : 'Telefone: '}
                          </Typography>
                          {row.isEdit ? (
                            <DInput
                              id="name"
                              type="text"
                              value={row.name}
                              defaultValue={row.name}
                              fullWidth={true}
                              inputProps={{ maxLength: 50 }}
                              positionIcon="end"
                              style={{ width: 360, marginTop: 0 }}
                              onChange={(e) => {
                                var new_value = this.state.contacts;
                                new_value[index].name =
                                  new_value[index].type == 'email'
                                    ? e.target.value
                                    : mascaraTelefone(e.target.value);

                                this.setState({
                                  ...this.state,
                                  contacts: new_value,
                                });
                              }}
                            />
                          ) : (
                            <span>{row.name}</span>
                          )}
                        </Grid>
                        <Grid
                          item
                          md={2}
                          style={{ display: 'flex', justifyContent: 'end' }}
                        >
                          {row.isEdit ? (
                            <>
                              <Button
                                size="sm"
                                startIcon={
                                  <CreateIcon style={{ fontSize: 20 }} />
                                }
                                classsName={classes.saveButton}
                                onClick={(e) => {
                                  this.editContact(row, index);
                                }}
                              >
                                {row.isLoading ? (
                                  <CircularProgress size={15} color="inherit" />
                                ) : (
                                  'Salvar alterações'
                                )}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                size="sm"
                                startIcon={
                                  <DeleteIcon style={{ fontSize: 20 }} />
                                }
                                className={classes.deleteButton}
                                onClick={(e) => {
                                  var new_contacts = this.state.contacts;
                                  new_contacts[index].isConfirmModal = true;

                                  this.setState({
                                    ...this.state,
                                    contacts: new_contacts,
                                  });
                                }}
                              >
                                {row.isLoading ? (
                                  <CircularProgress size={15} color="inherit" />
                                ) : (
                                  'Excluir'
                                )}
                              </Button>
                              <Button
                                size="sm"
                                startIcon={
                                  <CreateIcon style={{ fontSize: 20 }} />
                                }
                                className={classes.editButton}
                                onClick={(e) => {
                                  var new_contact = this.state.contacts;
                                  new_contact[index].isEdit = !row.isEdit;

                                  this.setState({
                                    ...this.state,
                                    contacts: new_contact,
                                  });
                                }}
                              >
                                Editar
                              </Button>
                            </>
                          )}

                          <Dialog
                            open={this.state.contacts[index].isConfirmModal}
                          >
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              style={{
                                color: '#949494',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}
                              onClick={(e) => {
                                var new_contacts = this.state.contacts;
                                new_contacts[index].isConfirmModal = false;

                                this.setState({
                                  ...this.state,
                                  contacts: new_contacts,
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <DialogContent style={{ padding: 16, width: 267 }}>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  marginBottom: 16,
                                }}
                              >
                                Confirmar a exclusão desse
                                <br />
                                contato?
                              </Typography>
                              <Grid container>
                                <Grid
                                  item
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    className={classes.btBack}
                                    size="sm"
                                    onClick={(e) => {
                                      var new_contacts = this.state.contacts;
                                      new_contacts[index].isConfirmModal =
                                        false;

                                      this.setState({
                                        ...this.state,
                                        contacts: new_contacts,
                                      });
                                    }}
                                  >
                                    Voltar
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className={classes.btConfirmDelete}
                                    size="sm"
                                    onClick={(e) => {
                                      this.confirmDeleteContact(row, index);
                                    }}
                                  >
                                    {row.isLoading ? (
                                      <CircularProgress
                                        size={15}
                                        style={{ color: '#fff' }}
                                      />
                                    ) : (
                                      'Confirmar excluir'
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogContent>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ))
            ) : (
              <>
                <Card style={{ background: '#f9f9f9', marginBottom: 40 }}>
                  <CardContent className={classes.emptyBox}>
                    <InfoOutlinedIcon />
                    <Typography style={{ fontWeight: 500, marginTop: 8 }}>
                      Ainda não há contatos cadastrados
                    </Typography>
                  </CardContent>
                </Card>
              </>
            )}

            <Dialog open={this.state.createContact}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                style={{
                  color: '#949494',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    createContact: false,
                    contact: '',
                    contactType: 0,
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent
                style={{
                  padding: 16,
                  width: 482,
                  textAlign: 'center',
                  color: '#4f4f4f',
                }}
              >
                <img src={SupportIcon} />

                <Typography
                  style={{
                    fontSize: 20,
                    lineHeight: '30px',
                    fontWeight: 700,
                    marginBottom: 24,
                    marginTop: 12,
                  }}
                >
                  Contato para dúvidas
                </Typography>

                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: 400,
                    marginBottom: 50,
                  }}
                >
                  este contato ficará disponível caso o usuário tenha
                  <br />
                  uma pergunta que não foi respondida na FAQ
                </Typography>

                <RadioGroup
                  row
                  style={{ marginBottom: 52 }}
                  defaultValue="email"
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="E-mail"
                    value="email"
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        contactType: 0,
                        contact: '',
                      });
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Telefone"
                    style={{ marginLeft: 40 }}
                    value="phone"
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        contactType: 1,
                        contact: '',
                      });
                    }}
                  />
                </RadioGroup>

                <DInput
                  id={
                    this.state.contactType === 0
                      ? 'contact_email'
                      : 'contact_phone'
                  }
                  label={
                    this.state.contactType === 0
                      ? 'Insira um endereço de email...'
                      : 'Insira um número de telefone...'
                  }
                  placeholder={
                    this.state.contactType === 0
                      ? 'duvidasfrequentes@telemed.com.br'
                      : '(00) 00000-000'
                  }
                  type="text"
                  fullWidth={true}
                  inputProps={
                    this.state.contactType === 0
                      ? { maxLength: 254 }
                      : { maxLength: 15 }
                  }
                  style={{ marginBottom: 77 }}
                  value={this.state.contact}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      contact:
                        this.state.contactType === 0
                          ? e.target.value
                          : mascaraTelefone(e.target.value),
                    });
                  }}
                />

                <Grid container>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="outlined"
                      className={classes.btBack2}
                      size="sm"
                      onClick={(e) => {
                        this.setState({
                          ...this.state,
                          createContact: false,
                          contact: '',
                          contactType: 0,
                        });
                      }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.btSave}
                      startIcon={<SaveIcon style={{ fontSize: 20 }} />}
                      size="sm"
                      onClick={(e) => {
                        if (this.state.contactType === 0) {
                          this.insertContact(this.state.contact, 'email');
                        } else {
                          this.insertContact(this.state.contact, 'phone');
                        }
                      }}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Container>
        ) : (
          <QuestionCreate
            category={this.state.selectedCategory}
            question={this.state.selectedQuestion}
            isEditAnswer={this.state.isEditAnswer}
            onSave={(title, value, selectedCategory, selectedQuestion) => {
              this.saveQuestion(
                title,
                value,
                selectedCategory,
                selectedQuestion,
              );
            }}
            onClose={(e) => {
              this.setState({
                ...this.state,
                isList: true,
                selectedCategory: null,
                selectedQuestion: null,
                isEditAnswer: false,
              });
            }}
          ></QuestionCreate>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Faq);
