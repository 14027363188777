import TextField from '@material-ui/core/TextField';
import {
  Autocomplete as MAutocomplete,
  createFilterOptions,
} from '@material-ui/lab';
import React from 'react';
import Error from '@material-ui/icons/Error';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormControl, FormHelperText, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
  },
  chipCustom: {
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
    width: 'max-content',
  },
}));

function DMultAutoComplete(props) {
  const classes = useStyles();

  const properts = { ...props };

  delete properts.label;
  delete properts.inputLabel;
  delete properts.error;
  delete properts.helperText;
  delete properts.renderInput;

  return (
    <>
      <FormControl
        className={`${classes.root} ${props.error ? 'form-control-error' : ''}`}
      >
        <MAutocomplete
          {...properts}
          multiple
          noOptionsText={props.noOptionsText}
          popupIcon={props.error ? <Error /> : <ExpandMore />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                classes={{ label: classes.chipCustom }}
                label={option.value}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="standard"
                label={
                  <>
                    {props.labelColor ? (
                      <span style={{ color: props.labelColor }}>
                        {props.label}
                      </span>
                    ) : (
                      props.label
                    )}{' '}
                    {props.required === true ? (
                      <span style={{ color: 'red' }}>*</span>
                    ) : props.inputLabel ? (
                      props.inputLabel
                    ) : (
                      ''
                    )}
                  </>
                }
                placeholder={props.placeholder}
              />
            );
          }}
        />
        {props.helperText ? (
          <FormHelperText>{props.helperText}</FormHelperText>
        ) : (
          ''
        )}
      </FormControl>
    </>
  );
}

DMultAutoComplete.defaultProps = {
  noOptionsText: 'Nenhuma opção',
};

export default DMultAutoComplete;
