import React from 'react';

export const PatientFilterContext = React.createContext({});

export const PatientFilterProvider = (props) => {
  const [filter, setFilter] = React.useState({
    search: '',
    grupo: '',
    unidade: '',
    idade: {
      de: '',
      ate: '',
    },
    pcd: '',
    time: 0,
  });

  return (
    <PatientFilterContext.Provider value={{ filter, setFilter }}>
      {props.children}
    </PatientFilterContext.Provider>
  );
};

export const usePatientFilter = () => React.useContext(PatientFilterContext);
