import env from 'react-dotenv';
import axios from 'axios';

function auth(dados) {
  return axios
    .post(`${env.API_ECINE_URL}/auth`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key':
          'I_puSF1V8LkKr3mBC08RcqirPqrt9SbH3Au1gZec9d-pAzFu6dAmYg==',
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getSalaChat(token) {
  return axios
    .get(`${env.API_ECINE_URL}/chat`, {
      headers: {
        'x-functions-key':
          'I_puSF1V8LkKr3mBC08RcqirPqrt9SbH3Au1gZec9d-pAzFu6dAmYg==',
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateSalaChat(token, dados) {
  return axios
    .post(`${env.API_ECINE_URL}/chat`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key':
          'I_puSF1V8LkKr3mBC08RcqirPqrt9SbH3Au1gZec9d-pAzFu6dAmYg==',
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getChat(token, id) {
  return axios
    .get(`${env.API_URL}/conversation-team/${id}?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getChatListByUserId(token, id) {
  return axios
    .get(
      `${env.API_URL}/conversation-team/list/user?user_id=${id}&token=${token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createChat(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conversation-team?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function addParticipantChat(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conversation-team/add-participant?token=${token}`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  auth,
  getChat,
  getSalaChat,
  getChatListByUserId,
  createChat,
  updateSalaChat,
  addParticipantChat,
};
