import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GlobalContext from 'component/shared/CalendarMonth/context/GlobalContext';
import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  baseDay: {
    background: '#F9F9F9',
    opacity: '0.5',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    '&>span:last-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
    },
  },
  titleDayWeek: {
    fontSize: '12px',
    color: theme.palette.neutral.cloud,
  },
  titleDay: {
    fontSize: '20px',
    color: theme.palette.neutral.cloud,
    fontWeight: 'bold',
  },
  currentDay: {
    background: 'rgba(104, 147, 248, 0.2);',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'pointer',
    '&>span:last-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: 'rgba(0, 111, 181, 0.5)',
    },
  },
  futureDay: {
    background: '#F9F9F9',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'pointer',
    '&>span:last-child': {
      color: '#006FB5',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: '#006FB5',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: '#006FB5',
    },
  },
  pastDay: {
    background: '#F9F9F9',
    opacity: '0.5',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'default',
    pointerEvents: 'none',
    '&>span:last-child': {
      color: '#3A404C !important',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: '#3A404C !important',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: '#3A404C !important',
    },
  },
  daysOfNextMonth: {
    background: '#F9F9F9',
    opacity: '0.1',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    '&>span:last-child': {
      color: '#3A404C !important',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: '#3A404C !important',
      fontSize: '12px',
      fontWeight: 'normal',
    },
  },
  selectedDay: {
    background: '#006FB5',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'pointer',
    '&>span:last-child': {
      color: theme.palette.background.snow,
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: theme.palette.background.snow,
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: theme.palette.background.snow,
    },
  },
  blockDay: {
    background: 'rgba(255, 36, 38, 0.16)',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    padding: '10px 0 10px 10px',
    cursor: 'pointer',
    '&>span:last-child': {
      color: theme.palette.support.error,
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '&>span:first-child': {
      color: theme.palette.support.error,
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '&>.iconDay:first-child': {
      color: theme.palette.support.error,
    },
  },
  dayWeekAndIcon: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
}));

export default function DayReagenda({ day, horariosAgenda, horariosDoDia }) {
  const classes = useStyles();

  const {
    monthIndex,
    setOpenScheduleOfTheDay,
    openScheduleOfTheDay,
    dayBlock,
    setMonthIndex,
  } = useContext(GlobalContext);
  const { changeProfessional, profissional, formError } =
    useConfiguracaoAgenda();

  const [currentDayIcon, setCurrentDayIcon] = useState({
    dia: null,
    icon: null,
  });

  useEffect(() => {
    if (!profissional || !formError) {
      setOpenScheduleOfTheDay(null);
    }
  }, [profissional, formError]);

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  function handleScheduleDay() {
    if (!getValidDay() || (changeProfessional && !profissional) || !formError) {
      if (!formError) {
        toast.error('Preencha todos os campos para continuar');
      }

      return;
    }

    let newDate = new Date(new Date().getFullYear(), monthIndex);

    if (day.format('MM/YYYY') !== moment(newDate).format('MM/YYYY')) {
      if (day.format('MM/YYYY') > moment(newDate).format('MM/YYYY')) {
        setMonthIndex(monthIndex + 1);
      } else {
        if (day.format('MM') === '01' && moment(newDate).format('MM') === '12') {
          setMonthIndex(monthIndex + 1);
        } else {
          setMonthIndex(monthIndex - 1);
        }
      }
    }

    setOpenScheduleOfTheDay({ day: day.format('DD/MM/YYYY'), click: true });
  }

  function getCurrentDayClass() {
    let d = new Date();
    let newDate = new Date(d.getFullYear(), monthIndex);
    let getTot = daysInMonth(monthIndex, d.getFullYear());

    for (var i = 1; i <= getTot; i++) {
      if (day.day() === 0) {
        return classes.pastDay;
      }

      if (day.day() === 6) {
        return classes.pastDay;
      }

      if (dayBlock.day === day.format('DD/MM/YYYY') && dayBlock.block) {
        return classes.blockDay;
      }

      if (day.format('MM/YYYY') !== moment(newDate).format('MM/YYYY')) {
        return classes.baseDay;
      }

      let diaBloqueado = horariosDoDia.find((x) => x.status === 'bloqueado');

      if (diaBloqueado) {
        if (horariosDoDia.filter((x) => x.status === 'bloqueado').length > 1) {
          // +1 bloqueio
          return classes.blockDay;
        } else {
          // 1 bloqueio
          return classes.blockDay;
        }
      }

      if (day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        return classes.currentDay;
      } else if (day.format('MM-YYYY') > moment().format('MM-YYYY')) {
        return classes.futureDay;
      } else if (day > moment()) {
        return classes.futureDay;
      } else {
        return classes.baseDay;
      }
    }
  }

  function getValidDay() {
    let d = new Date();
    let getTot = daysInMonth(monthIndex, d.getFullYear());

    for (var i = 1; i <= getTot; i++) {
      if (day.day() === 0) {
        return false;
      }

      if (day.day() === 6) {
        return false;
      }

      if (dayBlock.day === day.format('DD/MM/YYYY') && dayBlock.block) {
        return false;
      }

      if (day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        return true;
      } else if (
        new Date(day.format('YYYY-MM-DD')) >
        new Date(moment().format('YYYY-MM-DD'))
      ) {
        return true;
      }
    }
  }

  useEffect(() => {
    renderIcons();
  }, [day, horariosAgenda]);

  const parseDateTime = (str) =>
    str.slice(0, 10).split('-').reverse().join('/');

  function renderIcons() {
    let _currentDayIcon = { dia: day.format('DD'), icon: 'emptyIcon' };

    let diaBloqueado = horariosDoDia.find((x) => x.status === 'bloqueado');

    if (diaBloqueado) {
      if (horariosDoDia.filter((x) => x.status === 'bloqueado').length > 1) {
        _currentDayIcon = { dia: day.format('DD'), icon: 'lockIcon' };
      } else {
        _currentDayIcon = { dia: day.format('DD'), icon: 'periodBlock' };
      }
      // if(dia.period)
      // {
      // _currentDayIcon = { dia: day.format("DD"), icon: 'periodBlock'};
      // }
      // else
      // {
      //     _currentDayIcon = { dia: day.format("DD"), icon: 'lockIcon'};
      // }
    } else if (day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
      _currentDayIcon = { dia: day.format('DD'), icon: 'currentDayIcon' };
    } else if (
      Array.isArray(horariosAgenda) &&
      horariosAgenda.find(
        (x) => parseDateTime(x.data_time) === day.format('DD/MM/YYYY'),
      )
    ) {
      if (day.day !== 0 && day.day !== 6)
        _currentDayIcon = { dia: day.format('DD'), icon: 'futureDayIcon' };
    }

    setCurrentDayIcon(_currentDayIcon);
  }

  return (
    <>
      <div
        className={`${openScheduleOfTheDay && openScheduleOfTheDay.day === day.format('DD/MM/YYYY') && openScheduleOfTheDay.click ? classes.selectedDay : getCurrentDayClass()} `}
        onClick={() => handleScheduleDay()}
      >
        <div className={`${classes.dayWeekAndIcon} iconDay`}>
          <span>{day.format('ddd').toUpperCase()}</span>
          <div className="icon">
            {currentDayIcon &&
            currentDayIcon.icon === 'emptyIcon' &&
            currentDayIcon.dia === day.format('DD')
              ? null
              : null}
            {currentDayIcon &&
            currentDayIcon.icon === 'currentDayIcon' &&
            currentDayIcon.dia === day.format('DD') ? (
              <AccessTimeIcon style={{ fontSize: '15px' }} />
            ) : (
              ''
            )}
            {currentDayIcon &&
            currentDayIcon.icon === 'futureDayIcon' &&
            currentDayIcon.dia === day.format('DD') ? (
              <AddCircleOutlineIcon style={{ fontSize: '15px' }} />
            ) : (
              ''
            )}
            {currentDayIcon &&
            currentDayIcon.icon === 'periodBlock' &&
            currentDayIcon.dia === day.format('DD') ? (
              <AccessTimeIcon style={{ fontSize: '15px', color: 'red' }} />
            ) : (
              ''
            )}
            {currentDayIcon &&
            currentDayIcon.icon === 'lockIcon' &&
            currentDayIcon.dia === day.format('DD') ? (
              <LockOutlinedIcon style={{ fontSize: '15px' }} />
            ) : (
              ''
            )}
          </div>
        </div>
        <span>{day.format('DD')}</span>
      </div>
    </>
  );
}
