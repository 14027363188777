import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Backdrop, Button, Chip, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function HealthAttendanceTypeGrid(props) {
  const classes = useStyles();

  function Pagination(props) {
    var paginas = [];

    for (var cont = 1; cont < 10; cont++) {
      paginas.push(
        <li onClick={(e) => props.onPage(e.target.textContent)}>{cont}</li>,
      );
    }

    return (
      <ul>
        <li>
          <NavigateBeforeIcon />
        </li>
        {paginas.map(function (item, index) {
          if (index < 5) return item;
        })}
        <li>
          <NavigateNextIcon />
        </li>
      </ul>
    );
  }

  function table() {
    if (props.types.data[0]) {
      if (props.types.data[0].id == undefined) {
        return (
          <StyledTableRow key={`types-0`}>
            <StyledTableCell component="th" scope="row">
              -
            </StyledTableCell>
            <StyledTableCell align="right">-</StyledTableCell>
            <StyledTableCell align="right">-</StyledTableCell>
            <StyledTableCell align="right">
              <a
                className="text-link"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <EditIcon />
                <span>&nbsp;&nbsp;Alterar</span>
              </a>
              <a
                className="text-link"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <DeleteIcon />
                <span>&nbsp;&nbsp;Excluir</span>
              </a>
            </StyledTableCell>
          </StyledTableRow>
        );
      } else {
        return props.types.data.map((row, index) => (
          <StyledTableRow key={`types-${index}`}>
            <StyledTableCell component="th" scope="row">
              {row.active == '-'
                ? row.active
                : row.active === 1
                  ? 'Sim'
                  : 'Não'}
            </StyledTableCell>
            <StyledTableCell align="right">{row.name}</StyledTableCell>
            <StyledTableCell align="right">
              {row.description ? row.description : '-'}
            </StyledTableCell>
            <StyledTableCell align="right">
              <a
                className="text-link"
                onClick={(e) =>
                  props.alterar([
                    true,
                    [row.id, row.active, row.name, row.description],
                  ])
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <EditIcon />
                <span>&nbsp;&nbsp;Alterar</span>
              </a>
              <a
                className="text-link"
                onClick={(e) => props.excluir([true, row.id])}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <DeleteIcon />
                <span>&nbsp;&nbsp;Excluir</span>
              </a>
            </StyledTableCell>
          </StyledTableRow>
        ));
      }
    }
  }

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: 'white' }}
        open={props.types.data.length === 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="HealthAttendanceTypes table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Ativo</TableCell>
              <TableCell align="right">Nome</TableCell>
              <TableCell align="right">Descrição</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{table()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
