import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Clear, GetApp } from '@material-ui/icons';
import DButton from '../../../shared/DButton';
import { Grid, Typography } from '@material-ui/core';
import DRadio from '../../../shared/DRadio';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DInput from '../../../shared/DInput';
import DDropdown from '../../../shared/DDropdown';
import { listAllPerfis, listAllUnidades } from '../../../../api/dominios';
import { getAllUnidades } from '../../../../api/unidades';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportIcon from '@material-ui/icons/Report';
import useError from '../../../../hooks/useError';
import {
  required,
  validaCPF,
  requiredTwoName,
  requiredUnidades,
} from '../../../../helpers/validacoes';
import DDropzone from '../../../shared/DDropzone';
import { Alert, AlertTitle } from '@material-ui/lab';
import { mascaraCPF, mascaraRG } from '../../../../helpers/formatacao';
import modelocsv from './../../../../assets/modelos/example-upload-invites.csv';
import { getModelInvite } from './../../../../api/dominios';
import { toast } from 'react-toastify';
import DSelectMutiple from './../../../shared/DSelectMutiple';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: '626px',
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,
      maxHeight: '100%',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.snow,
      border: `1px solid ${theme.palette.neutral.midgrey}`,
      borderRadius: 15,
    },
    fontColor: {
      color: '#000 !important',
      '& .MuiFormControlLabel-label': {
        color: '#000 !important',
      },
      '& .MuiFormControl-root label': {
        color: '#000 !important',
      },
    },
    headermodal: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3px 15px',
      borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    },
    footermodal: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 16,
      borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
      marginTop: 8,
    },
    fontBottom: {
      '&>span ': {
        fontSize: '12px',
      },
    },
    iconRemovePermission: {
      color: theme.palette.support.error,
      cursor: 'pointer',
    },
  }),
);

export default function ProfissionalConvidar(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [qtd_un, setQTDUn] = useState([1]);
  const [file, setFile] = useState(null);
  const [tipo, setTipo] = useState('1');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState(null);
  const [cpf, setCPF] = useState(null);
  const [unidades, setUnidades] = useState([
    { medical_unit_id: [], profile_id: [] },
  ]);
  const [listUnidades, setListUnidades] = useState([]);
  const [listPerfis, setListPerfis] = useState([]);
  const [model, setModel] = useState('');
  const [workload, setWorkload] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  useEffect(function () {
    listAllPerfis(localStorage.getItem('token')).then((res) => {
      if (res.status) setListPerfis(res.profiles.data);
    });

    getAllUnidades(localStorage.getItem('token')).then((res) => {
      if (res.status != false) {
        setListUnidades(res);
      }
    });

    getModelInvite(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setModel(res.url);
      }
    });
  }, []);

  async function validarPerfil() {
    if (!unidades) throw new Error('Preencha a(s) unidade(s).');

    for await (const unidade of unidades) {
      if (unidade.profile_id.length == 0)
        throw new Error('Preencha o perfil da unidade.');
    }

    return true;
  }

  const [errorUnidades, setErrorUnidades] = useState([]);

  const [error, validarFormulario, setErrors] = useError({
    tipo: required,
    nome: requiredTwoName,
    email: required,
    workload: required,
    cpf: validaCPF,
    unidades: (valor) => {
      console.log('valor');
      console.log(valor);
      console.log('unidades');

      if (!valor || valor === '' || valor.length === 0) {
        return 'Campo obrigatório';
      } else {
        let valido = true;
        let error = [];

        for (let cont0 = 0; cont0 < valor.length; cont0++) {
          console.log('valor[cont0]', valor[cont0]);
          console.log(
            'valor[cont0].medical_unit_id.length',
            valor[cont0].medical_unit_id.length,
          );
          console.log(
            'valor[cont0].profile_id.length',
            valor[cont0].profile_id.length,
          );
          if (
            !valor[cont0] ||
            valor[cont0].medical_unit_id.length === 0 ||
            valor[cont0].profile_id.length === 0
          ) {
            valido = false;
            error.push(cont0);
          }
        }

        console.log(error);

        if (!valido) {
          setErrorUnidades(error);
          return 'É preciso preencher unidades e perfis';
        }

        for (let cont1 = 0; cont1 < valor.length; cont1++) {
          for (let cont2 = 0; cont2 < valor.length; cont2++) {
            if (cont1 !== cont2) {
              let medical_unit = false;
              let profile = false;
              valor[cont1].medical_unit_id.map((res1) => {
                if (valor[cont2].medical_unit_id.find((res) => res === res1))
                  medical_unit = true;
              });

              valor[cont1].profile_id.map((res1) => {
                if (valor[cont2].profile_id.find((res) => res === res1))
                  profile = true;
              });

              if (medical_unit && profile) {
                valido = false;
                error.push(cont1);
              }
            }
          }
        }
        setErrorUnidades(error);
        return !valido
          ? 'Há associações de unidades e perfis já utilizadas'
          : undefined;
      }
    },
  });

  function onClose() {
    setQTDUn([1]);
    setTipo('1');
    setNome(null);
    setEmail(null);
    setCPF(null);
    setUnidades(null);
    setFile(null);
    setWorkload(new Date(new Date().setHours(0, 0, 0, 0)));
  }

  function handleUnidades(e, index) {
    console.log('e', e);
    console.log('index', index);
    console.log('unidades', unidades);

    delete error.unidades;

    var new_unidades = unidades ? unidades : [];

    if (new_unidades.length === 0) {
      new_unidades.push(e);
      setUnidades(new_unidades);
    } else {
      if (e.medical_unit_id && e.profile_id) {
        new_unidades[index] = e;
        setUnidades(new_unidades);
      }
    }
  }

  function removeUnidades(index) {
    delete error.unidades;

    let new_unidades = [...unidades];
    new_unidades.splice(index, 1);
    setUnidades(new_unidades);
    let new_qtd_unidades = [...qtd_un];
    new_qtd_unidades.splice(index, 1);
    setQTDUn(new_qtd_unidades);
  }

  function Unidades(props) {
    const [medical_unit_id, setMedicalUnitId] = useState(
      props.value && props.value[props.index] !== undefined
        ? props.value[props.index].medical_unit_id
        : [],
    );
    const [profile_id, setProfileId] = useState(
      props.value && props.value[props.index] !== undefined
        ? props.value[props.index].profile_id
        : [],
    );

    return (
      <Grid item xs={12} key={`unidades-${props.index}`}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={11}>
            <Grid container spacing={1} className={classes.fontColor}>
              <Grid item md={6}>
                <DSelectMutiple
                  label="Unidade"
                  error={props.error}
                  id={`dropdown-unidade-${props.index}`}
                  onChange={(e) => {
                    setMedicalUnitId(e);
                    props.onChange({ medical_unit_id: e, profile_id });
                  }}
                  value={medical_unit_id}
                  options={
                    listUnidades.length
                      ? listUnidades.map(function (item) {
                          return { id: item.id, value: item.company_name };
                        })
                      : []
                  }
                />
              </Grid>
              <Grid item md={6}>
                <DSelectMutiple
                  label="Perfis"
                  error={props.error}
                  id={`dropdown-perfis-${props.index}`}
                  onChange={(e) => {
                    setProfileId(e);
                    props.onChange({ medical_unit_id, profile_id: e });
                  }}
                  value={profile_id}
                  options={
                    listPerfis.length
                      ? listPerfis.map(function (item) {
                          return { id: item.id, value: item.description };
                        })
                      : []
                  }
                />
              </Grid>
              {props.helperText ? (
                <Grid item md={12}>
                  <Typography
                    className={`text-est text-regular ${props.error ? 'text-error' : ''}`}
                    variant="body1"
                    gutterBottom
                  >
                    {props.helperText}
                  </Typography>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid item>
            <DeleteIcon
              classes={{ root: classes.iconRemovePermission }}
              onClick={(e) => props.onRemove(true)}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function ConviteCriadoComSucesso(props) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ marginTop: 187, marginBottom: 169 }}
      >
        <Grid md={6}>
          <MarkEmailReadIcon
            className="text-success"
            fontSize="large"
            classes={{
              fontSizeLarge: 'icon-large',
            }}
          />
        </Grid>
        <Grid md={6}>
          <h2 className="text-mt text-center text-regular text-success">
            {props.message
              ? props.message
              : 'Seu convite foi enviado para o profissional'}
          </h2>
        </Grid>
      </Grid>
    );
  }

  const body = (
    <form
      className="tema-light"
      autoComplete="off"
      onSubmit={async (form) => {
        form.preventDefault();
        if (tipo === '1') {
          if (
            validarFormulario({ tipo, nome, email, cpf, unidades, workload })
          ) {
            validarPerfil()
              .then((_) => {
                props.enviarConvite({
                  nome,
                  email,
                  cpf: cpf.replace(/[^0-9]/gm, ''),
                  unidades,
                  setErrors,
                  error,
                  workload,
                });
              })
              .catch((err) => {
                setErrors({
                  unidades: err.message || 'Preencha o a unidade corretamente.',
                });
              });
          } else {
          }
        } else {
          if (!props.arquivoComErro) {
            props.testUploadEmMassa({ file });
          } else {
            props.uploadEmMassa({ file });
          }
        }
      }}
    >
      <div style={modalStyle} className={`${classes.paper}`}>
        <div className={classes.headermodal}>
          <h2 className="simple-modal-title text-mt text-regular text-primary">
            Convidar profissional
          </h2>
          <Clear
            style={{ color: '#949494' }}
            onClick={(e) => {
              onClose();
              props.onClose(true);
            }}
          />
        </div>
        {props.enviado ? (
          <ConviteCriadoComSucesso />
        ) : (
          <>
            <Grid container className="tema-light" style={{ padding: 16 }}>
              {!props.arquivoComErro && !props.uploadEmMassaEnviado ? (
                <>
                  <Grid item md={11}>
                    <Typography
                      className="text-primary"
                      style={{ marginBottom: '8px' }}
                      variant="h6"
                    >
                      Envio de convites
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    className={`md-mb-3 md-mt-1 ${classes.fontColor}`}
                  >
                    <DRadio
                      inline
                      name="tipo"
                      onChange={(e) => setTipo(e.target.value)}
                      value={tipo}
                      style={{ marginBottom: '32px' }}
                      options={[
                        { value: '1', label: 'Convite único' },
                        { value: '2', label: 'Convite em massa' },
                      ]}
                    />
                  </Grid>
                </>
              ) : (
                ''
              )}
              {tipo === '1' ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      className="text-primary"
                      variant="h6"
                      style={{ marginBottom: '8px' }}
                    >
                      Dados do profissional
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.fontColor}>
                      <Grid item md={6} style={{ paddingRight: 8 }}>
                        <DInput
                          id="cpf"
                          label="CPF"
                          placeholder="CPF"
                          type="text"
                          error={error.cpf !== undefined}
                          helperText={error.cpf}
                          fullWidth={true}
                          value={cpf}
                          onChange={(e) => {
                            delete error.cpf;
                            setCPF(mascaraCPF(e.target.value));
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <DInput
                          id="nome-completo"
                          label="Nome completo"
                          placeholder="Nome completo"
                          type="text"
                          error={error.nome !== undefined}
                          helperText={error.nome}
                          fullWidth={true}
                          value={nome}
                          onChange={(e) => {
                            delete error.nome;
                            setNome(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.fontColor}>
                      <Grid
                        item
                        xs={6}
                        style={{ paddingRight: 8 }}
                        className={`md-mb-3 ${classes.fontColor}`}
                      >
                        <DInput
                          id="email"
                          label="E-mail"
                          placeholder="E-mail"
                          type="email"
                          error={error.email !== undefined}
                          helperText={error.email}
                          fullWidth={true}
                          value={email}
                          inputProps={{ maxLength: 254 }}
                          onChange={(e) => {
                            delete error.email;
                            setEmail(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={`md-mb-3 ${classes.fontColor}`}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            clearable
                            ampm={false}
                            id="carga-horaria"
                            label="Carga horária"
                            invalidDateMessage="Horário inválido"
                            value={workload}
                            onChange={setWorkload}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="text-primary" variant="h6">
                      Unidades e perfis
                    </Typography>
                  </Grid>
                  {qtd_un.length &&
                    qtd_un.map(function (item, index) {
                      return (
                        <Unidades
                          key={index}
                          index={index}
                          value={unidades}
                          error={
                            error.unidades !== undefined &&
                            errorUnidades.indexOf(index) >= 0
                          }
                          helperText={
                            error.unidades && errorUnidades.indexOf(index) >= 0
                              ? error.unidades
                              : ''
                          }
                          onRemove={(e) => removeUnidades(index)}
                          onChange={(e) => {
                            handleUnidades(e, index);
                          }}
                        />
                      );
                    })}
                  <Grid item md={12} style={{ marginTop: 32 }}>
                    <DButton
                      onClick={(e) => {
                        let new_qtd = [...qtd_un, 1];
                        console.log('new_qtd', new_qtd);
                        setQTDUn(new_qtd);
                        handleUnidades(
                          { medical_unit_id: [], profile_id: [] },
                          new_qtd.length - 1,
                        );
                      }}
                      variant="primary"
                      size="sm"
                      icon={<AddCircleIcon style={{ fontSize: 20 }} />}
                    >
                      Adicionar outra unidade
                    </DButton>
                  </Grid>
                </>
              ) : (
                <>
                  {props.arquivoComErro ? (
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="flex-start">
                            <Grid item>
                              <ReportIcon className="text-error" />
                            </Grid>
                            <Grid item>
                              <Typography
                                className="text-mt text-regular"
                                variant="body1"
                              >
                                Foram encontrados erros no arquivo enviado
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {props.arquivoComErro.errors.length &&
                          props.arquivoComErro.errors.map(function (item, key) {
                            return (
                              <Grid item xs={12} key={`upload-errors-${key}`}>
                                <Alert
                                  icon={false}
                                  severity="error"
                                  className="bg-error-10"
                                >
                                  <Typography
                                    className="text-error mb-2"
                                    variant="h5"
                                  >{`Linha ${item.line} - Coluna ${item.column}`}</Typography>
                                  <Typography
                                    className="text-st text-regular"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <strong>Erro:</strong> {item.message}
                                  </Typography>
                                  <Typography
                                    className="text-st text-regular"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <strong>Possivel solução: </strong>Tente
                                    passar um valor válido para a coluna ou
                                    verifique o modelo utilizado.
                                  </Typography>
                                </Alert>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {!props.uploadEmMassaEnviado ? (
                        <Grid item xs={12}>
                          <Typography
                            className="text-primary mb-3"
                            variant="h6"
                          >
                            Unidades e perfis
                          </Typography>
                          <Typography
                            className="text-st text-regular"
                            variant="body1"
                            gutterBottom
                          >
                            Para convidar profissionais em massa, envie o
                            arquivo modelo, contendo as informações dos
                            profissionais que deseja cadastrar.
                          </Typography>
                          {model !== '' ? (
                            <a
                              href={model}
                              download
                              className="text-link-bold text-st"
                            >
                              <DButton
                                disabled={model === ''}
                                className={classes.fontBottom}
                                style={{ marginTop: 16, marginBottom: 16 }}
                                variant="primary"
                                icon={<GetApp fontSize="small" />}
                                size="sm"
                              >
                                Baixar arquivo modelo para envio de convites 1
                              </DButton>
                            </a>
                          ) : (
                            <DButton
                              disabled={model === ''}
                              className={classes.fontBottom}
                              style={{
                                marginTop: 16,
                                marginBottom: 16,
                                fontSize: 12,
                              }}
                              variant="primary"
                              icon={<GetApp fontSize="small" />}
                              size="sm"
                            >
                              Baixar arquivo modelo para envio de convites 2
                            </DButton>
                          )}
                          <DDropzone
                            formatMessage="Formatos: .xls ou .csv com até 3 MB"
                            maxSize={3}
                            maxFiles={1}
                            multiple={false}
                            emit={(files) => setFile(files[0])}
                            accept=".csv, .xls, .xlsx"
                            onError={(message) => {
                              toast.error(message);
                              console.log(message);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <ConviteCriadoComSucesso message="Seus convites foram enviados para os profissionais" />
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
        <ProfissionalConvidar />
        <div className={`${classes.footermodal} tema-light`}>
          {tipo === '1' ? (
            <>
              {props.enviado ? (
                <>
                  <DButton
                    className="mr-3"
                    variant="primary"
                    size="sm"
                    onClick={(e) => {
                      onClose();
                      props.onClose(true);
                    }}
                  >
                    Voltar para lista
                  </DButton>
                  <DButton
                    variant="primary"
                    size="sm"
                    onClick={(e) => {
                      onClose();
                      props.onNew(true);
                    }}
                  >
                    Convidar mais um profissional
                  </DButton>
                </>
              ) : (
                <DButton
                  className="btn-success"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  {props.loading ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    'Enviar convite'
                  )}
                </DButton>
              )}
            </>
          ) : (
            <>
              {!props.arquivoComErro ? (
                <>
                  {!props.uploadEmMassaEnviado ? (
                    <DButton
                      disabled={file === null}
                      type="submit"
                      variant="primary"
                      size="sm"
                    >
                      {props.loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        'Avançar'
                      )}
                    </DButton>
                  ) : (
                    <DButton
                      variant="Primary"
                      size="sm"
                      onClick={(e) => {
                        onClose();
                        props.onClose(true);
                      }}
                    >
                      Voltar para a lista
                    </DButton>
                  )}
                </>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <DButton
                      variant="secondary"
                      size="sm"
                      onClick={(e) => {
                        props.onCancelarEnvio(true);
                        setFile(null);
                      }}
                    >
                      Cancelar e reenviar o arquivo
                    </DButton>
                  </Grid>
                  {!props.arquivoInvalido && (
                    <Grid item>
                      <DButton
                        type="submit"
                        className="btn-error"
                        variant="primary"
                        size="sm"
                      >
                        {props.loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          'Continuar mesmo assim'
                        )}
                      </DButton>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </div>
      </div>
    </form>
  );

  return (
    <div>
      <Modal
        open={props.open ? props.open : false}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
