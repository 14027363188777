import { Dialog, DialogContent, DialogContentClassKey, DialogTitle, Grid, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import { deleteProfile } from "../../../../../api/profiles";
import DButton from "../../../../shared/DButton";
import { IProfile, IState } from "../../interfaces/state.interface";
import { styles } from "./styles";

interface IProps {
  state: IState
  setState: React.Dispatch<React.SetStateAction<IState>>
  token: string | null;
}

const ModalConfirmDelete = (props: IProps) => {
  const confirmDelete = (profile: IProfile, index: number) => {
    const newState = props.state.profiles;
    newState[index].isLoading = !profile.isLoading;
    props.setState({ ...props.state, profiles: newState });

    deleteProfile(props.token, profile.id).then((res) => {
      if (res.status) {
        toast.success('Processo realizado com sucesso.');
        newState.splice(index, 1);
      } else {
        toast.error(res.message ? res.message : 'Erro ao realizar ação.');
        newState[index].isLoading = !profile.isLoading;
      }
      props.setState({ ...props.state, profiles: newState, delete: false });
    });
  };

  return (
    <Dialog
      aria-labelledby="conditional-remove-title"
      scroll="body"
      onClose={() => {
        props.setState({ ...props.state, delete: false });
      }}
      open={true}
    >
      <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={10}>
            <Typography variant="body1" className="text-regular text-st">
              Se seguir com essa ação{' '}
              {props.state.isEdit.status
                ? 'todas as alterações serão perdidas'
                : 'todos os dados serão perdidos'}
              , deseja confirmar essa ação?
            </Typography>
          </Grid>
          <Grid item>
            <Close
              onClick={() => props.setState({ ...props.state, delete: false })}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={styles.contentRemove as Partial<ClassNameMap<DialogContentClassKey>>}>
        <Grid
          style={{ height: 50 }}
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DButton
              type="button"
              style={styles.buttonBack}
              variant="secondary"
              size="none"
              className="btn-error"
              onClick={() => props.setState({ ...props.state, delete: false })}
            >
              Voltar
            </DButton>
          </Grid>
          <Grid item>
            <DButton
              type="button"
              style={styles.buttonConfirm}
              variant="primary"
              size="none"
              className="btn-error"
              onClick={() => {
                if (props.state.indexSelected !== null) {
                  confirmDelete(
                    props.state.profiles[props.state.indexSelected],
                    props.state.indexSelected,
                  )
                }
              }}
            >
              Confirmar Exclusão
            </DButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalConfirmDelete;