import { makeStyles } from '@material-ui/core/styles';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  estiloModalAtencao: {
    backgroundColor: theme.palette.neutral.white,
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    border: 'none',
    padding: '60px 100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconeModalAtencao: {
    color: theme.palette.neutral.black,
    fontSize: '14px',
  },
  titleModalAtencao: {
    fontSize: '20px',
    color: theme.palette.neutral.black,
    fontWeight: '700',
    marginTop: '20px',
  },
  fontDescricaoModalAtencao: {
    textAlign: 'center',
    fontSize: '16px',
    color: theme.palette.neutral.darkgray,
    marginTop: '20px',
  },
  alinhaBotoesModalAtencao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    marginTop: '50px',
  },
  botaoConfirmar: {
    background: `${theme.palette.advanced.sidebar} !important`,
    border: `1px solid ${theme.palette.advanced.sidebar} !important`,
    color: `${theme.palette.neutral.white} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  botaoCancelar: {
    backgroundColor: 'transparent',
    // border: `1px solid ${theme.palette.support.error} !important`,
    color: `${theme.palette.neutral.black} !important`,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '30px',
    padding: '5px 20px',
  },
  justificativa: {
    marginTop: '24px',
    width: '100%',
    '&>span:first-child': {
      color: `${theme.palette.neutral.darkgray} !important`,
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  rootInput: {
    borderRadius: '8px',
    paddingTop: 8,
  },
}));

export default function ModalReagendamento(props) {
  const classes = useStyles();

  const renderDate = (strDate) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format('DD/MM/YYYY');
  };

  const renderHour = (strHour) => {
    const hourObj = new Date(strHour);
    const momentObj = moment(hourObj);
    return momentObj.format('HH:mm');
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => props.onClose(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className={classes.estiloModalAtencao}>
          <div className={classes.iconeModalAtencao}>
            <CalendarMonthOutlinedIcon style={{ fontSize: 56 }} />
          </div>
          <div className={classes.titleModalAtencao} id="modal-modal-title">
            {props.isAgendamento
              ? 'Confirmação de solicitação'
              : 'Confirmar reagendamento'}
          </div>
          <div className={classes.fontDescricaoModalAtencao}>
            {props.isAgendamento
              ? 'Deseja confirmar a solicitação de atendimento?'
              : `Deseja confirmar o reagendamento para o dia ${props.horarioAgendado && renderDate(props.horarioAgendado.data_time)} às ${props.horarioAgendado && renderHour(props.horarioAgendado.data_time)}?`}
          </div>
          <div className={classes.alinhaBotoesModalAtencao}>
            <Button
              className={classes.botaoCancelar}
              onClick={(e) => props.onClose(true)}
            >
              Cancelar
            </Button>
            <Button
              className={classes.botaoConfirmar}
              onClick={(e) => props.onConfirm()}
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
