import React from 'react';
import { Redirect } from 'react-router-dom';

export const AuthRouteContext = React.createContext({});

export const AuthRouteProvider = (props) => {
  const [auth, setAuth] = React.useState({
    auth: true,
    route: true,
  });

  return (
    <AuthRouteContext.Provider value={{ auth, setAuth }}>
      {!auth.route && <Redirect to="/nao-autorizado" />}
      {props.children}
    </AuthRouteContext.Provider>
  );
};

export const useAuthRoute = () => React.useContext(AuthRouteContext);
