export function MonitorIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.color ? props.color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.16663 15H10.8333V17.5H9.16663V15Z" />
      <path d="M13.3333 17.5V19.1667H6.66663V17.5H13.3333Z" />
      <path d="M16.6667 15H3.33337C2.67054 14.9994 2.03504 14.7358 1.56634 14.2671C1.09764 13.7984 0.834036 13.1629 0.833374 12.5V3.33337C0.834036 2.67054 1.09764 2.03504 1.56634 1.56634C2.03504 1.09764 2.67054 0.834036 3.33337 0.833374H16.6667C17.3295 0.834036 17.965 1.09764 18.4337 1.56634C18.9024 2.03504 19.166 2.67054 19.1667 3.33337V12.5C19.166 13.1629 18.9024 13.7984 18.4337 14.2671C17.965 14.7358 17.3295 14.9994 16.6667 15ZM3.33337 2.50004C3.11236 2.50004 2.9004 2.58784 2.74412 2.74412C2.58784 2.9004 2.50004 3.11236 2.50004 3.33337V12.5C2.50004 12.7211 2.58784 12.933 2.74412 13.0893C2.9004 13.2456 3.11236 13.3334 3.33337 13.3334H16.6667C16.8877 13.3334 17.0997 13.2456 17.256 13.0893C17.4122 12.933 17.5 12.7211 17.5 12.5V3.33337C17.5 3.11236 17.4122 2.9004 17.256 2.74412C17.0997 2.58784 16.8877 2.50004 16.6667 2.50004H3.33337Z" />
      <path d="M13.3333 12.5L11.7383 7.18337L10.8333 10.8334L9.74246 8.33337H9.46663L8.33329 11.6667L7.36913 8.27754L6.92413 9.16671H4.16663V8.33337H6.40913L7.49996 5.83337L8.38996 8.92754L8.86663 7.50004H10.2575L10.6633 8.31087L11.6666 3.33337L13.3333 10L13.8666 8.33337H15.8333V9.16671H14.4666L13.3333 12.5Z" />
    </svg>
  );
}

export function PrintIcon(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={props.color ? props.color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.25 6.44446H3.75C2.505 6.44446 1.5 7.44946 1.5 8.69446V11.6945C1.5 12.5195 2.175 13.1945 3 13.1945H4.5V14.6945C4.5 15.5195 5.175 16.1945 6 16.1945H12C12.825 16.1945 13.5 15.5195 13.5 14.6945V13.1945H15C15.825 13.1945 16.5 12.5195 16.5 11.6945V8.69446C16.5 7.44946 15.495 6.44446 14.25 6.44446ZM11.25 14.6945H6.75C6.3375 14.6945 6 14.357 6 13.9445V10.9445H12V13.9445C12 14.357 11.6625 14.6945 11.25 14.6945ZM14.25 9.44446C13.8375 9.44446 13.5 9.10696 13.5 8.69446C13.5 8.28196 13.8375 7.94446 14.25 7.94446C14.6625 7.94446 15 8.28196 15 8.69446C15 9.10696 14.6625 9.44446 14.25 9.44446ZM12.75 2.69446H5.25C4.8375 2.69446 4.5 3.03196 4.5 3.44446V4.94446C4.5 5.35696 4.8375 5.69446 5.25 5.69446H12.75C13.1625 5.69446 13.5 5.35696 13.5 4.94446V3.44446C13.5 3.03196 13.1625 2.69446 12.75 2.69446Z" />
    </svg>
  );
}

export function DriveFileIcon(props) {
  return (
    <svg
      version="1.1"
      fill={props.color ? props.color : 'currentColor'}
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g>
        <g>
          <path d="M548.3,352.8h271.5L548.3,83.6V352.8z M205.5,10h393.4l294.5,294.5v588.9c0,26.1-10,48.7-29.9,67.9c-19.9,19.2-42.9,28.8-69,28.8H205.5c-26.1,0-49.1-9.6-69-28.8c-19.9-19.2-29.9-41.8-29.9-67.9l2.3-786.8c0-26.1,9.6-48.7,28.8-67.9C156.8,19.6,179.5,10,205.5,10z" />
        </g>
      </g>
    </svg>
  );
}

export function OpenNewIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={props.color ? props.color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 14.25H4.5C4.0875 14.25 3.75 13.9125 3.75 13.5V4.5C3.75 4.0875 4.0875 3.75 4.5 3.75H8.25C8.6625 3.75 9 3.4125 9 3C9 2.5875 8.6625 2.25 8.25 2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V9.75C15.75 9.3375 15.4125 9 15 9C14.5875 9 14.25 9.3375 14.25 9.75V13.5C14.25 13.9125 13.9125 14.25 13.5 14.25ZM10.5 3C10.5 3.4125 10.8375 3.75 11.25 3.75H13.1925L6.345 10.5975C6.0525 10.89 6.0525 11.3625 6.345 11.655C6.6375 11.9475 7.11 11.9475 7.4025 11.655L14.25 4.8075V6.75C14.25 7.1625 14.5875 7.5 15 7.5C15.4125 7.5 15.75 7.1625 15.75 6.75V3C15.75 2.5875 15.4125 2.25 15 2.25H11.25C10.8375 2.25 10.5 2.5875 10.5 3Z" />
    </svg>
  );
}
