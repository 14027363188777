import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Checkbox,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DInput from './../DInput';

function CNSCard(props) {
  const { error, helperText, value, isDefault, notDelete } = { ...props };

  const [locked, setLocked] = useState(true);
  const [inputIsDefault, setInputIsDefault] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
    setInputIsDefault(isDefault);
  }, [value, isDefault]);

  return (
    <Card key={`cns-component`} className="bg-light-gray mb-3">
      <CardContent style={{ padding: '15px 0px 0px 15px' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={5}>
            <DInput
              label="CNS"
              placeholder="CNS"
              labelColor="black"
              required
              type="text"
              inputProps={{ maxLength: 15 }}
              error={error}
              helperText={helperText}
              fullWidth
              onBlur={(e) => {
                props.onBlur({
                  value: e.target.value,
                  default: inputIsDefault,
                });
              }}
              onChange={(e) => {
                props.onChange({
                  value: e.target.value,
                  default: inputIsDefault,
                });
                setInputValue(e.target.value);
              }}
              positionIcon="end"
              value={inputValue}
            />
          </Grid>
          <Grid
            item
            md={6}
            style={
              notDelete
                ? { paddingTop: 23, paddingBottom: 30 }
                : { paddingTop: 23 }
            }
          >
            <FormControlLabel
              control={
                <Radio
                  value={1}
                  name="cnsPrincipal"
                  color="primary"
                  checked={inputIsDefault ? true : false}
                  onChange={(e) => {
                    props.onChange({
                      value: inputValue,
                      default: !inputIsDefault,
                    });
                    props.onBlur({
                      value: inputValue,
                      default: !inputIsDefault,
                    });
                    setInputIsDefault(!inputIsDefault);
                  }}
                  positionIcon="end"
                />
              }
              label="Definir como principal"
            />
          </Grid>
          <Grid item md={1} style={{ margin: '15px 0px', padding: '0px' }}>
            {!notDelete && (
              <Button
                style={{
                  height: '70%',
                  width: '100%',
                  padding: 0,
                  justifyContent: 'unset',
                }}
                onClick={(e) => props.onDelete(true)}
              >
                <DeleteForeverIcon
                  color="error"
                  style={{ height: '100%', width: '100%' }}
                  viewBox="0 -15 55 55"
                />
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CNSCard;
