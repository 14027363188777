import SimpleModal from 'component/shared/Modal';
import { Grid, makeStyles } from '@material-ui/core';

function VisualizadorClinico(props) {
  return (
    <SimpleModal fullPage open={props.open} onClose={(e) => props.setModal()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          item
          className="text-center"
          style={{ height: '100%', width: '100%' }}
        >
          <iframe width="100%" height="100%" noborder src={props.url}></iframe>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}

export default VisualizadorClinico;
