import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import DescriptionIcon from '@material-ui/icons/Description';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} placement="left" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  consultas_titulo: {
    fontWeight: 'bold !important',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    padding: '0 0 12px 0',
    margin: 0,
  },
  consultas_subtitulo: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
    padding: '12px 0',
    margin: 0,
  },
  consultas_text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
  },
  consultas_purpleCard: {
    width: '100%',
    height: 62,
    borderRadius: 4,
    background: '#9300D926',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    position: 'relative',
  },
  consultas_purpleBar: {
    width: 8,
    height: '100%',
    borderRadius: 4,
    background: '#9300D9',
    marginRight: 8,
  },
  consultas_greenCard: {
    width: '100%',
    height: 62,
    borderRadius: 4,
    background: '#3C757926',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    position: 'relative',
  },
  consultas_greenBar: {
    width: 8,
    height: '100%',
    borderRadius: 4,
    background: '#3C7579',
    marginRight: 8,
  },
  consultas_brownCard: {
    width: '100%',
    height: 62,
    borderRadius: 4,
    background: '#C5794226',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    position: 'relative',
  },
  consultas_brownBar: {
    width: 8,
    height: '100%',
    borderRadius: 4,
    background: '#C57942',
    marginRight: 8,
  },
  consultas_button: {
    width: 30,
    height: '100%',
    borderRadius: 4,
    color: '#fff',
    background: '#4A4AE9',
    position: 'absolute',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    fontSize: 20,
    cursor: 'pointer',
  },
  consultas_popover: {
    listStyle: 'none',
    padding: 0,
    margin: '0 16px',
  },
  consultas_popoverItem: {
    listStyle: 'none',
    margin: 0,
    padding: '12px 0',
    fontSize: 12,
    color: '#272727',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cardNames: {
    width: 250,
    height: 25,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function CardNextHealthAttendance(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDoctorName = () => {
    return (
      props.schedule?.schedule_professional?.user?.social_name ||
      props.schedule?.schedule_professional?.user?.full_name
    );
  };

  const getDoctorSpeciality = () => {
    return (
      props.schedule?.schedule_professional?.medical_speciality?.description ||
      'Não identificado'
    );
  };

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  return (
    <>
      {props.schedule && props.schedule_time ? (
        <>
          {/* { renderTitle(props.horarioPopover) } */}
          <div className={classes.consultas_greenCard}>
            <div className={classes.consultas_greenBar}></div>
            <p className={classes.consultas_text} style={{ marginRight: 16 }}>
              {props.horario}
            </p>
            <p className={classes.consultas_text}>
              {getDoctorSpeciality().length > 30 ? (
                <BootstrapTooltip title={getDoctorSpeciality()}>
                  <span className={classes.cardNames}>
                    {getDoctorSpeciality()}
                  </span>
                </BootstrapTooltip>
              ) : (
                <span className={classes.cardNames}>
                  {getDoctorSpeciality()}
                </span>
              )}

              {getDoctorName().length > 10 ? (
                <BootstrapTooltip title={getDoctorName()}>
                  <span
                    className={classes.cardNames}
                    style={{ fontWeight: 400, display: 'block' }}
                  >
                    {getDoctorName()}
                  </span>
                </BootstrapTooltip>
              ) : (
                <span
                  className={classes.cardNames}
                  style={{ fontWeight: 400, display: 'block' }}
                >
                  {getDoctorName()}
                </span>
              )}
            </p>
            <div className={classes.consultas_button} onClick={handleClick}>
              <p style={{ marginTop: '10px' }}>...</p>
            </div>
          </div>
          <Popover
            id={props.schedule?.id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ul className={classes.consultas_popover}>
              <li
                className={classes.consultas_popoverItem}
                onClick={(e) => props.onView()}
              >
                <DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} />{' '}
                Visualizar dados da consulta
              </li>
              {/* <li className={ classes.consultas_popoverItem }><UpdateIcon style={{ fontSize: 16, marginRight: 8 }} /> Reagendar consulta</li> */}
              <li
                className={classes.consultas_popoverItem}
                style={{ color: '#d13d2e' }}
                onClick={(e) => props.onCancel()}
              >
                <CancelIcon style={{ fontSize: 16, marginRight: 8 }} /> Cancelar
                consulta
              </li>
            </ul>
          </Popover>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

CardNextHealthAttendance.defaultProps = {
  title: false,
  healthAttendance: null,
  onCancel: function () {
    console.log('onCancel');
  },
  onView: function () {
    console.log('onView');
  },
};

export default CardNextHealthAttendance;
