import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  crossIcon: {
    cursor: 'pointer',
    color: 'rgba(103, 115, 139, 1)',
  },
  textColor: {
    color: '#4F4F4F',
  },
  textColorLightBlack: {
    color: '#272727',
  },
  textColorBlack: {
    color: '#000000',
  },
  textColorRed: {
    color: theme.palette.support.error,
  },
  textColorGreen: {
    fontSize: 14,
    color: `${theme.palette.primary.light} !important`,
    '&:hover': {
      color: `${theme.palette.primary.light} !important`,
    },
    '&:focus': {
      color: `${theme.palette.primary.light} !important`,
    },
  },
  colorGreen: {
    color: `${theme.palette.primary.light} !important`,
  },
  arrowBackRoot: {
    cursor: 'pointer',
  },
  arrowBackColor: {
    color: theme.palette.primary.light,
  },
  arrowBackSize: {
    fontSize: 18.77,
  },
  iconColorConfirm: {
    color: 'rgba(148, 148, 148, 1)',
  },
  iconConfirm: {
    fontSize: 20,
  },
  toolBar: {
    position: 'relative',
    zIndex: 1000,
    background: 'rgba(232, 244, 244, 1)',
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    height: 61,
  },
  innerToolBar: {
    width: '100%',
    maxWidth: 1537,
    padding: '0px 15px',
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
  notMargin: {
    margin: 0,
  },
  notMarginRight: {
    marginRight: 0,
  },
  radioOutros: {
    marginBottom: 0,
    marginRight: 0,
  },
  radioLabel: {
    color: 'rgba(39, 39, 39, 1)',
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  rootRadio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    border: 'solid rgba(39, 39, 39, 1) 1px',
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main} !important`,
    backgroundColor: '#fff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        'radial-gradient(rgba(68, 151, 147, 1),rgba(68, 151, 147, 1) 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  },
}));

export { useStyles };
