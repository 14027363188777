import { makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import DInput from '../../../../../../shared/DInput';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Box } from '@mui/system';
import { useState } from 'react';

const useStyles = makeStyles({
  gridDate: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  divDate: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    margin: 0,
    '& .MuiInputBase-adornedEnd': {
      marginRight: '30px',
    },
  },
});

const EBDateTime = ({
  id,
  label,
  placeholder,
  value,
  helper_text,
  additional_data,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const classes = useStyles();
  const { handleInputSubmit } = useContext(FormBuilderContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [hour, setHour] = React.useState();
  const [dateTime, setDateTime] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let data = selectedDate;
  // let dataFormatada = ((data.getMonth() + 1).toString().padStart(2, '0')) + ((data.getDate() )) + "/" +  "/" + data.getFullYear();

  useEffect(() => {
    const data = {
      value: {
        date: selectedDate,
        hour: hour,
      },
    };
    if (data.value.date !== undefined) {
      setDateTime(data.value);
      // handleInputSubmit({[id]: data.value})
    }
  }, [selectedDate, hour]);

  useEffect(() => {
    if (answer.length > 0) {
      setSelectedDate(new Date(answer[0].date));
    }
  }, []);
  console.log(is_required);
  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: '1em', display: 'flex' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${additional_data.construct.showTitle ? label : ''}`,
            }}
          />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
            {is_required === 1 ? '*' : ''}
          </span>
        </Typography>
        <Box className={classes.gridDate}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                className={classes.divDate}
                margin="normal"
                id="date-picker-dialog"
                label="Data"
                placeholder={placeholder ? placeholder : ''}
                format="dd/MM/yyyy"
                // readOnly={readOnly}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              {additional_data.construct.showTimeSelectOnly ? (
                <TextField
                  id="time"
                  className={classes.divDate}
                  label="Hora"
                  placeholder={placeholder ? placeholder : ''}
                  type="time"
                  value={hour}
                  defaultValue={answer.length > 0 ? answer[0].hour : ''}
                  error={erro[0] ? erro[0].value : ''}
                  helperText={erro[0] ? erro[0].value : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  onChange={(e) => {
                    if (erro !== null && erro.length > 0) {
                      delete erro[0].value;
                    }
                    setHour(e.target.value);
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onBlur={(e) => {
                    if (dateTime !== null) {
                      handleInputSubmit({ [id]: dateTime });
                    }
                  }}
                />
              ) : (
                ''
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </Box>
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBDateTime;
