import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import DButton from 'component/shared/DButton';
import { PlusIcon, TrashIcon } from 'component/shared/Icon/Icon';
import TInputBase from 'component/shared/TInputBase/TInputBase';
import TSelectNative from 'component/shared/TSelectNative/TSelectNative';
import { useAppProvider } from 'context/AppContext';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ScheduleReminder from '../../../../../api/schedule/reminder';

const useStyles = makeStyles((theme) => ({
  titulo: {
    color: `${theme?.palette?.primary?.main ?? '#000'} !important`,
    marginTop: 29.5,
    marginBottom: 29.5,
  },
}));

function EnvioLembretes() {
  const classes = useStyles();

  const { LoadingControl } = useAppProvider();

  const [lembretes, setLembretes] = useState([
    { hour: 0, minute: 0, message: '', default: true },
  ]);

  const [errors, setErrors] = useState({});

  useEffect(() => getRemainders(), []);

  function getRemainders() {
    LoadingControl.showLoading('Carregando lembretes...');

    ScheduleReminder.getAll()
      .then((res) => {
        if (res && res.status) {
          setLembretes((prevLembretes) => {
            if (res.scheduleReminders.length == 0) {
              return prevLembretes.length
                ? prevLembretes
                : [{ hour: 0, minute: 0, message: '', default: true }];
            }

            return res.scheduleReminders;
          });
          // setLembretes(
          //   res.scheduleReminders.length == 0
          //     ? [{ hour: 0, minute: 0, message: "", new: true }]
          //     : res.scheduleReminders
          // );
        } else if (res && res.message) {
          toast.error(res.message);
        }
      })
      .finally(() => LoadingControl.hideLoading());
  }

  const validarMensagem = () => {
    let isValid = true;
    lembretes.forEach((remainder) => {
      if (!remainder.message) {
        toast.error('Preencha a mensagem do lembrete');
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  const updateReminders = async () => {
    // if(!validarMensagem())
    //     return;

    LoadingControl.showLoading('Atualidando lembretes...');
    const newReminders = lembretes.find((x) => x.default);

    if (newReminders) {
      await ScheduleReminder.create(newReminders);
    }

    const updateReminders = lembretes.filter((x) => x.id);

    // var errors = [
    //     ...newReminders.filter((x) => (!x.hour && !x.minute) || !x.message),
    //     ...updateReminders.filter((x) => (!x.hour && !x.minute) || !x.message)
    // ];

    // if (errors.length > 0) {
    //     toast.error("Preencha todos os campos para editar ou criar novos campos.");

    //     var errorsIndex = errors.map(x => lembretes.findIndex(y => y == x));

    //     return;
    // }

    try {
      // for await (var remainder of newReminders) {
      //   try {
      //     remainder.hour = remainder.hour ?? 0;
      //     remainder.minute = remainder.minute ?? 0;

      //     await ScheduleReminder.create(remainder);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }

      // const errReminders = updateReminders.filter(x => x.message == "");

      // if(errReminders.length > 0){
      //   toast.error("Preencha todos os campos para editar ou criar novos campos.");
      //   return;
      // }

      for await (var remainder of updateReminders) {
        try {
          // if (!remainder.message) {
          //   toast.error("Preencha a mensagem do lembrete para atualizar.");
          //   return;
          // }
          remainder.hour = remainder.hour ?? 0;
          remainder.minute = remainder.minute ?? 0;

          await ScheduleReminder.edit(remainder);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      getRemainders();
      toast.success('Lembretes atualizados com sucesso.');
      LoadingControl.hideLoading();
    }
  };

  const newLembrete = () =>
    ScheduleReminder.create({ hour: 0, minute: 0, message: '' }).then((res) => {
      if (res.status) {
        setLembretes((prevLembretes) => {
          const reminder = res.scheduleReminder;

          return [
            ...prevLembretes,
            { hour: 0, minute: 0, message: '', new: true, id: reminder.id },
          ];
        });
      }
    });

  const removeLembrete = (index) => {
    if (lembretes[index].id) {
      LoadingControl.showLoading('Removendo lembrete...');
      ScheduleReminder.delete(lembretes[index].id)
        .then((res) => {
          if (res && res.status) {
            setLembretes((prevLembretes) => {
              const newLembretes = [...prevLembretes];

              newLembretes.splice(index, 1);

              if (newLembretes.length == 0) {
                return [{ hour: 0, minute: 0, message: '', default: true }];
              }

              return newLembretes;
            });
            toast.success('Lembrete removido com sucesso!');
          } else if (res && res.message) {
            toast.error(res.message);
          }
        })
        .finally(() => {
          LoadingControl.hideLoading();
          getRemainders();
        });
    } else {
      setLembretes((prevLembretes) => {
        const newLembretes = [...prevLembretes];

        newLembretes.splice(index, 1);

        if (newLembretes.length == 0) {
          return [{ hour: 0, minute: 0, message: '', default: true }];
        }

        return newLembretes;
      });
    }
  };

  const changeLembrete = (index, { hour, minute, message }) => {
    const newLembretes = [...lembretes];
    newLembretes[index] = {
      ...newLembretes[index],
      hour,
      minute,
      message: String(message).slice(0, 100),
    };

    setLembretes(newLembretes);
  };

  const getHoras = (maxHour = 42) => {
    const horas = new Array(maxHour).fill(0).map((_, index) => index + 1);

    return horas.map((hora) => ({
      value: hora,
      label: hora > 1 ? `${hora} Horas` : `${hora} hora`,
    }));
  };

  const getMinutos = (maxMinutos = 59) => {
    const minutos = new Array(maxMinutos).fill(0).map((_, index) => index + 1);

    return minutos.map((minutos) => ({
      value: minutos,
      label: minutos > 1 ? `${minutos} Minutos` : `${minutos} minuto`,
    }));
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h3"
          component="h1"
          classes={{ root: classes.titulo }}
        >
          Envio de lembretes
        </Typography>
        <DButton
          variant="primary"
          size="sm"
          style={{ marginTop: 20, borderRadius: '8px !important' }}
          onClick={updateReminders}
        >
          Salvar
        </DButton>
      </Grid>
      {lembretes.map((lembrete, index) => (
        <Grid key={`lembrete-${index}`} container spacing={5}>
          <Grid item xs={3}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TSelectNative
                  label="Horas"
                  name="horas"
                  options={getHoras()}
                  value={lembrete.hour}
                  onChange={(e) =>
                    changeLembrete(index, {
                      hour: e.target.value,
                      minute: lembrete.minute,
                      message: lembrete.message,
                    })
                  }
                  // onBlur={(e) => updateReminders()}
                />
              </Grid>
              <Grid item xs={6}>
                <TSelectNative
                  label="Minutos"
                  name="minutos"
                  options={getMinutos(59)}
                  value={lembrete.minute}
                  onChange={(e) =>
                    changeLembrete(index, {
                      hour: lembrete.hour,
                      minute: e.target.value,
                      message: lembrete.message,
                    })
                  }
                  // onBlur={(e) => updateReminders()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={9}>
                <TInputBase
                  label="Mensagem"
                  name="mensagem"
                  placeholder="Digite aqui a mensagem..."
                  value={lembrete.message}
                  error={errors[index]?.mensagem}
                  onChange={(e) => {
                    const value = e.target.value;

                    changeLembrete(index, {
                      hour: lembrete.hour,
                      minute: lembrete.minute,
                      message: value,
                    });
                  }}
                  onBlur={(e) =>
                    setErrors({
                      [index]: {
                        ...errors[index],
                        mensagem: !lembrete.message
                          ? 'Preencha a mensagem do campo'
                          : '',
                      },
                      ...errors,
                    })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      aria-label="remover"
                      onClick={(e) => removeLembrete(index)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Grid>
                  {index === lembretes.length - 1 && (
                    <Grid item>
                      <IconButton
                        aria-label="adicionar"
                        onClick={(e) => newLembrete()}
                      >
                        <PlusIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default EnvioLembretes;
