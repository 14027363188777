import { Grid, Divider } from '@material-ui/core';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';

function LineCareView(props) {
  const { healthAttendance, patient } = useNewHealthAttendance();

  return (
    <>
      <Grid item md={12}>
        <div className="fonte_paciente_tab">Linha de cuidado</div>
        <div className="main_div_tag_paciente_tab mt10_new_tabs">
          {patient &&
            patient.patient_line_care
              .filter((res) => res.line_care !== null)
              .map((item) => {
                return (
                  <div className="tag_paciente_tab">{item.line_care.name}</div>
                );
              })}
        </div>
      </Grid>
    </>
  );
}

export default LineCareView;
