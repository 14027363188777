import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../index.scss';
import { Divider, Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

function ModalLaudo(props) {
  const handleClose = () => props.onClose(true);

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box md className="estilo_modal_vacinacao">
          <div className="fonte_titulo_modal_vacinacao">Laudo de exame</div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Data:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.date_formated}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Procedimento:</div>
            </div>
            <Grid item md={6}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.procedure}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Lateralidade:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.laterality}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Categoria Birads:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Tipo do exame:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.exam_type}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Status:</div>
            </div>
            <Grid item md={4}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.status}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Codigo de acesso:
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.access_code}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Tipo de Atendimento:
              </div>
            </div>
            <Grid item md={4}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.type_of_service}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Unidade de Saude:
              </div>
            </div>
            <Grid item md={10}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.health_Unit}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Inclusão Sistema Externo:
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.Inclusion_external_system_formated}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Inclusão Repositório:
              </div>
            </div>
            <Grid item md={3}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.repository_inclusion_formated}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Atualização Sistema Externo:
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.external_system_update_formated}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Atualização Repositório:
              </div>
            </div>
            <Grid item md={3}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.repository_update_formated}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Modalidade:</div>
            </div>
            <Grid item md={9}>
              <div className="fonte_regular_modal_vacinacao">
                {props.exame.modality}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao fonte_bold_modal_vacinacao">
            Laudo
          </div>
          <div className="mt20_modal_vacinacao box_modal_laudo">
            <p className="fonte_regular_modal_vacinacao">
              {props.exame.report}
            </p>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="flex_end_modal_vacinacao mt20_modal_vacinacao">
            <Button
              onClick={handleClose}
              className="botao_modal_voltar_vacinacao "
            >
              Voltar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

ModalLaudo.defaultProps = {
  open: false,
  onClose: function () {
    console.log('onClose');
  },
};

export default ModalLaudo;
