import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  btnFilter: {
    margin: '0 auto',
    marginTop: '15px',
    borderRadius: '20px',
    width: '120px',
    display: 'block',
  },

  formControl: {
    width: '100%',
    minWidth: 220,
    height: 'calc(100vh - 540px)',
    overflow: 'auto',
    fontSize: 14,
  },
});

export default function CheckboxList({
  src,
  displayAttr,
  keyAttr,
  callbackFilterSelect,
  callbackFilterClick,
  selecionados,
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (src.filter((s) => s[keyAttr] == -1).length == 0) {
      let itemTodos = {};
      itemTodos[keyAttr] = -1;
      itemTodos[displayAttr] = 'Todos';
      src.unshift(itemTodos);
    }
    const tempValues = src.map((item) => {
      return selecionados.map((s) => s[keyAttr]).includes(item[keyAttr]);
    });
    setDataSelected(selecionados);
    setValues(tempValues);
  }, []);

  useEffect(() => {
    setData(src);
  }, [values]);

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {data.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values[index]}
                    onChange={(e) => {
                      if (item[keyAttr] != -1) {
                        const tempValues = values;
                        tempValues[index] = !tempValues[index];
                        if (!tempValues[index]) {
                          tempValues[0] = false;
                        }
                        setValues(tempValues);

                        let tempData = dataSelected;
                        const tempIndex = tempData
                          .map((t) => t[keyAttr])
                          .indexOf(item[keyAttr]);

                        if (e.target.checked && tempIndex < 0) {
                          tempData.push(item);
                        } else if (tempIndex > -1) {
                          tempData.splice(tempIndex, 1);
                          if (!tempValues[index]) {
                            tempData.splice(0, 1);
                          }
                        }

                        setDataSelected(tempData);
                        callbackFilterSelect(tempData);
                      } else {
                        const tempValues = src.map((_) => !values[0]);
                        setValues(tempValues);

                        if (!values[0]) {
                          setDataSelected([...src]);
                          callbackFilterSelect([...src]);
                        } else {
                          setDataSelected([]);
                          callbackFilterSelect([]);
                        }
                      }
                    }}
                  />
                }
                label={item[displayAttr]}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <div>
        <Button
          className={classes.btnFilter}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            callbackFilterClick();
          }}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
}
