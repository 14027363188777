import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getFormatDate } from '../../../../helpers/formatacao';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  text: {
    fontSize: 14,
  },
  item: {
    display: 'block',
    marginBottom: 8,
    lineHeight: '21px',
  },
});

export default function CardPaciente(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.text}>
        <span className={classes.item}>
          <strong>Paciente: </strong>{' '}
          {props.value.patient.social_name || props.value.patient.full_name}{' '}
          {props.value.patient.pronoun ? props.value.patient.pronoun.name : ''}
        </span>
        {props.value.patient.patient_document.length > 0 ? (
          <>
            <span className={classes.item}>
              <strong>CPF:</strong> {props.value.patient.cpf}
            </span>
          </>
        ) : (
          ''
        )}
      </div>
      <hr />
      <div className={classes.text}>
        <span className={classes.item}>
          <strong>Protocolo: </strong> {props.value.id}
        </span>
        <span className={classes.item}>
          <strong>Profissional: </strong>{' '}
          {props.value.user_requesting !== null
            ? props.value.user_requesting.social_name ||
              props.value.user_requesting.full_name
            : '-'}
        </span>
        <span className={classes.item}>
          <strong>Solicitação:</strong>{' '}
          {getFormatDate(
            new Date(props.value.created_at),
            "dd/MM/yyyy 'às' H:mm",
          )}
        </span>

        {props.value.health_attendance_status_id == 2 ? (
          <>
            <span className={classes.item}>
              <strong>Última atualização: </strong>{' '}
              {getFormatDate(
                new Date(props.value.updated_at),
                "dd/MM/yyyy 'às' H:mm",
              )}
            </span>
            <span className={classes.item}>
              <strong>Inicio da Consulta:</strong>{' '}
              {getFormatDate(
                new Date(props.value.open_at),
                "dd/MM/yyyy 'às' H:mm",
              )}
            </span>
          </>
        ) : (
          ''
        )}
        {props.value.health_attendance_status_id == 3 ? (
          <>
            <span className={classes.item}>
              <strong>Última atualização: </strong>{' '}
              {getFormatDate(
                new Date(props.value.updated_at),
                "dd/MM/yyyy 'às' H:mm",
              )}
            </span>
            <span className={classes.item}>
              <strong>Inicio da Consulta:</strong>{' '}
              {getFormatDate(
                new Date(props.value.open_at),
                "dd/MM/yyyy 'às' H:mm",
              )}
            </span>
            <span className={classes.item}>
              <strong>Fim da Consulta:</strong>{' '}
              {getFormatDate(
                new Date(props.value.closed_at),
                "dd/MM/yyyy 'às' H:mm",
              )}
            </span>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
