import React, { useState, useEffect } from 'react';
import '../index.scss';
import { TextField } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create, Save } from '@material-ui/icons';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import {
  updateMedicineAllergy,
  updateFoodAllergy,
} from './../../../../api/anamnesePreForm';
import { getByHealthAttendanceId } from './../../../../api/health_attendances';
import useError from './../../../../hooks/useError';
import { required } from './../../../../helpers/validacoes';
import AlergiasView from './AlergiasView';
import AlergiasEdit from './AlergiasEdit';
import { toast } from 'react-toastify';

export default function AlergiasNewTab(props) {
  const { healthAttendance, setHealthAttendance } = useNewHealthAttendance();
  const [edit, setEdit] = useState(false);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [drugAllergies, setDrugAllergies] = useState([]);
  const [foodAllergiesEdit, setFoodAllergiesEdit] = useState(false);
  const [drugAllergiesEdit, setDrugAllergiesEdit] = useState(false);

  const [error, validarFormulario, setErrors] = useError({});

  const handleUpdate = async () => {
    var anamnese_pre_form_id =
      healthAttendance && healthAttendance.anamnese_pre_form
        ? healthAttendance.anamnese_pre_form[0].id
        : null;

    if (Array.isArray(drugAllergies)) {
      var _drugs = drugAllergies
        .filter((drug) => drug.value)
        .map((drug) => {
          return { name: drug.value };
        });

      var response1 = await updateMedicineAllergy(
        localStorage.getItem('token'),
        anamnese_pre_form_id,
        _drugs,
      );

      if (response1.status) {
        toast.success('Alergia Medicamentosa atualizada com sucesso.');
      } else {
        toast.error('Erro ao atualizar alergias medicamentosas.');
      }
    }

    if (Array.isArray(foodAllergies)) {
      var _foods = foodAllergies
        .filter((food) => food.value)
        .map((food) => {
          return { name: food.value };
        });

      var response2 = await updateFoodAllergy(
        localStorage.getItem('token'),
        anamnese_pre_form_id,
        _foods,
      );

      if (response2.status) {
        toast.success('Alergia Alimentar atualizada com sucesso.');
      } else {
        toast.error('Erro ao atualizar alergias alimentares.');
      }
    }

    if (response1 && response2 && response1.status && response2.status) {
      getByHealthAttendanceId(
        localStorage.getItem('token'),
        healthAttendance.id,
      ).then((res) => {
        if (res.status) {
          setHealthAttendance({
            ...healthAttendance,
            anamnese_pre_form: res.healthAttendance.anamnese_pre_form,
          });
          setEdit(false);
        } else {
          toast.error('Erro ao atualizar exibição das alergias.');
        }
      });
    } else {
      toast.error('Erro ao atualizar alergias.');
    }
  };

  return (
    <div className="fundo_new_tab">
      <div className="main_flex_new_tab">
        <div className="fonte_new_tab text-primary">Alergias</div>

        {healthAttendance.user_id == localStorage.getItem('uid') && (
          <div className="flex_end_new_tab">
            {!edit ? (
              <Button
                className="botao_editar_new_tab text-primary"
                onClick={(e) => setEdit(true)}
                startIcon={<Create />}
              >
                Editar
              </Button>
            ) : (
              <Button
                className="botao_editar_new_tab text-primary"
                onClick={handleUpdate}
                startIcon={<Save />}
              >
                Salvar
              </Button>
            )}
          </div>
        )}
      </div>

      {!edit ? (
        <AlergiasView />
      ) : (
        <AlergiasEdit
          error={error}
          editAllergies={(foodAllergiesEdit, drugAllergiesEdit) => {
            setFoodAllergiesEdit(foodAllergiesEdit);
            setDrugAllergiesEdit(drugAllergiesEdit);
          }}
          onChange={(foodAllergies, drugAllergies) => {
            delete error.foodAllergies;
            delete error.drugAllergies;
            setFoodAllergies(foodAllergies);
            setDrugAllergies(drugAllergies);
          }}
        />
      )}
    </div>
  );
}
