import env from 'react-dotenv';
import axios from 'axios';

function getAllQuestions(token) {
  var api_url = new URL(env.API_URL).origin;

  return axios
    .get(`${api_url}/faq/question/all?token=${token}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllQuestionsByTitle(token, title) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/question/all/${title}?token=${token}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getSuggestedQuestions(token) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/view/count`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getQuestionById(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/question/${id}?token=${token}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createQuestion(token, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .post(`${api_url}/faq/question?token=${token}`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function registerViewQuestion(token, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .post(`${api_url}/faq/view`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateQuestion(token, dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .put(
      `${api_url}/faq/question/${id}?token=${token}`,
      JSON.stringify(dados),
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-auth-token': token,
          'x-domain': env.X_DOMAIN,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteQuestion(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .delete(`${api_url}/faq/question/${id}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllQuestions,
  getAllQuestionsByTitle,
  getSuggestedQuestions,
  getQuestionById,
  createQuestion,
  registerViewQuestion,
  updateQuestion,
  deleteQuestion,
};
