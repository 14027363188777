import axios from 'axios';
import env from 'react-dotenv';

function getAll(patientId, token) {
  return axios
    .get(`${env.API_URL}/animal/list/${patientId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createAnimal(token, data) {
  return axios
    .post(`${env.API_URL}/animal`, JSON.stringify(data), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAnimal(animalId, token) {
  return axios
    .get(`${env.API_URL}/animal/show/${animalId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateAnimal(animalId, token, data) {
  return axios
    .put(`${env.API_URL}/animal/${animalId}`, JSON.stringify(data), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getAll, createAnimal, getAnimal, updateAnimal };
