import { toast } from 'react-toastify';

function getValidators(setErrorField, data, elementsForValidate) {
  console.log('getValidators');

  let arrayWithFormResponses = data;

  const initalArrMapFormResponses = arrayWithFormResponses.reduce(
    (prev, current) => {
      return { ...prev, [current.id]: current };
    },
    {},
  );

  let errorCheck = false;

  if (elementsForValidate && elementsForValidate.length > 0) {
    elementsForValidate.forEach((element, index) => {
      const id = elementsForValidate[index].id;

      if (elementsForValidate[index].required === true) {
        try {
          if (
            elementsForValidate[index].id &&
            (!initalArrMapFormResponses[id] ||
              initalArrMapFormResponses[id].value === '' ||
              initalArrMapFormResponses[id].value === null)
          ) {
            console.log(id);
            errorCheck = true;
            setErrorField((prevState) => {
              if (prevState && Array.isArray(prevState)) {
                if (
                  prevState.find((x) => x.id === elementsForValidate[index].id)
                )
                  return prevState;
              }

              return [
                ...prevState,
                {
                  id: elementsForValidate[index].id,
                  value: 'Este campo é obrigatório',
                },
              ];
            });
          }
        } catch (e) {
          toast.error('Erro ao realizar ação.');
        }
      }
    });

    if (errorCheck) {
      toast.warning('Campos obrigatórios não preenchidos.');
    }
  }

  return errorCheck;
}

function getFieldsToAnswerToConditional(fields) {
  let fieldsToAnswerToConditional = [];

  fields &&
    fields.map((field) => {
      fieldsToAnswerToConditional.push({ id: `${field.id}`, value: '' });
    });

  return fieldsToAnswerToConditional;
}

export { getValidators, getFieldsToAnswerToConditional };
