import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../index.scss';
import { Divider, Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

function ModalAcompanhamento(props) {
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => props.onClose(true);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box md className="estilo_modal_vacinacao">
          <div className="fonte_titulo_modal_vacinacao">
            Acompanhamento de gestação
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Estabelecimento de Saúde (Acolhimento):
              </div>
            </div>
            <Grid item md={8}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.maternidade_referencia}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Data:</div>
            </div>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.data_acolhimento}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Semana:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.idade_gestacional_semanas}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Pré-Natal:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.data_primeira_consulta_pre_natal
                  ? 'Sim'
                  : 'Não'}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Situação:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Situação Vacinal:
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Data de atendimento:
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_bold_modal_vacinacao">Prontuário:</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Número Sisprenatal:
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.sisprenatal}
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">Previsão Parto:</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_bold_modal_vacinacao">DUM:</div>
            </Grid>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.dum}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Gestações Anteriores:
              </div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.numero_de_gestacoes}
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Filhos Nascidos Vivos:
              </div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.numero_filhos_nascidos_vivos}
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Filhos Natimortos:
              </div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </div>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">Partos Vaginais:</div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </div>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Partos por Cesáreas:
              </div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_bold_modal_vacinacao">Abortos:</div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.numero_de_abortos}
              </div>
            </div>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">Data do Parto:</div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </div>
            <Grid item md={2}>
              <div className="fonte_bold_modal_vacinacao">
                Tempo de internação:
              </div>
            </Grid>
            <div>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </div>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Tipo de parto:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Local do Parto:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Tipo Recém Nascido:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Condição Recém Nascido:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Peso do Bebê:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Alta Junto da Mãe:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.motivo_encerramento_gestacao}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Apgar 1 Min:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Apgar 5 Min:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Idade Gestacional:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.idade_gestacional_dias}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Alimentação:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Obervação Parto:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.condicoes_previas_de_saude}
              </div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Bebê Gemelar:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Peso do Bebê:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">
                Alta Junto da Mãe:
              </div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">
                {props.pregnancy.motivo_encerramento_gestacao}
              </div>
            </Grid>
          </div>
          <div className="mt20_modal_vacinacao flex_modal_vacinacao">
            <div>
              <div className="fonte_bold_modal_vacinacao">Apgar 1 Min:</div>
            </div>
            <Grid item md={1}>
              <div className="fonte_regular_modal_vacinacao">-</div>
            </Grid>
            <div>
              <div className="fonte_bold_modal_vacinacao">Apgar 5 Min:</div>
            </div>
          </div>
          <div className="mt20_modal_vacinacao">
            <Divider variant="fullWidth" className="divide_new_tab" />
          </div>
          <div className="flex_end_modal_vacinacao mt20_modal_vacinacao">
            <Button
              onClick={handleClose}
              className="botao_modal_voltar_vacinacao "
            >
              Voltar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

ModalAcompanhamento.defaultProps = {
  pregnancy: null,
  onClose: function () {
    console.log('onClose');
  },
};

export default ModalAcompanhamento;
