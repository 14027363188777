import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import './styles.scss';
import './range.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
    // alignItems: "center",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100% !important',
  },
}));

const EBRange = ({
  id,
  title,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();
  const [currentStepIndex, setCurrentStepIndex] = useState();
  let values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleInputChange = (e) => {
    setCurrentStepIndex(e.currentTarget.value);
  };

  // useEffect(() => {
  //   const data = {
  //     value: currentStepIndex,
  //   };

  //   handleInputSubmit({ [id]: data.value });
  // }, [currentStepIndex]);

  useEffect(() => {
    if (answer.length > 0) {
      setCurrentStepIndex(answer[0]);
    }
  }, []);

  const allRanges = window.document.querySelectorAll('.step');
  allRanges.forEach((wrap) => {
    const range = wrap.querySelector('.range');
    const bubble = wrap.querySelector('.bubble');

    range.addEventListener('input', () => {
      setBubble(range, bubble);
    });
    setBubble(range, bubble);
  });

  function setBubble(range, bubble) {
    const val = range.value;
    const min = range.min ? range.min : 0;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = val;

    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
  }

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold mb-2"
            style={{ fontSize: '0.8em' }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${label ? label : 'Escala Numérica de Intensidade da Dor'}`,
              }}
            />
          </Typography>
          <Box className="fields_scale">
            <span className="field_number">0</span>
            <span className="field_number">10</span>
          </Box>
          <Box className={classes.root}>
            <div className="step">
              <input
                onInput={(event) => handleInputChange(event)}
                id={id}
                type="range"
                disabled={readOnly}
                min="0"
                className="range"
                value={currentStepIndex}
                className={`range ${classes.textField}`}
                max="10"
                step="1"
                list="tick-list"
                readOnly={readOnly}
                onBlur={(e) => {
                  !readOnly
                    ? handleInputSubmit({ [id]: currentStepIndex })
                    : console.log('effect onBlur');
                }}
              />
              <output className="bubble"></output>
              {/* <span id="output">{values[currentStepIndex]}</span> */}
              <br />
              <div className="fields_range">
                <span className="field_list">sem dor</span>
                <span className="field_list">dor fraca</span>
                <span className="field_list">dor moderada</span>
                <span className="field_list">dor intensa</span>
              </div>
            </div>
          </Box>
          <span style={{ fontSize: 12 }} className="text-light text-error">
            {erro[0] ? erro[0].value : ''}
          </span>
          <br />
        </div>
      </div>
    </section>
  );
};

export default EBRange;
