import React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, InputLabel, TextField } from '@material-ui/core';
import { CloseOutlined, Error } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  inpuRoot: {
    height: 'auto',
    borderRadius: 8,
    paddingLeft: '50px !important',
  },
  chip: {
    height: 25,
    background: '#F4F4F4 !important',
    border: 'none !important',
    zIndex: 10,
  },
  addIcon: {
    color: '#006FB5 !important',
  },
  errorIcon: {
    color: '#FF6B57 !important',
  },
  deleteIcon: {
    fontSize: 9,
    color: '#4F4F4F !important',
  },
  label: {
    color: '#3A404C !important',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  errorLabel: {
    color: '#FF6B57 !important',
  },
  error: {
    borderColor: '#FF6B57',
  },
  helperText: {
    fontSize: 12,
    color: '#000',
  },
  helperTextError: {
    color: '#FF6B57',
  },
  endAdornment: {
    zIndex: 1000,
    left: '10px !important',
    right: 'unset',
  },
}));

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root.Mui-focused fieldset': {
    border: 'solid rgba(0, 0, 0, 0.87) 1px',
  },
});

function TSelectNativeMultiple({
  id,
  label,
  name,
  options,
  value,
  error,
  helperText,
  placeholder,
  limitTags,
  required,
  getOptionLabel,
  getOptionSelected,
  onChange,
  disabled,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomAutocomplete
        classes={{
          inputRoot: classes.inpuRoot,
          endAdornment: classes.endAdornment,
        }}
        multiple
        limitTags={limitTags}
        id={id}
        disableClearable
        noOptionsText="Nenhuma opção"
        options={options}
        disabled={disabled}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={onChange}
        popupIcon={
          error ? (
            <Error
              color="primary"
              classes={{ colorPrimary: classes.errorIcon }}
            />
          ) : (
            <AddIcon
              color="primary"
              classes={{ colorPrimary: classes.addIcon }}
            />
          )
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.label}
              deleteIcon={<CloseOutlined />}
              {...getTagProps({ index })}
              classes={{
                root: classes.chip,
                deleteIcon: classes.deleteIcon,
              }}
            />
          ))
        }
        renderInput={(params) => (
          <>
            <InputLabel
              error={error}
              classes={{
                root: classes.label,
                error: classes.errorLabel,
              }}
            >
              {label} {required ? '*' : ''}
            </InputLabel>
            <TextField
              {...params}
              error={error}
              variant="outlined"
              name={name}
              placeholder={placeholder}
            />
          </>
        )}
      />
      {helperText && (
        <p
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}
        >
          {helperText}
        </p>
      )}
    </div>
  );
}

TSelectNativeMultiple.defaultProps = {
  id: 'CustomAutocomplete',
  label: 'Select Native Multiple',
  name: 'select_native_multiple',
  placeholder: 'select_native_multiple',
  limitTags: 3,
  options: [],
  value: [],
  getOptionLabel: (option) => option.label,
  getOptionSelected: (option, value) => value && option.value === value.value,
  onChange: (e) => {
    console.log('onChange');
  },
};

export default TSelectNativeMultiple;
