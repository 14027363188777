import { Grid } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { getCitiesByStateId } from '../../../../../../../../api/getCitiesByStateId';
import logradourosCode from '../../../../../../../../assets/dados/logradouro.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { getCep } from '../../../../../../../../helpers/apis';
import { mascaraCEP } from '../../../../../../../../helpers/formatacao';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

interface ICity {
  id: number;
  value: string;
  description: string;
  code: string;
  created_at: string;
  deleted_at?: string | null | undefined;
  updated_at: string;
  state_id: number;
}

interface IAddressProps {
  errors: {
    cep?: string;
    logradouro?: string;
    bairro?: string;
    estado?: string;
    municipio?: string;
    complemento?: string;
    numero?: string;
    endereco?: string;
  };
  states: { id: number; value: string }[];
  removeError: (key: string) => void;
  setErrors: (errors: { [key: string]: string }) => void;
}

export const Address = ({
  errors,
  states,
  removeError,
  setErrors,
}: IAddressProps) => {
  const [cities, setCities] = useState<ICity[]>();
  const [loadingCities, setLoadingCities] = useState(false);
  const classes = useStyles();
  const { setDadosEndereco, dadosEndereco } = usePatient();
  
  useEffect(() => {
    if (dadosEndereco?.estado) {
      getCitiesByStateId(dadosEndereco?.estado).then((response) => {
        if (response.status) {
          const formattedCitys = response?.data?.cities?.map((city) => ({
            id: city.id,
            value: city.description,
            ...city,
          }));
          setCities(formattedCitys);
        }
      });
    }
  }, []);

  const logradourosMap = useMemo(
    () =>
      logradourosCode.map(function (item) {
        return { id: item.id, value: item.value };
      }),
    [],
  );

  function removeAccents(text) {
    const accentsMap = new Map([
      ['A', 'Á|À|Ã|Â|Ä'],
      ['a', 'á|à|ã|â|ä'],
      ['E', 'É|È|Ê|Ë'],
      ['e', 'é|è|ê|ë'],
      ['I', 'Í|Ì|Î|Ï'],
      ['i', 'í|ì|î|ï'],
      ['O', 'Ó|Ò|Ô|Õ|Ö'],
      ['o', 'ó|ò|ô|õ|ö'],
      ['U', 'Ú|Ù|Û|Ü'],
      ['u', 'ú|ù|û|ü'],
      ['C', 'Ç'],
      ['c', 'ç'],
      ['N', 'Ñ'],
      ['n', 'ñ'],
    ]);

    const reducer = (acc, [key]) =>
      acc.replace(new RegExp(accentsMap.get(key), 'g'), key);

    return [...accentsMap].reduce(reducer, text);
  }

  const handleGetAddressByCep = (cep: string) => {
    if (!cep || !cep.length) return;

    const cepWithoutMask = cep.replace(/[^0-9]/gm, '');

    if (cepWithoutMask.length === 8) {
      getCep(cepWithoutMask)
        .then(async (res) => {
          if (!res.status) {
            setErrors({ cep: 'CEP inexistente' });

            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                endereco: null,
                bairro: null,
                estado: null,
                municipio: null,
                idMunicipio: null,
                logradouro: null,
              };
            });
          } else {
            const stateSelect = states.find(
              (state) => state.value === res.estado_sigla,
            );
            const logradouroSelect = logradourosCode.find(
              (logradouro) => logradouro.value === res.logradouro_tipo,
            );

            const citiesByState = await getCitiesByStateId(stateSelect?.id);

            if (!citiesByState?.data?.status) {
              setErrors({
                municipio: 'Cidades inexistentes',
              });
            } else {
              
              const formattedCitys = citiesByState?.data?.cities?.map(
                (city) => ({
                  id: city.id,
                  value: city.description,
                  ...city,
                }),
              );
              setCities(formattedCitys);

              const citySelected = citiesByState?.data?.cities?.find((city) => {
                return (
                  city.description ===
                    removeAccents(res.localidade.toUpperCase()) &&
                  city.state_id === stateSelect.id
                );
              });

              if (citySelected) {
                setDadosEndereco((prevState) => {
                  return {
                    ...prevState,
                    endereco: res.logradouro,
                    bairro: res.bairro,
                    estado: stateSelect?.id ? stateSelect?.id : null,
                    idMunicipio: citySelected.id,
                    municipio: citySelected.id,
                    logradouro: logradouroSelect ? logradouroSelect?.id : null,
                  };
                });
              }
            }
          }
        })
        .catch(console.error);
    }
  };

  const handleGetCitiesByState = async (state: number) => {
    if (!state) return;
    setLoadingCities(true);

    const cities = await getCitiesByStateId(state);
    if (!cities?.data?.status) {
      setErrors({
        municipio: 'Cidades inexistentes',
      });
    } else {
      const formattedCitys = cities?.data?.cities?.map((city) => ({
        id: city.id,
        value: city.description,
        ...city,
      }));
      setCities(formattedCitys);
    }
    setLoadingCities(false);
  };

  return (
    <Grid
      container
      spacing={3}
      className={classes.alignItemsEnd}
      style={{ marginBottom: '40px' }}
    >
      <Grid item md={4} className={classes.field_padding}>
        <DInput
          id="cep"
          placeholder="CEP"
          label="CEP"
          type="text"
          error={errors.cep}
          helperText={errors.cep}
          fullWidth={true}
          onChange={(e) => {
            removeError('cep');
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                cep: e.target.value,
              };
            });

            if (e.target.value.length >= 10) {
              handleGetAddressByCep(e.target.value);
            }
          }}
          value={mascaraCEP(dadosEndereco.cep)}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DDropDownSimple
          id="Logradouro"
          placeholder="Logradouro"
          label="Logradouro"
          fullWidth
          error={errors.logradouro}
          helperText={errors.logradouro}
          value={dadosEndereco.logradouro}
          options={logradourosMap}
          onChange={(id) => {
            removeError('logradouro');
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                logradouro: id,
              };
            });
          }}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DInput
          id="endereco"
          placeholder="Endereço"
          label="Endereço"
          type="text"
          error={errors.endereco}
          helperText={errors.endereco}
          fullWidth={true}
          onChange={(e) => {
            removeError('endereco');
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                endereco: e.target.value,
              };
            });
          }}
          value={dadosEndereco.endereco}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DInput
          id="numero"
          placeholder="Número"
          label="Número"
          type="text"
          error={errors.numero}
          helperText={errors.numero}
          fullWidth={true}
          inputProps={{ maxLength: 8 }}
          onChange={(e) => {
            removeError('numero');
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                numero: e.target.value,
              };
            });
          }}
          value={dadosEndereco.numero}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DInput
          id="Complemento"
          placeholder="Complemento"
          label="Complemento"
          type="text"
          fullWidth={true}
          onChange={(e) => {
            if (e.target.value.length <= 10) {
              setDadosEndereco((prevState) => {
                return {
                  ...prevState,
                  complemento: e.target.value,
                };
              });
            }
          }}
          value={dadosEndereco.complemento}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DInput
          id="Bairro"
          placeholder="Bairro"
          label="Bairro"
          type="text"
          error={errors.bairro}
          helperText={errors.bairro}
          fullWidth={true}
          onChange={(e) => {
            removeError('bairro');
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                bairro: e.target.value,
              };
            });
          }}
          value={dadosEndereco.bairro}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DDropDownSimple
          id="estado"
          placeholder="Estado"
          label="Estado"
          fullWidth
          error={errors.estado}
          helperText={errors.estado}
          value={dadosEndereco.estado}
          options={states}
          onChange={(id) => {
            removeError('estado');
            if (id === '') {
              setDadosEndereco((prevState) => {
                return {
                  ...prevState,
                  estado: id,
                  municipio: null,
                };
              });
            } else {
              setDadosEndereco((prevState) => {
                return {
                  ...prevState,
                  estado: id,
                };
              });
            }
            handleGetCitiesByState(id);
          }}
        />
      </Grid>

      <Grid item md={4} className={classes.field_padding}>
        <DDropDownSimple
          id="municipio"
          placeholder="Município"
          label="Município"
          fullWidth
          error={errors.municipio}
          helperText={errors.municipio}
          value={dadosEndereco.municipio}
          disabled={loadingCities || !dadosEndereco?.estado}
          options={cities}
          onChange={(id) => {
            removeError('municipio');
            console.log(id);
            setDadosEndereco((prevState) => {
              return {
                ...prevState,
                municipio: id,
              };
            });
          }}
        />
      </Grid>
    </Grid>
  );
};
