import axios from 'axios';
import env from 'react-dotenv';

export async function getListExamsByHealthAttendanceId(
  token,
  healthAttendanceId,
) {
  const res = await axios.get(
    `${env.API_URL}/above/${healthAttendanceId}/laboratoryExamsList?token=${token}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res.data;
}

export async function getImagingExamsById(token, examsId) {
  const res = await axios.get(
    `${env.API_URL}/prodam/previousImagingExamsList/${examsId}?token=${token}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res.data;
}

export default {
  getListExamsByHealthAttendanceId,
  getImagingExamsById,
};
