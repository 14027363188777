import React from 'react';
import { Avatar, Item, Time, Message, Note, Content } from './styles';

function NotificationItem(props) {
  return (
    <Item>
      <Time>{props.time}</Time>
      <Content>
        <Avatar>
          <img src={props.img} />
        </Avatar>
        <Message>{props.children}</Message>
      </Content>
      <Note>{props.note}</Note>
    </Item>
  );
}

export default NotificationItem;
