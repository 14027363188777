import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { Redirect, withRouter } from 'react-router-dom';
import { fetchCurrentUserDetails } from '../../api/user';
import PacienteList from './components/PacienteList/PacienteList';
import PacienteCreate from './components/PacienteCreate/PacienteCreate';
import PacienteDetails from './components/PacienteDetails/PacienteDetails';
import PacientePregnant from './components/PacientePregnant/PacientePregnant';

import { userDominios } from '../../api/dominios';
import { getAllUnidades } from '../../api/unidades';
import {
  filterPatient,
  getAllPatients,
  getAllPatientsFilter,
  getPatientById,
} from '../../api/patient';

import { PatientFilterContext } from '../../context/PatientFilterContext';
import CreatePaciente from './components/CreatePaciente';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

class Paciente extends Component {
  static contextType = PatientFilterContext;

  constructor(props) {
    super(props);

    this.state = {
      isList: true,
      componentLoaded: false,
      isCadastro: false,
      isDetail: false,
      isPregnant: false,
      cadastrar: false,
      login: true,
      pacientes: [],
      paciente: {},
      userDominios: {},
      unidadesRef: [],
      unidadesVinculadas: [],
      filtro: null,
      pacientesPagination: {},
      page: 1,
      loadingPacientes: true,
      timeFilter: 0,
      sideBarInternal: true,
    };
  }

  async componentDidMount() {
    await fetchCurrentUserDetails(localStorage.getItem('token')).then((res) => {
      if (res.error) this.setState({ login: false });
      else this.setState({ ...this.state, componentLoaded: true });
    });

    await getAllUnidades(localStorage.getItem('token')).then((res) => {
      var unidadesRef = [];
      var unidadesVinculadas = [];

      res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == 'no') {
            unidadesRef.push(item);
          } else {
            unidadesVinculadas.push(item);
          }
        });
      this.setState({
        ...this.state,
        unidadesVinculadas: unidadesVinculadas,
        unidadesRef: unidadesRef,
      });
    });

    this.handleRedirectProps(this.props);

    this.listarPacientes();
  }

  handleRedirectProps(props) {
    if (props.location && props.location.pathname) {
      try {
        var path = props.location.pathname.split('/')[2];

        if (path) {
          if (path === 'cadastro') {
            // /pacientes/cadastro
            return this.cadastrar();
          }

          if (!isNaN(path)) {
            // /pacientes/patientId
            return this.buscaPacienteById(path);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  componentDidUpdate() {
    const { filter } = this.context;

    if (filter.time !== this.state.timeFilter) {
      console.log('filter', filter);

      filterPatient(localStorage.getItem('token'), {
        ...filter,
        max_age: filter.idade.ate,
        min_age: filter.idade.de,
        page: 1,
      }).then((res) => {
        var pacientes = [];

        if ((res.patient && res.patient.length === 0) || !res.status) {
          pacientes.push({
            id: '-',
            full_name: '-',
            cpf: '-',
            cns: '-',
            birth_date: '-',
            pcd: '-',
            group_description: '-',
          });

          this.setState({
            ...this.state,
            pacientes: pacientes,
            pacientesPagination: {},
            loadingPacientes: false,
            timeFilter: filter.time,
            page: 1,
          });
        } else {
          res.patient.length &&
            res.patient.map((item) => {
              var pacienteObj = {};

              pacienteObj.id = item.id;
              pacienteObj.full_name = item.full_name;
              pacienteObj.birth_date = item.birth_date;
              pacienteObj.cns = item.cns;
              pacienteObj.cpf = item.cpf;
              pacienteObj.pcd = item.deficiencies;
              pacienteObj.group_description = item.hasGroup;
              pacienteObj.pronoun = item.pronoun;

              pacientes.push(pacienteObj);
            });

          this.setState({
            ...this.state,
            pacientes: pacientes,
            pacientesPagination: res.paginate,
            loadingPacientes: false,
            timeFilter: filter.time,
            page: 1,
          });
        }
      });
    }
  }

  async listarPacientes(page = 1) {
    const { filter } = this.context;

    var _parseFilter = {
      page,
      max_age: filter.idade.ate,
      min_age: filter.idade.de,
      group_id: filter.grupo,
      pcd: filter.pcd,
      medical_unit_id: filter.unidade,
      search: filter.search,
    };

    var _filter = Object.keys(_parseFilter)
      .filter(
        (key) =>
          _parseFilter[key] !== null &&
          _parseFilter[key] !== undefined &&
          _parseFilter[key] !== '',
      )
      .reduce((obj, key) => {
        obj[key] = _parseFilter[key];
        return obj;
      }, {});

    await filterPatient(localStorage.getItem('token'), {
      ..._filter,
      page,
    }).then((res) => {
      var pacientes = [];

      if ((res.patient && res.patient.length === 0) || !res.status) {
        pacientes.push({
          id: '-',
          full_name: '-',
          social_name: '-',
          cpf: '-',
          cns: '-',
          birth_date: '-',
          pcd: '-',
          group_description: '-',
        });

        this.setState({
          ...this.state,
          pacientes: pacientes,
          pacientesPagination: {},
          loadingPacientes: false,
          page,
        });
      } else {
        res.patient.length &&
          res.patient.map((item) => {
            var pacienteObj = {};

            pacienteObj.id = item.id;
            pacienteObj.full_name = item.full_name;
            pacienteObj.social_name = item.social_name;
            pacienteObj.birth_date = item.birth_date;
            pacienteObj.cns = item.cns;
            pacienteObj.cpf = item.cpf;
            pacienteObj.pcd = item.deficiencies;
            pacienteObj.group_description = item.hasGroup;
            pacienteObj.pronoun = item.pronoun;

            pacientes.push(pacienteObj);
          });

        this.setState({
          ...this.state,
          pacientes: pacientes,
          pacientesPagination: res.paginate,
          loadingPacientes: false,
          page,
        });
      }
    });
  }

  cadastrar() {
    this.setState({
      ...this.state,
      isCadastro: true,
      isDetail: false,
      isPregnant: false,
      isList: false,
    });
  }

  buscaPacienteById(id) {
    return getPatientById(localStorage.getItem('token'), id)
      .then((res) => {
        if (res.status) {
          this.setState({
            ...this.state,
            isList: false,
            isDetail: true,
            paciente: res.details,
          });

          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
        console.log(err);
      });
  }

  updatePatientInfoOnScreen(id) {
    getPatientById(localStorage.getItem('token'), id)
      .then((res) => {
        if (res.status) {
          this.setState({ ...this.state, paciente: res.details });
        }
      })
      .catch(console.error);
  }

  handleTriggerSidebarOpen(value) {
    this.props.setSidebar && this.props.setSidebar(value);
  }

  render() {
    const { history } = this.props;

    return (
      <Container className="tema-light" style={{ paddingBottom: 50 }}>
        {!this.state.login ? <Redirect to="/login" /> : ''}

        <Switch>
          <Route path="/pacientes" exact>
            <PacienteList
              detalhes={(e) => {
                this.buscaPacienteById(e.id).then(() => {
                  history && history.push('/pacientes/detalhes/' + e.id);
                });
              }}
              pacientes={this.state.pacientes}
              pagination={this.state.pacientesPagination}
              loadingPacientes={this.state.loadingPacientes}
              onPage={this.listarPacientes.bind(this)}
              page={this.state.page}
              updatePatientList={() => this.listarPacientes()}
            />
          </Route>
          <Route path="/pacientes/cadastrar" exact>
            <CreatePaciente />
          </Route>
          <Route path="/pacientes/detalhes/:id">
            <PacienteDetails
              listar={(e) => {
                this.buscaPacienteById(e.id).then(() => {
                  history && history.push('/pacientes/detalhes/' + e.id);
                });
              }}
              pregnancy={(e) =>
                this.setState({
                  ...this.state,
                  isList: false,
                  isPregnant: true,
                  isDetail: false,
                  isCadastro: false,
                })
              }
              userDominios={this.state.userDominios}
              unidadesRef={this.state.unidadesRef}
              unidadesVinculadas={this.state.unidadesVinculadas}
              getPaciente={this.buscaPacienteById.bind(this)}
              paciente={this.state.paciente}
              updatePatientInfoData={(info) => {
                var _paciente = this.state.paciente;

                if (_paciente && _paciente.patient) {
                  _paciente.patient.full_name = info.full_name;

                  this.setState({ ...this.state, paciente: _paciente });
                }
              }}
              triggerSidebar={(e) => this.handleTriggerSidebarOpen(e)}
            />
          </Route>
          <Route path="/pacientes/mae-paulistana/:id">
            <PacientePregnant
              listar={(e) =>
                this.setState({
                  ...this.state,
                  isList: true,
                  isPregnant: false,
                })
              }
              paciente={this.state.paciente}
              triggerSidebar={(e) => this.handleTriggerSidebarOpen(e)}
            />
          </Route>
        </Switch>

        {/* {this.state.isPregnant && (
          <PacientePregnant
            listar={(e) =>
              this.setState({ ...this.state, isList: true, isPregnant: false })
            }
            paciente={this.state.paciente}
            getPaciente={this.buscaPacienteById.bind(this)}
            triggerSidebar={(e) => this.handleTriggerSidebarOpen(e)}
          />
        )} */}
      </Container>
    );
  }
}

export default withRouter(Paciente);
