import axios from 'axios';
import env from 'react-dotenv';

class PowerBI {
  getAccesToken(token) {
    try {
      return axios.get(`${env.API_URL}/power-bi/auth`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new PowerBI();
