import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  CircularProgress,
  DialogContent,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DButton from './../../../../../shared/DButton';
import Construtor from '../../../../../../api/construtor';

import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 8,
  },
  cardContent: {
    paddingBottom: '5px !important',
  },
  contentRemove: {
    width: 267,
    height: 65,
    padding: '0px 24px',
  },
  titleRemove: {
    borderBottom: 'none',
  },
  buttonBack: {
    minWidth: 74,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    marginLeft: '15px',
    padding: '6px 0px',
    fontWeight: '500 !important',
  },
  buttonConfirm: {
    minWidth: 145,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 10px',
    fontWeight: '500 !important',
  },
}));

function CardRemove(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  function deleteConditionals() {
    setLoading(true);

    Construtor.destroyConditional(
      localStorage.getItem('token'),
      props.conditionalsId,
    )
      .then((res) => {
        if (res.status) {
          toast.success(res.message);
          props.onDeleted(true);
        } else {
          toast.error(res.message);
        }

        setLoading(false);
      })
      .catch((res) => {
        toast.error('Erro inesperado.');

        setLoading(false);
      });
  }

  return (
    <Dialog
      aria-labelledby="conditional-remove-title"
      scroll="body"
      onClose={(e) => {
        if (!loading) props.onClose(true);
        //else
        //console.log('Aguardando...')
      }}
      open={props.open}
    >
      <DialogTitle
        id="conditional-remove-title"
        classes={{ root: classes.titleRemove }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={8}>
            <Typography variant="body1" className="text-regular text-st">
              Confirmar a exclusão dessa condição?
            </Typography>
          </Grid>
          <Grid item>
            <Close
              onClick={(e) => {
                if (!loading) props.onClose(true);
                //else
                //console.log('Aguardando...')
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRemove }}>
        <Grid
          style={{ height: 50 }}
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DButton
              disabled={loading}
              type="button"
              classes={{ root: classes.buttonBack }}
              variant="secondary"
              size="none"
              className="btn-error"
              onClick={(e) => props.onClose(true)}
            >
              Voltar
            </DButton>
          </Grid>
          <Grid item>
            <DButton
              disabled={loading}
              type="button"
              classes={{ root: classes.buttonConfirm }}
              variant="primary"
              size="none"
              className="btn-error"
              onClick={deleteConditionals}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Confirmar'
              )}
            </DButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CardRemove;
