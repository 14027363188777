// React import
import React from 'react';
import { Component } from 'react';

// Material styles and icons import
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { toast } from 'react-toastify';
import DSwitch from '../shared/DSwitch';

// API routes import
import configuration from '../../api/configuration';

class Gravacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      idConfig: null,
    };
  }

  componentDidMount() {
    this.listConfig();
  }

  listConfig() {
    this.setState({ ...this.state, loadingGroups: true });

    configuration.get(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        this.setState({
          ...this.state,
          idConfig: res.configuration.id,
          recording: res.configuration.recording == 1 ? true : false,
        });
      } else {
        this.setState({ ...this.state, idConfig: null });
      }
    });
  }

  enableOrDisableGravacoes = (event) => {
    console.log(event.target.name, event.target.checked);
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    let status = event.target.checked;

    configuration
      .putEnableOrDisableConfig(
        localStorage.getItem('token'),
        this.state.idConfig,
        { recording: status },
      )
      .then((res) => {
        if (res.status) toast.success('Processo realizado com sucesso.');
        else toast.error('Erro ao realizar ação.');
      });
  };

  render() {
    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
      flex: { display: 'flex' },
      listItem: {
        padding: '16px 0',
        margin: 0,
        borderTop: '1px solid #c7c7c7',
      },
      firstListItem: {
        padding: '16px 0',
        margin: 0,
      },
      item_text: {
        fontSize: 14,
        margin: 0,
        padding: 0,
      },
    };

    return (
      <>
        <Container className="tema-light">
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Gravações
          </Typography>
          <Card style={{ background: '#f9f9f9' }}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant="h4">Permissões</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    <ListItem style={styles.firstListItem}>
                      <ListItemText
                        primary={'Gravação de atendimentos realizados'}
                        disableTypography={true}
                        style={styles.item_text}
                      />
                      <ListItemSecondaryAction style={{ right: 0 }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <DSwitch
                            checked={this.state.recording}
                            onChange={this.enableOrDisableGravacoes}
                            name="recording"
                          />
                          <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                            {this.state.recording
                              ? 'Habilitado'
                              : 'Desabilitado'}
                          </Typography>
                        </Box>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </>
    );
  }
}

export default Gravacoes;
