import axios from 'axios';
import env from 'react-dotenv';

class ComorbidityFamilyHistory {
  token = null;

  constructor() {
    this.token = localStorage.getItem('token');
    this.uri = `${env.API_URL}/comorbidity-family-history`;
  }

  async getAll(token) {
    const { data } = await axios.get(
      `${this.uri}/list?token=${token ? token : this.token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  }

  async getById(token, id) {
    const { data } = await axios.get(
      `${this.uri}/show/${id}?token=${token ? token : this.token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  }

  /**
   * @param {*} token
   * @param {object} body
   * @param body.description "Nome da comorbidade familiar"
   */
  async create(token, body) {
    const { data } = await axios.post(
      `${this.uri}?token=${token ? token : this.token}`,
      body,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  }

  async updateById(token, id) {
    const { data } = await axios.put(
      `${this.uri}/${id}?token=${token ? token : this.token}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  }

  async deleteById(token, id) {
    const { data } = await axios.delete(
      `${this.uri}/${id}?token=${token ? token : this.token}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
        },
      },
    );

    return data;
  }
}

export default new ComorbidityFamilyHistory();
