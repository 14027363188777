import React, { useMemo } from 'react';
import '../index.scss';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { useState } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { getPatientById } from '../../../../api/patient';
import { useEffect } from 'react';
import DAutoComplete from '../../DAutoComplete';

export default function UnidadesEdit(props) {
  const { patient, setPatient } = useNewHealthAttendance();
  const [unidadeRef, setUnidadeRef] = useState('');

  useEffect(() => {
    if (patient) {
      console.log('props.unidadeRef', props.unidadeRef);
      setUnidadeRef(props.unidadeRef);
    }
  }, [patient]);

  const unidadeRefs = useMemo(
    () =>
      props.unidadesRef.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [],
  );

  return (
    <div>
      <div className="main_flex_new_tab">
        <div className="fonte_paciente_tab">Unidade de Referência</div>
      </div>
      {unidadeRefs && (unidadeRef || unidadeRef == null) ? (
        <>
          <DAutoComplete
            id="unidadeRef"
            placeholder="Unidade de referência"
            labelColor="black"
            fullWidth={true}
            disableClearable={false}
            // error={error.unidadeRef}
            // helperText={error.unidadeRef}
            defaultValue={unidadeRefs.filter((res) => res.id == unidadeRef)[0]}
            options={unidadeRefs}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) =>
              value && option.id === value.id
            }
            onChange={(event, newValue) => {
              // delete error.unidadeRef;
              setUnidadeRef(newValue ? newValue.id : null);
              props.onChange(newValue ? newValue.id : null);
            }}
          />
        </>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando unidade de referência...
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
