import axios from 'axios';
import env from 'react-dotenv';

function getConversationByAttendenceId(token, id) {
  return axios
    .get(`${env.API_URL}/conversation/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createConversation(token, dados) {
  return axios
    .post(`${env.API_URL}/conversation`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
}

function addParticipant(token, dados) {
  return axios
    .post(
      `${env.API_URL}/conversation/add-participant`,
      JSON.stringify(dados),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getConversationByAttendenceId, createConversation, addParticipant };
