import { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import DContainer from '../../../../shared/DContainer';
import DButton from '../../../../shared/DButton';
import DInput from '../../../../shared/DInput';
import DDropDownSimple from './../../../../shared/DDropDownSimple';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useContionals } from '../../../../../context/ConditionalsContext';
import { toast } from 'react-toastify';
import Construtor from '../../../../../api/construtor';
import fieldConditionReject from './../../../../../assets/dados/fieldConditionReject.json';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow:
      '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
    fontSize: 20,
    marginTop: 3,
  },
  arrow: {
    color: '#FFF',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  backDrop: {
    zIndex: 3000,
    color: '#FFF',
  },
  card: {
    background: '#F9F9F9',
    marginBottom: 15,
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  icon: {
    color: '#949494',
  },
  iconSelect: {
    color: `${theme.palette.primary.light} !important`,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: 15,
  },
  formControlMiddle: {
    minWidth: 120,
    maxWidth: 250,
    width: '100%',
    marginBottom: 15,
  },
  iconButton: {
    cursor: 'pointer',
    color: theme.palette.support.error,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AddTrueFormCondition(props) {
  const classes = useStyles();
  const { conditionals, fields, forms, setPendingSave } = useContionals();

  function addAction() {
    props.actions.setFormConditionalsActionTrue([
      ...props.actions.formConditionalsActionTrue,
      {
        id: null,
        action: null,
        type: true,
        serviceCharacter: null,
        field: null,
        form: null,
      },
    ]);
  }

  function removeAction(index) {
    let actions = [...props.actions.formConditionalsActionTrue];
    actions.splice(index, 1);
    props.actions.setFormConditionalsActionTrue(actions);
    toast.info('Para confirmar a exclusão utilize o botão salvar condicional.');
  }

  function ActionForm(props) {
    const [id, setId] = useState(props.action.id);
    const [type, setType] = useState(props.action.type);
    const [action, setAction] = useState(props.action.action);
    const [serviceCharacter, setServiceCharacter] = useState(
      props.action.service_character,
    );
    const [field, setField] = useState(props.action.field_id);
    const [form, setForm] = useState(props.action.form_id);
    console.log(props.action.form_id);
    console.log(props.action.id);
    function update(action) {
      props.onUpdate(action);
    }

    return (
      <Grid item md={12}>
        <Card classes={{ root: classes.card }}>
          <CardContent>
            <Grid container>
              <Grid item md={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <DeleteIcon
                      classes={{ root: classes.iconButton }}
                      onClick={(e) => props.onRemove(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <FormControl
                  className={`${classes.formControl} ${props.error && props.error[props.actionKey] && props.error[props.actionKey].action ? 'form-control-error' : ''}`}
                  autoComplete="off"
                >
                  <InputLabel id="select-field-label">
                    Selecione uma ação
                  </InputLabel>
                  <Select
                    disabled={props.loading}
                    IconComponent={() => (
                      <ExpandMore
                        color="primary"
                        classes={{ colorPrimary: classes.iconSelect }}
                      />
                    )}
                    labelId="select-action-label"
                    id="select-action"
                    value={action}
                    error={
                      props.error &&
                      props.error[props.actionKey] &&
                      props.error[props.actionKey].action
                        ? props.error[props.actionKey].action
                        : false
                    }
                    onChange={(e) => {
                      setAction(e.target.value);
                      update({
                        id,
                        type,
                        action: e.target.value,
                        serviceCharacter,
                        field,
                        form,
                      });
                      setPendingSave(true);
                    }}
                  >
                    <MenuItem value="esconder">Esconder</MenuItem>
                    <MenuItem value="exibir">Exibir</MenuItem>
                    <MenuItem value="enviar_para">Enviar para</MenuItem>
                  </Select>
                  {props.error &&
                  props.error[props.actionKey] &&
                  props.error[props.actionKey].action ? (
                    <FormHelperText className="text-error">
                      {props.error[props.actionKey].action}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </FormControl>

                {action === 'enviar_para' ? (
                  <>
                    <FormControl
                      className={`${classes.formControl} ${props.error && props.error[props.actionKey] && props.error[props.actionKey].serviceCharacter ? 'form-control-error' : ''}`}
                      autoComplete="off"
                    >
                      <InputLabel id="select-field-label">
                        Selecione o caráter de atendimento
                      </InputLabel>
                      <Select
                        disabled={props.loading}
                        IconComponent={() => (
                          <ExpandMore
                            color="primary"
                            classes={{ colorPrimary: classes.iconSelect }}
                          />
                        )}
                        labelId="select-action-label"
                        id="select-action"
                        value={serviceCharacter}
                        error={
                          props.error &&
                          props.error[props.actionKey] &&
                          props.error[props.actionKey].serviceCharacter
                            ? props.error[props.actionKey].serviceCharacter
                            : false
                        }
                        onChange={(e) => {
                          setServiceCharacter(e.target.value);
                          update({
                            id,
                            type,
                            action,
                            serviceCharacter: e.target.value,
                            field,
                            form,
                          });
                          setPendingSave(true);
                        }}
                      >
                        <MenuItem value="imediato">
                          Atendimento imediato
                        </MenuItem>
                        <MenuItem value="teleinterconsulta">
                          Teleinterconsulta
                        </MenuItem>
                        <MenuItem value="telemonitoramento">
                          Telemonitoramento
                        </MenuItem>
                      </Select>
                      {props.error &&
                      props.error[props.actionKey] &&
                      props.error[props.actionKey].serviceCharacter ? (
                        <FormHelperText className="text-error">
                          {props.error[props.actionKey].serviceCharacter}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <FormControl
                      className={`${classes.formControl} ${props.error && props.error[props.actionKey] && props.error[props.actionKey].form ? 'form-control-error' : ''}`}
                      autoComplete="off"
                    >
                      <InputLabel id="select-form-label">
                        Selecione um formulário
                      </InputLabel>
                      <Select
                        disabled={props.loading}
                        IconComponent={() => (
                          <ExpandMore
                            color="primary"
                            classes={{ colorPrimary: classes.iconSelect }}
                          />
                        )}
                        labelId="select-form-label"
                        id="select-form"
                        value={form}
                        error={
                          props.error &&
                          props.error[props.actionKey] &&
                          props.error[props.actionKey].form
                            ? props.error[props.actionKey].form
                            : false
                        }
                        onChange={(e) => {
                          setForm(e.target.value);
                          update({
                            id,
                            type,
                            action,
                            serviceCharacter,
                            field,
                            form: e.target.value,
                          });
                          setPendingSave(true);
                        }}
                      >
                        {/* <MenuItem value="esconder">Esconder</MenuItem>
                                                <MenuItem value="exibir">Exibir</MenuItem>
                                                <MenuItem value="enviar_para">Enviar para</MenuItem> */}
                        {forms.map(function (item, index) {
                          return (
                            <MenuItem
                              key={`menu-item-form-${item.id}`}
                              value={item.id}
                            >
                              {item.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {props.error &&
                      props.error[props.actionKey] &&
                      props.error[props.actionKey].form ? (
                        <FormHelperText className="text-error">
                          {props.error[props.actionKey].form}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </>
                ) : (
                  ''
                )}

                {action === 'exibir' || action === 'esconder' ? (
                  <FormControl
                    className={`${classes.formControl} ${props.error && props.error[props.actionKey] && props.error[props.actionKey].field ? 'form-control-error' : ''}`}
                    autoComplete="off"
                  >
                    <InputLabel id="select-field-label">
                      Selecione o campo
                    </InputLabel>
                    <Select
                      disabled={props.loading}
                      IconComponent={() => (
                        <ExpandMore
                          color="primary"
                          classes={{ colorPrimary: classes.iconSelect }}
                        />
                      )}
                      labelId="select-field-label"
                      id="select-field"
                      value={field}
                      error={
                        props.error &&
                        props.error[props.actionKey] &&
                        props.error[props.actionKey].field
                          ? props.error[props.actionKey].field
                          : false
                      }
                      onChange={(e) => {
                        setField(e.target.value);
                        update({
                          id,
                          type,
                          action,
                          serviceCharacter,
                          field: e.target.value,
                          form,
                        });
                        setPendingSave(true);
                      }}
                    >
                      {fields.map(function (item, index) {
                        if (!fieldConditionReject.includes(item.value)) {
                          return (
                            <MenuItem
                              key={`menu-item-field-${item.id}`}
                              value={item.id}
                            >
                              {item.value}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                    {props.error &&
                    props.error[props.actionKey] &&
                    props.error[props.actionKey].field ? (
                      <FormHelperText className="text-error">
                        {props.error[props.actionKey].field}
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function updateActions(action, key) {
    const old_actions = [...props.actions.formConditionalsActionTrue];

    old_actions[key] = {
      id: action.id,
      type: action.type,
      action: action.action,
      service_character: action.serviceCharacter,
      field_id: action.field,
      form_id: action.form,
    };

    props.actions.setFormConditionalsActionTrue(old_actions);
  }

  return (
    <>
      {fields.length > 0 && forms.length > 0 ? (
        <Grid container>
          <Grid item md={12}>
            <Typography
              style={{ marginTop: 15, marginBottom: 15 }}
              className="text-success"
              variant="h5"
              gutterBottom
            >
              Se as condições forem verdadeiras
            </Typography>
          </Grid>
          <>
            {props.actions.formConditionalsActionTrue.map(
              function (item, index) {
                return (
                  <ActionForm
                    error={props.error}
                    loading={props.loading}
                    actionKey={index}
                    key={`action-form-key-${item.id}`}
                    onRemove={(e) => removeAction(index)}
                    onUpdate={(action) => updateActions(action, index)}
                    chave={index}
                    action={item}
                  />
                );
              },
            )}
          </>
          <Grid item md={12}>
            <DButton
              disabled={props.loading}
              style={{ marginTop: 15, marginBottom: 15 }}
              type="button"
              variant="ghost"
              size="sm"
              icon={<AddCircleIcon />}
              onClick={(e) => addAction()}
            >
              Adicionar ação
            </DButton>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
}

export default AddTrueFormCondition;
