import * as React from 'react';
import env from 'react-dotenv';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, makeStyles } from '@material-ui/core';
import './index.scss';
import { ResultExaImg } from '../../../../api/above';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import ExamModalVisualizadorClinico from '../../ExamModalVisualizadorClinico';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaVacinas: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

export default function CustomizedTables() {
  const { healthAttendance } = useNewHealthAttendance();
  const [exames, setExames] = React.useState(null);
  const [exame, setExame] = React.useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (healthAttendance) {
      ResultExaImg.getPreviousImagingExams(
        localStorage.getItem('token'),
        healthAttendance.id,
      ).then((data) => {
        if (data.status) {
          setExames(data.details);
        }
      });
    }
  }, []);

  function formatDate(date) {
    if (date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : null;
      var day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
      var month =
        date.getMonth() + 1 >= 10 ? date.getMonth() : '0' + date.getMonth();
      var year = String(date.getFullYear()).slice(2);

      return [day, month, year].join('/');
    } else {
      return '';
    }
  }

  return (
    <>
      {exames && exames.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Data</StyledTableCell>
                <StyledTableCell align="left">Unidade</StyledTableCell>
                <StyledTableCell align="left">Solicitante</StyledTableCell>
                <StyledTableCell align="center">Resultado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exames &&
                exames.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {item.order_date && formatDate(item.order_date)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.name_Unit_origin}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.name_applicant}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          className={classes.iconResultadoTabelaVacinas}
                          onClick={(e) => setExame(item.id)}
                          startIcon={<DownloadIcon />}
                        >
                          {' '}
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ''
      )}

      {exame && (
        <ExamModalVisualizadorClinico
          onClose={(e) => setExame(null)}
          open={true}
          src={`${env.API_URL}/prodam/previousImagingExams/${exame}?token=${localStorage.getItem('token')}`}
        />
      )}
    </>
  );
}
