import openIcon from '../../../../../../assets/img-icon/openIcon.svg';
import assumedIcon from '../../../../../../assets/img-icon/assumedIcon.svg';
import finishedIcon from '../../../../../../assets/img-icon/finishedIcon.svg';
import { makeStyles, Tooltip } from '@material-ui/core';

export default function HealthAttendanceStatus(props) {
  const { status } = props;

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} placement="top" {...props} />;
  }

  if (status == 1) {
    return (
      <BootstrapTooltip title="Aberto">
        <img
          height={props.height ? props.height : ''}
          width={props.width ? props.width : ''}
          src={openIcon}
        />
      </BootstrapTooltip>
    );
  }

  if (status == 2) {
    return (
      <BootstrapTooltip title="Assumido">
        <img
          height={props.height ? props.height : ''}
          width={props.width ? props.width : ''}
          src={assumedIcon}
        />
      </BootstrapTooltip>
    );
  }

  if (status == 3) {
    return (
      <BootstrapTooltip title="Finalizado">
        <img
          height={props.height ? props.height : ''}
          width={props.width ? props.width : ''}
          src={finishedIcon}
        />
      </BootstrapTooltip>
    );
  }

  if (status == 4) {
    return (
      <BootstrapTooltip title="Cancelado">
        <img
          height={props.height ? props.height : ''}
          width={props.width ? props.width : ''}
          src={finishedIcon}
        />
      </BootstrapTooltip>
    );
  }
}
