import { Container } from '@material-ui/core';
import styled from 'styled-components';

function DContainer(props) {
  const window_height = window.innerHeight;

  const StyledContainer = styled(Container)`
    background: #f4f4f4;
    margin-top: 5px;
    height: auto;
    min-height: ${window_height}px;
    padding-top: 5px;
  `;

  return <StyledContainer {...props}>{props.children}</StyledContainer>;
}

export default DContainer;
