import CheckIcon from '@material-ui/icons/Check';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import LiberadoIcon from '../../../../assets/img-icon/assumedIcon.svg';
import AguardandoIcon from '../../../../assets/img-icon/openIcon.svg';
import BloqueadoIcon from '../../../../assets/img-icon/finishedIcon.svg';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.support.success,
  },

  attention: {
    color: theme.palette.support.attention,
  },

  error: {
    color: theme.palette.support.error,
  },
}));

export default function StatusProfissional(props) {
  const classes = useStyles();
  const { status } = { ...props };

  const handleIcon = () => {
    switch (status) {
      case 'Liberado':
        //return <CheckIcon className={classes.success}/>;
        return <img alt="Liberado" title="Liberado" src={LiberadoIcon} />;
      case 'Bloqueado':
        //return <LockOutlinedIcon className={classes.error}/>;
        return <img alt="Bloqueado" title="Bloqueado" src={BloqueadoIcon} />;
      default:
        return <img alt="Aguardando" title="Aguardando" src={AguardandoIcon} />;
      //return <QueryBuilderIcon className={classes.attention}/>;
    }
  };

  const Status = (props) => {
    const { icon, label } = { ...props };

    return (
      <>
        <img src={icon} alt={label} title={label} style={{ marginRight: 8 }} />
      </>
    );
  };

  return handleIcon(); //<Status icon={ handleIcon() } label={ status } />
}
