import styled from 'styled-components';

export const SimpleInput = styled.input`
  width: ${(props) =>
    props.fullWidth ? '100%' : props.width ? props.width : ''};
  height: ${(props) => (props.height ? props.height : '30px')};

  border: ${(props) =>
    props.border ? props.border : props.noBorder ? 'none' : '1px solid #ccc'};

  padding: ${(props) =>
    props.padding ? props.padding : props.noPadding ? '' : '5px'};

  outline: none;
`;
