/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useEffect, useState } from "react";
import { userDominios } from "../../../api/dominios";
import { getAllProfiles, getAllRoleGroups } from "../../../api/profiles";
import { IProfile, IState } from "./interfaces/state.interface";
import ModalConfirmClose from "./modals/ModalConfirmClose";
import ModalConfirmDelete from "./modals/ModalConfirmDelete";
import ModalProfile from "./modals/ModalProfile";
import { classStyles, styles } from "./styles";

const initialState: IState = {
  loadingProfiles: true,
  loadingScreen: true,
  profiles: [],
  permissions: [],
  counterPermission: [],
  documents: [],
  novoPerfil: false,
  limpandoPerfil: false,
  isEdit: { status: false, id: null, duplicate: false },
  creatingNewProfile: false,
  createdProfile: false,
  createProfileError: false,
  reloadProfileUnit: false,
  setErrors: null,
  confirmClose: false,
  createRegistry: false,
  registryDuplicaded: false,
  delete: false,
  rowSelected: {
    description: '',
  },
  indexSelected: null,
};


const Profiles = () => {
  const [state, setState] = useState<IState>(initialState);
  const classes = classStyles();

  const token = localStorage.getItem('token');

  const listProfiles = async () => {
    setState(prev => ({ ...prev, loadingProfiles: true, profiles: [] }));
    
    await getAllProfiles(token).then((res) => {
      if (res.status) {
        const newStateProfiles: IProfile[] = [];
        res.profiles.data.forEach((row: IProfile) => {
          newStateProfiles.push({
            id: row.id,
            description: row.description,
            profile_permissions: row.profile_permissions,
            isLoading: false,
          });
          setState(prev => ({ ...prev, loadingProfiles: false, profiles: newStateProfiles }));
        });
      } else {
        setState(prev => ({ ...prev, loadingProfiles: false, profiles: [] }));
      }
    });
  }

  const getRoleGroups = async () => {
    await getAllRoleGroups(token).then((res) => {
      res.status &&
        setState(current => ({
          ...current,
          permissions: res.rolesGroup,
          counterPermission: res.rolesCounter
        }));
    });
  }

  const getDocuments = async () => {
    await userDominios().then((res) => {
      res.status &&
        setState(current => ({ ...current, documents: res.document_type }));
    });
  }

  useEffect(() => {
    Promise.all([
      listProfiles(),
      getRoleGroups(),
      getDocuments(),
    ])
  }, []);

  return (
    <Container className="tema-light">
      <Typography
        style={styles.titleTop}
        variant="h3"
        className="text-primary"
      >
        Perfis e permissões
      </Typography>
      <Card style={{ background: '#f9f9f9' }}>
        <CardContent>
          <Grid container>
            <Grid item md={9}>
              <Typography variant="h4">Lista de perfis</Typography>
            </Grid>
            <Grid item md={3} style={styles.flex} justifyContent="flex-end">
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                className={classes.addTagButton}
                onClick={() => setState({
                  ...state,
                  novoPerfil: true,
                  limpandoPerfil: true,
                  createdProfile: false,
                  isEdit: { status: false, id: null, duplicate: false },
                  registryDuplicaded: false
                })}
              >
                Criar perfil
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: 24 }}>
            <Grid item md={12}>
              <List>
                {state.profiles.length
                  ? state.profiles.map((row, index) => (
                    <ListItem key={row.id}>
                      <ListItemText primary={row.description} />
                      <ListItemSecondaryAction>
                        {row.id > 1 ? (
                          <Button
                            size="small"
                            startIcon={
                              <DeleteIcon style={{ fontSize: 20 }} />
                            }
                            className={classes.deleteButton}
                            onClick={() => {
                              if (!row.isLoading) {
                                setState({
                                  ...state,
                                  delete: true,
                                  rowSelected: row,
                                  indexSelected: index,
                                });
                              }
                            }}
                          >
                            {row.isLoading ? (
                              <CircularProgress size={15} color="inherit" />
                            ) : (
                              'Excluir'
                            )}
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button
                          size="small"
                          startIcon={
                            <FileCopyOutlinedIcon
                              style={{ fontSize: 20 }}
                            />
                          }
                          className={classes.editButton}
                          onClick={() => {
                            setState({
                              ...state,
                              isEdit: {
                                status: true,
                                id: row.id,
                                duplicate: true,
                              },
                              novoPerfil: true,
                            });
                          }}
                        >
                          Duplicar
                        </Button>
                        <Button
                          size="small"
                          startIcon={<EditIcon style={{ fontSize: 20 }} />}
                          className={classes.editButton}
                          onClick={() => {
                            setState({
                              ...state,
                              isEdit: {
                                status: true,
                                id: row.id,
                                duplicate: false,
                              },
                              novoPerfil: true,
                            })
                          }}
                        >
                          Editar
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                  : state.profiles.length === 0 &&
                    !state.loadingProfiles
                    ? 'Não existem perfis cadastrados'
                    : 'Carregando perfis do Sistema '}
                  { state.loadingProfiles && (
                    <CircularProgress size={15} color="inherit" />
                  ) }
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {state.confirmClose ?
        <ModalConfirmClose
          state={state}
          setState={setState} /> : ''
      }
      {state.delete ?
        <ModalConfirmDelete
          state={state}
          setState={setState}
          token={token} /> : ''
      }
      {state.novoPerfil ?
        <ModalProfile
          listProfiles={listProfiles}
          setState={setState}
          state={state}
          token={token} /> : ''
      }
    </Container>
  );
}

export default Profiles
