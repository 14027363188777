import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Duplicar from '../../../../assets/img/Duplicar.png';
import { checkScheduleConflictFromUser } from '../../../../api/schedule/agenda-medico';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    justifyContent: 'left',
  },
  backgroundModalExcluir: {
    minWidth: '450px',
    maxWidth: '500px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.neutral.white,
    margin: '200px auto',
    padding: '20px',
  },
  mainTitle: {
    textAlign: 'center',
    color: theme.palette.neutral.black,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  titleDelete: {
    textAlign: 'center',
    color: theme.palette.neutral.darkgray,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  columnGrid: {
    textAlign: 'center',
    marginTop: 20,
  },

  btnExcluir: {
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    borderRadius: '10px',
    gap: '10px',
  },
  btnEditar: {
    background: `${theme.palette.support.error}`,
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    gap: '10px',
    borderRadius: '10px',
    color: 'white',
    transition: 'all 200ms linear',

    '&:hover': {
      color: 'black',
    },
  },
  innerModal: {
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  listConflict: {
    margin: '5px',
    padding: '0',
  },
}));

export default function AgendaDuplicadaModal({
  onExcluir,
  onEditar,
  open,
  onClose,
  daysWeek,
  userProfessional,
}) {
  const classes = useStyles();

  const [scheduleDaysConflict, setScheduleDaysConflict] = useState(null);

  useEffect(() => {
    if (open && daysWeek !== null && userProfessional !== null) {
      var payload = daysWeek;

      checkScheduleConflictFromUser(userProfessional, payload)
        .then((response) => {
          if (response.status) {
            setScheduleDaysConflict(response.scheduleDaysConflict);
          }
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao carregar os horários em conflito.');
          console.log(error);
        })
        .finally(() => {});
    }
  }, [open, daysWeek, userProfessional]);

  function getDayOfWeek(dayWeek) {
    var nameDay = '';

    switch (dayWeek) {
      case 1:
        nameDay = 'Segunda-feira';
        break;
      case 2:
        nameDay = 'Terça-feira';
        break;
      case 3:
        nameDay = 'Quarta-feira';
        break;
      case 4:
        nameDay = 'Quinta-feira';
        break;
      case 5:
        nameDay = 'Sexta-feira';
        break;
      case 6:
        nameDay = 'Sábado';
        break;
      case 7:
        nameDay = 'Domingo';
        break;
    }

    return nameDay;
  }

  function showConflict() {
    const listConflict = scheduleDaysConflict.map(function (data) {
      return (
        <>
          <p className={classes.listConflict}>
            {getDayOfWeek(data.day_week)} - {data.time_start} {data.time_end}
          </p>
        </>
      );
    });

    return listConflict;
  }

  return (
    <Modal open={open}>
      <Grid container className={classes.backgroundModalExcluir}>
        <Grid container style={{ flexDirection: 'row-reverse' }}>
          <Button
            id="basic-button"
            aria-haspopup="true"
            onClick={() => onClose()}
          >
            <CloseOutlined />
          </Button>
        </Grid>
        <div className={classes.innerModal}>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={Duplicar} alt="" />
            <h2 className={classes.mainTitle}>Conflito de horários</h2>
            <h3 className={classes.titleDelete}>
              Os dias a seguir possuem conflitos com outras agendas deste
              profissional. Volte à página anterior e corrija-os antes de salvar
              a nova agenda.
            </h3>
            {scheduleDaysConflict ? showConflict() : ''}
          </Grid>

          <Grid container>
            <Grid item md={12} className={classes.columnGrid}>
              <Button
                className={classes.btnExcluir}
                onClick={(e) => {
                  onEditar();
                }}
              >
                Voltar
              </Button>
            </Grid>

            {/* <Grid item md={6} className={classes.columnGrid}>
              <Button
                className={classes.btnExcluir}
                onClick={(e) => {
                  onExcluir();
                }}
              >
                Excluir agenda
              </Button>
            </Grid> */}

            {/* <Grid item md={6} className={classes.columnGrid}>
              <Button
                onClick={(e) => {
                  onEditar();
                }}
                className={classes.btnEditar}
              >
                Editar
              </Button>
            </Grid> */}
          </Grid>
        </div>
      </Grid>
    </Modal>
  );
}

AgendaDuplicadaModal.defaultProps = {
  onExcluir: () => console.log('Excluir'),
  onEditar: () => {
    console.log('Editar');
  },
  open: true,
};
