import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import tiposTelefoneCode from '../../../../../../../../assets/dados/tiposTelefone.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { mascaraNumero } from '../../../../../../../../helpers/formatacao';
import DDropDownSimple from '../../../../../../../shared/DDropDownSimple';
import DInput from '../../../../../../../shared/DInputWithPlaceholder';
import { useStyles } from './styles';

export interface IPhone {
  id: number;
  patient_id: number;
  name: string;
  type: string;
  phone: string;
  observation: string;
}

interface PhonesProps {
  phones?: IPhone[];
  setPhones: (phones: IPhone[]) => void;
  errors: {
    telefones?: {
      index: number;
      phone?: string;
      type?: string;
      name?: string;
    }[];
  };
  removeError: (key: string) => void;
}

export const Phones = ({
  phones,
  setPhones,
  errors,
  removeError,
}: PhonesProps) => {
  const classes = useStyles();
  const { dadosPrincipais } = usePatient();

  const tiposTelefoneMap = useMemo(
    () =>
      tiposTelefoneCode.map((item) => {
        return { id: item, value: item };
      }),
    [],
  );

  function novoTelefone() {
    removeError('telefones');

    setPhones((prevState) => {
      return [
        ...prevState,
        {
          id: prevState.length + 1,
          phone: '',
          name: '',
          type: null,
          observation: null,
        },
      ];
    });
  }
  return (
    <>
      {phones?.length ? (
        <>
          {phones?.map(function (item, i) {
            return (
              <div key={i} className={classes.dados_card}>
                <Grid container spacing={1}>
                  <Grid item md={3}>
                    Telefone
                    <br />
                    <DInput
                      id={`telefone-${i}`}
                      placeholder="Telefone"
                      style={{ marginTop: 5 }}
                      type="text"
                      error={
                        errors?.telefones?.find((res) => res.index === i)?.phone
                      }
                      helperText={
                        errors?.telefones?.find((res) => res.index === i)?.phone
                      }
                      fullWidth={true}
                      onChange={(e) => {
                        removeError(`telefones`);
                        let new_telefone = [...phones];

                        new_telefone[i].phone = mascaraNumero(e.target.value);
                        setPhones(new_telefone);
                      }}
                      value={item.phone}
                    />
                  </Grid>
                  <Grid item md={3}>
                    Tipo de telefone
                    <br />
                    <DDropDownSimple
                      primaryColorSelectIcon
                      removeDropDownMargin
                      id={`tipo-telefone-${i}`}
                      fullWidth
                      labelColor="black"
                      // required={!dadosIncompletos}
                      error={
                        errors?.telefones?.find((res) => res.index === i)?.type
                      }
                      helperText={
                        errors?.telefones?.find((res) => res.index === i)?.type
                      }
                      labelClass={classes.labelBlack}
                      value={item?.type}
                      options={tiposTelefoneMap}
                      onChange={(event) => {
                        removeError(`telefones`);

                        let new_telefone = [...phones];
                        new_telefone[i].type = event;
                        setPhones(new_telefone);
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    Apelido
                    <br />
                    <Grid item md={12}>
                      <DInput
                        id={`apelido-telefone-${i}`}
                        placeholder="Apelido"
                        style={{ marginTop: 5 }}
                        inputProps={{ maxLength: 10 }}
                        type="text"
                        error={
                          errors?.telefones?.find((res) => res.index === i)
                            ?.name
                        }
                        helperText={
                          errors?.telefones?.find((res) => res.index === i)
                            ?.name
                        }
                        fullWidth={true}
                        onChange={(e) => {
                          removeError(`telefones`);

                          let new_telefone = [...phones];
                          new_telefone[i].name = e.target.value;
                          setPhones(new_telefone);
                        }}
                        value={item?.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        if (
                          phones.length === 1 &&
                          !dadosPrincipais.dadosIncompletos
                        ) {
                          toast.error(
                            'Você deve informar pelo menos um telefone do paciente.',
                          );
                          return false;
                        }

                        removeError(`telefones`);

                        let new_telefone = [...phones];
                        new_telefone.splice(i, 1);
                        setPhones(new_telefone);
                      }}
                    >
                      <DeleteForever style={{ color: 'red' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            );
          })}

          <Grid item md={12}>
            <Button
              variant="text"
              className={classes.dados_editButton}
              onClick={novoTelefone}
            >
              <AddCircleIcon fontSize="small" />
              <Box ml={1}>Adicionar telefone</Box>
            </Button>
          </Grid>
        </>
      ) : (
        <Typography variant="body2" color="textSecondary">
          Nenhum telefone adicionado
        </Typography>
      )}
    </>
  );
};
