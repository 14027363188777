import axios from 'axios';
import env from 'react-dotenv';

export async function getExamsByCPF(token, cpf) {
  const res = await axios.get(
    `${env.API_URL}/above/medicalExamSearchByCPF/${cpf}?token=${token}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    },
  );
  return res.data;
}

export default {
  getExamsByCPF,
};
