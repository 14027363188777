import React, { useState, useContext, useEffect } from 'react';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { PersonPinIcon } from '../../../shared/Icon/Icon';

import { AppContext } from '../../../../context/AppContext';
import { getProfilePermissionsById } from '../../../../api/profiles';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto 24px auto',
  },
  menuContent: {
    width: '100%',
    marginBottom: 16,
  },
  menuItemTitle: {
    marginTop: 48,
    marginBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  menuItemTitleIcon: {
    color: theme.palette.primary.light,
    width: 24,
    height: 24,
    marginRight: 12,
    marginTop: 4,
  },
  menuItemTitle2: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 16,
    cursor: 'pointer',
  },
  menuItemTitle2Active: {
    paddingTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 8,
    marginBottom: 28,
    background: theme.palette.background.grass,
    cursor: 'pointer',
  },
  menuItem: {
    padding: '8px 24px',
  },
  menuItemActive: {
    padding: '8px 24px',
    background: theme.palette.background.grass,
  },
  iconProfile: {
    fill: theme.palette.primary.light,
    color: theme.palette.primary.light,
  },
}));

function Sidebar(props) {
  const classes = useStyles();

  const { userInfo } = useContext(AppContext);

  const [haveCallPermission, setHaveCallPermission] = useState(false);

  useEffect(() => {
    if (userInfo.perfil) {
      getProfilePermissionsById(
        localStorage.getItem('token'),
        userInfo.perfil,
      ).then((res) => {
        console.log(res);

        if (res.status) {
          var obj = res.profile[0].profile_permissions;

          if (
            obj.find(
              (res) => res?.permission?.code == 'medical-unit-call-permission',
            )
          ) {
            setHaveCallPermission(true);
          } else {
            setHaveCallPermission(false);
          }
        }
      });
    }
  }, [userInfo.perfil]);

  return (
    <div className={classes.menu}>
      <Grid
        container
        alignItems="center"
        classes={{ root: classes.menuItemTitle }}
      >
        <Grid item>
          <AddBoxIcon
            color="primary"
            classes={{ colorPrimary: classes.menuItemTitleIcon }}
          />
        </Grid>
        <Grid item>
          <Typography className="text-st text-medium" variant="body1">
            Atendimento
          </Typography>
        </Grid>
      </Grid>
      <Grid container classes={{ root: classes.menuContent }}>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'comorbidades'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('comorbidades')}
          >
            Comorbidades
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'especialidades'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('especialidades')}
          >
            Especialidades
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'forms'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('forms')}
          >
            Formulários
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'biomedidas'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('biomedidas')}
          >
            Biomedidas
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'linhas'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('linhas')}
          >
            Linhas de cuidado
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'grupos'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('grupos')}
          >
            Grupo de pacientes
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'sintomas'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('sintomas')}
          >
            Sintomas
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'unidades'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('unidades')}
          >
            Unidades
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'gravacoes'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('gravacoes')}
          >
            Gravações
          </Typography>
        </Grid>
        {haveCallPermission === true ? (
          <Grid
            item
            md={12}
            classes={{
              root:
                props.active === 'ligacoes'
                  ? classes.menuItemActive
                  : classes.menuItem,
            }}
          >
            <Typography
              className="text-st text-regular text-pointer"
              variant="body1"
              onClick={(e) => props.onChange('ligacoes')}
            >
              Ligações
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid
          item
          md={12}
          classes={{
            root:
              props.active === 'temas'
                ? classes.menuItemActive
                : classes.menuItem,
          }}
        >
          <Typography
            className="text-st text-regular text-pointer"
            variant="body1"
            onClick={(e) => props.onChange('temas')}
          >
            Identidade visual
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center" classes={{ root: classes.menuItemTitle }}>
                <Grid item>
                    <MonetizationOnIcon color="primary" classes={{ colorPrimary: classes.menuItemTitleIcon }} />
                </Grid>
                <Grid item>
                    <Typography className="text-st text-medium" variant="body1">Pagamento</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12} classes={{root: classes.menuItem}}>
                    <Typography className="text-st text-regular text-pointer" variant="body1">Consulta imediata</Typography>
                </Grid>
                <Grid item md={12} classes={{root: classes.menuItem}}>
                    <Typography className="text-st text-regular text-pointer" variant="body1">Consulta agendada</Typography>
                </Grid>
                <Grid item md={12} classes={{root: classes.menuItemTitle2}}>
                    <Typography className="text-st text-regular text-pointer" variant="body1">Desconto de parceirias</Typography>
                </Grid>
            </Grid> */}
      <Grid
        container
        alignItems="center"
        classes={{
          root:
            props.active === 'perfis'
              ? classes.menuItemTitle2Active
              : classes.menuItemTitle2,
        }}
      >
        <Grid item>
          <PersonPinIcon
            width={24}
            style={{ marginRight: 12, marginTop: 4 }}
            className={classes.iconProfile}
          />
        </Grid>
        <Grid item>
          <Typography
            className="text-st text-medium"
            variant="body1"
            onClick={(e) => props.onChange('perfis')}
          >
            Perfis e permissões
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center" classes={{ root: classes.menuItemTitle2 }}>
                <Grid item>
                    <LockIcon color="primary" classes={{ colorPrimary: classes.menuItemTitleIcon }} />
                </Grid>
                <Grid item>
                    <Typography className="text-st text-medium" variant="body1">Segurança</Typography>
                </Grid>
            </Grid> */}
      {/* <Grid container alignItems="center" classes={{ root: classes.menuItemTitle2 }}>
                <Grid item>
                    <ListCheckIcon width={24} style={{ marginRight: 12, marginTop: 4 }} />
                </Grid>
                <Grid item>
                    <Typography className="text-st text-medium" variant="body1">Termo de uso</Typography>
                </Grid>
            </Grid> */}
      <Grid
        container
        alignItems="center"
        classes={{
          root:
            props.active === 'email'
              ? classes.menuItemTitle2Active
              : classes.menuItemTitle2,
        }}
      >
        <Grid item>
          <EmailIcon
            width={24}
            className={classes.iconProfile}
            style={{ marginRight: 12, marginTop: 4 }}
          />
        </Grid>
        <Grid item>
          <Typography
            className="text-st text-medium"
            variant="body1"
            onClick={(e) => props.onChange('email')}
          >
            E-mails
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        classes={{
          root:
            props.active === 'termosUso'
              ? classes.menuItemTitle2Active
              : classes.menuItemTitle2,
        }}
      >
        <Grid item>
          <MenuBookIcon
            width={24}
            className={classes.iconProfile}
            style={{ marginRight: 12, marginTop: 4 }}
          />
        </Grid>
        <Grid item>
          <Typography
            className="text-st text-medium"
            variant="body1"
            onClick={(e) => props.onChange('termosUso')}
          >
            Termos de Uso
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        classes={{
          root:
            props.active === 'faq'
              ? classes.menuItemTitle2Active
              : classes.menuItemTitle2,
        }}
      >
        <Grid item>
          <HelpIcon
            width={24}
            className={classes.iconProfile}
            style={{ marginRight: 12, marginTop: 4 }}
          />
        </Grid>
        <Grid item>
          <Typography
            className="text-st text-medium"
            variant="body1"
            onClick={(e) => props.onChange('faq')}
          >
            FAQ
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center" classes={{ root: classes.menuItemTitle2 }}>
                <Grid item>
                    <IdentidadeVisualIcon width={24} style={{ marginRight: 12, marginTop: 4 }} />
                </Grid>
                <Grid item>
                    <Typography className="text-st text-medium" variant="body1">Identidade visual</Typography>
                </Grid>
            </Grid> */}
      {/* <Grid container alignItems="center" classes={{ root: classes.menuItemTitle2 }}>
                <Grid item>
                    <Typography className="text-st text-regular" variant="body1">Logo</Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" classes={{ root: classes.menuItemTitle2 }}>
                <Grid item>
                    <Typography className="text-st text-regular" variant="body1">Cores</Typography>
                </Grid>
            </Grid> */}
    </div>
  );
}

export default Sidebar;
