import { useState } from 'react';
import useError from '../../../../hooks/useError';
import { required } from './../../../../helpers/validacoes';

import DInput from '../../../shared/DInput';
import DButton from '../../../shared/DButton';

import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import SimpleModal from '../../../shared/Modal';

function FormRecover(props) {
  const [email, setEmail] = useState('');
  const [formvalid, setFormValid] = useState(false);
  const [error, validarFormulario, setErrors] = useError({
    email: required,
  });

  return (
    <>
      <form
        className="form-login"
        autoComplete="off"
        onSubmit={(form) => {
          form.preventDefault();
          if (validarFormulario({ email })) props.recover({ email });
        }}
      >
        <div
          style={{
            height: 150,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
        >
          <Typography>Digite seu e-mail para alterar sua senha</Typography>
          <DInput
            fullWidth={true}
            label="E-mail"
            name="email"
            positionIcon="end"
            error={!!error.email}
            helperText={error.email ? error.email : ''}
            onChange={(e) => {
              setEmail(e.target.value);
              setFormValid(true);
            }}
          />
        </div>
        <Grid container className="btn-toolbar">
          <Grid item xs={6}>
            <DButton variant="secondary" onClick={(e) => props.voltar(true)}>
              Voltar
            </DButton>
          </Grid>
          <Grid item xs={6}>
            <DButton
              variant="primary"
              className="color-white"
              disabled={!formvalid}
              type="submit"
            >
              {props.loading ? <CircularProgress color="inherit" /> : 'Avançar'}
            </DButton>
          </Grid>
        </Grid>
      </form>
      <SimpleModal open={props.modal} onClose={(e) => props.onCloseModal(true)}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 200 }}
        >
          <Grid item className="text-center">
            <CheckCircleOutline
              className="text-success"
              style={{ fontSize: 64 }}
            />
            <Typography className="text-mt text-regular">
              Solicitação feita com sucesso
            </Typography>
          </Grid>
        </Grid>
      </SimpleModal>
    </>
  );
}

export default FormRecover;
