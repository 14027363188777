import React from 'react';

const CalendarMonthContext = React.createContext({
  monthIndex: 0,
  setMonthIndex: (index) => {},
  openScheduleOfTheDay: null,
  setOpenScheduleOfTheDay: () => {},
  dayBlock: [],
  setDayBlock: () => {},
  openModalBloqueio: false,
  setOpenModalBloqueio: () => {},
  openModalBloqueioDia: false,
  setOpenModalBloqueioDia: () => {},
  openModalDesbloqueio: false,
  setOpenModalDesbloqueio: () => {},
});

export default CalendarMonthContext;
