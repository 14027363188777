import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { getPatientById } from '../../../../../api/patient';
import LineCares from '../../../../../api/lineCare';
import DMultAutoComplete from '../../../DMultAutoComplete';
import { useNewHealthAttendance } from '../../../../../context/NewHealthAttendance';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 20,
  },
  consultas_titulo: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    padding: '4px 0 0 0',
    margin: 0,
  },
  removeItem: {
    color: theme.palette.support.error,
  },
  buttonSave: {
    minWidth: 74,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
    color: `${theme.palette.primary.light} !important`,
  },
  buttonAdd: {
    minWidth: 110,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
  },
}));

function PatientLineCare(props) {
  const classes = useStyles();
  const [linhasDeCuidado, setLinhasDeCuidado] = useState([]);
  const [lineCare, setLineCare] = useState([]);
  const [updatedLineCare, setUpdatedLineCare] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loadingLineCare, setLoadingLineCare] = useState(false);
  const { patient, setPatient } = useNewHealthAttendance();

  useEffect(function () {
    getPatientById(localStorage.getItem('token'), props.paciente.id).then(
      (res) => {
        if (res.status) {
          if (res.details.patient.patient_line_care.length > 0) {
            setLineCare(
              res.details.patient.patient_line_care
                .filter((res) => res.line_care !== null)
                .map((item) => {
                  return {
                    id: item.line_care_id,
                    value: item.line_care.name,
                  };
                }),
            );
          }
        }
      },
    );

    LineCares.getAllLineCare(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        setLinhasDeCuidado(
          res.lineCare
            .filter(function (item) {
              return item.active === 1;
            })
            .map(function (item) {
              return {
                id: item.id,
                value: item.name,
              };
            }),
        );
      }
    });
  }, []);

  useEffect(
    function () {
      if (update) {
        setLoadingLineCare(true);

        LineCares.vinculateAllLineCare(
          localStorage.getItem('token'),
          props.paciente.id,
          {
            line_care:
              updatedLineCare.length > 0
                ? updatedLineCare.map((res) => res.id)
                : [],
          },
        )
          .then((res) => {
            if (res.status) {
              setLineCare(updatedLineCare);
              toast.success(
                res.message ? res.message : 'Realizado com sucesso.',
              );
              getPatientById(localStorage.getItem('token'), patient.id).then(
                (res) => {
                  if (res.status) {
                    setPatient(res.details.patient);
                  }
                },
              );
            } else {
              toast.error(res.message ? res.message : 'Erro inesperado.');
            }

            setLoadingLineCare(false);

            if (props.saveClose)
              props.onClose({
                lineCare: updatedLineCare,
                lineCares: linhasDeCuidado,
                close: true,
              });
          })
          .catch((err) => {
            console.log(err);
            toast.error('Erro inesperado.');
            setLoadingLineCare(false);
          });
      }
    },
    [updatedLineCare],
  );

  return (
    <>
      {/* <h5 className={ classes.consultas_titulo }>Linhas de Cuidado</h5>           */}
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          {loadingLineCare ? (
            <CircularProgress size={15} />
          ) : (
            <DMultAutoComplete
              placeholder="Linhas de cuidado"
              label="Linhas de cuidado"
              labelColor="black"
              options={linhasDeCuidado}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              // defaultValue={ lineCare && lineCare.length ? linhasDeCuidado.filter(res => lineCare.map(item => res.id == item.id)) : []}
              value={lineCare}
              onChange={(event, newValue) => {
                setUpdate(true);
                // setLineCare(newValue);
                setUpdatedLineCare(newValue);
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PatientLineCare;
