import React from 'react';
import './index.scss';
import LockIcon from '@mui/icons-material/Lock';

export default function ModalHabiliteVideo(props) {
  return (
    <>
      {props.open ? (
        <div className="blur_modal_new_tab">
          <div className="modal_block_fundo">
            <div className="icone_modal_block">
              <LockIcon />
            </div>
            <div className="mt20 alinha_texto_modal_block">
              <div>Habilite a videoconferência</div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
