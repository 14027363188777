import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import DayReagenda from './DayReagenda';

const useStyles = makeStyles((theme) => ({
  baseCalendar: {
    flexDirection: 'column',
    display: 'flex',
  },
  gridCalendar: {
    flex: '1 1 0%',
    display: 'grid',
  },
  itensCalendar: {
    display: 'grid',
    gridTemplateRows: 'repeat(5, minmax(0,1fr))',
    gridTemplateColumns: 'repeat(7,minmax(0,1fr))',
    flex: '1 1 0%',
    columnGap: '10px',
    rowGap: '10px',
    padding: 32,
  },
}));

export default function MonthReagenda({ month, horariosAgenda }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const parseDateTime = (str) =>
    str.slice(0, 10).split('-').reverse().join('/');

  const handleOpen = () => setOpen(true);

  return (
    <div className={classes.baseCalendar}>
      <div className={classes.gridCalendar}>
        <div className={classes.itensCalendar}>
          {month.map((row, i) => (
            <>
              {row.map((day, idx) => {
                var horariosDoDia = [];

                if (
                  Array.isArray(horariosAgenda) &&
                  horariosAgenda.find(
                    (x) =>
                      parseDateTime(x.data_time) === day.format('DD/MM/YYYY'),
                  )
                ) {
                  horariosDoDia = horariosAgenda.filter(
                    (x) =>
                      parseDateTime(x.data_time) === day.format('DD/MM/YYYY'),
                  );
                }

                return (
                  <DayReagenda
                    day={day}
                    key={idx}
                    horariosAgenda={horariosAgenda}
                    horariosDoDia={horariosDoDia}
                  />
                );
              })}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
