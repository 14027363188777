import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
  },
  horizontal: {
    display: 'inline',
  },
  vertical: {
    display: 'flex',
  },
}));

const EBCheckbox = ({
  id,
  label,
  name,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleChange, handleInputSubmit } = useContext(FormBuilderContext);
  // const [answer, setAnswer] = useState([]);
  const classes = useStyles();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    let optionsState = {};

    additional_data.construct.options.map(function (option, index) {
      optionsState = { ...optionsState, [option.value]: false };
    });
    if (answer && answer.length > 0) {
      Object.entries(answer[0]).map(function (res) {
        optionsState[res[0]] = res[1];
      });
    }
    setOptions(optionsState);
  }, []);

  const handleInputChange = (e) => {
    const old_input = { ...options, [e.target.value]: e.target.checked };
    setOptions(old_input);

    handleInputSubmit({ [id]: old_input });
  };

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      {field_category_id === 2 && (
        <div>
          {(additional_data.type === 'list' ||
            additional_data.type === 'text') && (
            <div className="mb-3">
              <Typography
                variant="body1"
                className="text-bold text-primary"
                style={{ fontSize: '1em', display: 'flex' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${additional_data.construct.showTitle ? label : ''}`,
                  }}
                />
                <span
                  style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}
                >
                  {is_required === 1 ? '*' : ''}
                </span>
              </Typography>
              {additional_data.options.length > 0 && options
                ? additional_data.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      className={`${additional_data.construct.inline ? classes.horizontal : classes.vertical} `}
                      control={
                        <Checkbox
                          id={id}
                          // defaultChecked={""}
                          checked={options[option.value]}
                          color="primary"
                          disabled={readOnly}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                          onChange={handleInputChange}
                          value={option.value}
                          name={option.label}
                        />
                      }
                      label={option.label}
                    />
                  ))
                : 'Não existe dados cadastrados'}
              <br />
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {erro[0] ? erro[0].value : ''}
              </span>
              <span style={{ fontSize: 12 }} className="text-light">
                {helper_text ? helper_text : ''}
              </span>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default EBCheckbox;
