import axios from 'axios';
import env from 'react-dotenv';

class Configuration {
  async get(token) {
    try {
      const { data } = await axios.get(
        `${env.API_URL}/configuration?token=${token}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getCredentialIpe(token, dados) {
    return axios
      .post(
        `${env.API_URL}/configuration/credential-ipe`,
        JSON.stringify(dados),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
            'x-auth-token': token,
          },
        },
      )
      .then((res) => res.data)
      .catch((error) => error.response);
  }

  async putEnableOrDisableConfig(token, id, body) {
    try {
      const { data } = await axios.put(
        `${env.API_URL}/configuration/${id}?token=${token}`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-domain': env.X_DOMAIN,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new Configuration();
