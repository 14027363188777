import React, { useEffect, useState } from 'react';
import { Grid, Container, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DButton from '../../../shared/DButton';
import { Save } from '@material-ui/icons';
import Error from './../../../../error/Error';
import { Bio } from './../../../../api/biomedidas';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  paciente__topo: {
    position: 'absolute',
    left: 0,
    top: 98,
    width: '100%',
    height: 53,
    display: 'flex',
    // padding: "10px 40px",
    background: theme.palette.background.snow,
    boxShadow:
      '0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 18,
    zIndex: 5,
  },
  paciente__nome: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.neutral.black,
    fontWeight: 600,
  },
}));

function Header(props) {
  const classes = useStyles();

  const [errorConfig, setErrorConfig] = useState(false);
  const [errorCampo, setErrorCampo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.onError(errorConfig, errorCampo);
  }, [errorCampo, errorConfig]);

  const handleSubmit = () => {
    setLoading(true);

    let valid = true;

    let new_error_campo = props.campos.map((campo) => {
      return '';
    });

    props.campos.map((campo, index) => {
      try {
        if (!campo.name || !campo.type) {
          valid = false;
          throw new Error(index, 'Campo obrigatório');
        }
      } catch (err) {
        new_error_campo[err.getIndex()] = err.getMessage();
      }
    });

    setErrorCampo(new_error_campo);

    if (
      props.biomedidas.find(
        (biomedida) => biomedida.name === props.config.name,
      ) !== undefined
    ) {
      if (
        !props.biomedida ||
        (props.biomedida && props.biomedida.name !== props.config.name)
      ) {
        setErrorConfig('Nome já cadastrado no sistema');
        valid = false;
      }
    } else if (!props.config.name || props.config.name === '') {
      setErrorConfig('Campo obrigatório');
      valid = false;
    } else {
      setErrorConfig(false);
    }

    if (valid) {
      props.id ? update() : create();
    } else {
      toast.error('Campos não preenchidos corretamente');
      setLoading(false);
    }
  };

  const getFieldType = (type) => {
    switch (type) {
      case 'lista':
        return 2;
      case 'opcoes':
        return 3;
      case 'verdadeiro_falso':
        return 4;
      default:
        return 1;
    }
  };

  const handleData = () => {
    return {
      biomeasure_exam: {
        name: props.config.name,
        show_history: props.config.viewHistory ? 1 : 0,
      },
      biomeasure_exam_profile: props.config.profiles
        .filter((profile) => profile.checked)
        .map((profile) => {
          return { profile_id: profile.id };
        }),
      biomeasure_exam_field: props.campos.map((campo) => {
        return {
          id: campo.id,
          biomeasure_exam_field_type_id: getFieldType(campo.type),
          label: campo.name,
          show_label: campo.showName ? 1 : 0,
          show_measure: campo.showUnit ? 1 : 0,
          measure: campo.unit ? campo.unit : '',
          multiple: campo.multiple ? 1 : 0,
          biomeasure_exam_field_option: campo.options.map((option) => {
            return { option: option.value };
          }),
        };
      }),
    };
  };

  const create = () => {
    let body = handleData();

    if (
      body.biomeasure_exam_field.length > 0 &&
      body.biomeasure_exam_profile.length > 0
    ) {
      Bio.create(localStorage.getItem('token'), body)
        .then((res) => {
          setLoading(false);
          toast.success(`${props.config.name} foi adicionado`);
          props.back();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      if (body.biomeasure_exam_field.length === 0)
        toast.warn('Preencha pelo menos 1 campo');

      if (body.biomeasure_exam_profile.length === 0)
        toast.warn('Selecione pelo menos 1 perfil');

      setLoading(false);
    }
  };

  const update = () => {
    let body = handleData();

    if (
      body.biomeasure_exam_field.length > 0 &&
      body.biomeasure_exam_profile.length > 0
    ) {
      Bio.update(localStorage.getItem('token'), props.id, body)
        .then((res) => {
          setLoading(false);
          toast.success(`${props.config.name} foi atualizado`);
          props.back();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      if (body.biomeasure_exam_field.length === 0)
        toast.warn('Preencha pelo menos 1 campo');

      if (body.biomeasure_exam_profile.length === 0)
        toast.warn('Selecione pelo menos 1 perfil');

      setLoading(false);
    }
  };

  return (
    <div className={classes.paciente__topo}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <a
              href="#"
              className={classes.paciente__nome}
              onClick={(e) => props.back()}
            >
              <ArrowBackIosIcon color="primary" fontSize="large" />
              {props.config.name}
            </a>
          </Grid>
          <Grid item>
            <DButton
              disabled={loading}
              onClick={handleSubmit}
              variant="primary"
              icon={<Save />}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                'Salvar medição'
              )}
            </DButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Header.defaultProps = {
  id: null,
  campos: [],
  config: {
    name: '-',
    viewHistory: false,
    profiles: [],
  },
  onError: function (errorConfig, errorCampo) {
    console.log(errorConfig, errorCampo);
  },
};

export default Header;
