import { React, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card_subtitle: {
    color: theme.palette.neutral.black,
    fontSize: 14,
    margin: '0 0 16px',
  },
}));

function ViewFieldType(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="flex-end">
      {props.campos.map((campo, index) => {
        return (
          <Grid key={`view-field-type-${index}`} item>
            {campo.showName && (
              <h6 className={classes.card_subtitle}>{campo.name}</h6>
            )}
            <Typography
              style={{ fontSize: 18, color: '#272727', fontWeight: 400 }}
            >
              {props.values[index] ? props.values[index].join(' - ') : '-'}{' '}
              {campo.showUnit ? campo.unit : ''}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

ViewFieldType.defaultProps = {
  campos: [],
  values: [],
};

export default ViewFieldType;
