import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/pt-br';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import ptBR from 'date-fns/esm/locale/pt-BR';
import { mascaraData } from '../../../helpers/formatacao';

const useStyles = makeStyles((theme) => ({
  btnFilter: {
    margin: '0 auto',
    marginTop: '15px',
    borderRadius: '20px',
    width: '120px',
    display: 'block',
  },
}));

export default function SelectRangeDate({
  dateStart,
  dateEnd,
  callbackFilterSelect,
  callbackFilterClick,
}) {
  const classes = useStyles();
  const [focusedDate, setFocusedDate] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(dateStart);
  const [selectedDateEnd, setSelectedDateEnd] = useState(dateEnd);
  const [focusedIsStart, setFocusedIsStart] = useState(true);

  const [strDateStart, setStrDateStart] = useState('');
  const [strDateEnd, setStrDateEnd] = useState('');

  const handleFocusedDateChange = (date) => {
    setFocusedDate(date);
    if (focusedIsStart) {
      setSelectedDateStart(date);
      callbackFilterSelect({ dataInicial: date, dataFinal: selectedDateEnd });
    } else {
      setSelectedDateEnd(date);
      callbackFilterSelect({ dataInicial: selectedDateStart, dataFinal: date });
    }
  };

  useEffect(() => {
    moment.locale('pt-br');
  }, []);

  useEffect(() => {
    const d = moment(selectedDateStart);
    if (d.isValid()) {
      const dateLabeled = moment(selectedDateStart).format('DD/MM/YYYY');
      setStrDateStart(dateLabeled);
    }
  }, [selectedDateStart]);

  useEffect(() => {
    const d = moment(selectedDateEnd);
    if (d.isValid()) {
      const dateLabeled = moment(selectedDateEnd).format('DD/MM/YYYY');
      setStrDateEnd(dateLabeled);
    }
  }, [selectedDateEnd]);

  const formatStringDate = (date) => {
    const dia = date.split('/')[0];
    const mes = date.split('/')[1];
    const ano = date.split('/')[2];
    return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
  };

  return (
    <>
      <Grid container justifyContent="space-around">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="static"
              format="dd/MM/yyyy"
              margin="normal"
              changeMonth={true}
              id="date-focused"
              label="Data selecionada"
              value={focusedDate}
              views={['year', 'month', 'date']}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onChange={handleFocusedDateChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid container justifyContent="space-around">
        <Grid item md={5}>
          <TextField
            autoFocus
            id="date-start"
            label="Data Inicial"
            variant="outlined"
            value={strDateStart}
            onFocus={(e) => {
              setFocusedDate(selectedDateStart);
              setFocusedIsStart(true);
            }}
            onChange={(e) => {
              const localTemp = mascaraData(e.target.value);
              const str = formatStringDate(e.target.value);
              const d = moment(str);

              setStrDateStart(localTemp);

              if (e.target.value?.length != 10) {
                setSelectedDateStart(null);
                callbackFilterSelect({
                  dataInicial: null,
                  dataFinal: selectedDateEnd,
                });
                return;
              }

              if (d.isValid()) {
                setFocusedDate(d);
                setSelectedDateStart(d);
                callbackFilterSelect({
                  dataInicial: d,
                  dataFinal: selectedDateEnd,
                });
              } else {
                setSelectedDateStart(null);
                callbackFilterSelect({
                  dataInicial: null,
                  dataFinal: selectedDateEnd,
                });
              }
            }}
          />
        </Grid>
        <Grid item md={5}>
          <TextField
            id="date-end"
            label="Data Final"
            variant="outlined"
            value={strDateEnd}
            onFocus={(e) => {
              setFocusedDate(selectedDateEnd);
              setFocusedIsStart(false);
            }}
            onChange={(e) => {
              const localTemp = mascaraData(e.target.value);
              const str = formatStringDate(e.target.value);
              const d = moment(str);
              setStrDateEnd(localTemp);

              if (e.target.value?.length != 10) {
                setSelectedDateEnd(null);
                callbackFilterSelect({
                  dataInicial: selectedDateStart,
                  dataFinal: null,
                });
                return;
              }

              if (d.isValid()) {
                setFocusedDate(d);
                setSelectedDateEnd(d);
                callbackFilterSelect({
                  dataInicial: selectedDateStart,
                  dataFinal: d,
                });
              } else {
                setSelectedDateEnd(null);
                callbackFilterSelect({
                  dataInicial: selectedDateStart,
                  dataFinal: null,
                });
              }
            }}
          />
        </Grid>
      </Grid>

      <div>
        <Button
          className={classes.btnFilter}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            callbackFilterClick();
          }}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
}
