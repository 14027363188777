import env from 'react-dotenv';
import axios from 'axios';

function getTermofUse() {
  return axios
    .get(`${env.API_URL}/term-of-use`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getTermofUse };
