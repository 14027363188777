import {
  assumirAtendimento,
  abdicarAtendimento,
} from '../../api/health_attendances';

export function getPatientName(patient) {
  if (patient) {
    let pronoun = patient.pronoun ? patient.pronoun.name : '';

    return `${patient.social_name ? patient.social_name : patient.full_name} ${pronoun}`;
  } else {
    return '-';
  }
}

export async function atender(token, health_attendance_id) {
  return await assumirAtendimento(token, health_attendance_id).then((res) => {
    return res;
  });
}

export async function abdicar(token, health_attendance_id, motivo) {
  return await abdicarAtendimento(token, health_attendance_id, { motivo }).then(
    (res) => {
      return res;
    },
  );
}
