import { makeStyles } from "@material-ui/core";

export const classStyles = makeStyles((theme) => ({
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    background: theme.palette.primary.light,
    color: theme.palette.background.default,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
  },
  deleteButton: {
    color: theme.palette.error.main,
    textTransform: 'none',
    marginRight: 16,
    fontWeight: 400,
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  listItem: {
    padding: '12px 0',
    margin: 0,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  firstListItem: {
    padding: '12px 0',
    margin: 0,
  },
}));

export const styles = {
  titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
  buttonToolbar: { marginTop: 24, marginBottom: 24 },
  flex: { display: 'flex' },

  contentRemove: {
    width: 267,
    height: 65,
    padding: '0px 24px',
  },
  titleRemove: {
    borderBottom: 'none',
  },
  buttonBack: {
    minWidth: 74,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
  },
  buttonConfirm: {
    minWidth: 145,
    height: 33,
    borderRadius: 100,
    fontSize: 14,
    padding: '6px 18px',
  },
}