import React from 'react';
import '../index.scss';
import { TextField } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import TabelaPrescricao from '../../NovasTabelas/TabelaPrescricao';

export default function PrescricaoNewTab(props) {
  return (
    <div className="fundo_new_tab">
      <TabelaPrescricao />
    </div>
  );
}
