import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Error from '@material-ui/icons/Error';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
  },
  disabledSelect: {
    cursor: 'not-allowed',
    backgroundColor: '#ebebe435',
    opacity: 0.5,
  },
  menuPaper: {
    maxHeight: 160,
  },
  '& > label': {
    color: '#000',
  },
  blueSelectIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.light,
    },
  },
  primaryColorSelectIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  removeDropDownMargin: {
    marginTop: '-11px',
  },
}));

function DDropDownSimple(props) {
  const classes = useStyles();

  // const [value, setValue] = useState(props.value);

  function handleChange(event) {
    // setValue(event.target.value);
    props.onChange(event.target.value, event);
  }

  return (
    <FormControl
      className={`${classes.root} ${props.error ? 'form-control-error' : ''} ${
        props.disabled ? `${classes.disabledSelect}` : ''
      } ${props.primaryColorSelectIcon ? classes.primaryColorSelectIcon : null} ${
        props.removeDropDownMargin ? classes.removeDropDownMargin : null
      }`}
    >
      {!props.notLabel && (
        <InputLabel
          className={`${
            props.value &&
            props.value !== '' &&
            props.value !== undefined &&
            props.value !== null
              ? 'MuiInputLabel-shrink'
              : ''
          }`}
          id={`${props.id}-label`}
        >
          {props.labelColor ? (
            <span style={{ color: props.labelColor }}>{props.label}</span>
          ) : (
            props.label
          )}{' '}
          {props.required === true && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <Select
        error={props.error}
        labelId={`${props.label}-${props.id}`}
        disabled={props.disabled}
        id={props.id}
        value={props.value}
        IconComponent={() =>
          props.error ? <Error /> : <ExpandMore color="red" />
        }
        onChange={handleChange}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem key={`${props.id}-menu-item-selecione`} value="">
          Selecione
        </MenuItem>
        {props?.options?.map(function (item, i) {
          return (
            <MenuItem
              SelectProps={props.SelectProps}
              key={`menu-item-${props.id}-${i}`}
              value={item.id}
            >
              {item.value}
            </MenuItem>
          );
        })}
      </Select>
      {props.helperText ? (
        <FormHelperText>{props.helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default DDropDownSimple;
