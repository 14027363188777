import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, makeStyles } from '@material-ui/core';
import './index.scss';
import { useState } from 'react';
import {
  listHealthAttendanceFiles,
  listHealthAttendanceFilesTypePatient,
} from '../../../../api/healthAttendance';
import { getExamsByCPF } from '../../../../api/above/ExameByPatient';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { DownloadIcon } from '../../Icon/Icon';
import ModalResultado from '../../../shared/NewTabs/VisualizadorClinNewTab/Components/ModalResultado';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaExamesEnviados: {
    color: `${theme.palette.primary.light} !important`,
  },
}));
function createData(dia, unidade, pedido, solicitante, resultado) {
  return { dia, unidade, pedido, solicitante, resultado };
}

export default function CustomizedTables() {
  const { patient } = useNewHealthAttendance();
  const [exames, setExames] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (patient && patient.cpf) {
      getExamsByCPF(
        localStorage.getItem('token'),
        patient.cpf.replace(/[^0-9]/g, ''),
      )
        .then((data) => {
          if (data.status) {
            setExames(data.details.VETOR_PEDIDO_EXAME);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [patient]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Data</StyledTableCell>
              <StyledTableCell align="left">Unidade</StyledTableCell>
              <StyledTableCell align="left">Pedido</StyledTableCell>
              <StyledTableCell align="left">Solicitante</StyledTableCell>
              <StyledTableCell align="center">Resultado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exames.map((row) => (
              <StyledTableRow key={row.CD_PEDIDO}>
                <StyledTableCell component="th" scope="row">
                  {row.DH_PEDIDO_FORMATED
                    ? row.DH_PEDIDO_FORMATED
                    : row.DH_PEDIDO}
                </StyledTableCell>
                <StyledTableCell align="left">{row.NM_ORIGEM}</StyledTableCell>
                <StyledTableCell align="left">{row.CD_PEDIDO}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.NM_SOLICITANTE}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    disabled
                    className={classes.iconResultadoTabelaExamesEnviados}
                    startIcon={<LaunchIcon />}
                  >
                    {' '}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
