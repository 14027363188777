// React import
import React from 'react';
import { Component } from 'react';

// Material styles and icons import
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DButton from '../shared/DButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

// Shared components import
import DInput from '../shared/DInput';

// Sidebar(right) import
import { Rightbar } from './component/Rightbar';

// API routes import
import Linecares from '../../api/lineCare';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
  titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
  buttonToolbar: { marginTop: 24, marginBottom: 24 },
  flex: { display: 'flex' },
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: 'none',
  },
  addButton: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  disabledTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    textTransform: 'none',
  },
  listItem: {
    padding: '16px 0',
    margin: 0,
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
  },
  inactiveListButton: {
    color: theme.palette.support.error,
    borderColor: theme.palette.support.error,
    marginRight: 18,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0px 18px',
  },
  addButton: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '0 18px 0',
  },
  firstListItem: {
    padding: '16px 0',
    margin: 0,
  },
  deleteButton: {
    color: theme.palette.support.error,
    textTransform: 'none',
    fontWeight: 400,
  },
  activeButton: {
    color: 'green',
    textTransform: 'none',
    fontWeight: 400,
  },
  editButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  saveButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 400,
  },
  item_text: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
});

class Linhas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMedicalUnits: true,
      Linecares: [],
      novaUnidade: false,
      creatingNewMedicalUnit: false,
      createdMedicalUnit: false,
      createMedicalUnitError: false,
      reloadCreateMedicalUnit: false,
      error: [],
      showRightbar: false,
      delete: false,
      rowSelected: '',
      indexSelected: '',
    };
  }

  componentDidMount() {
    this.listLinecares();
  }

  listLinecares() {
    Linecares.listLineCare(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        var _lineCares = res.lineCare.data;

        _lineCares.map((row) => {
          return {
            id: row.id,
            name: row.name,
            active: row.active === 1 ? true : false,
            isEdit: false,
            isLoading: false,
          };
        });

        this.setState({ ...this.state, Linecares: _lineCares });

        // res.lineCare.data.map((row) => {
        //     var new_state = [];

        //     new_state.push({id: row.id, name: row.name, active: (row.active == 1 ? true : false), isEdit: false, isLoading: false});

        //     this.setState({ ...this.state, Linecares: new_state })

        //     this.setState({ ...this.state, Linecares: new_state })
        // })
      } else {
        this.setState({ ...this.state, Linecares: [] });
      }
    });
  }

  saveLinecare(Linecare, index) {
    if (Linecare.name.trim() == '') {
      var new_state = this.state.error;
      new_state.push(index);
      this.setState({ ...this.state, error: new_state });
    } else {
      var new_state = this.state.Linecares;
      new_state[index].isLoading = !Linecare.isLoading;
      this.setState({ ...this.state, Linecares: new_state });

      var dados_post = {
        name: Linecare.name,
        active: Linecare.active,
      };

      if (Linecare.id) {
        Linecares.putLineCareById(
          dados_post,
          Linecare.id,
          localStorage.getItem('token'),
        )
          .then((res) => {
            if (res.status) {
              new_state[index].isLoading = !Linecare.isLoading;
              new_state[index].isEdit = !Linecare.isEdit;
              this.setState({ ...this.state, Linecares: new_state });
              toast.success('Registro atualizado com sucesso!');
              this.listLinecares();
            } else {
              new_state[index].isLoading = !Linecare.isLoading;
              this.setState({ ...this.state, Linecares: new_state });
            }
          })
          .catch((err) => {
            var new_error = this.state.error;
            new_error.push({
              index: index,
              erro: `${Linecare.name} já cadastrado no sistema`,
            });
            this.setState({ ...this.state, error: new_error });
            toast.error(`${Linecare.name} já cadastrado no sistema`);
            this.controlaMensagemErro(Linecare, index, new_state);
          });
      } else {
        Linecares.createLineCare(dados_post, localStorage.getItem('token'))
          .then((res) => {
            if (res.status) {
              new_state[index].id = res.lineCare.id;
              new_state[index].isLoading = !Linecare.isLoading;
              new_state[index].isEdit = !Linecare.isEdit;
              this.setState({ ...this.state, Linecares: new_state });

              toast.success('Registro inserido com sucesso!');
              this.listLinecares();
            } else {
              if (res?.status_code != 401)
                toast.error(
                  res.details
                    ? res.details
                    : 'Erro ao realizar ação, não foi possível salvar.',
                );

              new_state[index].isLoading = !Linecare.isLoading;
              this.setState({ ...this.state, Linecares: new_state });
            }
          })
          .catch((err) => {
            var new_error = this.state.error;
            new_error.push({
              index: index,
              erro: `${Linecare.name} já cadastrado no sistema`,
            });
            this.setState({ ...this.state, error: new_error });
            toast.error(`${Linecare.name} já cadastrado no sistema`);
            this.controlaMensagemErro(Linecare, index, new_state);
          });
      }
    }
  }

  controlaMensagemErro(Linecare, index, new_state) {
    new_state[index].isLoading = !Linecare.isLoading;
    this.setState({ ...this.state, Linecares: new_state });
  }

  confirmDelete(Linecare, index) {
    var new_state = this.state.Linecares;
    new_state[index].isLoading = !Linecare.isLoading;
    this.setState({ ...this.state, Linecares: new_state });

    Linecares.deleteLineCare(Linecare.id, localStorage.getItem('token'))
      .then((res) => {
        if (res.status) {
          new_state.splice(index, 1);
          this.setState({ ...this.state, Linecares: new_state });
          toast.success('Registro removido com sucesso!');
        } else {
          new_state[index].isLoading = !Linecare.isLoading;
          this.setState({ ...this.state, Linecares: new_state });
          toast.error('Erro: não foi possível remover o registro.');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro: não foi possível remover o registro.');
        this.controlaMensagemErro(Linecare, index, new_state);
      });
  }

  enableOrDisableLinecare(Linecare, index) {
    var new_state = this.state.Linecares;
    new_state[index].isLoading = !Linecare.isLoading;
    this.setState({ ...this.state, Linecares: new_state });

    var dados_post = {
      name: Linecare.name,
      active: !Linecare.active,
    };

    console.log('LINECARE');
    console.log(Linecare.id);

    var _promise = Linecares.putLineCareById(
      dados_post,
      Linecare.id,
      localStorage.getItem('token'),
    )
      .then((res) => {
        if (res.status) {
          new_state[index].isLoading = !Linecare.isLoading;
          new_state[index].active = !Linecare.active;
          this.setState({ ...this.state, Linecares: new_state });
        } else {
          new_state[index].isLoading = !Linecare.isLoading;
          this.setState({ ...this.state, Linecares: new_state });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    toast.promise(_promise, {
      error: 'Erro ao desativar linha de cuidado',
      success: `Linha de cuidado ${!Linecare.active ? 'desativada' : 'ativada'} com sucesso`,
      pending: `${!Linecare.active ? 'Desativando' : 'Ativando'} linha de cuidado...`,
    });
  }

  hideRightBar() {
    this.setState({ ...this.state, showRightbar: false });
  }

  modalConfirmDelete() {
    const styles = {
      contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px',
      },
      titleRemove: {
        borderBottom: 'none',
      },
      buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
      buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px',
      },
    };
    return (
      <Dialog
        aria-labelledby="conditional-remove-title"
        scroll="body"
        onClose={(e) => {
          this.setState({ ...this.state, delete: false });
        }}
        open={true}
      >
        <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={10}>
              <Typography variant="body1" className="text-regular text-st">
                Se seguir com essa ação todos os dados serão perdidos, deseja
                confirmar essa ação?
              </Typography>
            </Grid>
            <Grid item>
              <Close
                onClick={(e) => {
                  this.setState({ ...this.state, delete: false });
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={styles.contentRemove}>
          <Grid
            style={{ height: 50 }}
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonBack}
                variant="secondary"
                size="none"
                className="btn-error"
                onClick={(e) => this.setState({ ...this.state, delete: false })}
              >
                Voltar
              </DButton>
            </Grid>
            <Grid item>
              <DButton
                type="button"
                style={styles.buttonConfirm}
                variant="primary"
                size="none"
                className="btn-error"
                onClick={(e) => {
                  this.confirmDelete(
                    this.state.rowSelected,
                    this.state.indexSelected,
                  );
                  this.setState({ ...this.state, delete: false });
                }}
              >
                Confirmar Exclusão
              </DButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const styles = {
      titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14 },
      buttonToolbar: { marginTop: 24, marginBottom: 24 },
      flex: { display: 'flex' },
      dark_background: {
        width: '100%',
        height: '100vh',
        background: 'rgba(39,39,39,0.25)',
        position: 'absolute',
        top: 98,
        zIndex: 2010,
      },
      item_text: {
        fontSize: 14,
      },
      cadastrarButton: {},
    };
    const { classes } = this.props;
    return (
      <>
        <Container className="tema-light">
          <Typography
            style={styles.titleTop}
            variant="h3"
            className="text-primary"
          >
            Atendimento
          </Typography>
          <Card style={{ background: '#f9f9f9' }}>
            <CardContent>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant="h4">Linhas de cuidado</Typography>
                </Grid>
                <Grid item md={9} style={styles.flex} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    className={classes.inactiveListButton}
                    size="sm"
                    onClick={() =>
                      this.setState({ ...this.state, showRightbar: true })
                    }
                  >
                    <span style={{ marginTop: 1, display: 'block' }}>
                      Inativas
                    </span>
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.addButton}
                    size="sm"
                    startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                    disabled={this.state.Linecares.find((x) => x.isEdit)}
                    onClick={(e) => {
                      var new_linecare = this.state.Linecares;
                      new_linecare.unshift({
                        id: null,
                        name: '',
                        active: true,
                        isEdit: true,
                        isLoading: false,
                      });
                      this.setState({ ...this.state, Linecares: new_linecare });
                    }}
                  >
                    <span style={{ marginTop: 2, display: 'block' }}>
                      Cadastrar linha de cuidado
                    </span>
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item md={12}>
                  <List style={{ padding: 0 }}>
                    {this.state.Linecares.length
                      ? this.state.Linecares.map((row, index) => (
                          <>
                            {row.isEdit ? (
                              <ListItem
                                className={
                                  index == 0
                                    ? classes.firstListItem
                                    : classes.listItem
                                }
                              >
                                <Grid item md={6}>
                                  <DInput
                                    id="name"
                                    label="Nome da linha de cuidado"
                                    placeholder="Digite o nome da linha de cuidado"
                                    value={row.name}
                                    labelColor="#272727"
                                    defaultValue={row.name}
                                    inputProps={{ maxLength: 50 }}
                                    error={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.error.find(
                                        (error) => error.index == index,
                                      ) != undefined
                                        ? this.state.error.find(
                                            (error) => error.index == index,
                                          ).erro
                                        : ''
                                    }
                                    type="text"
                                    fullWidth={true}
                                    positionIcon="end"
                                    onChange={(e) => {
                                      var new_value = this.state.Linecares;
                                      new_value[index].name =
                                        e.target.value.replace(
                                          /[^a-zA-Zà-úÀ-Ú"'~´` ]/g,
                                          '',
                                        );

                                      this.setState({
                                        ...this.state,
                                        Linecares: new_value,
                                        error: [],
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <ListItemSecondaryAction style={{ right: 0 }}>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <DeleteIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.deleteButton}
                                      onClick={(e) => {
                                        var new_value = this.state.Linecares;
                                        new_value[index].isEdit = false;
                                        if (new_value[index].id === null) {
                                          new_value.splice(index, 1);
                                        }

                                        this.setState({
                                          ...this.state,
                                          Linecares: new_value,
                                          error: [],
                                        });

                                        this.listLinecares();
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      size="sm"
                                      startIcon={
                                        <SaveIcon style={{ fontSize: 20 }} />
                                      }
                                      className={classes.saveButton}
                                      onClick={(e) => {
                                        var new_value = this.state.Linecares;

                                        if (
                                          !new_value[index].name ||
                                          !new_value[index].name.trim()
                                        ) {
                                          var new_error = this.state.error;
                                          new_error.push({
                                            index: index,
                                            erro: 'O nome da linha de cuidado é obrigatório',
                                          });
                                          this.setState({
                                            ...this.state,
                                            error: new_error,
                                          });
                                        } else if (!row.isLoading) {
                                          this.saveLinecare(row, index);
                                        }

                                        // if (!row.isLoading) {
                                        //     this.saveLinecare(row, index)
                                        // }
                                      }}
                                    >
                                      {row.isLoading ? (
                                        <CircularProgress
                                          size={15}
                                          color="inherit"
                                        />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </ListItemSecondaryAction>
                                </Grid>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={
                                  index == 0
                                    ? classes.firstListItem
                                    : classes.listItem
                                }
                              >
                                <ListItemText
                                  primary={row.name}
                                  disableTypography={true}
                                  style={styles.item_text}
                                />
                                <ListItemSecondaryAction style={{ right: 0 }}>
                                  <IconButton
                                    size="sm"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        this.setState({
                                          ...this.state,
                                          delete: true,
                                          rowSelected: row,
                                          indexSelected: index,
                                        });
                                      }
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    className={
                                      row.active
                                        ? classes.deleteButton
                                        : classes.activeButton
                                    }
                                    onClick={(e) => {
                                      if (!row.isLoading) {
                                        this.enableOrDisableLinecare(
                                          row,
                                          index,
                                        );
                                      }
                                    }}
                                  >
                                    {row.active ? (
                                      <NotInterestedIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    ) : (
                                      <CheckCircleOutlineIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    className={classes.editButton}
                                    onClick={(e) => {
                                      var new_state = this.state.Linecares;
                                      new_state[index].isEdit = !row.isEdit;
                                      this.setState({
                                        ...this.state,
                                        Linecares: new_state,
                                      });
                                    }}
                                  >
                                    <EditIcon style={{ fontSize: 20 }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </>
                        ))
                      : 'Não existem sintomas cadastrados'}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>

        {this.state.showRightbar ? (
          <div style={styles.dark_background}></div>
        ) : (
          ''
        )}
        {this.state.delete ? this.modalConfirmDelete() : ''}
        <Rightbar
          show={this.state.showRightbar}
          hide={() => this.hideRightBar()}
          linecares={this.state.Linecares}
          enableOrDisableLinecare={(e) =>
            this.enableOrDisableLinecare(e.linecare, e.index)
          }
          style={styles.rightbar}
        />
      </>
    );
  }
}

export default withStyles(styles)(Linhas);
