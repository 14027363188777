import axios from 'axios';
import env from 'react-dotenv';

async function getAllProfiles(token) {
  return await axios
    .post(
      `${env.API_URL}/profile/list`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllProfilesNotPaginate(token) {
  return axios
    .get(`${env.API_URL}/profile`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

async function getAllRoleGroups(token) {
  return await axios
    .get(`${env.API_URL}/role/list-role-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getPermissionsFromRoleGroupId(token, roleGroupId) {
  return axios
    .get(`${env.API_URL}/role/list-permissions/${roleGroupId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllPermissions(token) {
  return axios
    .get(`${env.API_URL}/role/list`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getAllPermissionsByProfile(token, profileId) {
  return axios
    .get(`${env.API_URL}/role/list/profile/${profileId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getProfileById(token, id) {
  return axios
    .get(`${env.API_URL}/profile/${id}/show`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAINgetAllPermissions,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getProfilePermissionsById(token, id) {
  return axios
    .get(`${env.API_URL}/profile/${id}/permission/list`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getProfilePermissionsRoleById(token, id, role_id) {
  return axios
    .get(`${env.API_URL}/profile/${id}/permission/list?role_id=${role_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createProfile(token, dados) {
  return axios
    .post(`${env.API_URL}/profile`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateProfile(token, dados) {
  return axios
    .put(`${env.API_URL}/profile/${dados.id}/update`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteProfile(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getProfilePermission(token, id) {
  return axios
    .get(`${env.API_URL}/profile/${id}/list-permission`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableProfilePermissionCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/profile/${id}/call-enabled`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableProfilePermissionCall(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}/call-enabled`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableProfilePermissionSequentialCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/profile/${id}/sequential-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableProfilePermissionSequentialCall(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}/sequential-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableProfilePermissionGeneralGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/profile/${id}/call-in-general-group`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableProfilePermissionGeneralGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}/call-in-general-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableProfilePermissionTotalGroup(token, id) {
  return axios
    .put(
      `${env.API_URL}/profile/${id}/call-in-total-group`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableProfilePermissionTotalGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}/call-in-total-group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function enableProfilePermissionDirectCall(token, id) {
  return axios
    .put(
      `${env.API_URL}/profile/${id}/direct-call`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function disableProfilePermissionDirectCall(token, id) {
  return axios
    .delete(`${env.API_URL}/profile/${id}/direct-call`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  createProfile, deleteProfile, disableProfilePermissionCall, disableProfilePermissionDirectCall, disableProfilePermissionGeneralGroup, disableProfilePermissionSequentialCall, disableProfilePermissionTotalGroup, enableProfilePermissionCall, enableProfilePermissionDirectCall, enableProfilePermissionGeneralGroup, enableProfilePermissionSequentialCall, enableProfilePermissionTotalGroup, getAllPermissions, getAllPermissionsByProfile, getAllProfiles, getAllProfilesNotPaginate, getAllRoleGroups,
  getPermissionsFromRoleGroupId,
  getProfileById, getProfilePermission, getProfilePermissionsById,
  getProfilePermissionsRoleById, updateProfile
};

