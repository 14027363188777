import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function getOnlyNumbers(str) {
  return str.replace(/\D/g, '');
}

export function dateRegex(value) {
  if (value || value === '') {
    try {
      value = value.replace(/[^0-9]/g, '');
      value = value.substr(0, 11);
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '$1/$2');
      value = value.replace(/(\d{2})(\d)/, '$1/$2');
      value = value.replace(/(\d{4})(\d)/, '$1');
      return value;
    } catch (err) {
      console.log(err);
      return value;
    }
  }
}

const mascaraData = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
};

export function mascaraRNE(value) {
  try {
    if (typeof value === 'string') {
      value = value.replace(/[^0-9A-Za-z]/g, '');

      value = value.slice(0, 8);

      if (value.length <= 7) {
        value = value.substr(0, 7);
      } else if (value.length === 8) {
        value = value.substr(0, 7) + '-' + value.substr(7, 8);
      }

      return value;
    }
  } catch (err) {
    console.log(err);
    return value;
  }
}

function getCPF(value) {
  try {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } catch (err) {
    console.log(err);
    return value;
  }
}

function setCPF(value) {
  try {
    return value.toString().replace(/[^0-9]/g, '');
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraPassaporte(value) {
  try {
    var passaporte = value.substr(0, 14);
    passaporte = passaporte.replace(/\D/g, '');
    passaporte = passaporte.replace(/(\d{3})(\d)/, '$1.$2');
    passaporte = passaporte.replace(/(\d{3})(\d)/, '$1.$2');
    passaporte = passaporte.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return passaporte;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraCPF(value) {
  try {
    var cpf = value.substr(0, 14);
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraCNES(value) {
  try {
    var cnes = value.substr(0, 14);
    cnes = cnes.replace(/\D/g, '');
    cnes = cnes.replace(/(\d{3})(\d)/, '$1.$2');
    cnes = cnes.replace(/(\d{3})(\d)/, '$1.$2');
    cnes = cnes.replace(/(\d{3})(\d{1,2})$/, '$1.$2');
    return cnes;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraCNPJ(value) {
  try {
    var cnpj = value.substr(0, 18);
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    return cnpj;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraRG(value) {
  try {
    var rg = value.substr(0, 12);
    rg = rg.replace(/\D/g, '');
    rg = rg.replace(/(\d{2})(\d)/, '$1.$2');
    rg = rg.replace(/(\d{3})(\d)/, '$1.$2');
    rg = rg.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return rg;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraCEP(value) {
  if (value != null) {
    try {
      var cep = value.substr(0, 10);
      cep = cep.replace(/\D/g, '');
      cep = cep.replace(/(\d{2})(\d)/, '$1.$2');
      cep = cep.replace(/(\d{3})(\d)/, '$1-$2');
      return cep;
    } catch (err) {
      console.error(err);
      return value;
    }
  }
}

function mascaraPIS(value) {
  try {
    var pis = value.substr(0, 14);
    pis = pis.replace(/\D/g, '');
    pis = pis.replace(/^(\d{3})(\d)/, '$1.$2');
    pis = pis.replace(/^(\d{3})\.(\d{5})(\d)/, '$1.$2.$3');
    pis = pis.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, '$1.$2.$3-$4');
    return pis;
  } catch (err) {
    console.error(err);
    return value;
  }
}

function mascaraNumero(value) {
  try {
    var numero = value.substr(0, 15);

    numero = numero.replace(/\D/g, '');
    numero = numero.replace(/^(\d{2})(\d)/g, '($1) $2');
    numero = numero.replace(/(\d)(\d{4})$/, '$1-$2');

    return numero;
  } catch (err) {
    console.error(err);
    return value;
  }
}

function retira_acentos(str, uppercase = true) {
  if (typeof str !== 'string') return null;

  var com_acento =
    'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';

  var sem_acento =
    'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';

  var novastr = '';

  for (var i = 0; i < str.length; i++) {
    var troca = false;
    for (var a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) == com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1);
        troca = true;
        break;
      }
    }
    if (troca == false) {
      novastr += str.substr(i, 1);
    }
  }

  if (uppercase) return novastr.toUpperCase();
  else if (!uppercase) return novastr.toLowerCase();
  else return novastr;
}

function strCapitalize(str) {
  let new_array = [];

  for (let i = 0; i < str.length; i++) {
    let subst = str[i].value.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
    new_array.push({
      id: str[i].id,
      value: subst,
    });
  }

  return new_array;
}
/**
 * @description Retorna por padrão a data no formato dd/MM/yyyy H:mm:ss
 * @param {Date | string} date
 * @param {string} returnType
 */
// Formats doc: https://date-fns.org/v2.26.0/docs/format
function getFormatDate(date, returnType = 'dd/MM/yyyy H:mm:ss') {
  // retorna por padrão a data no formato dd/MM/yyyy H:mm:ss

  try {
    // Prevenção de "Invalid date"
    if (typeof date === 'string') {
      try {
        date = new Date(date.replace('T', ' ').replace('.000000Z', ''));
      } catch (err) {
        date = new Date(date);
      }
      return format(new Date(date), returnType, { locale: ptBR });
    } else if (typeof date === 'object') {
      return format(date, returnType);
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

function getAgeByDate(date) {
  try {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    console.error(error);
    return '';
  }
}

function setCookie(cname, cvalue, minutes) {
  var d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + '; ' + expires;
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function nameRegex(str) {
  var reg = /[a-zA-Z\u00C0-\u00FF ]+/i;

  if (str && typeof str === 'string') {
    if (
      str.charAt(str.length - 1) === "'" ||
      str.charAt(str.length - 1) === '"'
    ) {
      return true;
    } else {
      return str.charAt(str.length - 1).match(reg) ? true : false;
    }
  }
}

function mascaraAltura(value) {
  try {
    var v = value.substr(0, 12);

    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, '$1');
    return v;
  } catch (err) {
    // console.log(err);
    return value;
  }
}

function mascaraPeso(value) {
  try {
    var v = value,
      integer = v.split(',')[0];
    v = v.replace(/\D/g, '');

    v = v.replace(/^[0]+/, '');

    if (v.length <= 3 || !integer) {
      if (v.length === 1) v = '0,0' + v;

      if (v.length === 2) v = '0,' + v;

      if (v.length === 3) v = v;
    } else {
      v = v.replace(/^(\d{1,})(\d{2})$/, '$1,$2');
    }
    return v;
  } catch (err) {
    console.log(err);
    return value;
  }
}

function mascaraTelefone(v) {
  var r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1');
  }
  console.log(r);
  return r;
}

function convertToYear(value, type) {
  if (type == 'day') {
    value = value / 365;
  } else if (type == 'month') {
    value = value / 12;
  }

  return Number(value);
}

export const getNumbers = (value) => value.toString().replace(/[^0-9]/, '');

export {
  getCPF,
  setCPF,
  mascaraRG,
  mascaraCPF,
  mascaraCNES,
  mascaraCEP,
  mascaraPIS,
  mascaraNumero,
  mascaraCNPJ,
  retira_acentos,
  getFormatDate,
  getAgeByDate,
  setCookie,
  getCookie,
  nameRegex,
  mascaraAltura,
  mascaraPeso,
  mascaraTelefone,
  strCapitalize,
  mascaraData,
  convertToYear,
};
