import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Day from './Day';
import { makeStyles } from '@material-ui/core/styles';
import ModalHorarioBloqueio from './Modais/ModalHorarioBloqueio';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  baseCalendar: {
    flexDirection: 'column',
    display: 'flex',
  },
  gridCalendar: {
    flex: '1 1 0%',
    display: 'grid',
  },
  itensCalendar: {
    display: 'grid',
    gridTemplateRows: 'repeat(5, minmax(0,1fr))',
    gridTemplateColumns: 'repeat(7,minmax(0,1fr))',
    flex: '1 1 0%',
    columnGap: '10px',
    rowGap: '10px',
    padding: 32,
  },
}));

export default function Month({ month, handleClickDay, horariosAgenda }) {
  const classes = useStyles();

  return (
    <div className={classes.baseCalendar}>
      <div className={classes.gridCalendar}>
        <div className={classes.itensCalendar}>
          {month.map((row, i) => (
            <>
              {row.map((day, idx) => (
                <Day
                  day={day}
                  key={idx}
                  handleClickDay={handleClickDay}
                  horariosAgenda={horariosAgenda}
                />
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
