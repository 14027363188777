import React from 'react';
import './index.scss';
import { Work, ChatBubbleOutline, AccessTime } from '@material-ui/icons';

export default function NewCards(props) {
  return (
    <div
      className="main_div_box_new_card"
      onClick={() => props.handleCard(props.valor)}
    >
      <div className={props.active ? 'box_new_cards_active' : 'box_new_cards'}>
        <div className="title_box_card_new">{props.title}</div>
        <div className="subtitle_card_new">
          <Work className="incon_new_cards"></Work>
          {props.subtitle_1}
        </div>
        <div className="subtitle_card_new">
          <ChatBubbleOutline className="incon_new_cards"></ChatBubbleOutline>
          {props.subtitle_2}
        </div>
        <div className="subtitle_card_new">
          <AccessTime className="incon_new_cards"></AccessTime>
          {props.subtitle_3}
        </div>
      </div>
    </div>
  );
}
