import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { getFormatDate } from '../../../../../../../helpers/formatacao';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 8,
    padding: 16,
    margin: 8,
  },
  card__title: {
    margin: 0,
    fontSize: 16,
    color: theme.palette.neutral.black,
  },
  card__note: {
    fontSize: 12,
    color: theme.palette.neutral.black,
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
  },
  card__status: {
    fontSize: 14,
    color: theme.palette.neutral.black,
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
  },
  card__button: {
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 20,
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    '&:hover': {
      boxShadow: 'none',
      background: theme.palette.primary.main,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingBottom: 12,
    marginBottom: 8,
  },
  primaryColor: {
    color: theme.palette.primary.light,
  },
}));

function Card(props) {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <div>
          <h4 className={classes.card__title}>
            Atendimento #{props.identificador}
          </h4>
          <Typography className={classes.card__note}>
            <AccessTimeIcon fontSize="small" />
            {getFormatDate(new Date(props.attendance.created_at), 'dd/MM/yyyy')}
          </Typography>
        </div>
        <Button
          classes={{ contained: classes.card__button }}
          variant="contained"
          size="sm"
          onClick={(e) => props.openAttendance(props.attendance)}
        >
          Entrar
        </Button>
      </div>
      <div>
        <div>
          <Typography className={classes.primaryColor}>Status</Typography>
          <Typography className={[classes.card__status, classes.primaryColor]}>
            <InfoOutlinedIcon style={{ marginRight: 8 }} />{' '}
            {props.attendance &&
              props.attendance.health_attendance_status &&
              props.attendance.health_attendance_status.description}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Card;
