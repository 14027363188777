import env from 'react-dotenv';
import axios from 'axios';

function getAllContacts(token) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/contact/all?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function getContactById(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .get(`${api_url}/faq/contact/${id}?token=${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createContact(token, dados) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .post(`${api_url}/faq/contact?token=${token}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateContact(token, dados, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .put(`${api_url}/faq/contact/${id}?token=${token}`, JSON.stringify(dados), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteContact(token, id) {
  var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  return axios
    .delete(`${api_url}/faq/contact/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token,
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export {
  getAllContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
};
