import { Chip, Grid, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import generoCode from '../../../../../../../../assets/dados/genero.json';
import logradourosCode from '../../../../../../../../assets/dados/logradouro.json';
import orientacoesSexuaisCode from '../../../../../../../../assets/dados/orientacoesSexuais.json';
import populacaoEspecCode from '../../../../../../../../assets/dados/populacaoEspec.json';
import racasCode from '../../../../../../../../assets/dados/racas.json';
import sexoCode from '../../../../../../../../assets/dados/sexo.json';
import { usePatient } from '../../../../../../../../context/PatientContext';
import { mascaraCEP } from '../../../../../../../../helpers/formatacao';
import { IUserDominios } from '../../../../../../../../types/userDominios';
import { IPhone } from '../EditDetails/Phones';
import { BrazilianIdentity } from './BrazilianIdentity';
import { Cns } from './Cns';
import { ForeignIdentity } from './ForeignIdentity';
import { NaturalizedIdentity } from './NaturalizedIdentity';
import { useStyles } from './styles';

const nacionalidades = [
  { id: 1, value: 'Brasileiro' },
  { id: 2, value: 'Naturalizado' },
  { id: 3, value: 'Estrangeiro' },
];

export interface IPatientData {
  id: number;
  email: string;
  full_name: string;
  social_name: string;
  nationality: string;
  age: number;
  biological_sex: string;
  biological_sex_identifies: number;
  birth_date: string;
  birth_year: number | null;
  breed: Array<{
    id: number;
    code: string;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  breed_id: number;
  city_birth: number;
  cns_principal: string;
  cns_secundario: Array<string>;
  country_birth: number;
  cpf: string;
  etnia: Array<{
    id: number;
    code: string;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  etnia_id: number | null;
  father_know: number;
  father_name: string | null;
  genre: Array<{
    id: number;
    code: string;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  genre_id: number;
  incomplete_data: number;
  incomplete_data_reason: string | null;
  legal_responsible: string;
  legal_responsible_document: string;
  legal_responsible_document_date: string;
  marital_status: string | null;
  medical_unit: Array<{
    id: number;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  medical_unit_id: number | null;
  mother_know: number;
  mother_name: string | null;
  naturalizado: string | null;
  passport: string | null;
  patient_address: Array<{
    id: number;
    patient_id: number;
    address_name: string;
    street: string;
    number: string;
    complement: string;
    district: string;
    zip_code: string;
    city_id: number;
    country_id: number;
  }>;
  patient_contact: Array<{
    id: number;
    patient_id: number;
    name: string;
    type: string;
    phone: string;
    observation: string;
  }>;
  patient_deficiencies: Array<{
    id: number;
    code: string;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  patient_document: Array<{
    id: number;
    patient_id: number;
    document_type_id: number;
    document_type: { id: number; description: string };
  }>;
  patient_document_fields: Array<{
    id: number;
    patient_document_id: number;
    document_type_field_id: number;
    value: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  patient_has_group: Array<{
    id: number;
    group_id: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  patient_has_medical_unit: Array<{
    id: number;
    medical_unit_id: number;
    medical_unit?: {
      bussines_name?: string;
      company_name?: string;
    };
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  patient_line_care: Array<{
    id: number;
    patient_id: number;
    line_care_id: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  patient_pregnancy: Array<{
    id: number;
    patient_id: number;
    pregnancy_id: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  profession: string | null;
  pronoun: string | null;
  pronoun_id: number | null;
  relationship_degree: {
    id: number;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  };
  relationship_degree_id: number;
  rg: {
    id: number;
    value: {
      numero: { id: number; value: string };
      data_emissao: { id: number; value: { view: string; edit: string } };
      orgao_emissor: { id: number; value: string };
      uf: {
        id: number;
        value: {
          id: number;
          code: string;
          description: string;
          country_id: number;
        };
      };
    };
  };
  rne: string | null;
  sexual_orientation: Array<{
    id: number;
    code: string;
    description: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>;
  sexual_orientation_id: number;
  specific_population: string | null;
  specific_population_id: number | null;
  state_birth: number;
}

interface IViewDetailsProps {
  patientData: IPatientData;
  grauDeRelacionamentoList: {
    id: number;
    value: string;
  }[];
  unidadesRefs: {
    id: number;
    value: string;
  }[];
  phones: IPhone[];
}

export const ViewDetails = ({
  patientData,
  grauDeRelacionamentoList,
  unidadesRefs,
  phones,
}: IViewDetailsProps) => {
  const classes = useStyles();
  const {
    dadosPrincipais,
    dadosDemograficos,
    dadosGravidez,
    dadosComplementares,
    dadosEndereco,
  } = usePatient();

  const userDominios = localStorage.getItem('user_dominios');
  const userDominiosObj = JSON.parse(userDominios as string) as IUserDominios;

  const sexoMap = useMemo(
    () =>
      sexoCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    [],
  );

  const orientacoesSexuaisMap = useMemo(
    () =>
      orientacoesSexuaisCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const generosMap = useMemo(
    () =>
      generoCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const states =
    userDominiosObj?.state?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  const cities =
    userDominiosObj?.city?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  const etnias =
    userDominiosObj?.etnia?.map((item) => {
      return { id: item.id, value: item.code, description: item.description };
    }) || [];

  const racasMap = useMemo(
    () =>
      racasCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const populacaoEspecMap = useMemo(
    () =>
      populacaoEspecCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    [],
  );

  const unidadeVinculada = patientData?.patient_has_medical_unit?.map(
    (res) => ({
      id: res.medical_unit_id,
      value: res?.medical_unit?.bussines_name
        ? res?.medical_unit?.bussines_name
        : res?.medical_unit?.company_name,
    }),
  );

  const logradourosMap = useMemo(
    () =>
      logradourosCode.map(function (item) {
        return { id: item.id, value: item.value };
      }),
    [],
  );

  return (
    <>
      {/* DADOS PRINCIPAIS */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12} className={classes.field_padding}>
            <h5 className={classes.dados_titulo}>Dados Principais</h5>
          </Grid>
          <Grid item md={4} className={classes.field_padding}>
            <span style={{ fontSize: '14px' }}>
              <strong>Nacionalidade:</strong>{' '}
              {dadosPrincipais?.nacionalidade
                ? nacionalidades.find(
                    ({ id }) => id === dadosPrincipais?.nacionalidade,
                  )?.value || null
                : 'Não informado'}
            </span>
          </Grid>
          <Grid item md={4} className={classes.field_padding}>
            <span style={{ fontSize: '14px' }}>
              <strong>CPF:</strong> {dadosPrincipais.cpf || 'Não informado'}
            </span>
          </Grid>
          <Grid item md={4} className={classes.field_padding}>
            <span style={{ fontSize: '14px' }}>
              <strong>E-mail:</strong>{' '}
              {dadosPrincipais.email || 'Não informado'}
            </span>
          </Grid>
          <Grid item xs={4} className={classes.field_padding}>
            <span>
              <strong>NR:</strong> {dadosPrincipais.NR || 'Não informado'}
            </span>
          </Grid>
          {dadosPrincipais.dadosIncompletos ? (
            <Grid item xs={4} className={classes.field_padding}>
              <span style={{ fontSize: '14px' }}>
                <strong>Ano estimado de nascimento:</strong>{' '}
                {dadosPrincipais.anoEstimadoDeNascimento || 'Não informado'}
              </span>
            </Grid>
          ) : (
            <Grid item xs={4} className={classes.field_padding}>
              <span style={{ fontSize: '14px' }}>
                <strong>Data de nascimento:</strong>{' '}
                {dadosPrincipais.dataNascimento || 'Não informado'}
              </span>
            </Grid>
          )}

          <Grid item xs={4} className={classes.field_padding}>
            <span style={{ fontSize: '14px' }}>
              <strong>Nome completo:</strong>{' '}
              {dadosPrincipais.nomeCompleto || 'Não informado'}
            </span>
          </Grid>

          <Grid item xs={4} className={classes.field_padding}>
            <strong>Sexo Biológico:</strong>{' '}
            {dadosPrincipais.sexo
              ? sexoMap.filter((res) => res.id == dadosPrincipais.sexo)[0]
                ? sexoMap.filter((res) => res.id == dadosPrincipais.sexo)[0]
                    .value
                : 'Não informado'
              : 'Não informado'}
          </Grid>
          <Grid item xs={4} className={classes.field_padding}>
            <strong>Se identifica com sexo biológico?:</strong>{' '}
            {dadosPrincipais.seIdentificaSexo === 1
              ? 'Sim'
              : dadosPrincipais.seIdentificaSexo === 0
                ? 'Não'
                : 'Não informado'}
          </Grid>

          <Grid item xs={4} className={classes.field_padding}>
            <strong>Orientação sexual:</strong>{' '}
            {dadosPrincipais.orientacaoSexual
              ? orientacoesSexuaisMap.filter(
                  (res) => res.id == dadosPrincipais.orientacaoSexual,
                )[0].value
              : 'Não informado'}
          </Grid>

          <Grid item xs={4} className={classes.field_padding}>
            <strong>Gênero:</strong>{' '}
            {dadosPrincipais.genero
              ? generosMap.filter((res) => res.id == dadosPrincipais.genero)[0]
                  .value
              : 'Não informado'}
          </Grid>

          <Grid item xs={4} className={classes.field_padding}>
            <span style={{ fontSize: '14px' }}>
              <strong>Nome social:</strong>{' '}
              {dadosPrincipais.nomeSocial || 'Não informado'}
            </span>
          </Grid>
        </Grid>
      </div>

      {/* IDENTIDADE */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            {dadosPrincipais.nacionalidade === 1 && <BrazilianIdentity />}

            {dadosPrincipais.nacionalidade === 2 && <NaturalizedIdentity />}

            {dadosPrincipais.nacionalidade === 3 && (
              <ForeignIdentity
                patientRgUfCode={patientData?.rg?.value?.uf?.value?.code}
              />
            )}
          </Grid>
        </Grid>
      </div>

      {/* CNS */}
      <div className={classes.dados_container}>
        <Cns />
      </div>

      {/* DADOS DEMOGRÁFICOS */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <h5 className={classes.dados_titulo}>Dados demográficos</h5>
          </Grid>
          <>
            {dadosPrincipais.nacionalidade === 1 && (
              <>
                <Grid item md={4} xs={4} className={classes.field_padding}>
                  <strong>Estado de nascimento:</strong>{' '}
                  {states?.find(
                    (x) => x.id === dadosPrincipais.estadoNascimento,
                  )
                    ? states?.find(
                        (x) => x.id === dadosPrincipais.estadoNascimento,
                      )?.value
                    : 'Não informado'}
                </Grid>
                <Grid item md={4} xs={4} className={classes.field_padding}>
                  <strong>Município de nascimento:</strong>{' '}
                  {cities?.find(
                    (res) => res.id === dadosDemograficos.municipioNascimento,
                  )
                    ? cities?.find(
                        (res) =>
                          res.id === dadosDemograficos.municipioNascimento,
                      )?.value
                    : 'Não informado'}
                </Grid>
              </>
            )}
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Raça:</strong>{' '}
              {racasMap?.find((res) => res.id === dadosDemograficos.raca)
                ? racasMap?.find((res) => res.id === dadosDemograficos.raca)
                    ?.value
                : 'Não informado'}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Etnia:</strong>{' '}
              {etnias?.find((res) => res.id == dadosDemograficos.etnia)
                ? etnias?.find((res) => res.id == dadosDemograficos.etnia)
                    ?.value
                : dadosDemograficos.raca == 5
                  ? 'Não informado'
                  : 'Não informado'}
            </Grid>
          </>
        </Grid>
      </div>

      {/* GESTANTE */}
      {sexoMap?.find((x) => x.id === dadosPrincipais.sexo)?.value ===
        'Feminino' && (
        <div className={classes.dados_container}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Gravidez</h5>
            </Grid>

            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Quantas vezes já engravidou:</strong>{' '}
              {dadosGravidez.qtdVezesGravidez
                ? dadosGravidez.qtdVezesGravidez
                : 'Não informado'}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Filhos nascidos vivos:</strong>{' '}
              {dadosGravidez.filhosVivos
                ? dadosGravidez.filhosVivos
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Data da última menstruação:</strong> &nbsp;
              {dadosGravidez.dtUltimaMenstruacao
                ? dadosGravidez.dtUltimaMenstruacao
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Já teve algum aborto:</strong>{' '}
              {dadosGravidez.teveAborto
                ? 'Sim'
                : dadosGravidez.teveAborto == null
                  ? 'Não informado'
                  : 'Não'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Quantas vezes já abortou:</strong>{' '}
              {dadosGravidez.qtdVezesAborto && dadosGravidez.teveAborto
                ? dadosGravidez.qtdVezesAborto
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Data do último aborto:</strong> &nbsp;
              {dadosGravidez.dtUltimoAborto || 'Não informado'}
            </Grid>
          </Grid>
        </div>
      )}

      {/* TELEFONES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <h5 className={classes.dados_titulo}>Telefones</h5>
          </Grid>

          <Grid item md={12}>
            {phones?.length ? (
              <>
                {phones?.map((contact) => (
                  <div className={classes.dados_card}>
                    <Grid container spacing={1}>
                      <Grid item md={4}>
                        Telefone
                        <br />
                        {contact.phone}
                      </Grid>
                      <Grid item md={4}>
                        Tipo do telefone
                        <br />
                        {contact.type}
                      </Grid>
                      <Grid item md={4}>
                        Apelido do telefone
                        <br />
                        {contact.name}
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Nenhum telefone adicionado
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>

      {/* DADOS COMPLEMENTARES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Dados complementares</h5>
          </Grid>

          <>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Número do NIT-PIS-PASEP:</strong>{' '}
              {dadosComplementares.numeroPis
                ? dadosComplementares.numeroPis
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Deficiências:</strong>&nbsp;
              {Array.isArray(dadosComplementares.deficiencia) &&
              dadosComplementares.deficiencia.length == 0
                ? 'Não informado'
                : dadosComplementares.temDeficiencia &&
                    Array.isArray(dadosComplementares.deficiencia)
                  ? dadosComplementares.deficiencia.map((res, index) => {
                      return res.value.length && index == 0
                        ? res.value
                        : ', ' + res.value;
                    })
                  : 'Não'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>População específica:</strong>{' '}
              {dadosComplementares.populacaoEspec &&
              populacaoEspecMap.find(
                (populacaoEspecM) =>
                  populacaoEspecM.id === dadosComplementares.populacaoEspec,
              )
                ? populacaoEspecMap.find(
                    (populacaoEspecM) =>
                      populacaoEspecM.id == dadosComplementares.populacaoEspec,
                  )?.value
                : 'Não informado'}
            </Grid>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <strong>Responsável legal:&nbsp;</strong>
              {dadosComplementares.responsavelLegal
                ? dadosComplementares.responsavelLegal
                : 'Não informado'}
            </Grid>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <strong>Grau de relacionamento:&nbsp;</strong>
              {grauDeRelacionamentoList &&
              Array.isArray(grauDeRelacionamentoList) &&
              grauDeRelacionamentoList.length > 0 &&
              grauDeRelacionamentoList.find(
                (grauList) =>
                  grauList.id === dadosComplementares.grauDeRelacionamento,
              )
                ? grauDeRelacionamentoList.find(
                    (grauList) =>
                      grauList.id === dadosComplementares.grauDeRelacionamento,
                  )?.value
                : 'Não informado'}
            </Grid>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <strong>Número de identidade:&nbsp;</strong>
              {dadosComplementares.responsavelLegalDocumento
                ? dadosComplementares.responsavelLegalDocumento
                : 'Não informado'}
            </Grid>
            <Grid item xs={6} md={6} className={classes.field_padding}>
              <strong>Data emissão:&nbsp;</strong>
              {dadosComplementares.responsavelLegalDocumentoEmissao
                ? dadosComplementares.responsavelLegalDocumentoEmissao
                : 'Não informado'}
            </Grid>
          </>
        </Grid>
      </div>

      {/* UNIDADES */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Unidades</h5>
          </Grid>

          <>
            <Grid item md={12}>
              <strong>Unidade de referência:</strong>{' '}
              {unidadesRefs
                ? unidadesRefs.find(
                    (res) => res.id === patientData?.medical_unit[0]?.id,
                  )
                  ? unidadesRefs.find(
                      (res) => res.id === patientData?.medical_unit[0]?.id,
                    )?.value
                  : 'Não informado'
                : 'Não informado'}
            </Grid>
            <Grid item md={12}>
              <strong>Unidades vinculadas:</strong>{' '}
              {!unidadeVinculada.length && 'Não informado'}
            </Grid>
            <Grid item md={12}>
              {unidadeVinculada.length ? (
                <>
                  {unidadeVinculada.map((item) => (
                    <Chip
                      label={item.value ? item.value : '-'}
                      className={classes.dados_chip}
                    />
                  ))}
                </>
              ) : (
                ''
              )}
            </Grid>
          </>
        </Grid>
      </div>

      {/* ENDEREÇO */}
      <div
        className={classes.dados_container}
        style={{ marginBottom: 0, border: 0 }}
      >
        <Grid container spacing={1}>
          <Grid item md={12} style={{ paddingLeft: '0px' }}>
            <h5 className={classes.dados_titulo}>Endereço</h5>
          </Grid>

          <>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>CEP:</strong>{' '}
              {dadosEndereco.cep
                ? mascaraCEP(dadosEndereco.cep)
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Logradouro:</strong>{' '}
              {logradourosMap.find(
                (logradouro) => logradouro.id === dadosEndereco.logradouro,
              )
                ? logradourosMap.find(
                    (logradouro) => logradouro.id === dadosEndereco.logradouro,
                  )?.value
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Endereço:</strong>{' '}
              {dadosEndereco.endereco || 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Número:</strong>{' '}
              {dadosEndereco.numero ? dadosEndereco.numero : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Complemento:</strong>{' '}
              {dadosEndereco.complemento || 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Bairro:</strong> {dadosEndereco.bairro || 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>UF:</strong>{' '}
              {states[dadosEndereco.estado - 1]
                ? states[dadosEndereco.estado - 1].value
                : 'Não informado'}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Município:</strong>{' '}
              {cities?.find((city) => city.id === dadosEndereco.idMunicipio)
                ? cities?.find((city) => city.id === dadosEndereco.idMunicipio)
                    ?.value
                : 'Não informado'}
            </Grid>
          </>
        </Grid>
      </div>
    </>
  );
};
