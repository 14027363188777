import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { getClasses, getSize, getvariant } from '../shared.helper';

function DButtonCustom(props) {
  return (
    <Button
      style={props.style}
      classes={props.classes}
      id={props.id}
      type={props.type ? props.type : 'button'}
      onClick={props.onClick}
      className={`${getvariant(props.variant)} ${getClasses(props.className, props.disabled)} ${props.multiClass} ${getSize('button', props.size)} ${props.active ? 'btn-active' : ''} `}
      disabled={props.disabled ?? false}
    >
      {props.notGrid ? (
        props.children
      ) : (
        <Grid container justifyContent="space-between" alignItems="center">
          {props.icon ? (
            <Grid item style={{ paddingTop: 5 }}>
              {props.icon}
            </Grid>
          ) : (
            ''
          )}
          <Grid item>{props.children}</Grid>
          {props.endIcon ? (
            <Grid item style={{ paddingTop: 5 }}>
              {props.endIcon}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      )}
    </Button>
  );
}

export default DButtonCustom;
