import { makeStyles } from '@material-ui/core/styles';

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#67738B',
  },
  tooltip: {
    maxWidth: 150,
    fontSize: 14,
    lineHeight: '21px',
    backgroundColor: '#67738B',
  },
}));
