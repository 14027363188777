import {
  Grid,
  Paper
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import noData from '../../../../assets/img/noData.png';

import { useConfiguracaoAgenda } from 'context/ConfiguracaoAgendaContext';
import {
  deleteSchedule,
  filterScheduleList,
  getHasScheduleTime,
  listAllSchedule,
  updateStatusSchedule
} from '../../../../api/schedule/agenda-medico';
import CustomPopover from './CustomPopover';
import Filter from './Filter';

import DeletarAgendaModal from 'component/AgendaMedico/components/Modais/DeletarAgendaModal';
import DuplicarAgendaModal from 'component/AgendaMedico/components/Modais/DuplicarAgendaModal';

import { getUnidade } from 'api/unidades';
import { AppContext, useAppProvider } from 'context/AppContext';
import { useAuthRoute } from 'context/AuthRouteContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AgendaComHorariosModal from '../AgendaEdit/components/AgendaComHorariosModal';

const useStyles = makeStyles((theme) => ({
  containerFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.neutral.midgrey,
  },

  table: {
    minWidth: 650,
  },

  textColor: {
    color: `${theme.palette.neutral.black} !important`,
    fontSize: '14px',
  },

  paddingLeftNone: {
    paddingLeft: 0,
  },

  paddingRightNone: {
    paddingRight: 0,
  },

  chip: {
    margin: 4,
    color: `${theme.palette.neutral.black} !important`,
  },
  resizeName: {
    maxWidth: '100px',
    overflow: 'scroll',
  },
  icon: {
    fontSize: 15,
  },

  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  selected: {
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: theme.palette.primary.light,
    fontWeight: '500 !important',
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
    '&>li:last-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
  },
  tableContainer: {
    overflowX: 'auto',
  },
  marginBottom90: {
    marginBottom: '90px',
  },
  toolbar_top: {
    zIndex: 3000,
    color: 'white',
    textAlign: 'center',
  },
  btnEdit: {
    justifyContent: 'left',
  },
  customPopover: {
    width: 180,
  },
  backgroundModalExcluir: {
    width: '450px',
    height: '250px',
    border: `${theme.palette.neutral.gray} 2px solid`,
    borderRadius: '20px',
    background: theme.palette.neutral.white,
    margin: '200px auto',
  },

  titleDelete: {
    textAlign: 'center',
  },

  noPointer: {
    cursor: 'default',
  },
  backgroundList: {
    background: theme.palette.background.snow,
    padding: '20px',
  },
  contentNoData: {
    margin: '80px auto',
    textAlign: 'center',
  },

  titleNodata: {
    marginBottom: '5px',
  },

  imgNoData: {
    margin: '0 auto',
    display: 'block',
  },

  columnGrid: {
    textAlign: 'center',
    marginTop: 20,
  },

  btnExcluir: {
    background: `${theme.palette.support.error}`,
  },
}));

export default function List({ handleMenuItem }) {
  const classes = useStyles();

  const { auth, setAuth } = useAuthRoute();
  const { LoadingControl } = useAppProvider();
  const { userInfo } = useContext(AppContext);
  const [agendas, setAgendas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [anchorEdit, setAnchorEdit] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalAgendaComHorarios, setModalAgendaComHorarios] = useState(false);
  const [isReagendamento, setIsReagendamento] = useState({
    status: false,
    scheduleTimes: null,
  });
  const [modalExclusao, setModalExclusao] = useState(false);
  const [NomeDaAgendaSelecionada, setNomeDaAgendaSelecionada] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [excluirOpened, setExcluirOpened] = useState(false);
  const [modalDuplicar, setModalDuplicar] = useState(false);

  const { setHorariosAgendados, setDadosAgendamento, setIsExcluir } =
    useConfiguracaoAgenda();

  useEffect(() => {
    listarAgendas(1);
  }, []);

  const getAgendas = (filtrosSelecionados) => {
    filterScheduleList(filtrosSelecionados).then((data) => {
      if (data.status === true) {
        setHistoricoList(
          data.schedule.data,
          data.schedule.current_page,
          data.schedule.last_page,
        );
      }
    });
  };

  const handleUpdateSchedule = (id, status) => {
    updateStatusSchedule({ status: status == 1 ? 2 : 1 }, id).then((data) => {
      if (data.status === true) {
        toast.success('Agenda atualizada com sucesso!');
        listarAgendas(1);
      } else {
        toast.error('Não foi possível atualizar a agenda.');
      }
    });
  };

  const setHistoricoList = (dataParam, page, totalPageParam) => {
    setTotalPage(totalPageParam);
    setCurrentPage(page);
    setAgendas(dataParam);
  };

  const handleAnchorEditClose = () => {
    setAnchorEdit(null);
    setSelectedItem(null);
  };

  const renderDate = (strDate) => {
    const dateObj = new Date(strDate);
    const momentObj = moment(dateObj);
    return momentObj.format('DD/MM/YYYY');
  };

  const handleReagendarCreate = (horariosReagend, dadosAtendimento) => {
    handleMenuItem('lista_agendamentos');
    setHorariosAgendados(horariosReagend);
    setDadosAgendamento(dadosAtendimento);
    console.log(horariosReagend, dadosAtendimento);
  };

  function listarAgendas(page) {
    LoadingControl.showLoading('Carregando...');

    listAllSchedule(page)
      .then((data) => {
        if (data.status === true) {
          setHistoricoList(
            data.request.schedule.data,
            data.request.schedule.current_page,
            data.request.schedule.last_page,
          );
        }
      })
      .finally(() => {
        LoadingControl.hideLoading();
      });
  }

  async function checarHorariosAgendamento() {
    if (userInfo.unidade) {
      try {
        LoadingControl.showLoading();
        const res = await getUnidade(
          localStorage.getItem('token'),
          userInfo.unidade,
        );

        if (res.details?.medicalUnit?.medical_unit_type != 'Assistencial') {
          setAuth({ ...auth, route: false });
        } else {
          const data = await getHasScheduleTime(selectedItem.id);

          if (data.status === false) {
            setIsReagendamento({
              status: true,
              scheduleTimes: data.scheduleDataExtract,
            });
          }

          setAnchorEdit(null);
          setModalExclusao(true);
        }
      } catch (err) {
        toast.error('Erro ao checar horários agendados');
      } finally {
        LoadingControl.hideLoading();
      }
    } else {
      toast.error('Perfil não vinculado a uma unidade.');
    }
  }

  function deletarAgendamento() {
    LoadingControl.showLoading('Excluindo...');

    deleteSchedule(selectedItem.id)
      .then((data) => {
        if (data.status === true) {
          listarAgendas(1);

          toast.success(
            `Agenda de ${
              selectedItem?.schedule_professional?.user?.social_name ||
              selectedItem?.schedule_professional?.user?.full_name ||
              'Profissional'
            } foi excluída com sucesso`,
          );
        } else {
          toast.error(
            'Ocorreu um erro ao excluir a agenda. Por favor, tente novamente em alguns minutos.',
          );
        }
      })
      .finally(() => {
        LoadingControl.hideLoading();
      });
  }

  function confirmarAcaoAgendamento(statusReagend, horariosReagend) {
    if (statusReagend == true) {
      setIsExcluir(true);
      handleReagendarCreate(horariosReagend, selectedItem);
    } else {
      deletarAgendamento();
    }

    setSelectedItem(null);
    setModalExclusao(false);
  }

  function duplicarAgendamento(e) {
    handleMenuItem('duplicar_agenda', selectedItem);
  }

  const getCboDescription = (cbo_description) => {
    if (cbo_description) {
      try {
        let desc = cbo_description.split('(')[0];

        let cbo = [...new Set(desc.replaceAll('-', '').split(' '))];

        let formated_cbo = [
          ...new Set(
            desc
              .replaceAll('-', '')
              .split(' ')
              .filter((x) => x),
          ),
        ];

        if (formated_cbo.length == 3) {
          return `${formated_cbo[0]} ${formated_cbo[1]} - ${formated_cbo[2]}`;
        } else {
          return cbo.join(' ');
        }
      } catch (e) {
        return cbo_description;
      }
    }

    return cbo_description;
  };

  const renderTable = () => {
    if (agendas.length > 0) {
      return (
        <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ head: classes.textColor }}></TableCell>
                  <TableCell
                    classes={{
                      head: classes.textColor + ' ' + classes.paddingLeftNone,
                    }}
                  >
                    Nome
                  </TableCell>
                  <TableCell classes={{ head: classes.textColor }}>
                    Conselho
                  </TableCell>
                  <TableCell classes={{ head: classes.textColor }}>
                    Especialidade
                  </TableCell>
                  <TableCell classes={{ head: classes.textColor }}>
                    Ocupação
                  </TableCell>
                  <TableCell classes={{ head: classes.textColor }}>
                    Período
                  </TableCell>
                  <TableCell classes={{ head: classes.textColor }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agendas.map((row, index) => (
                  <TableRow key={`agendas-${index}`}>
                    <TableCell
                      classes={{ body: classes.paddingRightNone }}
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        title={`${row.status == 1 ? 'Ativo' : 'Inativo'}`}
                        placement="right"
                      >
                        <IconButton className={classes.noPointer}>
                          {row.status == 1 ? (
                            <CheckCircleIcon style={{ color: '#1DE8B5' }} />
                          ) : (
                            <RemoveCircleOutlineIcon
                              style={{ color: '#FF6B57' }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      classes={{ body: classes.paddingLeftNone }}
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        title={`${
                          row.schedule_professional?.user?.social_name
                            ? row.schedule_professional?.user?.social_name
                            : row.schedule_professional?.user?.full_name
                        }`}
                        placement="right"
                      >
                        <label>
                          {row.schedule_professional?.user?.social_name
                            ? row.schedule_professional?.user?.social_name
                            : row.schedule_professional?.user?.full_name}
                        </label>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip
                        title={`${getCboDescription(
                          row.schedule_professional.concil_value,
                        )}`}
                        placement="right"
                      >
                        <label>
                          {`${getCboDescription(
                            row.schedule_professional.concil_value,
                          )}`}
                        </label>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip
                        title={
                          row.schedule_professional.medical_speciality
                            .description
                        }
                        placement="right"
                      >
                        <label>
                          {
                            row.schedule_professional.medical_speciality
                              .description
                          }
                        </label>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip
                        title={row.schedule_professional.cbo.description}
                        placement="right"
                      >
                        <label>
                          {row.schedule_professional.cbo.description}
                        </label>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip
                        title={`${renderDate(row.start)} ${
                          row.end ? ' - ' : ''
                        } ${row.end ? renderDate(row.end) : ''}`}
                        placement="right"
                      >
                        <label>
                          {renderDate(row.start)}{' '}
                          {row.end ? ` - ${renderDate(row.end)}` : null}
                        </label>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="settings"
                        onClick={(e) => {
                          setAnchorEdit(e.target);
                          setSelectedItem(row);
                          if (row) {
                            setNomeDaAgendaSelecionada(
                              row.schedule_professional.user.full_name,
                            );
                          }
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid>
            <Grid item md={12} className={classes.marginBottom90}>
              <Pagination
                count={totalPage}
                page={currentPage}
                classes={{ root: classes.pagination, ul: classes.ul }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    classes={{
                      selected: classes.selected,
                      page: classes.page,
                    }}
                  />
                )}
                onChange={(event, page) => {
                  if (currentPage == page) return;

                  listarAgendas(page);
                }}
              />
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.contentNoData}>
            <img className={classes.imgNoData} src={noData} alt="" />
            <h3 className={classes.titleNodata}>
              Ops, nenhuma agenda foi criada
            </h3>
            <label>Crie a primeira para começar a gerenciar!</label>
          </div>
        </>
      );
    }
  };

  async function handleEditarAgenda(agenda) {
    handleMenuItem('editar_agenda:' + agenda.id);
    return;
  }

  return (
    <>
      <DeletarAgendaModal
        open={modalExclusao}
        isReagendamento={isReagendamento}
        onConfirm={({ status, scheduleTimes }) =>
          confirmarAcaoAgendamento(status, scheduleTimes)
        }
        onClose={(e) => {
          setModalExclusao(false);
          setIsReagendamento({ status: false, scheduleTimes: null });
        }}
      />
      <DuplicarAgendaModal
        open={modalDuplicar}
        NomeDaAgenda={NomeDaAgendaSelecionada}
        agendaDados={selectedItem}
        onDuplicar={(e) => duplicarAgendamento(e)}
        onCancelar={(e) => {
          setModalDuplicar(false);
        }}
      />

      <AgendaComHorariosModal
        open={modalAgendaComHorarios}
        onConfirm={() => {
          handleMenuItem('lista_agendamentos');
        }}
        onClose={(e) => {
          setModalAgendaComHorarios(false);
        }}
      />

      <Grid container className={classes.backgroundList}>
        <Grid item md={12}>
          <Filter handleMenuItem={handleMenuItem} callbackFilter={getAgendas} />

          {renderTable()}
        </Grid>
      </Grid>

      <CustomPopover
        id="simple-popover-periodo"
        anchor={anchorEdit}
        handleClose={handleAnchorEditClose}
        classCss={classes.customPopover}
      >
        <Button
          className={classes.btnEdit}
          fullWidth={true}
          onClick={() => handleMenuItem('visualizar_agenda:' + selectedItem.id)}
        >
          Visualizar
        </Button>
        <Button
          className={classes.btnEdit}
          fullWidth={true}
          onClick={() => handleEditarAgenda(selectedItem)}
        >
          Editar
        </Button>
        <Button
          className={classes.btnEdit}
          fullWidth={true}
          onClick={() => setModalDuplicar(true)}
        >
          Duplicar
        </Button>
        <Button
          className={classes.btnEdit}
          fullWidth={true}
          onClick={() =>
            handleUpdateSchedule(selectedItem.id, selectedItem.status)
          }
        >
          {selectedItem?.status == 1 ? 'Desativar' : 'Ativar'}
        </Button>
        <Button
          className={classes.btnEdit}
          fullWidth={true}
          onClick={(e) => {
            checarHorariosAgendamento();
          }}
        >
          Excluir
        </Button>
      </CustomPopover>
    </>
  );
}
