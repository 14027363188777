import { useEffect, useState } from 'react';
import { useAuthRoute } from './../../context/AuthRouteContext';
import { getAllUser } from './../../api/user';
import Profissional from './Professional.jsx';

function ProfessionalContent() {
  const { auth, setAuth } = useAuthRoute();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getAllUser(localStorage.getItem('token'), 1).then((res) => {
      if (!res.status) setAuth({ ...auth, route: false });
      else setLoaded(true);
    });
  }, [localStorage.getItem('token')]);

  return <>{loaded && <Profissional />}</>;
}

export default ProfessionalContent;
