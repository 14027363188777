import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

const ActionButton = withStyles((theme) => ({
  root: {
    width: 210,
    height: 33,
    borderRadius: 100,
    paddingTop: 6,
    paddingRight: 18,
    paddingBottom: 6,
    paddingLeft: 18,
  },
  text: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textPrimary: {
    textTransform: 'unset',
    color: '#FFF',
  },
  outlined: {
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'white',
    '&:hover': {
      border: 'solid 2px',
      backgroundColor: '#e7e7e7',
    },
  },
  outlinedPrimary: {
    textTransform: 'unset',
    color: theme.palette.primary.main,
  },
  disabled: {
    backgroundColor: theme.palette.primary.light,
    color: '#FFF !important',
  },
}))(Button);

export const DefaultButton = (props) => {
  return (
    <ActionButton {...props}>
      <Typography variant="body1" className="text-regular text-st">
        {props.children}
      </Typography>
    </ActionButton>
  );
};
