import env from 'react-dotenv';
import axios from 'axios';

function getAllGroups(token) {
  return axios
    .get(`${env.API_URL}/group`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function createGroup(token, dados) {
  return axios
    .post(`${env.API_URL}/group`, JSON.stringify(dados), {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

function updateGroup(token, dados) {
  return axios
    .put(
      `${env.API_URL}/group/${dados.id}`,
      JSON.stringify({ name: dados.name }),
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-domain': env.X_DOMAIN,
          'x-auth-token': token,
        },
      },
    )
    .then((res) => res.data)
    .catch((error) => error.response);
}

function deleteGroup(token, id) {
  return axios
    .delete(`${env.API_URL}/group/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-domain': env.X_DOMAIN,
        'x-auth-token': token,
      },
    })
    .then((res) => res.data)
    .catch((error) => error.response);
}

export { getAllGroups, createGroup, updateGroup, deleteGroup };
