function IMCView(props) {
  const calculo = () => {
    var altura_metro = props.altura / 100;

    return Math.round(props.peso / (altura_metro * altura_metro));
  };

  const escalaIMC = () => {
    var imc = calculo();
    if (imc >= 40) return 'Obesidade Grau III';
    else if (imc < 40 && imc >= 35) return 'Obesidade Grau II';
    else if (imc < 35 && imc >= 30) return 'Obesidade Grau I';
    else if (imc < 30 && imc >= 25) return 'Sobrepeso';
    else if (imc < 25 && imc >= 18.5) return 'Peso normal';
    else if (imc < 18.5) return 'Peso baixo';
  };

  return (
    <>
      <div className="info_new_visualizador_clinico">{calculo()}</div>
      <div className="tag_new_visualizador_clinico">{escalaIMC()}</div>
    </>
  );
}

IMCView.defaultProps = {
  peso: 95,
  altura: 172,
};

export default IMCView;
