import React from 'react';

export const MenuContext = React.createContext({});

export const MenuProvider = (props) => {
  const [menus, setMenus] = React.useState([]);
  const [atendimentoIndex, setAtendimentoIndex] = React.useState(true);

  return (
    <MenuContext.Provider
      value={{ menus, setMenus, atendimentoIndex, setAtendimentoIndex }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export const useMenus = () => React.useContext(MenuContext);
