import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, makeStyles } from '@material-ui/core';
import './index.scss';
import { useState } from 'react';
import { getExamsByCPF } from '../../../../api/above/ExameByPatient';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import {
  getPatientWallet,
  showPatientWalletItem,
} from '../../../../api/patientWallet';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4F4F4F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4F4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaExamesEnviados: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

function createData(dia, unidade, pedido, solicitante, resultado) {
  return { dia, unidade, pedido, solicitante, resultado };
}

export default function CustomizedTables() {
  const { patient } = useNewHealthAttendance();
  const [exames, setExames] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (patient && patient.cpf) {
      getExamsByCPF(
        localStorage.getItem('token'),
        patient.cpf.replace(/[^0-9]/g, ''),
      )
        .then((data) => {
          if (data.status) {
            setExames(data.details.VETOR_PEDIDO_EXAME);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getPatientWallet(localStorage.getItem('token'), patient.cpf, 'exam').then(
      (data) => {
        if (data.status) {
          setExames(data.patientWallet);
        }
      },
    );
  }, [patient]);

  const handleOpenFile = (patientWalletItemId) => {
    showPatientWalletItem(localStorage.getItem('token'), patientWalletItemId)
      .then((data) => {
        if (!data.status && !data.temporalyUrlFile) return;

        let _filename = data.patientWalletItem.file.split('/').pop();
        let link = document.createElement('a');
        link.href = data.temporalyUrlFile;
        link.target = '_blank';
        link.download = _filename;
        link.click();
        link.remove();
      })
      .catch((err) => console.error(err));
  };

  function formatDate(date) {
    if (date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : null;
      var day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
      var month =
        date.getMonth() + 1 >= 10 ? date.getMonth() : '0' + date.getMonth();
      var year = String(date.getFullYear()).slice(2);

      return [day, month, year].join('/');
    } else {
      return '';
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Data</StyledTableCell>
              <StyledTableCell align="left">Unidade</StyledTableCell>
              <StyledTableCell align="left">Pedido</StyledTableCell>
              <StyledTableCell align="left">Solicitante</StyledTableCell>
              <StyledTableCell align="center">Resultado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exames.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {item.date && formatDate(item.date)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {item.health_unit}
                </StyledTableCell>
                <StyledTableCell align="left">{item.order}</StyledTableCell>
                <StyledTableCell align="left">{item.requester}</StyledTableCell>
                <StyledTableCell align="center">
                  {item &&
                    item.items.map((subitem, indexSubitem) => {
                      return (
                        <Button
                          className={classes.iconResultadoTabelaVacinas}
                          onClick={(e) => handleOpenFile(subitem.id)}
                          startIcon={<LaunchIcon />}
                        ></Button>
                      );
                    })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
