import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ReactQuill from 'react-quill';
import { FormBuilderContext } from '../../../../../../../context/FormBuilderContext';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles({
  displayErrorShow: {
    border: '1px solid #f44336',
  },
});

const EBTxRich = ({
  id,
  label,
  placeholder,
  value,
  is_required,
  is_read_only,
  condicional,
  helper_text,
  readOnly,
  erro,
  answer,
  additional_data,
}) => {
  const classes = useStyles();
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [txRich, setTxRich] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (erro[0] && erro[0].value && txRich === '') {
      setShowError(true);
    } else if (erro[0] === undefined || txRich.length > 0) {
      setShowError(false);
    }
  }, [erro[0], txRich]);

  useEffect(() => {
    if (answer.length > 0) {
      setTxRich(answer[0]);
    }
  }, []);

  const style = {
    width: '100%',
  };

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: '1em', display: 'flex' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${additional_data.construct.showTitle ? label : ''}`,
            }}
          />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>
            {is_required === 1 ? '*' : ''}
          </span>
        </Typography>
        <ReactQuill
          className={showError ? classes.displayErrorShow : ''}
          theme="snow"
          value={txRich || ''}
          onChange={setTxRich}
          placeholder={placeholder ? placeholder : ''}
          readOnly={readOnly}
          onBlur={(e) => {
            !readOnly
              ? handleInputSubmit({ [id]: txRich })
              : console.log('effect onBlur');
          }}
          style={style}
        />
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBTxRich;
