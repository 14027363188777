import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  autoCompleteInput: {
    padding: '10px !important',
  },

  autoComplete: {
    marginBottom: '10px',
  },

  line: {
    margin: 0,
    marginBottom: '20px;',
  },

  btnFilter: {
    margin: '0 auto',
    marginTop: '15px',
    borderRadius: '20px',
    width: '120px',
    display: 'block',
  },

  formControl: {
    width: '100%',
    minWidth: 220,
    height: 'calc(100vh - 410px)',
    overflow: 'auto',
    fontSize: 14,
  },

  hrClass: {
    margin: 0,
    marginBottom: 20,
  },
});

export default function SearchList({
  src,
  searchAttr,
  keyAttr,
  callbackFilterSelect,
  callbackFilterClick,
  selecionados,
}) {
  const classes = useStyles();
  const [textSearch, setTextSearch] = useState('');
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState(null);
  const [dataSelected, setDataSelected] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (src.filter((s) => s[keyAttr] == -1).length == 0) {
      let itemTodos = {};
      itemTodos[keyAttr] = -1;
      itemTodos[searchAttr] = 'Todos';
      src.unshift(itemTodos);
    }
    const tempValues = src.map((item) => {
      return selecionados.map((s) => s[keyAttr]).includes(item[keyAttr]);
    });
    setDataSelected(selecionados);
    setValues(tempValues);
  }, []);

  useEffect(() => {
    let tempData = src;
    if (textSearch != '') {
      tempData = tempData.filter((uni) =>
        uni[searchAttr].toLowerCase().includes(textSearch.toLowerCase()),
      );
    }
    setSearchedData(tempData);
  }, [textSearch]);

  useEffect(() => {
    if (searchedData != null) {
      const tempValues = searchedData.map((item) => {
        return selecionados.map((s) => s[keyAttr]).includes(item[keyAttr]);
      });
      setValues(tempValues);
    }
  }, [searchedData]);

  useEffect(() => {
    setData(searchedData || src);
  }, [values]);

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <OutlinedInput
            value={textSearch}
            classes={{
              input: classes.autoCompleteInput,
              root: classes.autoComplete,
            }}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => {
              setTextSearch(e.target.value);
            }}
          />

          <hr className={classes.hrClass} />

          {data.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values[index]}
                    onChange={(e) => {
                      if (item[keyAttr] != -1) {
                        const tempValues = values;
                        tempValues[index] = !tempValues[index];
                        if (!tempValues[index]) {
                          tempValues[0] = false;
                        }
                        setValues(tempValues);

                        let tempData = dataSelected;
                        const tempIndex = tempData
                          .map((t) => t[keyAttr])
                          .indexOf(item[keyAttr]);

                        if (e.target.checked && tempIndex < 0) {
                          tempData.push(item);
                        } else if (tempIndex > -1) {
                          tempData.splice(tempIndex, 1);
                          if (!tempValues[index]) {
                            tempData.splice(0, 1);
                          }
                        }

                        setDataSelected(tempData);
                        callbackFilterSelect(tempData);
                      } else {
                        const tempValues = src.map((_) => !values[0]);
                        setValues(tempValues);

                        if (!values[0]) {
                          setDataSelected([...src]);
                          callbackFilterSelect([...src]);
                        } else {
                          setDataSelected([]);
                          callbackFilterSelect([]);
                        }
                      }
                    }}
                  />
                }
                label={item[searchAttr]}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <div>
        <Button
          className={classes.btnFilter}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            callbackFilterClick();
          }}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
}
