import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import DSwitch from 'component/shared/DSwitch';
import { useConfiguracaoAgendaGeral } from 'context/ConfiguracaoAgendaGeralContext';
import { AntSwitch } from 'component/shared/AntSwitch/AntSwitch';
import configuration from 'api/configuration';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  status: {
    height: 32,
    display: 'block',
    borderLeft: 'solid #67738B 2px',
    marginRight: 16,
  },
  gridItemHeader: {
    height: 58,
    background: '#F9F9F9',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
  },
  gridItem: {
    height: 58,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

function ParametrosGerais() {
  const classes = useStyles();

  const {
    agendarAplicativo,
    setAgendarAplicativo,
    encaixe,
    setEncaixe,
    retorno,
    setRetorno,
  } = useConfiguracaoAgendaGeral();
  const [loading, setLoading] = useState(false);

  function putParametrosGerais(configName, configValue) {
    setLoading(true);

    var configBody = {};

    if (configName == 'agendarAplicativo') {
      configBody.schedule_app = configValue;
    } else if (configName == 'encaixe') {
      configBody.allow_fit = configValue;
    } else if (configName == 'retorno') {
      configBody.allow_return_schedule = configValue;
    }

    configuration
      .putEnableOrDisableConfig(localStorage.getItem('token'), 1, configBody)
      .then((res) => {
        if (res.status) {
          if (configName == 'agendarAplicativo') {
            setAgendarAplicativo(Boolean(res.configuration.schedule_app));
          } else if (configName == 'encaixe') {
            setEncaixe(Boolean(res.configuration.allow_fit));
          } else if (configName == 'retorno') {
            setRetorno(Boolean(res.configuration.allow_return_schedule));
          }
        }

        setLoading(false);
      });
  }

  return (
    <Card>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: 'white' }} />
      </Backdrop>
      <CardContent>
        <Grid container direction="column">
          <Grid item classes={{ item: classes.gridItemHeader }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6" component="h2">
                  Parâmetros Gerais
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <span className={classes.status}>&nbsp;</span>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" component="h2">
                      Status
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item classes={{ item: classes.gridItem }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body1" className="text-regular text-st">
                  Agendar pelo aplicativo
                </Typography>
              </Grid>
              <Grid item>
                <AntSwitch
                  color="primary"
                  checked={agendarAplicativo}
                  onChange={(e) => {
                    putParametrosGerais('agendarAplicativo', e.target.checked);
                  }}
                  name="agendarAplicativo"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item classes={{ item: classes.gridItem }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body1" className="text-regular text-st">
                  Permitir encaixes
                </Typography>
              </Grid>
              <Grid item>
                <AntSwitch
                  color="primary"
                  checked={encaixe}
                  onChange={(e) => {
                    putParametrosGerais('encaixe', e.target.checked);
                  }}
                  name="encaixe"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item classes={{ item: classes.gridItem }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body1" className="text-regular text-st">
                  Permitir agendamento de retorno
                </Typography>
              </Grid>
              <Grid item>
                <AntSwitch
                  color="primary"
                  checked={retorno}
                  onChange={(e) => {
                    putParametrosGerais('retorno', e.target.checked);
                  }}
                  name="retorno"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ParametrosGerais;
