import env from 'react-dotenv';
import axios from 'axios';

const termData = {
  title: 'teste de titulo',
  html: '<div id="result" contenteditable="true"><p>Mussum Ipsum, cacilds vidis litro abertis. Interessantiss quisso pudia ce receita de bolis, mais bolis eu num gostis.Delegadis gente finis, bibendum egestas augue arcu ut est.Cevadis im ampola pa arma uma pindureta.Quem manda na minha terra sou euzis!</p><p>Aenean aliquam molestie leo, vitae iaculis nisl.Admodum accumsan disputationi eu sit. Vide electram sadipscing et per.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.Mauris nec dolor in eros commodo tempor. Aenean aliquam molestie leo, vitae iaculis nisl.</p><p>Suco de cevadiss deixa as pessoas mais interessantis.Cevadis im ampola pa arma uma pindureta.Sapien in monti palavris qui num significa nadis i pareci latim.Mé faiz elementum girarzis, nisi eros vermeio.</p></div>',
};

function getHistoricoVersaoById(token, id) {
  // var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

  // return fetch(`${api_url}/terms/${ id }?token=${ token }`, {
  //     method: 'get'
  // }).then(res => res.json());

  return new Promise((resolve, reject) => {
    resolve({ status: true, data: termData });
  });
}

export function getTermActive(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/terms/get-term-active?token=${token}`, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function getListTerm(token) {
  return axios
    .get(`${env.API_URL_SERVICE}/terms/list-terms?token=${token}`, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export function saveTerm(token, dados) {
  return axios
    .post(`${env.API_URL_SERVICE}/terms/save-term?token=${token}`, dados, {
      headers: {
        'x-domain': env.X_DOMAIN,
      },
    })
    .then((res) => res.data);
}

export { getHistoricoVersaoById, termData };
