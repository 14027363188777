import React, { useState } from 'react';
import AnamneseNewTab from '../../NewTabs/AnamneseNewTab';
import HistoricoNewTab from '../../NewTabs/HistoricoNewTab';
import PrescricaoNewTab from '../../NewTabs/PrescricaoNewTab';
import NovaSolicitacaoNewTab from '../../NewTabs/NovaSolicitacaoNewTab';
import '../index.scss';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  assumirAtendimento,
  getHealthAttendanceByPageFilter,
} from './../../../../api/health_attendances';
import { toast } from 'react-toastify';
import Confirm from './../../VoipCall/components/Confirm';
import { Assumir } from './../../VoipCall/modalcontent';
import { ErrorOutline } from '@material-ui/icons';
import {
  MuteCall,
  UnmuteCall,
  AssumirELigar,
  AbdicarAtendimento,
  AbdicarAtendimentoNew,
  LigarParaEspecialista,
  LigarParaPaciente,
  Desligar,
  DesligarEFinalizar,
  Finalizar,
  AssumirAtendimento as AssumirAtendimentoButton,
  FinalizarAtendimento,
  ChatButton,
  ChamadadeVoz,
  ChamadadeVideo,
  CancelarButtom,
  ConfirmarButtom,
  ConfirmarAbdicar,
  BackButtom,
} from './../../VoipCall/components/Buttons';

const useStyles = makeStyles((theme) => ({
  card_content: {
    width: '100%',
    height: 400,
    position: 'absolute',
    // background: "black",
    backdropFilter: 'blur(2px)',
    zIndex: 1299,
  },
  card_inner: {
    width: 328,
    height: 184,
  },
  container_blocked: {
    maxHeight: 400,
    overflow: 'hidden',
    position: 'relative',
  },
  iconColorConfirm: {
    color: 'rgba(148, 148, 148, 1)',
  },
  iconConfirm: {
    fontSize: 20,
  },
}));

function AssumirAtendimento(props) {
  const { healthAttendance, setHealthAttendance } = useNewHealthAttendance();

  const [assumir, setAssumir] = useState(false);

  const classes = useStyles();

  const handleAssumir = (health_attendance_id) => {
    assumirAtendimento(
      localStorage.getItem('token'),
      health_attendance_id,
    ).then((res) => {
      if (res.status) {
        getHealthAttendanceByPageFilter(localStorage.getItem('token'), 1, {
          search: health_attendance_id,
        })
          .then((res) => {
            if (res.status) {
              setHealthAttendance(res.healthAttendances.patient[0]);
              toast.success('Atendimento assumido com sucesso.');
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error('Erro ao recarregar atendimento.');
          });
      } else {
        toast.error('Erro ao assumir atendimento.');
      }
    });
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        classes={{ root: classes.card_content }}
      >
        <Grid item>
          <Card classes={{ root: classes.card_inner }}>
            <CardContent>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <ErrorOutline
                    fontSize="small"
                    color="primary"
                    classes={{
                      colorPrimary: classes.iconColorConfirm,
                      fontSizeSmall: classes.iconConfirm,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography className="text-regular text-mt" align="center">
                    Você precisa assumir o atendimento
                    <br />
                    para visualizar todos os dados
                  </Typography>
                </Grid>
                <Grid item className="actions-end">
                  <AssumirAtendimentoButton onClick={(e) => setAssumir(true)} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {assumir && (
        <Confirm
          open={assumir}
          onClose={(e) => setAssumir(false)}
          children={<Assumir />}
          footer={
            <Grid container justifyContent="center">
              <Grid item md={6}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CancelarButtom onClick={(e) => setAssumir(false)}>
                      Cancelar
                    </CancelarButtom>
                  </Grid>
                  <Grid item>
                    <ConfirmarButtom
                      onClick={(e) => handleAssumir(healthAttendance.id)}
                    >
                      Confirmar
                    </ConfirmarButtom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}

export default AssumirAtendimento;
