import React from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Excluir from '../../../../assets/img/Excluir.png';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    justifyContent: 'left',
  },
  backgroundModalExcluir: {
    minWidth: '450px',
    maxWidth: '500px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.neutral.white,
    margin: '200px auto',
    padding: '20px',
  },
  mainTitle: {
    textAlign: 'center',
    color: theme.palette.neutral.black,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  titleDelete: {
    textAlign: 'center',
    color: theme.palette.neutral.darkgray,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  columnGrid: {
    textAlign: 'center',
    marginTop: 20,
  },

  btnExcluir: {
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    borderRadius: '10px',
    gap: '10px',
  },
  btnEditar: {
    background: `${theme.palette.support.error}`,
    width: '226px',
    height: '48px',
    padding: '12px 16px',
    gap: '10px',
    borderRadius: '10px',
    color: 'white',
    transition: 'all 200ms linear',

    '&:hover': {
      color: 'black',
    },
  },
  innerModal: {
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textAlign: '-webkit-center',
  },
  trashIconCenter: {},
}));
export default function DeletarAgendaModal({
  onConfirm,
  isReagendamento,
  open,
  onClose,
}) {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <Grid container className={classes.backgroundModalExcluir}>
        <Grid container style={{ flexDirection: 'row-reverse' }}>
          <Button
            id="basic-button"
            aria-haspopup="true"
            onClick={() => onClose()}
          >
            <CloseOutlined />
          </Button>
        </Grid>
        <div className={classes.innerModal}>
          <Grid>
            <img src={Excluir} alt="" className={classes.trashIcon} />
            <h2 className={classes.mainTitle}>Excluir agenda</h2>
            <h3 className={classes.titleDelete}>
              {isReagendamento.status
                ? 'Há pacientes agendados no período, deseja reagendar ou cancelar?'
                : 'Ao confirmar, todas as configurações serão perdidas.'}
            </h3>
          </Grid>

          <Grid container>
            <Grid item md={6} className={classes.columnGrid}>
              <Button
                className={classes.btnExcluir}
                onClick={(e) => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item md={6} className={classes.columnGrid}>
              <Button
                onClick={(e) => {
                  onConfirm(isReagendamento);
                }}
                className={classes.btnEditar}
              >
                {isReagendamento.status ? 'Reagendar' : 'Confirmar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Modal>
  );
}

DeletarAgendaModal.defaultProps = {
  onConfirm: () => console.log('Confirmar'),
  open: true,
  isReagendamento: { status: false, scheduleTimes: null },
};
