import { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import styled from 'styled-components';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  getPermissionSelect,
  getMenusByProfileAndUnity,
  getDefaultPermission,
} from './../../../../../../api/user';
import { AppContext } from './../../../../../../context/AppContext';
import { useMenus } from './../../../../../../context/MenuContext';

function SelectPermission(props) {
  const theme = useTheme();

  const { userInfo, setUserInfo, setLockAdministrativeUnitMenu, setUpdateAcl } =
    useContext(AppContext);
  const { setMenus } = useMenus();
  const history = useHistory();

  const HeaderUserInfo = styled.div`
    display: flex;
    flex-direction: row-reverse;

    .MuiSelect-select.MuiSelect-select {
      padding: 5px 24px 2px 4px;
    }

    .MuiSelect-icon {
      color: ${theme.palette.primary.light};
    }

    .MuiInput-underline {
      &:after,
      &:before,
      &:hover:not(.Mui-disabled):before {
        border: 0;
      }
    }

    .content:first-child {
      padding-right: 20px;
    }

    .content:last-child {
      padding-right: 10px;
    }
  `;

  const [loading, setLoading] = useState(true);
  const [defaultPermission, setDefaultPermission] = useState(null);
  const [perfis, setPerfis] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [perfil, setPerfil] = useState(localStorage.getItem('perfil'));
  const [unidade, setUnidade] = useState(localStorage.getItem('unidade'));

  useEffect(() => {
    getPermissionSelect(localStorage.getItem('token')).then((res) => {
      if (res.data.status) {
        setPerfis(res.data.profiles);
      }
      setLoading(false);
    });

    getDefaultPermission(localStorage.getItem('token')).then((res) => {
      if (res.status) {
        handlePerfil(res.profile_id);
        localStorage.setItem('route_default', res.route_default);
      }
    });
  }, []);

  const handlePerfil = (perfilId = undefined, route_default = false) => {
    setPerfil(perfilId);

    if (perfilId) {
      setLoading(true);

      getPermissionSelect(localStorage.getItem('token'), perfilId).then(
        (resPerSelect) => {
          if (resPerSelect.data.status) {
            setUnidades(resPerSelect.data.medical_units);
            getDefaultPermission(localStorage.getItem('token'), perfilId).then(
              (redDefSelect) => {
                if (redDefSelect.status) {
                  const uniId = !unidade
                    ? redDefSelect.medical_unit_id
                    : unidade;
                  const v = resPerSelect.data.medical_units.filter(
                    (u) => u.id == uniId,
                  );
                  if (v.length > 0) {
                    handleUnidade(perfilId, uniId);
                  } else {
                    handleUnidade(perfilId, redDefSelect.medical_unit_id);
                  }
                  localStorage.setItem(
                    'route_default',
                    redDefSelect.route_default,
                  );
                  if (route_default) {
                    history.push(localStorage.getItem('route_default'));
                  }
                }
              },
            );
          }

          setLoading(false);
        },
      );
    }

    setUnidades([]);
    setUnidade(null);
  };

  const handleUnidade = (perfilId, unidadeId) => {
    setUnidade(unidadeId);

    if (unidadeId) {
      setUserInfo({
        perfil: perfilId,
        unidade: unidadeId,
      });

      getMenusByProfileAndUnity(
        localStorage.getItem('token'),
        unidadeId,
        perfilId,
      ).then((res) => {
        if (res.status) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('scope', res.details.token.scope);

          if (
            unidades.find((unidade) => unidade.medical_unit_id == unidadeId) &&
            unidades.find((unidade) => unidade.medical_unit_id == unidadeId)
              .medical_unit_type === 'Administrativa'
          ) {
            setMenus(
              res.details.profileMenu.filter(
                (menu) =>
                  menu.slug !== '/atendimento-imediato' &&
                  menu.slug !== '/solicitacao',
              ),
            );
            setLockAdministrativeUnitMenu(true);
          } else {
            setMenus(res.details.profileMenu);
            setLockAdministrativeUnitMenu(false);
          }

          setUpdateAcl(true);
        }
      });
    } else {
      setUserInfo({
        perfil,
        unidade: null,
      });

      setMenus([]);
    }
  };

  return (
    <>
      <HeaderUserInfo>
        {loading ? (
          <div className="content">
            <Typography variant="body1">
              Carregando permissão aguarde{' '}
              <CircularProgress size={15} color="inherit" />
            </Typography>
          </div>
        ) : (
          <>
            <div className="content">
              <strong>Unidade: </strong>
              <Select
                onChange={(e) => handleUnidade(perfil, e.target.value)}
                value={unidade}
              >
                {unidades.map((item, index) => {
                  return (
                    <MenuItem
                      value={item.medical_unit_id}
                      key={`unidades-menu-item-${index}`}
                    >
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="content">
              <strong>Perfil: </strong>
              <Select
                onChange={(e) => {
                  handlePerfil(e.target.value, true);
                }}
                value={perfil}
              >
                {perfis.map((item, index) => (
                  <MenuItem
                    value={item.profile_id}
                    key={`perfil-menu-item-${index}`}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
      </HeaderUserInfo>
    </>
  );
}

SelectPermission.defaultProps = {
  onMenus: function () {
    console.log('onMenus');
  },
};

export default SelectPermission;
