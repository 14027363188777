// import dayjs from "dayjs";
import { Button, Grid, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';

import GlobalContext from '../context/GlobalContext';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    padding: 32,
    display: 'flex',
    flex: 1,
  },
  colorButtonDate: {
    color: theme.palette.neutral.cloud,
  },
  snowBackgroundColor: {
    background: theme.palette.background.snow,
  },
  snowColor: {
    color: theme.palette.background.snow,
  },
  midlightgrayColor: {
    color: theme.palette.neutral.midlightgray,
  },
  midgrayColor: {
    color: theme.palette.neutral.midgrey,
  },
  blockButton: {
    color: `${theme.palette.support.error} !important`,
    borderColor: `${theme.palette.support.error} !important`,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
  },
  cancelButton: {
    color: `${theme.palette.support.secondary} !important`,
    borderColor: `${theme.palette.support.secondary} !important`,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
    marginRight: '5px !important',
  },
  saveButton: {
    color: `white !important`,
    borderColor: `${theme.palette.support.secondary} !important`,
    borderRadius: 20,
    textTransform: 'none',
    borderWidth: 2,
    padding: '3px 18px 0',
  },
  buttonDateMonth: {
    display: 'flex',
    background: `${theme.palette.advanced.sidebar} !important`,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.advanced.sidebar} !important`,
    height: '36px',
  },
  gridBlockButton: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignContent: 'center',
    paddingRight: '32px',
  },
}));

export default function CalendarHeader({ onGerenciarBloqueios }) {
  const classes = useStyles();

  const [bloqueioActive, setBloqueioActive] = useState(false);
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  const handlePrevMonth = () => setMonthIndex(monthIndex - 1);

  const handleNextMonth = () => setMonthIndex(monthIndex + 1);

  return (
    <Grid container>
      <Grid item md={4} className={classes.sidebar}>
        <div
          style={{
            display: 'flex',
            alignItems: 'unset',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div className={classes.buttonDateMonth}>
            <IconButton>
              <ChevronLeftIcon
                fontSize="large"
                className={classes.colorButtonDate}
                onClick={handlePrevMonth}
              />
            </IconButton>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: 14,
                }}
                className={classes.colorButtonDate}
              >
                {moment(new Date(moment().year(), monthIndex))
                  .format('MMMM yyyy')
                  .toUpperCase()}
              </h1>
            </div>
            <IconButton>
              <ChevronRightIcon
                fontSize="large"
                className={classes.colorButtonDate}
                onClick={handleNextMonth}
              />
            </IconButton>
          </div>
        </div>
      </Grid>
      <Grid item className={classes.gridBlockButton}>
        {bloqueioActive ? (
          <Fragment>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              size="sm"
              onClick={() => {
                onGerenciarBloqueios('cancel');
                setBloqueioActive(false);
              }}
            >
              <span style={{ marginTop: 2, display: 'block' }}>Cancelar</span>
            </Button>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="sm"
              startIcon={<SaveIcon style={{ fontSize: 20 }} />}
              onClick={() => {
                onGerenciarBloqueios('save');
                setBloqueioActive(false);
              }}
            >
              <span style={{ marginTop: 2, display: 'block' }}>Salvar</span>
            </Button>
          </Fragment>
        ) : (
          <Button
            variant="outlined"
            className={classes.blockButton}
            size="sm"
            startIcon={<CancelIcon style={{ fontSize: 20 }} />}
            onClick={() => {
              onGerenciarBloqueios(true);
              setBloqueioActive(true);
            }}
          >
            <span style={{ marginTop: 2, display: 'block' }}>
              Gerenciar bloqueios
            </span>
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

CalendarHeader.defaultProps = {
  onGerenciarBloqueios: () => {},
};
