import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import useDebounce from '../../../../../../hooks/useDebounce';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SimpleInput } from '../styles';
import {
  getHealthAttendanceObservation,
  createHealthAttendanceObservation,
  updateHealthAttendanceObservation,
} from '../../../../../../api/healthAttendance';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1800,
  },
  td: {
    whiteSpace: 'nowrap',
    padding: '0 12px',
    border: 0,
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 8,
  },
  paper: {
    padding: 8,
    fontSize: 12,
    minWidth: 350,
    whiteSpace: 'nowrap',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  selected: {
    backgroundColor: '#ffffff !important',
    border: '1px solid #4a4ae9 !important',
    color: '#4a4ae9',
    fontWeight: '500 !important',
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: '1px solid #4a4ae9 !important',
      borderRadius: '50%',
      color: '#4a4ae9',
    },
    '&>li:last-of-type>button': {
      border: '1px solid #4a4ae9 !important',
      borderRadius: '50%',
      color: '#4a4ae9',
    },
  },
  rootDialog: {
    display: 'flex',
    margin: 0,
    padding: '0 0 0 16px',
    height: 48,
    alignItems: 'center',
    flexFlow: 'row',
    justifyContent: 'space-between',
    minWidth: 500,
  },
  titleDialog: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  closeButton: {
    color: '#949494',
  },
  txtinput: {
    marginTop: 0,
  },
  rootDialog2: {
    display: 'flex',
    margin: 0,
    padding: '0 8px 0 16px',
    height: 48,
    alignItems: 'center',
    flexFlow: 'row',
    justifyContent: 'space-between',
    minWidth: 640,
  },
  titleDialog2: {
    fontSize: 16,
    fontWeight: 700,
    color: '#4a4ae9',
  },
  customMaxWidth: {
    maxWidth: 'none',
  },
  accordionRoot: {},
  accordionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#4a4ae9',
  },
  accordionText: {
    fontSize: 14,
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

export default function DialogObservation(props) {
  const classes = useStyles();
  const [originalObservation, setOriginalObservation] = useState(null);
  const [observation, setObservation] = useState('');
  const [newObservation, setNewObservation] = useState(null);
  const observationDebounced = useDebounce(newObservation, 500);
  const [statusAttendanceFinished, setStatusAttendanceFinished] =
    useState(null);
  const [userRequesting, setUserRequesting] = useState();
  const [dateOpenAttendance, setDateOpenAttendance] = useState();
  const [dateCloseAttendance, setDateClosenAttendance] = useState();

  function handleSaveObservation(observation) {
    if (originalObservation && originalObservation.id) {
      const promiseSave = updateHealthAttendanceObservation(
        localStorage.getItem('token'),
        observation,
        originalObservation.id,
        props.attendance.id,
        localStorage.getItem('uid'),
      )
        .then((data) => {
          if (!data.status) {
            throw new Error(data);
          }
        })
        .catch((err) => console.error(err));

      toast.promise(promiseSave, {
        pending: 'Salvando observação',
        success: 'Observação salva com sucesso',
        error: 'Erro ao salvar observação',
      });
    } else {
      const promiseCreate = createHealthAttendanceObservation(
        localStorage.getItem('token'),
        observation,
        localStorage.getItem('uid'),
        props.attendance.id,
      )
        .then((data) => {
          //console.log(data);
        })
        .catch((err) => console.error(err));

      toast.promise(promiseCreate, {
        pending: 'Criando observação',
        success: 'Observação salva com sucesso',
        error: 'Erro ao salvar observação',
      });
    }
  }

  useEffect(() => {
    if (!props.attendance) return;

    if (observationDebounced || observationDebounced === '') {
      newObservation && handleSaveObservation(observationDebounced);
    }
  }, [observationDebounced]);

  useEffect(() => {
    if (props.attendance) {
      getHealthAttendanceObservation(
        localStorage.getItem('token'),
        props.attendance.id,
      )
        .then((data) => {
          if (data.status) {
            setOriginalObservation(data.healthAttendanceObservation[0]);
            setObservation(data.healthAttendanceObservation[0].observation);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      setObservation('');
      setNewObservation(null);
      setOriginalObservation(null);
    };
  }, [props]);

  useEffect(() => {
    if (props.attendance) {
      setDateOpenAttendance(props.attendance.created_at);
      if (
        props.attendance.closed_at != null &&
        props.attendance.user_id != null &&
        props.attendance.health_attendance_status_id == 3
      ) {
        setStatusAttendanceFinished(true);
        setDateClosenAttendance(props.attendance.closed_at);
      }
      setUserRequesting(
        props.attendance.user_requesting
          ? props.attendance.user_requesting.full_name
          : '',
      );
    } else {
      toast.error(`Falha ao recuperar dados do atendimento, tente novamente.`);
    }
  }, []);

  function formatDate(date) {
    if (date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : null;
      var day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
      var month =
        date.getMonth() + 1 >= 10 ? date.getMonth() : '0' + date.getMonth();
      var year = String(date.getFullYear()).slice(2);

      return [day, month, year].join('/');
    } else {
      return '';
    }
  }

  function getDateHours(date) {
    if (date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : date;
      var hours =
        date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
      var minutes =
        date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
      var seconds =
        date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();
      return [hours, minutes].join(':');
    }
  }

  return (
    <>
      <Dialog onClose={props.close} open={props.open}>
        <MuiDialogTitle disableTypography className={classes.rootDialog}>
          <Typography className={classes.titleDialog}>
            {props.attendance && props.attendance.patient.full_name}
          </Typography>
          <IconButton className={classes.closeButton} onClick={props.close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ padding: '0 16px 16px' }}>
          <Grid container style={{ fontSize: 14 }}>
            <Grid item sm={6}>
              <strong>Solicitante:</strong>{' '}
              {userRequesting ? userRequesting : ''}
            </Grid>
            {statusAttendanceFinished ? (
              <Grid item sm={6}>
                <strong>Encerrado por:</strong>{' '}
                {userRequesting ? userRequesting : ''}
              </Grid>
            ) : (
              ''
            )}
          </Grid>

          <Typography
            style={{ fontWeight: 700, marginTop: 16, marginBottom: 5 }}
          >
            Observação:
          </Typography>

          <div dangerouslySetInnerHTML={{ __html: observation }} />
          {/* {newObservation !== "" && <SimpleInput
                        type="text"
                        placeholder="Digite uma observação"
                        noBorder
                        fullWidth
                        padding="0px 5px 0px"
                        onChange={e => setNewObservation(e.target.value)}
                        value={(newObservation || newObservation === "") ? newObservation : observation} 
                    /> } */}
          <Grid container style={{ marginTop: 16 }}>
            <Grid item sm={6}>
              <Typography style={{ fontSize: 14 }}>Aberto:</Typography>
              <div
                style={{
                  marginTop: 8,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                }}
              >
                <DateRangeIcon
                  fontSize="small"
                  style={{ marginRight: 20 }}
                  className="text-primary"
                />
                <strong style={{ fontWeight: 600 }}>
                  {dateOpenAttendance && formatDate(dateOpenAttendance)}{' '}
                  {dateOpenAttendance && getDateHours(dateOpenAttendance)}
                </strong>
              </div>
            </Grid>
            <Grid item sm={6}>
              {statusAttendanceFinished ? (
                <>
                  <Typography style={{ fontSize: 14 }}>Encerrado:</Typography>
                  <div
                    style={{
                      marginTop: 8,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 14,
                    }}
                  >
                    <DateRangeIcon
                      fontSize="small"
                      style={{ color: '#4a4ae9', marginRight: 20 }}
                    />
                    <strong style={{ fontWeight: 600 }}>
                      {dateCloseAttendance && formatDate(dateCloseAttendance)}{' '}
                      {dateCloseAttendance && getDateHours(dateCloseAttendance)}
                    </strong>
                  </div>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
