import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import { Backdrop, Chip, CircularProgress, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import DRadio from '../../../../../shared/DRadio';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  PatientContext,
  usePatient,
} from '../../../../../../context/usePatient';
import {
  getAllPatients,
  getPatientByPage,
  getPatientBySimpleFilter,
} from '../../../../../../api/patient';
import userSearchIcon from '../../../../../../assets/img-icon/userSearchIcon.svg';
import arrowBackIcon from '../../../../../../assets/img-icon/arrow_back.svg';
import { getFormatDate } from 'helpers/formatacao';

const useStyles = makeStyles((theme) => ({
  radioB: {
    color: `${theme.palette.primary.light} !important`,
  },
  table: {
    minWidth: 650,
  },
  textColor: {
    color: '#272727',
  },
  chip: {
    margin: 4,
  },
  resizeName: {
    maxWidth: '100px',
    overflow: 'scroll',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  selected: {
    backgroundColor: '#ffffff !important',
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,
    fontWeight: '500 !important',
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
    '&>li:last-of-type>button': {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
  },
  tableContainer: {
    overflowX: 'hidden',
  },
  gMessage: {
    fontSize: 14,
    color: '#949494',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 320px)',
    textAlign: 'center',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    overflow: 'scroll',
    maxWidth: '100px',
    minHeight: 58,
    padding: 9.5,
  },
  root: {
    borderBottom: 'solid #272727 1px',
  },
}))(TableCell);

const StyledRadioTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    width: 60,
    fontSize: 14,
    overflow: 'scroll',
    maxWidth: '100px',
    minHeight: 58,
    padding: 9.5,
    boxSizing: 'border-box',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: '1px solid !important',
      borderColor: `${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
    '&>li:last-of-type>button': {
      border: '1px solid !important',
      borderColor: `${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
  },
  root: {
    borderBottom: 'solid #272727 1px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: 'solid #272727 1px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledHeadTableRow = withStyles((theme) => ({
  root: {
    borderBottom: 'solid #272727 1.1px',
  },
}))(TableRow);

const StyledHeadTableCell = withStyles((theme) => ({
  root: {
    padding: 9.5,
    fontSize: '12px !important',
    fontWeight: '400 !important',
  },
}))(TableCell);

export default function SolicitacaoGrid(props) {
  const classes = useStyles();
  const {
    healthPatients,
    selectedValue,
    setSelectedValue,
    setIdPatient,
    loadingHealthPatients,
    setHealthPatients,
    paginate,
    setPaginate,
    searchText,
    firstFilter,
  } = usePatient();

  const handleChange = (event, id) => {
    setSelectedValue(id);
    setIdPatient(id);
    props.ver();
  };

  useEffect(function () {
    setSelectedValue(0);
    setIdPatient(0);
    setHealthPatients([]);
  }, []);

  function onPage(page) {
    console.log(page);

    getPatientBySimpleFilter(localStorage.getItem('token'), searchText, page)
      .then((res) => {
        console.log('onPage');
        let paginate = {
          current_page: 1,
          last_page: 1,
          per_page: 0,
          total: 0,
        };

        if (res.status) {
          setHealthPatients(res.patient);
          paginate = res.paginate;
        }
        setPaginate(paginate);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: 'white' }}
        open={loadingHealthPatients}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {Array.isArray(healthPatients) && healthPatients.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            style={{
              padding: 16,
              height: 'calc(100vh - 350px)',
              overflow: 'auto',
              boxShadow: 'none',
            }}
          >
            <Table className={classes.table} aria-label="solicitacoes table">
              <TableHead>
                <StyledHeadTableRow>
                  <StyledHeadTableCell></StyledHeadTableCell>
                  <StyledHeadTableCell>ID</StyledHeadTableCell>
                  <StyledHeadTableCell>Nome</StyledHeadTableCell>
                  <StyledHeadTableCell>Data de Nascimento</StyledHeadTableCell>
                  <StyledHeadTableCell>CPF</StyledHeadTableCell>
                  <StyledHeadTableCell>CNS principal</StyledHeadTableCell>
                  <StyledHeadTableCell>Nome da mãe</StyledHeadTableCell>
                </StyledHeadTableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(healthPatients) &&
                  healthPatients.map((row, index) => (
                    <StyledTableRow key={`pacientes-${index}`}>
                      <StyledRadioTableCell component="th" scope="row">
                        <Radio
                          checked={selectedValue === row.id ? true : false}
                          onChange={(e) => {
                            handleChange(e, row.id);
                          }}
                          value={row.id}
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      </StyledRadioTableCell>
                      <StyledTableCell>{row.id ? row.id : '-'}</StyledTableCell>
                      <StyledTableCell>
                        {row.full_name ? row.full_name : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.birth_date
                          ? getFormatDate(row.birth_date, 'dd/MM/yyyy')
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.cpf ? row.cpf : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.cns ? row.cns : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.mother_name ? row.mother_name : '-'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="center" alignItems="center">
            <Pagination
              page={paginate.current_page}
              count={paginate.last_page}
              onChange={(e, page) => {
                console.log(page);
                onPage(page);
              }}
              classes={{ root: classes.pagination, ul: classes.ul }}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  classes={{
                    selected: classes.selected,
                    page: classes.page,
                  }}
                />
              )}
            />
          </Grid>
        </>
      ) : (
        <>
          {!firstFilter ? (
            <Grid container>
              <Grid item className={classes.gMessage} sm={12}>
                <img src={userSearchIcon} style={{ marginBottom: 8 }} />
                Busque por um paciente para iniciar a solicitação de atendimento
              </Grid>
            </Grid>
          ) : (
            <>
              {!loadingHealthPatients && (
                <Grid container>
                  <Grid item className={classes.gMessage} sm={12}>
                    <img src={arrowBackIcon} style={{ marginBottom: 8 }} />
                    Nenhum paciente foi encontrado.
                    <br />
                    Tente uma busca diferente, ou cadastre um novo paciente
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
