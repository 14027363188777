/* eslint-disable no-use-before-define */
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { useState } from 'react';
import InnerApp from './InnerApp';
import { getList as getListTheme, palette } from './api/temas';
import './assets/sass/app.scss';
import AutoCadastro from './component/AutoCadastro';
import Autocomplete from './component/Demo/autocomplete';
import Buttons from './component/Demo/buttons';
import Inputs from './component/Demo/inputs';
import Radios from './component/Demo/radio';
import Switches from './component/Demo/switches';
import Textareas from './component/Demo/textareas';
import Titulos from './component/Demo/titulos';
import ErrorPage from './component/ErrorPage/ErrorPage';
import Home from './component/Home';
import Login from './component/Login';
import NaoAutorizado from './component/NaoAutorizado';
import Recover from './component/Recover';
import CriarPdf from './component/VisualizadorExames';
import CalendarMonth from './component/shared/CalendarMonth';
import ContextWrapper from './component/shared/CalendarMonth/context/ContextWrapper';
import LayoutAdmin from './component/shared/LayoutAdmin';
import SelectPermission from './component/shared/LayoutAdmin/components/Navbar/components/SelectPermission';
import { AuthRouteProvider } from './context/AuthRouteContext';
import { MenuProvider } from './context/MenuContext';
import { useAppTheme } from './context/useThemeContext';

function App() {
  const { handleChangeTheme } = useAppTheme();

  useState(() => {
    const jsonStorage = localStorage.getItem('theme');
    const plt = jsonStorage ? JSON.parse(jsonStorage) : palette;

    handleChangeTheme(plt);

    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

    if (path) {
      window.history.replaceState(path, '', path);
    }

    getListTheme().then((response) => {
      const localPalette = {
        ...palette,
        primary: {
          light:
            response?.configuration?.primary_color_light ||
            palette?.primary.light,
          main: response?.configuration?.primary_color || palette?.primary.main,
          dark:
            response?.configuration?.primary_color_dark ||
            palette?.primary.dark,
        },
        secondary: {
          light:
            response?.configuration?.second_color_light ||
            palette?.secondary.light,
          main:
            response?.configuration?.second_color || palette?.secondary.main,
          dark:
            response?.configuration?.second_color_dark ||
            palette?.secondary.dark,
        },
        logoPlataforma:
          response?.configuration?.file || palette?.logoPlataforma,
        logo_dev_enable:
          response?.configuration?.logo_dev_enable || palette?.logo_dev_enable,
        hidden_login_medical_group:
          response?.configuration?.hidden_login_medical_group ||
          palette?.hidden_login_medical_group,
      };

      handleChangeTheme(localPalette);
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/autocomplete">
          <Autocomplete />
        </Route>
        <Route path="/botoes">
          <Buttons />
        </Route>
        <Route path="/inputs">
          <Inputs />
        </Route>
        <Route path="/textareas">
          <Textareas />
        </Route>
        <Route path="/switches">
          <Switches />
        </Route>
        <Route path="/radios">
          <Radios />
        </Route>
        <Route path="/titulos">
          <Titulos />
        </Route>
        <Route path="/notificacoes">
          <LayoutAdmin>{/* <Notifications /> */}</LayoutAdmin>
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/recover">
          <Recover />
        </Route>

        <Route path="/autocadastro">
          <AutoCadastro />
        </Route>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/nao-autorizado" exact>
          <NaoAutorizado />
        </Route>
        <Route path="/month" exact>
          <ContextWrapper>
            <CalendarMonth />
          </ContextWrapper>
        </Route>
        <Route
          path="/visualizar-exame/:id"
          exact
          component={() => <CriarPdf attendancebundle={false} />}
        />
        {/* <Route path="/attendancebundle/pdf/:id" exact component={CriarPdf} />         */}
        <Route
          path="/attendancebundle/pdf/:id"
          exact
          component={() => <CriarPdf attendancebundle={true} />}
        />
        <Route path="*">
          <AuthRouteProvider>
            <MenuProvider>
              <SelectPermission />
              <InnerApp />
            </MenuProvider>
          </AuthRouteProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
