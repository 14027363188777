import React, { useContext } from 'react';
import '../index.scss';
import { Button } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ReactQuill from 'react-quill';
import DDropzone from '../../DDropzone';
import DButton from '../../DButton';
import FloatingBar from '../FloatingBar';
import { useNewHealthAttendance } from './../../../../context/NewHealthAttendance';
import { HealthAttendanceProvider } from './../../../../context/HealthAttendanceContext';
import Anamnese from './Anamnese';
import { toast } from 'react-toastify';
import NovaSolicitacaoNewTab from '../NovaSolicitacaoNewTab';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPatientById } from '../../../../api/patient';
import { Prescricao } from '../../../AtendimentoAnamnese/components/Modais/Prescricao';

function AnamneseNewTab(props) {
  const {
    startPrescription,
    healthAttendance,
    prescriptionModal,
    visualizador,
  } = useNewHealthAttendance();
  const [cid10, setCid10] = useState([]);

  return (
    <div className="fundo_new_tab">
      {prescriptionModal && <Prescricao />}
      <FloatingBar onReset={(e) => props.onReset(true)} />
      <HealthAttendanceProvider>
        {healthAttendance && (
          <Anamnese
            onCid10={(value) => {
              console.log(value);
              setCid10(value);
            }}
            toast={toast}
            idAtendimento={healthAttendance.id}
          />
        )}
      </HealthAttendanceProvider>
      {visualizador == false && (
        <div className="fundo_box_cinza_prescricao mt_35_new_tab">
          <div className="flex_1_box_cinza_new_tab">
            <div className="fonte_box_cinza_new_tab text-primary">
              <span
                style={{ cursor: 'pointer' }}
                onClick={(e) => startPrescription(cid10)}
              >
                Criar nova prescrição
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AnamneseNewTab.defaultProps = {
  onReset: function () {
    console.log('onReset');
  },
};

export default AnamneseNewTab;
