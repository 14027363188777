import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AcessibleIcon from '@material-ui/icons/AccessibleForward';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  textColor: {
    color: `${theme.palette.neutral.black} !important`,
  },
  chip: {
    margin: 4,
    color: `${theme.palette.neutral.black} !important`,
  },
  resizeName: {
    maxWidth: '100px',
    overflow: 'scroll',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  selected: {
    backgroundColor: `${theme.palette.background.cloud} !important`,
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: theme.palette.primary.light,
    fontWeight: '500 !important',
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    '&>li:first-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
    '&>li:last-of-type>button': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      color: theme.palette.primary.light,
    },
  },
  tableContainer: {
    overflowX: 'auto',
  },
  primaryDarkFill: {
    fill: theme.palette.primary.dark,
  },
  neutralGrayFill: {
    fill: theme.palette.neutral.midgrey,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.cloud,
  },
  body: {
    fontSize: 14,
    color: theme.palette.neutral.black,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function PacienteGrid(props) {
  const classes = useStyles();

  const printPronoun = (row) => {
    return row.pronoun ? ` (${row.pronoun.name})` : '';
  };

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: 'white', textAlign: 'center' }}
        open={props.loading}
      >
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando listagem de pacientes...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      {props.pacientes &&
      props.pacientes.length > 0 &&
      props.pacientes[0].id !== '-' ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} aria-label="pacientes table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ head: classes.textColor }}>ID</TableCell>
                <TableCell classes={{ head: classes.textColor }}>
                  Nome
                </TableCell>
                <TableCell classes={{ head: classes.textColor }}>CPF</TableCell>
                <TableCell classes={{ head: classes.textColor }}>
                  CNS principal
                </TableCell>
                <TableCell classes={{ head: classes.textColor }}>
                  Nascimento
                </TableCell>
                <TableCell classes={{ head: classes.textColor }}>PCD</TableCell>
                <TableCell classes={{ head: classes.textColor }}>
                  Grupos
                </TableCell>
                <TableCell classes={{ head: classes.textColor }}>
                  &nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.pacientes.map((row, index) => (
                <StyledTableRow key={`pacientes-${index}`}>
                  <StyledTableCell component="th" scope="row">
                    {row.id ? row.id : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.social_name
                      ? row.social_name + printPronoun(row)
                      : row.full_name + printPronoun(row) || '-'}
                  </StyledTableCell>
                  <StyledTableCell>{row.cpf ? row.cpf : '-'}</StyledTableCell>
                  <StyledTableCell>{row.cns ? row.cns : '-'}</StyledTableCell>
                  <StyledTableCell>
                    {row.birth_date && row.birth_date != '-'
                      ? row.birth_date
                          .substring(0, 10)
                          .split('-')
                          .reverse()
                          .join('/')
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.pcd[0] == '-' ||
                    row.pcd[0] == undefined ||
                    row.pcd[0] == '' ? (
                      <AcessibleIcon className={classes.neutralGrayFill} />
                    ) : row.pcd[0] ? (
                      <AcessibleIcon className={classes.primaryDarkFill} />
                    ) : (
                      <AcessibleIcon className={classes.neutralGrayFill} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.group_description != '-' &&
                    row.group_description.length
                      ? row.group_description.map((group, index) => {
                          if (!group)
                            return row.group_description.length == 1 ? '-' : '';

                          return (
                            <Chip
                              key={`chip-${index}`}
                              label={group}
                              className={classes.chip}
                            />
                          );
                        })
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <a
                      className="text-primary text-pointer"
                      onClick={(e) => row.id && props.detalhes(row)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <OpenInNewIcon />
                      <span>&nbsp;&nbsp;Abrir</span>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {!props.loading ? (
            <Card>
              <CardContent>
                <Typography variant="h4">
                  Nenhum resultado encontrado
                </Typography>
              </CardContent>
            </Card>
          ) : (
            ''
          )}
        </>
      )}
      {props.pacientes &&
        props.pacientes.length > 0 &&
        props.pacientes[0].id !== '-' && (
          <Pagination
            page={props.page}
            count={props.pagination ? props.pagination.last_page : 1}
            classes={{ root: classes.pagination, ul: classes.ul }}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                classes={{
                  selected: classes.selected,
                  page: classes.page,
                }}
              />
            )}
            onChange={(event, page) => props.onPage(page)}
          />
        )}
    </>
  );
}
