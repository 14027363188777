import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import DAutoComplete from '../../../../../../../shared/DAutoComplete';
import DButton from '../../../../../../../shared/DButton';
import DRadio from '../../../../../../../shared/DRadio';
import './styles.scss';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useError from '../../../../../../../../hooks/useError';

const useStyles = makeStyles((theme) => ({
  buttonSymptom: {
    minWidth: '15px !important',
    height: '34px !important',
    borderRadius: '100px !important',
    fontSize: '14px !important',
  },
  label: {
    color: '#000000 !important',
  },
  input_root: {
    marginRight: 16,
    marginTop: 8,
    '& input': {
      padding: 8,
      fontSize: 14,
    },
  },
}));

const typesDrugs = [
  { id: 1, value: 'Chá de Ayahuasca' },
  { id: 2, value: 'Cocaína' },
  { id: 3, value: 'Crack' },
  { id: 4, value: 'Ecstasy' },
  { id: 5, value: 'Haxixe' },
  { id: 6, value: 'Heroína' },
  { id: 7, value: 'Lança-perfume' },
  { id: 8, value: 'LSD' },
  { id: 9, value: 'Maconha' },
  { id: 10, value: 'MDMA' },
  { id: 11, value: 'Merla' },
  { id: 12, value: 'Oxi' },
  { id: 13, value: 'Quetamina' },
  { id: 14, value: 'Skank' },
  { id: 15, value: 'Outras' },
];

const formsUse = [
  { id: 1, value: 'Aspirada' },
  { id: 2, value: 'Injetada' },
  { id: 3, value: 'Inalada' },
  { id: 4, value: 'Oral' },
  { id: 5, value: 'Sublingual' },
  { id: 6, value: 'Fumada' },
  { id: 7, value: 'Uso Tópico' },
];

const EBDrugs = ({
  id,
  label,
  name,
  title,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();

  const [options, setOptions] = useState({});

  const [drugs, setDrugs] = useState(null);
  const [otherDrugs, setOtherDrugs] = useState('');
  const [drugsList, setDrugsList] = useState({
    answer: {
      type: null,
      use: null,
    },
  });

  const [showError, setShowError] = useState(false);

  const [error, validarFormulario, setErrors] = useError();

  function getData() {
    var data;

    data = {
      drugs,
      drugs_list: drugsList,
      otherDrugs: otherDrugs,
    };

    if (drugs == 'Não') {
      data.drugs_list = [];
    }

    return data;
  }

  useEffect(() => {
    var data = getData();
    let error = false;
    setShowError(false);
    if (drugs == null) {
      setShowError(false);
      error = false;
    }
    if (drugs != 'Não' || drugs == 'Sim') {
      if (drugsList.answer.type == null && drugsList.answer.use == null) {
        setShowError(true);
        error = true;
      }
    } else {
      setShowError(false);
      error = false;
    }
    if (drugs == null) {
      setShowError(false);
      error = false;
    }

    if (!error && validarFormulario({ ...data })) {
      handleInputSubmit({ [id]: data });
    }
  }, [drugs, drugsList, otherDrugs]);

  useEffect(() => {
    if (Array.isArray(answer) && answer.length > 0) {
      setDrugs(answer[0].drugs);
      if (answer[0].drugs == 'Sim') {
        setDrugsList({
          answer: {
            type: answer[0].drugs_list ? answer[0].drugs_list.answer.type : '',
            use: answer[0].drugs_list ? answer[0].drugs_list.answer.use : '',
          },
        });
      }
      if (
        Array.isArray(answer) &&
        answer[0].drugs_list &&
        answer[0].drugs_list.answer &&
        answer[0].drugs_list.answer.type == 'Outras'
      ) {
        setOtherDrugs(answer[0].otherDrugs);
      }
    }
  }, []);

  return (
    <section
      style={{
        display: `${
          condicional[0]
            ? condicional[0].action === 'esconder'
              ? 'none'
              : 'block'
            : ''
        }`,
      }}
    >
      {field_category_id === 3 && (
        <div>
          <div className="mb-3">
            <div className="box-medicoes-clinicas">
              <Typography
                variant="body1"
                className="text-bold mb-2"
                style={{ fontSize: '0.8em' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${label ? label : 'Uso de drogas ilícitas'}`,
                  }}
                />
              </Typography>

              <Box>
                <DRadio
                  inline
                  disabled={readOnly}
                  name="drugs-use"
                  onChange={(e) => {
                    setDrugs(e.target.value);
                    if (e.target.value == 'Não') {
                      setDrugsList({
                        answer: {
                          type: null,
                          use: null,
                        },
                      });
                    }
                  }}
                  value={drugs}
                  labelClass={classes.label}
                  options={[
                    { value: 'Não', label: 'Não' },
                    { value: 'Sim', label: 'Sim' },
                  ]}
                />
                {drugsList && drugs == 'Sim' ? (
                  <Grid container>
                    <>
                      <Grid item sm={6} style={{ paddingRight: 16 }}>
                        <DAutoComplete
                          label={'Tipo de droga ilícita'}
                          disabled={readOnly}
                          error={showError}
                          required={showError ? showError : false}
                          fullWidth={true}
                          placeholder="Tipo de droga ilícita"
                          labelColor="black"
                          style={{ marginTop: -8, marginBottom: 8 }}
                          defaultValue={
                            typesDrugs.filter(
                              (res) => res.value === drugsList.answer.type,
                            )[0]
                          }
                          options={typesDrugs}
                          getOptionLabel={(option) => option && option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(e, x) => {
                            if (!x) return;

                            setDrugsList({
                              ...drugsList,
                              answer: {
                                ...drugsList.answer,
                                type: x.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      {drugsList && drugsList.answer.type == 'Outras' ? (
                        <Grid item sm={5}>
                          <TextField
                            label=""
                            disabled={readOnly}
                            error={showError}
                            required={showError ? showError : false}
                            type="text"
                            value={otherDrugs}
                            onChange={(e) => {
                              setOtherDrugs(e.target.value);
                            }}
                            classes={{
                              root: classes.input_root,
                            }}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item sm={5}>
                        <DAutoComplete
                          label={'Forma de uso'}
                          disabled={readOnly}
                          required={showError ? showError : false}
                          error={showError}
                          fullWidth={true}
                          placeholder="Forma de uso"
                          labelColor="black"
                          style={{ marginTop: -8, marginBottom: 12 }}
                          defaultValue={
                            formsUse.filter(
                              (res) => res.value === drugsList.answer.use,
                            )[0]
                          }
                          onChange={(e, x) => {
                            if (!x) return;
                            setDrugsList({
                              ...drugsList,
                              answer: {
                                ...drugsList.answer,
                                use: x.value,
                              },
                            });
                            setShowError(false);
                          }}
                          options={formsUse}
                          getOptionLabel={(option) => option && option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                        />
                      </Grid>
                    </>
                  </Grid>
                ) : (
                  ''
                )}
              </Box>
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {showError ? 'Campos em destaque obrigatórios' : ''}
              </span>
              <br />
              <span style={{ fontSize: 12 }} className="text-light text-error">
                {erro[0] ? erro[0].value : ''}
              </span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBDrugs;
