import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { FormBuilderContext } from '../../../../../../../../context/FormBuilderContext';
import DRadio from '../../../../../../../shared/DRadio';
import './styles.scss';

const EBStateGeral = ({
  id,
  label,
  title,
  name,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);

  const [options, setOptions] = useState({});

  const handleChange = (e) => {
    setOptions(e.target.value);
    handleInputSubmit({ [id]: e.target.value });
  };

  return (
    <section
      style={{
        display: `${condicional[0] ? (condicional[0].action === 'esconder' ? 'none' : 'block') : ''}`,
      }}
    >
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold "
            style={{ fontSize: '0.8em' }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${label ? label : 'Estado Geral'}`,
              }}
            />
          </Typography>
          {additional_data.options.length > 0 ? (
            <DRadio
              inline
              name={name}
              disabled={readOnly}
              required={is_required === 1 ? true : false}
              onChange={handleChange}
              value={options.length > 0 ? options : answer[0]}
              options={additional_data.options}
            />
          ) : (
            'Não existe dados cadastrados'
          )}
        </div>
        <span style={{ fontSize: 12 }} className="text-light text-error">
          {erro[0] ? erro[0].value : ''}
        </span>
        <br />
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ''}
        </span>
      </div>
    </section>
  );
};

export default EBStateGeral;
